import BuilderLayout from "@/modules/pagebuilder/BuilderLayout.vue"
import Create from './views/Create.vue'
import SliderCreate from './views/Slider/Create.vue'
import TestimonialCreate from './views/Testimonial/Create.vue'

export default [
  {
    path: '/builder/:entity',
    name: 'wizard-builder',
    component: BuilderLayout,
    meta: {
      layout: 'blank',
    },
    children: [

      // Custom routes

      // Sliders
      {
        path: '/builder/sliders/create',
        name: 'builder-slider-create',
        component: SliderCreate,
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/builder/sliders/:id',
        name: 'builder-slider-show',
        component: SliderCreate,
        meta: {
          layout: 'blank',
        },
      },

      // Testimonials
      {
        path: '/builder/testimonials/create',
        name: 'builder-testimonials-create',
        component: TestimonialCreate,
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/builder/testimonials/:id',
        name: 'builder-testimonials-show',
        component: TestimonialCreate,
        meta: {
          layout: 'blank',
        },
      },

      // Dynamic routes
      {
        path: '/builder/:entity/create',
        name: 'builder-create',
        component: Create,
        props: true,
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/builder/:entity/:id',
        name: 'builder-show',
        component: Create,
        meta: {
          layout: 'blank',
        },
      },

    ],
  },
]