<template>
  <treeselect-field
    v-model="model"
    :field-props="fieldProps"
    :placeholder="placeholder"
    :multiple="multiple"
  />
</template>

<script>
import TreeselectField from "@/modules/erp_entities/components/page/form/fields/TreeselectField.vue";

export default {
  name: "EeFormTreeselect",
  components: {
    TreeselectField,
  },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
      default: {},
    },
    placeholder: {
      required: false,
    },
    multisite: {
      required: false,
      default: false,
    },
    multiple: {
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>