<template>
  <span
    class="status-badge"
    :class="`status_${rawValue}`"
  >
    {{ getStatusName }}
  </span>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: true,
    },
    rawValue: {
      required: false,
    },
  },
  computed: {
    getStatusName() {
      if (typeof this.value !== 'object') {
        if (this.value.toString() === '1') {
          return this.translate('Active', 'entities')
        } else if (this.value.toString() === '0') {
          return this.translate('Inactive', 'entities')
        }
      }
      // hotfix for products
      if (this.fieldProps.name === 'stock_status' && this.value === 'low_stock') {
        return this.translate('Low stock', 'products')
      }

      return this.translate(this.entityLabel(this.value), 'entities')
    },
  },
}
</script>
