// Blockzapps added

import Node from '../core/Node';
import {getCraftConfig} from "./createNodeFromVNode";
import {parse as nodeParse, HTMLElement} from "node-html-parser";

function parseSettings(settings){
  return settings ? JSON.parse(settings.replace(/'/g, '"')) : {}
}

function setDefaultPadding(attrStyles){
  if(typeof attrStyles['padding-top'] === 'undefined'){
    attrStyles['padding-top'] = '0px';
  }
  if(typeof attrStyles['padding-bottom'] === 'undefined'){
    attrStyles['padding-bottom'] = '0px';
  }
}

function explodeAttributeValues(attribute){
  if(attribute){
    let res = {}
    let props = attribute.split(";")

    props.forEach(value => {
      let [propName, ...propValue] = value.split(':'); // used array for propValue because "embedVideo: https://..." is split into 3 parts instead of 2

      if(propName){ // because styles ends with ';' , skip the last empty prop
        propName = propName.trim();
        propValue = propValue.join(':').replace('!important', '').trim() // remove important from styles

        if(propValue.length && propValue !== 'px'){ // skip empty values
          // console.log('propName="'+propName+'"'); console.log('propValue="'+propValue+'"'); console.log('---------------')
          res[propName] = propValue;
        }
      }
    })
    return res
  }
  return null
}
function createNodeFromHtmlNode(editor, htmlnode, parentNode = null) {

  if (htmlnode.isWhitespace) {
    //console.log('skipped because it is whitespace')
    return null;
  }
  if (!htmlnode.classNames) {
    //console.log('skipped because it does not have classNames')
    return null;
  }

  let attrStyles = explodeAttributeValues(htmlnode.attributes.style)
  if(attrStyles === null){ // required for setDefaultPadding
    attrStyles = {}
  }

  const apps = {
    'portfolio': 'PortfolioElement',
    'blog': 'BlogElement',
    'testimonial': 'TestimonialsElement',
    'form-builder': 'FormElement',
    'slider': 'SliderElement',
    'product': 'ProductsElement',
    'categories': 'CategoriesElement',
  }

  let componentName = ''
  let props = {}
  let htmlnodeChildren = false

  // Main Builder Container - has children
  if(htmlnode.classNames.includes('main-builder-container')){
    componentName = 'Canvas'
    props.component = 'MainContainerElement'
    htmlnodeChildren = htmlnode.childNodes;


    // Section - has children
  } else if(htmlnode.classNames.includes('cms-block-section') || htmlnode.rawTagName === 'section'){
    componentName = 'Canvas'
    props.component = 'SectionElement'
    htmlnodeChildren = htmlnode.childNodes;

    // get the class from the first div
    // use the children of the first div
    const containerHtmlNode = htmlnode.childNodes.filter(childHtmlNode => childHtmlNode.rawTagName === 'div')[0] // filter whitespaces

    props.settings = {}
    props.settings.container_class = containerHtmlNode.classNames.includes('container-fluid') ? 'container-fluid' : 'container'

    htmlnodeChildren = containerHtmlNode.childNodes

    setDefaultPadding(attrStyles)

    // Row - has children
  } else if(htmlnode.classNames.includes('row')){
    componentName = 'Canvas'
    props.component = 'RowElement'
    htmlnodeChildren = htmlnode.childNodes;

    props.settings = {}

    // we only need columns with classes col-md-*
    // col-4/col-8 will be custom classes, we don't want to covert them to col-md-4/col-md-8
    let row_columns = htmlnode.childNodes
        .filter(childHtmlNode => childHtmlNode.classNames && childHtmlNode.classNames.some(className => className.startsWith('col-md-') || className.startsWith('col-lg-') || className.startsWith('col-xl-'))) // get only columns
        .reduce((acc, obj) => { // return only col class names
          acc.push(obj.classNames.filter(colClassName => colClassName.startsWith('col-md-') || colClassName.startsWith('col-lg-') || colClassName.startsWith('col-xl-'))[0].replace(/\D/g,'')) // \D = all non digits
          return acc
        }, [])

    if(row_columns.length === 0){ // skip empty rows
      return null
    }

    props.settings.grid_layout = row_columns.join('-')

    setDefaultPadding(attrStyles)

    // Column - has children
  } else if(htmlnode.classNames.includes('cms-block-column') || htmlnode.classNames.some(substr => substr.startsWith('col-md-') || substr.startsWith('col-lg-') || substr.startsWith('col-xl-'))){
    componentName = 'Canvas'
    props.component = 'ColumnElement'
    htmlnodeChildren = htmlnode.childNodes;

    props.settings = {}
    props.settings.width = 12 // default width

    // regular columns (col-md-*)
    let col_md_lg_xl_found = htmlnode.classNames.filter(colClassName => colClassName.startsWith('col-md-') || colClassName.startsWith('col-lg-') || colClassName.startsWith('col-xl-'))
    if(col_md_lg_xl_found.length){
      props.settings.width = col_md_lg_xl_found[0].replace(/\D/g,'') // \D = all non digits
    } else {
      // custom columns (col-*)
      let col_found = htmlnode.classNames.filter(colClassName => colClassName.startsWith('col-'))
      if(col_found.length){
        props.settings.width = col_found[0].replace(/\D/g,'') // \D = all non digits
      }
    }

    if(typeof htmlnode.attributes['data-redirect'] !== 'undefined'){
      props.settings.link = htmlnode.attributes['data-redirect']
    } else if(typeof htmlnode.attributes['onclick'] !== 'undefined' && htmlnode.attributes['onclick'].startsWith('location.href')){
      props.settings.link = htmlnode.attributes['onclick'].replace("location.href='", '').replace("'", '')
    }

    setDefaultPadding(attrStyles)

  // else no children
  } else {

    // get rid of add new section/row/snippet code
    if(htmlnode.rawTagName === 'button' && (htmlnode.innerHTML.includes('Add row') || htmlnode.innerHTML.includes('Add section')) || htmlnode.innerHTML.includes('Add more snippets')){
      return null
    } else if(htmlnode.rawTagName === 'div' && htmlnode.attributes && htmlnode.attributes.handler === 'displaySnippetSettings'){
      return null
    }


    /*
     * Basic Components
     */
    if (true === false) {

      // Text Block
    } else if (htmlnode.rawTagName === 'text-block') {
      componentName = 'TextElement'
      props.content = decodeURIComponent(htmlnode.attributes.settings)

      if(attrStyles && attrStyles['font-size']){

        const parsed_html = nodeParse(props.content).childNodes.filter(childNode => childNode.outerHTML)[0]

        // append font-size to the first element, font-size and line-height cannot be supported in the main div
        if(parsed_html && attrStyles['font-size']){
          const style = `font-size: ${attrStyles['font-size']};` + (parsed_html.attributes.style ? parsed_html.attributes.style : '')
          let spanHTMLElement = new HTMLElement('span', {});
          spanHTMLElement.childNodes = parsed_html.childNodes;
          spanHTMLElement.setAttribute('style', style)
          parsed_html.childNodes = [spanHTMLElement];
          props.content = parsed_html.toString()
        }
      }

      // Image
    } else if (htmlnode.rawTagName === 'img') {
      componentName = 'ImageElement'
      props.attributes = {}
      props.attributes.src = htmlnode.attributes.src
      props.attributes.title = htmlnode.attributes.title
      props.attributes.alt = htmlnode.attributes.alt

      props.settings = {}
      if(typeof htmlnode.attributes['data-redirect'] !== 'undefined'){
        props.settings.link = htmlnode.attributes['data-redirect']
      } else if(typeof htmlnode.attributes['redirect'] !== 'undefined'){
        props.settings.link = htmlnode.attributes['redirect']
      }

    // Button
    } else if (htmlnode.rawTagName === 'button') {
      componentName = 'ButtonElement'
      props.settings = {}
      props.settings.link = htmlnode.attributes.redirect
      if(htmlnode.attributes['data-new-tab']){
        props.settings.link_target = '_blank'
      }
      props.settings.button_text = htmlnode.innerHTML

    // Icon Box
    /* not supported because we have only one in dynad
    } else if (htmlnode.rawTagName === 'icon-box') {
      componentName = 'IconBoxElement'
      props.settings = {}
    */

    // White space
    } else if (htmlnode.classNames.includes('cms-block-whitespace') || htmlnode.classNames.includes('builder-whitespace')) {
      componentName = 'WhitespaceElement'

    // Separator
    } else if (htmlnode.classNames.includes('cms-block-separator')) {
      componentName = 'SeparatorElement'

      const containerHtmlNode = htmlnode.childNodes.filter(childHtmlNode => childHtmlNode.rawTagName === 'div')[0] // filter whitespaces
      const separatorTextNode = containerHtmlNode.childNodes.filter(childHtmlNode => childHtmlNode.classNames?.includes('separator-text'))[0]
      const separatorBorderNode = containerHtmlNode.childNodes.filter(childHtmlNode => childHtmlNode.classNames?.includes('separator-border'))[0]

      const separatorTextStyles = explodeAttributeValues(separatorTextNode.attributes.style)
      const separatorBorderStyles = explodeAttributeValues(separatorBorderNode.attributes.style)

      let text_alignment = ''
      if(separatorTextStyles['right'] === '100%'){
        text_alignment = 'left'
      } else if (separatorTextStyles['right'] === '50%') {
        text_alignment = 'center'
      } else { // separatorTextStyles['right'] === '0'
        text_alignment = 'right'
      }

      props.settings = {
        separator_type: separatorTextStyles['display'] === 'none' ? 'without_text' : 'with_text',
        separator_text: separatorTextNode.innerHTML,
        text_alignment: text_alignment,
        border_bottom_color: separatorBorderStyles['border-bottom-color'],
        border_bottom_style: separatorBorderStyles['border-bottom-style'],
        border_bottom_width: separatorBorderStyles['border-bottom-width']
      }

    // Embed Video
    } else if (htmlnode.classNames.includes('cms-block-embed-video')) {
      componentName = 'EmbedVideoElement'

      const embedVideoStyles = explodeAttributeValues(htmlnode.attributes.style, true)
      props.settings = {
        link: embedVideoStyles['embedVideo'],
        width: embedVideoStyles['child-width'],
        alignment: embedVideoStyles['child-alignment'],
      }

    // Custom Html
    } else if (htmlnode.classNames.includes('cms-block-custom-html')) {
      componentName = 'CustomCodeElement'
      props.content = htmlnode.innerHTML

    // App
    } else if (Object.keys(apps).includes(htmlnode.rawTagName)) {
      componentName = apps[htmlnode.rawTagName]
      props.settings = parseSettings(htmlnode.attributes.settings)

      // delete not needed settings
      if(props.settings && props.settings['renderKey']){
        delete props.settings['renderKey']
      }

    // fallback to Custom Html
    } else {

      // debug
      // console.error('PAGE BUILDER: Unmatched element, converting to CustomCodeElement')
      // console.log(htmlnode.outerHTML)

      componentName = 'CustomCodeElement'
      props.content = htmlnode.rawTagName === 'div' ? htmlnode.innerHTML : htmlnode.outerHTML
    }
  }

  // style
  if(attrStyles){
    // delete text specific styles, they are added above to the text itself
    delete attrStyles['font-size']
    delete attrStyles['line-height']

    props.elementStyle = attrStyles
  }

  // id
  if(htmlnode.attributes.id){
    props.elementId = htmlnode.attributes.id
  }

  // classes
  if(htmlnode.classNames){
    props.elementClasses = {}
    let customClasses = htmlnode.classNames
    const device_classes = {"desktop": 'frontside-d-lg-none', "tablet": 'frontside-d-md-none', "mobile": 'frontside-d-xs-none'}
    const exclude_classes = ['row', 'btn', 'col-md-1', 'col-md-2', 'col-md-3', 'col-md-4', 'col-md-5', 'col-md-6' ,'col-md-7', 'col-md-8', 'col-md-9', 'col-md-10', 'col-md-11', 'col-md-12']
    Object.keys(device_classes).forEach(device => {
      if(customClasses.includes(device_classes[device])){
        props.elementClasses[device] = device_classes[device]
        customClasses.splice(customClasses.indexOf(device_classes[device]), 1);
      }
    })

    exclude_classes.forEach(exclude_class => {
      if(customClasses.includes(exclude_class)){
        customClasses.splice(customClasses.indexOf(exclude_class), 1);
      }
    })

    // check if any are left
    if(customClasses.length){
      props.elementClasses.custom = customClasses.join(' ')
    }
  }

  // console.log('props.elementStyle')
  // console.log(props.elementStyle)

  // props

  /*
  if (componentName === 'Canvas' && htmlnode.data.attrs) {
    props = { ...props, ...htmlnode.data.attrs };
  }*/

  const { rules, addition, defaultProps } = getCraftConfig(componentName, props, editor);
  const nodeProps = { ...defaultProps, ...props };

  const node = new Node(componentName, nodeProps, parentNode, [], rules, addition);

  const children = htmlnodeChildren
      ? htmlnodeChildren.map((childHtmlNode) => createNodeFromHtmlNode(editor, childHtmlNode, node))
          .filter((childNode) => !!childNode)
      : [];
  node.children = children;

  return node;
}

export default createNodeFromHtmlNode;