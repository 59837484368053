<template>
  <div class="modal media-fm" v-show="isOpen">
    <div class="modal-content">
      <div class="modal-body p-0">
        <file-manager v-if="settings" :settings="settings"></file-manager>
      </div>
      <div class="modal-footer bg-white">
        <button type="button" class="btn btn-sm webcode-btn-close" @click="close">
          {{ translate('Cancel', 'global') }}
        </button>
        <button class="btn btn-sm btn-primary" @click="emitSelected">
          {{ translate('Select', 'global') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vue from 'vue'
import {mapState} from "vuex";
import { fileManagerSettings } from "@/utilities/helper"

export default {
  created(){
    this.fm.$on('open', this.open)
  },
  methods: {
    fileManagerSettings,
    open(instance){
      this.isOpen = true
      this.instance = instance
    },
    close(){
      this.isOpen = false
      this.$store.state.fm.left.selected.files = []
    },
    emitSelected(){
      // unmute, set prefix and send

      //this.fm.$emit('selected_' + this.instance, [...this.store.state.fm.left.selected.files].map(img => `/uploads/${this.$store.state.fm.left.selectedDisk}/${img.replaceAll(' ', '%20')}`))
      this.fm.$emit('selected_' + this.instance, [...this.$store.state.fm.left.selected.files].map(img => `/uploads/${this.$store.state.fm.left.selectedDisk}/${img.replaceAll(' ', '%20')}`))
      this.close()
    },
  },
  data(){
    return {
      isOpen: false,
      instance: '',
    }
  },
  computed: {
    settings() {
      // I don't know why but using directly fileManagerSettings() does not work
      return this.fileManagerSettings()
    },
    fm(){
      return this.$fileManager
    },
  },
  props: {
    options: {
      required: true,
    },
  },
}
</script>

<style lang="scss">
.modal.media-fm {
    display: inherit;

    .modal-content {
        width: 83.3%;
        margin-left: auto;
        height: 100%;
    }
}

.fm-navbar .btn-group .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: $primary !important;
    color: #fff !important;
}

.fm .fm-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 500px !important;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #6d757d;
    border-bottom: 1px solid #6d757d;

    .fm-content .fm-content-body  {
      height: calc(100vh - 270px);
    }

    .fm-tree {
      height: calc(100vh - 207px);
    }

    .fm-breadcrumb .breadcrumb.active-manager {
        background-color: transparent !important;
    }
}
</style>
