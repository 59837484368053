<template>
  <div>
    <div v-if="customer" class="user-col">
      <div class="customer-info">
        <label>Address</label>
        <div class="single-info mb-3">
          <v-select
            v-model="selectedAddress"
            :options="addressOptions"
            :reduce="value => value.key"
            :placeholder="translate('Customer addresses', 'orders')"
            :clearable="false"
            @input="v => $emit('changeAddress', v)"
          />
        </div>
      </div>
    </div>

    <div class="user-col">
      <div class="customer-info w-100 mt-2">
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('First name', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_client_first_name"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('First name', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Last name', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_client_last_name"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Last name', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Address', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_address"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Address', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('City', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_city"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('City', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Postcode', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_postcode"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Postcode', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Country', 'orders') }}</span>
          <div
            v-if="countries"
            class="col-md-7 align-items-center"
          >
            <v-select
              v-model="value.billing_address_country"
              :options="countries"
              label="value"
              :disabled="customer"
              :reduce="val => val.key"
              :placeholder="translate('Country', 'orders')"
              :clearable="false"
            />
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('State', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_state"
              class="form-control"
              :readonly="customer"
              type="text"
              :placeholder="translate('State', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Email', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_client_email"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Email', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Phone', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_client_phone"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Phone', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Mol', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_mol"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Mol', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Bulstat', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_address_bulstat"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Bulstat', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Company name', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_company_name"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Company name', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('VAT number', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.billing_vat_number"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('VAT number', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Chamber of commerce', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <input
              v-model="value.chamber_of_commerce"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Chamber of commerce', 'orders')"
              :required="true"
            >
          </div>
        </div>
        <div class="single-info d-flex justify-content-between py-2">
          <span>{{ translate('Additional information', 'orders') }}</span>
          <div class="col-md-7 align-items-center">
            <textarea
              v-model="value.billing_address_additional_information"
              class="form-control"
              type="text"
              :readonly="customer"
              :placeholder="translate('Additional information', 'orders')"
              :required="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderBillingAddressForm",
  props: {
    value: {
      required: true,
    },
    countries: {
      required: true,
    },
    customer: {
      required: false,
    },
    addressOptions: {
      required: false,
    },
    selectedAddress: {
      required: false,
    },
  },
}
</script>
