<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <span class="add-new-item" @click.prevent="addItem(newItem)"><i class="fa icon-erp-add mr-2"></i>{{ translate('Add new icon', 'page-builder') }}</span>

            <draggable
                v-model="items"
                @start="drag=true"
                @end="dragEnd"
                :draggable="'.draggable-item'">
              <div v-for="(item, itemIndex) in items" :key="itemIndex" class="card draggable-item">
                <h6 class="card-head d-flex align-items-center" @click="toggleVisibility(itemIndex)">
                  <i class="fa icon-erp-drag-and-drop"></i>

                  <span><i v-if="item.icon" :class="item.icon" class="fa mr-2"></i>{{ itemLabel(item, itemIndex) }}</span>
                  <i
                      class="fa ml-auto fa-chevron-down chevron"
                      :class="{'chevron-rotate': visible[itemIndex]}"
                  ></i>
                </h6>

                <transition-expand>
                  <div v-show="visible[itemIndex]">
                    <div class="card-body pt-0" :class="{'snippets-holder' : visible[itemIndex]}">
                      <div class="app-page-snippets d-flex flex-column">

                        <craft-icon-picker
                            :label="'Choose your icon'"
                            :value="item.icon"
                            :icons="iconsList"
                            @input="iconSelected(item, $event)"
                        />

                        <craft-input
                            :label="'Label'"
                            :value="item.label"
                            @input="e => {item.label = e}"
                        />

                        <craft-input
                            :label="'Add link'"
                            :value="item.link"
                            @input="e => {item.link = e}"
                        />

                        <craft-tab-pills
                            :label="'Link behavior'"
                            :value="item.link_target"
                            @input="e => {item.link_target = e}"
                            :options="generalLinkTargetOptions"
                        />

                        <button class="btn btn-secondary rounded-pill" @click.prevent="removeItem(itemIndex)"><i class="icon-erp-trash"></i> {{ translate('Remove this icon', 'page-builder') }}</button>
                      </div>
                    </div>
                  </div>
                </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
                :label="'Layout'"
                :value="getSetting('orientation')"
                @input="setSetting('orientation', $event)"
                :options="generalOrientationOptions"
            />
          </div>
          <div class="col-12">
            <craft-tab-pills
                :label="translate('Button layout', 'page-builder')"
                :value="getSetting('type')"
                @input="setSetting('type', $event)"
                :options="typeOptions"
            />
          </div>
        </div>

        <transition name="fade">
          <div v-if="getSetting('type') !== 'icon_only'" class="row">
            <div class="col-6">
              <craft-input
                  :label="'Border'"
                  :input-type="'number'"
                  :suffix="'PX'"
                  :value="getSetting('border_width', 'px')"
                  @input="setSetting('border_width', $event, 'px')"
              />
            </div>
            <div class="col-6">
              <craft-color-picker
                  :label="'Border color'"
                  :value="getSetting('border_color')"
                  @input="setSetting('border_color', $event)"
              />
            </div>
          </div>
        </transition>

        <div class="row">
          <transition name="fade">
            <div v-if="getSetting('type') !== 'icon_only'" class="col-6">
              <craft-color-picker
                  :label="'Background'"
                  :value="getSetting('background_color')"
                  @input="setSetting('background_color', $event)"
              />
            </div>
          </transition>
          <div class="col-6">
            <craft-color-picker
                :label="'Icon color'"
                :value="getSetting('icon_color')"
                @input="setSetting('icon_color', $event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <craft-input
                :label="'Icon size'"
                :input-type="'range'"
                :min="1" :max="60"
                :suffix="'PX'"
                :value="getSetting('icon_width', 'px')"
                @input="setSetting('icon_width', $event, 'px')"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <craft-input
                :label="'Padding'"
                :input-type="'range'"
                :suffix="'PX'"
                :value="getSetting('padding', 'px')"
                @input="setSetting('padding', $event, 'px')"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <craft-input
                :label="'Spacing'"
                :input-type="'range'"
                :suffix="'PX'"
                :value="getSetting('gap', 'px')"
                @input="setSetting('gap', $event, 'px')"
            />
          </div>
        </div>

      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import ComponentWithItemsMixin from "@/modules/pagebuilder/mixins/ComponentWithItemsMixin";

import {CraftColorPicker, CraftIconPicker, CraftInput, CraftTabPills, CraftToggle} from "@/modules/pagebuilder/components/craft-inputs";
import {humanReadable} from "@/utilities/helper";

export default {
  name: 'SocialMediaIconsSettings',
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, ComponentWithItemsMixin],
  inject: ['node'],
  components: {
    CraftColorPicker, CraftIconPicker, CraftInput, CraftTabPills, CraftToggle
  },
  computed: {
    newItem(){
      return {
        icon: '',
        label: '',
        link: '',
        link_target: '_blank',
        position: this.items.length
      }
    },
    typeOptions() {
      return [
        {key: 'rounded', label: 'Rounded'},
        {key: 'icon_only', label: 'Icon only'},
        {key: 'square', label: 'Square'},
        {key: 'round', label: 'Round'}
      ]
    },
    iconsList(){
      return [
        "fa-facebook",
        "fa-square-facebook",
        "fa-instagram",
        "fa-linkedin",
        "fa-linkedin-in",
        "fa-medium",
        "fa-pinterest",
        "fa-pinterest-p",
        "fa-square-pinterest",
        "fa-quora",
        "fa-reddit",
        "fa-reddit-alien",
        "fa-square-reddit",
        "fa-skype",
        "fa-slack",
        "fa-snapchat",
        "fa-square-snapchat",
        "fa-soundcloud",
        "fa-spotify",
        "fa-steam",
        "fa-square-steam",
        "fa-telegram",
        "fa-tiktok",
        "fa-tumblr",
        "fa-square-tumblr",
        "fa-twitch",
        "fa-twitter",
        "fa-square-twitter",
        "fa-vimeo",
        "fa-square-vimeo",
        "fa-vk",
        "fa-whatsapp",
        "fa-youtube",
        "fa-square-youtube",
      ]
    },
  },
  methods: {
    iconSelected(item, icon){
      if(icon){
        item.icon = icon
        let label = icon.replaceAll('fa-brands ', '').replaceAll('fa-solid ', '')
        const parts = label.split('-')
        parts.shift() // Remove the first part, which is 'fa-brands' or 'fa-solid'
        item.label = humanReadable(parts.join('-'))
      } else {
        item.icon = ''
        item.label = ''
      }
    },
    itemLabel(item, itemIndex){
      if(item.label !== ''){
        return item.label
      } else {
        let number = itemIndex + 1
        return number < 2 ? "Icon" : "Icon " + number
      }
    },
  },
}
</script>