<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="label">{{ translate('Parent category', 'categories') }}</label>
                    <treeselect-field
                      :value="getSetting('filter')"
                      @input="setSetting('filter', $event)"
                      :field-props="{ data_type: 'treeselectCategory', data_module: entity }"
                      :placeholder="translate('Choose parent category', 'entities')"
                      :multiple="true"
                      :clearable="true"
                    />
                </div>
            </div>

            <div class="col-12">
              <craft-select
                  :label="'Layout'"
                  :options="layoutOptions"
                  :value="getSetting('layout')"
                  @input="setSetting('layout', $event)"
                  :clearable="true"
              />
            </div>

            <transition name="fade">
              <div class="col-12" v-if="getSetting('layout') === 'table'">

                <label class="label mb-2 fs-">{{ translate('Number of columns', 'categories') }}</label>

                <div class="row">
                  <div class="col-6">
                    <craft-select
                      v-model="columns['desktop']"
                      :label="'Desktop'"
                      :options="columnOptions"
                    />
                  </div>
                  <div class="col-6">
                    <craft-select
                      v-model="columns['tablet_landscape']"
                      :label="'Tablet (landscape)'"
                      :options="columnOptions"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <craft-select
                      v-model="columns['tablet_portrait']"
                      :label="'Тablet (portrait)'"
                      :options="columnOptions"
                    />
                  </div>
                  <div class="col-6">
                    <craft-select
                      v-model="columns['mobile']"
                      :label="'Mobile'"
                      :options="columnOptions"
                    />
                  </div>
                </div>

              </div>
            </transition>

            <div class="col-12">
              <craft-input
                  :label="'How many categories you want to show'"
                  :input-type="'number'"
                  :value="getSetting('number_of_categories')"
                  @input="setSetting('number_of_categories', $event)"
              />
            </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import { TreeselectField } from "@/modules/erp_entities/components/page/form/fields"
import { CraftInput, CraftSelect } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: 'CategorySettings',
  components: {
    TreeselectField,
    CraftInput,
    CraftSelect,
  },
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  	data() {
		return {
			entity: 'inventories',
      columns: {},
		}
  	},
  	computed: {
    	layoutOptions() {
			return [
				{ key: 'table', label: 'Grid' },
				{ key: 'list_image', label: 'List view with image' },
				{ key: 'list', label: 'List with text links only' }
			]
		},
    	columnOptions() {
			return [
        { key: '12', label: '1' },
				{ key: '6', label: '2' },
				{ key: '4', label: '3' },
				{ key: '3', label: '4' },
			]
		}
  	},
    mounted() {
      this.columns = this.getSetting('columns')
    },
    watch: {
      columns: {
        deep: true,
        handler() {
          this.setSetting('columns', this.columns)
        },
      },
    },
}
</script>
