export default function (node) {
  const searchBarId = `search-${node.uuid}`

  let styles = ''

  // Layout

  if (node.props.settings['width']) {
    styles += `#${searchBarId} { width: ${node.props.settings['width']} }`
  }

  // Round type
  if (node.props.settings['type'] === 'rounded') {
    styles += `#${searchBarId} .input-group {border-radius: 0.25rem;}`
  } else if (node.props.settings['type'] === 'square') {
    styles += `#${searchBarId} .input-group {border-radius: 0!important}`
  } else if (node.props.settings['type'] === 'round') {
    styles += `#${searchBarId} .input-group {border-radius: 50rem!important}`
  }

  // Styling

  // Background and colors

  if (node.props.settings['background_color']) {
    styles += `#${searchBarId} .input-group > input { background-color: ${node.props.settings['background_color']} }`
  }

  if (node.props.settings['button_background_color']) {
    styles += `#${searchBarId} .input-group button { background-color: ${node.props.settings['button_background_color']} }`
  }

  if (node.props.settings['text_color']) {
    styles += `#${searchBarId} .input-group > input { color: ${node.props.settings['text_color']} }`
  }

  if (node.props.settings['icon_color']) {
    styles += `#${searchBarId} .input-group button > i { color: ${node.props.settings['icon_color']} }`
  }

  if (node.props.settings['placeholder_color']) {
    styles += `#${searchBarId} .input-group > input::placeholder { color: ${node.props.settings['placeholder_color']} }`
  }

  // Border styles

  if (node.props.settings['border_style']) {
    styles += `#${searchBarId} .input-group { border-style: ${node.props.settings['border_style']} }`
  }

  if (node.props.settings['border_color']) {
    styles += `#${searchBarId} .input-group { border-color: ${node.props.settings['border_color']} }`
  }

  if (node.props.settings['border_width']) {
    styles += `#${searchBarId} .input-group { border-width: ${node.props.settings['border_width']} }`
  }

  return styles

}