import { mapState } from 'vuex'
import moment from "moment"
import { scopeSystemByWebsite } from "@/utilities/helper"
import store from "@/store"
import setColors from "@/utilities/setColorsGlobal"

export default {
  async created() {
    // this.trial = await this.getTrialPeriod() NOT USED
    // if (this.scope) scopeSystemByModel(this.scope.data[0]) this brakes switching website functionality, it calls the scope again return the previous website
  },
  methods: {
    saveWebsite() {
      let data = {
        options: this.website.objectData,
      }
      delete this.website.objectData.id
      this.erp.ext.request.axiosInstance.put(`modules/cms-websites/${this.$store.state.system.scope.value}`, data)
      this.$toast.requestSuccess('put', `Website status`)
    },
    publishWebsite() {
      if (this.steps.complete < this.steps.total) {
        this.$alert.error(`You cannot publish website until you complete all ${this.steps.total} steps!`)
        return
      }
      this.$set(this.website.objectData, 'status', Number(!Number(this.website.objectData.status)))
      this.saveWebsite()
    },
    async loadWebsiteProgress(id = null) {
      let website
      if (this.scope) {
        website = this.scope.value
      } else {
        website = id
      }
      let { data } = await this.erp.ext.request.axiosInstance.get(`modules/cms-websites/get-info`)
      this.info = data
    },
    async loadData() {
      this.loading = true
      this.website = await this.erp.ext.request.find(`cms-websites`, this.scope.value)
      await this.loadWebsiteProgress()
      this.loading = false
    },

    /* NOT USED
    async getTrialPeriod() {
      let subs = await this.erp.ext.request.axiosInstance.get('/modules/financial/subscriptions?perpage=5&column=created_at&direction=desc', {
        headers: {
          company: this.$store.state.system.company.key
        },
      })

      if (subs.data.data.length > 0) {
        this.plan = subs.data.data.slice(-1)[0].additional_data.plan_name
        let end = moment(subs.data.data[0].trial_end)
        let start = moment.now()
        let days_left = Math.ceil(moment.duration(end.diff(start)).asDays())
        let total = Math.ceil(moment.duration(end.diff(moment(subs.data.data[0].created_at))).asDays())

        if (subs.data.data[0].trial_end === null || subs.data.data[0].trial_end === undefined) {
          return {valid: false, days_left: days_left, total: total}
        } else if (days_left < 0) {
          return {valid: false, days_left: days_left, total: total}
        } else {
          return {valid: true, days_left: days_left, total: total}
        }
      }
      return {
        days_left: 0,
        total: 0,
      }
    },
    */
  },
  data() {
    return {
      info: null,
      website: null,
      trial: {},
      plan: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('system', ['scope']),
    steps() {
      if (this.info) {
        return {
          complete: Object.values(this.info).filter(entry => entry).length,
          total: Object.values(this.info).length,
        }
      }
    },
    isWebsitePublished() {
      if (!this.website) return
      return this.website.objectData.status == '1'
    },
  },
  watch: {
    'scope': {
      deep: true,
      handler() {
        if (this.scope && this.scope.value) {
          this.loadData()
          setColors()
        }
      },
    },
  },
}
