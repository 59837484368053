import axios from 'axios'
import { environment } from "./helper"
import { SweetToast } from '@/utilities/sweetalert'

import request from './request-deprecated'
import { erp } from '@/modules/erp_framework'

/*
* Create single axios instance for the whole project
*/

let axiosInstance = erp.ext.request.axiosInstance


/*
* Chain request middlewares here
*/
axiosInstance.interceptors.request.use(config => {

  // If we dont have internet connection cancel the request
  if (!window.navigator.onLine) {
    SweetToast.appIsOffline()
    throw new axios.Cancel('Application is offline.')
  }

  return config


  /*
    * If axios has errors before request launch, alert them
    */
}, error => {
  // check for errorHandle config
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error)
  }

  // if has response show the error
  if (error.response) {
    SweetToast.error(error.response.data.message)
  }
})

/*
*  Extend axios and create caching functionality mentioned in task #1
*/
export const db = axiosInstance

/**
 * Request object will act as a service for the requests
 * For entities that are not dynamic, describe requests in service folder
 */

request.axiosInstance = axiosInstance

/*
* Project routes
* Store all routes here for easier navigation
*/
export const routes = {
  oauth_token: '/oauth/token',
  tasks: {
    update: '/modules/tasks/:id',
  },
  //Users
  get_user_data: '/user-data',
  user: {
    filter: '/users',
    create: '/users',
    update: '/users/%id',
    delete: '/users/%id',
    show: 'users/%id',
    isDeletableAndEditable: '/users/actions/%id?action=all_actions',
    switchStatus: '/switch-status/entity/%id',
  },
  orders: {
    index: '/modules/sales/orders',
  },
  subscriptions: {
    index: 'modules/subscriptions',
  },
  plans: {
    index: 'modules/blockz/plans',
  },

  //Entities
  entities: {
    filter: '/entities',
    create: '/entities',
    update: '/entities/:id',
    delete: '/entities/:id',
    updatePermissions: '/entities/:id/permissions',
    updateRestrictions: '/entities/:id/properties/:propId/restrictions',
    updateProperty: '/property',
  },

  //Dropdowns
  dropdown: {
    filter: '/select-options',
    filterByModel: '/select-options/models/:model',
    dropdown: '/select-options/as-array/:id',
    create: '/select-options',
    update: '/select-options/:id',
    delete: '/select-options/:id',
  },

  //Tracker
  tracker: {
    stop: `modules/tracker/stop`,
    update: '/modules/tracker/:id',
    log: '/modules/tracker/log',
  },
  invoice: {
    index: '/modules/sales/invoices',
    payments: '/modules/payments',
    notes: 'modules/comments/store',
  },


}
