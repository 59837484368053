<template>
  <div class="container">
    <page-title :page-title="translate('Customer Groups', 'entities')" v-if="records" :overview="true"
                :border-bottom="true"
                :counter="records.meta.total">
        <template v-slot:actions>
          <router-link class="btn btn-primary rounded-pill" to="/webshop-customer-groups/create">
            {{ translate('Add new {entity}', 'entities', { entity: translate('Customer Group', 'entities').toLowerCase() }) }}
          </router-link>
        </template>
      </page-title>

    <div class="row" v-if="records">
      <div class="col-md-12">
        <div class="main-table mt-4">
          <div class="d-flex justify-content-between align-items-center table-header px-4">
            <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
              <div class="search-icon">
                <span><i class="fa fa-search"></i></span>
              </div>
              <input type="text" class="form-control h-100 search-field"
                     :placeholder="translate('Search', 'global')"
                     :value="userPreferences.search"
                     @input="e => setSearch(e.target.value)">
            </div>
            <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
              <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="records">
                  {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                  <i class="fa fa-chevron-down"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                  <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">
                    {{per_page.value}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center m-0">
              <thead class="thead-light">
              <tr>
                <th scope="col">{{ translate('Customer group', 'entities') }}</th>
                <th scope="col">{{ translate('Number of customers', 'entities') }}</th>
                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-1" v-for="(customerGroup, customerGroupI) in records.data" :key="customerGroupI">
                <td><router-link :to="`/webshop-customer-groups/update/${customerGroup.id}`" class="table">{{ customerGroup.name }}</router-link></td>
                <td>{{ customerGroup.customers_count }}</td>
                <td class="text-right">
                  <cta>
                    <action-button class-icon="fa-pen" @click.native="$router.push(`/webshop-customer-groups/update/${customerGroup.id}`)"></action-button>
                    <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(customerGroup.id)"></action-button>
                  </cta>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                      :value="records.meta" v-if="records && records.meta"
                      @goToPage="v => setProp('page', v)"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EntityIndexMixin from "../../../../mixins/EntityIndexMixin";

export default {
  name: "CustomerGroups",
  mixins: [EntityIndexMixin],
  props: {
    value: {
      required: true,
    },
  },
  async created() {
    this.entityType = 'customer-groups'
    await this.loadUserPreferences()
    this.loadData()
  },
}
</script>

<style scoped>

</style>
