import Vue from 'vue'

import store from '@/store'
import router from '@/router'
import {db, routes} from '@/utilities/database'
//import { environment } from './utilities/helper'
import { environment } from './modules/erp_entities/utilities/helper'

/**
 * Third party libraries
 */

import VuejsModal from 'vue-js-modal'
Vue.use(VuejsModal)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

// Page builder scss
import '@/modules/pagebuilder/assets/scss/all.scss'

import formatCurrency from 'currency-formatter'
Vue.prototype.$formatCurr = formatCurrency


/*
* Webcode packages
*/
import { erp_translations } from '@/modules/erp_translations'
erp_translations.initialize({ vue: Vue })

import { erp } from '@/modules/erp_framework'
erp.initialize({ vue: Vue, axiosInstance: db })

// import request_service from "@/modules/erp_framework/services/request_service";
// request_service.axiosInstance.defaults.headers.common['env'] = router.currentRoute.meta.interface
// console.log(router.currentRoute)
// console.log(router.currentRoute)

import FileManager from 'erp-laravel-file-manager'
Vue.use(FileManager, { store })

const fileManagerEventBus = new Vue()
Vue.prototype.$fileManager = fileManagerEventBus

/* not a package/plugin anymore
import ErpEntitiesModule from '@/modules/erp_entities'
Vue.use(ErpEntitiesModule, {
    request: db,
    store: store,
    environment,
    router,
    routes,
})
*/
environment.request = db
environment.store = store
environment.router = router
// end not a package/plugin anymore

/**
 * Register globally used components.
 * DO NOT ABUSE !!!!
 * If you have doubts for making component global, ask zgdevv
 */

import TransitionExpand from '@/modules/system/components/TransitionExpand'
Vue.component('transition-expand', TransitionExpand)


// TODO: remove it from here
import PageTitle from '@/modules/erp_entities/components/page/PageTitle'
Vue.component('page-title', PageTitle)

import CTA from '@/modules/erp_entities/components/page/table/CTA'
Vue.component('cta', CTA)

import ActionButton from '@/modules/erp_entities/components/page/table/ActionButtons'
Vue.component('action-button', ActionButton)
// End TODO

import vInfiniteProgressBar from "@/modules/system/components/utilities/vInfiniteProgressBar";
Vue.component('v-infinite-progressbar', vInfiniteProgressBar)

import Pagination from "@/modules/erp_entities/components/Pagination.vue"
Vue.component('pagination', Pagination)

/*
 * Pagebuilder
 */

import '@/modules/pagebuilder/registerGlobalComponents'