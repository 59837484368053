import { render, staticRenderFns } from "./createOrderExtensionIndex.vue?vue&type=template&id=13e22b8c&scoped=true&"
import script from "./createOrderExtensionIndex.vue?vue&type=script&lang=js&"
export * from "./createOrderExtensionIndex.vue?vue&type=script&lang=js&"
import style0 from "./createOrderExtensionIndex.vue?vue&type=style&index=0&id=13e22b8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e22b8c",
  null
  
)

export default component.exports