<template>
  <component
    :is="component"
    v-bind="$attrs"
    :draggable="true"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <slot></slot>
  </component>
</template>

<script>
import createNodeFromVNode from '../utils/createNodeFromVNode';

export default {
  inject: [
    'editor',
  ],
  props: {
    component: [Object, String],
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    if (!this.editor) {
      throw new Error('<Blueprint/> must be wrapped with <Editor/>.');
    }
  },
  mounted() {
    if (!this.$slots.blueprint) {
      throw new Error('v-slot:blueprint is required.');
    }
    if (this.$slots.blueprint.length !== 1) {
      throw new Error('v-slot:blueprint must to have only one root element.');
    }

    // console.log(this.$slots.blueprint[0])

    if (!createNodeFromVNode(this.editor, this.$slots.blueprint[0])) {
      throw new Error('The element in v-slot:blueprint is not a valid vue component.');
    }
  },
  methods: {
    handleDragStart(event) {
      if (this.disabled) {
        event.preventDefault()
        return
      }
      event.stopPropagation()

      const node = createNodeFromVNode(this.editor, this.$slots.blueprint[0])
      this.editor.dragNode(node)
      this.$emit('dragstart')
    },
    handleDragEnd(event) {
      this.editor.handleDragEnd()
    }
  },
};
</script>
