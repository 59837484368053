<template>
  <icon-picker
    :value="value"
    @input="canWrite ? $emit('input', $event) : null"
  />
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import IconPicker from "./components/IconPicker.vue"

export default {
  name: "IconPickerField",
  components: { IconPicker },
  mixins: [FormFieldMixin],
}
</script>

