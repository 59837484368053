<template>
  <div>
    <element-settings-content-tabs>

      <element-settings-content-tab :selected="true" :name="translate('Image', 'page-builder')">

        <div class="row">
          <div class="col-md-12">
            <craft-color-picker
              :label="'Background overlay color'"
              :value="getSetting('background_overlay_color')"
              @input="setSetting('background_overlay_color', $event);"
            />
          </div>
          <div class="col-md-12">
            <craft-image-picker
              :label="'Background image'"
              :value="getSetting('image')"
              @input="setSetting('image', $event)"
              :node="this.editor.selectedNode"
            />
          </div>

          <div class="col-12">
            <craft-input
              label="Height (leave empty for auto)"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getSetting('height', 'px')"
              @input="setSetting('height', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Background image filter"
              :options="imageFilterOptions"
              clearable
              :value="getSetting('image_filter')"
              @input="setSetting('image_filter', $event)"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Background image filter on hover"
              :options="imageFilterOptions"
              clearable
              :value="getSetting('image_filter_hover')"
              @input="setSetting('image_filter_hover', $event)"
            />
          </div>

        </div>

      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Content', 'page-builder')">

        <div class="row">
          <div class="col-12">
            <craft-text-editor
              :label="'Title'"
              :value="getSetting('title')"
              @input="setSetting('title', $event)"
            />
          </div>

          <div class="col-12">
            <craft-text-editor
              :label="'Description'"
              :value="getSetting('description')"
              @input="setSetting('description', $event)"
            />
          </div>

        </div>

      </element-settings-content-tab>

      <element-settings-content-tab :has-padding="false" :name="translate('Button', 'page-builder')">

        <element-settings-content-tabs>

          <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
            <div class="row">
              <div class="col-12">
                <craft-input
                  :label="'Button'"
                  :value="getSetting('button_text')"
                  @input="setSetting('button_text', $event)"
                />
              </div>

              <div class="col-12">
                <craft-input
                  :label="'Button Link'"
                  :value="getSetting('button_link')"
                  @input="setSetting('button_link', $event)"
                />
              </div>

              <div class="col-12">
                <craft-tab-pills
                  :label="'Link behavior'"
                  :value="getSetting('button_link_target')"
                  @input="setSetting('button_link_target', $event)"
                  :options="generalLinkTargetOptions"
                />
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="'Layout'">
            <div class="row">
              <div class="col-12">
                <craft-tab-pills
                  :label="'Button type'"
                  :value="getSetting('border_radius')"
                  @input="setSetting('border_radius', $event)"
                  :options="borderRadiusOptions"
                />
              </div>
              <div class="col-12">
                <craft-input
                  label="Button size"
                  input-type="range"
                  min="1"
                  max="100"
                  :value="getSetting('button_width', '%')"
                  @input="setSetting('button_width', $event, '%')"
                  suffix="%"
                />
              </div>
              <div class="col-12">
                <craft-tab-pills
                  :label="'Button alignment'"
                  :value="getSetting('button_margin')"
                  @input="setSetting('button_margin', $event)"
                  :options="buttonAlignmentOptions"
                />
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Styling', 'page-builder')" :has-padding="false">

            <element-settings-content-tabs>

              <element-settings-content-tab :selected="true" :name="translate('Background', 'page-builder')">
                <div class="row">
                  <div class="col-md-6">
                    <craft-color-picker
                      :label="'Background color'"
                      :value="getSetting('button_background_color')"
                      @input="setSetting('button_background_color', $event);"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                      :label="'Background hover color'"
                      :value="getSetting('button_background_hover_color')"
                      @input="setSetting('button_background_hover_color', $event)"
                    />
                  </div>
                </div>
              </element-settings-content-tab>

              <element-settings-content-tab :name="translate('Text', 'page-builder')">
                <div class="row">
                  <div class="col-md-6">
                    <craft-color-picker
                      :label="'Default text color'"
                      :value="getSetting('button_text_color')"
                      @input="setSetting('button_text_color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                      :label="'Hover text color'"
                      :value="getSetting('button_hover_text_color')"
                      @input="setSetting('button_hover_text_color', $event);"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input
                      :label="'Font size'"
                      :input-type="'number'"
                      :suffix="'PX'"
                      :value="getSetting('button_font_size')"
                      @input="setSetting('button_font_size', $event)"
                    />
                  </div>
                </div>
              </element-settings-content-tab>

              <element-settings-content-tab :name="translate('Border', 'page-builder')">
                <div class="row">
                  <div class="col-md-6">
                    <craft-select
                        :label="'Border style'"
                        :options="borderStyleOptions"
                        :value="getSetting('button_border_style')"
                        @input="setSetting('button_border_style', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input
                        :label="'Border width'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getSetting('button_border_width')"
                        @input="setSetting('button_border_width', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Border color'"
                        :value="getSetting('button_border_color')"
                        @input="setSetting('button_border_color', $event);"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Border hover color'"
                        :value="getSetting('border_hover_color')"
                        @input="setSetting('border_hover_color', $event);"
                    />
                  </div>
                </div>
              </element-settings-content-tab>

            </element-settings-content-tabs>

          </element-settings-content-tab>

        </element-settings-content-tabs>

      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Link', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
              :label="'Link'"
              :value="getSetting('link')"
              @input="setSetting('link', $event)"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
              :label="'Link behavior'"
              :value="getSetting('link_target')"
              @input="setSetting('link_target', $event)"
              :options="generalLinkTargetOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>


      <element-settings-content-tab :name="translate('Settings', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
              :label="'Content vertical alignment'"
              :value="getSetting('content_justify_content')"
              @input="setSetting('content_justify_content', $event)"
              :options="generalVerticalAlignmentOptions"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
              :label="'Content visibility'"
              :value="getSetting('show_content')"
              @input="setSetting('show_content', $event)"
              :options="visibilityOptions"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
              :label="'Background overlay visibility'"
              :value="getSetting('show_background_overlay')"
              @input="setSetting('show_background_overlay', $event)"
              :options="visibilityOptions"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Hover animation"
              :options="animationOptions"
              :value="getSetting('animation')"
              @input="setSetting('animation', $event)"
            />
          </div>

        </div>
      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import {
  CraftTabPills,
  CraftTextEditor,
  CraftSelect,
  CraftInput,
  CraftColorPicker,
  CraftImagePicker,
} from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: "BannerSettings",
  components: { CraftInput, CraftColorPicker, CraftImagePicker, CraftTabPills, CraftTextEditor, CraftSelect },
  mixins: [
    ComponentSettingsMixin,
    CraftSettingsMixin,
  ],
  inject: ['editor', 'node'],
  computed: {
    animationOptions(){
      return [
        { key: '', label: 'None' },
        { key: '1', label: 'Animation 1' },
        { key: '2', label: 'Animation 2' },
        { key: '3', label: 'Animation 3' },
      ]
    },
    buttonAlignmentOptions() {
      return [
        { key: '0 auto 0 0', label: 'Left' },
        { key: '0 auto 0 auto', label: 'Center' },
        { key: '0 0 0 auto', label: 'Right' },
      ]
    },

    visibilityOptions() {
      return [
        { key: 'always', label: 'Always visible' },
        { key: 'show_on_hover', label: 'Show on hover' },
        { key: 'hide_on_hover', label: 'Hide on hover' },
      ]
    },
    imageFilterOptions() {
      return [
        { key: 'none', label: 'None' },
        { key: 'blur', label: 'Blur' },
        { key: 'brightness', label: 'Brightness' },
        { key: 'contrast', label: 'Contrast' },
        { key: 'grayscale', label: 'Grayscale' },
        { key: 'opacity', label: 'Opacity' },
        { key: 'saturate', label: 'Saturate' },
        { key: 'sepia', label: 'Sepia' },
      ]
    },
  },
}
</script>