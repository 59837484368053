import { createEntityFormObjectNoTabs, generateSlug, hasManyTranslationLocales, suffixedField } from "@/utilities/helper"
import { v4 as uuidv4 } from 'uuid'

export default {
  data() {
    return {
      showSettings: '',
      duplicatedContent: {},
    }
  },
  methods: {
    async duplicateContent(content) {

      let copyOfContent = JSON.parse(JSON.stringify(content))

      if (this.entityType === 'inventory') {
        copyOfContent = await this.getDuplicatedProduct(copyOfContent)
      }

      copyOfContent.name = suffixedField(copyOfContent.name, ' duplicated')
      copyOfContent.url_key = suffixedField(copyOfContent.url_key, '-duplicated')

      this.duplicatedContent = copyOfContent
      this.$modal.show('duplicate_content')
    },
    async getDuplicatedProduct(content) {
      const res = await this.erp.ext.request.axiosInstance.get(`/modules/inventory/${content.id}`)

      let duplicatedProduct = { ...res.data.data, ...createEntityFormObjectNoTabs(res.data.data.options, 'key') }
      duplicatedProduct.name = content.name
      duplicatedProduct.url_key = content.url_key
      duplicatedProduct.sku = uuidv4()
      duplicatedProduct.slug = generateSlug()

      if (duplicatedProduct.variations && duplicatedProduct.variations.length) {
        duplicatedProduct.variations.forEach(variation => {
          variation.sku = uuidv4()
          delete variation.id
        })
      }

      return duplicatedProduct
    },
    async saveDuplicatedContent(v, callback, bindArgs=false) {
      let data = { ...v }

      const entity = this.entityType || this.builder.meta.entity

      try {
        await this.erp.ext.request.axiosInstance.post(`modules/${entity}`, { options: data })
        this.showSettings = ''
        if(bindArgs) {
          let locale = hasManyTranslationLocales() ? this.$store.state.system.translation_locale : false
          callback({ entity, locale })
        } else {
          callback()
        }

        this.$modal.hide('duplicate_content')
        this.$alert.success('Duplicated successfully!')
      } catch(error) {
        this.$alert.formattedError(error)
      }

    },
  },
}
