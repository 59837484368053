<template>
    <div >
        <div class="card">
            <div class="card-body project-showview">
                <tabs>
                    <tab name="sprint" :selected="true">
                        <sprint :statuses="statuses"></sprint>
                    </tab>
                    <tab name="chat">
                        <chat></chat>
                    </tab>
                    <tab name="budget">
                        <budget></budget>
                    </tab>
                    <tab name="calendar">
                        <calendar :tracks="tracks" :tasks="tasks" @logTime="logTime" @update="updateTraks"></calendar>
                    </tab>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { db, modules, routes } from "@/utilities/database";
import EntityMixin from '@/mixins/EntityMixin'
import TrackableMixin from '@/mixins/TrackableMixin'
import { mapState } from 'vuex'
import Sprint from "./Sprint";
import Chat from "./Chat";
import Budget from "./Budget";
import {createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs, searchArray} from "@/utilities/helper";
import Calendar from "./Calendar";
import moment from "moment";

export default {
    name:"Show",
    mixins: [TrackableMixin, EntityMixin],
    components: {
      Sprint,
      Chat,
      Budget,
      Calendar
    },
     async created() {
        db.get("modules/tasks", {
             params: {
                project_id: this.$router.currentRoute.params.id
            }
        })
        .then(res => {       
         searchArray(res.data.columns, 'name', 'status', 'data').forEach(el=>{           
          el["tasks"] = []; 
          el["show"] = true;
          this.statuses.push(el)
        })  
        
            res.data.data.forEach(rec => {
                let task={}
                task.options = createEntityFormObjectNoTabs(rec.options, "key");
                task.id=rec.id; 
                this.tasks.push(task)
                if(task.options.status) {           
                this.statuses.filter(status=>status.key==[task.options.status])[0].tasks.push(task)};       
            })            
        })
        .catch(err => {
            return Promise.resolve(null);
        });
        this.takeTracks();
    },
    
    data(){
        return {
            tasks: [],
            statuses: [],
            entity: null,
            tracks: {ready:[],
                    real:[],
                    users:["Allusers"],
                    issues:[]} ,
                    
        }
    },
    computed: {
        ...mapState('tracker', ['timeline'])
    },
    props: {
        type: {
            required: false,
            type: String,
            default: 'project'
        }
    },
    methods:{
        track(rec){
            let time;
            if(rec.worked_correction){
            time=Number(rec.worked_correction)
            }
            else{
            rec.worked ?  time=Number(rec.worked) : time=(Date.parse(rec.end)-Date.parse(rec.start))/1000; 
            }                    
            let day;
            rec.date_correction ? day=new Date(rec.date_correction) : day=new Date(rec.start);
            let track={}; 
            track.original=rec;                                       
            track.id=rec.id;                                    
            track.day=day.getDate();
            track.month=day.getMonth(); 
            track.year=day.getFullYear();                   
            track.date=  moment().year(track.year).month(track.month).date(track.day).format("DD/MMM/YY");
            track.calDate= day;
            track.newDate=null;
            track.user=`User${rec.created_by}`;
            if (!this.tracks.users.includes(track.user)){this.tracks.users.push(track.user)}                                      
            track.time=time; 
            track.nameIssue=rec.description.replace('Working on ','')
            track.description=rec.description;
            return track
        },
        logTime(res){
           
            this.tracks.real.find(({key})=>key==res.data.data.trackable_id).tracks.push(this.track(res.data.data))
        },
        updateTraks(res){                    
            let rec=res.data.data;        
            this.tracks.real.forEach((element)=>{
                element.tracks.forEach((el,ind)=>{
                    if(el.id==rec.id){
                    element.tracks.splice(ind,1)
                    }
                })               
            }) 
                        
            this.tracks.real.find(({key})=>key==rec.trackable_id).tracks.push(this.track(rec))
            
        },
        takeTracks(){
            db.get("modules/tracker/report?perpage=0")
            .then(res => { 
                this.tracks.ready=[];
                this.tracks.real=[];
                this.tracks.users=["Allusers"];
                this.tracks.issues=[];               
                res.data.data.forEach(rec=>{                              
                     let oldTrack=this.tracks.real.find(({key})=>key==rec.trackable_id);                
                                   
                    if(oldTrack==undefined){
                     let issue={};
                     issue.name=rec.description.replace('Working on ','')
                     this.tracks.issues.push({name:issue.name,id:rec.trackable_id})   
                     issue.key=rec.trackable_id;   
                     issue.tracks=[];
                     issue.tracks.push(this.track(rec))
                     this.tracks.real.push(issue)}
                     else{ 
                         
                       oldTrack.tracks.push(this.track(rec))  
                     }         
                     
                })         
            })
            .catch(err => {
                return Promise.resolve(null);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    // @import "../../assets/scss/default/utilities/_custom_tabs.scss";
</style>

