<template>
  <div class="container-fluid">
    <page-title :page-title="translate(entityType.charAt(0).toUpperCase() + entityType.slice(1), 'categories')">
      <template #actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          <span>{{ translate('Save changes', 'global') }}</span>
        </button>
      </template>
    </page-title>

    <div v-if="columns" class="row">
      <div class="col-md-10">
        <span class="font-weight-bold mb-3 d-block">
          <span v-if="method == 'post'">{{ translate('Add new {entity}', 'entities', { entity: entityTypeNameTranslated }) }}</span>
          <span v-else>{{ translate('Edit {entity}', 'entities', { entity: entityTypeNameTranslated }) }}</span>
        </span>
      </div>
      <div v-if="!selected" class="col-md-2 mb-3">
        <div class="d-flex justify-content-end align-items-center h-100 py-2">
          <div class="nav-item dropdown dropdown-styled dropdown-animate text-nowrap" data-toggle="hover">
            <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ translate('Sort', 'entities') }}
              <i class="fa fa-chevron-down"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
              <a v-for="(option, idx) in sortOptions" :key="idx" href="#" class="dropdown-item" @click="sortCategories(option.key)">
                {{ translate(option.value, 'entities') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <category-form
          :key="renderKey"
          v-model="data"
          :columns="columns"
          :entity="entity"
          :entity-type="entityType"
          :render-key="renderKey"
        />

        <div class="d-flex justify-content-end">
          <button v-if="method === 'put'" class="btn btn-white rounded-pill" @click="goBack">
            {{ translate('Go back', 'global') }}
          </button>
          <button class="btn btn-primary rounded-pill" @click="method === 'put' ? save() : create()">
            <span v-if="method === 'put'">{{ translate('Save changes', 'global') }}</span>
            <span v-else>{{ translate('Create', 'global') }}</span>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <template v-if="type && type.data">
          <div class="row-table">
            <div class="row bg-white">
              <div class="col-md-1">
                <!--
                  disabled for now as it does not work well
                  <div class="custom-control custom-checkbox">
                  <input
                  <input type="checkbox"
                  class="custom-control-input"
                  @click="selectAll()"
                  :id="`option-`"> <label class="custom-control-label" :for="`option-`"></label>
                  />
                  </div>
                -->
              </div>
              <div :class="isCmsCategory ? 'col-md-3' : 'col-md-6'">
                {{ translate('Title', 'categories') }}
              </div>
              <div v-if="isCmsCategory" class="col-md-3">
                {{ translate('Color', 'categories') }}
              </div>
              <div class="col-md-2">
                {{ translate('Count', 'categories') }}
              </div>
              <div class="col-md-3 text-right">
                <div
                  class="bulk-actions nav-item dropdown dropdown-animate actions-dropdown"
                  data-toggle="hover"
                >
                  <button
                    class="btn nav-link dropdown-toggle system-text-primary"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ translate('Bulk actions', 'global') }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-lg-0">
                    <div class="py-1 delimiter-bottom">
                      <button class="d-flex align-items-center dropdown-item" @click="deleteAll">
                        {{ translate('Delete', 'global') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nested-categories
            :value="nestedCategories"
            :entity-type="entityType"
            :entity="entity"
          />
        </template>
        <div v-else>
          <div v-if="nestedCategories.length === 0" class="p-3 text-center">
            <span class="font-weight-700">{{ translate('No data', 'categories') }}</span>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center p-3">
            <i class="fa fa-spinner fa-spin fa-2x" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: When you selected a category change route instead of putting data in variables.
// -> results using bus
import eventBus from '@/utilities/eventBus'
import { isVoidValue, translationLocales, generateSlug } from "@/utilities/helper"
import CategoryForm from "@/modules/cms/views/categories_and_tags/CategoryForm.vue"
import NestedCategories from "./NestedCategories.vue"

import pluralize from 'pluralize'

export default {
  name: "Create",
  components: {
    CategoryForm, NestedCategories,
  },
  data() {
    return {
      data: {},
      errors: {},
      type: null,
      columns: null,
      method: 'post',
      selected: null,
      renderKey: 0,
    }
  },
  computed: {
    translationLocales,
    isCmsCategory() {
      return this.entityType === 'categories' && this.entityFormatted !== 'inventories'
    },
    entity(){
      return this.$route.params.entity
    },
    entityName() {
      return this.entity.slice(0, -1).toPascalCase()
    },
    entityNamePluralTranslated(){
      return this.translate(pluralize(this.entityName), 'entities')
    },
    entityFormatted() {
      return this.entity === 'products' ? 'inventories' : this.entity
    },
    entityType(){
      return this.$route.params.type
    },
    entityTypeNameTranslated() {
      if(this.entityType === 'categories'){
        return this.translate('Category', 'entities')
      }
      return ''
    },
    nestedCategories(){
      return this.selected ? this.selected.children : this.type ? this.type.data : []
    },
    sortOptions() {
      return [
        { key: 'asc', value: 'Alphabetical (A-Z)' },
        { key: 'desc', value: 'Alphabetical (Z-A)' },
        { key: 'most_least_products', value: 'Most to Least Products' },
        { key: 'least_most_products', value: 'Least to Most Products' },
      ]
    },
    sortedCategories() {
      const sortedData = []

      const traverseAndSort = (category, order) => {
        sortedData.push({ parent_id: category?.parent_id, id: category.id, order })

        if (category.children && category.children.length) {
          category.children.forEach((child, index) => {
            traverseAndSort(child, index + 1)
          })
        }
      }

      this.type.data.forEach((category, index) => {
        traverseAndSort(category, index + 1)
      })

      return sortedData
    },
    sortFunctions() {
      return {
        'asc': (a, b) => this.entityLabel(a.options.name).localeCompare(this.entityLabel(b.options.name)),
        'desc': (a, b) => this.entityLabel(b.options.name).localeCompare(this.entityLabel(a.options.name)),
        'most_least_products': (a, b) => b.categoryable_count - a.categoryable_count,
        'least_most_products': (a, b) => a.categoryable_count - b.categoryable_count,
      }
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      async handler() {
        this.$store.state.system.isLoading = true
        await this.loadTypes()
        this.data = {}
        this.renderKey++
        this.$store.state.system.isLoading = false
      },
    },
    'nestedCategories': {
      deep: true,
      handler() {
        this.updateParentIds(
          this.selected ? this.selected.children : this.type ? this.type.data : [],
          this.selected ? this.selected.id : 0
        )
      },
    },
  },
  async created() {
    this.$store.state.system.isLoading = true
    eventBus.$on('edit', this.edit)
    eventBus.$on('del', this.del)
    await this.loadTypes()
    this.$store.state.system.isLoading = false
  },
  methods: {
    updateParentIds(categories, parentId) {
      categories.forEach(category => {
        category.parent_id = parentId

        if (category.children && category.children.length > 0) {
          this.updateParentIds(category.children, category.id)
        }
      })
    },
    goBack(){
      this.data = {}
      this.renderKey++
      this.selected = null
      this.method = 'post'
    },
    async del(type) {
      const confirmDelete = await this.$alert.confirmDelete({
        title: 'Are you sure you want to delete the category and all its subcategories? If you delete the category, they will also be removed from all {entityPlural}.',
        translateArgs: { entityPlural: this.entityNamePluralTranslated.toLowerCase() },
      })

      if(!confirmDelete.isConfirmed) return

      await this.erp.ext.request.axiosInstance.delete(`modules/categories/${type.id}`)
      await this.loadTypes()
      this.$toast.requestSuccess('delete', 'Category')
    },
    validateFields(){
      this.errors = {}

      if (JSON.stringify(this.data) === '{}' || !this.data.name) {
        this.errors.name = ['Title field is required']
      }

      if (JSON.stringify(this.data) === '{}' || !this.data.url_key) {
        this.errors.url_key = ['URL field is required']
      } else {
        let locales = Object.keys(this.translationLocales)
        for(let i=0; i < locales.length; i++){
          if(isVoidValue(this.data.url_key[locales[i]])){
            this.errors.url_key = ['URL field is required for all languages']
          }
        }
      }
    },

    async create() {
      this.$store.state.system.isLoading = true

      await this.validateFields()
      if(Object.keys(this.errors).length){
        this.$store.state.system.isLoading = false
        return this.$toast.info(Object.values(this.errors)[0][0]) // for now just show the first error
      }

      this.data.slug = generateSlug()
      this.data.website_id = this.$store.state.system.scope.value // always send the current website id

      let data = {
        options: { ...this.data },
      }

      data.options.type = this.entityType
      data.options.entity = this.entityFormatted

      await this.erp.ext.request.axiosInstance.post(`/modules/categories`, data)
      await this.loadTypes()
      this.data = {}
      this.$toast.requestSuccess('post', 'Category')
      this.$store.state.system.isLoading = false
    },
    async save() {
      try {
        this.$store.state.system.isLoading = true
        await this.erp.ext.request.axiosInstance.post(`modules/categories/order`, { data: this.sortedCategories })
        this.$store.state.system.isLoading = false

        if (this.method !== 'put') {
          this.$toast.requestSuccess('put', 'Categories')
        }
      } catch (err) {}

      if (this.method === 'put') {
        await this.updateCategory()
      }
    },
    async updateCategory() {
      this.$store.state.system.isLoading = true

      await this.validateFields()
      if(Object.keys(this.errors).length){
        this.$store.state.system.isLoading = false
        return this.$toast.info(Object.values(this.errors)[0][0]) // for now just show the first error
      }

      this.data.website_id = this.$store.state.system.scope.value // always send the current website id
      let data = {
        options: { ...this.data },
      }
      let id = data.options.id
      delete data.options.id
      delete data.options.items
      if (!data.options.parent_id) data.options.parent_id = 0 // hot fix, todo remove me after backend is made to work with null

      await this.erp.ext.request.axiosInstance.put(`/modules/categories/` + id, data)
      await this.loadTypes()
      this.goBack()
      this.$toast.requestSuccess('put', 'Category')
      this.$store.state.system.isLoading = false
    },
    selectAll() {
      if (this.type && this.type.data) {
        this.type.data.forEach(rec => {
          this.$set(rec, 'selected', !rec.selected)
        })
      }
    },
    async deleteAll() {
      if (this.type && this.type.data) {
        const confirmDelete = await this.$alert.confirmDelete()
        if(!confirmDelete.isConfirmed) return

        for (const val of this.type.data) {
          if (val.selected) {
            await this.erp.ext.request.axiosInstance.delete(`modules/categories/${val.id}`)
          }
        }

        this.$toast.requestSuccess('delete', 'Category')
        await this.loadTypes()
      }
    },
    edit(type) {
      this.selected = type
      if(type.options.parent_id === '0') {type.options.parent_id = null} // hot fix, todo make it null in the backend but not here

      let data = { ...type.options }
      data.id = type.id
      data.parent_id = type.parent_id

      // OLD LOGIC for root elements - this.data = options ? {...data} : {...type}
      this.data = { ...data }

      this.method = 'put'
      // this.loadType(type.id) seems unused #0001
    },
    async loadTypes() {
      const query = this.erp.ext.query()
        .where('entity', '=', this.entityFormatted)
        .where('type', '=', this.entityType)
        .set('perpage', '9999')

      this.type = await this.erp.ext.request.get( `/modules/categories/nested/categories`, query.toString())

      if (this.type && this.type.data.length) {
        this.type.data = await this.assignTypes(this.type.data)
      }

      if (this.type && this.type.columns) {
        this.columns = this.type.columns.reduce((acc, column) => {
          acc[column.name] = column
          return acc
        }, {})
      }
    },
    async assignTypes(response) {
      response.forEach(type => {
        type.options = type.options.toKeyValue('key')
        if (type.children && type.children.length) {
          this.assignTypes(type.children)
        }
      })
      return response
    },

    /* seems unused #0001
        async loadType(id) {
            let items = await
                this.erp.ext.request.get(
                    `/modules/${this.entityFormatted}`,
                    this.erp.ext.query().where('categories', '=', `${id}`).toString()
                )
            this.$set(this.data, 'items', items)
        },*/
    sortCategories(option) {
      const sortFunction = this.sortFunctions[option]

      if (!sortFunction) {
        this.loadTypes()
      } else {
        const sortCategory = category => {
          category.children?.sort(sortFunction)
          category.children?.forEach(sortCategory)
        }

        this.type.data?.sort(sortFunction)
        this.type.data?.forEach(sortCategory)
      }
    },

  },
}
</script>

<style lang="scss">
.nav-link-title {
  background: #fff;
  padding: 1rem;
  border: 1px solid #dedede;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  .handle {
    cursor: pointer;
  }
}
</style>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  border-bottom: 1px solid #f9f9f9;

  span {
    color: #000;
  }
}

.table-responsive.categories-table {
  border-radius: 5px;
  border: 1px solid #f1f5fb;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  overflow: hidden;

  thead {
    tr {
      th {
        border-top: none;
        border-bottom: 1px solid #e5edf5;
        border-color: #e5edf5;

        .bulk-actions {
          display: flex;
          justify-content: flex-end;

          .nav-link {
            border: 1px solid #e5edf5;
            border-radius: 5px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #e5edf5;
        border-color: #e5edf5;
      }
    }
  }

  .custom-checkbox {
    label:before {
      border-radius: 6px;
    }
  }
}
</style>
