
import Vue from 'vue'
import blank from '@/layout/layouts/Blank.vue'
import master from '@/layout/layouts/Master.vue'
import FileManagerModal from "@/components/FileManagerModal.vue"

import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    master, blank,
    FileManagerModal,
  },
  computed: {
    ...mapState('system', ['layout', 'theme', 'authData']),
    fileManagerKey(){
      let company = this.$store.state.system.company ? this.$store.state.system.company : 1
      return company + '_' + this.$store.state.system.fileManagerKey
    },
  },
})
