<template>
    <div class="container">
        <div class="row">
            <div class="col-md-1">
                <img class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
            </div>
            <div class="col-md-11">
                <div class="col-style">
                    <div id="tab-1" class="d-flex justify-content-between p-2">
                        <span>{{ translate('System notification', 'invoices') }}</span>
                        <span class="date">02 june 09:02</span>
                    </div>
                    <div class="d-flex justify-content-between p-2 m-3">
                        <span>{{ translate('The invoice with invoice number 25252 is expired today', 'invoices') }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <img class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
            </div>
            <div class="col-md-11">
                <div class="col-style">
                    <div id="tab-2" class="d-flex justify-content-between p-2">
                        <span>Jos Moes</span>
                        <span class="date">04 june 09:02</span>
                    </div>
                    <div class="d-flex justify-content-between p-2 m-3">
                        <span>{{ translate('Marked a payment of', 'invoices') }} $5,00</span>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <img class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
            </div>
            <div class="col-md-11">
                <div class="col-style">
                    <div id="tab-2" class="d-flex justify-content-between p-2">
                        <span>Jos Moes</span>
                        <span class="date">04 june 09:02</span>
                    </div>
                    <div class="d-flex justify-content-between p-2 m-3">
                        <span>{{ translate('Marked a payment of', 'invoices') }} $5,00</span>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <img class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
            </div>
            <div class="col-md-11">
                <div class="col-style">
                    <div id="tab-2" class="d-flex justify-content-between p-2">
                        <span>Jos Moes</span>
                        <span class="date">04 june 09:02</span>
                    </div>
                    <div class="d-flex justify-content-between p-2 m-3">
                        <span>{{ translate('Marked a payment of', 'invoices') }} $5,00</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style scoped>
    #tab-1, #tab-2 {
        border-bottom: 1px solid black;
    }

    .date {
        color: #cccccc;
    }

    .col-style {
        background-color: white;
        margin-bottom: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.88);
        border-radius: 5px;
    }
</style>