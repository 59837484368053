import _ from 'lodash'

/**
 * Applying sort properties to 'SET' function
 *
 * @type {{column: string, direction: string}}
 */
const refactoredProperties = {
  direction: 'sort.direction',
  column: 'sort.column',
}

/**
 * Query builder for magento/erp style filters
 * e.g. erp.query().filter('created_at', '>', '13-12-12').with(['children'])
 *
 * @export
 * @class httpQueryBuilder
 */
export default class httpQueryBuilder {
  constructor() {
    this.query = {
      page: 1,
      perpage: 20,
      sort: [],
      relations: [],
      filter_criteria: {
        filter_groups: [],
      },
    }
  }


  /**
   *  Add to query key / value / condition for filtering
   *
   * @param {String} key
   * @param {String} condition
   * @param {String} value
   * @param {String} [group=false]
   * @memberof httpQueryBuilder
   * @returns {httpQueryBuilder}
   */
  where(key, condition, value, group = false) {
    if(!group){
      group = Object.keys(this.query.filter_criteria.filter_groups).length
    }

    if (!this.query.filter_criteria.filter_groups[group]) {
      this.query.filter_criteria.filter_groups[group] = {}
      this.query.filter_criteria.filter_groups[group].filters = []
    }

    this.query.filter_criteria.filter_groups[group].filters.push({ key, condition, value })

    return this
  }

  /**
   *
   *
   * @param {String} prop
   * @param {String} value
   * @returns
   * @memberof httpQueryBuilder
   */
  set(prop, value) {
    refactoredProperties[prop] ? _.set(this.query, refactoredProperties[prop], value) : this.query[prop] = value
    return this
  }

  /**
   * Sort entity records by options or record properties
   *
   * @param {String} column
   * @param {String} direction
   * @returns
   * @memberof httpQueryBuilder
   */
  sort(column, direction) {
    this.query.sort = {
      column: column,
      direction: direction,
    }
    return this
  }


  /**
   * Pass relations you need from entity
   *
   * @param {*} relations
   * @memberof httpQueryBuilder
   * @returns {httpQueryBuilder}
   */
  with(relations = []) {
    typeof relations == 'string' ? this.query.relations.push(relations) : this.query.relations = [...this.query.relations, ...relations]
    return this
  }

  /***********************************
     *  MAGIC METHODOS
     ***********************************/

  /**
     *  String representation of query
     *
     * @returns {String}
     * @memberof httpQueryBuilder
     */
  toString() {
    return urlEncoder(this.query)
  }


  /**
     * Object representation of query
     *
     * @returns
     * @memberof httpQueryBuilder
     */
  toObject() {
    return this.query
  }

}

export function urlEncoder(obj, prefix) {
  var str = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p]
      str.push((v !== null && typeof v === "object") ?
        urlEncoder(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v))
    }
  }

  return str.join("&")
}
