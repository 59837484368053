export default function (node) {
  const iconBoxId = 'icon-box-' + node.uuid

  let styles = ''

  // todo remove me after the css is added in frontside
  styles += `.cms-block-icon-box .icon-box-content-wrapper {flex-grow: 1}`
  styles += `.cms-block-icon-box a:hover {text-decoration: none!important}`
  // END todo remove me after the css is added in frontside

  // Icon

  // Icon position
  if (node.props.settings['icon_position'] === 'left') {
    styles += `#${iconBoxId} {flex-direction: row}`
  } else if (node.props.settings['icon_position'] === 'right') {
    styles += `#${iconBoxId} {flex-direction: row-reverse}`
  } else if (node.props.settings['icon_position'] === 'top') {
    styles += `#${iconBoxId} {flex-direction: column}`

    // Icon alignment (only when icon_position = top)
    if (node.props.settings['icon_alignment'] === 'left') {
      styles += `#${iconBoxId} .icon-box-icon-wrapper {justify-content: start}`
    } else if (node.props.settings['icon_alignment'] === 'center') {
      styles += `#${iconBoxId} .icon-box-icon-wrapper {justify-content: center}`
    } else if (node.props.settings['icon_alignment'] === 'right') {
      styles += `#${iconBoxId} .icon-box-icon-wrapper {justify-content: right}`
    }
  }

  // icon_width
  if (node.props.settings['icon_width']) {
    styles += `#${iconBoxId} img.icon-box-icon {width: ${node.props.settings['icon_width']}}`
    styles += `#${iconBoxId} i.icon-box-icon {font-size: ${node.props.settings['icon_width']}}`
  }

  // icon_margin
  if (node.props.settings['icon_margin']) {
    if (node.props.settings['icon_position'] === 'left') {
      styles += `#${iconBoxId} .icon-box-icon {margin-right: ${node.props.settings['icon_margin']}}`
    } else if (node.props.settings['icon_position'] === 'right') {
      styles += `#${iconBoxId} .icon-box-icon {margin-left: ${node.props.settings['icon_margin']}}`
    } else if (node.props.settings['icon_position'] === 'top') {
      styles += `#${iconBoxId} .icon-box-icon {margin-bottom: ${node.props.settings['icon_margin']}}`
    }
  }

  // icon_color
  if (node.props.settings['icon_color']) {
    styles += `#${iconBoxId} i.icon-box-icon {color: ${node.props.settings['icon_color']}}`
  }

  // icon_background_color
  if (node.props.settings['icon_background_color']) {
    styles += `#${iconBoxId} .icon-box-icon {background-color: ${node.props.settings['icon_background_color']}}`
  }

  // icon_padding
  if (node.props.settings['icon_padding']) {
    styles += `#${iconBoxId} .icon-box-icon {padding: ${node.props.settings['icon_padding']}}`
  }

  // icon_border_radius
  if (node.props.settings['icon_border_radius']) {
    styles += `#${iconBoxId} .icon-box-icon {border-radius: ${node.props.settings['icon_border_radius']}}`
  }

  // Title

  // title_alignment
  // todo
  if (node.props.settings['title_alignment']) {
    styles += `#${iconBoxId} ${node.props.settings['title_font']}.icon-box-title {text-align: ${node.props.settings['title_alignment']}}`
  }

  // title_margin_bottom
  if (node.props.settings['title_margin_bottom']) {
    styles += `#${iconBoxId} ${node.props.settings['title_font']}.icon-box-title {margin-bottom: ${node.props.settings['title_margin_bottom']}}`
  }

  // title_font_size
  if (node.props.settings['title_font_size']) {
    styles += `#${iconBoxId} ${node.props.settings['title_font']}.icon-box-title {font-size: ${node.props.settings['title_font_size']}}`
  }

  // title_color
  if (node.props.settings['title_color']) {
    styles += `#${iconBoxId} ${node.props.settings['title_font']}.icon-box-title {color: ${node.props.settings['title_color']}}`
  }

  // Description

  // description_alignment
  // todo
  if (node.props.settings['description_alignment']) {
    styles += `#${iconBoxId} .icon-box-description {text-align: ${node.props.settings['description_alignment']}}`
  }

  // description_font_size
  if (node.props.settings['description_font_size']) {
    styles += `#${iconBoxId} .icon-box-description {font-size: ${node.props.settings['description_font_size']}}`
  }

  // description_color
  if (node.props.settings['description_color']) {
    styles += `#${iconBoxId} .icon-box-description {color: ${node.props.settings['description_color']}}`
  }

  // icon-box-description

  return styles
}