import debounce from 'debounce'
import { mapState } from "vuex"
import { createEntityFormObjectNoTabs, isVoidValue, hasManyTranslationLocales, IsJsonString } from "@/utilities/helper"
import axios from "axios"
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"

const requestMap = {}

export default {
  data() {
    return {
      entityType: false,
      loading: false,
      records: null,
      useUserPreferences: true,
      userPreferences: {
        columns: [],
        filters: {},
        filterProps: {},
        search: '',
      },
      relations: [],
      modifyData: true, // false used only on datatable-editable-field
      searchableColumns: [],
    }
  },
  methods: {
    async updateUserPreferences() {
      // skip update on entities that are not configured in the api
      if(['sales/quote-requests'].includes(this.entityType) || !this.useUserPreferences) return

      await this.erp.ext.request.axiosInstance.post(`/modules/entity/${this.entityType}/preferences`, {
        user_preferences: { ...this.userPreferences },
      })
    },

    setFilters(filters){
      this.userPreferences.filterProps['page'] = 1 // on changing any filter always start from first page
      this.userPreferences.filters = filters

      this.loadDataDebounced()
      this.updateUserPreferences()
    },

    setProp(key, v) {
      // on changing perpage start from first page
      if(key === 'perpage'){
        this.userPreferences.filterProps['page'] = 1
      }
      this.userPreferences.filterProps[key] = v

      this.loadDataDebounced()
      this.updateUserPreferences()
    },

    setSearch(value) {
      this.userPreferences.filterProps['page'] = 1 // on changing any filter always start from first page
      this.userPreferences.search = value

      this.loadDataDebounced()
      this.updateUserPreferences()
    },

    sortByColumn(column) {
      if (!this.userPreferences.filterProps.hasOwnProperty('sort')) {
        this.userPreferences.filterProps.sort = []
      }

      const sortableCol = this.userPreferences.filterProps.sort.filter(c => c.column === column)
      if (sortableCol.length) {
        if (isVoidValue(sortableCol[0].direction)) {
          sortableCol[0].direction = 'asc'
        } else if (sortableCol[0].direction == 'asc') {
          sortableCol[0].direction = 'desc'
        } else { // else unset
          this.userPreferences.filterProps.sort.splice(this.userPreferences.filterProps.sort.indexOf(sortableCol[0]), 1)
        }
      } else {
        this.userPreferences.filterProps.sort.push({ column: column, direction: 'asc' })
      }

      this.setProp('sort', this.userPreferences.filterProps.sort)
    },

    /*
     * User preferences
     */

    async loadUserPreferences(applyPreferences = true){ // todo rename method

      // todo cache or change the endpoint to get only the user preferences
      const filterQuery = this.erp.ext.query().set('perpage', '1')
      const { data } = await this.getRecords(filterQuery)

      if (applyPreferences) {
      if(typeof data.user_preferences !== 'undefined'){
        this.userPreferences.columns = data.user_preferences.columns || []
        this.userPreferences.filters = data.user_preferences.filters || {}
        this.userPreferences.filterProps = data.user_preferences.filterProps || {}
        this.userPreferences.search = data.user_preferences.search || ''
      }

      this.applyDefaultUserPreferences()
      }

      if (data?.columns) {
      this.searchableColumns = data.columns.reduce((acc, col) => {
        if (col.searchable == '1') {
          acc.push({ key: col.name, value: col.label })
        }

        return acc
      }, [])
      }
    },

    applyDefaultUserPreferences() {
      // Hot fix sort by default on newest first on some entities
      if(['invoices', 'sales/quote-requests'].includes(this.entityType)){
        if (!this.userPreferences.filterProps.hasOwnProperty('sort')) {
          this.userPreferences.filterProps.sort = []
        }
        this.userPreferences.filterProps.sort.push({ column: 'created_at', direction: 'desc' })
      }
    },

    applyFilters(filterQuery){
      Object.values(this.userPreferences.filters).forEach(filter => {

        // Custom filters
        // Products - sale status
        if(filter.prop === 'custom-filter-sale-status'){

          if (filter.value === 'on-sale') {
            const today = moment(moment.now()).format('YYYY-MM-DD HH:mm:ss')

            filterQuery.where('sale_price', '>', 0)
            filterQuery.where('sale_price_from', '<=', today)
            filterQuery.where('sale_price_utill', '>', today)
          } else if (filter.value === 'not-on-sale') {
            filterQuery.where('sale_price', '=', 0, filter.group)
          }

        // default
        } else {
          filterQuery.where(filter.prop, filter.compare, filter.value, filter.group)
        }

      })
    },

    applySearch(filterQuery){
      if(this.userPreferences.search){
        let properties = []

        if (this.searchableColumns.length) {
          properties = this.searchableColumns.map(col => col.key)
        } else {
          properties = ['name']

        // Form Entries
        if(this.entityType === 'form-entries'){
          properties = ['email']
        // Products
        } else if(this.entityType === 'inventory'){
          properties = ['name', 'sku']
        // Coupons
        } else if(this.entityType === 'coupons'){
          properties = ['code']
        // Orders
        } else if(this.entityType === 'orders'){
          properties = ['order_number', 'billing_client_first_name', 'billing_client_last_name', 'billing_client_email']
        // Request Quotes
        } else if(this.entityType === 'sales/quote-requests'){
          properties = ['quote_request_number']
        // Customers
        } else if(this.entityType === 'customers'){
          properties = ['first_name', 'last_name', 'email']
        // Citizens
        } else if (this.entityType === 'citizens') {
          properties = ['first_name', 'last_name', 'email']
        // Users
        } else if(this.entityType === 'users'){
          properties = ['first_name', 'last_name', 'email']
        // Shipping methods
        } else if(this.entityType === 'shipping/rates'){
          properties = ['courier']
        // Invoices
        } else if(this.entityType === 'invoices'){
          properties = ['invoice_number', 'billing_client_first_name', 'billing_client_last_name']
        }
        }

        properties.forEach(prop => {
          filterQuery.where(prop, 'like', `%${this.userPreferences.search}%`, 'group_search')
        })
      }
    },

    /*
     * Data
     */

    resetData(){
      this.records = null
      this.filtersActive = false
    },
    loadDataDebounced: debounce(async function () {
      // debounced moved as separate method
      // still not perfect, if you search on 2 datables in less than the wait time 700 the first request will not return data
      // but at least we can show 2 datatables
      this.loadData()
    }, 700),
    async loadData() {
      this.loading = true

      this.$store.state.system.isLoading = true
      // If there's already a request for this URL, cancel it
      if (requestMap[this.baseRoute]) {
        requestMap[this.baseRoute].abort()
      }

      let filterQuery = this.erp.ext.query()
      filterQuery.with(this.relations)

      this.applyFilters(filterQuery)
      this.applySearch(filterQuery)

      // filter data by locale
      if(this.shouldScopeByTranslationLocale) {
        const filters = {
          key: 'locale',
          condition: '=',
          value: this.$store.state.system.translation_locale,
        }

        filterQuery.where(filters.key, filters.condition, filters.value)
      }

      if(this.shouldScopeByUser) {
        const filters = {
          key: 'user_id',
          condition: '=',
          value: this.$store.state.system.authData.user_id,
        }

        filterQuery.where(filters.key, filters.condition, filters.value)
      }

      if (this.fieldProps?.data_filters?.length && this.dataFilters) {
        Object.values(this.fieldProps.data_filters).forEach(filter => {

          const condition = typeof this.dataFilters[filter].condition !== 'undefined'
            ? this.dataFilters[filter].condition
            : 'in' // used only in products select for now

          let conditionValue = this.dataFilters[filter].value
          if (condition === 'in' && !Array.isArray(conditionValue)) {
            conditionValue = [conditionValue]
          }

          filterQuery.where(filter, condition, conditionValue, this.dataFilters[filter].group)
        })
      }

      Object.entries(this.userPreferences.filterProps).forEach(([key, value]) => {
        filterQuery.set(key, value)
      })

      /* copied from DataTable, but it seems unused
      if (this.$route.query.filterBy) {
          let filterInQuery = JSON.parse(this.$route.query.filterBy)
          filterQuery.where(filterInQuery.key, '=', filterInQuery.value)
      }*/

      let response = await this.getRecords(filterQuery)

      // skip the rest if the request is being canceled in the meantime
      if(typeof response === 'undefined'){
        return
      }

      // just for reference this was in DataTable
      // this.settings.menu = response.data.settings_menu

      // if(this.entityType == 'inventory-categories'){
      //   returnData.filter = 'status'
      // }
      // END just for reference this was in DataTable

      this.records = await this.getModifiedRecords(response.data)
      this.$emit('dataLoaded')
      this.loading = false
      this.$store.state.system.isLoading = false
    },
    async getModifiedRecords(records) {
      // todo only append options on visible_in_table comlumns
      // records.data is raw data, records.data.objectData is modified data
      records.objectData = []

      records.data.forEach((record, i) => {
        if (records.data[i].options) {
          records.data[i].options = createEntityFormObjectNoTabs(records.data[i].options, 'key')
          records.data[i] = { ...records.data[i], ...records.data[i].options }
        }
      })

      // hot fix products column names and order
      if(this.entityType === 'inventory'){
        records.columns.forEach((record, i) => {
          if ([records.columns[i].label['en'], records.columns[i].label['nl']].includes('Product gallery')) {
            records.columns[i].label['nl'] = records.columns[i].label['en'] = 'Image'
          } else if ([records.columns[i].label['en'], records.columns[i].label['nl']].includes('Product price')) {
            records.columns[i].label['nl'] = records.columns[i].label['en'] = 'Price'
          } else if ([records.columns[i].label['en'], records.columns[i].label['nl']].includes('Product categories')) {
            records.columns[i].label['nl'] = records.columns[i].label['en'] = 'Categories'
          } else if ([records.columns[i].label['en'], records.columns[i].label['nl']].includes('Active on Websites')) {
            records.columns[i].label['nl'] = records.columns[i].label['en'] = 'Websites'
          }
        })

        // Order columns
        const imagesIndex = records.columns.findIndex(obj => obj.name === 'images')
        if (imagesIndex !== -1) {
          const imagesObject = records.columns.splice(imagesIndex, 1)[0] // Remove the object from its current position
          records.columns.unshift(imagesObject) // Place the object at the beginning of the array
        }
      }
      // END hot fix products column names and order

      if (!this.shouldModifyData) {
        records.objectData = records.data
        return records
      }

      const allAvailableColumns = records.columns.filter(column => parseInt(column.visible_in_table) === 1)

      const moduleTypes = {
        'custom': async obj => {
          let url = `select-options/as-array-by-slug/${obj.data_module}`

          // backward compatibility for dropdowns using id instead of slug
          if (!isNaN(Number(obj.data_module))) {
            url = `select-options/as-array/${obj.data_module}`
          }

          return await this.erp.ext.request.axiosInstance.get(url)
        },
        'moduleNames': async obj => {
          const result = await this.erp.ext.request.axiosInstance.get(`/modules/dropdowns/${obj.data_module}?perpage=9999`)
          return result.data
        },
        'treeselect': async obj => {
          return await this.erp.ext.request.axiosInstance.get(`/modules/treeselect/${obj.data_module}`)
        },
        'treeselectCategory': async obj => {
          return await this.erp.ext.request.axiosInstance.get(`/modules/dropdowns/Category`)
        },
      }

      const columnData = {}

      await Promise.all(allAvailableColumns.map(async column => {
        if (column.data_module) {
          const response = await moduleTypes[column.data_type](column)
          columnData[column.name] = response.data
        }
      }))

      const replaceableColumns = allAvailableColumns
        .filter(column => ['select', 'multiselect', 'treeselect'].includes(column.type))
        .map(column => column.name)

      const modifiedData = []

      // Apply default value #14991377
      const columnsWithDefaultValue = allAvailableColumns.reduce((acc, column) => {
        if (!isVoidValue(column.default_value)) {
          acc[column.name] = column.default_value
        }
        return acc
      }, {})

      // for each record
      records.data.forEach(record => {
        const modifiedRecord = cloneDeep(record)

        // Apply default value #14991377
        Object.keys(columnsWithDefaultValue).forEach(key => {
          if (isVoidValue(modifiedRecord[key])) {
            modifiedRecord[key] = columnsWithDefaultValue[key]
          }
        })

        // for each property(column)
        replaceableColumns.forEach(key => {
          if (!record.hasOwnProperty(key)) {
            return
          }

          let valuesToReplace = []
          if(Array.isArray(record[key])) {
            valuesToReplace = record[key]
          } else if (IsJsonString(record[key])) {
            valuesToReplace = !isNaN(record[key]) ? [record[key]] : JSON.parse(record[key])
          } else if(record[key] !== null) { // skip such results - [null]
            valuesToReplace = [record[key]]
          }

          modifiedRecord[key] = valuesToReplace.map(value => {
            const data = columnData[key]?.data || columnData[key]

            // hot fix roles on users, todo remove me once api return ids but not whole json
            if(key === 'roles' && typeof value === 'object'){
              value = value.id.toString()
            }
            // END hot fix

            if (data !== undefined) {
              const replacement = Object.values(data).find(entry => entry.key == value)
              return replacement ? replacement.value : value
            } else {
              return value
            }
          })

        })

        modifiedData.push(modifiedRecord)
      })

      records.objectData = modifiedData
      return records
    },
    async deleteRecord(id) {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.erp.ext.request.axiosInstance.delete(`${this.baseRoute}/${id}`).then(res => {
        this.loadData()
        this.$toast.requestSuccess('delete')
      }).catch(error => {
        this.loadData()
        this.$alert.error(error.message)
      })
    },
    selectAll(checked) {
      if (this.records && this.records.data) {
        this.records.data.forEach(rate => {
          if (checked) {
            this.$set(rate, 'selected', true)
          } else {
            this.$set(rate, 'selected', false)
          }
        })
      }
    },
    async bulkDelete() {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.records.data.filter(row => row.selected).forEach(item => {
        this.erp.ext.request.axiosInstance.delete(`${this.baseRoute}/${item.id}`)
          .then(res => {})
          .catch(error => {
            this.$alert.error(error.message)
          })
      })

      await this.loadData()
    },
    async exportData() {
      this.loading = true
      this.$store.state.system.isLoading = true

      const filterQuery = this.erp.ext.query().set('perpage', 9999)

      this.applyFilters(filterQuery)
      this.applySearch(filterQuery)

      const { data } = await this.getRecords(filterQuery)
      const modifiedRecords = await this.getModifiedRecords(data)

      this.loading = false
      this.$store.state.system.isLoading = false
      return modifiedRecords
    },
    async getRecords(filterQuery) {

      // Create a new AbortController instance
      const controller = new AbortController()
      const signal = controller.signal

      // Add the new instance to the request map
      requestMap[this.baseRoute] = controller

      return await this.erp.ext.request.axiosInstance.get(`${this.baseRoute}?${filterQuery.toString()}`, {
        signal: signal,
      }).catch(error => {
        if (error.response && error.response.status == 422) {
          this.$store.state.system.isLoading = false
          this.$alert.formattedError(error)
          this.$router.push('/')
        }
        if (error.response && error.response.status == 404) {
          this.$store.state.system.isLoading = false
          this.$router.push('/404')
        }
      })
    },
  },
  computed: {
    shouldModifyData(){
      // do not modify the data for these entities
      return this.modifyData &&
      !['attributes',
        'attribute-sets',
        'customer-groups',
        'payment-providers',
        'sales/quote-requests',
        'coupons'].includes(this.entityType)
    },
    baseRoute(){
      if(['customers', 'users'].includes(this.entityType)){
        return `/${this.entityType}`
      } else if(['invoices'].includes(this.entityType)){
        return `/modules/sales/${this.entityType}`
      } else if(['orders'].includes(this.entityType)){
        return `/modules/sales/${this.entityType}`
      } else {
        return `/modules/${this.entityType}`
      }
    },
    perPage() {
      return [
        { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
        { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
        { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
        { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
      ]
    },
    shouldScopeByTranslationLocale() {
      return ['cms-pages', 'cms-sections', 'blogs', 'portfolios'].includes(this.entityType)
    },
    shouldScopeByUser() {
      return ['cms-templates'].includes(this.entityType) && !this.$route.query['skip-user-scope']
    },
  },
}
