<template>
  <div class="col-xl-5 offset-xl-7 mt-3">
    <div class="main-table border-0">
      <div class="table-responsive rounded">
        <table class="table m-0 table-order-totals">
          <tbody>
            <tr>
              <td>
                <span class="font-weight-bold">{{ translate('Subtotal excl VAT', 'orders') }}</span>
              </td>
              <td>
                <span
                  class="font-weight-bold"
                  v-html="cartOverview?.subtotal_without_vat_formatted"
                />
              </td>
            </tr>

              <!--  TODO DISCOUNTS
              <tr>
                <td>
                  <span class="font-weight-bold">{{ translate('Discount excl VAT', 'orders') }}</span>
                </td>
                <td class="text-right">
                  <div class="dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                    <button class="btn-link text-decoration-none font-weight-bold">{{ translate('Add discount', 'orders') }}</button>
                    <div class="dropdown-menu dropdown-menu-md-right dropdown-menu-right discount-dropdown p-3">
                      <div>
                        <div class="mb-2">
                          <span class="font-weight-bold">{{ translate('Discount type', 'orders') }}</span>
                          <div class="d-flex my-2">
                            <div class="switch-field" v-for="(option, optionIndex) in discountOptions" :key="optionIndex" >
                              <input :id="`option-discount-${option.key}`" name="option-discount"
                                     type="radio" :value="option.key" v-model="discountType">
                              <label :for="`option-discount-${option.key}`">{{ translate(option.value, 'orders') }}</label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span class="font-weight-bold">{{ translate('Discount amount', 'orders') }}</span>
                          <input type="text" class="form-control my-2"/>
                        </div>
                      </div>
                      <div class="text-right">
                        <button class="btn btn-primary rounded-pill">{{ translate('Apply discount', 'orders') }}</button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              -->

            <tr>
              <td>
                <span class="font-weight-bold">{{ translate('Total VAT', 'orders') }}</span>
              </td>
              <td>
                <span
                  class="font-weight-bold"
                  v-html="cartOverview?.tax_amount_formatted"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold">{{ translate('Shipping costs', 'orders') }}</span>
              </td>
              <td>
                <span
                  class="font-weight-bold"
                  v-html="cartOverview?.shipping_amount_formatted"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold">{{ translate('Total', 'orders') }}</span>
              </td>
              <td>
                <span
                  class="font-weight-bold"
                  v-html="cartOverview?.total_amount_formatted"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="btn btn-primary rounded-pill w-100 my-2 py-2"
      @click="$emit('completeOrder')"
    >
      {{ translate('Create order', 'orders') }}
    </button>
  </div>
</template>

<script>
export default {
  name: "OrderOverview",
  props: {
    cartOverview: {
      required: true,
    },
  },
  computed: {
    /* TODO DISCOUNTS
    discountOptions() {
      return [
        { value: 'Price discount', key: 'price_discount' },
        { value: '% Discount', key: 'percentage_discount' },
      ]
    },
     */
  },
}
</script>

<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}

.discount-dropdown {
  width: 500px;
}
</style>