<template>
  <div>
    <page-title
      v-if="meta"
      :page-title="translate('Dropdowns', 'entities')"
      :counter="meta.total"
    >
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="$router.push('/dropdowns/create')">
          {{ translate('Add new {entity}', 'entities', { entity: translate('Dropdown', 'entities').toLowerCase() }) }}
        </button>
      </template>
    </page-title>

    <dropdowns-table @getData="getData" />
  </div>
</template>

<script>
import DropdownsForm from "./DropdownsForm.vue"
import DropdownsTable from "./DropdownsTable"
import { setPageTitle } from "@/utilities/helper"
import PageTitle from '../../components/page/PageTitle'

export default {
  name: "Dropdowns",
  components: {
    DropdownsTable, DropdownsForm, PageTitle,
  },
  data() {
    return {
      meta: null,
    }
  },
  computed: {

  },
  created() {
    setPageTitle('dropdowns')
  },
  methods: {
    getData(data) {
      this.meta = data.meta
    },
  },
}
</script>

<style>

</style>
