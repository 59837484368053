<template>
  <div class="d-flex">
    <small class="badge badge-secondary mr-3">{{ value }}{{ inputAppend }}</small>
    <input
      v-model="value"
      type="range"
      min="0"
      :max="max"
      :style="{'background': `linear-gradient(to right, #69a3f6 0px, #69a3f6 ${value}px, #ccc ${value}px, #ccc 500px)`}"
      class="system-custom-range"
      :disabled="isDisabled"
      @change="$emit('input', value)"
    >
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "RangeField",
  mixins: [FormFieldMixin],
  computed: {
    isDisabled() {
      return !this.canWrite
    },
    max() {
      return this.fieldProps.type === 'percentage' ? 100 : 500
    },
    inputAppend() {
      if(this.fieldProps.imput_append){
        return this.fieldProps.imput_append
      } else if (this.fieldProps.type === 'percentage') {
        return '%'
      } else {
        return 'px'
      }
    },
  },
}
</script>

