import settingMixin from "../craft/components/settingMixin"

export default {
  mixins: [settingMixin],
  computed: {
    elementClasses() {
      let classList = []
      if (this.elementProps && this.elementProps.elementClasses) {
        Object.entries(this.elementProps.elementClasses).forEach(([key, value]) => {
          if (value && value.length) {
            classList.push(value)
          }
        })
      }
      return classList.join(' ')
    },

    bindAttributes() {
      return {
        ...this.elementProps,
        'data-uuid': this.node.uuid,
        'style': this.elementProps.elementStyle,
        'id': this.elementProps.elementId,
      }
    },

    bindExportAttributes() {
      let props = {
        'component': this.elementProps.component,
      }

      if (this.elementProps.elementId) {
        props.id = this.elementProps.elementId
      }

      if (this.elementProps.elementStyle) {
        props.style = this.elementProps.elementStyle
      }

      // Text, Custom Html
      if (this.elementProps.content) {
        props.content = this.elementProps.content
      }

      // dynamic components
      if (this.elementProps.settings) {
        props['data-settings'] = JSON.stringify(this.elementProps.settings).replaceAll('"', "'")
      }

      if (this.elementProps.elementStyle && typeof this.elementProps.elementStyle === 'object') {
        props['data-uuid'] = this.node.uuid
      }

      if (this.elementProps.attributes) {
        Object.entries(this.elementProps.attributes).forEach(([key, value]) => props[key] = value)
      }

      return props
    },
  },
}
