<template>
  <!-- PLEASE NOTE THAT payment_provider_id can be the same for multiple payment methods -->
  <div class="col-style">
    <div class="col-style-child p-3">
      <div class="row align-items-center">
        <div class="col-md-5 system-text-primary font-weight-bold">
          {{ translate('Payment method', 'orders') }}
        </div>
        <div class="col-md-7">
          <v-select
            :options="value['payment_methods'] ? Object.values(value['payment_methods']) : []"
            label="name"
            :placeholder="translate('Choose payment method', orders)"
            @input="setPaymentMethods"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderPaymentMethods",
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    setPaymentMethods(e) {
      if (e) {
        this.value.payment_method = e['name']
        this.value.payment_provider_id = e['payment_provider_id']
      } else {
        this.value.payment_method = null
        this.value.payment_provider_id = null
      }
    },
  },
}
</script>
