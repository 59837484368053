import axios from "axios"

export default {
  axiosInstance: axios.create({
    headers: {
      // not needed for now
      //'internal': true,
    },
  }),
}
