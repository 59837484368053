<template>
	<div>
	  <element-settings-content-tabs>
        <element-settings-content-tab :selected="true" :name="translate('Add Slider', 'page-builder')">
          <craft-select
              :label="'Choose slider'"
              :options="sliderOptions"
              :value="getSetting('id')"
              @input="setSetting('id', $event)"
          />
        </element-settings-content-tab>
      </element-settings-content-tabs>
	</div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import {CraftSelect} from "@/modules/pagebuilder/components/craft-inputs";

export default {
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  inject: ['editor', 'node'],
  components:{
    CraftSelect,
  },
  data() {
    return {
      entity: 'sliders',
      sliders: [],
    }
  },
  computed: {
    sliderOptions() {
      return this.sliders.reduce((options, { name, id }) => {
        options.push({
          label: name,
          key: id
        })
        return options
      }, [])
    },
  },
  async created() {
    const sliders = await this.erp.ext.request.get(`/modules/${this.entity}`)
    this.sliders = sliders.objectData
  }
}
</script>
