<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <span class="add-new-item" @click.prevent="addItem(newItem)"><i class="fa icon-erp-add mr-2"></i>{{ translate('Add new accordion', 'page-builder') }}</span>

            <draggable
                v-model="items"
                @start="drag=true"
                @end="dragEnd"
                :draggable="'.draggable-item'">
              <div v-for="(item, itemIndex) in items" :key="itemIndex" class="card draggable-item">
                <h6 class="card-head d-flex align-items-center" @click="toggleVisibility(itemIndex)">
                  <i class="fa icon-erp-drag-and-drop"></i>

                  <span>{{ itemLabel(item, itemIndex) }}</span>
                  <i
                      class="fa ml-auto fa-chevron-down chevron"
                      :class="{'chevron-rotate': visible[itemIndex]}"
                  ></i>
                </h6>

                <transition-expand>
                  <div v-show="visible[itemIndex]">
                    <div class="card-body pt-0" :class="{'snippets-holder' : visible[itemIndex]}">
                      <div class="app-page-snippets d-flex flex-column">
                        <craft-input
                          :label="'Title'"
                          :value="item.title"
                          @input="e => {item.title = e}"
                        />

                        <craft-text-editor
                          :label="'Content'"
                          :value="item.content"
                          @input="e => {item.content = e}"
                        />

                        <craft-toggle
                            :label="'Put accordion open'"
                            :id="'collapsed-'+itemIndex"
                            :value="item.collapsed"
                            :value-on="0"
                            :value-off="1"
                            @input="item.collapsed = $event"
                        />

                        <button class="btn btn-secondary rounded-pill" @click.prevent="removeItem(itemIndex)"><i class="icon-erp-trash"></i>{{ translate('Remove this accordion', 'page-builder') }}</button>
                      </div>
                    </div>
                  </div>
                </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Layout', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
                :label="'Type'"
                :value="getSetting('type')"
                @input="setSetting('type', $event)"
                :options="roundTypeOptions"
            />
          </div>

          <div class="col-12">
            <craft-input
              label="Accordion size"
              input-type="range"
              min="1"
              max="100"
              :value="getSetting('width', '%')"
              @input="setSetting('width', $event, '%')"
              suffix="%"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
                :label="'Accordion alignment'"
                :value="getSetting('alignment')"
                @input="setSetting('alignment', $event)"
                :options="generalAlignmentOptions"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
              :label="'Title alignment'"
              :value="getSetting('title_alignment')"
              @input="setSetting('title_alignment', $event)"
              :options="generalAlignmentOptions"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
                :label="'Icon alignment'"
                :value="getSetting('icon_alignment')"
                @input="setSetting('icon_alignment', $event)"
                :options="iconAlignmentOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')">
        <div class="row">
          <div class="col-6">
            <craft-color-picker
                :label="'Background color'"
                :value="getSetting('background_color')"
                @input="setSetting('background_color', $event)"
            />
          </div>
          <div class="col-6">
            <craft-color-picker
                :label="'Text color'"
                :value="getSetting('color')"
                @input="setSetting('color', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <craft-input
                :label="'Title font size'"
                :input-type="'number'"
                :suffix="'PX'"
                :value="getSetting('font_size','px')"
                @input="setSetting('font_size', $event, 'px')"/>
          </div>
          <div class="col-md-6">
            <craft-select
                :label="'Border style'"
                :options="borderStyleOptions"
                :value="getSetting('border_style')"
                @input="setSetting('border_style', $event)"
                :clearable="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <craft-input
                :label="'Border width'"
                :input-type="'number'"
                :suffix="'PX'"
                :value="getSetting('border_width', 'px')"
                @input="setSetting('border_width', $event, 'px')"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Border color'"
                :value="getSetting('border_color')"
                @input="setSetting('border_color', $event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <craft-select
                :label="'Add icon'"
                :options="iconOptions"
                :clearable="true"
                :value="getSetting('icon')"
                @input="setSetting('icon', $event)"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Icon color'"
                :value="getSetting('icon_color')"
                @input="setSetting('icon_color', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
<!--    <div>-->
<!--        <div class="card-body">-->
<!--            <div v-for="(tabName, idx) in vTabsNames" :key="idx" class="item-settings-wrapper">-->
<!--                <div class="item-settings-icons-wrapper">-->
<!--                    <div class="drag-and-drop-icon-wrapper">-->
<!--                        <i class="fas fa-arrows-alt"></i>-->
<!--                    </div>-->
<!--                    <div class="settings-and-duplicate-icons-wrapper">-->
<!--                        <i class="fas fa-cog"></i>-->
<!--                        <i @click="() => duplicateTab(idx)" class="far fa-copy"></i>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="item-name-wrapper">-->
<!--                    <input type="text" :value="getTabName(idx)" @input="ev => updateTabName(idx, ev.target.value)">-->
<!--                </div>-->
<!--                <div class="tab-delete-icon-wrapper">-->
<!--                    <i @click="() => removeTab(idx)" class="far fa-trash-alt"></i>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div @click="appendNewTab" class="add-item-wrapper">-->
<!--                <i class="fas fa-2x fa-plus-circle"></i>-->
<!--                <p>{{ translate('Add new tab', 'page-builder') }}</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import ComponentWithItemsMixin from "@/modules/pagebuilder/mixins/ComponentWithItemsMixin";
import {CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftToggle, CraftTextEditor} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    name: 'AccordionSettings',
    mixins: [ComponentSettingsMixin, CraftSettingsMixin, ComponentWithItemsMixin],
    components: {
      CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftToggle, CraftTextEditor
    },
    computed: {
      newItem(){
        return {
          title: '',
          content: '',
          collapsed: 1,
          position: this.items.length
        }
      },
      iconAlignmentOptions(){
        return [
          {key: 'left', label: 'Left'},
          {key: 'right', label: 'Right'},
        ]
      },
      iconOptions() {
        return [
          {key: 'chevron', label: 'Chevron'},
          {key: 'chevron-circle', label: 'Chevron circle'},
          {key: 'arrow', label: 'Arrow'},
          {key: 'arrow-circle', label: 'Arrow circle'},
          {key: 'plus-minus', label: 'Plus/Minus'},
        ]
      },
    },
    methods: {
      itemLabel(item, itemIndex){
        if(item.title !== ''){
          return item.title
        } else {
          let number = itemIndex + 1
          return number < 2 ? "Accordion" : "Accordion " + number
        }
      },
    },
}
</script>