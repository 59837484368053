<template>
  <div class="dashboard-skitle container">
    <page-title :page-title="translate('Dashboard', 'dashboard')"></page-title>

    <template v-if="$store.state.system.theme === 'blockzapp'">

      <main-content :trial="trial" :steps="steps" :website="website" :plan="plan" :info="info"
                    :website-is-published="isWebsitePublished" @publish="publishWebsite"></main-content>

      <settings-boxes :info="info" :website="website" :is-website-published="isWebsitePublished" @publish="publishWebsite"></settings-boxes>

      <sitecart-settings-boxes :info="info" :website="website" :is-website-published="isWebsitePublished"></sitecart-settings-boxes>

      <published-webshop :info="info" :website="website" :is-website-published="isWebsitePublished"></published-webshop>

      <best-selling :is-website-published="isWebsitePublished"></best-selling>

      <loader v-if="loading"></loader>

      <help></help>

    </template>
  </div>
</template>

<script>
import WebsiteProgress from "../../../../mixins/WebsiteProgress";

import MainContent from "../../components/dashboard/MainContent";
import SettingsBoxes from "../../components/dashboard/SettingsBoxes";
import SitecartSettingsBoxes from "../../components/dashboard/SitecartSettingsBoxes";
import PublishedWebshop from "../../components/dashboard/PublishedWebshop";
import Loader from "../../components/utilities/Loader";
import HelpContainer from "../../components/dashboard/HelpContainer";
import BestSelling from "../../components/dashboard/BestSelling";

export default {
  name: "SitecartDashboard",
  mixins: [WebsiteProgress],
  components: {
    MainContent, Loader, SettingsBoxes, SitecartSettingsBoxes, PublishedWebshop, 'help': HelpContainer,
    BestSelling
  },
  async created() {
    if (this.$store.state.system.scope && this.$store.state.system.scope.value){
      await this.loadData()
    }
  },
}
</script>

<style lang="scss">
    @import "../../../../assets/scss/layout/dashboards";
</style>
