<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <span class="add-new-item" @click.prevent="addItem(newItem)"><i class="fa icon-erp-add mr-2"></i>{{ translate('Add new tab', 'page-builder') }}</span>

            <draggable
                v-model="items"
                @start="drag=true"
                @end="dragEnd"
                :draggable="'.draggable-item'">
              <div v-for="(item, itemIndex) in items" :key="itemIndex" class="card draggable-item">
                <h6 class="card-head d-flex align-items-center" @click="toggleVisibility(itemIndex)">
                  <i class="fa icon-erp-drag-and-drop"></i>

                  <span>{{ itemLabel(item, itemIndex) }}</span>
                  <i
                      class="fa ml-auto fa-chevron-down chevron"
                      :class="{'chevron-rotate': visible[itemIndex]}"
                  ></i>
                </h6>

                <transition-expand>
                  <div v-show="visible[itemIndex]">
                    <div class="card-body pt-0" :class="{'snippets-holder' : visible[itemIndex]}">
                      <div class="app-page-snippets d-flex flex-column">
                        <craft-input
                          :label="'Title'"
                          :value="item.title"
                          @input="e => {item.title = e}"
                        />

                        <craft-text-editor
                          :label="'Content'"
                          :value="item.content"
                          @input="e => {item.content = e}"
                        />

                        <craft-icon-picker
                            :label="'Add icon'"
                            :value="item.icon"
                            @input="$event => {item.icon = $event}"
                        />

                        <button class="btn btn-secondary rounded-pill" @click.prevent="removeItem(itemIndex)"><i class="icon-erp-trash"></i> {{ translate('Remove this tab', 'page-builder') }}</button>
                      </div>
                    </div>
                  </div>
                </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>
      <element-settings-content-tab :name="translate('Layout', 'page-builder')">
        <div class="row">
          <div class="col-12">

            <craft-tab-pills
                :label="'Layout'"
                :value="getSetting('orientation')"
                @input="setSetting('orientation', $event)"
                :options="generalOrientationOptions"
            />

            <craft-tab-pills
                :label="'Icon position'"
                :value="getSetting('icon_position')"
                @input="setSetting('icon_position', $event)"
                :options="iconPositionOptions"
            />

            <craft-tab-pills
                :label="'Tabs width'"
                :value="getSetting('tabs_width')"
                @input="setSetting('tabs_width', $event)"
                :options="tabsWidthOptions"
            />

            <craft-input
                :label="'Space between tabs'"
                :input-type="'range'"
                :suffix="'PX'"
                :value="getSetting('space_between_tabs', 'px')"
                @input="setSetting('space_between_tabs', $event, 'px')"
            />

            <craft-input
                :label="'Icon size'"
                :input-type="'range'"
                :suffix="'PX'"
                :min="1" :max="60"
                :value="getSetting('icon_size', 'px')"
                @input="setSetting('icon_size', $event, 'px')"
            />
            <craft-input
                :label="'Corners'"
                :input-type="'range'"
                :suffix="'PX'"
                :min="0" :max="60"
                :value="getSetting('corners', 'px')"
                @input="setSetting('corners', $event,'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Colors', 'page-builder')">
        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Tabs background color'"
                :value="getSetting('tab_background_color')"
                @input="setSetting('tab_background_color', $event)"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Tabs font color'"
                :value="getSetting('tab_font_color')"
                @input="setSetting('tab_font_color', $event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Tabs icon color'"
                :value="getSetting('tab_icon_color')"
                @input="setSetting('tab_icon_color', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Active tabs background color'"
                :value="getSetting('tab_active_background_color')"
                @input="setSetting('tab_active_background_color', $event)"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Active tabs font color'"
                :value="getSetting('tab_active_font_color')"
                @input="setSetting('tab_active_font_color', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Active tabs icon color'"
                :value="getSetting('tab_active_icon_color')"
                @input="setSetting('tab_active_icon_color', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Content background color'"
                :value="getSetting('content_background_color')"
                @input="setSetting('content_background_color', $event)"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Content font color'"
                :value="getSetting('content_font_color')"
                @input="setSetting('content_font_color', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>
      <element-settings-content-tab :name="translate('Fonts', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
                :label="'Title font'"
                :value="getSetting('title_font')"
                @input="setSetting('title_font', $event)"
                :options="generalFontOptions"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Title font size'"
                :input-type="'range'"
                :min="1" :max="100"
                :value="getSetting('title_font_size', 'px')"
                @input="setSetting('title_font_size', $event, 'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>
<script>

import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import ComponentWithItemsMixin from "@/modules/pagebuilder/mixins/ComponentWithItemsMixin";

import {CraftColorPicker, CraftIconPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextEditor} from "@/modules/pagebuilder/components/craft-inputs";

export default {
  name: "TabsSettings",
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, ComponentWithItemsMixin],
  inject: ['editor', 'node'],
  components: {
    CraftColorPicker, CraftIconPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextEditor
  },
  computed: {
    iconPositionOptions(){
      return [
        {key: 'left', label: 'Left'},
        {key: 'right', label: 'Right'},
        {key: 'top', label: 'Top'},
      ]
    },
    tabsWidthOptions(){
      return [
        {key: 'full', label: 'Full width'},
        {key: 'large', label: 'Large'},
        {key: 'medium', label: 'Medium'},
        {key: 'small', label: 'Small'}
      ]
    },
    newItem(){
      return {
        title: '',
        content: '',
        icon: '',
        upload_icon: false,
        position: this.items.length
      }
    }
  },
  methods: {
    itemLabel(item, itemIndex){
      if(item.title !== ''){
        return item.title
      } else {
        let number = itemIndex + 1
        return number < 2 ? "Tab" : "Tab " + number
      }
    },
  },
}
</script>