<template>
  <div class="row align-items-center">
    <div class="col-md-12">
      <div v-if="text_with_caution_editable" class="d-flex">
        <div class="w-100">
          <input
            id="text_with_caution"
            v-model="value"
            type="text"
            name="text_with_caution"
            :class="`form-control  ${errors.text_with_caution ? 'is-invalid' : ''}` "
            @keyup="checkUrl"
          />
          <small class="help text-danger" v-if="errors.text_with_caution" >{{ translate('This field is required', 'entities')}}</small>
        </div>
        <div class="ml-3">
          <button class="btn btn-primary rounded-pill" @click="saveUrl">{{ translate('Save', 'entities') }}</button>
        </div>
      </div>

      <div v-else class="d-flex justify-content-between">
        /{{value}}
        <button v-if="!isReadonly"
                class="btn-link"
                @click="setCautionToEditable">{{ translate('Edit', 'entities') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin";
import { checkIfUrlExists, getEntityModel } from "@/utilities/helper"

export default {
  name: "TextWithCautionField",
  mixins: [FormFieldMixin],
  data() {
    return {
      text_with_caution_editable: false,
      errors: {
        text_with_caution: false,
      },
      tempText: null,
    }
  },
  methods: {
    async saveUrl(){
      this.checkUrl()
      if(!this.errors.text_with_caution){
        if (this.tempText === this.value) { // In case the initial text was not changed
          this.text_with_caution_editable = false
          return
        }

        const model = getEntityModel(this.$store.state.system.currentEntity)
        const existingUrl = await checkIfUrlExists(this.value, model, this.fieldProps.id)
        if (!existingUrl.available) {
          this.$toast.info({
            title: `The entered {fieldLabel} is already in use. A unique {fieldLabel} has been generated.`,
            translateArgs: { fieldLabel: this.entityLabel(this.fieldProps.label) },
          })

          this.$emit('input', existingUrl.url_key)
        } else {
          this.$emit('input', this.value)
        }

        this.text_with_caution_editable = false
      }
    },
    checkUrl() {
      this.errors.text_with_caution = true
      if(this.value.replace(/\s/g,'').length) {
        this.errors.text_with_caution = false
      }
    },
    setCautionToEditable(){
      this.text_with_caution_editable = true
      this.tempText = JSON.parse(JSON.stringify(this.value))
    },
  },
}
</script>