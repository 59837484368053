<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab selected>
        <div class="row">
          <div class="col-12">
            <craft-select
              :options="layoutOptions"
              :value="getSetting('layout')"
              label="Choose layout"
              @input="setSetting('layout', $event)"
            />
          </div>

          <transition name="fade">

            <div class="col-12" v-if="['widget-with-comments', 'carousel'].includes(getSetting('layout'))">
              <craft-input
                :label="'How many items you want to show'"
                :input-type="'number'"
                :value="getSetting('number_of_posts')"
                @input="setSetting('number_of_posts', $event)"
              />
            </div>

          </transition>

          <transition name="fade">

            <div class="col-12" v-if="getSetting('layout') == 'widget-with-comments'">
              <label class="label mb-2">{{ 'Number of columns' | translate('categories') }}</label>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['desktop']"
                    :label="'Desktop'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_landscape']"
                    :label="'Tablet (landscape)'"
                    :options="columnOptions"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_portrait']"
                    :label="'Тablet (portrait)'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['mobile']"
                    :label="'Mobile'"
                    :options="columnOptions"
                  />
                </div>
              </div>
            </div>
          </transition>

        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import CraftInput from "@/modules/pagebuilder/components/craft-inputs/CraftInput.vue"
import CraftSelect from "@/modules/pagebuilder/components/craft-inputs/CraftSelect.vue"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"

export default {
  name: "GoogleReviewsSettings",
  components: {
    CraftInput,
    CraftSelect,
  },
  mixins: [CraftSettingsMixin],
  data() {
    return {
      columns: {},
    }
  },
  computed: {
    layoutOptions() {
      return [
        { key: 'widget', label: 'Widget' },
        { key: 'widget-with-comments', label: 'Widget with comments' },
        { key: 'carousel', label: 'Carousel' },
      ]
    },
    columnOptions() {
      return [
        { key: '12', label: '1' },
        { key: '6', label: '2' },
        { key: '4', label: '3' },
        { key: '3', label: '4' },
      ]
    },
  },
  mounted() {
    this.columns = this.getSetting('columns')
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        this.setSetting('columns', this.columns)
      },
    },
  },
}
</script>
