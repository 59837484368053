<template>
  <div v-if="data">
    <div class="row">
      <div class="col-6 mb-3">
        <label class="m-1">{{ translate('Width', 'entities') }}</label>
        <div class="input-group">
          <input
            v-model="data.width"
            type="number"
            class="form-control"
            :readonly="isReadonly"
          >
          <div class="input-group-append">
            <span class="input-group-text">{{ suffix }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label class="m-1">{{ translate('Height', 'entities') }}</label>
        <div class="input-group">
          <input
            v-model="data.height"
            type="number"
            class="form-control"
            :readonly="isReadonly"
          >
          <div class="input-group-append">
            <span class="input-group-text">{{ suffix }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label class="mb-1">{{ translate('Fill color', 'entities') }}</label>
        <ee-form-color-picker v-model="data.fill" />
      </div>
      <!-- Checkbox fields must have unique id, different label fixes the issue -->
      <div class="col-6 mb-3">
        <label class="mb-1">{{ translate('Crop', 'entities') }}</label>
        <checkbox-field
          v-model="data.crop"
          :field-props="{ ...fieldProps, label: 'Crop'}"
        />
      </div>
      <div class="col-6 mb-3">
        <label class="mb-1">{{ translate('Keep proportions', 'entities') }}</label>
        <checkbox-field
          v-model="data.keep_proportion"
          :field-props="{ ...fieldProps, label: 'Keep proportions'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxField from "@/modules/erp_entities/components/page/form/fields/CheckboxField.vue"
import { EeFormColorPicker } from "@/modules/erp_entities/components/forms"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "ImageSettingsField",
  mixins: [FormFieldMixin],
  components: { CheckboxField, EeFormColorPicker },
  data() {
    return {
      data: {
        name: '',
        width: '',
        height: '',
        fill: '',
        crop: '',
      },
      suffix: 'PX',
    }
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$emit('input', JSON.stringify(this.data))
      },
    },
  },
  created() {
    if (this.value && typeof this.value == 'string') {
      this.data = JSON.parse(this.value)
    }
  },
}
</script>

<style scoped>

</style>