<template>
  <div class="container app-entities-settings">
    <page-title :page-title="translate('General settings', 'settings')">
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div class="row" v-if="website">

      <div class="col-md-12">

        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.general = !visible.general">
            <h5>{{ translate('General settings', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.general === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.general" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Website', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <div class="w-100">
                        <input @keyup="checkName"
                               class="form-control"
                               name="name"
                               @input="(v) => website.set('name', v.target.value)"
                               :value="website._data.name"
                        />
                        <small class="help text-danger" v-if="errors.name" >{{ translate('This field is required', 'settings')}}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Description', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                          class="settings-input form-control"
                          @input="(v) => website.set('description', v.target.value)"
                          :value="website._data.description"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Domain', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2" v-if="url_editable">
                      <div class="w-100">
                        <input @keyup="checkUrl" type="text" name="url" id="url" :class="`settings-input form-control ${errors.url ? 'is-invalid' : ''}` " v-model="website._data.url">
                        <small class="help text-danger" v-if="errors.url" >{{ translate('This field is required', 'settings') }}</small>
                      </div>
                      <div class="ml-3">
                        <button class="btn btn-primary rounded-pill" v-on:click="saveUrl">{{ translate('Save changes', 'global') }}</button>
                      </div>

                    </div>
                    <div class="d-flex justify-content-between align-items-center" v-else>
                      <span>{{website._data.url}}</span>
                      <button class="btn-link" v-on:click="url_editable=true">{{ translate('Edit', 'settings') }}</button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 border-bottom">
                  <div class="form-group">
                    <label>{{ translate('Enable indexing by search engines', 'settings')}}</label>
                    <div class="d-flex justify-content-between py-2">
                      <div class="custom-control custom-switch">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="enable"
                            value="true"
                        />
                        <label class="custom-control-label" for="enable"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <entity-settings-form
                :value="general_settings"
                :defaultInputSize="12"
              />
            </div>
          </transition-expand>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-primary rounded-pill" @click="save">
        <span>{{ translate('Save changes', 'global') }}</span>
      </button>
    </div>

  </div>
</template>

<script>
import ErpModel from "@/modules/erp_framework/services/ErpModel"
import EntitySettingsForm from "@/modules/erp_entities/components/page/form/EntitySettingsForm.vue"
import { createEntityFormObjectNoTabs } from "@/utilities/helper"

export default {
	name: "SettingsWebsiteGeneral",
  components: {
    EntitySettingsForm,
  },
  // not needed anymore I guess
  // props: {
  // 	value: {
  // 		required: true
  // 	}
  // },
  data() {
    return {
      website: null,
      visible: {
        general: true,
      },
      url_editable: false,
      errors: {
        url: false,
        name: false,
      },
      general_settings: [],
    }
  },
  methods: {
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },

    async getGeneralSettings(website_id){
      this.$store.state.system.isLoading = true;
      const resEntitiesResponse = await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${website_id}/general/general_settings`).catch(() => false);
      if(resEntitiesResponse) {
        for (const prop of resEntitiesResponse.data.inputs) {
          let recordProp = resEntitiesResponse.data.data.filter(rec => rec.key == prop.name)
          if (recordProp) {
            let propIndex = resEntitiesResponse.data.data.indexOf(recordProp[0])
            this.general_settings.push({...prop, ...resEntitiesResponse.data.data[propIndex]})
          } else {
            this.general_settings.push({...prop})
          }
        }
        this.$store.state.system.isLoading = false
      }
    },

    async saveGeneralSettings(website_id) {

      let data = {
        options: createEntityFormObjectNoTabs(this.general_settings, 'name', 'value', false),
        model: 'cms-websites',
        modelId: website_id,
        settingsSlug: 'general',
      }

      this.erp.ext.request.axiosInstance.post(`/modules/settings/cms-websites/${website_id}`, data).then(response => {

      }).catch(error => {
        console.log(error)
      })
    },

    async save() {
      this.$store.state.system.isLoading = true

      // validation
      this.errors.url = false
      this.errors.name = false
      this.checkUrl()
      this.checkName()
      if (this.errors.url || this.errors.name) {
        this.$store.state.system.isLoading = false
        return false
      }
      // end validation

      try {
        await this.website.save()

        await this.saveGeneralSettings(this.website.data.id)

        this.$toast.requestSuccess(false, 'General settings')
        this.$store.state.system.scope.obj.name = this.website._data.name
        this.$store.state.system.scope.obj.url = this.website._data.url
      } catch (error) {
        this.$alert.formattedError(error)
      }
      this.$store.state.system.isLoading = false
    },
    saveUrl() {
      this.checkUrl()
      if (!this.errors.url) {
        this.url_editable = false
      }
    },
    checkUrl() {
      this.errors.url = true
      if (this.website._data.url.replace(/\s/g, '').length) {
        this.errors.url = false
      }
    },
    checkName() {
      this.errors.name = true
      if (this.website._data.name.replace(/\s/g, '').length) {
        this.errors.name = false
      }
    },
  },
  async created() {
    await this.findWebsite()
    await this.getGeneralSettings(this.website.data.id)
    this.$emit('reload-site')
  },
}
</script>