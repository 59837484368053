<template>
    <div class="container">
        <div class="row" v-if="subscription && !loading">
            <div class="col-md-12">
                <page-title :page-title="translate(`Subscription ${subscription.id} - ${subscription.plan.readable_options.name}`, 'subscriptions')"></page-title>
            </div>
            <div class="col-md-12">
                <div id="totals" class="col-style">
                    <div id="first_field" class="d-flex justify-content-between align-items-center p-4">
                        <div class="col-md-4">
                            <div class="d-flex align-items-center flex-column my-5">
                                <span>{{ translate('Active subscription', 'subscriptions') }}</span>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex align-items-center flex-column">
                                <span>{{ translate('Totals', 'subscriptions') }}</span>
                                <span style="font-size: 50px;">{{ subscription.price }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex align-items-center flex-column my-5">
                                <span>{{ translate('Auto renewal', 'subscriptions') }}</span>
                                <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-style mt-3">
                    <div class="card m-0">
                        <div class="d-flex flex-column text-center p-2">
                            <span class="system-text-primary text-uppercase second-color" style="font-size: 12px">
								{{ subscription.plan.readable_options.name }}
							</span>
                            <span class="d-flex align-items-center justify-content-center">
								€<span style="font-size: 35px;">{{ month_payment }}</span>
							</span>
                            <span class="system-text-primary second-color text-uppercase" style="font-size: 12px">
								{{ translate('month', 'subscriptions') }}</span>
                        </div>
                        <div id="plan" class="card-body">
                            <div class="d-flex flex-column">
<!--                                <div style="border-bottom: 1px solid black;"-->
<!--                                     class="d-flex justify-content-between py-2">-->
<!--                                    <span>Unlimited activity</span>-->
<!--                                    <i style="color: green;" class="fa fa-check-circle" aria-hidden="true"></i>-->
<!--                                </div>-->
                                <div style="border-bottom: 1px solid black;" class="d-flex justify-content-between py-2" v-for="(lim, l) in subscription.plan.limits">
                                    <span>{{ lim.readable_options.name }}</span>
                                    <span>{{ lim.pivot.qty }}</span>
                                </div>
                                <button @click="$modal.show('contact_sales')"
										type="button"
										class="btn btn-primary rounded-pill mt-4">
									{{ translate('upgrade or renew', 'subscriptions') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex flex-column">
                    <div class="col-style mt-3 mb-2">
                        <div class="d-flex justify-content-between p-2">
                            <div class="d-flex flex-column">
                                <span style="font-size: 12px;" class="system-text-primary second-color">
									{{ translate('Seats', 'subscriptions') }}
								</span>
                                <span>4 {{ translate('subscriptions.out_of') }} 6</span>
                                <span style="font-size: 10px;" class="system-text-primary font-italic">
									{{ translate('extra seat per user', 'subscriptions') }}
								</span>
                            </div>
                            <button @click="$modal.show('contact_sales')"
									style="height: 100%;" type="button"
									class="btn btn-light rounded-pill m-0">
								{{translate('Upgrade', subscriptions)}}</button>
                        </div>
                    </div>
                    <div class="col-style mb-2">
                        <div class="d-flex justify-content-between p-2">
                            <div class="d-flex flex-column">
                                <span style="font-size: 12px;" class="system-text-primary second-color">
									{{ translate('Disk used', 'subscriptions') }}
								</span>
                                <div class="d-flex flex-row align-items-center">
                                    <label class="mr-1" for="progress">84.5%</label>
                                    <progress-bar
                                      :value="84.5"
                                      :total="100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-style mb-2">
                        <div class="d-flex justify-content-between p-2">
                            <div class="d-flex flex-column">
                                <span style="font-size: 12px;" class="system-text-primary second-color">
									{{ translate('Components used', 'subscriptions') }}
								</span>
                                <span class="mb-2">300 {{ translate('out of', 'subscriptions') }} 1000</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-style mb-2">
                        <div class="d-flex justify-content-between p-2">
                            <div class="d-flex flex-column">
                                <span style="font-size: 12px;" class="system-text-primary second-color">
									{{ translate('Components used', 'subscriptions') }}
								</span>
                                <span class="mb-2">300 {{ translate('out of', 'subscriptions') }} 1000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-style mt-3">
                    <div class="card m-0">
                        <div class="d-flex flex-column text-center p-2">
                            <div class="text-center">
                                <img class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
                            </div>
                            <span v-if="customer" style="font-size: 35px;">
								{{ customer.first_name }} {{ customer.last_name }}</span>
                            <span v-if="customer" class="system-text-primary">({{ translate('customer number', 'subscriptions') }}: {{ customer.id }})</span>
                        </div>
                        <div id="pay" class="card-body">
                            <div class="d-flex flex-column">
                                <div style="border-bottom: 1px solid black; border-top: 1px solid black;"
                                     class="d-flex justify-content-between py-2">
                                    <span>{{ translate('Last order number', 'subscriptions') }}</span>
                                    <span v-if="customer" class="pink text-right">
										{{ subscription.orders.slice(-1)[0].order_number }}</span>
                                </div>
                                <div style="border-bottom: 1px solid black;"
                                     class="d-flex justify-content-between py-2">
                                    <span>{{ translate('Last payment', 'subscriptions') }}</span>
                                    <span class="pink text-right">-</span>
                                </div>
                                <div style="border-bottom: 1px solid black;"
                                     class="d-flex justify-content-between py-2">
                                    <span>{{ translate('Next payment', 'subscriptions') }}</span>
                                    <span class="pink text-right">-</span>
                                </div>
                                <div style="border-bottom: 1px solid black;"
                                     class="d-flex justify-content-between py-2">
                                    <span>{{ translate('Payment method', 'subscriptions') }}</span>
                                    <span class="pink text-right">-</span>
                                </div>
                                <div style="border-bottom: 1px solid black;"
                                     class="d-flex justify-content-between py-2">
                                    <span>{{ translate('Contract expire', 'subscriptions') }}</span>
                                    <span class="pink text-right">{{ subscription.end_date }}({{ period }} {{ translate('contract', 'subscriptions') }})</span>
                                </div>
                                <div class="d-flex justify-content-between pt-2">
                                    <span>{{ translate('Paid per', 'subscriptions') }}</span>
                                    <span class="pink text-right">{{ translate('Month contract', 'subscriptions') }} ({{ period }} {{ translate('contract', 'subscriptions') }})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="col-md-12">
				<div class="col-style mt-3">
					<div class="table-responsive">
						<div class="card m-0">
							<div class="card_border d-flex justify-content-start p-3">
								<span class="font-weight-bold">{{ translate('Modules', 'subscriptions') }}</span>
							</div>
							<div class="card-body">
                <template v-for="(app, a) in subscription.plan.apps">
                  <div v-if="app && app.inventory_type === 'module'" class="d-flex flex-column plan-app py-2">
                    <div class="d-flex blog justify-content-between">
                      <div>
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ app.readable_options.name }}</span>
                          <span class="system-text-primary second-color">
                            {{ translate('We only notify you for significant promotions', 'subscriptions') }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">€ {{ app.month_payment }}</span>
                          <span class="system-text-primary second-color">
                            {{ translate('per month', 'subscriptions') }}
                          </span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <label class="switch_red">
                          <input type="checkbox" checked>
                          <span class="slider_red round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div class="col-md-12">
                <div class="col-style mt-3">
                    <div class="table-responsive">
                        <div class="card m-0">
                            <div class="card_border d-flex justify-content-start p-3">
                                <span class="font-weight-bold">{{ translate('Appstore extensions', 'subscriptions') }}</span>
                            </div>
                            <div class="card-body">
                              <template v-for="(app, a) in subscription.plan.apps">
                                <div v-if="app.inventory_type === 'extension'" class="d-flex flex-column plan-app py-2">
                                  <div class="blog d-flex justify-content-between">
                                      <div>
                                        <div class="d-flex flex-column">
                                          <span class="font-weight-bold">{{ app.readable_options.name }}</span>
                                          <span class="system-text-primary second-color">
                                          {{ translate('We only notify you for significant promotions', 'subscriptions') }}
                                        </span>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="d-flex flex-column">
                                          <span class="font-weight-bold">€ {{ app.month_payment }}</span>
                                          <span class="system-text-primary second-color">
                                          {{ translate('per month', 'subscriptions') }}
                                        </span>
                                        </div>
                                      </div>
                                      <div class="d-flex justify-content-end align-items-center">
                                        <label class="switch_red">
                                          <input type="checkbox" checked>
                                          <span class="slider_red round"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                              </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<modal name="contact_sales"
			   :pivot-x="0.5"
			   :pivot-y="0.5"
			   height="auto"
			   width="500px"
			   transition="slide-top"
			   overlayTransition="wait"
			   classes="remove-modal-height">
			<div class="bg-white py-5 px-3 text-center" style="border-radius: 20px;">
				<img src="/img/menu-icons/seller.svg" height="200px" alt="">
				<hr>
				<h3 class="text-center mt-3">{{ translate('Questions?', 'subscriptions') }}</h3>
				<p>{{ translate('Please contact our sales person!', 'subscriptions') }}</p>
				<h6 class="font-weight-700 mb-3">{{ '+31  (0) 85 902 1583' }}</h6>
<!--				<button class="btn btn-primary rounded-pill" @click="$router.push('/modules')">Choose your plan!</button>-->
			</div>
		</modal>
        <div v-if="loading" class="d-flex justify-content-center m-5">
            <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
        </div>
    </div>
</template>
<script>
import {db} from "@/utilities/database";
import EntityMixin from '@/mixins/EntityMixin'
import {createEntityFormObjectNoTabs} from "@/utilities/helper";
import moment from 'moment'

export default {
    name: "SubscriptionShowview",
    data() {
        return {
            subscription: null,
            loading: false,
            month_payment: 0,
            extensions: null,
            customer: null,
            period: null
        }
    },
    methods: {
        loadSubscription() {
            this.loading = true
            db.get(`/modules/subscriptions/${this.$route.params.id}`).then(res => {
                this.subscription = res.data.data
                this.subscription.plan.readable_options = createEntityFormObjectNoTabs(this.subscription.plan.options, 'key')
                this.subscription.plan.apps.forEach(app => {
                    app.readable_options = createEntityFormObjectNoTabs(app.options, 'key')
                    app.month_payment = parseFloat(app.price.price / app.readable_options.period).toFixed(2)
                })
                this.subscription.plan.limits.forEach(lim => {
                    lim.readable_options = createEntityFormObjectNoTabs(lim.options, 'key')
                })
                this.customer = this.subscription.orders[0].client.contacts[0]
                this.subscription.end_date = moment(this.subscription.contract_end).format('DD MMM YYYY')
                this.calcMonthPrice()
                this.loading = false
            })
        },
        calcMonthPrice() {
            let period = this.subscription.plan.readable_options.period
            let price = this.subscription.price
            if (period === 1) {
                this.month_payment = price
                this.period = 'Month'
            } if (period === 12) {
                this.month_payment = parseFloat(price / 12).toFixed(2)
                this.period = '1 year'
            } if (period === 24) {
                this.month_payment = parseFloat(price / 24).toFixed(2)
                this.period = '2 year'
            } if (period === 36) {
                this.month_payment = parseFloat(price / 36).toFixed(2)
                this.period = '3 year'
            }
        }
    },
    mounted() {
        this.loadSubscription()
    }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 767px) {
    #first_field {
        flex-direction: column;
    }
}

@media screen and (max-width: 1230px) {
    #upgrade_plan {
        font-size: 13px;
    }
}

/* @media screen and (max-width: 1174px) {
    #upgrade_plan {
        font-size: 12px;
    }
} */

#totals {
    background-color: black;
    color: white;
    border-color: black;
}

.btn-light, .col-style {
    background-color: white;
	border: 1px solid #000;
	border-radius: 10px;
}

#pay span {
    font-size: 10px;
}

#plan .justify-content-between span {
    font-size: 10px;
}

.pink {
    color: $primary;
}

.card_border, .blog:not(:last-child), #seo {
    border-bottom: 1px solid black;
}

/* Toggle switch CSS green */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: green;
}

input:focus + .slider {
    box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(15px);
}

.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 50%;
}


/* Toggle switch CSS red */
.switch_red {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch_red input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider_red {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider_red:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider_red {
    background-color: $primary;
}

input:focus + .slider_red {
    box-shadow: 0 0 1px red;
}

input:checked + .slider_red:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(15px);
}

.slider_red.round {
    border-radius: 24px;
}

.slider_red.round:before {
    border-radius: 50%;
}

.second-color {
	color: #95b2d8
}

.card-body .plan-app {
	border-bottom: 1px solid #000;

	&:last-child {
		border-bottom: none;
	}
}
</style>
