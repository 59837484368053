<template>
  <div v-if="isAdvancedBuilder" id="builder" class="app-page">
    <div class="app-page-preview">
      <div id="dom">
        <page-builder-content />
      </div>
    </div>

    <v-tour v-if="hasSidebar" name="builder" :steps="tour" />
  </div>
</template>

<script>
// Utilities
import PageBuilderContent from '../components/PageBuilderContent.vue'

// Prototypes used for package
import '../utilities/prototype'
import { mapActions, mapState } from 'vuex'
import BuilderMixin from "../mixins/BuilderMixin"
import PagesMixin from '../mixins/PagesMixin'
import { isVoidValue } from "@/utilities/helper"
import convertHtmlToJson from "@/modules/pagebuilder/craft/utils/convertHtmlToJson"

export default {
  name: 'AdvancedBuilder',
  components: {
    PageBuilderContent,
  },
  mixins: [BuilderMixin, PagesMixin],
  inject: ['editor'],
  data() {
    return {
      show: {
        sidebar: false,
      },
    }
  },
  computed: {
    ...mapState('builder', ['dom', 'entity']),
    hasSidebar() {
      return !this.$route.path.includes('/create')
    },
    tour() {
      if (!window.localStorage.getItem('builderTour'))
        return [
          {
            target: '.add-new-page', // We're using document.querySelector() under the hood
            header: {
              title: 'Create new page',
            },
            content: `Add pages and discover templates!`,
            params: {
              placement: 'right',
            },
          },
          {
            target: '.pages-sidebar--header',
            content: 'Select page',
            params: {
              placement: 'right',
            },
          },
          {
            target: '.btn-toggle-status',
            content: 'Publish your website or unpublish it',
          },
        ]
      return [
        {
          target: '.pages-sidebar--header',
          content: 'Select page',
          params: {
            placement: 'right',
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions('builder', [
      'fetchBlocks',
      'hideGlobalLoader',
      'askForName',
    ]),
    initialActions(){
      if(this.isCreateMode){
        // blog and portfolio not need this because by default they use the post builder
        if(this.builder && ['cms-pages', 'cms-sections', 'cms-templates'].includes(this.builder.meta.entity)) {
          this.askForName()
        }
      }

      window.localStorage.setItem('builderTour', '1')

    },
    importEditorContent() {
      if(isVoidValue(this.entity.objectData.content_json)){
        this.$set(this.entity.objectData, 'content_json', convertHtmlToJson(this.editor, this.entity.objectData.content_html))
        if(this.$route.query.debug){
          console.log('PAGEBUILDER: This cms content was converted to json format')
        }
      } else {
        if(this.$route.query.debug){
          console.log('PAGEBUILDER: Great this page is in json format')
        }
      }

      this.editor.import(this.entity.objectData.content_json)
      this.hideGlobalLoader()
    },
  },

  watch: {
    '$route': {
      handler() {
        this.editor.selectNode(null) // hide row tools
      },
    },
    // called when the entity is updated (after save) or when the entity is changed (when you switch to another or click create new)
    '$store.state.builder.originalState': {
      deep: false,
      handler(){
        this.initialActions()
        this.importEditorContent()
        this.editor.renderKey++
      },
    },
    // update state content on change, we need this for unsaved changes and autosave
    'editor.nodes': {
      deep: true,
      handler() {
        this.entity.objectData.content_json = this.editor.export()
      },
    },
  },

  async created() {
    await this.fetchBlocks()
  },
  mounted(){
    this.$store.state.layout.preview = false
  },
}
</script>
