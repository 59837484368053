<template>
    <div class="comments-container col-styling mb-3 bg-white" v-if="value">
        <span class="_title">{{ translate('Comments', 'comments') }}</span>
        <div class="_body">
            <div class="comments-section">
                <div class="single-comment" v-for="(comment, cI) in value" :key="cI">
                    <div class="comment-info">
                        {{ comment.user.first_name }}
                        {{ comment.user.last_name }}
                        <small>
                            {{ formatDate(comment.created_at, null, 'DD MMM YY - HH:mm') }}
                        </small>
                    </div>
                    <div class="comment">
                        {{ comment.comment }}
                    </div>
                </div>
            </div>
            <div class="add-comment pb-2 mb-5">
                <textarea v-model="comment"
                          :placeholder="translate('Put a comment to this order', 'comments')"
                          class="form-control"
                >
                </textarea>
                <transition name="fade">
                    <button v-if="comment" class="btn btn-primary btn-save-textarea"
                            @click="addComment"
                            @keypress.enter="addComment">
                        {{ translate('Add comment', 'comments') }}
                    </button>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Comments",
    data() {
        return {
            comment: ''
        }
    },
    methods: {
        addComment() {
            this.$emit('saveComment', this.comment)
            this.comment = ''
        }
    },
    props: {
        value: {
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.comments-container {
    ._body {
        .comments-section {
            max-height: 150px;
            overflow: auto;

            .single-comment {
                border-bottom: 1px solid $border-color;

                &:last-child {
                    border-bottom: 0;
                }

                .comment-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .comment {
                    padding: .3rem 0;
                    font-style: italic;
                }
            }
        }

        .add-comment {
            padding: .3rem 0 .3rem 0;
            display: flex;
            flex-direction: column;

            .btn-save-textarea {
                margin-right: 1.5rem;
                width: max-content;
                margin-left: auto;
            }
        }

    }
}
</style>
