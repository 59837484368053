<template>
  <div>
    <div v-if="value" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ translate('Update item', 'attributes') }} </h5>
        <button class="close" type="button" @click="$emit('close')">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body b-b-pr">
        <div class="form-group">
          <label class="form-control-label">{{ translate('Title', 'attributes') }} *</label>
          <input-field v-model="data.label" :field-props="{ type: 'text', translatable: 1 }" required/>
        </div>
      </div>

      <div class="modal-footer remove-border">
        <button class="btn btn-white rounded-pill" @click.prevent="$emit('close')">
          {{ translate('Go back', 'global') }}
        </button>
        <button class="btn btn-primary rounded-pill" @click.prevent="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: "EditPage",
  components: {
    InputField,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    getId() {
      return this.$route.params.id || this.$store.state.system.company.key
    },
  },
  created() {
    this.data = this.value
  },
  methods: {
    save() {
      this.$emit('input', this.data)
      this.$emit('close')
    },
  },
}
</script>
