<template>
  <div class="container app-entities-settings">
    <page-title :page-title="translate('Sitemaps', 'settings')" >
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          <span>{{ translate('Save changes', 'global') }}</span>
        </button>
      </template>
    </page-title>

    <div class="row" v-if="Object.keys(sitemaps).length">
      <div v-for="sitemap in sitemaps" :key="sitemap.key_plain"
           class="col-md-12 sitemap"
      >
        <div class="card bg-white">
          <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !sitemap.expanded}"
               @click="sitemap.expanded = !sitemap.expanded">
            <h5>{{ translate(sitemap.label, 'settings') }}</h5>

            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': sitemap.expanded === true}"></i>
          </div>

          <transition-expand>
            <div v-show="sitemap.expanded" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label :for="`sitemap-for-${sitemap.key_plain}`">
                      {{ translate('Active sitemap', 'settings') }}
                    </label>
                    <div class="d-flex justify-content-between py-2">
                      <div class="custom-control custom-switch">
                        <input type="checkbox"
                               v-model="sitemap.value"
                               name="show"
                               :id="`sitemap-for-${sitemap.key_plain}`"
                               class="custom-control-input"
                               :value="sitemap.value"
                               :true-value="1" :false-value="0"
                        />
                        <label class="custom-control-label" :for="`sitemap-for-${sitemap.key_plain}`"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="sitemap.value">
                  <div class="form-group">
                    <label :for="`sitemap-for-${sitemap.key_plain}`">
                      {{ translate('Url for sitemap', 'settings') }}
                    </label>
                    <div class="d-flex justify-content-between py-2">
                      <span class="font-12">
                        /seo-sitemap/{{sitemap.key_plain}}
                      </span>
                      <a target="_blank" :href="`https://${$store.state.system.scope.obj.url}/seo-sitemap/${sitemap.key_plain}`" class="float-right strong">
                        {{ translate('Visit page', 'settings') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </transition-expand>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { createSettingsForm } from "@/utilities/helpers/websiteSettings"

export default {
  name: 'SeoSitemap',
  data() {
    return {
      sitemaps: {},
    }
  },
  mounted() {
    this.getSitemapSettingsData();
  },
  methods: {
    async getSitemapSettingsData() {
      this.$store.state.system.isLoading = true
      await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}/sitemap-settings-cmswebsite/sitemap`).then(res => {
        let form = createSettingsForm(res)
        form.forEach(item => {
          this.$set(this.sitemaps, item.name, {
            key_plain: item.name.replace('.'+item.group_by, '').replace('sitemap_', ''),
            label: item.label,
            value: item.value,
            expanded: true,
          })
        })
        this.$store.state.system.isLoading = false
      })
    },
    async save() {
      this.$store.state.system.isLoading = true;

      let data = {
        model: "cms-websites",
        modelId: this.$store.state.system.scope.value,
        options: {},
        settingsSlug: "sitemap-settings-cmswebsite",
      }

      // fill options
      for (const [key, el] of Object.entries(this.sitemaps)) {
        data.options[key] = parseInt(el.value)
      }

      await this.erp.ext.request.axiosInstance.post(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}`, data).then(res => {
        this.$toast.requestSuccess('put', 'Sitemap settings') // explicit put
        this.$store.state.system.isLoading = false
      }).catch(error => {
        this.$alert.formattedError(error)
        this.$store.state.system.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sitemap {
  > div {
    a {
      color: #000;
      font-weight: 600;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &:hover {
        color: $primary;
      }
    }
  }
}
</style>