<template>
  <div>
    <div class="input-group" @click="toggleIconPicker()">
      <div class="input-group-prepend">
        <button class="btn btn-outline-secondary icon-selected">
          <!-- class .fa needed for backward compatibility -->
          <i :class="'fa '+value"></i>
        </button>
      </div>

      <button class="btn btn-white form-control">
        {{ translate('Choose icon', 'entities') }}
      </button>
    </div>

    <div class="dropdown-opened dropdown-menu dropdown-menu-md dropdown-menu-arrow mr-0" v-if="iconPickerOpened">
      <div class="menu-dropdown-items">
        <div class="icon-picker">
          <div class="icon-picker-header">
            <div class="d-flex">

              <input type="text" class="form-control" :placeholder="translate('Search icon', 'entities')" @keyup="filterIcons($event)">

                <button class="rounded p-2 ml-2 border-0 bg-transparent" @click="removeSelectedIcon()">
                  <i class="icon-erp-trash text-danger cursor-pointer"></i>
                </button>

            </div>
          </div>

          <div class="icon-picker-body">
            <div class="icon-picker-icons" v-if="iconsFiltered.length">
              <div
                  v-for="icon in iconsFiltered"
                  :key="icon"
                  @click.stop.prevent="setIcon(icon)"
                  :class="`item ${value === icon ? 'selected' : ''}`"
              >
                <i :class="icon"></i>
              </div>
            </div>
            <div v-else class="text-center">
              {{ translate('No icons found', 'entities') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* TODO

plugin format is
icons = [
  {
      name: 'address-book',
      value: 'f2b9',
  },

Our format is
{
    "fontawesome-6.5.1": [
        "fa-solid fa-glass",
        "fa-solid fa-music",
* */
import allIcons from '@/assets/misc/icons.json'

export default {
  name: 'fontAwesomePicker',
  props: {
    value: {
      required: true
    },
    icons: { // filter icons
      required: false
    }
  },
  data () {
    return {
      allIcons,
      iconsFiltered: {},
      iconPickerOpened: false,
    }
  },
  methods: {
    toggleIconPicker(){
      this.iconPickerOpened = !this.iconPickerOpened
    },
    closeIconPicker(){
      this.iconPickerOpened = false
    },
    setIcon(icon) {
      this.iconPickerOpened = false
      this.$emit('input', icon)
    },
    defaultIconsList(){
      if(this.icons){
        return this.allIcons['fontawesome-6.5.1'].filter(this.isPresent)
      } else {
        return this.allIcons['fontawesome-6.5.1']
      }
    },
    isPresent(iconName){
      const [prefix, name] = iconName.split(" ")
      return this.icons.some(icon => icon === name)
    },
    filterIcons(event) {
      const search = event.target.value.trim();
      let filter = [];
      if (search.length > 0) {
        this.iconsFiltered = this.defaultIconsList().filter((item) => {
          const regex = new RegExp(search, 'gi');
          return item.match(regex);
        });
      } else if (search.length === 0) {
        this.iconsFiltered = this.defaultIconsList();
      }
    },
    removeSelectedIcon(){
      this.$emit('input', null);
      this.iconPickerOpened = false
    },
  },
  created() {
    this.iconsFiltered = this.defaultIconsList()
  }
};
</script>

<style lang="scss" scoped>
.icon-selected {
  width: 50px;
  i {
    vertical-align: middle;
    font-size: 20px;
  }
}

.dropdown-menu {
  top: unset; /* remove bootstrap's 100% */
}

.dropdown-opened {
  display: block !important;
  margin: 0;
}

.icon-picker {
  position: relative;
  background: #fff;
  width: 262px;
  padding: 10px;
}
.icon-picker-header {
  margin-bottom: 1rem;
  input {
    padding: 1em;
  }
}

.icon-picker-body {
  position: relative;
  max-height: 250px;
  overflow: auto;
  margin: 0 -5px;
}

.icon-picker-icons {
  display: table;
  .item {
    float: left;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px;

    text-align: center;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    color: $body-color;
    i {
      font-size: 20px;
    }
  }

  .item.selected, .item:hover {
    background: #e6e6e6;
    cursor: pointer;
  }
}

</style>