<template>
  <span>
    <template v-if="fieldProps.translatable == 1">
      <template v-if="fieldProps.system_attribute == 1">
        {{ translate(value['en'], 'entities') }}
      </template>
      <template v-else>
        {{ value[environment.store.state.system.translation_locale]}}
      </template>
    </template>
    <template v-else>
      {{ translate(value, 'entities') }}
    </template>
  </span>
</template>

<script>
import {environment} from '../../utilities/helper'

export default {
  name: "EeTitle",
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
      default: {},
    },
  },
  computed: {
    environment: () => environment,
  },
}
</script>