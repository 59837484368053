import httpQueryBuilder from "./http_query_builder"
import requestService from "./request_service"
import Collection from "./Collection"

/**
 *  Used for searching
 *  ErpModel.search('search').where('status', '=', 'active').get()
 *
 * @export
 * @class EntitiesEloquentSearch
 * @extends {httpQueryBuilder}
 */
export default class EntitiesEloquentSearch extends httpQueryBuilder {

  constructor(entity = ''){
    super()
    this._entity = entity
  }


  /**
     * Returns collection class of records filtered by query
     *
     * @returns {Collection}
     * @memberof EntitiesEloquentSearch
     */
  async get(){
    let result = await requestService.get(this.requestUrl, this.toString())
    return new Collection(result.dataModels)
  }

  // Getters

  get requestUrl(){
    return `/modules/${this._entity}`
  }
}
