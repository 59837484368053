<template>
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped lang="scss">
    .select-company-view {
        .lds-ripple {
            position: fixed;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        z-index: 999999999;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid $primary;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

</style>
