// Blockzapps added
import createNodeFromHtmlNode from "./createNodeFromHtmlNode";
import { parse as nodeParse } from 'node-html-parser'
import {getInitialContent} from "@/modules/pagebuilder/utilities/helpers";
import {isVoidValue} from "@/utilities/helper";

function convertHtmlToJson(editor, html) {
  if(isVoidValue(html)){
    return getInitialContent();
  }

  const parsed_html = nodeParse(html).childNodes.filter(childNode => childNode.outerHTML)[0]

  if(parsed_html.classNames && parsed_html.classNames.includes("main-builder-container")){
    const node = createNodeFromHtmlNode(editor, parsed_html)
    const nodesData = [node].map((node) => node.serialize());
    return JSON.stringify(nodesData);
  } else {
    console.error('PAGE BUILDER: Content cannot be converted because it does not start with div.main-builder-container')
    return getInitialContent();
  }
}

export default convertHtmlToJson;