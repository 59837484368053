<template>
  <div>
    <transition-expand>
      <div v-if="!sameAsBillingAddress">
        <div class="user-col" v-if="customer">
          <div class="customer-info">
            <div class="single-info mb-3">
              <label>Address</label>
              <v-select
                v-model="selectedAddress"
                :options="addressOptions"
                :reduce="value => value.key"
                :placeholder="translate('Customer addresses', 'orders')"
                :clearable="false"
                @input="v => $emit('changeAddress', v)"
              />
            </div>
          </div>
        </div>
        <div class="user-col">
          <div class="customer-info w-100 mt-2">
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('First name', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_client_first_name"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('First name', 'orders')"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Last name', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_client_last_name"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Last name', 'orders')"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Address', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_address_address"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Address', 'orders')"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('City', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_address_city"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('City', orders)"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Postcode', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_address_postcode"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Postcode', 'orders')"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Country', 'orders') }}</span>
              <div
                v-if="countries"
                class="col-md-7 align-items-center"
              >
                <v-select
                  v-model="value.shipping_address_country"
                  :options="countries"
                  label="value"
                  :disabled="customer"
                  :reduce="value => value.key"
                  :placeholder="translate('Country', 'orders')"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Phone', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_client_phone"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Phone', 'orders')"
                  :required="true"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Company name', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <input
                  v-model="value.shipping_company_name"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Company name', orders)"
                  :required="false"
                >
              </div>
            </div>
            <div class="single-info d-flex justify-content-between py-2">
              <span>{{ translate('Additional information', 'orders') }}</span>
              <div class="col-md-7 align-items-center">
                <textarea
                  v-model="value.shipping_address_additional_information"
                  class="form-control"
                  type="text"
                  :readonly="customer"
                  :placeholder="translate('Additional information', 'orders')"
                  :required="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
export default {
  name: "OrderShippingAddressForm",
  props: {
    value: {
      required: true,
    },
    countries: {
      required: true,
    },
    customer: {
      required: false,
    },
    addressOptions: {
      required: false,
    },
    selectedAddress: {
      required: false,
    },
    sameAsBillingAddress: {
      required: true,
    },
  },
}
</script>
