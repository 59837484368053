// hot fix, todo absolute path imports must be configured in cms-website package
//import { validate } from '@/modules/erp_entities/utilities/validation/helper'
import { validate } from '../../erp_entities/utilities/validation/helper'
import requestService from './request_service'
import EntitiesEloquentSearch from './EntitiesEloquentSearch'
import { entityNameToModel } from '../utilities/helpers'

export default class ErpModel {
  /**
     * Creates an instance of ErpModel.
     * @param {Object} data
     * @param {Object} columns
     * @param {String} route
     * @memberof ErpModel
     */
  constructor(data, columns, route, method = 'put', additionalData = {}) {
    this._data = data
    this._columns = columns
    this._route = route
    this._method = method
    this._errors = {}
    this._additionalData = additionalData
  }

  static search(entity) {
    return new EntitiesEloquentSearch(entity)
  }

  static async create(entity, data, additionalOptions = {}) {
    const method = 'post'
    const target = `modules/${entity}`
    const columnsTarget = `modules/columns/${entityNameToModel(entity)}`
    const columnsResponse = await requestService.axiosInstance.get(columnsTarget)
    const columns = columnsResponse.data

    const newErpEntity = new this(data, columns, target, method)

    Object.entries(data).forEach(
      ([key, val]) => newErpEntity.set(key, val),
    )

    return newErpEntity.save(additionalOptions)
  }

  static async find(entity, id) {
    let entityData = await requestService.find(entity, id, true)
    return entityData.model
  }

  async delete() {
    return requestService.axiosInstance.delete(`${this._route}/${this.data.id}`)
  }

  /**
     *
     *
     * @readonly
     * @memberof ErpModel
     */
  get data() {
    return this._data
  }

  get additionalData() {
    return this._additionalData
  }

  /**
     *
     *
     * @readonly
     * @memberof ErpModel
     */
  get columns() {
    return this._columns
  }

  /**
     *
     *
     * @readonly
     * @memberof ErpModel
     */
  get normalizedColumns() {
    return this._columns.optionsToObject('name')
  }

  /**
     *
     *
     * @readonly
     * @memberof ErpModel
     */
  get validationRules() {
    return this._columns.reduce((validations, { name, rules }) => {
      validations[name] = rules
      return validations
    }, {})
  }

  /**
     *
     *
     * @param {String} property
     * @param {*} value
     * @return {*}
     * @memberof ErpModel
     */
  set(property, value) {
    if (!this.normalizedColumns[property]) {
      // throw new Error(`Property ${property} does not exist in option types.`)
      console.error(`Property ${property} does not exist in option types.`)
      return
    }

    const validationErrors = validate(value, this.validationRules[property])

    Object.keys(validationErrors).length
      ? this._errors[property] = validationErrors
      : delete this._errors[property]

    this._data[property] = value
    return this
  }


  /**
     *
     *
     * @param {Object} updateObject
     * @return {Promise}
     * @memberof ErpModel
     */
  update(updateObject) {
    Object.entries(updateObject).forEach(([key, value]) => {
      this.set(key, value)
    })
    return this.save()
  }

  /**
     *  Returns promise
     *
     * @return {Promise}
     * @memberof ErpModel
     */
  save(additionalOptions) {
    const requestRoute = this._method === 'put'
      ? `${this._route}/${this.data.id}`
      : `${this._route}`

    const options = Object.assign({}, this.data)
    delete options.id

    let payload = {
      options,
    }

    if (additionalOptions && Object.keys(additionalOptions).length) {
      payload = Object.assign(payload, additionalOptions)
    }

    return requestService.axiosInstance[this._method](requestRoute, payload)
  }
}
