<template>
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ translate('Duplicate {entity}', 'page-builder', { entity: entityNameTranslated }) }}
        </h5>
        <button type="button" class="close" @click="$modal.hide('duplicate_content')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <template v-if="entity !== 'inventory'">
          <div class="form-group">
            <language-picker
              :lang="data.locale"
              :label="translate('Locale', 'page-builder')"
              @changeLocale="e => data.locale = e"
            />
          </div>

          <div v-if="data.locale !== value.locale" class="form-group">
            <div class="custom-control custom-switch">
              <input
                id="keep_relation"
                v-model="keep_relation"
                type="checkbox"
                name="keep_relation"
                class="custom-control-input"
              >
              <label for="keep_relation" class="custom-control-label">{{ translate('This {entity} is translation of the selected {entity}', 'page-builder', { entity: entityNameTranslated.toLowerCase() }) }}</label>
            </div>
          </div>
        </template>

        <div class="form-group">
          <label>{{ translate('Enter title', 'page-builder') }}</label>
          <input-field v-model="data.name" :field-props="data.options ? options['name'] : {}" />
        </div>
        <div class="form-group">
          <label>{{ translate('Enter url key', 'page-builder') }}</label>
          <input-field v-model="data.url_key" :field-props="data.options ? options['url_key'] : {}" />
        </div>
      </div>
      <div class="modal-footer remove-border">
        <button type="button" class="btn btn-cancel-sidebar" @click.prevent="$modal.hide('duplicate_content')">
          {{ translate('Cancel', 'page-builder') }}
        </button>
        <button class="btn btn-primary" @click="save">
          {{ translate('Duplicate', 'page-builder') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LanguagePicker from "@/components/LanguagePicker"
import uniqid from "uniqid"
import PagesMixin from "@/modules/pagebuilder/mixins/PagesMixin" // required for findCmsContentBySlug
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { slug } from "@/utilities/helper";

export default {
  name: "DuplicateContent",
  components: {
    InputField, LanguagePicker,
  },
  mixins: [PagesMixin],
  props: {
    value: {
      required: true,
    },
    entity: {
      required: true,
    },
    entityNameTranslated: {
      required: true,
    },
  },
  data() {
    return {
      keep_relation: false,
      data: {},
      options: {},
    }
  },
  created() {
    this.data = { ...this.data, ...this.value }
    // covert to string, json objects are not saved properly in the api, when we send "" it saves null, therefore make problems later
    if(this.entity !== 'inventory' && this.data.content_json && typeof this.data.content_json !== 'string'){
      this.data.content_json = JSON.stringify(this.data.content_json)
    }

    if (this.data.options && this.data.options.length) {
      this.options = {}
      for (const option of this.data.options) {
        this.options[option['key']] = option
      }
    }

    this.$watch('data.name', () => this.generateUrlKey(), { deep: true })
  },
  methods: {
    generateUrlKey() {
      if (typeof this.data.name === 'string') {
        this.data.url_key = slug(this.data.name, '-')
      } else if (typeof this.data.name === 'object') {
        this.data.url_key[this.$store.state.system.translation_locale] = slug(this.data.name[this.$store.state.system.translation_locale], '-')
      }
    },
    async save() {
      delete this.data.id

      if (this.entity !== 'inventory') {
        this.data.status = 0

        // generate new slug if the lang is the same
        if(this.data.locale === this.value.locale || !this.keep_relation){
          this.data.slug = uniqid()
        } else {
          // check if page with that slug already exists
          let translatedPage = await this.findCmsContentBySlug(this.entity, this.value.slug, this.data.locale)
          if(translatedPage){
            this.alertCmsContentRelationExists()
            return false
          }

          this.data.slug = this.value.slug
        }
      } else {
        ['created_by', 'created_at', 'deleted_at', 'updated_at', 'inputs', 'options']
          .forEach(field => delete this.data[field])
      }

      this.$emit('save', this.data)
    },
  },
}
</script>

<style scoped>
    .modal-body {
        min-height: 200px;
    }
</style>
