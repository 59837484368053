import { ErpModel } from '@/modules/erp_framework'

export default {
  data() {
    return {
      data: null,
    }
  },
  props: {
    value: {
      required: false,
    },
    builder: {
      required: false,
      default: true,
    },
  },
  methods: {
    async loadData(){
      const { id } = this.$route.params
      const res = await ErpModel.find(this.entity, id)
      this.data = res.data
    },
  },
  async created() {
    if(this.value){
      this.data = { ...this.value }
      return
    }
    await this.loadData()
  },
  watch: {
    '$route.params.id': {
      deep: true,
      handler: async function (){
        await this.loadData()
      },
    },
  },
  computed: {
    image(){
      if(!this.data || this.data.image === null || !this.data.image)
        return '/uploads/defaults/image-placeholder.jpg'
      return this.data.image
    },
    componentData(){
      // todo check what is this
      /*
            console.log('componentData()')
            console.log(this.data)
            console.log(this.value)
            */
      return this.data || this.value
    },
  },
}
