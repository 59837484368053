<template>
	<div>
		<div class="extension-body py-4 b-b-pr-gr" v-if="value.plan.apps">
			<div class="d-flex flex-column">
				<div>
					<div>
						<h3 class="py-3 text-center">{{ translate('Plan extensions', 'orders') }}</h3>
						<div class="row" v-if="plan_extensions">
							<div class="col-md-4" v-for="(ext, exI) in plan_extensions" :key="exI">
								<div class="extension d-flex mb-3">
									<div class="extension-checkbox mx-4 my-3">
										<input :value="ext.slug" :id="ext.id" type="checkbox" disabled checked/>
										<label :for="ext.id"></label>
									</div>
									<div class="d-flex flex-column justify-content-center">
                                        <span class="extension-title font-weight-bold">
											{{ ext.readable_options.name }}</span>
										<span class="extension-price text-secondary-color">
                                            {{ translate('Appstore price', 'orders') }} {{ ext.price.price }}
                                        </span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="plan_extensions.length === 0" class="row">
							<div class="col-md-12 text-center">
								<span>{{ translate('The plan does not contain any extensions, please select any from the list below', 'orders') }}</span>
							</div>
						</div>

						<h3 class="py-3 text-center" v-if="all_extensions">
							{{ translate('Extra extensions', 'orders') }}</h3>
						<div class="row" v-if="all_extensions">
							<div class="col-md-4" v-for="(extension, exI) in all_extensions" :key="exI">
								<div class="extension d-flex mb-3">
									<div class="extension-checkbox mx-4 my-3">
										<input @change="toggleCartExtension(extension, $event)" :value="extension.slug"
											   :id="`all-${extension.id}`"
											   type="checkbox"/>
										<label :for="`all-${extension.id}`"></label>
									</div>
									<div class="d-flex flex-column justify-content-center">
										<span class="extension-title font-weight-bold">{{ extension.name }}</span>
										<span class="extension-price text-secondary-color">
                                            {{ translate('Price', 'orders') }} {{ extension.price.price }}
                                        </span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {db} from "@/utilities/database";
import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";
import {mapActions, mapState} from 'vuex'

export default {
	name: "OrderExtensions",
	props: {
		value: {
			required: true
		}
	},
	data() {
		return {
			plan_extensions: [],
			all_extensions: [],
		}
	},
	methods: {
		...mapActions('blockzCheckout', ['addToCart', 'removeFromCart', 'getCart']),
		toggleCartExtension(extension, event) {
			if (event.target.checked) {
				this.addToCart(extension)
			} else {
				this.removeFromCart(extension)
			}
		}
	},
	computed: {
		...mapState('blockzCheckout', ['cart', 'data']),
		allExtensionsFiltered(otherArray) {

		},
	},
	async mounted() {
		let plan_ext = []
		let uniqueExt = []
		let newUniExt = []

		this.value.plan.apps.forEach(app => {
			app.readable_options = createEntityFormObjectNoTabs(app.options, 'key')
			if (app.inventory_type === 'extension') {
				plan_ext.push(app)
			}
		})
		this.plan_extensions = plan_ext

		let response = await db.get(`modules/blockz/app-extensions/extensions-modules?page=1&perpage=200`)
		await Promise.all(response.data.data.map((modules) => {
			return db.get(`modules/blockz/app-extensions/extensions-props/${modules.id}?page=1&perpage=200&`)
				.then(extensions => {
				    if (extensions.data.data && extensions.data.data.length > 0) {
                        extensions.data.data.forEach(ext => {
                            ext.readable_options = createEntityFormObjectNoTabs(ext.options, 'key')
                            if (!uniqueExt.some(item => item.id === ext.id)) {
                                uniqueExt.push(ext)
                            }
                        })
                    }
				})
		}))
		let planExtId = plan_ext.reduce((acc, ext) => {
			acc.push(ext.id)
			return acc
		}, [])

		newUniExt = uniqueExt.filter(item => !planExtId.includes(Number(item.id)))
		this.all_extensions = newUniExt
	},
	beforeRouteLeave: function(to, from, next) {
		if (to.name === 'trial-and-extensions' || to.name === 'client') {
			next()
		} else {
			window.localStorage.removeItem('session')
			this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
			this.$store.state.blockzCheckout.cart = []
			next();
		}
	}
}
</script>

<style scoped lang="scss">
.extension-body {
	.module-name {
		font-size: 20px;
		margin: 0 .5rem;
		font-weight: 600;
		cursor: pointer;

		&.active, &:hover {
			color: #FF004F;
		}
	}

	.extensions-length {
		color: $primary;
	}

	.extension {
		border: 1px solid;
		border-radius: 5px;

		.extension-title {
			font-size: 16px;
		}

		.extension-price {
			font-size: 12px;
		}
	}

	.extension-checkbox {
		position: relative;
		padding: 20px 0 20px 40px;
		text-align: left;

		label {
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				border-radius: 50%;
				left: 0;
				width: 30px;
				height: 30px;
				margin: -15px 0 0;
				background: #f7f7f7;
				box-shadow: 0 0 1px grey;
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				border-radius: 50%;
				left: 5px;
				width: 20px;
				height: 20px;
				margin: -10px 0 0;
				opacity: 0;
				background: $primary;
				transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
			}
		}

		input {
			position: absolute;
			top: 0;
			left: -9999px;
			visibility: hidden;

			&:checked + label {
				&:before {
				}

				&:after {
					transform: translate3d(0, 0, 0);
					opacity: 1;
				}
			}
		}
	}
}
</style>
