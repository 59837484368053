<template>
  <!-- We have different span's because the tooltip cannot be disabled and enabled on the fly -->
  <span v-if="value && value.includes('non_translated')" v-tooltip="translate('Item not translated', 'entities')" v-html="valueFormatted" key="shortcode-1"></span>
  <span v-else v-html="valueFormatted" key="shortcode-default"></span>
</template>

<script>
export default {
  name: "TitleWithShortcodes",
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    valueFormatted() {
      let new_value = ''
      if(this.value){

      }
      // Non translated shortcode
      new_value = this.value.replaceAll('[non_translated]', '<span class="icon-xs icon-non-translated"></span>')

      return new_value
    },
  },
}
</script>
