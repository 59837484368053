// @ts-nocheck

/*
* Vue official packages
*/
import Vue from 'vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false


// Register global components
import Tabs from '@/modules/erp_entities/components/Tabs.vue'
import TabContent from '@/modules/erp_entities/components/TabContent.vue'
import Loader from './modules/system/components/utilities/Loader.vue'
import ProgressBar from './modules/system/components/utilities/ProgressBar.vue'

Vue.component('tabs', Tabs)
Vue.component('tab', TabContent)
Vue.component('loader', Loader)
Vue.component('progress-bar', ProgressBar)

/*
* Application addons
*/
import './registerServiceWorker'
import './bootstrap'
import { SweetAlert, SweetToast } from './utilities/sweetalert'

Vue.prototype.$alert = SweetAlert
Vue.prototype.$toast = SweetToast

// Swiper Element (WebComponent)
// import { register } from 'swiper/swiper-element-bundle' // import function to register Swiper custom elements
// register() // register Swiper custom elements

/**
 * File requirements
 */
import { erp } from '@/modules/erp_framework'

/*
* Application instance
*/
import App from '@/App.vue'
import { changeTranslationLocale, themes } from "@/utilities/helper"

Vue.config.silent = true

export default new Vue({
  router,
  store,
  async created() {

    // this.$store.dispatch('system/watchForUpcomingEvents') NOT USED CURRENTLY

    // Theme
    if (typeof process.env.VUE_APP_THEME !== 'undefined' && themes[process.env.VUE_APP_THEME]) {
      this.$store.state.system.theme = process.env.VUE_APP_THEME
      //  TODO: remove hotfix - interface overriding on refresh
      this.$store.state.system.interface = process.env.VUE_APP_THEME
    } else {
      this.$store.state.system.theme = 'blockzapp'
    }

    erp.ext.request.axiosInstance.defaults.headers.common['domain'] = window.location.origin

    Notification.requestPermission()
  },
  render: h => h(App),
}).$mount('#backend-app')
