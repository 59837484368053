
<template>
  <div>

    <div class="col-md-12">
      <button class="save-btn top-save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
        {{ translate('Save changes', 'global') }}
      </button>
    </div>

    <div class="row" v-if="company">

      <div class="col-md-12">
        <div class="card border-bottom rounded bg-white">
          <div class="card-header d-flex justify-content-between border border-bottom-0" :class="{'border-bottom-0': !visible.company_details}"
               @click="visible.company_details = !visible.company_details">
            <h5>{{ translate('Webshop details', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.company_details === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.company_details">
              <div class="card-body p-0">

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Webshop name', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9 m-auto">
                      <div class="input-container">
                        <input v-model="company.data.name" class="bg-white form-control" name="name"
                               :class="'rounded'"
                               @input="v => company.set('name', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>


                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Webshop e-mail', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9 m-auto">
                      <div class="input-container">
                        <input v-model="company.data.email" class="form-control bg-white" name="email"
                               :class="'rounded'"
                               @input="v => company.set('email', v.target.value)"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Company VAT Number', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9 m-auto">
                      <div class="input-container">
                        <input v-model="company.data.vat" class="form-control bg-white" name="vat"
                               :class="'rounded'"
                               @input="v => company.set('vat', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{
                            translate('Chamber of commerce', 'settings')
                          }}</span>
                      </div>
                    </div>
                    <div class="col-md-9 m-auto">
                      <div class="input-container">
                        <input v-model="company.data.chamber_of_commerce" class="form-control bg-white"
                               name="chamber_of_commerce"
                               :class="'rounded'"
                               @input="v => company.set('chamber_of_commerce', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <!--                locales disabled for now #BLOCKZAPPS-1475 -->
                <!--                <div class="client-info-row">-->
                <!--                  <div class="row">-->
                <!--                    <div class="col-md-3 border-right">-->
                <!--                      <div class="input-container container-title">-->
                <!--                        <span class="font-weight-700">{{ translate('Locales', 'settings') }}</span>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="col-md-9">-->
                <!--                      <div class="input-container" v-if="countries">-->
                <!--                        <v-select :options="countries"-->
                <!--                                  label="value"-->
                <!--                                  :reduce="value => value.key"-->
                <!--                                  v-model="company.data.default_language"-->
                <!--                                  @input="v => company.set('locales', v)" multiple>-->
                <!--                        </v-select>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card border-bottom rounded bg-white">
          <div class="card-header d-flex justify-content-between border border-bottom-0" :class="{'border-bottom-0': !visible.company_address}"
               @click="visible.company_address = !visible.company_address">
            <h5>{{ translate('Webshop address', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.company_address === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.company_address">
              <div class="card-body p-0">

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Address', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.street_number" class="bg-white form-control" name="street_number"
                               :class="'rounded'"
                               @input="v => company.set('street_number', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('ZIP *', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.zip_code" class="bg-white form-control" name="zip_code"
                               :class="'rounded'"
                               @input="v => company.set('zip_code', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('City *', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.city" class="bg-white form-control" name="city"
                               :class="'rounded'"
                               @input="v => company.set('city', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('State / Province', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.state" class="bg-white form-control" name="state"
                               :class="'rounded'"
                               @input="v => company.set('state', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Country *', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container" v-if="countries">
                        <v-select :options="countries"
                                  label="value"
                                  :reduce="value => value.key"
                                  v-model="company.data.country"
                                  @input="v => company.set('country', v)">
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Phone number', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.phone" class="bg-white form-control" name="phone"
                               :class="'rounded'"
                               @input="v => company.set('phone', v.target.value)">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card border rounded bg-white">
          <div class="card-header d-flex justify-content-between border-bottom-0" :class="{'border-bottom-0': !visible.finances}"
               @click="visible.finances = !visible.finances">
            <h5>{{ translate('Finances', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.finances === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.finances">
              <div class="card-body p-0">

                <div class="client-info-row borer-0 last">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Primary currency', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9 m-auto" v-if="currencies">
                      <div class="input-container">
                        <v-select :options="currencies" label="value"
                                  :reduce="value => value.key"
                                  v-model="company.data.currency"
                                  @input="v => company.set('currency', v)">
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </transition-expand>

        </div>

      </div>

      <div class="col-md-12">
        <div class="card border-bottom rounded bg-white">

          <div class="card-header d-flex justify-content-between border border-bottom-0" :class="{'border-bottom-0': !visible.company_details}"
               @click="visible.contact_person = !visible.contact_person">
            <h5>{{ translate('Contact person', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.contact_person === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.contact_person">
              <div class="card-body p-0">

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('First name', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.contact_person_first_name" class="form-control bg-white mr-2 mb-2"
                               :class="'border rounded'"
                               name="contact_person_first_name"
                               :placeholder="translate('Contact person first name', 'entities')"
                               @input="v => company.set('contact_person_first_name', v.target.value)"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Last name', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.contact_person_last_name" class="form-control bg-white mb-2"
                               :class="'border rounded'"
                               name="contact_person_last_name"
                               :placeholder="translate('Contact person last name', 'entities')"
                               @input="v => company.set('contact_person_last_name', v.target.value)"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="client-info-row">
                  <div class="row">
                    <div class="col-md-3 border-right">
                      <div class="input-container container-title">
                        <span class="font-weight-700">{{ translate('Email', 'settings') }}</span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="input-container">
                        <input v-model="company.data.contact_person_email" class="form-control bg-white mb-2"
                               :class="'border rounded'"
                               name="contact_person_email"
                               :placeholder="translate('Contact person email', 'settings')"
                               @input="v => company.set('contact_person_email', v.target.value)"
                        >
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </transition-expand>
        </div>
      </div>


      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2 right" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>


    <!--      <div class="col-md-6">-->
    <!--        <ul class="list-group border">-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between py-2">-->
    <!--                        <span class="font-weight-700">{{ translate('Customer details', 'settings') }} -->
    <!--                            <small>({{ company.data.id }})</small>-->
    <!--                        </span>-->
    <!--            <button class="btn border rounded-pill py-0" v-if="!editModeCustomer"-->
    <!--                    @click="editModeCustomer = !editModeCustomer">-->
    <!--              {{ translate('Edit', 'settings') }}-->
    <!--            </button>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--            <span class="font-weight-700">{{ translate('Company name', 'settings') }}:</span>-->
    <!--            <div class="input-container">-->
    <!--              <input v-model="company.data.name" class="bg-white" name="name"-->
    <!--                     :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                     @input="v => company.set('name', v.target.value)" :disabled="!editModeCustomer">-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--                        <span class="font-weight-700">-->
    <!--                            {{ translate('Email', 'settings') }}:-->
    <!--                        </span>-->
    <!--            <div class="input-container">-->
    <!--              <input v-model="company.data.email" class="bg-white" name="email"-->
    <!--                     :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                     @input="v => company.set('email', v.target.value)" :disabled="!editModeCustomer">-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--            <span class="font-weight-700">{{ translate('Phone', 'settings') }}:</span>-->
    <!--            <div class="input-container">-->
    <!--              <input v-model="company.data.phone" class="bg-white" name="phone"-->
    <!--                     :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                     @input="v => company.set('phone', v.target.value)" :disabled="!editModeCustomer">-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--            <span class="font-weight-700">{{ translate('Street', 'settings') }}:</span>-->
    <!--            <div class="input-container">-->
    <!--              <input v-model="company.data.street_number" class="bg-white" name="street_number"-->
    <!--                     :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                     @input="v => company.set('street_number', v.target.value)" :disabled="!editModeCustomer">-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--            <span class="font-weight-700">{{ translate('ZIP code', 'settings') }}:</span>-->
    <!--            <input v-model="company.data.zip_code" class="bg-white"-->
    <!--                   :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                   @input="v => company.set('zip_code', v.target.value)" :disabled="!editModeCustomer">-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--            <span class="font-weight-700">{{ translate('VAT', 'settings') }}:</span>-->
    <!--            <input v-model="company.data.vat" class="bg-white"-->
    <!--                   :class="editModeCustomer === true ? 'border rounded' : 'border-transparent'"-->
    <!--                   @input="v => company.set('vat', v.target.value)" :disabled="!editModeCustomer">-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between" v-if="countries">-->
    <!--            <span class="font-weight-700">{{ translate('Locales', 'settings') }}:</span>-->
    <!--            <v-select :options="countries" style="width: 184px" label="value" :reduce="value => value.key"-->
    <!--                      :disabled="!editModeCustomer" :class="{'disable-v-select': !editModeCustomer}"-->
    <!--                      v-model="company.data.locales" @input="v => company.set('locales', v)" multiple></v-select>-->
    <!--          </li>-->
    <!--          <li class="list-group-item d-flex align-items-center justify-content-between" v-if="currencies">-->
    <!--            <span class="font-weight-700">{{ translate('Default currency', 'settings') }}:</span>-->
    <!--            <v-select :options="currencies" style="width: 184px" label="value" :reduce="value => value.key"-->
    <!--                      :disabled="!editModeCustomer" :class="{'disable-v-select': !editModeCustomer}"-->
    <!--                      v-model="company.data.currency" @input="v => company.set('currency', v)"></v-select>-->
    <!--          </li>-->

    <!--        </ul>-->
    <!--        <button v-if="editModeCustomer" class="btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">-->
    <!--          {{ translate('Save changes', 'global') }}-->
    <!--        </button>-->
    <!--      </div>-->
    <!--            <div class="col-md-12">-->
    <!--              <hr>-->
    <!--              <ul class="list-group border mt-3">-->
    <!--                <li class="list-group-item d-flex align-items-center justify-content-between py-2">-->
    <!--                              <span-->
    <!--                                  class="font-weight-700">{{ translate('Contact person', 'settings') }}</span>-->
    <!--                  <button class="btn border rounded-pill py-0" v-if="!editModeContact"-->
    <!--                          @click="editModeContact = !editModeContact">-->
    <!--                    {{ translate('Edit', 'settings') }}-->
    <!--                  </button>-->
    <!--                </li>-->
    <!--                <li class="list-group-item d-flex align-items-center justify-content-between py-2">-->
    <!--                  <div class="d-flex flex-column">-->
    <!--                    <div>-->
    <!--                      <input v-model="company.data.contact_person_first_name" class="bg-white mr-2 mb-2"-->
    <!--                             :class="editModeContact === true ? 'border rounded' : 'border-transparent'"-->
    <!--                             :placeholder="translate('Contact person first name', 'entities')"-->
    <!--                             @input="v => company.set('contact_person_first_name', v.target.value)"-->
    <!--                             :disabled="!editModeContact">-->
    <!--                      <input v-model="company.data.contact_person_last_name" class="bg-white mb-2"-->
    <!--                             :class="editModeContact === true ? 'border rounded' : 'border-transparent'"-->
    <!--                             :placeholder="translate('Contact person last name', 'entities')"-->
    <!--                             @input="v => company.set('contact_person_last_name', v.target.value)"-->
    <!--                             :disabled="!editModeContact">-->
    <!--                    </div>-->
    <!--                    <input v-model="company.data.contact_person_email" class="bg-white"-->
    <!--                           :class="editModeContact === true ? 'border rounded' : 'border-transparent'"-->
    <!--                           :placeholder="translate('Contact person email', 'settings')"-->
    <!--                           @input="v => company.set('contact_person_email', v.target.value)"-->
    <!--                           :disabled="!editModeContact">-->
    <!--                  </div>-->
    <!--                </li>-->
    <!--              </ul>-->
    <!--              <button v-if="editModeContact" class="btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">-->
    <!--                {{ translate('Save changes', 'global') }}-->
    <!--              </button>-->
    <!--            </div>-->

    <!--UNCOMMENT FOR BLOCKZ SALES REPRESENTATIVE-->
    <!--            <div class="col-md-6">-->
    <!--                <ul class="list-group border mt-5">-->
    <!--                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">-->
    <!--                        <span-->
    <!--                            class="font-weight-700">{{ translate('clients.blockz_sales') }}</span>-->
    <!--                    </li>-->
    <!--                    <li class="list-group-item d-flex align-items-center justify-content-between">-->
    <!--                        <div class="d-flex flex-column">-->
    <!--                            <span>Diana Smiley</span>-->
    <!--                            <span class="cont_personEmail">diana.smiley@company.com</span>-->
    <!--                        </div>-->
    <!--                    </li>-->
    <!--                </ul>-->
    <!--            </div>-->

    <div class="col-md-12">
      <div class="delete_account">
        <hr>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between align-items-center p-2">
            <div>
              <p>{{ translate('Delete account', 'settings') }}</p>
              <p id="note">{{ translate('Delete account text', 'settings') }}</p>
            </div>
            <button class="btn btn-danger rounded-pill">{{ translate('Delete', 'settings') }}</button>
          </div>
        </div>
        <hr>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import EntityForm from '@/modules/erp_entities/components/page/form/EntityForm'
import ErpModel from "@/modules/erp_framework/services/ErpModel";

export default {
  name: 'SubscriptionsGeneral',
  components: {
    EntityForm,
  },
  data() {
    return {
      company: null,
      editModeCustomer: false,
      editModeContact: false,
      countries: null,
      currencies: null,
      visible: {
        company_details: true,
        company_address: true,
        finances: true,
        contact_person: true,
      }
    }
  },
  methods: {
    async save() {
      try {
        await this.company.save()
        this.$toast.requestSuccess('put', 'Company') // explicit put
        this.editModeCustomer = false
      } catch (errors) {
        Object.entries(errors.errors).forEach(([name, errors]) => {
          document.querySelector(`[name="${name}"]`).addClass('is-invalid')
          Object.values(errors).forEach(err => {
            document.querySelector(`[name="${name}"]`).parentElement
                .insertAdjacentHTML('beforeend', `<p class="mb-0" style="color: #dc3545; font-size: 12px">${err}</p>`)
          })
        })
      }
    }
  },
  async created() {
    this.$store.state.system.isLoading = true
    this.company = await ErpModel.find('companies', this.$route.params.id)
/* locales disabled for now #BLOCKZAPPS-1475
    if (typeof this.company.data.locales == 'string')
      this.company.data.locales = JSON.parse(this.company.data.locales)
 */
    let countries = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country?perpage=9999')
    this.countries = countries.data.data
    let currencies = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Currency?perpage=9999')
    this.currencies = currencies.data.data
    this.$store.state.system.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.disable-v-select {
  .vs__dropdown-toggle {
    height: auto !important;
    border: 0;
    background-color: #fff !important;

    input {
      background-color: #fff !important;
    }

    .vs__actions, .vs__deselect {
      display: none;
    }
  }
}

#note {
  font-weight: 600;
}

#drop_file {
  border: 2px dotted grey;
  border-radius: 2px;
}

.cont_personEmail {
  color: grey;
  font-weight: 500;
  word-wrap: break-word;
}

.settings-input {
  background-color: #fff;
  border: none;

  &:focus {
    outline: none;
  }
}

.border-transparent {
  border: 1px solid transparent;
}

input.rounded {
  border-radius: 50rem !important;
}

.client-info-row {
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.client-info-row:last-child {
  //border-bottom: 1px solid #dadada;
}
.client-info-row.last{
  border: none;
  border-top: 1px solid #dadada;
}

.input-container {
  padding: 1rem;
}

.container-title {
  margin-top: 0.5rem;
}

.border-right {
  border-right: 1px solid #dadada;
}
.vs__dropdown-toggle {
  border-radius: 50rem !important;
}
.top-save-btn{
  position: absolute;
  right: 0;
  top: -5rem;
}
.save-btn{
  text-align: center;
  justify-content: center;
}
</style>
