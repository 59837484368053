<template>
    <div class="appointment-modal">
        <div class="modal-default">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="text-dark" style="width: 100%; text-align: center;">
                      {{ translate('Add new {entity}', 'entities', { entity: translate('VAT rate', 'entities').toLowerCase() }) }}
                    </h5>
                    <i class="fas fa-times close-modal pr-2" @click="$modal.hide('addNewTaxRateModal')"></i>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <h6 style="padding-left: 15px; padding-bottom: 10px;}">{{ translate('Enter tax information for use in future invoices.', 'invoices') }}</h6>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Tax name', 'invoices') }} </label>
                            <input type="text" v-model="newTax.name" :placeholder="translate('For example VAT', 'invoices')" class="form-control">
                        </div>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Tariff', 'invoices') }}</label>
                            <div style="display:flex">
                            <input  type="number" v-model="newTax.tax" class="form-control">
                            <span class="py-2 px-2">%</span>
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <button class="btn btn-primary rounded-pill mt-3" @click="saveNewRate()" >{{ translate('Save changes', 'global') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddRate",
        props: {

        },
        data(){
            return {
                newTax:{
                    tax:'',
                    label:'',
                    name:''
                }

            }
        },
        methods: {
            saveNewRate(){
                this.newTax.label=this.newTax.tax+'%';
                let newTax={
                    ...this.newTax
                };
                this.$emit('newTaxRate',newTax)
                this.$modal.hide('addNewTaxRateModal');
                Object.keys(this.newTax).forEach(k => this.newTax[k] = '')
            }
        }
    }
</script>

<style lang="scss">
    .appointment-modal {
        border-radius: 1px;
        .modal-content {
            .modal-header {
                border-bottom: 1px solid $primary !important;

                .close-modal {
                    cursor: pointer;
                    color: #A3ADBD;
                }
            }

            .modal-body {
                label {
                    margin-bottom: 0.25rem;
                }

                .meeting-select .vs__dropdown-toggle {
                    padding: 11px 0 11px 10px;
                }
            }
        }
    }
</style>