
export const conditionOptions = [
  { key: 'lower', label: 'Lower than' },
  { key: 'greater', label: 'Greater than' },
  { key: 'equals', label: 'Equals' },
  { key: 'not_equals', label: 'Not equals' },
  { key: 'is_empty', label: 'Is empty' },
  { key: 'is_not_empty', label: 'Is not empty' },
  { key: 'one_of', label: 'One of' },
  { key: 'not_one_of', label: 'Not one of' },
]

export const conditionsWithoutInput = [
  'is_empty', 'is_not_empty',
]

export const customShortcodes = [
  { key: '{website_id}', value: 'Website id' },
  { key: '{primary_locale}', value: 'Primary locale' },
  { key: '{current_datetime}', value: 'Current datetime' },
  { key: '{current_datetime_iso}', value: 'Current datetime ISO' },
]