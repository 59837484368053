<template>
  <div>
    <div v-if="showCTA === true">
      <div class="cta-container">
        <div class="action-container"
             :style="{width: width + 'px', 'z-index': buttonsContainer == true ? 2 : 0}">
          <button class="action-btn icon rounded-circle icon-sm icon-shape"
                  @click="open" v-show="buttonsContainer == false">
            <i class="fa fa fa-ellipsis-h"></i>
          </button>
          <transition name="slide">
            <div class="buttons-container" v-show="buttonsContainer">
              <div class="buttons-container-inner" :style="{width: width + 'px' }">
                <slot></slot>
                <button class="action-btn icon rounded-circle icon-sm icon-shape"
                        @click="buttonsContainer = false">
                  <i class="fa fa fa-ellipsis-v"></i>
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div v-else>
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { busEvent } from "../../../utilities/eventBus"

export default {
  name: "CTA",
  data() {
    return {
      showCTA: false,
      buttonsContainer: false,
      width: 0,
    }
  },
  methods: {
    open() {
      busEvent.$emit('closeCta')
      this.buttonsContainer = true
    },
  },
  created() {
    busEvent.$on('closeCta', () => this.buttonsContainer = false)

    let counter = 0
    this.$slots.default.forEach(elm => {
      if (typeof elm.tag !== 'undefined') {
        counter++
        this.width = ((counter + 1) * 32) + (counter * 5)
      }
    })

    // always show cta button
    if (counter > 0) {
      this.showCTA = true
    }
  },
}
</script>

<style scoped lang="scss">
  .cta-container {
      position: relative;
  }

  .action-container {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: -15px;
    right: 0;
    height: 36px;
    z-index: 1;
    margin: 0 !important;

    .action-btn {
      background: #fff;
      border: 1px solid #000;
      color: #000;

      &:hover {
        background: #000;
        border: 1px solid #000;
        color: #fff;
      }
    }
  }

  .buttons-container {
    position: absolute;
    right: 0;
  }

  .icon-shape {
    border: none;
  }

  .buttons-container-inner {
    display: flex;
    justify-content: flex-end;
    background: transparent;
  }

  .table tbody tr:nth-child(odd) .buttons-container-inner {
    background: transparent;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .3s ease;
    opacity: 1;
  }

  .slide-enter, .slide-leave-to {
    transform: translateX(100%);
  }
</style>
