<template>
  <div class="erp-page-builder" :class="builderClasses">
    <editor component="div" :resolver-map="resolverMap">
      <builder-header/>

      <left-sidebar />
      <left-sidebar-content @loadData="loadData" />

      <div id="page-content" >
        <router-view :key="builder.meta.entity" />
      </div>

      <!-- Global modals -->
      <modal
        name="content-library-modal"
        :pivot-x="0.5"
        :pivot-y="0.5"
        width="1200px"
        height="700px"
        transition="slide-top"
        overlay-transition="wait"
        classes="remove-modal-height"
      >
        <content-library-modal />
      </modal>
      <!-- Global modals end -->
    </editor>

    <!-- Global loader -->
    <div v-if="$store.state.builder.showLoader" class="loader">
      <img :src="`/img/themes/${$store.state.system.theme}/page-builder/preloader-editor.gif`" alt="preloader-editor">
    </div>
    <!-- Global loader end -->
  </div>
</template>

<script>
import BuilderHeader from './components/BuilderHeader.vue'
import { mapActions, mapState } from 'vuex'
import BuilderMixin from './mixins/BuilderMixin'
import LeftSidebar from "./components/left-sidebar/LeftSidebar.vue"
import LeftSidebarContent from "./components/left-sidebar/LeftSidebarContent.vue"
import ContentLibraryModal from "./views/wizard/ContentLibraryModal.vue"

import {
  Canvas, Editor, Frame, Blueprint,
} from '../pagebuilder/craft'

import BuilderElements from '@/modules/pagebuilder/components/components/elements'
import { hasManyTranslationLocales } from "@/utilities/helper"

export default {
  name: 'App',
  components: {
    LeftSidebar, LeftSidebarContent, BuilderHeader, Editor,
    ContentLibraryModal,
  },
  mixins: [BuilderMixin],
  props: {
    value: {
      required: false,
    },
  },
  data() {
    return {
      resolverMap: {
        ...BuilderElements,
        Canvas,
      },
    }
  },
  computed: {
    ...mapState('builder', ['showLoader', 'entity']),
    ...mapState('layout', ['responsiveMode']),
    builderClasses() {
      const classes = []
      if (this.$store.state.layout.responsiveMode) {
        classes.push('responsive-menu-open')
      }
      return classes
    },
  },
  methods: {
    ...mapActions('builder', [
      'fetchEntities',
    ]),
  },
}
</script>

<style lang="scss">
// 50px = .builder-header-container + 1px border
// 99px = 2 x .builder-header-container + 1px border
// 43px = #left-sidebar
#app {
  margin: 1px 1px 13px 1px; // 13px for the add new section button which causes overflow, 1px on the sides to make the outline visible
  min-height: unset !important;
}

.has-dragged-node {
  .builder-header {
    display: none;
  }

  #left-sidebar {
    padding-top: 50px;
  }

  #page-content {
    padding-top: 50px;
  }
}

#page-content {
  width: 100%;
  padding-left: 43px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.responsive-menu-open {
  #page-content {
    height: calc(100vh - 99px);
  }

  .has-dragged-node {
    #left-sidebar {
      padding-top: 99px;
      height: 100vh;
    }
    #page-content {
      padding-top: 99px;
    }
  }

  .left-sidebar-content {
    top: 99px;
  }
}

.loader {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 15;
  top: 50px;
  left: 0;
  box-shadow: inset 0 3px 4px -4px rgba(0, 0, 0, 0.5);

  img{
    width: 90px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

//@import 'assets/scss/all';

/* HOT FIXES BECAUSE OF app.css */

.vc-chrome-fields .vc-input__input {
  font-size: 11px!important;
}
// Icons
.icon-erp-plus:before {
  content: "\e953"!important;
}

/* END HOT FIXES BECAUSE OF app.css */
</style>