<template>
  <div>
    <page-title :page-title="translate('Rock Dashboard', 'global')" />

    <template v-for="(entity, idx) in roleSettingFields">
      <role-setting v-if="entity" :key="idx" :entity="entity.data_module" />
    </template>
  </div>
</template>

<script>
import RoleSetting from "@/modules/system/views/dashboards/components/RoleSetting.vue"

export default {
  name: "RockDashboard",
  components: { RoleSetting },
  data() {
    return {
      roleSettingFields: null,
    }
  },
  async created() {
    this.$store.state.system.isLoading = true

    const currentRole = await this.erp.ext.request.axiosInstance(`/modules/roles/${this.$store.state.system.authData.roles[0].id}`)
    this.roleSettingFields = currentRole.data.data.settings.fields

    this.$store.state.system.isLoading = false
  },
}
</script>
