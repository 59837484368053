<template>
  <div
    class="dropdown dropdown-animate align-middle"
    data-toggle="hover"
    style="width: 36px;"
  >
    <button class="btn rounded dfc w-100 h-100 py-0">
      <i :class="deviceTypes[deviceType]"></i>
    </button>
    <div class="dropdown-menu" style="min-width: unset; width: 36px;">
      <div class="d-flex flex-column w-100">
        <button
          v-for="(device, idx) in deviceTypes"
          :key="idx"
          class="btn btn-clear text-center dropdown-item px-0 rounded-0"
          @click="switchDevice(device, idx)"
        >
          <i :class="device"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: 'DevicesDropdown',
  inject: ['editor'],
  data() {
    return {
      deviceTypes: {
        'desktop': 'icon-erp-device-desktop',
        'tablet': 'icon-erp-device-tablet-portrait',
        'mobile': 'icon-erp-device-mobile-portrait',
      },
    }
  },
  computed: {
    ...mapGetters('layout', ['deviceType']),
  },
  methods: {
    ...mapActions('layout', [
      'changeDeviceViewport',
    ]),
    switchDevice(device, key) {
      if(!this.$store.state.layout.responsiveMode){
        this.$store.state.layout.responsiveMode = true
      }

      this.changeDeviceViewport(key)
    },
  },
}
</script>