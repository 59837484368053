<template>
  <div class="row" v-if="data">
    <div class="col-md-6">
      <div class="nav-sides">
        <h4 class="mb-3">{{ translate('Available links', 'global') }}</h4>
        <draggable :list="entitiesData.pages"
                   v-bind="dragOptions"
                   @start="dragStart"
                   @end="dragEnd"
        >
          <div v-for="(page, pageIndex) in entitiesData.pages"
               :key="'nav-page-'+pageIndex"
               class="draggable-item py-1">
            <div class="nav-link-title">
              <!-- If you need me use computed because this slows down the drag and drop -->
              <!-- {{ translate('Page', 'page-builder') }}: -->
              {{ page.original_name }}
            </div>
          </div>
        </draggable>

        <h4 class="mb-3">{{ translate('Categories links', 'global') }}</h4>
        <draggable :list="entitiesData.categories"
                   v-bind="dragOptions"
                   @start="dragStart"
                   @end="dragEnd"
        >
          <div v-for="(category, categoryIndex) in entitiesData.categories"
               :key="'nav-cat-'+categoryIndex"
               class="draggable-item py-1">
            <div class="nav-link-title">
              <!-- If you need me use computed because this slows down the drag and drop -->
              <!-- {{ translate('Category', 'page-builder') }}: -->
              {{ category.original_name }}
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div class="col-md-6">
      <div class="nav-sides">
        <h4 class="mb-3">{{ translate('Navigation', 'page-builder') }}</h4>

        <div class="row-table">
          <div class="row bg-white">
            <div class="col-md-5">{{ translate('Title', 'categories') }}</div>
            <div class="col-md-4">{{ translate('Show on mobile', 'categories') }}</div>
            <div class="col-md-3 text-right">{{ translate('Actions', 'global') }}</div>
          </div>
        </div>

        <RightNavigation :value="data"
                        @input="e=>$emit('input', e)"
                        @dragStart="$store.state.system.dragInProgress = true"
                        @dragEnd="$store.state.system.dragInProgress = false"
        />
        <div class="nav-link-title_add add-page-body mt-2" @click="addNewLink">
          <span class="mr-3"><i class="fa fa-plus-circle"></i></span>
          <span>{{ translate('Add custom link', 'global') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import RightNavigation from "./RightNavigation.vue"

export default {
  name: "NavigationLinks",
  components: {
    draggable, RightNavigation,
  },
  props: {
    value: {
      required: true,
    },
    entitiesData: { // pages, categories
      required: true,
    },
  },
  data() {
    return {
      data: null,
      drag: false,
      page: null,
    }
  },
  computed: {
    dragOptions() {
      return {
        draggable: ".draggable-item",
        group: { name: 'nav', pull: 'clone', put: false },
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        dragClass: "draggable-ghost",
      }
    },
  },
  methods: {
    addNewLink() {
      this.$emit('input', [...this.value, { name: 'Custom link', entity: "", visible_on_mobile: 1, nav: [], extras: [] }])
    },
    dragStart(){
      this.drag = true
      this.$store.state.system.dragInProgress = true
    },
    dragEnd(){
      this.drag = false
      this.$store.state.system.dragInProgress = false
    },
    formatMenu(menu){
      menu.forEach(m => {
        // we need original name for entities
        if(m.entity === 'cms_pages'){
          const entity = this.entitiesData.pages.find(el => el.entity_id == m.entity_id)
          m.original_name = entity ? entity.original_name : 'DELETED'
        } else if (m.entity === 'categories'){
          const entity = this.entitiesData.categories.find(el => el.entity_id == m.entity_id)
          m.original_name = entity ? entity.original_name : 'DELETED'
        }

        // menus must have nav for nesting to work
        if(!m.nav) {
          //this.$set(m, 'nav', [])
          m.nav = []
        } else {
          m.nav = this.formatMenu(m.nav)
        }
      })
      return menu
    },
  },
  watch: {
    'value': {
      deep: true,
      handler() {
        this.data = this.formatMenu(this.value)
      },
    },
  },
}
</script>

<style lang="scss">
.draggable-ghost {
  .nav-link-title_add {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.draggable-item {
  padding-bottom: 10px;

  .nav-link-title {
    min-height: 54px; // hot fix because some categories doesn't have name
    background: #fff;
    padding: 1rem;
    border: 1px solid #dedede;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .handle {
      cursor: pointer;
    }
  }
}

.nav-link-title_add {
  background: #cccccc69;
  color: #69a3f6;
  padding: 1rem;
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    font-size: 20px;
  }
}

.add-page-body {
  border: 1px solid #dedede;
  border-radius: 5px;
}

.nav-sides {
  height: calc(100vh - 330px);
  overflow: auto;
}
</style>
