<template>
  <div class="col-style">
    <div class="col-style-child p-3">
      <div class="row align-items-center">
        <div class="col-md-5 system-text-primary font-weight-bold">
          {{ translate('Shipping method', 'orders') }}
        </div>
        <div class="col-md-7">
          <v-select
            :options="value['shipping_rates'] ? value['shipping_rates'] : []"
            label="courier_name"
            :placeholder="translate('Choose shipping method', orders)"
            @input="setShippingMethod($event)"
          />
        </div>
      </div>

      <div class="row mt-1">
        <div class="col">
          <small class="text-muted">
            {{ translate('Available shipping methods based on the selected shipping address.', 'orders')}}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderShippingMethods",
  props: {
    value: {
      required: true,
    },
  },
  methods:{
    setShippingMethod(e){
      if (e) {
        this.value.shipping_method = e['courier_name']
        this.value.shipping_method_id = e['shipping_method_id']
      } else {
        this.value.shipping_method = null
        this.value.shipping_method_id = null
      }
    },
  },
}
</script>
