<template>
  <div>
    <draggable :value="value"
               @input="e => $emit('input', e)"
               v-bind="dragOptions"
               @start="dragStart"
               @end="dragEnd"
    >
      <div v-show="value.length" v-for="(m, menuL) in value" :key="menuL + key" class="draggable-item pt-2">
        <div class="d-flex dragNdrop nav-link-title draggable-item-child align-items-center">
            <!-- DISABLED FOR NOW BECAUSE WE DON'T HAVE BULK ACTIONS
            <div class="col-md-1">
              <div class="custom-control custom-checkbox">
                <input type="checkbox"
                  class="my-auto custom-control-input"
                  v-model="value[menuL].selected"
                  :id="`option-${menuL}`"
                >
                <label class="custom-control-label" :for="`option-${menuL}`"></label>
              </div>
             </div>
            -->

          <div class="col-md-6" :class="{'font-weight-bold': group === 'groups'}">
            <template v-if="m.name === '__default__'">
              {{ translate("Without group", 'attributes') }}
            </template>
            <template v-else-if="m.label">
              {{ entityLabel(m.label) }}
<!--              ({{ m.name }}) -->
            </template>
            <template v-else>
              {{ m.name }}
            </template>
          </div>

          <div class="col-md-3 text-center font-weight-600">
            <template v-if="m.type !== 'group'">
              <!-- there was also this check in the old code, v-if="!attr.rules || attr.rules.length === 0" -->
              <div class="custom-control custom-switch mx-2">
                <input
                  type="checkbox"
                  v-model="m.required"
                  :true-value="1" :false-value="0"
                  name="show"
                  :id="`required-${menuL}-${generateNum}`"
                  class="custom-control-input"
                  :value="m.required"
                />
                <label
                  :for="`required-${menuL}-${generateNum}`"
                  class="custom-control-label"
                ></label>
              </div>
            </template>
          </div>

          <div class="col-md-3 text-right">
<!--            <cta>-->
              <action-button
                v-if="m.type === 'group' && m.name !== '__default__'"
                class-icon="fa-edit"
                @click.native="editLink(menuL)"/>

              <action-button
                v-if="canDelete(m)"
                @click.native="removeLink(menuL)"
                class-icon="fa-trash"
              />

<!--            </cta>-->
          </div>
        </div>

        <RightNavigation
          v-if="m.fields"
          class="ml-4"
          group="fields"
          v-model="m.fields"
          :parent="m"
          @dragStart="$emit('dragStart')"
          @dragEnd="$emit('dragEnd')"
        />
      </div>
    </draggable>

    <modal v-if="value"
           :name="`edit-link-${key}`"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="auto"
           :scrollable="true"
           :adaptive="true"
           classes="edit-link-modal remove-modal-height"
    >
      <edit-link :key="pageIndex" v-model="value[pageIndex]" @close="$modal.hide(`edit-link-${key}`)" :edit_key="key"></edit-link>
    </modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import EditLink from "./EditLink.vue"

export default {
  name: "RightNavigation",
  components: {
    draggable,
    EditLink,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    group: {
      required: true,
    },
    parent: {
      required: false,
    },
  },
  data() {
    return {
      drag: false,
      left: [],
      pageIndex: null,
      key: Math.floor((Math.random() * 100) + 1)
    }
  },
  computed: {
    dragOptions() {
      return {
        draggable: ".draggable-item",
        group: this.group, // groups or fields
        //group: { name: 'nav', pull: 'clone', put: false },
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        dragClass: "draggable-ghost",
        handle: ".dragNdrop",
      }
    },
    generateNum() {
      return Math.floor((Math.random() * 100) + 1)
    },
  },
  methods: {
    canDelete(item) {
      // todo if it is a group check if the fields inside can also be removed
      if (item.type === 'group') {
        if (item.is_default) {
          return false
        } else {
          //return !item.fields.some(attribute => attribute.requires_attribute)
          return !item.fields.length
        }
        return true
      } else {
        //return !item.requires_attribute
        return false // no delete for now, on delete we must return the attribute back to the availableAttributes
      }
    },
    // used to show/hide add new item rows
    dragStart(){
      this.$emit('dragStart')
    },
    dragEnd(){
      this.$emit('dragEnd')
    },
    // END used to show/hide add new item rows
    editLink(i) {
      this.pageIndex = i
      this.$modal.show(`edit-link-${this.key}`)
    },
    updateLink(v){
      this.$emit('input', this.value)
    },
    removeLink(i) {
      this.value.splice(i, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  cursor: grabbing;
}

.draggable-item {

  .draggable-item-child {
    border: 1px solid #dedede;
    border-radius: 5px;
  }

  .dragNdrop {
    cursor: grab;
  }

  &.sortable-chosen {
    .nav-link-title {
      cursor: grabbing;
    }
  }

  .nav-link-title {
    background: #fff;
    padding: 1rem;
  }

  .nav-link-title_add {
    background: #cccccc69;
    color: #69a3f6;
    padding: 1rem;
    border: 1px solid $border-color;
    align-items: center;
    cursor: pointer;
    border-bottom: 0;

    .fa {
      font-size: 20px;
    }
  }

  .add-page-body {
    border: 1px solid #dedede;
    border-radius: 5px;
  }

  .btn-group{
    min-width: 50px;
  }
}
</style>
