<template>
  <div class="container">
    <page-title :page-title="translate('Translation settings', 'settings')">
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div class="card rounded bg-white">
      <div class="card-header">
        <h5>{{ translate('Translation settings', 'settings') }}</h5>
      </div>
      <div class="container" v-if="data.website_locales !== null">
        <div class="row">
          <div class="col-md-12 p-0 overflow-hidden">
            <ul class="list-group mt-2 mb-2">
              <li class="list-group-item row d-flex">
                <div class="col-md-10">
                  <h6 class="font-weight-700">{{ translate('Languages', 'settings') }}</h6>
                </div>
                <div class="col-md-2 text-center">
                  <a href="#" class="text-black" @click="showModal">
                    <i class="icon-erp-plus icon-xl p-2 mt-1 font-weight-700 border-bold-circle"></i>
                  </a>
                </div>
              </li>

              <li class="list-group-item row d-flex align-items-center" v-if="data.website_locales" v-for="(locale, key) in Object.keys(data.website_locales)" :key="key">
                <div class="col-md-1">
                  <img width="40" height="40" class="rounded-circle" :src="'/img/flags/locales/1x1/'+locale+'.svg'" :alt="allLocalesObject[locale].name">
                </div>

                <div class="col-md-6">
                  <span>{{ allLocalesObject[locale].name }}</span>
                  <i class="icon-erp-trash text-danger ml-2 cursor-pointer" @click="removeLocale(locale)"></i>
                </div>

                <div class="col-md-3">
                  <a href="#" disabled
                     @click="data.website_primary_locale = locale"
                     :class="{'text-black' : data.website_primary_locale === locale}"
                  >{{ translate(data.website_primary_locale === locale ? 'Primary' : 'Set Primary', 'settings') }}</a>
                </div>

                <div class="col-md-2">
                  <div class="d-flex justify-content-between py-2 m-auto" style="width: 48px">
                    <div class="custom-control custom-switch">
                      <input
                          type="checkbox"
                          name="status"
                          :id="'status-'+key"
                          :true-value="1"
                          :false-value="0"
                          class="custom-control-input"
                          v-model="data.website_locales[locale].status"
                      />
                      <label :for="'status-'+key" class="custom-control-label"></label>
                    </div>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>

    <modal name="add_locale"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="600px"
           transition="slide-top"
           overlayTransition="wait"
           classes="remove-modal-height">
      <add-locale @hideModal="hideModal" @localeChosen="addLocale" :website_locales="data.website_locales" :allLocalesObject="allLocalesObject"></add-locale>
    </modal>

  </div>
</template>

<script>
import AddLocale from "./AddLocale";
import {allLocales, changeTranslationLocale} from "@/utilities/helper";
import ErpModel from "@/modules/erp_framework/services/ErpModel";
import store from "@/store";

export default {
  name: 'Settings',
  props: {},
  components: {AddLocale},
  data() {
    return {
      show_add_modal: false,
      allLocalesObject: null,
      website: null,
      data: {
        website_locales: null,
        website_primary_locale: null
      }

    }
  },
  async created() {
    // all locales
    this.allLocalesObject = {}
    let locales = await allLocales()
    for (let locale of locales){
      this.allLocalesObject[locale.key] = {name: locale.name}
    }
  },
  methods: {

    showModal(){
      this.$modal.show('add_locale');
    },
    hideModal(){
      this.$modal.hide('add_locale');
    },

    addLocale(locale){
      this.$set(this.data.website_locales, locale, {status: 0})
    },

    removeLocale(locale){
      this.$delete(this.data.website_locales, locale)
    },

    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)

      // new sites compatibility
      if(this.website._data.website_locales === undefined){
        this.website._data.website_locales = '{}'
      }
      // backward compatibility
      if(this.website._data.website_primary_locale === undefined){
        this.website._data.website_primary_locale = ''
      }

      this.data.website_locales = JSON.parse(this.website._data.website_locales)
      this.data.website_primary_locale = this.website._data.website_primary_locale
    },

    async save(){

      // Validation
      if(Object.keys(this.data.website_locales).length === 0){
        return this.$toast.error('Please add at least one language')
      }
      if(this.data.website_locales[this.data.website_primary_locale] === undefined){
        return this.$toast.error('Please choose your primary language')
      }
      if(this.data.website_locales[this.data.website_primary_locale].status === 0){
        return this.$toast.error('Primary language cannot be disabled')
      }
      // END Validation

      this.$store.state.system.isLoading = true

      this.website._data.website_primary_locale = this.data.website_primary_locale
      this.website._data.website_locales = this.data.website_locales
      await this.website.save();

      this.$store.state.system.scope.obj.website_locales = this.data.website_locales;

      // change translation locale if it is not present anymore
      if(this.data.website_locales[this.$store.state.system.translation_locale] === undefined){
        changeTranslationLocale(this.data.website_primary_locale)
      }

      // let route = this.$route.params.id ?
      // this.erp.ext.request.axiosInstance.put(`/modules/languages/${this.$route.params.id}`, this.data) :
      // this.erp.ext.request.axiosInstance.post('/modules/languages', this.data)

      // try {
      //   await route
      this.$toast.requestSuccess('put', 'Translation settings') // explicit put
      // } catch (e) {
      //   this.$toast.errorGeneral()
      // }

      this.$store.state.system.isLoading = false
    },
  },
  mounted() {
    this.findWebsite()
  }
}
</script>

<style scoped>
.border-bold-circle {
  border: 2px solid #000;
  border-radius: 50rem;
}
.text-black{
  color: #000;
}

</style>
