<template>
  <div class="tabbable">
    <ul class="nav nav-tabs mb-4">
      <li>
        <router-link :to="`/webshop-customers/${$route.params.id}/account-information`" class="nav-link"
                     :class="{'active': $router.currentRoute.name == 'account-information'}">
          {{ translate('Account Information', 'global') }}
        </router-link>
      </li>
      <li>
        <router-link :to="`/webshop-customers/${$route.params.id}/addresses`" class="nav-link"
                     :class="{'active': ['addresses', 'addresses-create', 'addresses-update'].includes($router.currentRoute.name)}">
          {{ translate('Addresses', 'global') }}
        </router-link>
      </li>
      <li>
        <router-link :to="`/webshop-customers/${$route.params.id}/orders`" class="nav-link"
                     :class="{'active': $router.currentRoute.name == 'customer-orders'}">
          {{ translate('Orders', 'global') }}
        </router-link>
      </li>
    </ul>
  </div>

</template>

<script>

export default {
  name: "Tabs"
}
</script>

<style scoped>

</style>
