import { render, staticRenderFns } from "./AddNewTaxRateModal.vue?vue&type=template&id=aa04d524&"
import script from "./AddNewTaxRateModal.vue?vue&type=script&lang=js&"
export * from "./AddNewTaxRateModal.vue?vue&type=script&lang=js&"
import style0 from "./AddNewTaxRateModal.vue?vue&type=style&index=0&id=aa04d524&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports