<template>
    <div class="dashboard-skitle container">
        <page-title :page-title="translate('Dashboard', 'dashboard')"></page-title>

        <template v-if="$store.state.system.theme === 'blockzapp'">

          <main-content :trial="trial" :steps="steps" :website="website" :plan="plan" :info="info"
                        :website-is-published="isWebsitePublished" @publish="publishWebsite"></main-content>
          <settings-boxes :info="info" :website="website" :is-website-published="isWebsitePublished" @publish="publishWebsite"></settings-boxes>
          <published-website :info="info" :website="website" :is-website-published="isWebsitePublished"></published-website>
          <loader v-if="loading"></loader>
          <help></help>

        </template>
    </div>
</template>
<script>
import WebsiteProgress from "../../../../mixins/WebsiteProgress";

import MainContent from "../../components/dashboard/MainContent";
import SettingsBoxes from "../../components/dashboard/SettingsBoxes";
import Loader from "../../components/utilities/Loader";
import HelpContainer from "../../components/dashboard/HelpContainer";
import PublishedWebsite from "../../components/dashboard/PublishedWebsite";

export default {
    name: 'SkitleDashboard',
    mixins: [WebsiteProgress],
    async created() {
        if (this.$store.state.system.scope && this.$store.state.system.scope.value)
            await this.loadData()
    },
    components: {
        MainContent, SettingsBoxes, Loader, 'help': HelpContainer, PublishedWebsite
    },
}
</script>

<style lang="scss">
    @import "../../../../assets/scss/layout/dashboards";
</style>
