<template>
  <!-- 'text', 'number', 'int' -->
  <span>
    <!-- todo make it work for int type -->
    <div class="text-right field-actions-container" v-if="hasActionShortcodes && !isReadonly">
      <shortcodes-dropdown
        :entity="entity"
        :shortcodes="shortcodes"
        @shortcodeAppend="shortcode => shortcodeAppend(shortcode)"
      />
    </div>

    <div class="input-group">

      <input v-if="fieldProps && fieldProps.translatable == 1"
             class="form-control"
             v-model="translationModel"
             :type="fieldType"
             :placeholder="placeholderComputed"
             :required="required"
             :class="fieldClasses"
             :readonly="isReadonly"
             :ref="fieldRef"
      />
      <input v-else class="form-control"
             v-model="nonTranslationModel"
             :type="fieldType"
             :placeholder="placeholderComputed"
             :required="required"
             :class="fieldClasses"
             :readonly="isReadonly"
             :ref="fieldRef"
      />

      <div v-if="fieldProps && fieldProps.input_append" class="input-group-append">
        <span class="input-group-text">{{ fieldProps.input_append }}</span>
      </div>

      <div v-if="hasActionLocales" class="input-group-append">
        <locales-dropdown/>
      </div>

    </div>
  </span>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"
import LocalesDropdown from "./components/LocalesDropdown.vue"
import ShortcodesDropdown from "./components/ShortcodesDropdown.vue"

export default {
  name: 'InputField',
  mixins: [FormFieldMixin, FormInputMixin],
  components: {
    LocalesDropdown, ShortcodesDropdown,
  },
  props: {
    entity: {
      required: false,
    },
    // min: {
    //   required: false,
    // },
    // max: {
    //   required: false,
    // },
    fieldRef: {
      required: false,
      default: 'input',
    },
    shortcodes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    fieldType() {
      if(this.fieldProps.type === 'int') {
        return 'number'
      }
      // text, number
      return this.fieldProps.type
    },
  },
}
</script>