import { render, staticRenderFns } from "./EntityPermissions.vue?vue&type=template&id=275cbc38&scoped=true&"
import script from "./EntityPermissions.vue?vue&type=script&lang=js&"
export * from "./EntityPermissions.vue?vue&type=script&lang=js&"
import style0 from "./EntityPermissions.vue?vue&type=style&index=0&id=275cbc38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275cbc38",
  null
  
)

export default component.exports