<template>
  <div id="builder" class="app-page">
    <div class="preview-title w-100 d-flex justify-content-center">
      <span>{{ translate('Preview mode', 'page-builder') }}</span>
    </div>
    <div class="app-page-preview container-fluid" :emulator="deviceType">
      <div id="dom" :class="deviceType">
        <div class="row">
          <div v-if="componentData" class="col-md-12 mt-5">
            <div class="card card-list text-left container">
              <img
                :alt="componentData.title"
                :src="image"
                class="img-list-layout"
              >
              <div class="card-body card-portfolio">
                <h5 class="card-title">
                  {{ componentData.title }}
                </h5>

                <div v-if="builder.meta.entity === 'blogs'" class="card-text" v-html="componentData.description" />
                <div v-if="builder.meta.entity === 'portfolios'" class="card-text" v-html="componentData.content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin" // required because of builder.meta.entity above, remove me once all entities use the same field name
import EntityShowMixin from "@/modules/pagebuilder/mixins/EntityShowMixin"

export default {
  name: 'PostPreview',
  mixins: [BuilderMixin, EntityShowMixin],
  data() {
    return {
      entity: 'portfolios',
    }
  },
  computed: {
    ...mapGetters('layout', [
      'deviceType',
    ]),
  },
}
</script>

<style scoped lang="scss">
.app-page .app-page-preview {
    width: 100%;
}

.card-body a,
.card-text {
    color: black;
    font-weight: 600;
}

.card-body a:hover {
    color: grey;
}

.card-title {
    color: black;
    font-weight: 700;
}

.card-portfolio {
    padding-left: 0;
    padding-right: 0;
}

.card.card-list {
    display: block;
}

.preview-title {
	border-bottom: 1px solid #ccc;

	span {
		font-size: 40px;
		color: #ccc;
	}
}
</style>
