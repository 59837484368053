<template>
  <ee-form-color-picker
    :value="value"
    :is-readonly="isReadonly"
    @input="$emit('input', $event)"
  />
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker.vue"

export default {
  name: "ColorPickerField",
  components: { EeFormColorPicker },
  mixins: [FormFieldMixin],
}
</script>

