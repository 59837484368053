<template>
  <page-title :page-title="translate('Invoices', 'entities')"></page-title>
</template>

<script>
export default {
  name: "Invoices"
}
</script>

<style scoped>

</style>