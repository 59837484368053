<template>
  <div class="container-fluid">
    <page-title :page-title="translate('Request quotes', 'orders')" v-if="records"
                :counter="records.meta.total"></page-title>
    <div class="row" v-if="records">
      <div class="col-md-12">
        <div class="main-table mt-4">
          <div class="d-flex justify-content-between align-items-center table-header px-4">
            <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
              <div class="search-icon">
                <span><i class="fa fa-search"></i></span>
              </div>
              <input type="text" class="form-control h-100 search-field"
                     :placeholder="translate('Search by request quote number', 'orders')"
                     :value="userPreferences.search"
                     @input="e => setSearch(e.target.value)">
            </div>
            <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
              <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="records">
                  {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                  <i class="fa fa-chevron-down"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                  <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">
                    {{per_page.value}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center m-0">
              <thead class="thead-light">
              <tr>
                <th scope="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" @input="e => selectAll(e.target.checked)"
                           class="custom-control-input"
                           :id="`select_all`">
                    <label class="custom-control-label" :for="`select_all`"></label>
                  </div>
                </th>
                <th scope="col" class="sortable" @click="sortByColumn('quote_request_number')">
                  {{ translate('Request quote number', 'orders') }}
                  <i class="fa fa-sort"></i>
                </th>
                <th scope="col">{{ translate('name', 'orders') }}</th>
                <th scope="col">{{ translate('email', 'orders') }}</th>
                <th scope="col">{{ translate('Created at', 'global') }}</th>
                <th scope="col">{{ translate('total amount', 'orders') }}</th>
                <th scope="col">{{ translate('status', 'orders') }}</th>
                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-1" v-for="(quote, quoteI) in records.data">
                <td>
                  <div class="custom-control custom-checkbox">
                    <input :id="'row-' + quoteI" type="checkbox" class="custom-control-input"
                           v-model="records.data[quoteI].selected">
                    <label class="custom-control-label" :for="'row-' + quoteI"></label>
                  </div>
                </td>
                <td>
                  <router-link :to="`/request-quotes/${quote.id}`"
                               class="table">
                    {{ quote.quote_request_number }}
                  </router-link>
                </td>
                <td>{{ quote.billing_client_first_name }} {{quote.billing_client_last_name}}</td>
                <td>{{ quote.billing_client_email }}</td>
                <td>{{ formatDate(quote.created_at, null, 'DD MMM YY') }}</td>
                <td>{{ parseFloat(quote.total_amount).toFixed(2) }}</td>
                <td>
                  <span class="status-badge" :class="`${quote.status}`">
                      {{ translate(`${quote.status}`, 'orders') }}
                  </span>
                </td>
                <td class="text-right">
                  <cta>
                    <!-- <action-button class-icon="fa fa-pen" @click.native="$router.push(`/webshop-orders/edit/${order.id}`)"></action-button> -->
                    <action-button class-icon="fa fa-eye" @click.native="$router.push(`/request-quotes/${quote.id}`)"></action-button>
                    <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(quote.id)"></action-button>
                  </cta>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                      :value="records.meta" v-if="records && records.meta"
                      @goToPage="v => setProp('page', v)"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import EntityIndexMixin from "@/mixins/EntityIndexMixin"

export default {
  name: 'RequestQuoteList',
  mixins: [EntityIndexMixin],
  async created() {
    this.entityType = 'sales/quote-requests'
    await this.loadUserPreferences()
    await this.loadData()
  },
}
</script>

