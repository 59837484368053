<template>
    <a class="fc-time-grid-event fc-v-event fc-event fc-start fc-end">
        <div class="fc-content">
            <div class="fc-title">{{ 'tracker.started_progress_on_task'}}: {{value.data.name}}</div>
        </div>
        <div class="fc-bg"></div>
        <div class="fc-event-description">
            <p>{{value.data.description.slice(0, 100)}}</p>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        }
    },
    computed: {
        tags() {
            if (this.value.data.tag) {
                return JSON.parse(this.value.data.tag)
            }
            return []
        },
        priority(){
            if (this.value.data.priority) {
                return JSON.parse(this.value.data.priority)
            }
            return []
        },
        assignee(){
            if (this.value.data.assignee) {
                return JSON.parse(this.value.data.assignee)
            }
            return []
        }
    }
};
</script>
