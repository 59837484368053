<template>
  <div class="progress-new w-100">
    <div
      class="progressbar"
      role="progressbar"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="0"
      :style="progressBarStyle"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: {
      required: true,
    },
    total: {
      required: true,
    },
    color: {
      required: false,
    },
    colorful: {
      default: false,
    },
  },
  computed: {
    progressBarStyle(){
      let styles = {
        width: this.calculateWidth+'%',
      }

      if(this.color){
        styles.backgroundColor = this.color
      } else if(this.colorful){
        styles.backgroundColor = this.getBackgroundColor
      }

      return styles
    },
    calculateWidth() {
      return (this.value * 100) / this.total
    },
    getBackgroundColor() {
      // Same as .bg-rank- classes, on change don't forget to modify them
      const percent_ceil = Math.ceil(this.calculateWidth / 5) * 5

      if (percent_ceil === 0) return '#dd776e'  // never used
      else if (percent_ceil === 5) return '#e0816d'
      else if (percent_ceil === 10) return '#e2886c'
      else if (percent_ceil === 15) return '#e5926b'
      else if (percent_ceil === 20) return '#e79a69'
      else if (percent_ceil === 25) return '#e9a268'
      else if (percent_ceil === 30) return '#ecac67'
      else if (percent_ceil === 35) return '#e6ad61'
      else if (percent_ceil === 40) return '#e9b861'
      else if (percent_ceil === 45) return '#f3c563'
      else if (percent_ceil === 50) return '#f5ce62'
      else if (percent_ceil === 55) return '#e2c965'
      else if (percent_ceil === 60) return '#d4c86a'
      else if (percent_ceil === 65) return '#c4c56d'
      else if (percent_ceil === 70) return '#b0be6e'
      else if (percent_ceil === 75) return '#a4c073'
      else if (percent_ceil === 80) return '#94bd77'
      else if (percent_ceil === 85) return '#84bb7b'
      else if (percent_ceil === 90) return '#73b87e'
      else if (percent_ceil === 95) return '#63b682'
      else if (percent_ceil === 100) return '#57bb8a'

      // old code
      // if (this.calculateWidth <= 25) {
      //     return `linear-gradient(90deg, ${this.firstColor} 0%, ${this.secondColor} 100%)`
      // } else if (this.calculateWidth <= 50) {
      //     return `linear-gradient(90deg, ${this.firstColor} 0%, ${this.secondColor} 50%, ${this.thirdColor} 100%)`
      // } else if (this.calculateWidth <= 75) {
      //     return `linear-gradient(90deg, ${this.firstColor} 0%, ${this.secondColor} 50%, ${this.thirdColor} 100%)`
      // } else {
      //     return `linear-gradient(90deg, ${this.firstColor} 0%, ${this.secondColor} 33%, ${this.thirdColor} 66%, ${this.forthColor} 100%)`
      // }

    },
  },
}
</script>
