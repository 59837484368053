<template>
  <!-- DEPRECATED, TODO REMOVE ME AFTER COMPONENT IS CHANGED TO CustomCode on all envs -->
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">
        <div class="row">
          <div class="col-12">
            <codemirror v-model="model" :options="cmOptions"></codemirror>
          </div>

          <div class="col-12">
            <div class="text-right mt-3">
              <!-- <a @click="$emit('closeSettingsPopup')" class="btn btn-cancel rounded-pill mr-2">{{ translate('Cancel', 'page-builder') }}</a> -->
              <a
                  @click.prevent="save"
                  href="#"
                  class="btn btn-primary rounded-pill"
              >{{ translate('Save changes', 'global') }}</a>
            </div>
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>

import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import setingMixin from "@/modules/pagebuilder/craft/components/settingMixin"; // required for this.node / elementProps
import { codemirror } from 'vue-codemirror'
/* this was in the html editor in the sidebar but does not seem required
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/theme/oceanic-next.css'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/jump-to-line.js'
*/
export default {
  name: 'CustomHtmlSettings',
  mixins: [ComponentSettingsMixin, setingMixin],
  components: {
    codemirror
  },
  props: {
      value: {
          required: true,
      },
  },
  data() {
      return {
          cmOptions: {
              tabSize: 4,
              styleActiveLine: true,
              lineNumbers: true,
              //autoCloseTags: true, // we don't need this, by default false
              line: true,
              mode: 'text/html',
              theme: 'oceanic-next',
              search: true,
              // lineWrapping: true // we don't need this, by default false
          },
          key: null,
          tempContent: ''
      }
  },
  computed: {
    model: {
      get(){
        return this.tempContent
      },
      set(content){
        this.tempContent = content;
      }
    },
  },
  created() {
    this.model = this.node.props.content
  },
  methods: {
    save() {
      // todo validate html
      this.node.setProps({ content: this.tempContent });
    }
  },
}
</script>