<template>
    <div>
        <div class="card tracker system-text-primary align-items-center">
                
                    <i v-if="!tracking" class="fa fa-play pr-3 pl-1 cursor-pointer" @click="startTracking"></i>
                    <i v-else class="fa fa-pause pr-3 pl-1" @click="pauseTracking"></i>
                    {{timePassed}}
                    <i class="fa fa-eye pl-3 pr-1 cursor-pointer" @click="$modal.show('tracker')"></i>
                    <i class="fa fa-times pl-3 pr-1 cursor-pointer" aria-hidden="true" @click="clearTracking"></i>

        </div>
        <modal
                :name="'tracker'"
                :pivot-x="1"
                :pivot-y="0"
                transition="slide-fade"
                overlayTransition="wait"
                height="100%"
                width="350px"
                :adaptive="true">
            <div class="modal-content tracker-container">
                <div class="modal-header align-items-center">
                    <div class="tracker-controls">

                        <i v-if="!tracking" class="fa fa-play" @click="startTracking"></i>
                        <i v-else class="fa fa-pause" @click="pauseTracking"></i>
                        <div>
                            {{ translate('Time worked', 'agenda') }}: {{timePassed}}
                        </div>
                        <i v-if="started" @click="stopTracker" class="fa fa-stop"></i>
                    </div>

                    <button type="button" class="close black-close" @click="$modal.hide('tracker')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <!-- TIMEliNE -->
                        <div class="mx-2">
                            <div class="row">
                                <div class="col-md-12">
                                    <!--                                <h4>Tracker history</h4>-->
                                    <ul class="timeline">
                                        <li v-for="(action, ai) in timeline" :key="ai" class="timeline-action">
                                            <!-- Right side info -->
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <i class="fa fa-play"></i> {{moment(action.time).format('H:mm')}}
                                                </div>
                                                <div>
                                                    <a href="#" v-if="action.type != 'started' && action.type != 'paused'">
                                                        <i class="fa fa-clock"></i> {{ getTimeOfAction(action.time, timeline[ai + 1] ? timeline[ai + 1].time : null) }}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a @click="$set(action, 'showDescription', !action.showDescription)" href="#">
                                                    <i class="fa fa-comment"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <b v-if="action.type == 'started' || action.type == 'paused'">
                                                {{ translate('Tracker', 'agenda') }}:  {{ translate(action.type, 'agenda') }}
                                            </b>
                                            <component v-else :is="`tracker-${action.type}-template`"
                                                       v-model="timeline[ai]"></component>

                                            <!-- description -->
                                            <p v-text="action.description"></p>
                                            <!-- end description -->

                                            <!--  textarea for action descr in timeline -->
                                            <div v-if="action.showDescription">
                                                <textarea class="form-control w-100" v-model="action.description"
                                                          placeholder="Add comment here..."></textarea>
                                                <button class="btn btn-primary btn-xs pull-right w-100 mt-2"
                                                        @click="$set(action, 'showDescription', !action.showDescription)">
                                                    {{ translate('Save description', 'agenda') }}
                                                </button>
                                            </div>
                                            <!-- end textarea -->

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- TIMELINE END -->
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import moment from 'moment'
    import {mapActions, mapGetters, mapState} from 'vuex'
    
    // REFACTOR TEMPLATES
    import TrackerProjectTemplate from "./TrackerProjectTemplate";
    import TrackerTaskTemplate from './TrackerTaskTemplate'

    // TEMPLATES
    import TrackerEventTemplate from "./TrackerEventTemplate";

    export default {
        components: {
            TrackerTaskTemplate, TrackerProjectTemplate, TrackerEventTemplate
        },
        mounted() {
            this.startTrackingLoop()
            // this.addCheckpointToTimeline('')

            window.onscroll = this.stickTrackerMenu

            let localTracker = window.localStorage.getItem('tracker')

            if(localTracker){
                this.$store.state.tracker = JSON.parse(localTracker)
            }
            

            window.addEventListener("beforeunload", function(evt) {                
                window.localStorage.setItem('tracker', JSON.stringify(this.$store.state.tracker))
            }.bind(this));
        },
        data() {
            return {
                show: {
                    details: false,
                }
            }
        },
        methods: {
            ...mapActions('tracker', [
                'startTrackingLoop', 'startTracking', 'pauseTracking', 'stopTracker', 'addCheckpointToTimeline', 'clearTracking'
            ]),
            getTimeOfAction(time, previousTime = null) {
                if (previousTime) {
                    let timeBetweenTasks = moment(previousTime).diff(moment(time))
                    let duration = moment.duration(timeBetweenTasks)
                    return parseInt(duration.asHours()) + 'h ' + parseInt(duration.asMinutes()) % 60 + 'm ' + parseInt(duration.asSeconds()) % 60 + 's';
                }
                return `${moment(time).format('HH:mm:ss')} `
            },
            moment
        },
        computed: {
            ...mapGetters('tracker', [
                'timePassed'
            ]),
            ...mapState('tracker', [
                'started', 'trackedTime', 'timeline', 'tracking',
            ]),
        }
    }
</script>

<style scoped type="scss">

</style>
