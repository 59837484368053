<template>
    <div class="container">
        <page-title :page-title="translate('Product attributes', 'entities')">
            <template v-slot:actions>
                <router-link class="btn btn-primary rounded-pill" to="/product-attributes/create">
                  {{ translate('Add new {entity}', 'entities', {entity: translate('Product attribute', 'entities').toLowerCase() }) }}
                </router-link>
            </template>
        </page-title>
        <div class="row">
            <div class="col-md-12">
                {{ translate('Attributes are used to add aditional defails for products. Some of attributes can be used in filters also.', 'attributes') }}
            </div>
            <div class="col-md-12">
                <div class="main-table mt-4">
                    <div class="d-flex justify-content-end align-items-center table-header px-1">
                        <div class="bulk-actions nav-item dropdown dropdown-animate actions-dropdown"
                             data-toggle="hover">
                            <a class="nav-link dropdown-toggle system-text-primary" href="#" role="button"
                               data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false">
                                {{ translate('Bulk actions', 'global') }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                                <div class="p-lg-4">
                                    <div class="pb-1 pt-1 delimiter-bottom">
                                        <a href="javascript:;"
                                           class="d-flex align-items-center dropdown-item">
                                            {{ translate('Delete', 'global') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" v-if="records">
                        <table class="table m-0">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               class="custom-control-input"
                                               :id="`select_all`">
                                        <label class="custom-control-label" :for="`select_all`"></label>
                                    </div>
                                </th>
                                <th scope="col">{{ translate('Attribute', 'attributes') }}</th>
                                <th scope="col">{{ translate('Slug', 'attributes') }}</th>
                                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="tr-1" v-for="(attr, attrI) in records.data">
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" class="custom-control-input">
                                        <label for="all" class="custom-control-label"></label>
                                    </div>
                                </td>
                                <td>
                                  <router-link :to="`/product-attributes/${attr.id}`" class="table">
                                    <title-with-shortcodes :value="entityLabel(attr.label, false, true)"/>
                                  </router-link>
                                </td>
                                <td>{{attr.name}}</td>
                                <td class="actions-cta">
                                    <cta>
                                        <action-button class-icon="fa-pen" @click.native="$router.push(`/product-attributes/${attr.id}`)"></action-button>
                                        <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(attr.id)"></action-button>
                                    </cta>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                                :value="records.meta" v-if="records && records.meta"
                                @goToPage="v => setProp('page', v)"></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EntityIndexMixin from "../../../../mixins/EntityIndexMixin";
import TitleWithShortcodes from "@/modules/erp_entities/components/utilities/TitleWithShortcodes";

export default {
  name: "Attributes",
  components: {TitleWithShortcodes},
  mixins: [EntityIndexMixin],
  async created() {
    this.entityType = 'attributes'
    await this.loadUserPreferences()
    await this.loadData()
  },
}
</script>

<style scoped>

</style>
