<template>
  <ul class="sidebar-new-links" :class="{'with-tooltips': $store.state.system.interface === 'rock'}">
    <template v-for="(link, li) in value">
      <li v-if="showApps(link) && showLink(link) && link" :key="li + 0" class="sidebar-link-item" :class="{'active': link.expand}">
        <a href="javascript:;" class="sidebar-link" @click="toggleSidebar(link)" v-if="link.nav">
          <span v-if="$store.state.system.interface === 'rock' && (link.expand === false || link.expand === undefined)" class="sidebar-icon-info-box">{{ translate(link.title, 'sidebar') }}</span>
          <i v-if="link.image" :class="`${link.image} icon-erp-2x`"></i>
          <span class="btn-inner--icon sidebar-link-text">{{ translate(link.title, 'sidebar') }}</span>
          <i class="fa ml-auto fa-chevron-down chevron d-none d-lg-block" :class="{'chevron-rotate': link.expand}"></i>
        </a>
        <router-link :to="link.url || '/'" class="sidebar-link" @click.native="closeAll" v-else>
          <i v-if="link.image" :class="`${link.image} icon-erp-2x`"></i>
          <span v-if="$store.state.system.interface === 'rock' && (link.expand === false || link.expand === undefined)" class="sidebar-icon-info-box">{{ translate(link.title, 'sidebar') }}</span>
          <span class="btn-inner--icon sidebar-link-text">{{ translate(link.title, 'sidebar') }}</span>
        </router-link>
        <transition name="slide-in" v-if="link.nav">
          <div class="links-container" v-show="link.expand">
            <div class="_inner">
              <div class="_inner-header">
                <h4 class="font-weight-700">{{ translate(link.title, 'sidebar') }}</h4>
                <button class="btn" @click="closeSubnav(link)"><span class="fa fa-times"></span></button>
              </div>
              <div class="_inner-container">
                <ul class="reset-list"> <!-- MAIN menu -->
                  <li v-for="(column, ci) in link.nav" :key="ci" class="column"> <!-- columns 1,2,3.... -->
                    <ul class="reset-list"> <!-- whole menu in the column -->
                      <li v-for="(sub_menu, sm) in column" :key="sm" class="sub-menu"> <!-- sub menu -->
                        <template v-if="showSubmenu(sub_menu) && showApps(sub_menu)">
                          <strong class="sub-menu-title">{{ translate(sub_menu.title, 'sidebar') }}</strong> <!-- sub title -->
                          <ul class="reset-list"> <!-- the single menu itself -->
                            <template v-for="(item, idx) in sub_menu.nav">
                              <li v-if="item" @click="closeAll" class="sub-menu-item"> <!-- menu single item -->
                                <router-link class="sub-menu-link" :to="`${item.url}`">{{ translate(item.title, 'sidebar') }}</router-link>
                              </li>
                            </template>
                          </ul>
                        </template>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
        <div class="links-container_overlay" @click="toggleSidebar(link)" v-if="link.expand"></div>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'sidebar-links',
  props: {
    value: {
      required: true,
    },
    website_sidebar: {
      required: false,
    },
  },
  created() {
    if(this.website_sidebar) {
      this.closeAll()
    }
  },
  methods: {
    toggleSidebar(link) {
      if(link.expand == false || link.expand == undefined) {
        this.value.forEach(m => {
          if (m) {
            m.expand = false
          }
        })
      }
      this.value.forEach(m => {
        if(m && m.nav && m.nav.length > 0) {
          this.$set(m, 'expand', m.expand)
        }
      })
      this.$set(link, 'expand', !link.expand)
      this.$forceUpdate()
    },
    closeSubnav(link) {
      if (link.expand) {
        this.$set(link, 'expand', false)
      }
      this.$forceUpdate()
    },
    closeAll() {
      this.value.forEach(link => {
        if (link) {
          link.expand = false
        }
      })
      this.value.forEach(m => {
        if(m && m.nav && m.nav.length > 0) {
          this.$set(m, 'expand', m.expand)
        }
      })
      this.$emit('close')
      this.$forceUpdate()
    },
    showLink(link) {
      /*
      Not used
      if (users && users.length) {
          return users.map(u => u.key).includes(this.$store.state.system.authData.user_id)
      }

      if (roles && roles.length) {
           return roles.map(r => r.key).includes(this.$store.state.system.authData.roles[0].id)
      }
      return true
      */

      if (link && !link.url && link.nav) {
        // Returns true if there is at least one submenu to show
        return Object.values(link.nav).some(column => {
          return column && Object.values(column).filter(Boolean).some(entity => {
            // returns all items which are not falsy value
            return Array.isArray(entity.nav) && entity.nav.length > 0
          })
        })
      }

      // Returns true for links without entities
      return true
    },
    showSubmenu(submenu) {
      return submenu && submenu.nav.filter(item => item !== false).length
    },
    showApps(link) {
      // debug
      // console.log('Current apps:', this.$store.state.system.authData.apps);

      if (this.$store.state.system.authData.selected_company === 1) {
        return true
      } else if (link.app && this.$store.state.system.authData.apps.includes(link.app)) {
        return true
      }

      return !link.app || !link.hasOwnProperty('app');
    },
  },
}
</script>
