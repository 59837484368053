<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Icon', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
                :label="'Icon position'"
                :value="getSetting('icon_position')"
                @input="setSetting('icon_position', $event)"
                :options="iconPositionOptions"
            />
          </div>

          <div class="col-12">
            <craft-icon-picker
                :label="'Add icon'"
                :value="getSetting('icon')"
                @input="setSetting('icon', $event)"
            />
          </div>

          <div class="col-12">
            <craft-image-picker
                :label="'Or upload custom image'"
                :value="getSetting('icon_custom', '')"
                @input="setSetting('icon_custom', $event);"
            />
          </div>

          <transition name="fade">
            <div v-if="getSetting('icon_position') === 'top'" class="col-md-12">
              <craft-tab-pills
                  :label="'Icon alignment'"
                  :value="getSetting('icon_alignment')"
                  @input="setSetting('icon_alignment', $event)"
                  :options="generalAlignmentOptions"
              />
            </div>
          </transition>

          <div class="col-12">
            <craft-input
                :label="'Icon width'"
                :input-type="'range'"
                :min="1" :max="60"
                :suffix="'PX'"
                :value="getSetting('icon_width', 'px')"
                @input="setSetting('icon_width', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Spacing between text and icon'"
                :input-type="'range'"
                :min="0" :max="100"
                :suffix="'PX'"
                :value="getSetting('icon_margin', 'px')"
                @input="setSetting('icon_margin', $event, 'px')"
            />
          </div>

          <div class="col-md-6">
            <craft-color-picker
                :label="'Icon color (not for images)'"
                :value="getSetting('icon_color')"
                @input="setSetting('icon_color', $event)"
            />
          </div>
          <div class="col-md-6">
            <craft-color-picker
                :label="'Icon background color'"
                :value="getSetting('icon_background_color')"
                @input="setSetting('icon_background_color', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Icon background padding'"
                :input-type="'range'"
                :min="0" :max="60"
                :suffix="'PX'"
                :value="getSetting('icon_padding', 'px')"
                @input="setSetting('icon_padding', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Icon background corners'"
                :input-type="'range'"
                :min="0" :max="60"
                :suffix="'PX'"
                :value="getSetting('icon_border_radius', 'px')"
                @input="setSetting('icon_border_radius', $event, 'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Title', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
                :label="'Title'"
                :value="getSetting('title')"
                @input="setSetting('title', $event)"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
                :label="'Title font'"
                :value="getSetting('title_font')"
                @input="setSetting('title_font', $event)"
                :options="generalFontOptions"
            />
          </div>

          <div class="col-md-12">
            <craft-tab-pills
                :label="'Title alignment'"
                :value="getSetting('title_alignment')"
                @input="setSetting('title_alignment', $event)"
                :options="generalAlignmentOptions"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Bottom spacing'"
                :input-type="'range'"
                :min="0" :max="100"
                :suffix="'PX'"
                :value="getSetting('title_margin_bottom', 'px')"
                @input="setSetting('title_margin_bottom', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Title font size'"
                :input-type="'range'"
                :min="1" :max="100"
                :suffix="'PX'"
                :value="getSetting('title_font_size', 'px')"
                @input="setSetting('title_font_size', $event, 'px')"
            />
          </div>

          <div class="col-md-6">
            <craft-color-picker
                :label="'Text color'"
                :value="getSetting('title_color')"
                @input="setSetting('title_color', $event)"
            />
          </div>

        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Description', 'page-builder')">
        <div class="row">

          <div class="col-12">
            <craft-text-editor
                :label="'Description'"
                :value="getSetting('description')"
                @input="setSetting('description', $event)"
            />
          </div>

        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('link', 'page-builder')">
        <div class="row">

          <div class="col-12">
            <craft-input
                :label="'Link'"
                :value="getSetting('link')"
                @input="setSetting('link', $event)"
            />
          </div>


          <div class="col-md-12">
            <craft-tab-pills
                :label="'Link behavior'"
                :value="getSetting('link_target')"
                @input="setSetting('link_target', $event)"
                :options="generalLinkTargetOptions"
            />
          </div>

        </div>
      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import {CraftColorPicker, CraftIconPicker, CraftImagePicker, CraftInput, CraftSelect, CraftTabPills, CraftTextEditor} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    name: "IconBoxSettings",
    mixins: [ComponentSettingsMixin, CraftSettingsMixin],
    components: {
      CraftColorPicker, CraftIconPicker, CraftImagePicker, CraftInput, CraftSelect, CraftTabPills, CraftTextEditor,
    },
    computed: {
      iconPositionOptions(){
        return [
          {key: 'left', label: 'Left'},
          {key: 'right', label: 'Right'},
          {key: 'top', label: 'Top'},
        ]
      },
    },
}
</script>