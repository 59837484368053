<template>
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" @click="$modal.hide('update_entity_permissions')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white text-muted border rounded-pill"
          @click="$emit('close')">{{ translate('Go back', 'global') }}
        </button>
        <button type="submit" class="btn btn-primary rounded-pill" @click="$emit('update', data)">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
      <div class="modal-body overflow-auto p-0">
        <permissions-and-restrictions v-model="data" :permissions="permissions"/>
      </div>
    </div>
  </div>
</template>

<script>

import PermissionsAndRestrictions from "../../components/entities/PermissionsAndRestrictions";

export default {
  name: "EntitiesPermissions",
  components: {
    PermissionsAndRestrictions
  },
  props: {
    value: {
      required: true
    },
    title: {
      required: true
    },
  },
  data(){
    return {
      data: null,
      permissions: [
        {key: 'create', value: 'Create'},
        {key: 'update', value: 'Update'},
        {key: 'delete', value: 'Delete'},
        {key: 'access', value: 'Access'}
      ]
    }
  },
  created() {
    this.data = this.value
  },
}
</script>

<style scoped>
    .custom-switch {
        padding-left: 4.75rem;
    }
</style>
