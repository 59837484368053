<template>
  <div class="d-flex justify-content-center flex-column bg-white p-5">
    <div class="text-center py-3">
      <h3
        v-if="creating"
        class="font-weight-bold"
      >
        {{ translate('Hold on while we are creating your website', 'onboarding') }}!
      </h3>
      <h3
        v-else
        class="font-weight-bold"
      >
        {{ translate('Website created', 'onboarding') }}!
      </h3>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div>
        <div
          v-if="creating"
          class="circle-progress"
        >
          <span class="progress-left">
            <span class="progress-bar" />
          </span>
          <span class="progress-right">
            <span class="progress-bar" />
          </span>
          <div class="progress-value">
            <img
              v-if="$store.state.system.interface === 'skitle'"
              src="/img/skitle-icon.png"
              alt="Skitle"
            >
            <img
              v-else
              src="/img/sitecart-logo.png"
              alt="Sitecart"
            >
          </div>
        </div>
        <div v-if="!creating">
          <div
            class="swal2-icon swal2-success swal2-icon-show"
            style="display: flex;"
          >
            <div
              class="swal2-success-circular-line-left"
              style="background-color: rgb(255, 255, 255);"
            />
            <span class="swal2-success-line-tip" />
            <span class="swal2-success-line-long" />
            <div class="swal2-success-ring" />
            <div
              class="swal2-success-fix"
              style="background-color: rgb(255, 255, 255);"
            />
            <div
              class="swal2-success-circular-line-right"
              style="background-color: rgb(255, 255, 255);"
            />
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="creating"
      class="text-center p-3"
    >
      <span>{{ translate('One moment, we will setup your site', 'onboarding') }} {{ step_percentage }}% !</span>
    </div>
    <div
      v-if="!creating"
      class="d-flex justify-content-center"
    >
      <!-- only for skittle for now because -->
      <router-link
        class="btn btn-secondary rounded-pill"
        to="/"
      >
        {{ translate('Go to homepage', 'onboarding') }}
      </router-link>

      <router-link
        v-if="$store.state.system.interface === 'sitecart'"
        class="btn btn-secondary rounded-pill"
        to="/onboarding/company-settings"
      >
        {{ translate('Continue with webshop settings', 'onboarding') }}
      </router-link>

      <button
        v-if="$store.state.system.interface === 'skitle'"
        class="btn btn-primary rounded-pill"
        @click="redirectToBuilder"
      >
        {{ translate('Start building your website', 'onboarding') }}
      </button>
    </div>
  </div>
</template>

<script>
import { scopeSystemByWebsite , slug } from '@/utilities/helper'
import ErpModel from '@/modules/erp_framework/services/ErpModel'

export default {
  name: "WebsiteFinish",
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      step_percentage: 0,
      creating: true,
      website: null,
      theme: null,
      templates: [],
    }
  },
  watch: {
    'step_percentage': function () {
      this.loadCirclePerc()
    },
  },
  async created() {
    let websiteId = null
    await this.erp.ext.request.axiosInstance.post('/modules/cms-websites', {
      options: this.value,
    }).then(res => {
      websiteId = res.data.data.id
    }).catch(error => {
      this.$toast.error(error.response.data.message)
      // this.$alert.formattedError(error) cannot use this because the format returned is different
    })

    if(!websiteId){
      return
    }

    this.website = await this.erp.ext.request.find('cms-websites', websiteId)

    this.$store.state.system.scope.data.push(this.website.objectData)
    scopeSystemByWebsite(this.website.objectData)
    await ErpModel.create('cms-pages', {
      status: '1',
      website_id: this.website.objectData.id,
      locale: this.website.objectData.website_primary_locale,
      name: 'Home',
      url_key: '/',
    })
    await ErpModel.create('cms-pages', {
      status: '1',
      website_id: this.website.objectData.id,
      locale: this.website.objectData.website_primary_locale,
      name: 'Terms and conditions',
      url_key: 'terms-and-conditions',
    })
    await ErpModel.create('cms-pages', {
      status: '1',
      website_id: this.website.objectData.id,
      locale: this.website.objectData.website_primary_locale,
      name: 'Privacy policy',
      url_key: 'privacy-policy',
    })
    this.step_percentage = 20

    // Save template
    if (this.value.theme) {
      this.theme = await this.erp.ext.request.find('cms-themes', this.value.theme)
      let templateIds = JSON.parse(this.theme.objectData.templates)
      let templates = await Promise.all(templateIds.map(id => this.erp.ext.request.find('cms-templates', id)))

      await Promise.all(templates.map(template => {
        delete template.objectData.id

        return ErpModel.create('cms-pages', { ...template.objectData, website_id: this.website.objectData.id })
      }))
    }
    this.step_percentage = 40
    // End save template

    // Get settings ids
    let websiteSettings = await this.erp.ext.request.axiosInstance.get(`/modules/cms-websites`)
    let settingsMenu = websiteSettings.data.settings_menu.reduce((acc, setting) => {
      acc[slug(setting.name)] = setting.id

      return acc
    }, {})
    // End get settings ids

    // Save colors in design options
    await this.erp.ext.request.axiosInstance.post(`/modules/settings/CmsWebsite/${this.website.objectData.id}`, {
      options: {
        primary_color: this.value.color_schema.primary,
        secondary_color: this.value.color_schema.secondary,
        third_color: this.value.color_schema.third,
      },
      model: 'CmsWebsite',
      modelId: `${this.website.objectData.id}`,
      settingsId: settingsMenu.colors,
      name: '',
    })
    this.step_percentage = 60
    // End save colors in design options

    // Save layout in design options
    await this.erp.ext.request.axiosInstance.post(`/modules/settings/CmsWebsite/${this.website.objectData.id}`, {
      options: {
        content_width: this.value.layout,
      },
      model: 'CmsWebsite',
      modelId: `${this.website.objectData.id}`,
      settingsId: settingsMenu.general,
      name: '',
    })
    this.step_percentage = 80
    // End save layout in design options

    // Save logo in design options
    await this.erp.ext.request.axiosInstance.post(`/modules/settings/CmsWebsite/${this.website.objectData.id}`, {
      options: {
        mobile_logo: this.value.logo,
        desktop_logo: this.value.logo,
      },
      model: 'CmsWebsite',
      modelId: `${this.website.objectData.id}`,
      settingsId: settingsMenu.general,
      name: '',
    })
    this.step_percentage = 80
    // End save logo in design options

    // Save font in design options
    let font = {
      letterType: {
        family: this.value.font.name,
      },
    }

    await this.erp.ext.request.axiosInstance.post(`/modules/settings/CmsWebsite/${this.website.objectData.id}`, {
      options: {
        body_fonts: JSON.stringify(font),
      },
      model: 'CmsWebsite',
      modelId: `${this.website.objectData.id}`,
      settingsId: settingsMenu.typography,
      name: '',
    })
    this.step_percentage = 100
    // End save font in design options

    let options = {
      primary_color: this.value.color_schema.primary,
      secondary_color: this.value.color_schema.secondary,
      third_color: this.value.color_schema.third,
    }
    window.localStorage.setItem('colors', options)

    this.creating = false

    this.value.website_id = websiteId
  },
  methods: {
    redirectToBuilder() {
      this.$router.push(`/builder/cms-pages/create`)
    },
    percentageToDegrees(percentage) {
      return percentage / 100 * 360
    },
    loadCirclePerc() {
      let left = document.querySelector('.progress-left .progress-bar')
      let right = document.querySelector('.progress-right .progress-bar')

      if (this.step_percentage <= 50) {
        right.style.transform = `rotate(${this.percentageToDegrees(this.step_percentage)}deg)`
      } else {
        right.style.transform = 'rotate(180deg)'
        left.style.transform = `rotate(${this.percentageToDegrees(this.step_percentage - 50)}deg)`
      }
    },
  },
}
</script>

<style scoped lang="scss">
#initial-loader {
    display: inherit;
}

.circle-progress {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress-left {
        left: 0;
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 4px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $primary;
    }

    .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        transform-origin: center left;
    }

    .progress-right{
        right: 0;
    }

    .progress-right .progress-bar{
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        transform-origin: center right;
    }

    .progress-value{
        width: 98.5%;
        height: 98.5%;
        display: flex;
        margin: auto;
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 50%;

        img {
            width: 65%;
            margin: auto;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
