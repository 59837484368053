<template>
  <div class="container w-50">
    <div class="d-flex flex-column">
      <div class="form-group">
        <label class="form-control-label">{{ translate('Website name', 'onboarding') }}</label>
        <input
          v-model="value.name"
          class="form-control"
          @input="ev => generateURL(ev.target.value)"
        >
      </div>
      <div class="form-group">
        <label class="form-control-label">{{ translate('Website url', 'onboarding') }}</label>
        <input
          placeholder="your-domain.app.skitle.co"
          v-model="value.url"
          class="form-control"
        >
      </div>
      <div
        v-if="allLocales"
        class="form-group"
      >
        <label>{{ translate('Website language', 'onboarding') }}</label>
        <v-select
          v-model="value.website_primary_locale"
          label="name"
          :options="allLocales"
          :reduce="value => value.key"
          class="select2-form"
        />
      </div>
      <div class="form-group">
        <label>{{ translate('Website type', 'onboarding') }}</label>
        <v-select
          v-model="value.website_type"
          :options="websiteTypeOptions"
          :get-option-label="(option) => entityLabel(option.value)"
          :reduce="v => v.key"
        />
      </div>
    </div>
    <div class="container d-flex justify-content-between w-100 mt-5"
         :class="$router.currentRoute.meta.prev ? 'justify-content-between': 'justify-content-end'">
      <router-link v-if="$router.currentRoute.meta.prev"
                   :to="`${$router.currentRoute.meta.prev}`"
                   class="btn btn-primary"
      >
        {{ translate('Go back', 'global') }}
      </router-link>
      <button class="btn btn-primary" @click="nextStep">
        {{ translate('Next', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import { allLocales, slug } from "@/utilities/helper"

export default {
  name: "Identity",
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      allLocales: null,
      websiteTypeOptions: [
        { key: 'webshop', value: 'Webshop' },
        { key: 'website', value: 'Website' },
      ],
    }
  },
  async created() {
    this.allLocales = await allLocales()
  },
  methods: {
    generateURL(name) {
      if (name.indexOf(' ') >= 0) name = name.replace(/\s+/g, '-').toLowerCase()
      this.value.url = name
      this.value.slug = slug(name)
    },
    async nextStep() {
      if (!this.value.name || this.value.name === '') {
        return this.$toast.error('Name field cannot be empty!')
      } else if (!this.value.url || this.value.url === '') {
        return this.$toast.error('Url field cannot be empty!')
      } else if (!this.value.website_primary_locale) {
        return this.$toast.error('Language field cannot be empty!')
      } else if (!this.value.website_type) {
        return this.$toast.error('Website type field cannot be empty!')
      } else {
        this.$router.push(this.$router.currentRoute.meta.next)
      }
    },
  },
}
</script>