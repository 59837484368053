<template>
    <div class="sidebar-tabs">
        <div class="d-flex settings-buttons" v-if="sidebar_tabs.length > 1">
            <button 
                v-for="(tab, idx) in sidebar_tabs" :key="idx" 
                @click="selectTab(tab)"
                class="btn tab-title py-3" 
                :class="{'active' : tab.isActiveTab, '' : tab.name != 'Design settings'}"
                :title="tab.name"
            >
                <span :class="{'active' : tab.isActiveTab}">
                   {{ tab.name }}
                </span>
            </button>
        </div>
        <div class="tab-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElementSettingsContentTabs",
        inject:['editor'],
        data() {
            return {
                sidebar_tabs: [],
                show: {
                    sidebar_tabs: false
                }
            }
        },
        created() {
            this.sidebar_tabs = this.$children
        },
        methods: {
            selectTab(selectedTab) {
                this.$emit('selected', selectedTab)
                this.sidebar_tabs.forEach(tab => {
                    tab.isActiveTab = (tab.name == selectedTab.name)
                    this.selected_tab = selectedTab.name
                })
                this.$children.forEach(tab => {
                    tab.isActiveTab = (tab.name == selectedTab.name)
                })
                this.show.sidebar_tabs = false
            }
        }
    }
</script>


<style lang="scss" scoped>


.settings-buttons button.btn.tab-title:first-child {
    padding-left: 0;
}

.settings-buttons button.btn.tab-title.active {
    box-shadow: none;
    color: #000 !important;
}
.settings-buttons > button.btn.tab-title {
    background-color: #fff;
    box-shadow: none;
    border: none;
    font-weight: bold;
    color: #c4c0c0 !important;

    &:last-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0;
    }

}
.settings-buttons > button.btn.tab-title:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

</style>
