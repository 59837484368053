<template>
    <div style="background-color: #d6e2ea">
        <div class="col-md-12">
            <page-title :page-title="translate('Invoice ' + i_d.invoice_number, 'invoices')"
                :borderBottom="false">
            </page-title>
        </div>
        <div class="d-flex justify-content-center" :id="`${i_d.status}`" style="border-radius:1.4rem; padding:0.7rem; font-size: 1.1rem;">
            <!--- BLOCKZ {{i_d.currency_code}} {{i_d.payd.toFixed(2)}} / {{i_d.total_amount.toFixed(2)}} -->
            {{i_d.currency_code}} {{i_d.payd}} / {{i_d.total_amount}}
        </div>
        <div v-if="status" class="d-flex justify-content-center"  style="border-radius:1.4rem; padding:0.7rem; font-size: 1.1rem; background-color: #9E9E9E; margin-left:1rem;">
            {{ translate('Expires in', 'invoices') }}: {{daysToExpires}}
        </div>
        <div class="col-md-12 d-flex justify-content-end" style="height: 2.6rem">
            <div>
                <li class="nav-item dropdown dropdown-animate" data-toggle="hover" >
                    <button class="button-invoice rounded-pill dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" style="height:2.6rem"> {{ translate('Action', 'invoices') }} </button>

                         <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right p-lg-4" style="margin-top:0.7rem">

                                    <div class="pb-1 pt-1 delimiter-bottom" >
                                        <a href="javascript:;" class="d-flex align-items-center dropdown-item" @click="sendReminder()">
                                           <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                            <span style="padding-left:0.5rem">{{ translate('Send reminder', 'invoices') }}</span>
                                        </a>
                                    </div>
                                    <div class="pb-1 pt-1 delimiter-bottom" >
                                        <a href="javascript:;" class="d-flex align-items-center dropdown-item" @click="sendAgain()">
                                           <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                            <span style="padding-left:0.5rem">{{ translate('Send again', 'invoices') }}</span>
                                        </a>
                                    </div>
                                    <div class="pb-1 pt-1 delimiter-bottom" >
                                        <a href="javascript:;" class="d-flex align-items-center dropdown-item" @click="creditNote()">
                                         <i class="fas fa-sticky-note" ></i>
                                            <span style="padding-left:0.5rem" > {{ translate('Credit note', 'invoices') }}</span>
                                        </a>
                                    </div>
                         </div>

                </li>
            </div>

            <button class="button-invoice rounded-pill"> {{ translate('PDF', 'invoices') }} </button>
            <button class="button-invoice rounded-pill" style="color:white; background-color:green;" @click="$modal.show('payment_modal')"> {{ translate('Payment', 'invoices') }} </button>
        </div>
        <modal name="payment_modal"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="500px"
               transition="slide-top"
               overlayTransition="wait"
               classes="remove-modal-height">
            <payment-modal :curency="i_d.currency_code" :object_id="i_d.id" @payd="newData()"></payment-modal>
        </modal>
        <div class="card" style="background-color: #d6e2ea">
            <div class="card-body project-showview">
                <tabs :clear="true">
                    <tab name="Invoice" :selected="true">
                        <invoice-send :i_d="i_d" :company="authData.company" ></invoice-send>
                    </tab>
                    <tab name="Payments">
                        <payments :payments="i_d.payments" :curency="i_d.currency_code"></payments>
                    </tab>
                    <tab name="Notes">
                        <notes @newNote="newData()" :notes="notes" :invoice_id="i_d.id" :src="authData.user.avatar"></notes>
                    </tab>
                    <tab name="History">
                        <history></history>
                    </tab>
                </tabs>
            </div>
        </div>

    </div>
</template>
<script>
import InvoiceSend from "./InvoiceShowviewSend";
import Payments from "./InvoicePaymentTab";
import Notes from "./InvoiceComments";
import History from "./InvoiceActionLogger";
import PaymentModal from "./PaymentModal";
import moment from "moment";
import { db, modules, routes } from "@/utilities/database";
import {mapState} from 'vuex';
export default {

    name:"InvoiceShowview",

    components: {
      InvoiceSend,
      Payments,
      Notes,
      History,
      PaymentModal
    },
    data(){
        return {
            notes:[],
            i_d:null,
            i_d_old:{

                payd:10.00.toFixed(2),
                reminders:0,
                payments:[
                    {
                        type: "Pay with wire",
                        date: "13 June 2020",
                        amount: 5.00,
                        registered: 1,
                    },
                    {
                        type: "IDeal",
                        date: "9 June 2020",
                        amount: 5.00,
                        registered: 1,
                    },
                ],
                history:[

                ]

            },
            status:true,
            daysToExpires:Number
        }
    },
    mounted(){
        // this.daysExpires()
    },
    async created () {
        this.newData()
    },

    methods:{
        async newData(){
            this.$store.state.system.isLoading = true;
            this.getInvoice();
            this.getPayments();
            this.$store.state.system.isLoading = false
        },
        getPayments(){
            db.get(routes.invoice.payments)
        .then(res=>{
            console.log(res);
        })
        .catch(err => {
            return Promise.resolve(null);
        });
        },
        async getInvoice(){
            db.get(`${routes.invoice.index}/${this.$route.params.id}`)
        .then(res=>{

           this.i_d=Object.assign({}, res.data.data)
           this.i_d=Object.assign(this.i_d,this.i_d_old)
           this.i_d.discount_amount? null:this.i_d.discount_amount=0.00.toFixed(2)
           this.i_d.currency_code=='EUR'? this.i_d.currency_code = '€': null;
           this.i_d.notes=Object.assign({},res.data.notes);
           res.data.notes.notes? this.i_d.notes.comments.unshift(res.data.notes.notes): null;
           this.readyNotes();
           this.daysExpires();
           this.checkStatus(this.i_d.status);

        })
        .catch(err => {
            return Promise.resolve(null);
        });
        },
        async readyNotes(){
            let length = this.notes.length;
            this.notes.splice(0, length);
            this.i_d.notes.comments.forEach((el,id)=>{
            db.get(`/users/${el.user_id}`)
                .then(response=>{
                    el.name=`${response.data.data.first_name} ${response.data.data.last_name}`;
                    el.avatar=response.data.data.avatar;
                    el.number=id;
                    this.notes.push(el)
                })
                .catch(err => {
                    return Promise.resolve(null);
                });
            })
            this.notes.sort((a,b)=>a.id-b.id)
        },
        checkStatus(stat){
            (stat!='send'&&stat!='not_sent')?this.status=false:null
        },
        daysExpires(){
            // BLOCKZ - this.daysToExpires= moment().from(moment(this.i_d.due_date,"DD-MM-YYYY"), true);
            this.daysToExpires= moment().from(moment(this.i_d.due_date,"YYYY-MM-DD"), true);
        },
        sendReminder(){
          this.$toast.info("The Reminder is sending")
        },
        sendAgain(){
            this.$toast.info("The Invoice is sending again")
        },
        creditNote(){
          this.$toast.info("Create Credit Note")
        }
    },
    computed: {
            ...mapState('system', ['authData','company'])
        },

}
</script>
<style scoped>
    #Send {
        background-color: #ffa500;
    }
    #not_sent{
        background-color: #ffa500;
    }
    #toLate{
        background-color:red;
    }
    .button-invoice {
    border-radius: 0.5rem;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    margin: 0 0.5rem -0.5rem;
    height: 2.6rem
    }
</style>
