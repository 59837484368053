<template>
    <div class="row">
<!--      TODO: Enabled this with funcitonality -->
<!--        <div class="col-md-12">-->
<!--            <div class="d-flex justify-content-center my-4">-->
<!--                <button class="hide-guide">-->
<!--                    <span>{{ translate('Hide help guide on next visit', 'dashboard') }}</span>-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
        <div class="col-md-12 mb-2">
            <div class="style-comp">
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start py-2 pl-2">
                        <div class="d-flex flex-column">
                            <span class="help">{{ translate('Need some help', 'dashboard') }}</span>
                            <span class="system-text-primary">{{ translate('Contact us and we will help you!', 'dashboard') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end py-2 pr-3">
                        <div id="two-bottom-buttons" class="d-flex justify-content-between">
                          <!--
                          TODO: Enable when knowledge base is available

                            <div class="d-flex align-items-center mr-2">
                                <router-link to="/knowledgebase" class="btn border rounded-pill">
                                    {{ translate('Go to knowledgebase', 'dashboard') }}
                                </router-link>
                            </div>
                          -->
                            <div class="d-flex align-items-center">
                                <button class="btn btn-primary rounded-pill" @click="$modal.show('contact_sales')">
                                    {{ translate('Contact us', 'dashboard') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "HelpContainer",
}
</script>

<style scoped>

</style>
