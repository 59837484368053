<template>
  <div class="container-fluid login select-company-view container-application">
    <loading-circles v-if="loading"></loading-circles>
    <div class="main-content position-relative w-100">
      <div class="page-content">
        <div class="min-vh-100 py-5 d-flex align-items-center">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-sm-8 col-lg-4">
                <div class="card bg-white shadow zindex-100 mb-0" :class="{ 'has-search': hasSearch() }">
                  <div class="card-header pb-2">
                    <h6 class="h3">{{ translate('Select company', 'global') }}</h6>
                    <div class="input-group mt-3 w-100 h-100 page-builder-modal-input align-items-center">
                      <div class="search-icon">
                        <span><i class="fa fa-search"></i></span>
                      </div>
                      <input type="text" class="form-control pl-3 h-100 search-field"
                             :placeholder="translate('Search by name', 'global')"
                             ref="companySearch"
                             v-model="searchInput"
                      >
                    </div>
                  </div>
                  <div class="card-body p-0" :class="{ 'nav-scrollbar': hasSearch() }">
                    <div class="list-group">
                      <a href="javascript:;" class="list-group-item list-group-item-action" v-for="(company, ci) in filteredCompanies" :key="ci" @click="selectCompany(company)">
                        {{company.value}}
                      </a>
                    </div>
                    <div class="text-center" v-if="filteredCompanies.length < 1">
                      <p>{{ translate('No companies found', 'global') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoadingCircles from "@/modules/system/components/utilities/LoadingCircles.vue"
import { changeTranslationLocale, scopeSystemByCompany, scopeSystemByEnv } from '@/utilities/helper'
import { loadSessionData } from '@/utilities/auth'

export default {
  name: 'SelectCompany',
  components: {
    LoadingCircles,
  },
  data() {
    return {
      loading: false,
      searchInput: '',
    }
  },

  /* Not needed anymore
  created() {
    window.localStorage.removeItem('company')
    window.localStorage.removeItem('website')
    window.localStorage.removeItem('interface')
    delete this.erp.ext.request.axiosInstance.defaults.headers.common['env']
    this.$store.state.system.interface = ''
    this.$store.state.system.company = null
  },
  */
  methods: {
    hasSearch(){
      return this.companies.length > 5
    },
    async selectCompany(company) {
      this.loading = true

      await scopeSystemByEnv(false)
      scopeSystemByCompany(company)
      await loadSessionData()

      let route = '/onboarding/identity'

      if (this.$store.getters["system/hasWebsite"]) {
        if (this.$store.state.system.interface === 'blockz') {
          changeTranslationLocale('nl') // blockz has only NL translation locale, all entities must be entered in NL
        }
        route = '/'
      }

      await this.$router.push(route)
      this.loading = false
    },

  },
  computed: {
    ...mapState('system', [
      'companies', 'authData',
    ]),
    filteredCompanies() {
      return this.companies.filter(company => {
        return company.value.trim().toLowerCase().includes(this.searchInput.trim().toLowerCase())
      })
    },
  },
  mounted() {
    if(this.hasSearch()){
      this.$nextTick(() => this.$refs['companySearch'].focus())
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  &.has-search {
    .card-body {
      height: 23.4rem;
      overflow-y: auto;
    }
  }
  &:not(.has-search) {
    .input-group {
      display: none;
    }
  }

  .card-header {
    border-bottom: 1px solid $primary;

    .search-field {
      border: none;
      font-size: 16px;

      &:focus {
        background: transparent;
        box-shadow: none;
      }
    }
  }

  .list-group-item {
    margin-bottom: 0;
    background-color: #fff !important;
    color: $primary !important;
    border-bottom: 1px solid $primary !important;
    transition: all .3s ease-in-out;

    &:first-child {
      border-radius: 0;
    }

    &:last-child {
      border-bottom: 0 !important;
    }
  }
  .list-group-item:hover {
    background-color: $primary !important;
    color: #fff !important;
  }
}
</style>
