import { isVoidValue } from "@/utilities/helper"
import { cleanseInput, isMultiSelectType, isSingleSelectType } from "@/modules/erp_entities/utilities/helper"

export default {
  props: {
    applyDefaultVal: {
      required: false,
      default: true,
    },
    // only for treeselect fields, todo refactor
    // used only for parent category in categories page and in blog author we pass false,
    // since we don't have a fieldProps.multiple we use component prop
    multiple: {
      required: false,
      default: true,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    // used for select, multiselect, treeselect, visual_swatch and radio
    model: {
      get() {

        // ON EDIT
        if (!isVoidValue(this.value)) {

          // Multiselect, Table multiselect, Visual swatch
          // all multislect values must be String, during import categories and attributes get Integer values sometimes
          if (isMultiSelectType(this.fieldProps.type) || (this.fieldProps.type === 'treeselect' && this.multiple)) {

            try {
              // if this is the first time getting the value, convert the format
              if (typeof this.value !== "object") {
                // hot fix convert non array values into arrays
                if(!this.value.toString().startsWith('[')) {
                  this.value = `[${this.value}]`
                }

                this.value = JSON.parse(this.value)

                // remove void values and convert to proper type
                this.value = this.value.filter(element => !isVoidValue(element)).map(element => {
                  return element.toString()
                })

                // hot fix, options must contain the json parsed value
                this.$emit('input', this.value)
                // END hot fix, options must contain the json parsed value
              }
            } catch (e) {}

          // select, table_select
          } else if (isSingleSelectType(this.fieldProps.type)) {
            this.value = this.value.toString()
          }

          this.value = cleanseInput(this.value, this.data, this.fieldProps.type)
        }

        return this.value
      },
      set(v) {
        this.value = v // required for radio buttons (RadioField.vue), todo check why and remove me
        this.$emit('input', v)
      },
    },
  },

  methods: {
    // used for Radio, Select, TableSelect, VisualSwatch
    // not used on Treeselect
    createNewOptions(fieldProps) {
      if (fieldProps.data_type === 'moduleNames') {
        this.$modal.show('create_new_entity')
      } else if (fieldProps.data_type === 'custom') {
        this.$modal.show('create_new_options')
      }

      this.$emit('createOptions', fieldProps)
    },

    async getRecords(){
      if(this.fieldProps.data_type === 'custom') {
        let url = `select-options/as-array-by-slug/${this.fieldProps.data_module}`

        // backward compatibility for dropdowns using id instead of slug
        if (!isNaN(Number(this.fieldProps.data_module))) {
          url = `select-options/as-array/${this.fieldProps.data_module}`
        }

        return await this.erp.ext.request.axiosInstance.get(url)
      } else if (this.fieldProps.data_type === 'treeselect') {
        const result = await this.erp.ext.request.axiosInstance.get(`/modules/treeselect/${this.fieldProps.data_module}`)
        return result.data

      } else if (this.fieldProps.data_type === 'treeselectCategory') {
        const result = await this.erp.ext.request.axiosInstance.get(`/modules/treeselect/categories/${this.fieldProps.data_module}`)
        return result.data
      }
    },

    async loadData(){
      const { data } = await this.getRecords()
      this.data = data
      this.fieldProps.data = data // hot fix mutate the prop because of product variations, see attributeNames(variation)
    },

    setDefaultValue(){
      if(this.data && this.data.length) {
        // Custom dropdown (selected value is set in admin > dropdowns) - ex. product_kind, product_type
        let hasSelected = this.data.filter(v => v.selected == '1')
        if(hasSelected.length > 0) {
          this.$emit('input', hasSelected[0].key)
        }
      }
    },
  },

  async created(){
    if (this.fieldProps.data) {
      this.data = this.fieldProps.data
      if (this.applyDefaultVal && isVoidValue(this.value)) {
        this.setDefaultValue()
      }
      return
    }

    // throw nice js errors if backend is not configured properly, todo refactor #19281651
    if(!this.fieldProps.data_type){
      console.error('data_type on '+ this.fieldProps.name +' is required')
      return
    }

    await this.loadData()

    if(this.applyDefaultVal && isVoidValue(this.value)) {
      this.setDefaultValue()
    }
  },
}