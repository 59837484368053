<template>
  <div class="file-picker">
    <div class="file-picker_files d-flex">
      <div class="row">
        <div class="col-md-4">
          {{ translate('Featured image', 'entities') }}
          <div class="file-picker_content file-picker-featured" :class="{'border-0': files[0]}"
               v-if="files.length >= 1">
            <img :src="files[0]">
            <div class="file-options_row">
              <a href="javascript:;" @click="remove(0)" v-tooltip="translate('Remove', 'entities')">
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </div>
          <div class="initial_file-picker file-picker_content" v-if="files.length === 0">
            <i class="far fa-images"></i>
            <span>{{ translate('Click the button to upload files', 'entities') }}</span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">{{ translate('Image gallery', 'entities') }}</div>
            <div class="col-md-3 d-flex" v-for="(file, fi) in files" :key="fi" v-if="fi != 0">
              <div class="file-picker_content file-picker_file" :class="{'border-0': files[fi]}">
                <img :src="file">
                <div class="file-options_row">
                  <a href="javascript:;" @click="makeFeatured(fi)" v-tooltip="translate('Set as featured', 'entities')">
                    <i class="fa fa-check"></i>
                  </a>
                  <a href="javascript:;" @click="remove(fi)" v-tooltip="translate('Remove', 'entities')">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="initial_file-picker col-md-3" v-if="files.length <= 1">
              <div class="file-picker_content w-100">
                <i class="far fa-images"></i>
              </div>
            </div>
            <div class="initial_file-picker col-md-3" v-if="files.length <= 2">
              <div class="file-picker_content w-100">
                <i class="far fa-images"></i>
              </div>
            </div>
            <div class="initial_file-picker col-md-3" v-if="files.length <= 3">
              <div class="file-picker_content w-100">
                <i class="far fa-images"></i>
              </div>
            </div>
            <div class="initial_file-picker col-md-3" v-if="files.length <= 4">
              <div class="file-picker_content w-100">
                <i class="far fa-images"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <a class="btn btn-primary" @click="triggerFilemanager">
        {{ translate('Add files', 'entities') }}
      </a>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash"

export default {
  name: 'FilePicker',
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      key: 0,
      files: [],
    }
  },
  created() {
    this.key = uniqueId('fpi_')

    this.files = (typeof this.value == 'string' ? JSON.parse(this.value) : this.value) || []

    // fpi = file picker input
    this.$fileManager.$on('selected_'+ this.key, imgs => {
      imgs.forEach(img => this.files.push(img));
      this.$emit('input', this.files)
    })
  },
  methods: {
    triggerFilemanager() {
      this.$fileManager.$emit('open', this.key)
    },
    remove(i) {
      this.files.splice(i, 1)
      this.$emit('input', this.files)
    },
    makeFeatured(i) {
      var removedElement = this.files.splice(i, 1)
      this.files.splice(0, 0, removedElement[0])
      this.$emit('input', this.files)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-picker_content {
    padding: .5rem;
    border: 2px dashed $border-color;
    border-radius: 5px;
    position: relative;
    width: max-content;
    max-width: 100%;
}

.file-picker_files {
    display: flex;
    flex-direction: column;

    .file-picker-featured {
        img {
            max-height: 200px;
            max-width: 100%;
        }

        &:hover .file-options_row {
            opacity: 1;
            visibility: visible;
        }
    }

    .file-picker_file {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover .file-options_row {
            opacity: 1;
            visibility: visible;
        }
    }

    .file-options_featured {
        position: absolute;
        top: 0;
        right: 0;
        margin: .5rem;
        transition: all .3s ease-in-out;
        opacity: 0;
        visibility: hidden;

        .btn {
            color: $primary;
            font-size: 1.3rem;
        }
    }

    .file-options_row {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        background-color: #00000015;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;

        a {
            color: #000;

            + a {
                margin-left: .5rem;
            }
        }
    }

    .col-md-3 {
        margin-bottom: 1rem;
    }
}

.initial_file-picker {
    &.file-picker_content {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        i {
            font-size: 40px;
        }
    }

    &.col-md-3 {
        .file-picker_content {
            min-height: 70px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
        }
    }
}
</style>
