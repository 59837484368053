<template>
    <div class="container">
        <div :class="{'finished': $router.currentRoute.name == 'finished'}" class="add-extension-header d-flex flex-column justify-content-center align-items-center">
            <span class="step-count">{{ translate('Step', wizard) }} {{ $router.currentRoute.meta.step }} {{ translate('of 5', 'wizard') }}</span>
            <h5 class="step-title font-weight-bold py-2">{{ $router.currentRoute.meta.title }}</h5>
            <span class="step-description text-center">{{ translate("Understanding the type of team you're creating, helps us to ask all the right questions.", 'wizard') }}</span>
            <div class="flags-container d-flex justify-content-between py-3">
                <img src="/img/flags/locales/4x3/nl.svg">
                <img src="/img/flags/locales/4x3/en.svg">
            </div>
        </div>

        <router-view :value="form"></router-view>
    </div>
</template>

<script>
    export default {
        name: "Wizard",
        data(){
            return {
                form: {
                	env: '',
                    name: '',
                    description: '',
                    apps: [],
                    extensions: [],
                    limits: [],
                    vat: '',
                    prices: [
                        {
                            name: 'Month',
                            price: '',
                            currency: 'EUR',
                            period: 1,
                            sale_price: '',
                            sale_price_from: '',
                            sale_price_until: '',
                            vat: ''
                        },
                        {
                            name: 'Year',
                            price: '',
                            currency: 'EUR',
                            period: 12,
                            sale_price: '',
                            sale_price_from: '',
                            sale_price_until: '',
                            vat: ''
                        },
                        {
                            name: 'Two years',
                            price: '',
                            currency: 'EUR',
                            period: 24,
                            sale_price: '',
                            sale_price_from: '',
                            sale_price_until: '',
                            vat: ''
                        },
                        {
                            name: 'Three years',
                            price: '',
                            currency: 'EUR',
                            period: 36,
                            sale_price: '',
                            sale_price_from: '',
                            sale_price_until: '',
                            vat: ''
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .add-extension-header.finished {
        display: none !important;
    }

    .step-count {
        font-size: 12px;
        text-transform: uppercase;
        color: #b5c4d9;
    }

    .add-extension-header {
        .step-description {
            width: 330px;
            color: #b5c4d9;
        }

        .flags-container {
            min-width: 70px;

            img {
                height: 30px;
                width: 30px;
                border-radius: 50%
            }
        }
    }
</style>
