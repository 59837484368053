<template>
    <div :class="{'container': $router.currentRoute.name !== 'order-final'}">
        <div :class="{'finished': $router.currentRoute.name == 'order-finish' || $router.currentRoute.name == 'order-final'}" class="add-order-header d-flex flex-column justify-content-center align-items-center">
            <span class="step-count">{{ translate('Step', 'orders') }} {{ $router.currentRoute.meta.step }} {{ translate('of', 'orders') }} 5</span>
            <h5 class="step-title font-weight-bold py-2">{{ $router.currentRoute.meta.title }}</h5>
            <span class="step-description text-center">{{ translate('Understanding the type of team you are creating, helps us to ask all the right questions.', 'orders') }}</span>
            <div class="flags-container d-flex justify-content-between py-3">
                <img src="/img/flags/locales/4x3/nl.svg">
                <img src="/img/flags/locales/4x3/en.svg">
            </div>
        </div>

        <div>
            <router-view :value="form"></router-view>
        </div>

		<div :class="{'d-none': $router.currentRoute.name == 'client'}">
			<div :class="{'finished': $router.currentRoute.name == 'order-finish' ||
			$router.currentRoute.name == 'order-final'}" class="order-footer d-flex justify-content-between my-2">
				<button class="btn btn-white rounded-pill" @click="cancel">{{ translate('Cancel order', 'orders') }}</button>
				<span class="step-count">{{ translate('Step', 'orders') }} {{ $router.currentRoute.meta.step }} {{ translate('of', 'orders') }} 5</span>
				<div>
					<router-link v-if="$router.currentRoute.name !== 'choose-plan'" class="btn btn-white rounded-pill"
								 :to="`/blockz-order/create/` + $router.currentRoute.meta.back">
						{{ translate('Go back', 'orders') }}
					</router-link>
					<router-link class="btn btn-primary rounded-pill" :to="`/blockz-order/create/` + $router.currentRoute.meta.next">
						{{ translate('Continue', 'orders') }}
					</router-link>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    export default {
        name: "CreateOrderIndex",
        data() {
            return {
                form: {

                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/orders')
                window.localStorage.removeItem('session')
                this.$store.state.blockzCheckout.data.additional_data = {}
                this.$store.state.blockzCheckout.cart = []
            }
        }
    }
</script>

<style scoped lang="scss">
    .add-order-header.finished {
        display: none !important;
    }

    .step-count {
        font-size: 12px;
        text-transform: uppercase;
        color: #b5c4d9;
    }

    .add-order-header {
        .step-description {
            width: 330px;
            color: #b5c4d9;
        }

        .flags-container {
            min-width: 70px;

            img {
                height: 30px;
                width: 30px;
                border-radius: 50%
            }
        }
    }

    .order-footer.finished {
        display: none !important;
    }

    .order-footer {
        .btn-cancel {
            background: #fff;
            border-color: #b5c4d9;
            color: #000;
            &:hover {
                background: var(--secondary-btn-color);
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>
