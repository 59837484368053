<template>
	<div>
		<!--		<div>-->
		<!--			<page-title page-title="order_details"></page-title>-->
		<!--			<div class="my-3" v-if="order">-->
		<!--				<div class="row">-->
		<!--					<div class="col-md-12">-->
		<!--						<div class="d-flex justify-content-between align-items-center mb-3">-->
		<!--							<router-link to="/orders">-->
		<!--								<i class="fa fa-chevron-left"></i>-->
		<!--								{{ translate('orders.back_to_orders') }}-->
		<!--							</router-link>-->
		<!--							<div class="change-order-status">-->
		<!--								<span><b>{{ translate('orders.current_status') }}:</b></span>-->
		<!--								<button class="ml-3 btn">-->
		<!--									<span-->
		<!--										:class="`status-badge ${order.status}`">{{ translate('orders.' + order.status) }}</span>-->
		<!--								</button>-->
		<!--								<div class="btn-group" v-if="order.status == 'pending'">-->
		<!--									<button class="btn" :class="`status-badge approved`"-->
		<!--											@click="changeOrderStatus('complete')">-->
		<!--										<span>{{ translate('orders.approve') }}</span>-->
		<!--									</button>-->
		<!--									<button class="btn" :class="`status-badge canceled`"-->
		<!--											@click="changeOrderStatus('cancel')">-->
		<!--										<span>{{ translate('orders.reject') }}</span>-->
		<!--									</button>-->
		<!--								</div>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--					<div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">-->
		<!--						<div class="card p-4">-->
		<!--							<h6 data-v-323bbc33="" class="d-inline-block mb-3">{{ translate('orders.order_details') -->
		<!--								}}</h6>-->
		<!--							<ul>-->
		<!--								<li><b>{{ translate('orders.order_number') }}:</b><span>{{ order.order_number }}</span>-->
		<!--								</li>-->
		<!--								<li>-->
		<!--									<b>{{ translate('orders.order_period') }}:</b>-->
		<!--									<span>{{ translate('orders.years') }}</span>-->
		<!--									<span>{{ order.additional_data.payment_terms }}</span>-->
		<!--								</li>-->
		<!--								<li><b>{{ translate('orders.name') }}:</b>-->
		<!--									<span>{{ order.billing_client_first_name }} {{ order.billing_client_last_name }}</span>-->
		<!--								</li>-->
		<!--								<li><b>{{ translate('orders.email') -->
		<!--									}}:</b><span>{{ order.billing_client_email }}</span></li>-->
		<!--								<li><b>{{ translate('orders.phone_number') -->
		<!--									}}:</b><span>{{ order.billing_client_phone }}</span></li>-->
		<!--							</ul>-->
		<!--							<div class="d-flex flex-column align-items-center mt-4">-->
		<!--								&lt;!&ndash;                            <img :src="order.barcode" alt="">&ndash;&gt;-->
		<!--								{{ order.order_number }}-->
		<!--							</div>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--					<div class="col-xl-8 col-lg-6 col-md-12 col-sm-12">-->
		<!--						<div class="card p-4">-->
		<!--							<h6 data-v-323bbc33="" class="d-inline-block mb-3">{{ translate('orders.order_items') -->
		<!--								}}</h6>-->
		<!--							<div class="table-responsive">-->
		<!--								<table class="table align-items-center">-->
		<!--									<thead>-->
		<!--									<tr>-->
		<!--										<th><b>{{ translate('orders.item_name') }}</b></th>-->
		<!--										<th><b>{{ translate('orders.item_qty') }}</b></th>-->
		<!--										<th><b>{{ translate('orders.single_price') }}</b></th>-->
		<!--										<th><b>{{ translate('orders.total') }}</b></th>-->
		<!--									</tr>-->
		<!--									</thead>-->
		<!--									<tbody class="list">-->
		<!--									<tr v-for="(item, ii) in order.items" :key="ii">-->
		<!--										<td class="text-limit">-->
		<!--											{{ item.item_name }}-->
		<!--											<span class="text-muted font-italic" v-if="item.total_amount === '0.00'">-->
		<!--											(included in plan)-->
		<!--										</span>-->
		<!--										</td>-->
		<!--										<td class="text-limit">-->
		<!--											{{ item.qty }}-->
		<!--										</td>-->
		<!--										<td class="text-limit">-->
		<!--											{{ item.single_price }}-->
		<!--										</td>-->
		<!--										<td class="text-limit">-->
		<!--											{{ item.total_amount }}-->
		<!--										</td>-->
		<!--									</tr>-->
		<!--									</tbody>-->
		<!--								</table>-->
		<!--							</div>-->
		<!--							<h6 class="text-right mt-3 mr-2"><b>{{ translate('orders.total') }}: </b>-->
		<!--								<span>{{ order.total_amount }} </span>-->
		<!--								<span class="font-weight-600">{{ order.currency_code }}</span>-->
		<!--							</h6>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</div>-->
		<div v-if="order" class="container">
			<page-title
          :page-title="translate(`Order ${order.order_number}`, 'orders')"
          :buttons="'/orders'"
      />
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="order-price d-flex justify-content-center align-items-center flex-column py-3">
							<span class="order-totals">{{ translate('Totals', 'orders') }}</span>
							<div class="order-single-price d-flex justify-content-between align-items-center">
								<span class="order-currency font-weight-bold mr-3">{{ currency(order.currency_code) }}</span>
								<span class="order-amount font-weight-bold mr-3">{{ order.total_amount }}</span>
							</div>
							<span v-if="order.tax_amount === '0.00' || order.tax_amount === null" class="text-muted">
								/{{ translate('No VAT included', 'orders') }}
							</span>
							<span v-else class="text-muted">
								/ {{ translate('incl', 'orders') }}
								{{ order.tax_amount }}
								{{ translate('VAT', 'orders') }}
							</span>
						</div>
					</div>
					<div class="col-md-12">
						<div class="my-4">
							<div class="row">
								<div class="col-md-4">
									<div
										class="col-styling user-col d-flex flex-column justify-content-center align-items-center bg-white h-100 p-4">
										<img v-if="order.avatar"
											 :src="order.avatar" alt="user avatar">
										<img v-else src="/img/default_logo.png" alt="user avatar">
										<h3 class="font-weight-900">{{ order.billing_client_first_name }}
											{{ order.billing_client_last_name }}</h3>
										<span class="customer-number">({{ translate('Customer number', 'orders') }}:
											{{ order.client_id }})</span>
										<div class="customer-info w-100 mt-2">
											<div class="single-info d-flex justify-content-between py-2">
												<span>{{ translate('Street number', 'orders') }}</span>
												<span class="info-value">{{ order.billing_address_address }}</span>
											</div>
											<div class="single-info d-flex justify-content-between py-2">
												<span>{{ translate('Zip city', 'orders') }}</span>
												<span class="info-value">{{ order.billing_address_postcode }} |
												{{ order.billing_address_city }}</span>
											</div>
											<div class="single-info d-flex justify-content-between py-2">
												<span>{{ translate('Email', 'orders') }}</span>
												<span class="info-value">{{ order.billing_client_email }}</span>
											</div>
											<div class="single-info d-flex justify-content-between py-2">
												<span>{{ translate('Phone', 'orders') }}</span>
												<span class="info-value">{{ order.billing_client_phone }}</span>
											</div>
											<div class="single-info d-flex justify-content-between py-2">
												<span>{{ translate('Customer since', 'orders') }}</span>
												<span class="info-value">20 20 20</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="d-flex flex-column h-100">
										<div class="col-style">
											<div
												class="col-style-child d-flex justify-content-between align-items-center h-100 px-3">
												<span class="system-text-primary font-weight-bold">
													{{ translate('Order number', 'orders') }}
												</span>
												<span class="order-number">{{ order.order_number }}</span>
											</div>
										</div>
										<div class="col-style">
											<div
												class="col-style-child d-flex justify-content-between align-items-center h-100 px-3">
												<span class="system-text-primary font-weight-bold">
													{{ translate('Order status', 'orders') }}
												</span>
												<span class="d-flex align-items-center"
													  :class="`status-badge ${order.status} `">
													{{ translate(order.status, 'orders') }}
													<i class="fa fa-chevron-down px-2"></i>
												</span>
											</div>
										</div>
										<div class="col-style">
											<div
												class="col-style-child d-flex justify-content-between align-items-center h-100 px-3">
												<span class="system-text-primary font-weight-bold">
													{{ translate('Invoice number', 'orders') }}
												</span>
												<span class="invoice-number">
													{{ invoice.invoice_number }}
												</span>
											</div>
										</div>
										<div class="col-style">
											<div
												class="col-style-child d-flex justify-content-between align-items-center h-100 px-3">
												<span class="system-text-primary font-weight-bold">
													{{ translate('Order date', 'orders') }}
												</span>
												<span>{{ created_at }}</span>
											</div>
										</div>
										<div class="col-style">
											<div
												class="col-style-child d-flex justify-content-between align-items-center h-100 px-3">
												<span class="system-text-primary font-weight-bold">
													{{ translate('Payment status', 'orders') }}
												</span>
												<span class="payment-status">
													{{ translate('Invoice', 'orders') }} {{ invoice.status }}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="col-styling position-relative bg-white h-100 p-4 pb-2 mb-5">
										<textarea v-model="order.notes"
												  class="form-control" type="text"
												  :placeholder="translate('Put a note to this order', 'orders')">
										</textarea>
										<transition name="fade">
											<button v-if="order.notes"
													@click="saveNote"
													class="btn btn-primary btn-save-textarea">
												{{ translate('Save changes', 'global') }}
											</button>
										</transition>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 mt-3">
						<div class="w-100">
							<div class="main-table border-0">
								<div class="table-responsive rounded">
									<table class="table m-0">
										<thead class="bg-white">
										<tr>
											<th class="font-weight-bold" scope="col">
												{{ translate('Item name', 'orders') }}
											</th>
											<th class="font-weight-bold" scope="col">
												{{ translate('Item quantity', 'orders') }}
											</th>
											<th class="font-weight-bold" scope="col">
												{{ translate('Single price', 'orders') }}
											</th>
											<th class="font-weight-bold" scope="col">
												{{ translate('Payment terms', 'orders') }}
											</th>
											<th class="font-weight-bold" scope="col">
												{{ translate('Contract years', 'orders') }}
											</th>
										</tr>
										</thead>

										<tbody>
										<tr v-for="(item, i) in order.items" :key="i">
											<td>
												<div>
													<span class="font-weight-bold">
														{{ item.item_name }}
															<span class="text-muted font-italic font-weight-300"
																  v-if="item.total_amount === '0.00'">
																({{ translate('included in plan', 'orders') }})
															</span>
													</span>
												</div>
											</td>
											<td class="text-limit">
													<span class="font-weight-bold">
														{{ item.qty }}
													</span>
											</td>
											<td>
													<span class="font-weight-bold">
														{{ currency(order.currency_code) }}
														{{ item.single_price }}
													</span>
											</td>
											<td>
													<span class="font-weight-bold">
														<span>
															{{ currency(order.currency_code) }}
															{{ item.total_amount }}
														</span><br>
														<span>
															{{ translate('yearly', 'orders') }}
														</span>
													</span>
											</td>
											<td>
												<div>
														<span class="font-weight-bold"
															  v-if="order.additional_data.period == 12">1 {{ translate('Year', 'orders') }} /
															<span v-if="remaining_days"
																  class="period-left font-italic font-weight-300">
																({{ remaining_days }} {{ translate('days remaining', 'orders') }})
															</span>
															<span v-else class="period-left font-italic font-weight-300">
																({{ still_trial }})
															</span>
														</span>
													<span class="font-weight-bold"
														  v-if="order.additional_data.period == 24">2 {{ translate('Years', 'orders') }}
															<span v-if="remaining_days"
																  class="period-left font-italic font-weight-300">
																({{ remaining_days }} {{ translate('days remaining', 'orders') }})
															</span>
															<span v-else class="period-left font-italic font-weight-300">
																({{ still_trial }})
															</span>
														</span>
													<span class="font-weight-bold"
														  v-if="order.additional_data.period == 36">3 {{ translate('Years', 'orders') }}
															<span v-if="remaining_days"
																  class="period-left font-italic font-weight-300">
																({{ remaining_days }} {{ translate('days remaining', 'orders') }})
															</span>
															<span v-else class="period-left font-italic font-weight-300">
																({{ still_trial }})
															</span>
														</span>
												</div>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="col-styling bg-white d-flex justify-content-between mt-4 p-3">
							<button class="btn btn-primary rounded-pill px-5">{{ translate('Refund', 'orders') }}</button>
							<div class="w-50 d-flex justify-content-between align-items-center">
								<div class="vat-details font-weight-bold">
									<span>{{ translate('Total excl VAT', 'orders') }}: </span>
									<span>
										{{ currency(order.currency_code) }}
										{{ order.total_amount_without_vat }}
									</span>
								</div>

								<div class="vat-details font-weight-bold">
									<span>{{ translate('VAT', 'orders') }}: </span>
									<span>
										{{ order.tax_amount }}
									</span>
								</div>

								<div class="vat-details font-weight-bold">
									<span>{{ translate('Total incl VAT', 'orders') }}: </span>
									<span>
										{{ currency(order.currency_code) }}
										{{ order.total_amount }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {db, modules} from "@/utilities/database";
import moment from 'moment'

export default {
	name: "ShowOrder",
	components: {moment},
	data() {
		return {
			order: {},
			invoice: {},
			created_at: null,
			remaining_days: null,
			still_trial: null,
			notes: null,
		}
	},
	methods: {
		async loadOrder() {
			this.$store.state.system.isLoading = true
			await db.get(`modules/sales/orders/${this.$route.params.id}`).then(res => {
				this.order = res.data.data
				this.invoice = this.order.invoices.slice(-1)[0]
				this.$store.state.system.isLoading = false

				let sub = this.order.subscriptions[0]
				if (sub.contract_start) {
					let end_date = moment(sub.contract_end).format(`YYYY, M, DD`)
					this.remaining_days = parseInt(moment.duration(moment(new Date()).diff(end_date)).asDays()).toString().slice(1)

					let start_order = moment(sub.contract_start).format('DD MMM YYYY')
					if (moment(moment(new Date())).format('DD MMM YYYY') < start_order) {
						this.created_at = 'Still in trial'
					} else {
						this.created_at = moment(sub.contract_start).format('DD MMM YYYY')
					}
				} else {
					this.still_trial = 'Still in trial'
				}
			})
		},
		changeOrderStatus(status) {
			this.$store.state.system.isLoading = true
			this.order = {}
			db.post(`modules/sales/orders/${status}/${this.$route.params.id}`).then(res => {
				this.order = res.data.data
				this.$store.state.system.isLoading = false
        this.$toast.requestSuccess('post', 'Order')
			})
		},
		saveNote() {
			// db.post(`modules/sales/orders/${this.$route.params.id}`)
      return this.$toast.error('Sorry, you cannot save notes now!')
		}
	},
	created() {
		this.loadOrder()
	},
	watch: {
		'$route.params.id': function () {
			this.order = {}
			this.total = 0
			this.loadOrder()
		}
	}
}
</script>

<style scoped lang="scss">
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.change-order-status {
	display: flex;
	align-items: center;
}

.main-table {
	.table-responsive {
		border: 1px solid #a0a0a0;

		table {
			tr {
				border-bottom: 1px solid #e5edf5;

				&:last-of-type {
					border-bottom: none;
				}
			}

			thead tr th {
				font-size: 0.9rem;
				border-top: none !important;
				border-bottom-color: #e5edf5;
			}

			tbody tr td {
				border-top: 1px solid #e5edf5 !important;
			}
		}
	}
}

small {
	font-size: 70%;
}

.col-styling {
	border: 1px solid #a0a0a0;
	border-radius: 5px;

	&.user-col {
		img {
			width: 100px;
			border-radius: 50%;
		}

		.customer-number {
			color: #9a9a9a;
		}

		.customer-info {
			.single-info {
				border-bottom: 1px solid #000;

				&:first-of-type {
					border-top: 1px solid #000;
				}

				.info-value {
					color: $primary;
				}
			}
		}
	}

	.btn-save-textarea {
		position: absolute;
		right: 1rem;
		bottom: 2rem;
	}

	.vat-details {
		font-size: 0.9rem;
	}
}

.order-price {
	background-color: #000;
	border-radius: 5px;

	.order-totals {
		color: #fff;
		text-transform: uppercase;
	}

	.order-single-price {
		.order-currency {
			font-size: 24px;
			color: #ff004f;
		}

		.order-amount {
			font-size: 46px;
			color: #fff;
		}
	}

	.order-vat {
		color: #9a9a9a;
	}
}

.order-footer {
	.btn + .btn {
		margin: 0;
	}
}

.period-left {
	color: #9a9a9a;
}

.col-style {
	flex-grow: 1;
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0;
	}

	.col-style-child {
		background-color: white;
		border: 1px solid #a0a0a0;
		border-radius: 10px;
	}

	.order-numnber, .invoice-number, .payment-status {
		color: #ff004f;
	}

	.status-badge {
		cursor: pointer;

		i {
			font-size: 16px;
			color: #000;
		}

		&.awaiting.payment {
			color: #d69710;
		}

		&.completed {
			color: #66d8a4;
		}

		&.failed {
			color: #f19dac;
		}
	}
}
</style>
