<template>
  <div>

    <page-title
      :page-title="translate(entityNamePlural, 'entities')"
      :borderBottom="hasPageTitleBorder"
      :counter="totalRecordsCount"
    >

      <!--    <page-title v-if="records.meta"-->
      <!--                :page-title="translate(entityType, 'global')"-->
      <!--                :counter="records && records.meta ? records.meta.total : false"-->
      <!--                :borderBottom="hasPageTitleBorder">-->

      <template v-slot:actions>
        <export-table :entity-type="entityType"
                      :actions="{ exportData }" />

        <!-- columnsFilterable is moved to Datatable.vue now -->
        <!-- <button v-if="columnsFilterable.length"> -->
        <button
          v-if="showFiltersButton"
          :class="{ 'active': filtersActive }"
          class="btn product-layout-buttons filters"
          @click="filtersActive = !filtersActive"
        >
          <i class="icon-erp-filter"></i>
          <span>{{ translate('Filters', 'entities') }}</span>
          <span v-if="filtersCount > 0" class="badge badge-primary badge-pill ml-2">{{ filtersCount }}</span>
        </button>

        <button
          class="btn btn-secondary"
          @click="clearFilters"
          v-tooltip="translate('Reset all filters and sorting', 'entities')"
        >
          <i class="fas fa-filter-circle-xmark"></i>
        </button>

        <div v-if="entityHasGridLayout" class="btn-group mx-3">
          <button class="btn product-layout-buttons" :class="{'active': layout === 'table'}" @click="layout = 'table'">
            <i class="fa fa-list"></i>
          </button>

          <button class="btn product-layout-buttons" :class="{'active': layout === 'grid'}" @click="layout = 'grid'">
            <i class="fa fa-th-large"></i>
          </button>
        </div>


        <router-link v-if="entitySlug && can('create', entitySlug)"
                     class="btn btn-primary rounded-pill"
                     :to="entityRoute(entityType, 'post')">
          <span class="btn-inner--icon p-3">{{ translate('Add new {entity}', 'entities', { entity: entityNameTranslated.toLowerCase() }) }}</span>
        </router-link>

      </template>
    </page-title>

    <div class="card mt-3 entity-index" :class="{ 'pages-form': entityType == 'cms-pages' }">

      <!-- TODO TABS
      <div class="card filter-nav">
        <ul class="nav nav-tabs scope-tabs" v-if="records.data && records.filter && $store.state.system.interface != 'rock'">
          <li class="nav-item pt-3" :class="{'active': selectedFilter == null}">
            <a href="javascript:;" class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
               @click="filterBySelectedTab(null)">
              {{ translate(`All`, 'global') }}
              <span class="counter ml-4">{{ records.data.length }}</span>
            </a>
          </li>
          <li class="nav-item d-flex justify-content-between align-items-center pt-3 px-2" @click="filterBySelectedTab(filter.key)"
              v-for="(filter, fi) in entityFilterTabs" :key="fi"
              :class="{'active': selectedFilter === filter.key}">
            <a href="javascript:;" class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
               @click="selectedFilter = filter.key">
              {{ translate(filter.label, 'global')}}
              <span class="counter ml-4">{{ filter.count }}</span>
            </a>
          </li>
        </ul>
      </div>
      -->

      <!-- key is required to re-render datatable columns on change entity type -->
      <!-- <data-table v-if="records" :key="`data-table-${entityType}`"-->

      <data-table
        :entity-type="entityType"
        has-filters
        :filters-active="filtersActive"
        @onFiltersClose="filtersActive = false"
        @showFiltersButton="showFiltersButton = true"
        @updateEntityName="entityName = $event"
        @updateEntitySlug="entitySlug = $event"
        @updateFiltersCount="filtersCount = $event"
        @updateTotalRecordsCount="totalRecordsCount = $event"
        :dtFilters="dtFilters"
        :dtFilterProps="dtFilterProps"
        :custom-filters="customFilters"
        :layout="layout"
        ref="datatable"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from './PageTitle.vue'
import DataTable from './table/DataTable.vue'
import ExportTable from '@/modules/erp_entities/components/page/table/ExportTable.vue'

import { can, setPageTitle } from '@/utilities/helper'
import { entityRoute } from '@/modules/erp_entities/utilities/helper'

export default {
  name: "Entity",
  components: {
    PageTitle, DataTable, ExportTable,
  },
  props: {
    entityType: {
      required: true,
    },
    customFilters: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      formData: null,
      show: {
        export: false,
      },
      showFiltersButton: false,
      filtersActive: false,
      dtFilterProps: false,
      dtFilters: false,
      exportData: null,
      entityName: null,
      entitySlug: null,
      filtersCount: 0,
      totalRecordsCount: 0,
      layout: 'table',
    }
  },
  computed: {
    /* TODO TABS
    entityFilterTabs() {
      let filters = []
      if (this.records && this.records.data && this.records.filters) {
        this.records.filters.forEach(filter => {
          filters.push( {
            label: filter,
            key: Number(Object.keys(filter)[0]),
            count: Object.values(filter)[0]
          })
        })
      }
      return filters
    },*/

    entityNameTranslated(){
      return this.translate(this.entityName, 'entities')
    },
    entityNamePlural(){
      // products hot fix
      if(this.entityType === 'inventory'){
        return 'Products'
      }
      return this.entityType.capitalizeFirstLetter()
    },
    hasPageTitleBorder() {
      return (this.entityType == 'cms-pages' || this.$store.state.system.interface == 'rock') ? false : true
    },
    entityHasGridLayout() {
      return ['plans', 'extensions'].includes(this.entityType)
    },
  },

  mounted(){
    this.exportData = this.$refs['datatable'].exportData
  },

  methods: {
    can,
    entityRoute,
    async setUpRedirectProps() {
      const data = this.$route.query.redirectProps // || localStorage.getItem('redirectProps');
      if(data) {
        const redirectProps = JSON.parse(data)
        if(redirectProps && redirectProps.filterProps) {
          this.dtFilterProps = redirectProps.filterProps
        }
        if(redirectProps && redirectProps.filters) {
          this.dtFilters = redirectProps.filters
        }
      }
    },

    // todo not tested
    filterBySelectedTab(filterVal){
      return
      this.selectedFilter = filterVal
      let filters = {}
      if(filterVal !== null){
        filters = {
          key: this.records.filter,
          condition: '=',
          value: `"${this.selectedFilter}"`,
        }
      }
      // todo
      //this.loadData()
    },
    clearFilters(){
      this.$refs.datatable.clearFilters()
    },
  },

  async created(){
    setPageTitle(this.entityType)
    await this.setUpRedirectProps()
  },

  // required only for EntityIndex, todo move it there
  watch: {
    '$route.params.entityType': function(v){
      setPageTitle(v)
      this.showFiltersButton = false // the button is shown after dataLoaded if there are filters
    },
  },
}
</script>

<style scoped lang="scss">
.product-layout-buttons {
  background-color: #fff;
  color: #000;

  &.filters {
    border: 1px solid $border-color;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &.active {
    background-color: #000;
    color: #fff;
  }
}

// filter badge hot fix
.btn .badge {
  line-height: 1.1; // it is not aligned properly with bootstraps 1
}
</style>
