<template>
  <entity-index-content
    entity-type="inventory"
    :custom-filters="customFilters"
    class="products-index"
  />
</template>

<script>
import EntityIndexContent from "@/modules/erp_entities/components/page/EntityIndexContent.vue"

export default {
  name: "Products",
  components: { EntityIndexContent },
  computed: {
    customFilters(){
      return [
        {
          name: 'custom-filter-sale-status',
          label: 'Sale status',
          type: 'select',
          data: [
            { key: 'on-sale', value: 'On sale' },
            { key: 'not-on-sale', value: 'Not on sale' },
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss">
  // todo test if it not brakes any select and move to _custom-controls.scss
  .products-index .select2-form .vs__dropdown-toggle {
    background: #fff;
  }
  // end todo
</style>
