<template>
  <element-settings-content-tabs>
    <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
      <div class="row">
        <div class="col-md-12">
          <craft-input
            label="Placeholder text"
            :value="getSetting('placeholder')"
            @input="setSetting('placeholder', $event)"
          />
        </div>
        <div class="col-md-12">
          <craft-input
            label="No results text"
            :value="getSetting('no_results_text')"
            @input="setSetting('no_results_text', $event)"
          />
        </div>
      </div>
    </element-settings-content-tab>

    <element-settings-content-tab :name="translate('Layout', 'page-builder')">
      <div class="row">

        <div class="col-12">
          <craft-tab-pills
            :label="'Type'"
            :value="getSetting('type')"
            @input="setSetting('type', $event)"
            :options="roundTypeOptions"
          />
        </div>

        <div class="col-md-12">
          <craft-tab-pills
            :label="'Alignment'"
            :options="generalAlignmentOptions"
            :value="getSetting('alignment')"
            @input="setSetting('alignment', $event)"
          />
        </div>
        <div class="col-md-12">
          <craft-input
            label="Width"
            input-type="range"
            suffix="%"
            :min="0"
            :max="100"
            :value="getSetting('width', '%')"
            @input="setSetting('width', $event, '%')"
          />
        </div>
      </div>
    </element-settings-content-tab>
    <element-settings-content-tab :name="translate('Styling', 'page-builder')">

      <div class="row">
        <div class="col-md-6">
          <craft-color-picker
            :label="'Input background color'"
            :value="getSetting('background_color')"
            @input="setSetting('background_color', $event)"
          />
        </div>

        <div class="col-md-6">
          <craft-color-picker
            :label="'Button background color'"
            :value="getSetting('button_background_color')"
            @input="setSetting('button_background_color', $event)"
          />
        </div>

        <div class="col-md-6">
          <craft-color-picker
            :label="'Text color'"
            :value="getSetting('text_color')"
            @input="setSetting('text_color', $event)"
          />
        </div>

        <div class="col-md-6">
          <craft-color-picker
            :label="'Icon color'"
            :value="getSetting('icon_color')"
            @input="setSetting('icon_color', $event)"
          />
        </div>

        <div class="col-md-6">
          <craft-color-picker
            :label="'Placeholder color'"
            :value="getSetting('placeholder_color')"
            @input="setSetting('placeholder_color', $event)"
          />
        </div>

      <!-- Borders -->
        <div class="col-md-6">
          <craft-select
            :label="'Border style'"
            clearable
            :options="borderStyleOptions"
            :value="getSetting('border_style')"
            @input="setSetting('border_style', $event)"
          />
        </div>
        <div class="col-md-6">
          <craft-color-picker
            :label="'Border color'"
            :value="getSetting('border_color')"
            @input="setSetting('border_color', $event)"
          />
        </div>
        <div class="col-md-6">
          <craft-input
            :label="'Border width'"
            :input-type="'number'"
            :suffix="'PX'"
            :value="getSetting('border_width', 'px')"
            @input="setSetting('border_width', $event, 'px')"
          />
        </div>
      </div>
    </element-settings-content-tab>

  </element-settings-content-tabs>
</template>

<script>
import ElementSettingsContentTabs from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTabs.vue"
import ElementSettingsContentTab from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTab.vue"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import { CraftColorPicker, CraftInput, CraftSelect, CraftTabPills } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: "SearchBarSettings",
  components: { CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, ElementSettingsContentTab, ElementSettingsContentTabs },
  mixins: [CraftSettingsMixin, ComponentSettingsMixin],
}
</script>
