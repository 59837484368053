<template>
    <div v-show="isActiveTab" :class="{'py-4' : hasPadding}">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "ElementSettingsContentTab",
  inject: ['editor'],
  props: {
    name: {
      required: true
    },
    selected: {
      required: false,
      default: false
    },
    hasPadding: {
      required: false,
      default: true // tab containing tabs instead of content does not have padding top
    }
  },
  data() {
    return {
        isActiveTab: false
    }
  },
  mounted() {
    this.isActiveTab = this.selected
  }
}
</script>
