import menu from './menu';
import entities from "./entities";
import global from "./global";
import sidebar from "./sidebar";
import table from './table';
import dashboard from './dashboard.json';
import users from "./users";
import roles from "./roles";
import companies from "./companies";
import calendar from "./calendar";
import entity from "./entity";
import tracker from "./tracker";
import tasks from "./tasks";
import builder from "./builder";
import dropdowns from "./dropdowns";
import entity_settings from "./entity_settings";
import projects from "./projects";
import wizard from "./wizard";
import status from "./status";
import agenda from "./agenda";
import marketplace from "./marketplace";
import orders from "./orders";
import inventory from "./inventory";
import reports from './reports';
import subscriptions from './subscriptions';
import plans from './plans';
import clients from './clients.json';
import invoices from './invoices.json';
import attributes from './attributes.json';
import taxes from './taxes.json';
import onboarding from './onboarding.json';
import products from  './products.json';
import shipping from './shipping.json';
import payments from './payments.json';
import settings from './settings.json';

export default {
    name: 'English',
    groups:{
        menu,
        entities,
        global,
        sidebar,
        table,
        dashboard,
        users,
        roles,
        companies,
        calendar,
        entity,
        entity_settings,
        tracker,
        tasks,
        builder,
        dropdowns,
        projects,
        status,
        agenda,
        orders,
        inventory,
        marketplace,
        wizard,
        reports,
        subscriptions,
        plans,
        clients,
        invoices,
        attributes,
        taxes,
        onboarding,
        products,
        shipping,
        payments,
        settings
    }
}
