<template>
    <modal name="scheduler" :pivot-x="1"
           :pivot-y="0"
           transition="slide-fade"
           overlayTransition="wait"
           height="100%"
           width="85%"
           :adaptive="true">
        <div class="modal-default" v-if="value">
            <div class="modal-content">
                <div class="modal-header d-flex">
                    <h5 class="modal-title" v-if="!value.title">
                        {{ translate('Create event', 'entities') }}
                    </h5>
                    <h5 class="modal-title" v-else>
                        {{ value.title}} {{duration}}
                    </h5>
                    <button type="button" class="close" @click="$modal.hide('scheduler')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>{{ translate('Start datetime', 'agenda') }}</label>
                            <date-picker type="datetime" v-model="value.start" value-type="format" format="YYYY-MM-DD HH:mm" :placeholder="translate('Start datetime', 'agenda')" :input-class="['form-control']"/>
                        </div>
                        <div class="col-md-6">
                            <label>{{ translate('End datetime', 'agenda') }}</label>
                            <date-picker type="datetime" v-model="value.end" value-type="format" format="YYYY-MM-DD HH:mm" :placeholder="translate('End datetime', 'agenda')" :input-class="['form-control']"/>
                        </div>
                        <div class="col-md-12 mt-5">
                            <label>{{ translate('Event title', 'agenda') }}</label>
                            <input type="text" v-model="value.title" :placeholder="translate('Workflow improvements', 'agenda')" class="form-control">
                        </div>
                        <div class="col-md-12 mt-5">
                            <label>{{ translate('Event description', 'agenda') }}</label>
<!--                            vue-simplemde is removed, if needed use different editor-->
<!--                            <vue-simplemde v-model="value.description" />-->
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-right">
                    <button class="btn btn-white rounded-pill btn-sm " @click="$modal.hide('scheduler')">
                      {{ translate('Go back', 'global') }}
                    </button>
                    <button class="btn btn-primary rounded-pill btn-sm" @click="createEvent">
                      {{ translate('Create', 'global') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
// import EntityForm from '@/components/page/form/EntityForm'
import moment from 'moment'

export default {
    components: {
        DatePicker
    },
    methods: {
        createEvent(){
            // TODO: save to database
            this.$emit('created', {...this.value})
            this.$modal.hide('scheduler')
        },
        moment
    },
    props: {
        value: {
            required: true
        }
    },
    computed: {
        duration(){
            if(!this.value || !this.value.start){
                return '0 mins'
            }
            let duration = moment.duration(moment(this.value.end, 'YYYY-MM-DD HH:mm').diff(moment(this.value.start, 'YYYY-MM-DD HH:mm')))
            return `${duration.asHours()} hours`
        }
    }
}
</script>

<style>

</style>
