<template>
    <div class="row continent-wrapper">
        <div class="col-4">
            <div v-for="(continent, ci) in continents">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <input type="checkbox" @input="e => selectContinent(e.target.checked, ci)" class="mr-2">
                        <span @click="toggleVisibility(ci)" class="continent-name"
                              :class="{active: continents[ci].expand === true}">{{ ci }}</span>
                    </div>
                    <span @click="toggleVisibility(ci)" class="fa fa-caret-right"></span>
                </div>
            </div>
        </div>
        <div class="col-8 continent-countries">
            <div v-if="selectedContinent && continents[selectedContinent].expand" class="">
                <div v-for="(country, ci) in continents[selectedContinent].countries"
                     @click="selectCountry(country.id)"
                     class="d-flex align-items-center ml-3">
                    <span class="checked-element mr-2" :class="{active: value.includes(country.id)}"></span>
                    <img :src="`/img/flags/countries/4x3/${country.code.toLowerCase()}.svg`" width="20px" :alt="country.name">
                    <span class="ml-2 country-name" :class="{active: value.includes(country.id)}">
                        {{ country.name }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Countries",
        props: {
            value: {
                required: true
            }
        },
        data() {
            return {
                continents: null,
                selectedContinent: null,
            }
        },
        async created() {
            let continents = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/country/continents?perpage=9999')
            this.continents = continents.data.data
            for (const continent in this.continents) {
                this.$set(this.continents[continent], 'expand', false)
            }
        },
        methods: {
            toggleVisibility(continent) {
                this.selectedContinent = continent
                for (const continent in this.continents) {
                    this.continents[continent].expand = false
                }
                this.continents[continent].expand = true
            },
            selectContinent(v, c) {
                if (v) {
                    this.continents[c].countries.forEach(country => {
                      if(!this.value.includes(country.id)){
                        this.value.push(country.id)
                      }
                    })
                } else {
                  this.continents[c].countries.forEach(country => {
                    if(this.value.includes(country.id)){
                      this.value.splice(this.value.indexOf(country.id), 1);
                    }
                  })
                }
            },
            selectCountry(id) {
                if (this.value.includes(id)) {
                    this.value.splice(this.value.indexOf(id), 1);
                } else {
                    this.value.push(id)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .continent-wrapper {
        border-radius: 3px;
    }

    .continent-countries {
        border-left: 1px solid $border-color;
        height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: .4em;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $primary;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
            outline: 1px solid $primary;
            border-radius: 5px;
        }
    }

    .country-name, .continent-name {
        user-select: none;
        cursor: pointer;

        &.active {
            color: $primary;
            font-weight: 600;
        }
    }

    .checked-element {
        width: 13px;
        height: 15px;
        position: relative;
        border: 1px solid rgba(60, 60, 60, 0.26);

        &.active {
            border-color: $primary;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
                height: 2px;
                background-color: $primary;
            }
        }
    }
</style>
