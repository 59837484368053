import { SweetToast } from '@/utilities/sweetalert'
import {erp} from '@/modules/erp_framework';

export default {
    state: {
        data: {
            additional_data: {
                trial: null,
                additional_notes: null,
            }
        },
        cart: []
    },

    actions: {
        async addToCart({state}, item) {
            state.cart = []
            let data = {
                item_id: item.id,
            }
            if(item.inventory_type === 'limit') {
                data = {
                    item_id: item.id,
                    qty: item.properties.qty,
                    price: item.properties.price
                }
            }
            if(item.inventory_type === 'extension') {
                data = {
                    item_id: item.id,
                    price: item.price.price,
                    qty: 1,
                }
            }

            await erp.ext.request.axiosInstance.post(`/modules/blockz/cart/item/add`, data).then(res => {
                item.added = true
                state.cart.push(...res.data.data.items)
                state.data.additional_data = {...state.data.additional_data, ...res.data.data.additional_data}

                item.readable_options.name
                      ? SweetToast.requestSuccess('put', item.readable_options.name)
                      : SweetToast.requestSuccess('put', item.slug)
            }).catch((err) => {
                if(err.response.data.message) {
                  SweetToast.error(err.response.data.message)
                } else {
                  SweetToast.errorGeneral()
                }
            })
        },
        async removeFromCart({state}, item) {
            state.cart = []

            erp.ext.request.axiosInstance.delete(`/modules/blockz/cart/item/delete/${item.id}`).then(res => {
                item.added = false
                state.cart = res.data.data.items
                this.$toast.success('Item removed.')
            })
        },
        getCart({state}) {
            erp.ext.request.axiosInstance.get(`/modules/blockz/cart`).then(res => {
                if((res.data && res.data.data.length == 0) || res.data == '') {
                    return state.cart = []
                } else {
                    state.cart = res.data.data.items
                }
            })
        }
    },

    getters: {
        total(state) {
            let total = 0.00

            state.cart.forEach(item => {
                total += +item.total_amount
            })
            return total.toFixed(2)
        }
    },

    namespaced: true
}
