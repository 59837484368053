import ApiAuthorization from "./ApiAuthorization";


export default class Authentication extends ApiAuthorization {
    /**
     * By default we authorize guest user for token if needed
     */
    constructor(authorizeAsGuest = false, attachRefrehTokenListener = false)
    {
        super();
        
        this.auth = null

        if(authorizeAsGuest) this.authenticate()
        // if(attachRefrehTokenListener) this.listenForUnauthorizedRequestsWithToken()
    }

    /**
     *  Authenticate 
     *
     * @param {Object} {username = null, password = null}
     * @memberof Authentication
     */
    async authenticate(username = null, password = null, token = null)
    {
        let authorizationToken = token
        // Authenticate using database
        if (!token) {
            let authRequest = await this.getTokenFromApi(username, password);
            authorizationToken = authRequest.authorizationToken 
        }

        this.authorizationToken = authorizationToken
    }

    
    /**
     *  Will automatically handle token removal from storage and code,
     *  from setters in parent class
     *
     * @memberof Authentication
     */
    unauthenticate()
    {
        this.authorizationToken = null
        this.refreshToken = null
    }
    
}