<template>
    <div class="row" v-if="isWebsitePublished && website && info">
        <!-- <div class="col-md-4 d-flex">
            <div class="style-comp d-flex align-items-center p-4 mb-4 flex-grow-1 position-relative">
                <span class="mr-3"><i class="fa fa-chair fa-2x"></i></span>
                <div class="d-flex flex-column">
                    <small class="text-muted">{{ translate('seats', 'dashboard') }}</small>
                    <h6 class="mb-0 d-inline-block font-weight-700">
                        {{ usersLength }}
                        {{ translate('of', 'dashboard') }}
                        {{ $store.state.system.authData.limits['user-limit'] }}
                        {{ translate('seats used', 'dashboard') }}
                    </h6>
                </div>
                <router-link to="/users" class="published-website-btns">
                    {{ translate('Add contributor', 'dashboard') }}
                </router-link>
            </div>
        </div>

        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex align-items-center p-4 mb-4 flex-grow-1 position-relative">
                <span class="mr-3"><i class="fas fa-chart-bar fa-2x"></i></span>
                <div class="d-flex flex-column w-100">
                    <small class="text-muted">{{ translate('Products', 'dashboard') }}</small>
                    <div>
                      <progress-bar value="10" total="10"/>
                      <span>{{ translate('{completed} of {total} finished', 'dashboard'completed: 10, total: 10 ) }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex align-items-center p-4 mb-4 flex-grow-1 position-relative">
                <span class="mr-3"><i class="fas fa-chart-bar fa-2x"></i></span>
                <div class="d-flex flex-column w-100">
                    <small class="text-muted">{{ translate('Pages', 'dashboard') }}</small>
                    <div>
                      <progress-bar value="3" total="10"/>
                      <span>{{ translate('{completed} of {total} finished', 'dashboard', { completed: 3, total: 10 }) }}</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: "PublishedWebsite",
    async mounted() {
        let users = await this.erp.ext.request.axiosInstance.get('/users')
        this.usersLength = users.data.data.length
    },
    data() {
        return {
            usersLength: 0
        }
    },
    props: {
        isWebsitePublished: {
            required: true
        },
        website: {
            required: true
        },
        info: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>
