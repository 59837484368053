export default function (node) {
  const accordionId = 'accordion-' + node.uuid

  let styles = ''
  // type !important in place because of frontend .card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;}
  if (node.props.settings['type'] === 'rounded') {
    styles += `#${accordionId} .card-header {border-radius: 0.25rem!important}`
  } else if (node.props.settings['type'] === 'square') {
    styles += `#${accordionId} .card-header {border-radius: 0!important}`
  } else if (node.props.settings['type'] === 'round') {
    styles += `#${accordionId} .card-header {border-radius: 50rem!important}`
  }

  if (node.props.settings['background_color']) {
    styles += `#${accordionId} .card-header {background-color: ${node.props.settings['background_color']}}`
  }
  if (node.props.settings['color']) {
    styles += `#${accordionId} .card-header button {color: ${node.props.settings['color']}}`
  }
  if (node.props.settings['font_size']) {
    styles += `#${accordionId} .card-header button {font-size: ${node.props.settings['font_size']}}`
  }

  if (node.props.settings['border_style'] && node.props.settings['border_width']) {
    styles += `#${accordionId} .card-header {border-style: ${node.props.settings['border_style']}}`
    styles += `#${accordionId} .card-header {border-width: ${node.props.settings['border_width']}}`
  }

  if (node.props.settings['border_color']) {
    styles += `#${accordionId} .card-header {border-color: ${node.props.settings['border_color']}}`
  }

  if (node.props.settings['icon_color']) {
    styles += `#${accordionId} .card-header i.fa {color: ${node.props.settings['icon_color']}}`
  }

  if (node.props.settings['icon']) {
    styles += `#${accordionId} .card-header .accordion-button:not(.collapsed) i.collapsed {display: none}`
    styles += `#${accordionId} .card-header .accordion-button.collapsed i:not(.collapsed) {display: none}`
  }

  return styles
}