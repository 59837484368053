export default function (node) {
  const bannerId = 'banner-' + node.uuid

  let styles = ''

  // overlay
  if (node.props.settings['background_overlay_color'])
  {
    styles += `#${bannerId} .cms-block-banner-background-overlay { background: ${node.props.settings['background_overlay_color']} }`
  }

  // height
  if (node.props.settings['height']) {
    styles += `#${bannerId} { height: ${node.props.settings['height']} }`
  }

  // Content vertical alignment
  styles += `#${bannerId} .cms-block-banner-content { justify-content: ${node.props.settings['content_justify_content']} }`

  // Button styles
  if (node.props.settings['button_background_color']) {
    styles += `#${bannerId} a { background-color: ${node.props.settings['button_background_color']} !important }`
  }
  if (node.props.settings['button_background_hover_color']) {
    styles += `#${bannerId} a:hover { background-color: ${node.props.settings['button_background_hover_color']} !important }`
  }
  if (node.props.settings['border_radius']) {
    styles += `#${bannerId} a { border-radius: ${node.props.settings['border_radius']} }`
  }
  if (node.props.settings['button_width']) {
    styles += `#${bannerId} a { width: ${node.props.settings['button_width']} }`
  }
  if (node.props.settings['button_margin']) {
    styles += `#${bannerId} a { margin: ${node.props.settings['button_margin']} }`
  }
  if (node.props.settings['button_border_color']) {
    styles += `#${bannerId} a { border-color: ${node.props.settings['button_border_color']} } `
  }
  if (node.props.settings['button_text_color']) {
    styles += `#${bannerId} a { color: ${node.props.settings['button_text_color']} }`
  }
  if (node.props.settings['button_hover_text_color']) {
    styles += `#${bannerId} a:hover { color: ${node.props.settings['button_hover_text_color']} }`
  }
  if (node.props.settings['button_font_size']) {
    styles += `#${bannerId} a { font-size: ${node.props.settings['button_font_size']}px }`
  }
  if (node.props.settings['border_hover_color']) {
    styles += `#${bannerId} a:hover { border-color: ${node.props.settings['border_hover_color']} }`
  }
  if (node.props.settings['button_border_style']) {
    styles += `#${bannerId} a { border-style: ${node.props.settings['button_border_style']} }`
  }
  if (node.props.settings['button_border_width']) {
    styles += `#${bannerId} a { border-width: ${node.props.settings['button_border_width']}px }`
  }

  return styles
}