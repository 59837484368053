<template>
  <div class="container-fluid">
    <page-title :page-title="translate('Translation manager', 'global')">
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ translate('Choose translations for App or Frontside', 'global') }}</label>
          <v-select v-model="system" :options="['app', 'front']"></v-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ translate('Locale', 'global') }}</label>
          <v-select @input="v => selectLocale(v)" v-model="locale" :options="locales"></v-select>
        </div>
      </div>
      <div class="col-md-4" v-if="locale && system === 'app'">
        <div class="form-group">
          <label>{{ translate('Group', 'global') }}</label>
          <v-select @input="v => selectGroup(v)" taggable push-tags v-model="group" :options="groups"></v-select>
        </div>
      </div>

      <div class="col-md-12" v-if="locale && (group || system === 'front')">

        <div class="table-responsive">

          <table class="table align-items-center m-0">
            <thead class="thead-light">
              <tr>
                <th scope="col">{{ translate('Key', 'global') }} <img :src="'/img/flags/locales/4x3/en.svg'" class="flag"/></th>
                <th scope="col">{{ translate('Value', 'global') }} <img :src="'/img/flags/locales/4x3/'+locale+'.svg'" class="flag"/></th>
                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-1" v-for="(a, i) in translations">
                <td>
                  <textarea :value="a.key"
                            @input="e => changeKey(e.target.value, i)"
                            class="form-control"/>

                </td>
                <td>
                  <textarea :value="a.value"
                            @input="e => changeValue(e.target.value, i)"
                            class="form-control"/>
                </td>
                <td class="text-right">
                  <action-button :key="`cta-delete-${a.key}`" v-tooltip="translate('Delete', 'global')"
                                 class-icon="fa-trash-alt" @click.native="deleteRow(i)"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <th colspan="3">
                <button class="btn btn-primary rounded-pill mb-3" @click="addNewRow">{{ translate('Add new row', 'global') }}</button>
              </th>
            </tfoot>
          </table>

        </div>

      </div>
    </div>

    <div class="d-flex justify-content-end mb-3">
      <button class="btn btn-primary rounded-pill" @click="save">{{ translate('Save changes', 'global') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Manager",
  data() {
    return {
      translations: [],
      groups: null,
      locales: null,
      locale: '',
      group: '',
      system: '',
      saved: false,
    }
  },
  async created() {
    /* OLD WAY
    this.locales = this.$store.state.system.authData.company.locales
    this.locales.push('en')
    */
    this.locales = ['en', 'nl', 'es', 'fr', 'it', 'de', 'cz', 'pl', 'pt', 'ru', 'ua', 'se']

    let groups = await this.erp.ext.request.axiosInstance.get(`/modules/translations/dropdown/groups`)
    this.groups = Object.keys(groups.data)
  },
  methods: {
    changeValue(v, i) {
      this.translations[i].value = v
    },
    changeKey(newKey, i) {
      newKey = newKey.trim()
      this.translations[i].key = newKey
    },
    addNewRow() {
      const length = this.translations.length
      this.translations.push({ key: '', value: '' })
      this.$forceUpdate()
    },
    async deleteRow(row){
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.translations.splice(row, 1)
    },
    async selectLocale(v) {
      this.locale = v
      this.group = ''
      this.translations = {}
      if (this.system === 'app') return
      if (!v) return this.translations = {}
      let translations = await this.erp.ext.request.axiosInstance.get(`/modules/translations/${this.locale.toLowerCase()}/front?group_by=translation`)
      if (translations.data.data.length === 0) {
        return this.translations = []
      } else {
        const trArray = [];
        for (let key in translations.data.data) {
          trArray.push({ key, value: translations.data.data[key] })
        }
        return this.translations = trArray;
      }
    },
    async selectGroup(v) {
      if (!v) return this.translations = {}
      this.group = v
      let translations = await this.erp.ext.request.axiosInstance.get(`/modules/translations/${this.locale.toLowerCase()}/${v}?group_by=translation`)
      if (translations.data.data.length === 0) {
        return this.translations = [];
      } else {
        const trArray = [];
        for (let key in translations.data.data) {
          trArray.push({ key, value: translations.data.data[key] })
        }
        return this.translations = trArray
      }
    },
    async save() {
      this.$store.state.system.isLoading = true
      let route = this.system === 'app'
        ? `/modules/translations/${this.locale.toLowerCase()}/${this.group}`
        : `/modules/translations/${this.locale.toLowerCase()}/front`

      let tObject = this.translations.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})

      await this.erp.ext.request.axiosInstance.post(route, {
        translations: tObject,
        system: this.system,
      })
      let res = await this.erp.ext.request.axiosInstance.get('/modules/translations')
      window.localStorage.setItem('translations', JSON.stringify(res.data.data))
      this.$toast.requestSuccess(false, 'Translations')
      this.$store.state.system.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
  table {
    margin-top: 200px;
    img.flag {
      height: 18px;
      margin-left: 5px;
    }
  }
</style>