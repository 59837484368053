<template>
    <div class="order-finish">
        <span><i class="fa fa-check-circle"></i></span>
        <h3 class="font-weight-600">{{ translate('orders.success', 'orders') }}</h3>
        <div class="d-flex mt-3">
            <router-link to="/" class="btn btn-white rounded-pill">
              {{ translate('Go back', 'orders') }}
            </router-link>
            <a href="/blockz-order/create/choose-plan" class="btn btn-primary rounded-pill">
              {{ translate('Create another', 'orders') }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderFinish"
    }
</script>

<style scoped lang="scss">
    .order-finish {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 3rem;

        span {
            font-size: 50px;
            color: #00da7d;
        }
    }
</style>
