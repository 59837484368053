<template>
    <g :transform="`translate(${x} ${y}) scale(13)`">
        <path class="st0" d="M1.2,1.1L1.2,1.1l0.1,0V1H1.2L1.1,0.2h0H0.3H0.2V1H0.1v0.1h0.1v0H0.1v0.6h0.1v0.7h1V1.7h0.1V1.1H1.2z M1.1,0.3
	H0.3v0L1.1,0.3L1.1,0.3z M1.1,0.4H0.3v0L1.1,0.4L1.1,0.4z M1.1,0.5H0.3v0L1.1,0.5L1.1,0.5z M1.1,0.7H0.3v0L1.1,0.7L1.1,0.7z
	 M1.1,0.8H0.3v0L1.1,0.8L1.1,0.8z M1.1,0.9H0.3v0L1.1,0.9L1.1,0.9z M1.1,1.1H0.3v0L1.1,1.1L1.1,1.1z M1.1,1.2H0.3v0L1.1,1.2L1.1,1.2
	z M1.1,1.3H0.3v0L1.1,1.3L1.1,1.3z M1.1,1.5H0.3v0L1.1,1.5L1.1,1.5z M1.1,1.6H0.3v0L1.1,1.6L1.1,1.6z"/>
        <g>
            <rect x="0.1" y="1.8" class="st1" width="1" height="0.8"/>
            <rect x="0.2" y="1.7" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="1.8" class="st1" width="0.8" height="0.8"/>
            <rect x="0.2" y="1.8" class="st3" width="0.8" height="0.7"/>
            <rect x="0.3" y="1.8" class="st4" width="0.7" height="0.6"/>
            <rect x="0.2" y="1.5" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="1.4" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="1.3" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="1.1" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="1" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.8" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.7" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.6" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.4" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.3" class="st2" width="0.8" height="0.1"/>
            <rect x="0.2" y="0.2" class="st2" width="0.8" height="0.1"/>
            <g>
                <rect x="1" y="0.2" class="st2" width="0.1" height="1.6"/>
                <rect x="0.1" y="0.2" class="st2" width="0.1" height="1.6"/>
            </g>
            <g>
                <rect x="1.1" y="1.1" class="st5" width="0.1" height="0.6"/>
                <rect y="1.1" class="st5" width="0.1" height="0.6"/>
            </g>
            <g>
                <rect x="1.1" y="1" class="st1" width="0.1" height="0.1"/>
                <rect y="1" class="st1" width="0.1" height="0.1"/>
            </g>
        </g>
        <path class="st0" d="M3.8,1.1L3.8,1.1l0.1,0V1H3.8L3.7,0.2h0H2.8H2.7V1H2.6v0.1h0.1v0H2.6v0.6h0.1v0.7h1V1.7h0.1V1.1H3.8z M3.7,0.3
	H2.8v0L3.7,0.3L3.7,0.3z M3.7,0.4H2.8v0L3.7,0.4L3.7,0.4z M3.7,0.5H2.8v0L3.7,0.5L3.7,0.5z M3.7,0.7H2.8v0L3.7,0.7L3.7,0.7z
	 M3.7,0.8H2.8v0L3.7,0.8L3.7,0.8z M3.7,0.9H2.8v0L3.7,0.9L3.7,0.9z M3.7,1.1H2.8v0L3.7,1.1L3.7,1.1z M3.7,1.2H2.8v0L3.7,1.2L3.7,1.2
	z M3.7,1.3H2.8v0L3.7,1.3L3.7,1.3z M3.7,1.5H2.8v0L3.7,1.5L3.7,1.5z M3.7,1.6H2.8v0L3.7,1.6L3.7,1.6z"/>
        <g>
            <rect x="2.7" y="1.8" class="st1" width="1" height="0.8"/>
            <rect x="2.8" y="1.7" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="1.8" class="st1" width="0.8" height="0.8"/>
            <rect x="2.8" y="1.8" class="st3" width="0.8" height="0.7"/>
            <rect x="2.8" y="1.8" class="st4" width="0.7" height="0.6"/>
            <rect x="2.8" y="1.5" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="1.4" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="1.3" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="1.1" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="1" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.8" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.7" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.6" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.4" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.3" class="st2" width="0.8" height="0.1"/>
            <rect x="2.8" y="0.2" class="st2" width="0.8" height="0.1"/>
            <g>
                <rect x="3.6" y="0.2" class="st2" width="0.1" height="1.6"/>
                <rect x="2.7" y="0.2" class="st2" width="0.1" height="1.6"/>
            </g>
            <g>
                <rect x="3.7" y="1.1" class="st5" width="0.1" height="0.6"/>
                <rect x="2.5" y="1.1" class="st5" width="0.1" height="0.6"/>
            </g>
            <g>
                <rect x="3.7" y="1" class="st1" width="0.1" height="0.1"/>
                <rect x="2.5" y="1" class="st1" width="0.1" height="0.1"/>
            </g>
        </g>
        <polygon class="st0" points="2.7,3.1 2.7,3.1 1.5,3.1 1.5,3.1 0.7,2.3 0.7,1.1 1.5,0.3 1.5,0.3 2.7,0.3 3.5,1.1 3.5,2.3 "/>
        <g>
            <polygon :class="{'st6': value.is_available == 1, 'st6-1': value.is_available == 0, 'st6-2': value.reserved == true}" points="2.5,0 3.3,0.8 1.9,1.4 	"/>
            <polygon :class="{'st7': value.is_available == 1, 'st7-1': value.is_available == 0, 'st7-2': value.reserved == true}" points="3.3,0.8 3.3,0.8 3.3,2 3.3,2 1.9,1.4 	"/>
            <polygon :class="{'st8': value.is_available == 1, 'st8-1': value.is_available == 0, 'st8-2': value.reserved == true}" points="3.3,2 2.5,2.8 1.9,1.4 	"/>
            <polygon :class="{'st7': value.is_available == 1, 'st7-1': value.is_available == 0, 'st7-2': value.reserved == true}" points="1.3,0 2.5,0 2.5,0 1.9,1.4 	"/>
            <polygon :class="{'st9': value.is_available == 1, 'st9-1': value.is_available == 0, 'st9-2': value.reserved == true}" points="2.5,2.8 2.5,2.8 1.3,2.8 1.9,1.4 	"/>
            <polygon :class="{'st8': value.is_available == 1, 'st8-1': value.is_available == 0, 'st8-2': value.reserved == true}" points="1.3,0 1.9,1.4 0.5,0.8 1.3,0 	"/>
            <polygon :class="{'st9': value.is_available == 1, 'st9-1': value.is_available == 0, 'st9-2': value.reserved == true}" points="0.5,2 0.5,2 0.5,0.8 0.5,0.8 1.9,1.4 	"/>
            <polygon :class="{'st10': value.is_available == 1, 'st10-1': value.is_available == 0, 'st10-2': value.reserved == true}" points="1.3,2.8 1.3,2.8 0.5,2 1.9,1.4 	"/>
            <g>
                <polygon class="st11" points="2.1,1.9 1.7,1.9 1.8,1.8 2.1,1.8 		"/>
                <polygon class="st11" points="1.6,2.3 1.6,2.1 2.2,2.1 2.3,2.3 		"/>
                <polygon class="st11" points="1.9,1.6 1.9,1.4 2,1.6 		"/>
                <polygon class="st11" points="1.4,2.7 1.5,2.5 2.4,2.5 2.4,2.7 		"/>
            </g>
            <polygon class="st12" points="2.1,0.9 1.7,0.9 1.8,1.1 2.1,1.1 	"/>
            <polygon class="st13" points="1.6,0.5 1.6,0.7 2.2,0.7 2.3,0.5 	"/>
            <polygon class="st12" points="1.9,1.2 1.9,1.4 2,1.2 	"/>
            <polygon class="st13" points="1.4,0.2 1.5,0.3 2.4,0.3 2.4,0.2 	"/>
            <g>
                <polygon class="st14" points="1.7,1.9 1.4,1.6 1.6,1.6 1.8,1.8 		"/>
                <polygon class="st14" points="1,1.8 1.2,1.7 1.6,2.1 1.6,2.3 		"/>
                <polygon class="st14" points="1.8,1.5 1.9,1.4 1.9,1.6 		"/>
                <polygon class="st14" points="0.7,1.9 0.9,1.9 1.5,2.5 1.4,2.7 		"/>
            </g>
            <polygon class="st15" points="2.5,1.2 2.1,0.9 2.1,1.1 2.3,1.3 	"/>
            <polygon class="st3" points="2.3,0.5 2.2,0.7 2.6,1.1 2.8,1 	"/>
            <polygon class="st15" points="2,1.2 1.9,1.4 2.1,1.3 	"/>
            <polygon class="st3" points="2.4,0.2 2.4,0.3 3,1 3.2,0.9 	"/>
            <polygon class="st16" points="1.4,1.6 1.4,1.2 1.6,1.3 1.6,1.6 	"/>
            <polygon class="st11" points="1,1 1.2,1.1 1.2,1.7 1,1.8 	"/>
            <polygon class="st16" points="1.8,1.3 1.9,1.4 1.8,1.5 	"/>
            <polygon class="st11" points="0.7,0.9 0.9,1 0.9,1.9 0.7,1.9 	"/>
            <polygon class="st12" points="2.5,1.6 2.5,1.2 2.3,1.3 2.3,1.6 	"/>
            <polygon class="st13" points="2.8,1 2.6,1.1 2.6,1.7 2.8,1.8 	"/>
            <polygon class="st12" points="2.1,1.3 1.9,1.4 2.1,1.5 	"/>
            <polygon class="st13" points="3.2,0.9 3,1 3,1.9 3.2,1.9 	"/>
            <g>
                <polygon class="st17" points="1.4,1.2 1.7,0.9 1.8,1.1 1.6,1.3 		"/>
                <polygon class="st17" points="1.6,0.5 1.6,0.7 1.2,1.1 1,1 		"/>
                <polygon class="st17" points="1.9,1.2 1.9,1.4 1.8,1.3 		"/>
                <polygon class="st17" points="1.4,0.2 1.5,0.3 0.9,1 0.7,0.9 		"/>
            </g>
            <polygon class="st18" points="2.1,1.9 2.5,1.6 2.3,1.6 2.1,1.8 	"/>
            <polygon class="st17" points="2.8,1.8 2.6,1.7 2.2,2.1 2.3,2.3 	"/>
            <polygon class="st18" points="2.1,1.5 1.9,1.4 2,1.6 	"/>
            <polygon class="st17" points="3.2,1.9 3,1.9 2.4,2.5 2.4,2.7 	"/>
            <g>
                <polygon :class="{'st10': value.is_available == 1, 'st10-1': value.is_available == 0, 'st10-2': value.reserved == true}" points="1.6,2.1 1.2,1.7 1.9,1.4 		"/>
                <polygon :class="{'st9': value.is_available == 1, 'st9-1': value.is_available == 0, 'st9-2': value.reserved == true}" points="1.2,1.7 1.2,1.7 1.2,1.1 1.2,1.1 1.9,1.4 		"/>
                <polygon :class="{'st8': value.is_available == 1, 'st8-1': value.is_available == 0, 'st8-2': value.reserved == true}" points="1.2,1.1 1.6,0.7 1.9,1.4 		"/>
                <polygon :class="{'st9': value.is_available == 1, 'st9-1': value.is_available == 0, 'st9-2': value.reserved == true}" points="2.2,2.1 1.6,2.1 1.6,2.1 1.9,1.4 		"/>
                <polygon :class="{'st7': value.is_available == 1, 'st7-1': value.is_available == 0, 'st7-2': value.reserved == true}" points="1.6,0.7 1.6,0.7 2.2,0.7 1.9,1.4 		"/>
                <polygon :class="{'st8': value.is_available == 1, 'st8-1': value.is_available == 0, 'st8-2': value.reserved == true}" points="2.2,2.1 1.9,1.4 2.6,1.7 2.2,2.1 		"/>
                <polygon :class="{'st7': value.is_available == 1, 'st7-1': value.is_available == 0, 'st7-2': value.reserved == true}" points="2.6,1.1 2.6,1.1 2.6,1.7 2.6,1.7 1.9,1.4 		"/>
                <polygon :class="{'st6': value.is_available == 1, 'st6-1': value.is_available == 0, 'st6-2': value.reserved == true}" points="2.2,0.7 2.2,0.7 2.6,1.1 1.9,1.4 		"/>
            </g>
        </g>
    </g>

</template>

<script>
    export default {
        name: "BeachUmbrella",
        data() {
            return {
                x: 0,
                y: 0,
            }
        },
        props: {
            value: {
                required: true
            }
        },
        created() {
            this.y += (75 * this.value.position_row) - 75
            this.x = this.value.position_column * 100 - 50
        }
    }
</script>

<style scoped>
    g {
        cursor: pointer;
    }

    .st0{fill:#BCBEC0;}
    .st1{fill:#BD8751;}
    .st2{fill:#E6AF75;}
    .st3{fill:#CED0D1;}
    .st4{fill:#D7D9DB;}
    .st5{fill:#EDB172;}
    .st6{fill:#149ACC;}
    .st6-1{fill: #cc1414;}
    .st6-2{fill: #cc9614;}
    .st7{fill:#16A4D9;}
    .st7-1{fill: #d91616;}
    .st7-2{fill: #d99616;}
    .st8{fill:#43BAE6;}
    .st8-1{fill: #e64343;}
    .st8-2{fill: #e69643;}
    .st9{fill:#46C3F2;}
    .st9-1{fill: #f24646;}
    .st9-2{fill: #f29646;}
    .st10{fill:#70D7FF;}
    .st10-1{fill: #ff7070;}
    .st10-2{fill: #ff9670;}
    .st11{fill:#F5F5F5;}
    .st12{fill:#E0E2E3;}
    .st13{fill:#D8DADB;}
    .st14{fill:#FFFFFF;}
    .st15{fill:#DBDDDE;}
    .st16{fill:#FAFAFA;}
    .st17{fill:#E6E6E6;}
    .st18{fill:#EBEBEB;}
</style>
