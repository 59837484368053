<template>
  <div class="py-3">
    <h3 class="mb-3">
      {{ translate('Recent {entity}', 'entities', { entity: entityNameTranslated }) }}
    </h3>
    <data-table
      :entity-type="entityType"
      :has-filters="false"
      :dt-filter-props="{ perpage: 5 }"
    />
  </div>
</template>

<script>
import DataTable from "@/modules/erp_entities/components/page/table/DataTable.vue"

import pluralize from 'pluralize'

export default {
  name: "RoleSetting",
  components: { DataTable },
  props: {
    entity: {
      type: String,
      required: true,
    },
  },
  computed: {
    entityType() {
      return pluralize(this.entity).toKebabCase()
    },
    entityNameTranslated() {
      return this.translate(pluralize(this.entity), 'entities')
    },
  },
}
</script>