import Agenda from "@/modules/project_management/views/Agenda";
import Board from "@/modules/project_management/views/projectManagement/Board";
import ProjectShowView from "@/modules/project_management/views/project/Show";
import TasksShowView from "@/modules/project_management/views/tasks/Show";

export default [
    {
        path: '/agenda',
        name: 'agenda',
        component: Agenda
    },
    
    {
        path: '/projects/:id/board',
        name: 'projects',
        component: Board
    },
    {
        path: '/entity/projects/:id',
        name: 'entity.project.show',
        component: ProjectShowView
    },
    {
        path: '/entity/tasks/:id',
        name: 'entity.tasks.show',
        component: TasksShowView
    }
]