<template>
  <div class="tabbable">

    <ul class="nav nav-tabs">
      <li class="nav-item edit" v-for="(tab, tabI) in tabs" @click="selectTab(tab, tabI)">
        <button :class="{'active' : tab.isActiveTab}" class="nav-link w-100">
          <div class="d-flex position-relative justify-content-center align-items-center tab-edit">
            <input
              :ref="`tab-${tabI}`"
              :value="tab.name"
              :readonly="currentlyEdited !== tabI"
              type="text"
              class="tab-edit-input"
              @focusout="currentlyEdited = false"
              @change="e => editTab(tab.tabKey, e.target.value)"
            />
            <template v-if="tab.isActiveTab">
              <i class="position-absolute fas fa-pen" />
              <i class="position-absolute fas fa-trash-alt" @click="removeTab(tab.tabKey)" />
            </template>
          </div>
        </button>
      </li>

      <li class="nav-item">
        <button class="nav-link" @click="$emit('addTab')">
          <i class="fas fa-plus"></i>
          {{ translate('New tab', 'entities') }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableTabs',
  data() {
    return {
      tabs: [],
      currentlyEdited: null,
    }
  },
  props: {
    value: {
      required: false,
    },
    allTabNames: {
      required: true,
    },
  },
  created() {
    this.tabs = this.$children
  },
  methods: {
    selectTab(selectedTab, tabI) {

      if (selectedTab.isActiveTab) {
        this.currentlyEdited = tabI
        this.$nextTick(() => this.$refs[`tab-${tabI}`][0].focus())
      } else {
        this.currentlyEdited = null
      }
      this.tabs.forEach(tab => {
        tab.isActiveTab = (tab.tabKey == selectedTab.tabKey)
      })
      this.$children.forEach(tab => {
        tab.isActiveTab = (tab.tabKey == selectedTab.tabKey)
      })

    },
    // async removeTab(tab) {
    //   const confirmDelete = await this.$alert.confirmDelete()
    //   if(!confirmDelete.isConfirmed) return
    //
    //   let idx = this.tabs.indexOf(t => t.name === tab)
    //   this.tabs.splice(idx, 1)
    //   this.$emit('removeTab', tab)
    // },

    async removeTab(tabI) {

      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      let idx = this.tabs.indexOf(t => t.tabKey === tabI)
      this.tabs.splice(idx, 1)

      this.$emit('removeTab', tabI)
    },

    editTab(tabKey, value){
      if (!value) {
        return this.$toast.error('Tab name cannot be empty!')
      }  else if (this.allTabNames.includes(value)) {
        return this.$toast.error('Tab with the same name already exists!')
      }

      this.currentlyEdited = null
      this.$emit('editTab', tabKey, value)
    },
  },
}
</script>

<style lang="scss">
.nav-item.edit {
  flex-grow: 1;
  flex-basis: auto;
  max-width: 200px;

  .tab-edit {
    i.fas.fa-pen {
      left: 5px;
    }

    i.fas.fa-trash-alt {
      right: 5px;
    }

    .tab-edit-input {
      background: transparent;
      border: none;
      outline: none;
      cursor: text;
      text-align: center;
      padding: 0;
      margin: 0;

      &[readonly] {
        cursor: pointer;
      }
    }
  }
}
</style>
