<template>
  <div class="data-table">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr v-if="value">
            <th scope="col">
              {{ translate('Roles', 'entities') }}
            </th>
            <th scope="col" class="text-center border-top-0" v-for="permission in permissions">
              <div class="mb-2 ml-2">
                {{ translate(permission.value, 'entities') }}
              </div>
              <div class="custom-control custom-switch">
                <input
                  :id="'select-all-' + permission.key"
                  type="checkbox"
                  :checked="allChecked(permission.key)"
                  class="custom-control-input"
                  @click="selectAll(permission.key)"
                  :true-value="1"
                  :false-value="0"
                >
                <label class="custom-control-label" :for="'select-all-' + permission.key"></label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="role in $store.state.system.roles">
            <th class="border-0" scope="row">{{ translate(role.value, 'roles') }}</th>

            <td class="text-center" v-for="permission in permissions">
              <div class="custom-control custom-switch">
                <input type="checkbox"
                       class="custom-control-input"
                       :id="'permission-' + role.value + '-' + permission.key"
                       @click="adjustPermission(data[role.key].data, permission.key)"
                       :true-value="1" :false-value="0"
                       v-model="data[role.key]['data'][permission.key]">
                <label class="custom-control-label" :for="'permission-' + role.value + '-' + permission.key"></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionsAndRestrictions",
  props: {
    value:{
      required: true,
      type: Array
    },
    permissions: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      data: null
    }
  },
  created() {

    // convert input array to key value
    let data = this.createEntityPermissionsObject(this.$store.state.system.roles)
    if(this.value){
      this.value.forEach(role => {
        if(typeof data[role.role_id] !== 'undefined'){ // skip deleted roles
          // skip deleted/old permissions
          data[role.role_id].data = this.permissions.reduce(function(acc, el) {
            acc[el.key] = role.data[el.key] == true ? 1 : 0;
            return acc
          }, {});
        }
      })
    }

    this.data = data
  },
  methods: {
    createEntityPermissionsObject(roles){
      let defaultPermissions = this.permissions.reduce((acc,el) => (acc[el.key] = 0, acc), {});
      let permissions = {}
      for (const role of roles) {
        permissions[role.key] = {
          // name: role.value, not used anymore
          role_id: role.key,
          data: {...defaultPermissions}
        }
      }
      return permissions
    },

    adjustPermission(perm, type) {

      // only on edit entity field
      // toggle other permissions based on the toggled permission
      if(['r', 'w', 'd'].includes(type)){
        // please note that here perm.d or perm.w holds the old value while console.log(perm) will output the new values

        // on enable perm r
        if(type == 'r' && perm.r == 0) {
          perm.d = 0
        // on disable r
        } else if(type == 'r' && perm.r == 1) {
          perm.w = 0
          perm.d = 1
        }

        // on enable w
        if(type == 'w' && perm.w == 0) {
          perm.r = 1
          perm.d = 0
        // on disable w
        } else if(type == 'w' && perm.w == 1) {
          // nothing to here, read is enable
        }

        // on enable d
        if(type == 'd' && perm.d == 0) {
          perm.w = 0
          perm.r = 0
          // on disable d
        } else if (type == 'd' && perm.d == 1) {
          perm.r = 1
        }
      }
    },
    selectAll(perm) {
      const allChecked = this.allChecked(perm)

      const newValue = allChecked ? 0 : 1

      for (const role of this.$store.state.system.roles) {
        // On disable read, set deny to all
        if (perm === 'r' && allChecked) {
          this.data[role.key].data = { r: newValue, w: newValue, d: 1 }
        }

        // when enable write permission, enable the read permission too
        if (perm === 'w' && !allChecked) {
          this.data[role.key].data['r'] = newValue
        }

        // when change read or write set deny to 0
        if (['r', 'w'].includes(perm) && !allChecked) {
          this.data[role.key].data['d'] = 0
        }

        if (perm === 'd') {
          if (!allChecked) {
            // When enable deny permission set read and write to 0
            this.data[role.key].data['r'] = 0
            this.data[role.key].data['w'] = 0
          } else {
            // When disable deny permission set read to 1
            this.data[role.key].data['r'] = 1
          }
        }


        this.data[role.key].data[perm] = newValue
      }
    },
    // check if all permissions are checked
    allChecked(perm) {
      return this.$store.state.system.roles.every(role => this.data[role.key].data[perm] === 1)
    },
  },
  watch: {
    data: {
      deep: true,
      handler(new_value, old_value){
        // don't emit input on first load
        if(old_value !== null){
          this.$emit('input', Object.values(new_value)) // covert object to to array
        }
      }
    }
  }
}
</script>