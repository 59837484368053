<template>
    <div class="modal-content mb-5" v-if="role">
        <div class="modal-header">
            <h5 class="modal-title" v-if="role.id">{{ translate('Edit {entity}', 'entities', { entity: translate('Role', 'entities') }) }}</h5>
            <h5 class="modal-title" v-else>{{ translate('Create {entity}', 'entities', { entity: translate('Role', 'entities') }) }}</h5>
        </div>
        <div class="modal-body b-b-pr">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-control-label">{{ translate('Name', 'entities') }}</label>
                        <input type="text" v-model="role.value" class="form-control">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-control-label">{{ translate('Slug', 'entities') }}</label>
                        <input type="text" v-model="role.slug" class="form-control" :readonly="role.slug === 'admin'">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-control-label">{{ translate('Level', 'entities') }}</label>
                        <input type="number" v-model="role.level" class="form-control">
                    </div>
                </div>

              <template v-if="$store.state.system.theme === 'rock'">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">{{ translate('Dashboard Entities', 'entities') }}</label>
                  <v-select
                    v-model="roleSettingsFieldsModel"
                    class="select2-form"
                    label="label"
                    :options="moduleOptions"
                    :multiple="true"
                    :reduce="option => option.key"
                  />
                </div>
              </div>
              </template>
            </div>
        </div>
        <div class="modal-footer remove-border">
            <button type="button" class="btn btn-white rounded-pill" @click="$router.push('/roles')">
              {{ translate('Go back', 'global')}}
            </button>
            <button type="submit" @click="saveRole" class="btn btn-primary rounded-pill">
              {{ translate('Save changes', 'global')}}
            </button>
        </div>
        <div v-if="modulesLoaded">
          <rolePerm :role="role" :modules="modules"></rolePerm>
        </div>
        <div v-else class="dfc py-4">
          <div class="_loader">
            <i class="fa fa-spinner fa-spin fa-3x"></i>
          </div>
        </div>
    </div>
</template>

<script>
import {db} from "@/utilities/database";
import RolePermissions from "./RolePermissions";

export default {
    name: "CreateRole",
    components: {
        'rolePerm': RolePermissions
    },
    data() {
        return {
            role: {
              settings: {
                fields: [],
              },
            },
            modules: null,
            moduleOptions: [],
            modulesLoaded: false,
        }
    },
    computed: {
      roleSettingsFieldsModel: {
        get() {
          return this.role.settings?.fields?.map(field => field.data_module) || []
        },
        set(value) {
          const fields = value.map(key => ({ type: 'datatable', data_module: key }))
          this.$set(this.role.settings, 'fields', fields)
        },
      },
    },
    methods: {
        saveRole() {
            let mode = 'create'
            if(this.$route.params.id) {
                mode = 'edit'
            }
            let method = mode === 'edit' ? 'put' : 'post';
            let route = mode === 'edit' ? `/modules/roles/${this.role.id}` : '/modules/roles'

            db[method](route, this.role).then(res => {
                this.$toast.requestSuccess(method, 'Role')
            }).catch(err => {
                this.$toast.errorGeneral()
            })
        },
    },
    created() {
        this.moduleOptions = this.$store.state.system.moduleNames
        if(this.$route.params.id) {
            db.get(`/modules/roles/${this.$route.params.id}`).then(res => {
                this.role = res.data.data
                this.role.settings = res.data.data?.settings
                  ? JSON.parse(res.data.data?.settings)
                  : {}

            })

            this.erp.ext.request.axiosInstance.get('entities', { params: { 'perpage': 9999 } }).then(res => {
              this.modules = res.data.data.reduce((acc, el) => {
                let { model, name, id } = el

                acc[model] = { model, name, id }
                return acc
              }, {})

              this.modulesLoaded = true
            })
        }
    }
}
</script>

<style scoped>

</style>
