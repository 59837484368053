<template>
  <a class="action-btn icon rounded-circle icon-sm icon-shape" :href="to">
    <i class="fas" :class="classIcon"></i>
  </a>
</template>

<script>
export default {
  name: "ActionButtons",
  props: {
    classIcon: {
      required: false,
      type: String,
    },
    to: {
      required: false,
      type: String,
      default: 'javascript:;',
    },
  },
}
</script>

<style scoped lang="scss">
  .icon-shape{
    margin-right: 5px !important;
  }

  .action-btn {
    background: #fff;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background: #000;
      border: 1px solid #000;
      color: #fff;
    }
  }
</style>
