// someone rewrite this package PLEASE
// Im leaving all this here because I dont have time
import Form from "../../utilities/Form"
// import {busEvent} from "../../utilities/eventBus";
import Vue from 'vue'
import { routes } from "../../utilities/database"
import { environment } from '../../utilities/helper'

export default {
  namespaced: true,
  state: {
    entities: [],
    form: new Form({
      id: '',
      name: '',
      model: '',
      default_properties: [],
    }),
    entity: null,
    loading: false,
    pagination: {},
    // page_permissions: {}, todo remove it from the backend and then from this file
    columns: [],
    // New props
    validationRules: [],
  },
  getters: {
    allEntities: state => state.entities,
    registerForm: state => state.form,
    uPagination(state) {return  state.pagination},
    getColumns: state => state.columns,
    // getPagePermissions: state => state.page_permissions,
  },
  actions: {
    async fetchPaginatedEntities({ commit, dispatch, state }, page) {
      environment.store.state.system.isLoading = true

      const response = await environment.request.get(routes.entities.filter, { params: { page } })
      commit('setEntities', response.data)
      environment.store.state.system.isLoading = false
    },

    async filterEntities({ commit }, data) {   // for limit for example
      const response = await environment.request.get(routes.entities.filter, {
        params: data,
      })

      commit('setEntities', response.data)
    },

    async fetchValidationRules({ commit, dispatch, state }) {
      const response = await environment.request.get(routes.entities.getValidationRules)
      commit('setValidationRules', response.data)
    },

  },
  mutations: {
    setEntities: (state, entities) => {
      state.entities = entities.data
      state.columns = entities.columns
      // state.page_permissions = entities.page_permissions

      delete entities.data
      delete entities.columns
      delete entities.page_permissions

      state.pagination = entities
    },
    setValidationRules: (state, rules) => {
      state.validationRules = rules.data
    },
  },
}