<!-- copied from TableRates -->
<template>
    <div>
        <div class="table-responsive">
            <div class="d-flex justify-content-end">
                <a href="javascript:;" class="mr-2" @click="addRow">{{ translate('Add new row', 'settings') }}</a>
                <a href="javascript:;" @click="removeSelected">{{ translate('Remove selected', 'settings') }}</a>
            </div>
            <table class="table align-items-center m-0" v-if="value && value.provisions">
                <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" @input="e => selectAll(e.target.checked)"
                                   class="custom-control-input"
                                   :id="`select_all`">
                            <label class="custom-control-label" :for="`select_all`"></label>
                        </div>
                    </th>
                    <th scope="col">{{ translate('Title', 'settings') }}</th>
                    <th scope="col">{{ translate('Price from', 'settings') }}</th>
                    <th scope="col">{{ translate('Price till', 'settings') }}</th>
                    <th scope="col">{{ translate('Provision on price in %', 'settings') }}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="tr-1" v-for="(provision, provisionI) in value.provisions">
                    <td width="10px">
                        <div class="custom-control custom-checkbox">
                            <input :id="'row-' + provisionI" type="checkbox" class="custom-control-input"
                                   v-model="value.provisions[provisionI].selected">
                            <label class="custom-control-label" :for="'row-' + provisionI"></label>
                        </div>
                    </td>
                    <td>
                        <!-- @keyup="validateFields" not used for now -->
                        <input v-model="provision.name" class="form-control form-control-sm" :class="{'is-invalid': value.errors[provisionI] && value.errors[provisionI].name}" type="text"/>
                    </td>
                    <td>
                        <input v-model.number="provision.price_from" class="form-control form-control-sm" :class="{'is-invalid': value.errors[provisionI] && value.errors[provisionI].price_from}" type="number" min="0" step="0.01"/>
                    </td>
                    <td>
                        <input v-model.number="provision.price_till" class="form-control form-control-sm" :class="{'is-invalid': value.errors[provisionI] && value.errors[provisionI].price_till}" type="number" min="0" step="0.01"/>
                    </td>
                    <td>
                        <input v-model="provision.provision" class="form-control form-control-sm" :class="{'is-invalid': value.errors[provisionI] && value.errors[provisionI].provision}" type="number" min="0" max="100" step="0.01"/>
                    </td>
                    <td class="text-right">
                        <a href="javascript:;" @click="removeDataProp(provisionI)">{{ translate('Remove', 'global') }}</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProvisionRates",
    props: {
        value: {
            required: true
        },
    },
    data() {
        return {
            errors:[]
        }
    },
    methods: {
        /* not used for now
        validateFields(){
            this.$parent.validateFields()
        },*/
        addRow() {
            if (!this.value.provisions) {
                this.$set(this.value, 'provisions', [])
            }

            this.value.provisions.push({
                name: '',
                price_from: '',
                price_till: '',
                provision: ''
            })
            this.$forceUpdate()
        },

        async remove(pdi) {
            if(this.value.provisions[pdi].id){
                await this.erp.ext.request.axiosInstance.delete(`modules/provisions/${this.value.provisions[pdi].id}`)
            }
            this.value.provisions.splice(pdi, 1);
        },

        async removeDataProp(pdi) {
            const confirmDelete = await this.$alert.confirmDelete()
            if(!confirmDelete.isConfirmed) return

            this.$store.state.system.isLoading = true
            this.remove(pdi)
            this.$toast.requestSuccess('delete', 'Provision')
            this.$store.state.system.isLoading = false
        },
        selectAll(checked) {
            if (this.value && this.value.provisions) {
                this.value.provisions.forEach(provision => {
                   if (checked) {
                       this.$set(provision, 'selected', true)
                   } else {
                       this.$set(provision, 'selected', false)
                   }
                })
            }
        },
        async removeSelected() {
            // without API calls
            // this.value.provisions = this.value.provisions.filter(item => !item.selected)
            let selected = this.value.provisions.filter(item => item.selected);
            if(selected.length){
                const confirmDelete = await this.$alert.confirmDelete()
                if(!confirmDelete.isConfirmed) return

                this.value.provisions.forEach((provision, index) => {
                    if(provision.selected){
                        this.remove(index);
                    }
                })
                this.$toast.requestSuccess('delete', 'Provision')
            }else{
              this.$toast.info("Please select at least one item first")
            }

        }
    }
}
</script>

<style scoped>

</style>
