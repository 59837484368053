import { presetProductConfigurableAttributes, presetProductVariations } from "@/modules/sitecart/views/products/presetGroups"
import { getWebsiteSettings } from "@/utilities/helpers/websiteSettings"

export default {
  data() {
    return {
      options: {},
      optionsLoaded: false,
      attributeSetGroups: null,
      multipliedGroups: {}, // list of groups on which the fields are grouped
      visible: {}, // active or not active accordion
    }
  },
  methods: {
    // Set initial visibility of attribute set groups and sub groups
    setInitialVisibility() {
      for (let groupKey in this.attributeSetGroups) {
        // Main groups visible
        this.$set(this.visible, groupKey, true)
        for (let subGroupKey in this.attributeSetGroups[groupKey]) {
          // Main sub-groups not visible
          this.$set(this.visible, groupKey + '_' + subGroupKey, false)
        }
      }
    },
    // todo refactor #1048715466
    visibilityKey(...params){
      return params.join('_')
    },
    toggleVisibility(visibilityKey){
      // check if exists (it's not defined in product variations)
      if(typeof this.visible[visibilityKey] === 'undefined'){
        this.$set(this.visible, visibilityKey, true)
      } else {
        this.visible[visibilityKey] = !this.visible[visibilityKey]
      }
    },

    // get attribute set properties
    async getAttributeSetProperties(attributeSetId) {
      let properties = await this.erp.ext.request.axiosInstance.get(`/modules/attribute-sets/${attributeSetId}/properties`)
      const related_products_settings = await getWebsiteSettings('webshop-settings-cmswebsite/related_products')

      properties.data.data['variations'] = {
        label: { en: 'Variations NEW TODO', nl: 'Variations NEW TODO' },
        fields: [
          presetProductConfigurableAttributes(),
          presetProductVariations(),
        ],
      }

      let groupBy = {}

      Object.entries(properties.data.data).forEach(([groupKey, group]) => {

        // todo make use of group.label

        groupBy[groupKey] = group.fields.reduce((acc, v) => {
          if(v.multiply !== null){
            this.multipliedGroups[v.multiply_group] = true
          }

          if (!acc[v.pivot.group_name]) {
            acc[v.pivot.group_name] = {
              label: group.label,
              fields: [],
            }
          }

          if (typeof this.options[v.name] === 'undefined') {
            this.$set(this.options, v.name, null)
          }

          // hide fields based on settings
          if (v.name === 'related_products' && parseInt(related_products_settings['related_products.enable_related_products']) === 0) {
            v.v_hide = true
          }

          acc[v.pivot.group_name].fields.push(v)
          return acc
        }, {})
      })

      this.attributeSetGroups = groupBy

      this.setInitialVisibility()
    },

    getIndexByFieldName(fields, field_name){
      const attr = fields.filter(field => field.name == field_name)
      if(attr.length){
        return fields.indexOf(attr[0])
      }
      return false
    },
    getGroupAndIndexByFieldName(attributeSetGroups, field_name){
      for (const group_by of Object.keys(attributeSetGroups)) {
        for (const group_name of Object.keys(attributeSetGroups[group_by])) {
          let index = this.getIndexByFieldName(attributeSetGroups[group_by][group_name].fields, field_name)
          if(index !== false){
            return {
              group_by: group_by,
              group_name: group_name,
              index: index,
            }
          }
        }
      }
      return false
    },

    fieldPlaceholder(field) {
      if(field.name === 'meta_title'){
        return this.entityLabel(this.options['name'])
      }
      if(field.name === 'meta_description'){
        return this.htmlentitiesDecode(
          this.stripTags(
            this.entityLabel(this.options['description'])
          )
        )
      }

      return false // use the default placeholder
    },
  },
}