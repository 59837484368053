<template>
  <div class="container">
    <page-title :page-title="translate('Dashboard', 'dashboard')">
      <template v-slot:actions>
        <router-link to="/blockz-order">
          <button class="btn btn-primary rounded-pill">{{ translate('Add new order', 'dashboard') }}</button>
        </router-link>
      </template>
    </page-title>

    <div class="row">
      <div class="col-md-12">
        <div class="chart-info">
          <line-chart :chart-data="data_collection" :options="options"></line-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mt-3">
          <label class="pricing-label font-weight-bold">{{ translate('From', 'dashboard') }}</label>
          <date-picker v-model="start_date" type="date" valueType="format" :input-class="['form-control']"/>
          <!--                  <date-picker @input="getEarnings" v-model="start_date" type="date" valueType="format" :input-class="['form-control']"/>-->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mt-3">
          <label class="pricing-label font-weight-bold">{{ translate('To', 'dashboard') }}</label>
          <date-picker v-model="end_date" type="date" valueType="format" :input-class="['form-control']"/>
          <!--                  <date-picker @input="getEarnings" v-model="end_date" type="date" valueType="format" :input-class="['form-control']"/>-->
        </div>
      </div>
      <div class="col-md-3 info-block">
        <div class="d-flex justify-content-between align-items-center mt-4 p-3"
             style="border: 1px solid; border-radius: 10px">
          <div>
            <span class="block-title">{{ translate('Weekly sales', 'dashboard') }}</span>
            <div class="d-flex">
              <h5 class="block-value">$<span>{{ weekly_sales }}</span></h5>
              <span class="additional-value d-flex align-items-center ml-2 px-1"><span>-3.65</span>$</span>
            </div>
          </div>
          <div class="d-flex">
            <i class="fas fa-dollar-sign"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3 info-block">
        <div class="d-flex justify-content-between align-items-center mt-4 p-3"
             style="border: 1px solid; border-radius: 10px">
          <div>
            <span class="block-title">{{ translate('This weeks orders', 'dashboard') }}</span>
            <h5 class="block-value">{{ weekly_orders }}</h5>
          </div>
          <div class="d-flex">
            <i class="fas fa-suitcase"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3 info-block">
        <div class="d-flex justify-content-between align-items-center mt-4 p-3"
             style="border: 1px solid; border-radius: 10px">
          <div class="w-100">
            <span class="block-title">{{ translate('Disk usage', 'dashboard') }}</span>
            <div class="d-flex align-items-center">
              <h5 class="block-value">84.5%</h5>
              <!--                          <div class="progress progress-striped active ml-2 mr-4">-->
              <!--                              <div class="bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>-->
              <!--                          </div>-->
              <div class="progress ml-2 mr-5">
                <span class="progress-bar" style="width: 84%"></span>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <i class="far fa-clipboard"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3 info-block">
        <div class="d-flex justify-content-between align-items-center mt-4 p-3"
             style="border: 1px solid; border-radius: 10px">
          <div>
            <span class="block-title">{{ translate('Avg. order value', 'dashboard') }}</span>
            <h5 class="block-value">$<span>{{ avg_order_value }}</span></h5>
          </div>
          <div class="d-flex">
            <i class="fas fa-chart-line"></i>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="order-chart mt-4 p-4">
          <div class="d-flex justify-content-between">
            <h5>{{ translate('Orders', 'dashboard') }}</h5>
            <div class="hide-app-store-holder custom-control custom-switch p-2">
              <input type="checkbox" name="affiliate" id="affiliate" class="custom-control-input" value="1">
              <label for="affiliate" class="custom-control-label">{{ translate('Show affiliate', 'dashboard') }}</label>
            </div>
          </div>
          <bar-chart :chart-data="data_order_collection" :options="order_options"></bar-chart>
        </div>
      </div>
      <div class="col-md-4">
        <div class="traffic_chart mt-4 p-4">
          <div class="d-flex justify-content-between mb-3">
            <h5>{{ translate('Traffic Channels', 'dashboard') }}</h5>
            <div>
              <span class="mx-2">{{ translate('All', 'dashboard') }}</span>
              <span>{{ translate('Direct', 'dashboard') }}</span>
            </div>
          </div>
          <doughnut-chart :chart-data="traffic_collection" :options="traffic_options"></doughnut-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/modules/system/components/charts/LineChart"
import BarChart from "@/modules/system/components/charts/BarChart"
import DoughnutChart from "@/modules/system/components/charts/DoughnutChart"
import {db} from '@/utilities/database'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default {
  name: 'BlockzDashboard',
  components: {
    LineChart, BarChart, DoughnutChart, DatePicker
  },
  data() {
    return {
      data_collection: null,
      options: null,
      customers: null,
      earnings: null,

      data_order_collection: null,
      order_options: null,
      orders: null,

      traffic_collection: null,
      traffic_options: null,

      avg_order_value: null,
      weekly_orders: null,
      weekly_sales: null,

      start_date: null,
      end_date: null,
    }
  },
  methods: {
    fillData() {
      this.data_collection = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Customers',
            borderColor: '#ff0055',
            data: this.customers
          },
          {
            label: 'Earnings',
            borderColor: '#dc589a',
            data: this.earnings
          },
          // {
          //     label: 'Disc usage',
          //     borderColor: '#ee6396',
          //     data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          // },
        ]
      }
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'end',
          labels: {
            boxWidth: 0,
            fontSize: 14,
            fontColor: '#fff'
          },
        },
        elements: {
          point: {
            radius: 5
          },
          line: {
            borderWidth: 5
          }
        }
      }

      this.data_order_collection = {
        labels: ['Jan', 'Feb', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Orders',
            borderColor: '#FF0553',
            borderRadius: 5,
            barThickness: 4,
            backgroundColor: 'red',
            data: this.orders
          },
        ]
      }
      this.order_options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        styling: {
          borderWidth: 1
        }
      }

      this.traffic_collection = {
        labels: ['Direct', 'Organic', 'Referral'],
        datasets: [
          {
            label: 'Traffic Channels',
            borderColor: 'rgba(255,255,255, 1)',
            borderWidth: 6,
            data: [1, 1, 1],
            backgroundColor: ['#ff0055', '#dc589a', '#ee6396']
          },
        ]
      }
      this.traffic_options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        legend: {
          position: 'bottom',
          align: 'center',
          labels: {
            boxWidth: 13,
            radius: 5,
            fontSize: 14,
            fontColor: '#ff0055'
          },
        }
      }
    },

    getEarnings() {
      if (this.start_date !== null && this.end_date !== null) {
        db.get(`/modules/reports/statistics?from=${this.start_date}&to=${this.end_date}`).then(res => {
          this.getStatistics(res)
        })
      }
    },

    getStatistics(res) {
      if (res) {
        const customers = {
          'January': 0,
          'February': 0,
          'March': 0,
          'April': 0,
          'May': 0,
          'June': 0,
          'July': 0,
          'August': 0,
          'September': 0,
          'October': 0,
          'November': 0,
          'December': 0
        }
        this.customers = Object.values(Object.assign(customers, res.data.data.customers))

        const earnings = {
          'January': 0,
          'February': 0,
          'March': 0,
          'April': 0,
          'May': 0,
          'June': 0,
          'July': 0,
          'August': 0,
          'September': 0,
          'October': 0,
          'November': 0,
          'December': 0
        }
        this.earnings = Object.values(Object.assign(earnings, res.data.data.earnings))

        const orders = {
          'January': 0,
          'February': 0,
          'March': 0,
          'April': 0,
          'May': 0,
          'June': 0,
          'July': 0,
          'August': 0,
          'September': 0,
          'October': 0,
          'November': 0,
          'December': 0
        }
        this.orders = Object.values(Object.assign(orders, res.data.data.orders))

        this.weekly_sales = res.data.data.weekly_sales
        this.weekly_orders = res.data.data.weekly_orders
        this.avg_order_value = parseFloat(res.data.data.avg_order_value).toFixed(2)
        this.fillData()
      } else {
        return
      }
    }
  },
  mounted() {
    this.fillData()
    let currDate = new Date().getFullYear()
    // db.get(`/modules/reports/statistics?year=${currDate}`).then(res => {
    //     this.getStatistics(res)
    // })
  }
}
</script>

<style scoped lang="scss">
::v-deep .chart-info {
  #line-chart {
    height: 300px !important;
    width: 100% !important;
    background-color: #000;
    padding: 1rem;
  }
}

.info-block {
  .block-title {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(149, 178, 216);
  }

  .block-value {
    font-size: 18px;
    font-weight: bold;
  }

  .additional-value {
    font-size: 10px;
    border-radius: 5px;
    background-color: #ccf7e5;
    color: #41e29d;

    span {
      font-size: 12px;
    }
  }

  .fas, .fa, .far {
    font-size: 20px;
    color: rgb(149, 178, 216);
  }

  .progress {
    width: 100%;
    max-width: 260px;
    height: 5px;
    background: #e1e4e8;
    border-radius: 10px;
    overflow: hidden;

    .progress-bar {
      display: block;
      height: 100%;
      background: $primary;
      background-size: 300% 100%;
      animation: progress-animation 2s linear infinite;
    }
  }
}

.order-chart, .traffic_chart {
  border: 1px solid;
  border-radius: 10px;
}
</style>
