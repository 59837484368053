<template>
    <div>
        <page-title
        	:page-title="translate(value.type? 'Create courier' : 'Choose shipping type', 'shipping')"
          buttons="/webshop-settings/shipping/list"
		>
            <template v-slot:actions>
            	<button
                    v-if="value.type"
            		class="btn btn-primary rounded-pill"
                    @click="$emit('save-courier')"
            	>
            		{{ translate('Create', 'global') }}
            	</button>
        	</template>
        </page-title>

        <div
            v-if="!value.type"
            class="row"
        >
            <div
                v-for="(type, i) in types" :key="i"
                class="col-md-4 mb-3"
            >
                <div
                    class="box p-4 text-center"
                    @click="setType(type.type)"
                >
                    <i
                        :class="`icon-erp-${type.icon}`"
                        class="icon-erp-6x mb-4"
                    >
                    </i>
                    <h5 class="font-weight-bold mb-4 mt-4">{{ translate(type.name, 'shipping') }}</h5>
                    <span>{{ translate(type.desc, 'shipping') }}</span>
                </div>
            </div>
        </div>
        <ShippingForm
            v-else
            ref="saveCourier"
            :shippingType="value.type"
            :shippingShipToSingleCountry="value.shipToSingleCountry"
            :initialShipping="shipping"
            :initialDefaultFlatRateShippingPrices="defaultFlatRateShippingPrices"
            :initialDefaultTableRateShippingPrices="defaultTableRateShippingPrices"
            :initialShippingPrices="shippingPrices"
        />
    </div>
</template>
<script>
import ShippingForm from "./ShippingForm";
export default {
    name: 'ShippingCreate',
    components: {
        ShippingForm
    },
    data() {
      return {
        value: {
            type: '',
            shipToSingleCountry: false
        },
        shipping: {
          'shipping_type': null,
          'courier': '',
          'vat_type_id': null,
          'cash_on_delivery': 0,
          'status': 0,

          // only for table rate & local pickup
          'enable_free_shipping': 0,
          'free_shipping_above': "",
          'condition_name': 'package_qty',
          // END only for table rate & local pickup

          // only for local pickup
          'title': '',
          'region': '',
          'city': '',
          'zip': '',
          'address': '',
          'street_number': '',
          'address_additional_information': '',
          // END only for local pickup

          'ship_to_all_countries': 1, // ship to all countries
          'same_pricing': 1, // 1 = same price for all countries/regions, 0 = different prices
        },
        defaultFlatRateShippingPrices: {
          price: '',
          enable_free_shipping: 0,
          free_shipping_above: ''
        },
        defaultTableRateShippingPrices: {
          rates: []
        },
        shippingPrices: {}
      }
    },
    computed: {
      types(){
        return [
          {
            name: 'Simple shipping',
            desc: 'Setup that shipping cost per country. This shipping cost applies for the entire webshop',
            icon: 'simple-shipping',
            type: 'flat_rate'
          },
          {
            name: 'Advanced shipping',
            desc: 'Advanced shipping costs based on quantity, weight or other Specific attributes',
            icon: 'advanced-shipping',
            type: 'table_rates'
          },
          {
            name: 'Local Pickup',
            desc: 'Add a local pickup point where customer can pick up their order',
            icon: 'local-pickup-shipping', // todo change the icon
            // icon: 'local-pickup',
            type: 'local_pickup'
          }
        ];
      }
    },
    methods: {
        setType(type) {
            this.value.shipToSingleCountry = type === 'local_pickup' ? true : false
            this.value.type = type;
        }
    },
}
</script>

<style lang="scss">
    .box {
        background-color: #fff;
        color: $body-color;
        &:hover {
          cursor: pointer;
        }
    }
</style>