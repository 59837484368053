// Applied to all tabs, accordions, social media icons, gallery
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import DraggableMixin from "@/mixins/DraggableMixin";

export default {
  mixins: [
    ComponentSettingsMixin,
    DraggableMixin,
  ],
  data() {
    return {
      items: [],
      visible: {}, // active or not active accordion
    }
  },
  methods: {
    toggleVisibility(visibilityKey){
      // check if exists (it's not defined in product variations)
      if(typeof this.visible[visibilityKey] === 'undefined'){
        this.$set(this.visible, visibilityKey, true)
      } else {
        this.visible[visibilityKey] = !this.visible[visibilityKey]
      }
    },
    dragEnd(){
      this.drag = false
      this.items.forEach((item,i) => {
        item.position = i
      })
    },
    addItem(newItem) {
      this.items.push(newItem);
    },
    removeItem(index){
      this.items.splice(index,1)
      this.items.forEach((item,i) => {
        item.position = i
      })
    },
  },
  async mounted() {
    this.items = this.getSetting('items') || []
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.setSetting('items', this.items)
      },
    },
  },
}