<template>
  <div class="container-fluid">
    <div v-if="previewHTML !== ''" class="app-page-preview" :emulator="deviceType">
      <div id="dom" class="preview-builder-html" :class="deviceType">
        <component is="style" v-if="appStyles">
          {{ appStyles }}
        </component>
        <component is="style" v-if="designOptionsStyles">
          {{ designOptionsStyles }}
        </component>
        <component is="style" v-if="websiteStyles">
          {{ websiteStyles }}
        </component>
        <component is="style" v-if="themeStyles">
          {{ themeStyles }}
        </component>

        <div id="pb-wrapper" v-html="previewHTML" />
<!--        <iframe ref="myIframe" id="pb-wrapper" style="width: 100%; height: 100%; border: none;"></iframe>-->
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <h1>{{ translate('This page is empty', 'page-builder') }} </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StyleSheetsMixin from '../../mixins/StyleSheetsMixin'

export default {
  name: 'PageShow',
  mixins: [StyleSheetsMixin],
  props: {
    value: {
      required: false,
    },
  },
  data() {
    return {
      entity: 'cms-pages',
    }
  },
  computed: {
    ...mapGetters('layout', ['deviceType']),
    ...mapState('builder', ['previewHTML']),
    previewHTML() {
      return this.$store.state.builder.previewHTML
    },
  },

  // watch: {
  //   previewHTML: {
  //     immediate: true, // immediate is required because we are async fetching and modifying the property directly
  //     handler(newPreviewHTML) {
  //       this.$nextTick(() => {
  //         this.$refs.myIframe.contentDocument.write(this.iframeContent);
  //         this.$refs.myIframe.contentDocument.close()
  //       })
  //     },
  //   },
  // },

}
</script>