// Made as mixin, so we can quickly change it on vue 3 migrate

export default {
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}