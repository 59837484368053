
export default {
    cache: {},
    allowedTargets: [
        'columns', 'blocks'
    ],

    
    hasPermissionToCache: function (target) {
        return this.allowedTargets.some(allowedTarget => target.match(allowedTarget))
    },
    
    
    /**
     *  Check if request exists in cache, will be returned 
     *  false if there is no permission 
     * @param {String} target combination of target + query
     * @returns {Boolean}
     */
    has: function (target) {
        const hasTarget = !!this.cache[target]
        if(!this.hasPermissionToCache(target) && hasTarget)
            return false;
        return hasTarget
    },


    store: function (target, value) {
        this.cache[target] = value
    },

    get: function (target) {
        return this.cache[target]
    }

}