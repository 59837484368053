<template>
    <div class="row" v-if="website && info">
        <div class="col-md-8 d-flex flex-column">
            <div class="style-comp mb-4 flex-grow-1 d-flex flex-column">
                <div id="first-container" class="row d-flex justify-content-between p-4">
                    <div class="col-md-4" v-if="website.objectData.logo">
                        <img :src="website.objectData.logo" class="site-img mr-4">
                    </div>
                    <div class="col-md-4 d-flex flex-column">
                        <span class="site-title">{{ website.objectData.name }}</span>
                        <span class="website-published" v-if="websiteIsPublished">
                                {{ translate('Website is published', 'dashboard') }}
                            </span>
                        <span class="website-not-published" v-else>
                                {{ translate('Website not published', 'dashboard') }}
                            </span>
                        <label class="mr-1 mt-3 font-weight-600">{{ translate('Site setup', 'dashboard') }}:</label>
                        <div>
                          <progress-bar
                            :value="steps.complete"
                            :total="steps.total"
                          />
                          <span class="font-weight-600">{{ translate('{completed} of {total} finished', 'dashboard', { completed: 3, total: 10 }) }}</span>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex flex-column ml-auto p-2">
                        <div class="nav-item dropdown dropdown-animate mb-2" data-toggle="hover">
                            <button type="button" class="btn btn-primary rounded-pill w-100">
                                {{ translate('Site settings', 'dashboard') }}
                                <i class="fa fa-chevron-down"></i></button>
                            <div
                                class="dropdown-menu dropdown-menu-md dropdown-menu-right site-settings dropdown-menu-arrow mt-1">
                                <div class="d-flex flex-column px-4 py-2">
                                    <router-link
                                        :to="`/builder/cms-pages/create`"
                                        class="py-1">
                                        {{ translate('Add pages to your website', 'dashboard') }}
                                    </router-link>
                                    <a :href="'//' + $store.state.system.scope.obj.url" target="_blank"
                                       class="py-1">
                                        {{ translate('Show my website', 'dashboard') }}
                                    </a>
                                    <router-link to="/settings/general" class="py-1">
                                        {{ translate('Change website name', 'dashboard') }}
                                    </router-link>
                                    <a class="py-1" href="javascript:;" @click="$modal.show('contact_sales')">
                                        {{ translate('Upgrade or renew plan', 'dashboard') }}
                                    </a>
                                    <a class="py-1" href="javascript:;" @click="$emit('publish')">
                                            <span v-if="websiteIsPublished">
                                                {{ translate('Unpublish website', 'dashboard') }}
                                            </span>
                                        <span v-else>
                                                {{ translate('Publish website', 'dashboard') }}
                                            </span>
                                    </a>
                                    <router-link to="/settings/general" class="py-1">
                                        {{ translate('Website settings', 'dashboard') }}
                                    </router-link>
                                </div>
                                <hr>
                                <div class="p-2">
                                    <router-link to="/websites/identity" class="btn border rounded-pill w-100">
                                        {{ translate('Add new website', 'dashboard') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="button-publish" @click="$emit('publish', true)">
                                <span v-if="websiteIsPublished">
                                    {{ translate('Unpublish website', 'dashboard') }}
                                </span>
                            <span v-else>
                                    {{ translate('Publish website', 'dashboard') }}
                                </span>
                        </button>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="col-md-6 domain-setup-border">
                        <div class="d-flex flex-column align-items-center py-2" v-if="website.objectData.url">
                            <span>{{ translate('Current domain setup', 'dashboard') }}</span>
                            <span class="red-text">{{ website.objectData.url }}</span>
                        </div>
                        <div class="d-flex flex-column align-items-center py-2" v-else>
                            <span>{{ translate('No domain name setup', 'dashboard') }}</span>
                            <router-link to="/website-settings" class="red-text">
                                {{ translate('Add domain name', 'dashboard') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-6 current-plan-border">
                        <div class="d-flex flex-column align-items-center py-2" v-if="trial && trial.valid">
                            <span>
                                {{ translate('Trial period active', 'dashboard') }}
                            </span>
                            <span class="red-text cursor-pointer" @click="$modal.show('contact_sales')">
                                {{ translate('Compare plans', 'dashboard') }}
                            </span>
                        </div>
                        <div class="d-flex flex-column justify-content-center h-100 align-items-center py-2" v-else>
                            <span>{{ translate('Current plan', 'dashboard') }} -
                                <span class="red-text">{{ plan }}</span>
                            </span>
                            <span class="red-text cursor-pointer" v-if="plan !== 'Business'"
                                  @click="$modal.show('contact_sales')">
                                {{ translate('Upgrade to Business', 'dashboard') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-md-flex" v-if="trial">
            <div class="style-comp-upgrade mb-4 p-4">
                <div class="d-flex flex-column">
                    <div v-if="trial.valid">
                        <span class="text-font">
                            {{ translate('You are using the Skitle trial', 'dashboard') }}
                        </span>
                        <div>
                          <progress-bar
                            :value="trial.total - trial.days_left"
                            :total="trial.total"/>
                          <span>{{ translate('{completed} of {total} finished', 'dashboard', { completed: trial.total - trial.days_left, total: trial.total }) }}</span>
                        </div>
                    </div>
                    <div v-else>
                            <span class="text-font" v-if="plan">
                                {{ translate('Plan', 'dashboard') }} {{ plan }}
                            </span>
                        <span class="text-font" v-else>
                                {{ translate('You do not have plan', 'dashboard') }}
                            </span>
                    </div>
                    <div class="text-white m-1">
                        {{ translate('Package upgrade description', 'dashboard') }}
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-4" v-if="trial.valid">
                    <button type="button" @click="$modal.show('contact_sales')" class="button_upg">
                        {{ translate('Upgrade', 'dashboard') }} {{ plan }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainContent",
    props: {
        trial: {
            required: true
        },
        plan: {
            required: true
        },
        website: {
            required: true
        },
        steps: {
            required: true
        },
        info: {
            required: true
        },
        websiteIsPublished: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>
