<template>
  <div class="form-group">
    <div class="row">
      <div class="col-sm-12">
        <label class="label">{{ translate(label, 'page-builder') }}</label>
        <div class="input-group">
          <template>
            <text-editor-inline
              :value="value"
              @input="e => { this.$emit('input', e) }"
              :key="uuid"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
  name: "CraftTextEditor",
  components: {
    TextEditorInline,
  },
  inject: ['editor'],
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      uuid: uuidv4().split('-')[0],
    }
  },
}
</script>

