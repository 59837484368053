<template>
  <div class="cms-block cms-block-social-media-icons">
    <ul :id="socialMediaIconsId" :aria-orientation="getSetting('orientation')" :class="navClasses" class="nav d-flex">
      <li v-for="(item, itemIndex) in getSetting('items')" class="nav-item">
        <a class="nav-link btn d-flex align-items-center justify-content-center" :href="linkHref(item)" :target="linkTarget(item)">
          <i :class="`fa ${item.icon}`"></i><span v-if="getSetting('type') !== 'icon_only' && item.label" class="ml-2">{{ item.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";

export default {
  name: 'SocialMediaIconsElement',
  mixins:[
    CraftSettingsMixin,
  ],
  inject: ['editor', 'node'],
  craft: {
    defaultProps:{
      settings:{
        items: [
          {
            icon: 'fa-brands fa-facebook',
            label: 'Facebook',
            link: '',
            link_target: '_blank',
            position: 1
          },
          {
            icon: 'fa-brands fa-instagram',
            label: 'Instagram',
            link: '',
            link_target: '_blank',
            position: 2
          },
          {
            icon: 'fa-brands fa-linkedin',
            label: 'LinkedIn',
            link: '',
            link_target: '_blank',
            position: 3
          }
        ],

        // Styling
        orientation: 'horizontal',
        type: 'rounded',
        border_width: '',
        border_color: '',
        background_color: '',
        icon_color: '',
        icon_width: '16px',
        padding: '8px', // 0.5 rem
        gap: '0px'
      },
    }
  },

  computed: {
    socialMediaIconsId() {
      return 'social-media-icons-' + this.node.uuid
    },

    navClasses(){
      let classes = []
      if(this.getSetting('orientation') === 'vertical'){
        classes.push('flex-column')
      } else {
        classes.push('flex-row')
      }
      return classes
    },
  },
  methods: {
    linkHref(item){
      return item.link.length ? item.link : null;
    },
    linkTarget(item) {
      return (item.link_target && item.link_target !== '_self') ? item.link_target : null
    },
  },
};
</script>