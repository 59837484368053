<!-- This file is indentical to PortfolioSettings.vue -->
<template>
  	<div class="container">
		<page-title :page-title="translate('Provision settings', 'settings')"></page-title>

		<div class="row">
			<div class="col-md-12">
				<div class="list-group border bg-white">
					<div class="card m-0">
						<div class="card-header">
						  <h5>{{ translate('Provision on product prices', 'settings') }}</h5>
						</div>
                        <div class="card-body">
                          <p>Lorem ipsum text</p>
                          <provision-rates v-model="data" ></provision-rates>

                            <div class="d-flex mt-2" v-for="(displayError, key) in displayErrors">
                                <small class="help text-danger" v-if="displayErrors[key]">{{ translate(errorMessages[key], 'settings') }}</small>
                            </div>
                        </div>
                    </div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="d-flex justify-content-end mt-5">
				<button class="btn btn-primary rounded-pill" @click="save">
					<span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
					<span v-else>{{ translate('Save changes', 'global') }}</span>
				</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import ProvisionRates from "@/modules/sitecart/views/settings/provision/ProvisionRates";

export default {
	name: "ProvisionSettings",
  components: {
      ProvisionRates
  },
  props: {
      value: {
        required: true
      },
  },
	data() {
      return {
        data: {
          provisions: [],
          errors: []
        },
        displayErrors: {}
      }
  },
    computed: {
        errorMessages() {
            return {
                required_fields: 'Please fill the required fields',
                provision_percent: 'Provision on price must be between 0 and 100',
                price_from_till: 'Price from cannot be higher than Price till',
                overlapping: 'You cannot use those values because they are already overlapping',
            }
        },
    },
  methods: {
    setInitialDisplayErrors(){
        this.displayErrors = {
            required_fields: false,
            provision_percent: false,
            price_from_till: false,
            overlapping: false,
        }
    },
    validateFields() {
      this.setInitialDisplayErrors();
      this.data.errors = [];
      this.data.errors_count = 0;
      let ranges = [];

      this.data.provisions.forEach((provision, index) => {
        this.data.errors[index] = {};

        // check if the fields are empty
        if(!provision.name || provision.name.replace(/\s/g,'').length === 0){ // trim
          this.data.errors[index].name = true;
          this.data.errors_count++;
          this.displayErrors.required_fields = true;
        }

        if(provision.price_from === null || provision.price_from.length === 0){
          this.data.errors[index].price_from = true;
          this.data.errors_count++;
          this.displayErrors.required_fields = true;
        }
        if(provision.price_till === null ||  provision.price_till.length === 0){
          this.data.errors[index].price_till = true;
          this.data.errors_count++;
          this.displayErrors.required_fields = true;
        }
        if(provision.provision === null || provision.provision.length === 0){
          this.data.errors[index].provision = true;
          this.data.errors_count++;
          this.displayErrors.required_fields = true;
        }else if(provision.provision < 0 || provision.provision>100){
          this.data.errors[index].provision = true;
          this.data.errors_count++;
          this.displayErrors.provision_percent = true;
        }

        // END check if the fields are empty

        // hot fix, todo remove me after the api is fixed to return float but not string
        if(!this.data.errors[index].price_from){
            provision.price_from = parseFloat(provision.price_from)
        }
        if(!this.data.errors[index].price_till){
            provision.price_till = parseFloat(provision.price_till)
        }
        // END hot fix

        // check ranges if the fields are good so far
        if(!this.data.errors[index].price_from && !this.data.errors[index].price_till){

            // check if Price from	<= Price till
            if(provision.price_from > provision.price_till){
              this.data.errors[index].price_from = true;
              this.data.errors[index].price_till = true;
              this.data.errors_count++;
              this.displayErrors.price_from_till = true;
            } else{
              // else check if the range is overlapping
              if(ranges.length){

                ranges.forEach(range => {
                  let error_range = false

                  if(provision.price_from >= range.price_from && provision.price_from <= range.price_till){
                    error_range = true;
                    this.data.errors[index].price_from = true;
                    this.data.errors_count++;
                    this.displayErrors.overlapping = true;
                  }

                  if(provision.price_till >= range.price_from && provision.price_till <= range.price_till){
                    error_range = true;
                    this.data.errors[index].price_till = true;
                    this.data.errors_count++;
                    this.displayErrors.overlaping = true;
                  }

                  if(provision.price_from <= range.price_from && provision.price_till >= range.price_till){
                    error_range = true;
                    this.data.errors[index].price_from = true;
                    this.data.errors[index].price_till = true;
                    this.data.errors_count++;
                  }

                  // no need to check other ranges if there are errors
                  if(error_range){
                    return
                  }

                })
              }

              ranges.push({
                price_from: provision.price_from,
                price_till: provision.price_till
              })
            }
        }

      });
    },
    save() {
        this.validateFields();
        if(this.data.errors_count > 0){
          return
        }

        this.$store.state.system.isLoading = true

        // todo single call
        let success = true;
        this.data.provisions.forEach(provision => {
            if(provision.id){
              this.erp.ext.request.axiosInstance.put(`/modules/provisions/${provision.id}`, provision).then(res => {

              }).catch(e => {
                  success = false;
              })
            }else{
              this.erp.ext.request.axiosInstance.post(`/modules/provisions`, provision).then(res => {
                provision.id = res.data.data.id;
              }).catch(e => {
                  success = false;
              })
            }
        });

        if(success){
          this.$toast.requestSuccess(false, 'Provision')
          this.$forceUpdate()
        }else{
          this.$toast.error('Provision not saved successfully!')
        }
        this.$store.state.system.isLoading = false
    },
  },

  async created() {
      this.$store.state.system.isLoading = true
      this.setInitialDisplayErrors();
      let provisions = await this.erp.ext.request.axiosInstance.get(`/modules/provisions`);
      this.data.provisions = provisions.data.data
      this.$store.state.system.isLoading = false
  }
}

</script>
