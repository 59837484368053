<template>
  <div v-if="data" class="font-settings-container">
    <div class="card font-settings">
      <div class="card-body inner-cards-as-accordion">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Letter type', 'entities') }}</label>
              <v-select :options="fonts" label="family" class="select2-form input-group-sm w-100"
                        :placeholder="translate('Letter type', 'entities')"
                        v-model="data.letterType" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Back up letter type', 'entities') }}</label>
              <v-select :options="fonts" label="family" class="select2-form input-group-sm w-100"
                        :placeholder="translate('Back up letter type', 'entities')"
                        v-model="data.backUpLetterType" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Font weight', 'entities') }}</label>
              <v-select
                  :options="['100', '200', '300', '400', '500', '600', '700', '800', '900']"
                  :placeholder="translate('Font weight', 'entities')"
                  class="select2-form input-group-sm w-100" v-model="data.fontWeight" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Font style', 'entities') }}</label>
              <v-select
                  :options="['normal', 'italic']"
                  :placeholder="translate('Font style', 'entities')"
                  class="select2-form input-group-sm w-100" v-model="data.fontStyle" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Text alignment', 'entities') }}</label>
              <v-select :options="['left', 'center', 'right']" class="select2-form input-group-sm w-100"
                        :placeholder="translate('Text alignment', 'entities')"
                        v-model="data.textAlign" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Font size', 'entities') }}</label>
              <div class="input-group">
                <input type="number" v-model="data.fontSize"
                       :placeholder="translate('Font size', 'entities')" class="form-control" :readonly="isReadonly">
                <div class="input-group-append">
                  <span class="input-group-text">{{ suffix }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Line height', 'entities') }}</label>
              <div class="input-group">
                <input type="number" v-model="data.lineHeight"
                       :placeholder="translate('Line height', 'entities')" class="form-control" :readonly="isReadonly">
                <div class="input-group-append">
                  <span class="input-group-text">{{ suffix }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Spacing characters', 'entities') }}</label>
              <div class="input-group">
                <input type="number" v-model="data.spacingCharacters"
                       :placeholder="translate('Spacing characters', 'entities')" class="form-control" :readonly="isReadonly">
                <div class="input-group-append">
                  <span class="input-group-text">{{ suffix }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Text transform', 'entities') }}</label>
              <v-select :options="['capitalize', 'inherit', 'lowercase', 'uppercase', 'unset']"
                        :placeholder="translate('Text transform', 'entities')"
                        class="select2-form input-group-sm w-100" v-model="data.textTransform" :disabled="isDisabled"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-control-label">{{ translate('Text color', 'entities') }}</label>
              <ee-form-color-picker
                  :value="data.textColor"
                  :is-readonly="isReadonly"
                  @input="color => $set(data, 'textColor', color)"
              />
            </div>
          </div>

          <!-- Tablet Landscape -->
          <div
            class="col-12 card border-top mt-2"
            :class="{ active: visible['tablet_landscape'] }">

            <div class="card-header d-flex justify-content-between" @click="visible['tablet_landscape'] = !visible['tablet_landscape']">
              <h6>{{ translate('Tablet Landscape', 'entities') }}</h6>
              <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['tablet_landscape'] }"></i>
            </div>

            <transition-expand>
              <div v-show="visible['tablet_landscape']" class="card-body">
                <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font size', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.tabletLandscapeFontSize"
                             :placeholder="translate('Font size', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Line height', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.tabletLandscapeLineHeight"
                             :placeholder="translate('Line height', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </transition-expand>
          </div>

          <!-- Tablet Portrait -->
          <div
            class="col-12 card"
            :class="{ active: visible['tablet_portrait'] }">

            <div class="card-header d-flex justify-content-between" @click="visible['tablet_portrait'] = !visible['tablet_portrait']">
              <h6>{{ translate('Tablet Portrait', 'entities') }}</h6>
              <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['tablet_portrait'] }"></i>
            </div>

            <transition-expand>
              <div v-show="visible['tablet_portrait']" class="card-body">
                <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font size', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.tabletPortraitFontSize"
                             :placeholder="translate('Font size', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Line height', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.tabletPortraitLineHeight"
                             :placeholder="translate('Line height', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </transition-expand>
          </div>

          <!-- Mobile (Landscape & Portrait) -->
          <div
            class="col-12 card"
            :class="{ active: visible['mobile'] }">

            <div class="card-header d-flex justify-content-between" @click="visible['mobile'] = !visible['mobile']">
              <h6>{{ translate('Mobile (Landscape & Portrait)', 'entities') }}</h6>
              <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['mobile'] }"></i>
            </div>

            <transition-expand>
              <div v-show="visible['mobile']" class="card-body">
                <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font size', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.mobileFontSize"
                             :placeholder="translate('Font size', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-control-label">{{ translate('Line height', 'entities') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.mobileLineHeight"
                             :placeholder="translate('Line height', 'entities')" class="form-control" :readonly="isReadonly">
                      <div class="input-group-append">
                        <span class="input-group-text">{{ suffix }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </transition-expand>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import googleFonts from "@/assets/misc/googleFonts";
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker";
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "FontAndMediaSettingsField",
  mixins: [FormFieldMixin],
  components: {
    EeFormColorPicker,
  },
  data() {
    return {
      visible: {
        desktop: false,
        tablet_landscape: false,
        tablet_portrait: false,
        mobile: false,
      },
      data: {
        letterType: '',
        backUpLetterType: '',
        fontWeightStyle: '',
        subsetLetterType: '',
        textAlign: '',
        fontSize: '',
        lineHeight: '',
        spacingCharacters: '',
        textTransform: '',
        textColor: '',
        // Tablet Landscape
        tabletLandscapeFontSize: '',
        tabletLandscapeLineHeight: '',
        // Tablet Portrait
        tabletPortraitFontSize: '',
        tabletPortraitLineHeight: '',
        // Mobile
        mobileFontSize: '',
        mobileLineHeight: '',
      },
      suffix: 'PX',
    }
  },
  created() {
    if (this.value && typeof this.value == 'string') {
      this.data = JSON.parse(this.value)
    }

  },
  computed: {
    fonts() {
      return googleFonts[0].items
    },
    isDisabled() {
      return !this.canWrite
    },
  },
  watch: {
    data: {
      deep: true,
      handler(){
        this.$emit('input', JSON.stringify(this.data))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-select .vs__dropdown-toggle {
  height: 40px;
}
</style>