import { erp , ErpModel } from '@/modules/erp_framework'
import failSafeHandlers from '../utilities/crud/failSafeHandlers'
import debounce from "debounce"

import { SweetAlert, SweetToast } from "@/utilities/sweetalert"

import { checkIfUrlExists, getEntityModel, slug } from "@/utilities/helper"
import router from '@/router'

export default {
  namespaced: true,
  state: {
    // initial load
    website: null,
    blocks: null,
    // depending on url
    originalState: null,
    entity: {
      objectData: {},
    },
    entities: null,
    history: null,
    autoSave: false,
    showLoader: true,
    previewHTML: '',
    categories: [],
    templateCategories: [],
  },
  mutations: {
    SET_WEBSITE: (state, website) => {
      state.website = website
    },
    SET_BLOCKS: (state, blocks) => {
      state.blocks = blocks
    },
    SET_ENTITY: (state, entity) => {
      if(entity.objectData.categories && typeof entity.objectData.categories == 'string') {
        entity.objectData.categories = JSON.parse(entity.objectData.categories)
      }
      // hot fix old slugs with '_'. erp.ext.query filter by slug does not work if the slug is with '_' in the options
      if(entity.objectData.slug){
        entity.objectData.slug = entity.objectData.slug.replace('_', '-')
      }

      if(typeof entity.objectData.advanced_builder !== 'undefined'){
        entity.objectData.advanced_builder = parseInt(entity.objectData.advanced_builder)
      }

      state.originalState = { ...entity.objectData }

      state.entity = entity
      state.showLoader = false
    },
    SET_ENTITIES: (state, entities) => {
      state.entities = entities
    },
    SET_CATEGORIES: (state, categories) => {
      state.categories = categories
    },
    SET_TEMPLATE_CATEGORIES: (state, categories) => {
      state.templateCategories = categories
    },
    SHOW_GLOBAL_LOADING: state => {
      state.showLoader = true
    },
    HIDE_GLOBAL_LOADING: state => {
      state.showLoader = false
    },
  },
  actions: {
    getEntities: async ({ state }, payload) => {
      let query = erp.ext.query()
        .set('perpage', 9999)
        .set('page', payload.page || 1)

      if(payload.locale){
        query.where('locale', '=', payload.locale)
      }
      if (payload.name){
        query.where('name', 'like', `%${payload.name}%`)
      }
      if(payload.user_id){
        query.where('user_id', '=', payload.user_id)
      }

      return await erp.ext.request.get(
        `modules/${payload.entity}`, query.toString(),
      )
    },
    getEntityCategories: async ({ state }, entity) => {
      return await erp.ext.request.axiosInstance.get(`/modules/treeselect/categories/${entity}`)
    },
    showGlobalLoader({ commit })  {
      commit('SHOW_GLOBAL_LOADING')
    },
    hideGlobalLoader({ commit })  {
      commit('HIDE_GLOBAL_LOADING')
    },
    fetchWebsite: async ({ commit }, id) => {
      const website = await erp.ext.request.find('cms-websites', id)
      commit('SET_WEBSITE', website)
    },
    fetchBlocks: async ({ commit }) => {
      let filterQuery = erp.ext.query()
      filterQuery.set('perpage', 999999)
      filterQuery.set('sort', [{ column: 'position', direction: 'asc' }])

      const blocks = await erp.ext.request.get(`modules/cms-blocks?${filterQuery.toString()}`)
      commit('SET_BLOCKS', blocks)
    },
    fetchEntity: async ({ commit, state }, { entity, id }) => {
      const entityData = await erp.ext.request.find(entity, id)
      commit('SET_ENTITY', entityData)
    },
    fetchEntityCategories: async ({ commit, state, dispatch }, { entity }) => {
      const { data } = await dispatch('getEntityCategories', entity)
      // let query = erp.ext.query().where('type', '=', 'categories').where('entity', '=', entity, true)
      // const categories = await erp.ext.request.get(`/modules/categories`, query.toString())

      commit('SET_CATEGORIES', data.data)
    },
    fetchTemplateCategories: async({ commit, dispatch }) => {
      const { data } = await dispatch('getEntityCategories', 'cms-templates')
      commit('SET_TEMPLATE_CATEGORIES', data.data)
    },
    fetchEntities: async ({ commit, state, dispatch }, { entity, page = 1, name = '', locale = null, user_id = null }) => {
      let websiteId = state.website.objectData.id

      if(state.entities) state.entities.objectData = []
      let payload = {
        entity: entity,
        page: page,
        locale: locale,
        name: name,
        user_id: user_id,
      }

      const entities = await dispatch('getEntities', payload)

      commit('SET_ENTITIES', entities)
      failSafeHandlers[entity](state, websiteId, locale)
    },

    async saveWebsite({ state }){
      let websiteData = { ...state.website.objectData }
      delete websiteData.id
      await erp.ext.request.axiosInstance.put('modules/cms-websites/' + state.website.objectData.id, {
        options: websiteData,
      })
    },

    async askForName({ state, dispatch }){
      // Pop alert to fill page name
      const [empty, b, entity, idOrCreate] = router.currentRoute.fullPath.split('/')
      if(!state.entity.objectData.name){
        const name = await SweetAlert.confirmWithInput('Please enter title to continue')

        if(!name.value){
          dispatch('askForName')
          return false
        } else {
          state.entity.objectData.name = name.value

          if (entity === 'cms-pages' && state.entity.objectData.url_key === '') {
            const model = getEntityModel(state.entities.entity_slug)
            const urlKey = slug(name.value, '-')

            const existingUrl = await checkIfUrlExists(urlKey, model)
            if (existingUrl.available) {
              state.entity.objectData.url_key = slug(name.value, '-')
            } else {
              dispatch('askForUrlKey', 'The entered url key has already been used. Please enter new one', urlKey)
              return false
            }
          }
        }
      }
      return true
    },
    async askForUrlKey({ state, dispatch }, message = 'Please enter valid url key to continue', initialUrlKey = null){
      // Pop alert to fill url key
      if(!state.entity.objectData.url_key || initialUrlKey){
        let url_key = await SweetAlert.confirmWithInput(message)

        if(!url_key.isConfirmed || !url_key.value.length || initialUrlKey === url_key){
          if (initialUrlKey === url_key) {
            dispatch('askForUrlKey', 'The entered url key has already been used. Please enter new one', initialUrlKey)
            return false
          }
          dispatch('askForUrlKey')
          return false
        } else {
          const model = getEntityModel(state.entities.entity_slug)
          const existingUrl = await checkIfUrlExists(url_key.value, model)
          if (existingUrl.available) {
            state.entity.objectData.url_key = url_key.value
          } else {
            dispatch('askForUrlKey', 'The entered url key has already been used. Please enter new one', initialUrlKey)
            return false
          }
        }
      }
      return true
    },

    async saveEntity({ state, dispatch, getters }){
      const [empty, b, entity, idOrCreate] = router.currentRoute.fullPath.split('/')
      if(['cms-pages', 'cms-sections', 'cms-templates', 'blogs', 'portfolios', 'stores'].includes(entity)) {
        if (!(await dispatch('askForName'))) return
      }

      if(entity === 'cms-pages') {
        if(!(await dispatch('askForUrlKey'))) return
      }

      const method = state.entity.objectData.id ? 'put' : 'post'
      let savedData = !state.entity.objectData.id
        ? await dispatch('create')
        : await dispatch('update')

      if((savedData.status === 200 || savedData.status === 201) && !savedData.errors){
        SweetToast.requestSuccess(method)
        if(!state.entity.objectData.id){
          router.push(router.currentRoute.fullPath.replace('/create', `/${savedData.data.data.id}`))
        }
      } else {
        SweetAlert.formattedError(savedData)
      }
      return savedData
    },
    async create(context, data){
      context.state.originalState = context.state.entity.objectData

      const [empty, b, entity, idOrCreate] = router.currentRoute.fullPath.split('/')

      let res = {}
      await ErpModel.create(entity, context.state.entity.objectData).then(response => {
        res = response
      })
        .catch(error => {
          res = error
        })

      return res
    },
    async update(context, optionalData = {}){
      let data = { ...context.state.entity.objectData, ...optionalData }
      delete data.id

      let res = {}
      await context.state.entity.model.update(data).then(response => {
        res = response
      })
        .catch(error => {
          res = error
        })

      context.state.originalState = { ...context.state.entity.objectData }

      return res
    },

    async fetchApps({ state }, { appName, params = {} } ) {
      const res = await erp.ext.requestFront.axiosInstance.get(`https://${state.website.data.url}/api/${appName}`,
        { params },
      )

      return Promise.resolve(res.data)
    },
    async fetchStyles({ state }, { styleName } ) {
      // example https://florablom.rc.skitle.co/css/florablom.rc.skitle.co-styles.css
      const res = await erp.ext.requestFront.axiosInstance.get(`https://${state.website.data.url}/css/${state.website.data.url}${styleName}.css`)
      return Promise.resolve(res.data)
    },
    async fetchPreview({ state }, { html, include_header_and_footer }) {
      // example https://florablom.rc.skitle.co/css/florablom.rc.skitle.co-styles.css

      var data = {
        content: html,
        only_main: include_header_and_footer ? 0 : 1,
      }

      const res = await erp.ext.requestFront.axiosInstance.post(`https://${state.website.data.url}/preview-page`, data)
      return Promise.resolve(res.data)
    },
  },
  getters: {
    title: state => state.entity.objectData.name || state.entity.objectData.title || state.entity.objectData.subject,
    unsavedChanges: state => !(JSON.stringify(state.entity.objectData) == JSON.stringify(state.originalState)),
    mainContainerElementId: state => state.blocks.objectData.find(item => item.component === 'MainContainerElement').id,
  },
}