<template>
  <span>
    <div v-if="selectedVariants.length && !loading" class="row">
      <vue-dropzone
        v-for="(variant, idx) in selectedVariants"
        :id="`dropzone-${idx}`"
        :key="idx"
        :ref="`myVueDropzone-${variant}`"
        class="rounded-lg col-md-6"
        :options="dropzoneOptions"
        use-custom-slot
        :include-styling="false"
        @vdropzone-success="(file, response) => vdropzoneSuccess(file, response, variant)"
        @vdropzone-removed-file="file => vdropzoneFileRemoved(file)"
        @vdropzone-mounted="vdropzoneMounted"
      >
        <div class="mt-4">
          <label class="d-block">{{ optionLabel(variantOptions, variant) }}</label>
          <div class="open-file_manager" style="height: 70px; line-height: 1.25rem">
            <div class="col-9 pl-0">
              <p class="m-0" style="font-size: .9rem">{{ translate('Upload custom font file', 'settings') }}</p>
              <span style="font-size: .7rem;">{{ supportedFormats }}</span>
            </div>
            <button class="btn-browse rounded col h-100">
              {{ translate('Upload', 'entities') }}
            </button>
          </div>
        </div>
      </vue-dropzone>
    </div>
    <div v-else-if="!selectedVariants.length && !loading" class="col-12 alert alert-primary">
      {{ translate('Please select at least 1 variant', 'settings') }}
    </div>
  </span>
</template>

<script>
import { environment } from "@/utilities/helper"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import store from "@/store"
import VueDropzone from "vue2-dropzone"

export default {
  name: "FontUploadField",
  components: { VueDropzone },
  mixins: [FormFieldMixin],
  props: {
    dataFilters: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fonts: [],
      loading: false,
      supportedFormats: 'TTF, OTF, WOFF, WOFF2',

      dropzoneOptions: {
        url: `${environment.api_domain}/api/modules/documents`,
        maxFilesize: 10000,
        previewTemplate: this.template(),
        duplicateCheck: true,
        acceptedFiles: '.ttf,.otf,.woff,.woff2',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('authorizationToken')}`,
          user_id: store.state.system.authData.user_id,
          company: store.state.system.company.key,
          website: store.state.system.scope.value,
          package: 'Cms',
        },
      },
    }
  },
  computed: {
    selectedVariants() {
      return this.dataFilters['variants'].value || []
    },
    isDisabled(){
      // we also use "disabled" for read only because v-select does not support readonly
      return !this.canWrite
    },
    variantOptions() {
      return [
        { key: 'thin', label: 'Thin (100)' },
        { key: 'extra-light', label: 'Extra Light (200)' },
        { key: 'light', label: 'Light (300)' },
        { key: 'regular', label: 'Regular (400)' },
        { key: 'medium', label: 'Medium (500)' },
        { key: 'semibold', label: 'Semibold (600)' },
        { key: 'bold', label: 'Bold (700)' },
        { key: 'extra-bold', label: 'Extra Bold (800)' },
        { key: 'black', label: 'Black (900)' },
      ]
    },
  },
  async created() {
    this.loading = true
    await this.getFonts()
    this.loading = false
  },
  methods: {
    optionLabel(options, key) {
      const item = options.find(option => option.key == key)
      return item ? item.label : ''
    },
    template() {
      return `
        <div
          class="dz-preview dz-file-preview bg-white p-2 mt-2 border rounded d-flex align-items-center"
          style="height: 70px;"
        >
          <div class="dz-image col dfc">
            <i class="fa-solid fa-font fa-2x h-100"></i>
          </div>
          <div class="dz-details col-9">
              <div class="dz-filename"><span data-dz-name></span></div>
          </div>
          <button class="dz-remove btn btn-link text-decoration-none col dfc" data-dz-remove>
            <i class="icon-erp-trash"></i>
          </button>
        </div>
        `
    },
    vdropzoneSuccess(file, response, variant) {
      const existingFonts = JSON.parse(this.value) || {}

      // Ensure the variant property exists and is an array
      existingFonts[variant] = existingFonts[variant] || []

      // id is needed for removing newly uploaded fonts during when creating font
      file.id = response.data.id

      // Add the new font ID to the array
      existingFonts[variant].push(response.data.id)

      this.$emit('input', JSON.stringify(existingFonts))
    },
    vdropzoneFileRemoved(file) {
      let existingFonts = JSON.parse(this.value) || {}

      for (const variant in existingFonts) {
        const index = existingFonts[variant].indexOf(file.id)

        if (index !== -1) {
          // Remove the font ID from the array
          existingFonts[variant].splice(index, 1)

          // If the array is empty, delete the variant property
          if (existingFonts[variant].length === 0) {
            delete existingFonts[variant]
          }

          if (Object.keys(existingFonts).length) {
            this.$emit('input', JSON.stringify(existingFonts))
          } else {
            this.$emit('input', null)
          }

          break
        }
      }
    },
    vdropzoneMounted() {
      const value = JSON.parse(this.value) || {}

      if (this.isDisabled) {
        this.selectedVariants.forEach(variant => {
          const dropzoneRef = this.$refs[`myVueDropzone-${variant}`][0]
          if (dropzoneRef) {
            dropzoneRef.disable()
          }
        })
      }

      this.fonts.forEach(font => {
        this.selectedVariants.forEach(variant => {
          const dropzoneRef = this.$refs[`myVueDropzone-${variant}`]

          if (dropzoneRef && dropzoneRef[0]?.dropzone?.files) {
            const fontIdsForVariant = value[variant] || []
            const fontExist = dropzoneRef[0].dropzone.files.some(file => file.id == font.id)

            if (fontIdsForVariant.includes(font.id) && !fontExist) {
              dropzoneRef.forEach(dzRef => {
                dzRef.manuallyAddFile({
                  name: font.original_filename,
                  type: font.mime_type,
                  id: font.id,
                  url: font.url,
                }, font.url)
              })
            }
          }
        })
      })
    },
    async getFonts() {
      const fontData = this.value ? JSON.parse(this.value) : {}
      const fontIds = Object.values(fontData).reduce((acc, ids) => acc.concat(ids), [])

      if (!fontIds || !fontIds.length) {
        return
      }

      const query = this.erp.ext.query().where('id', 'IN', fontIds)
      this.fonts = (await this.erp.ext.request.axiosInstance.get(`/modules/documents?${query.toString()}`)).data.data
    },
  },
}
</script>
