// Settings
import WebsiteGeneral from "./views/website/General";
import WebshopLocalization from "./views/website/Localization";

export default [

    // 1. Website

    // 1.1 General
    {
        path: '/settings/general',
        name: 'settings-general',
        component: WebsiteGeneral,
    },

    // 1.2 Localization
    {
        path: '/settings/localization',
        name: 'settings-localization',
        component: WebshopLocalization,
    },


]
