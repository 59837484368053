<template>
    <div class="container">
        <page-title :page-title="translate('VAT rates', 'entities')" v-if="records" :counter="records.meta.total">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="$modal.show('create_vat_rate')">
                    {{ translate('Add new {entity}', 'entities', { entity: translate('VAT rate', 'entities').toLowerCase() }) }}
                </button>
            </template>
        </page-title>
        <div class="row" v-if="records">
            <div class="col-md-12">
                <div class="main-table mt-4">
                    <div class="d-flex justify-content-between align-items-center table-header px-4">
<!--                        <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">-->
<!--                            <div class="search-icon">-->
<!--                                <span><i class="fa fa-search"></i></span>-->
<!--                            </div>-->
<!--                            <input type="text" class="form-control h-100 search-field"-->
<!--                                   :placeholder="translate('Search by group', 'global')"-->
<!--                                   :value="userPreferences.search"-->
<!--                                   @input="e => search(e.target.value)">-->
<!--                        </div>-->
                        <div class="d-flex justify-content-end align-items-center h-100 py-2 flex-grow-1 flex-shrink-1 w-100" style="min-width: 150px">
                            <div class="nav-item dropdown dropdown-styled dropdown-animate text-nowrap" data-toggle="hover">
                                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="records">
                                    {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                                    <i class="fa fa-chevron-down"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                    <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">
                                        {{per_page.value}}
                                    </a>
                                </div>
                            </div>
                            <div class="nav-item dropdown dropdown-animate actions-dropdown" data-toggle="hover">
                                <a class="nav-link dropdown-toggle system-text-primary" href="#" role="button"
                                   data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false">
                                    {{ translate('Bulk actions', 'global') }}
                                </a>
                                <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                                    <div class="p-lg-4">
                                        <div class="pb-1 pt-1 delimiter-bottom">
                                            <a href="#" class="d-flex align-items-center dropdown-item" @click="bulkDelete">
                                                {{ translate('Delete', 'global') }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center m-0">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" @input="e => selectAll(e.target.checked)"
                                               class="custom-control-input"
                                               :id="`select_all`">
                                        <label class="custom-control-label" :for="`select_all`"></label>
                                    </div>
                                </th>
                                <th scope="col">{{ translate('VAT group', 'entities') }}</th>
                                <th scope="col">{{ translate('Country', 'entities') }}</th>
                                <th scope="col">{{ translate('Percentage rate', 'entities') }}</th>
                                <th scope="col">{{ translate('State', 'entities') }}</th>
                                <th scope="col">{{ translate('ZIP', 'entities') }}</th>
                                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="tr-1" v-for="(vat, vatI) in records.data" :key="vatI">
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input :id="'row-' + vatI" type="checkbox" class="custom-control-input"
                                               >
                                        <label class="custom-control-label" :for="'row-' + vatI"></label>
                                    </div>
                                </td>
                                <td @click="edit(vat)"><span role="button">{{ vat.vat_type ? vat.vat_type.name : '' }}</span></td>
                                <td>{{ vat.country }}</td>
                                <td>{{ vat.percentage_rate }}</td>
                                <td>{{ vat.state }}</td>
                                <td>{{ vat.zip }}</td>
                                <td class="text-right">
                                    <cta>
                                        <action-button class-icon="fa-pen" @click.native="edit(vat)"></action-button>
                                        <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(vat.id)"></action-button>
                                    </cta>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                                :value="records.meta" v-if="records && records.meta"
                                @goToPage="v => setProp('page', v)"></pagination>
                </div>
            </div>
        </div>

        <modal name="create_vat_rate"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="auto"
               width="500px"
               transition="slide-top"
               overlayTransition="wait"
               @before-close="vat = {}"
               classes="remove-modal-height">
            <create :vat="vat" @save="save"></create>
        </modal>
    </div>
</template>

<script>
import ErpModel from '@/modules/erp_framework/services/ErpModel'
import EntityIndexMixin from "@/mixins/EntityIndexMixin";

import VatRatesCreate from "@/modules/sitecart/views/taxes/VatRatesCreate";

export default {
    name: "VatRates",
    mixins: [EntityIndexMixin],
    components: {'create': VatRatesCreate},
    data() {
        return {
            vat: {},
        }
    },
    methods: {
        save(value) {
                if (value.id) {
                    this.erp.ext.request.axiosInstance.put(`/modules/vat-rates/${value.id}`, {options: value})
                    .then(res => {
                      this.$toast.requestSuccess('put', 'VAT group')
                    }).catch(error => {
                      this.$alert.formattedError(error)
                    })
                } else {
                    ErpModel.create('vat-rates', value)
                    .then(res => {
                      this.$toast.requestSuccess('post', 'VAT group')
                    }).catch( error =>{
                      this.$alert.formattedError(error)
                    })
                }
                this.$modal.hide('create_vat_rate')
                this.loadData()

        },
        edit(vat) {
            this.$modal.show('create_vat_rate')
            this.vat = Object.assign({}, vat)
        }
    },
    async mounted() {
        if (this.$route.query.hasOwnProperty('create'))
            this.$modal.show('create_vat_rate')
    },
    async created() {
        this.entityType = 'vat-rates'
        this.relations = ['VatType']
        await this.loadUserPreferences()
        await this.loadData()
    },
    watch: {
        '$route.query': {
            deep: true,
            handler() {
                if (this.$route.query.hasOwnProperty('create')) {
                    this.$modal.show('create_vat_rate')
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
