<template>
  <div class="container">

      <page-title :page-title="translate('Create {entity}', 'entities', { entity: translate('Customer Group', 'entities') })" :overview="true" :border-bottom="true">
          <!--
          <template v-slot:actions>
              <router-link class="btn btn-primary rounded-pill" to="/webshop-customers/create">
                  {{ translate('Create', 'global') }}
              </router-link>
          </template>-->
      </page-title>

    <div class="card rounded bg-white" v-if="website">
      <div class="card-header">
        <h5>{{ translate('Create {entity}', 'entities', { entity: translate('Customer Group', 'entities') }) }}</h5>
      </div>
      <div class="card-body">
        <div class="row">

          <div class="col-md-12">
            <div class="form-group">
              <label>{{ translate('Name', 'entities') }}</label>
              <input @keydown="checkName" type="text" name="name" id="name" :class="`form-control  ${errors.name ? 'is-invalid' : ''}` " v-model="data.name">
              <small class="help text-danger" v-if="errors.name" >{{ translate('This field is required', 'entities')}}</small>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="d-flex justify-content-between py-2">
                <label>{{ translate('Show prices excl VAT', 'entities') }}<span class="text-danger">*</span></label>
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="show_price_excl_vat"
                      id="show_price_excl_vat"
                      :true-value="1"
                      :false-value="0"
                      class="custom-control-input"
                      v-model="data.show_price_excl_vat"
                  />
                  <label for="show_price_excl_vat" class="custom-control-label">

                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <router-link class="btn btn-white rounded-pill" to="/webshop-customer-groups">
        {{ translate('Go back', 'global') }}
      </router-link>
      <button class="btn btn-primary rounded-pill" @click="save">
        <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
        <span v-else>{{ translate('Create', 'global') }}</span>
      </button>
    </div>
    <router-view v-if="website" @reload-site="findWebsite" :request="findWebsite" :value="website"></router-view>
  </div>

</template>

<script>
import ErpModel from "@/modules/erp_framework/services/ErpModel";

export default {
  name: "CreateCustomerGroup",
  data() {
    return {
      data: {
        name: '',
        show_price_excl_vat: 0,
      },
      website: null,
      errors: {
        name: false
      },
    }
  },
  async created() {
    if (!this.$route.params.id) {
      this.data.company_id = this.$store.state.system.company.key
      this.data.website_id = this.$store.state.system.scope.value
    }

    if (this.$route.params.id) {
      this.$store.state.system.isLoading = true
      let customer_group = await this.erp.ext.request.axiosInstance.get(`/modules/customer-groups/${this.$route.params.id}`)
      this.data = customer_group.data.data
      this.$store.state.system.isLoading = false
    }
  },
  methods: {
    async save() {

      this.errors.name = false;
      if(!this.data.name.replace(/\s/g,'').length) {
        this.errors.name = true;
        return false;
      }

      let route = this.$route.params.id
          ? await this.erp.ext.request.axiosInstance.put(`/modules/customer-groups/${this.$route.params.id}`, this.data)
              .then((res) => this.$toast.requestSuccess('put', 'Customer Group'))
              .catch(error => {
                  this.$alert.formattedError(error)
              })
          : await this.erp.ext.request.axiosInstance.post(`/modules/customer-groups`, this.data)
            .then((res) => {
              this.$toast.requestSuccess('post', 'Customer Group')
              this.$router.push(`/webshop-customer-groups`)
            }).catch(error => {
              this.$alert.formattedError(error)
            })

    },
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },
    checkName() {
      if(this.data.name.replace(/\s/g,'').length) {
        this.errors.name = false;
      }
    }
  },
  mounted() {
    this.findWebsite()
  }
}
</script>

<style scoped>

</style>
