<template>
  <div class="inline-editor" :class="{'inline-editor-bordered': bordered}">
    <template v-if="editor && editorEnabled">
      <text-editor-actions v-if="!externalActions" :editor="editor" />
      <editor-content :editor="editor"/>
    </template>

    <template v-else>
      <span v-html="this.value"/>
    </template>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit' // https://tiptap.dev/api/extensions/starter-kit

import Link from '@tiptap/extension-link'

import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'

import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import { BackgroundColor } from "./BackgroundColorExtension"
import FontSize from 'tiptap-extension-font-size'
import TextEditorActions from "@/modules/erp_entities/components/TextEditorActions.vue"

export default {
  name: "TextEditorInline",
  components: {
    TextEditorActions,
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    editorEnabled: {
      type: Boolean,
      default: true,
    },
    externalActions: {
      type: Boolean,
      default: false,
    },
    showDrag: {
      required: false,
      default: false,
      type: Boolean,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,

        Link.configure({
          openOnClick: false,
        }),

        Underline,
        TextStyle,
        Color,
        BackgroundColor, // Custom extension
        FontSize,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Placeholder.configure({
          // Use a placeholder:
          placeholder: this.placeholder || 'Start typing your message',
          // Use different placeholders depending on the node type:
          // placeholder: ({ node }) => {
          //   if (node.type.name === 'heading') {
          //     return 'What’s the title?'
          //   }

          //   return 'Can you add some further context?'
          // },
        }),

      ],
      onUpdate: () => {
        let content = this.editor.getHTML() // HTML
        let json = this.editor.getJSON() // JSON

        // get rid of empty p, h1, h2, etc tag
        if (Array.isArray(json.content) && json.content.length === 1 && !json.content[0].hasOwnProperty("content")) {
          content = ""
        }

        this.$emit('input', content)
      },
    })

    this.$emit('editorMounted', this.editor)
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.inline-editor {
  .ProseMirror {
    cursor: text;
    padding: 15px;
  }
  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  &.inline-editor-bordered {
    border: 1px solid #e6e6e6;

    .inline-editor-actions {
      flex-wrap: wrap;
      border-bottom: 1px solid #e6e6e6;
    }

    .ProseMirror {
      background: #fff;
      overflow-y: auto;
      resize: vertical;
    }
    .ProseMirror.ProseMirror-focused {
      outline-color: #e5edf5;
      border-radius: 0;
    }
  }
}
</style>