// Marketplace
import AppStore from "@/modules/blockz/views/marketplace/AppStore";
import MyApps from "@/modules/blockz/views/marketplace/MyApps";
import AppShowView from "@/modules/blockz/views/marketplace/AppShowView";

// Plans
import Plans from "@/modules/blockz/views/plans/Plans";
import Overview from "@/modules/blockz/views/plans/createPlan/Overview";
import Basics from "@/modules/blockz/views/plans/createPlan/Basics";
import Pricing from "@/modules/blockz/views/plans/createPlan/Pricing";
import AddExtensions from "@/modules/blockz/views/plans/createPlan/AddExtensions";
import PlanSetup from "@/modules/blockz/views/plans/createPlan/PlanSetup";
import Wizard from "@/modules/blockz/views/plans/Wizard";
import PlanFinished from "./views/plans/createPlan/PlanFinished";

// Extensions
import Extension from "@/modules/blockz/views/extensions/Extension.vue";
import CreateExtension from "@/modules/blockz/views/extensions/CreateExtension";
import Finished from "./views/extensions/Finished";

// Orders
import IndexOrder from "./views/orders/IndexOrder";
import CreateOrderIndex from "./views/orders/createOrder/CreateOrderIndex";
import ChoosePlan from "./views/orders/createOrder/ChoosePlan";
import Limits from "./views/orders/createOrder/Limits";
import TrialAndExtensions from "./views/orders/createOrder/TrialAndExtensions";
import OrderExtensions from "./views/orders/createOrder/OrderExtensions";
import OrderFinalView from "./views/orders/createOrder/OrderFinalView";
import OrderFinish from "./views/orders/createOrder/OrderFinish";
import AssignClient from "@/modules/blockz/views/orders/createOrder/AssignClient";
import ClientsIndex from "@/modules/blockz/views/clients/ClientsIndex";
import SubscribtionIndex from "@/modules/blockz/views/subscribtions/SubscribtionIndex";
import SubscribtionShowview from "@/modules/blockz/views/subscribtions/SubscribtionShowview";
import InvoiceIndex from "@/modules/blockz/views/invoices/InvoiceIndex";

import createOrderExtensionIndex from "@/modules/blockz/views/orders/createOrderExtension/createOrderExtensionIndex";


// Subscriptions
import Subscriptions from '@/modules/blockz/views/subscriptions/Subscriptions'
import Modules from "@/modules/blockz/views/modules/Modules";

// Settings
import EmailAndNotificationsSettings from "@/modules/blockz/views/settings/EmailAndNotificationsSettings";

// Tools
import ContentGenerator from "@/modules/blockz/views/tools/ContentGenerator.vue";

export default [
    // Plans routes
    {
        path: '/plans',
        name: 'plans',
        component: Plans,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/plans/create',
        name: 'plans-create',
        component: Wizard,
        meta: {
            interface: 'blockz'
        },
        children: [
            {
                path: 'overview',
                name: 'overview',
                component: Overview,
                meta: {title: 'Overview', step: '1', interface: 'blockz'}
            },
            {
                path: 'basics',
                name: 'basics',
                component: Basics,
                meta: {title: 'Let\'s start with the basics', step: '2', interface: 'blockz'}
            },
            {
                path: 'extensions',
                name: 'plan-extensions',
                component: AddExtensions,
                meta: {title: 'Add free to use extensions (includes in package)', step: '3', interface: 'blockz'}
            },
            {
                path: 'setup',
                name: 'plan-setup',
                component: PlanSetup,
                meta: {title: 'Compose the setup for CMS plan', step: '4', interface: 'blockz'}
            },
            {
                path: 'pricing',
                name: 'pricing',
                component: Pricing,
                meta: {title: 'Pricing', step: '5', interface: 'blockz'}
            },
            {
                path: 'finished',
                name: 'plan-finished',
                component: PlanFinished,
                meta: {
                    interface: 'blockz'
                }
            },
        ]
    },

    // Extension routes
    {
        path: '/extensions',
        name: 'extensions',
        component: Extension,
    },
    {
        path: '/extensions-create',
        name: 'create',
        component: CreateExtension,
        meta: {
            interface: 'blockz'
        },
    },
    {
        path: '/extensions-finished',
        name: 'extensions-finished',
        component: Finished,
        meta: {
            interface: 'blockz'
        },
    },

    // Modules routes
    {
        path: '/modules',
        name: 'modules',
        component: Modules,
        meta: {
            interface: 'blockz'
        },
    },

    // Marketplace routes
    {
        path: '/app-store',
        name: 'app-store',
        component: AppStore,
        meta: {
            interface: 'skitle'
        },
    },
    {
        path: '/my-apps',
        name: 'my-apps',
        component: MyApps,
        meta: {
            interface: 'skitle'
        },
    },
    {
        path: '/app-show/:id',
        name: 'app-show',
        component: AppShowView,
        meta: {
            interface: 'skitle'
        },
    },

    //Order routes
    {
        path: '/blockz-order',
        name: 'blockz-order',
        component: IndexOrder,
        meta: {
            interface: 'blockz'
        },
    },
    {
        path: '/blockz-order/create',
        name: 'order-create',
        component: CreateOrderIndex,
        meta: {next: 'choose-plan', interface: 'blockz'},
        children: [
            {
                path: 'choose-plan',
                name: 'choose-plan',
                component: ChoosePlan,
                meta: {title: 'Choose your plans', step: '1', next: 'limits', interface: 'blockz'}
            },
            {
                path: 'limits',
                name: 'limits',
                component: Limits,
                meta: {
                    title: 'Limits',
                    step: '2',
                    next: 'trial-and-extensions',
                    back: 'choose-plan',
                    interface: 'blockz'
                }
            },
            {
                path: 'trial-and-extensions',
                name: 'trial-and-extensions',
                component: TrialAndExtensions,
                meta: {
                    title: 'Trial and (extra) Extensions',
                    step: '3',
                    next: 'extensions',
                    back: 'limits',
                    interface: 'blockz'
                }
            },
            {
                path: 'extensions',
                name: 'order-extensions',
                component: OrderExtensions,
                meta: {
                    title: 'Add free to use extensions (includes in package)',
                    step: '4',
                    next: 'client',
                    back: 'trial-and-extensions',
                    interface: 'blockz'
                }
            },
            {
                path: 'client',
                name: 'client',
                component: AssignClient,
                meta: {
                    title: 'Add client',
                    step: '5',
                    next: 'order-final',
                    interface: 'blockz'
                }
            },
            {
                path: 'order-final',
                name: 'order-final',
                component: OrderFinalView,
                meta: {
                    interface: 'blockz'
                }

            },
            {
                path: 'order-finish',
                name: 'order-finish',
                component: OrderFinish,
                meta: {
                    interface: 'blockz'
                }
            },
        ]
    },
    {
        path: '/blockz-order-extension/create',
        name: 'order-create-extension',
        component: createOrderExtensionIndex,
        meta: {
            interface: 'blockz'
        }
    },

    //Clients routes
    {
        path: '/companies',
        name: 'companies',
        component: ClientsIndex,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/companies/:id',
        name: 'companies-show',
        component: () => import('../blockz/views/clients/ClientsShow.vue'),
        meta: {
            interface: 'blockz'
        }
    },

    // Subscriptions
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: SubscribtionIndex,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/subscriptions/:id',
        name: 'subscription-show',
        component: SubscribtionShowview,
        meta: {
            interface: 'blockz'
        }
    },

    // Invoices
    {
        path: '/invoices',
        name: 'invoices',
        component: InvoiceIndex,
        meta: {
            interface: 'blockz'
        }
    },

    // Subscriptions routes
    // {
    //     path: '/subscriptions',
    //     name: 'subscriptions',
    //     component: Subscriptions
    // },

    // Settings routes
    {
        path: '/settings/notifications-and-emails',
        name: 'settings-notifications-and-emails',
        component: EmailAndNotificationsSettings,
        meta: {
            interface: 'blockz'
        }
    },

    // Tools routes
    {
        path: '/tools/content-generator',
        name: 'tools-content-generator',
        component: ContentGenerator,
        meta: {
            interface: 'blockz',
        }
    }
]

