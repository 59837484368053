export function clientDataToApiData(data, invoice, additional){
  return {
    additional_data: { ...additional },


    //Billable required data
    billing_client_first_name: invoice.first_name || data.first_name,
    billing_client_last_name: invoice.last_name || data.last_name || data.first_name,
    billing_client_phone: invoice.phone || data.phone || '0000000000',
    billing_client_email: invoice.email || data.email,

    billing_address_company: invoice.company || 'Bulgaria',
    billing_address_country: invoice.country || 'Bulgaria',
    billing_address_state: invoice.state || 'Bulgaria',
    billing_address_city: invoice.city || 'Bulgaria',
    billing_address_postcode: invoice.postcode || '0000',
    billing_address_address: invoice.address || 'Bulgaria',
    billing_address_mol: invoice.mol || 'Bulgaria',
    billing_address_bulstat: invoice.bulstat || 'Bulgaria',
    billing_address_vat: invoice.vat || '',
    create_invoice: data.create_invoice || false,

    company_name: invoice.company_name || invoice.first_name + invoice.last_name,
    company_address: invoice.company_address || invoice.address,

    shipping_client_first_name: invoice.first_name || data.first_name,
    shipping_client_last_name: invoice.last_name || data.last_name,
    shipping_client_phone: invoice.phone || data.phone,

    shipping_address_country:'Bulgaria',
    shipping_address_state: 'Bulgaria',
    shipping_address_city: 'Bulgaria',
    shipping_address_postcode: '5000',
    shipping_address_address: 'Bulgaria',
  }
}
