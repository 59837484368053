<template>
  <div class="file-picker">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7 p-1">

          <draggable
            v-model="files"
            class="file-picker_files"
            v-bind="dragOptions"
            draggable=".draggable-item"
            v-on="!isReadonly ? { start: drag = true, end: drag = false } : null"
          >
            <div class="item draggable-item m-2" v-for="(file, idx) in files" :key="idx" @click="canWrite ? select(idx) : null" :class="{ selected: selected === idx}">
              <div class="initial_file-picker file-picker_content">
                <img
                  :src="srcByType(file)"
                  :alt="entityLabel(file.alt)"
                  :title="entityLabel(file.title)"
                />
                <div class="file_video_overlay d-flex justify-content-center align-content-center" v-if="isVideo(file)"><i class="fa-solid fa-play text-white"></i></div>
                <div class="file_overlay" v-if="file.title">
                  <div class="text">{{ entityLabel(file.title) }}</div>
                </div>
                <div class="file-options_row" v-if="file.featured">
                  <i class="fas fa-star bg-transparent"></i>
                </div>
              </div>
            </div>

            <div class="item m-2">
              <div class="initial_file-picker file-picker_content dropdown dropdown-animate" data-toggle="hover">
                <span>
                  <i class="fa-3x fas fa-circle-plus"></i>
                  <div>{{ translate('Add media', 'entities') }}</div>
                </span>
                <div class="dropdown-menu">
                  <template v-for="type in mediaSources">
                    <a @click="canWrite ? type.action() : null" class="dropdown-item">{{ entityLabel(type.label) }}</a>
                  </template>
                </div>
              </div>
            </div>
          </draggable>

        </div>
        <div class="col-md-5 file_settings" v-if="selected || selected === 0">
          <div class="form-group">
            <div class="row justify-content-between">
              <div class="col-md-8">
                <label class="">{{translate('Set as featured', 'entities')}}:</label>
              </div>
              <div class="custom-control custom-switch col-md-4 text-right">
                <input type="checkbox"
                       :id="`option-featured-${selected}`"
                       name=""
                       class="custom-control-input"
                       @change="canWrite ? changeFeatured(selected) : null"
                       :checked="files[selected].featured"
                       :disabled="files[selected].featured === 1"
                       :true-value="1"
                       :false-value="0">
                <label :for="`option-featured-${selected}`" class="custom-control-label"></label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ translate('Alt text', 'entities') }}</label>
            <input-field v-model="files[selected].alt"
                         :field-props="fieldProps"/>
          </div>
          <div class="form-group">
            <label>{{ translate('Title', 'entities') }}</label>
            <input-field v-model="files[selected].title"
                         :field-props="fieldProps"/>
          </div>
          <div class="form-group py-2">
            <button @click="canWrite ? remove(selected) : null" class="btn btn-white rounded-pill">
              <i class="far fa-trash-can"></i> {{ translate('Delete', 'entities') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <modal
      name="embed-video"
      :pivot-x="0.5"
      :pivot-y="0.5"
      height="auto"
      width="800px"
      transition="fade-in"
      overlay-transition="wait"
      classes="remove-modal-height"
    >
      <embed-video-modal :field-props="fieldProps" @input="files.push($event)" />
    </modal>
  </div>
</template>

<script>
import { uniqueId } from "lodash"
import { isVoidValue } from "@/utilities/helper"
import DraggableMixin from "@/mixins/DraggableMixin"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import EmbedVideoModal from "@/modules/erp_entities/components/modals/EmbedVideoModal.vue"

export default {
  name: 'GalleryField',
  components: {
    EmbedVideoModal, InputField,
  },
  mixins: [DraggableMixin, FormFieldMixin],
  data() {
    return {
      key: 0,
      selected: null,
      files: [],
      alt: null,
      title: null,
      mediaSources: [
        { key: 'media-library', label: 'Choose from media library', action: this.triggerFilemanager },
        { key: 'embed-video', label: 'Embed video', action: this.showEmbedVideoModal },
      ],
    }
  },

  methods: {
    triggerFilemanager() {
      this.$fileManager.$emit('open', this.key)
    },
    showEmbedVideoModal() {
      this.$modal.show('embed-video')
    },
    remove(i) {
      if (isVoidValue(i)) {
        return
      }
      this.files.splice(i, 1)
      this.selected = null
      this.$emit('input', JSON.stringify(this.files))
    },
    select(idx) {
      if (this.selected === idx) {
        idx = null
      }
      this.selected = idx
    },
    changeFeatured(idx) {
      this.files.forEach((file, i) => {
        if (idx !== i) {
          file.featured = 0
        }
      })
      this.files[idx].featured = 1
    },
    srcByType(file) {
      return file.thumbnail ? file.thumbnail : file.url
    },
    isVideo(file) {
      const fileExtension = file.url.split('.').pop().toLowerCase()
      const videoExtensions = ['mp4', 'mov', 'wmv', 'webm', 'avi', 'mkv', 'flv', 'mts']
      return file.type === 'embed-video' || videoExtensions.includes(fileExtension)
    },
  },

  created() {
    this.key = uniqueId('fpi_')

    this.files = (typeof this.value == 'string' ? JSON.parse(this.value) : this.value) || []

    // fpi = file picker input
    this.$fileManager.$on('selected_'+ this.key, (files, idx) => {
      files.forEach(file => this.files.push({
        url: file,
        alt: this.alt,
        title: this.title,
        featured: file.featured === true,
        type: 'file-manager',
      }))

      this.$emit('input', JSON.stringify(this.files))
    })

  },

  watch: {
    'files': {
      handler: function (newFiles) {
        // The first file added is featured
        if (newFiles.length === 1) {
          newFiles[0].featured = 1
        }
        this.$emit('input', JSON.stringify(newFiles))
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.file-picker {
  border: 1px solid $border-color;

  .file-picker_files {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .item {
      width: 130px;
      height: 130px;
      border: 2px solid $border-color;
      cursor: pointer;

      .file-picker_content {
        position: relative;
        min-height: 70px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .file_video_overlay {
        position: absolute;

        i {
          font-size: 1.5rem;
        }
      }

      .file_overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        left:  0;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .2s ease;
        display: flex;
        justify-content: center;
        align-items: center;

        > .text {
          color: #fff;
          font-size: 12px;
          text-align: center;
        }
      }

      &:hover, &.selected {
        border-color: $primary;

        .file_overlay {
          height: 25%;
          background-color: $primary;

        }
      }
    }

    .file-options_row {
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;

      i {
        font-size: 1.5rem;
      }
    }

    .col-md-3 {
      margin-bottom: 1rem;
    }
  }

  .initial_file-picker {
    span {
      color: var(--vs-colors--light);
    }

    &.file-picker_content, .file-picker_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      i:not(.fa-star) {
        font-size: 2rem;
        color: var(--vs-colors--light);
      }
    }
  }

  .file_settings {
    padding: 2rem 1rem;
    line-height: 0.8;
    border-left: 1px solid $border-color;

    .form-group {
      .custom-control {
        &.custom-switch {
          margin-top: -5px;
        }
      }
    }
  }

  @media all and (max-width: 767px)  {
    .file_settings {
      border-left: none;
    }
  }
}
</style>