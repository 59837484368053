<template>
  <div>
    <div class="card-header d-flex">
      <h5 class="font-weight-bold">
        <span>{{ translate('Attributes used for variations', 'products') }}</span>
      </h5>
      <button type="button" style="margin-left: auto;" class="close" @click="$modal.hide('product_variations_attributes')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="card-body" style="height: 400px; overflow-y: auto" ref="variation-attributes-modal-body">

      <div v-if="loading" class="d-flex justify-content-center m-5">
        <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
      </div>

      <template v-if="attributes">

          <template v-if="attributes.length === 0">
            <div class="warning">
              <div class="content">
                <span><i class="fas fa-exclamation-triangle"></i></span>
                <span class="text-secondary-color"> {{ translate('To use this feature you must have selectable attributes.', 'products') }}</span>
              </div>
            </div>
          </template>

          <div class="row" v-else>

            <div v-if="configurableAttributes" class="col-12">
              <div class="content mb-2">
                <p><strong>{{ translate('These changes apply to the current item only', 'products') }}</strong></p>
                <p>{{ translate('Options added or removed here will not affect other items using this options set. Removing an option here will also delete its variations on this item', 'products') }}</p>
<!--                <span class="text-secondary-color"> {{ translate('On changing attributes all existing variations will be removed.', 'products') }}</span>-->
              </div>
            </div>

            <!-- Page 1 - Select Attributes -->
            <div v-show="modalPage === 1" class="col-12">
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th scope="col">
                    <div class="d-flex justify-content-between">
                      <label>{{ translate('Attribute', 'products') }}</label>
                      <label>{{ translate('Enabled', 'products') }}</label>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="tr-1" v-for="(field, fieldI) in attributes">
                  <td>
                    <div class="d-flex justify-content-between">
                      <label :for="`attribute_enabled-${fieldI}`">{{ entityLabel(field.label) }}</label>

                      <div class="custom-control custom-switch">
                        <input
                            type="checkbox"
                            name="attribute_enabled"
                            :id="`attribute_enabled-${fieldI}`"
                            class="custom-control-input"
                            :checked="configurableAttributes && configurableAttributes.includes(field.name)"
                            @change="toggleAttribute(field.name)"
                        />
                        <label :for="`attribute_enabled-${fieldI}`" class="custom-control-label"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Page 2 - Select Attributes Values -->
            <div v-show="modalPage === 2" class="col-12">

              <!-- wait for set initial values -->
              <template v-if="Object.keys(attributesSelectValuesData).length">
                <field
                  v-for="field in enabledAttributes"
                  @createOptions="v => $emit('createOptions', v)"
                  :key="'attribute_value_' + field.name"
                  @input="v => $emit('handleAttributeValue', field.name, v)"
                  :value="attributesSelectValuesData[field.name]"
                  :field-props="field"
                  :apply-default-val="false"
                />
              </template>
            </div>
          </div>
      </template>

    </div>

    <div class="d-flex justify-content-end p-3">
      <button class="btn btn-white rounded-pill" @click="$modal.hide('product_variations_attributes')">
        <span>{{ translate('Cancel', 'global') }}</span>
      </button>
      <button class="btn btn-primary rounded-pill" v-if="attributes && attributes.length > 0 && modalPage === 1" @click.stop="goToPage(2)">
        <span>{{ translate('Next', 'global') }} <i class="fas fa-chevron-right"></i> </span>
      </button>
      <button class="btn btn-primary rounded-pill" v-if="attributes && attributes.length > 0 && modalPage === 2" @click.stop="goToPage(1)">
        <span><i class="fas fa-chevron-left"></i> {{ translate('Go back', 'global') }} </span>
      </button>
      <button class="btn btn-primary rounded-pill" v-if="attributes && attributes.length > 0 && modalPage === 2" @click="$modal.hide('product_variations_attributes')">
        <span> {{ translate('Done', 'global') }} </span>
      </button>

    </div>
  </div>
</template>

<script>
import busEvent from "@/utilities/eventBus";
import field from '@/modules/erp_entities/components/page/form/InputEntityProps'

export default {
  components: {
    field
  },
  props: {
    configurableAttributes: {
      required: true,
      type: Array
    },
    attributesSelectValues: {
      required: false,
      type: Object
    },
    attributeSetId: {
      required: true
    },
  },
  data() {
    return {
      modalPage: 1,
      loading: false,
      attributes: null,
      configurableAttributesData: [],
      attributesSelectValuesData: {},
    }
  },
  async created() {
    this.loading = true
    this.attributes = await this.getAttributes()
    this.setInitialValues()
    this.loading = false
  },
  computed: {
    enabledAttributes() {
      return this.attributes.reduce((acc, el) => {
        if(this.configurableAttributes.includes(el.name)){
          acc.push(el)
        }
        return acc
      }, [])
    }
  },
  methods: {
    goToPage(page){
      this.modalPage = page
      this.$refs['variation-attributes-modal-body'].scrollTop = 0;
    },
    setInitialValues() {
      // Configurable attributes
      this.configurableAttributesData = this.configurableAttributes

      // Attributes select values, for reactivity all must be defined
      const attributesSelectValuesCopy = JSON.parse(JSON.stringify(this.attributesSelectValues))
      this.attributes.forEach(attribute => {
        this.$set(this.attributesSelectValuesData, attribute.name, attributesSelectValuesCopy[attribute.name] || [])
      })
    },
    async getAttributes(){
      let properties = await this.erp.ext.request.axiosInstance.get(`/modules/attribute-sets/${this.attributeSetId}/properties`)

      const fields = []

      Object.entries(properties.data.data).forEach(([groupKey, group]) => {
        group.fields.forEach(v => {
          // Skip system attributes
          if(typeof v.system_attribute === 'undefined' || parseInt(v.system_attribute) === 1){
            return
          }
          // Skip non select types (multiselect excluded as well as it is not working in the frontside)
          if(!['select', 'visual_swatch'].includes(v.type)){
            return
          }

          v.type = 'table_multiselect' // change the type to render it as table multi select

          fields.push(v)
        })
      })

      return fields

    },
    toggleAttribute(key) {
      if (this.configurableAttributesData.includes(key)) {
        this.configurableAttributesData.splice(this.configurableAttributesData.indexOf(key), 1);
      } else {
        this.configurableAttributesData.push(key)
      }
      this.$emit('inputConfigurableAttributes', this.configurableAttributesData)
    },
  },
}
</script>