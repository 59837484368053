import Vue from 'vue';

import Vuex from 'vuex';

import auth from "./modules/auth";
import system from './modules/system';
import tracker from './modules/tracker';
import blockzCheckout from "@/modules/blockz/views/orders/createOrder/blockzCheckout";
import entities from '../modules/erp_entities/store/modules/entities';

Vue.use(Vuex)

export default  new Vuex.Store({
    modules: {
       system, tracker, auth, blockzCheckout, entities
    },
});
