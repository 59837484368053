<template>
  <!-- DEPRECATED, TODO REMOVE ME AFTER COMPONENT IS CHANGED TO CustomCode on all envs -->
  <div class="cms-block cms-block-custom-html" v-html="content"></div>
</template>

<script>

import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";

export default {
  name: 'CustomHtmlElement',
  mixins: [CraftSettingsMixin],
  props: {
    content: String,
  },
  inject: ['node'],
  craft: {
    defaultProps: {
      content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
    },
  },
};
</script>