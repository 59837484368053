
<template>
  <div class="form-group" style="position:relative;">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <devices-dropdown v-if="hasResponsiveStyles" />
    <div>
      <div v-if="value == '' || value == 'none'">

<!--        <div class="row open-file_manager mr-1 ml-1" @click="() => this.$emit('selectImageChild','background_image')">-->
        <div class="row open-file_manager mr-1 ml-1" @click="triggerFilemanager">
          <div class="col-md-9">
<!--              <i class="picker-placeholder">Drag and drop files here</i>-->
          </div>
          <div class="col-md-2">
            <button class="btn-browse">{{ translate('Browse', 'page-builder') }}</button>
          </div>
        </div>

      </div>
      <div v-else>
        <small>{{ translate('Selected image', 'page-builder') }}</small>
        <img
            :src="value"
            width="100%"
            v-if="value"
            height="300px"
            style="object-fit: contain"
            alt
        />
        <button class="btn remove-image" @click="removeImage()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {uniqueId} from "lodash";
import DevicesDropdown from "@/modules/pagebuilder/components/craft-inputs/DevicesDropdown.vue";

export default {
  name: "CraftImagePicker",
  components: { DevicesDropdown },
  inject:['editor'],
  props:{
    label: {
      required: true,
    },
    value:{
      required: true
    },
    type: {
      default: 'image', // image, background-image
      required: true,
    },
    hasResponsiveStyles: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      key: 0,
      files: []
    }
  },
  created() {
    this.key = uniqueId('cfpi_')

    // cfpi = craft file picker input
    this.$fileManager.$on('selected_'+ this.key, imgs => {
      /* if we need more then one files
      imgs.forEach(img => this.files.push(img));
      this.$emit('input', this.files)
      */

      let path = ''
      if(this.type === 'background-image'){
        path = `url(${imgs[0]})`
      } else {
        path = imgs[0]
      }

      this.$emit('input', path)

      // not needed
      // this.$fileManager.$emit('close')
    })

  },
  methods: {
    triggerFilemanager() {
      this.$fileManager.$emit('open', this.key)
    },
    removeImage(){
      let default_value = ''
      if(this.type === 'background-image'){
        default_value = 'none'
      }
      this.$emit('input', default_value)
    }
  }
}
</script>

<style scoped lang="scss">

.remove-image {
  position: absolute;
  right: 9px;
  top: 60px;
  background: #fff;
  padding: 5px 8px;
  line-height: 0;

  .fa-times {
    font-size: 16px;
  }
}

.btn-browse{
  background-color: #F9306D;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 0.5rem;
}
.picker-placeholder{
  font-size: 0.8rem;
}
</style>
