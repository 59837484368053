<template>
  <!-- DEPRECATED, use textarea-simple-field instead -->
  <textarea-simple-field
    v-model="model"
    :field-props="fieldProps"
    :placeholder="placeholder"
    :entity="entity"
  />
</template>

<script>
import TextareaSimpleField from "@/modules/erp_entities/components/page/form/fields/TextareaSimpleField.vue"

export default {
  name: "EeFormTextareaSimple",
  components: { TextareaSimpleField },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
      default: {},
    },

    placeholder: {
      required: false,
      default: '',
    },
    entity: {
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>
