<template>
    <div class="app-plan-modal">
        <div class="modal-default">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="text-white">{{ translate('Review skitle plan prices', 'marketplace') }}</h5>
                </div>
                <div class="modal-body">
                    <div class="plan-body m-3">
                        <div class="row">
                            <div class="col-md-4 b-b-pr-gr py-2">
                                <span class="font-weight-bold">{{ translate('Skitle product', 'marketplace') }}</span>
                            </div>
                            <div class="col-md-4 b-b-pr-gr py-2">
                                <span class="font-weight-bold">{{ translate('Plan', 'marketplace') }}</span>
                            </div>
                            <div class="col-md-4 b-b-pr-gr py-2 text-right">
                                <span class="font-weight-bold">
									{{ translate('Price in', 'marketplace') }}
									{{ value.price.currency }}
								</span>
                            </div>

                            <div class="single-plan d-flex w-100 py-3 b-b-pr-gr">
                                <div class="col-md-4">{{ value.readable_options.name }}</div>
                                <div class="col-md-4">{{ translate('Pro', 'marketplace') }}</div>
                                <div class="col-md-4 text-right">
									{{ value.price.currency }}
									{{ value.price.price }}
								</div>
                            </div>
                        </div>

                        <div class="w-100 text-right py-2">
                            <span class="font-weight-bold">{{ translate('New month total', 'marketplace') }}: 45$ {{ translate('p or m', 'marketplace') }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer footer-plan-modal d-flex flex-nowrap">
                    <button @click="$modal.hide('app-plan-modal')" class="btn btn-cancel-plan w-50 py-3">{{ translate('Cancel plan', 'marketplace') }}</button>
                    <button class="btn btn-confirm-plan w-50 py-3">{{ translate('Confirm my new plan', 'marketplace') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppPlanModal",
		props: {
        	value: {
        		required: false
			}
		},
		data() {
        	return {

			}
		}
    }
</script>

<style lang="scss">
    .app-plan-modal {
        .modal-header {
            background: $primary !important;
            border-bottom: none !important;
        }

        .plan-body .row {
            border: 1px solid #DEDEDE;
            border-radius: 5px;

            .single-plan:last-child {
                border-bottom: none;
            }
        }

        .modal-footer.footer-plan-modal {
            padding: 0;
            border-bottom: none !important;

            & > * {
                margin: 0;
            }

            .btn-cancel-plan {
                background: #dedede;
                border-color: #dedede;
                color: #000;
                border-radius: 0;
                &:hover {
                    background: #fff;
                    border-color: #dedede;
                    color: #000;
                }
            }

            .btn-confirm-plan {
                background: #1ce67a;
                border-color: #1ce67a;
                color: #fff;
                border-radius: 0;
                &:hover {
                    background: #fff;
                    border-color: #1ce67a;
                    color: #1ce67a;
                }
            }
        }
    }
</style>