<template>
    <div class="container">
        <div class="row" v-if="company">
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Company name', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('name', v.target.value)" v-model="company.data.name">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Email', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('email', v.target.value)" v-model="company.data.email">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Street number', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('street_number', v.target.value)" v-model="company.data.street_number">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('ZIP code', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('zip_code', v.target.value)" v-model="company.data.zip_code">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('City', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('city', v.target.value)" v-model="company.data.city">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Country', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('country', v.target.value)" v-model="company.data.country">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('VAT', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('vat', v.target.value)" v-model="company.data.vat">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Phone', 'onboarding') }}</label>
                    <input class="form-control"
                           @input="v => company.set('phone', v.target.value)" v-model="company.data.phone">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>{{ translate('Currency', 'onboarding') }}</label>
                    <v-select v-model="company.data.currency"
                              @input="v => company.set('currency', v.target.value)" label="name" :reduce="value => value.code" :options="currencies">
                        <template v-slot:option="option">
                            {{ option.symbol_native}} - {{ option.name }}
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
        <div class="container d-flex w-100 mt-5"
             :class="$router.currentRoute.meta.prev ? 'justify-content-between': 'justify-content-end'">
          <router-link v-if="$router.currentRoute.meta.prev"
                       :to="`${$router.currentRoute.meta.prev}`"
                       class="btn btn-primary"
          >
            {{ translate('Go back', 'global') }}
          </router-link>
          <button class="btn btn-primary" @click="nextStep">
            {{ translate('Next', 'global') }}
          </button>
        </div>
    </div>
</template>

<script>


// - Company name
// - Chamber of commerce number (not required)
// - Currency = All currencies and symbols from the world

import currencies from "@/assets/misc/currencies";
import {ErpModel} from "@/modules/erp_framework";

export default {
    name: "CompanySettings",
    data() {
        return {
            company: null
        }
    },
    computed: {
        currencies() {
            return Object.values(currencies[0])
        }
    },
    async created() {
        this.company = await ErpModel.find('companies', this.$store.state.system.company.key)
    },
    methods: {
        async nextStep() {
            try {
              await this.company.save()
              this.$router.push(this.$router.currentRoute.meta.next)
            } catch (e) {
                console.log(e)
            }
        }
    },
}
</script>

<style scoped>

</style>
