<template>
    <div class="container">
        <div class="pricing-body py-4 b-b-pr-gr">
            <div class="row mb-5" v-for="(price, pi) in value.prices" :key="pi">
                <div class="col-md-12">
                    <div class="parent-border">
                        <label class="input-label">{{ price.name }} {{ translate('subscription', 'plans') }}</label>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label v-if="price.period === 1"
							   class="pricing-label">{{translate('monthly price', plans)}}</label>
						<label v-else class="pricing-label">{{translate('yearly price', plans)}}</label>
                        <div class="d-flex justify-content-between" style="border-radius: 5px">
                            <input v-model="price.price" class="period-input form-control">
                            <span class="pricing-value w-25 d-flex justify-content-center align-items-center font-weight-bold">€</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="vat_options">
                    <div class="form-group">
                        <label class="pricing-label">{{ translate('VAT', 'plans') }}</label>
                        <div class="vat-dropdown select2-form">
                            <div class="d-flex">
                                <v-select class="w-75" label="value" v-model="price.vat" :reduce="value => value.key" :options="vat_options">
                                </v-select>
                                <span class="pricing-value w-25 d-flex justify-content-center align-items-center p-2 font-weight-bold">%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="pricing-label">{{ translate('sale from', 'plans') }}</label>
                        <date-picker type="date" v-model="price.sale_price_from" valueType="format" :input-class="['form-control']"></date-picker>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="pricing-label">{{ translate('sale till', 'plans') }}</label>
                        <date-picker type="date" v-model="price.sale_price_until" valueType="format" :input-class="['form-control']"></date-picker>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="pricing-label">{{ translate('sale price', 'plans') }}</label>
                        <div class="d-flex justify-content-between" style="border: 1px solid; border-radius: 5px">
                            <input v-model="price.sale_price" class="period-input form-control">
                            <span class="pricing-value w-25 d-flex justify-content-center p-2 align-items-center font-weight-bold">€</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-footer d-flex justify-content-between py-4">
            <router-link to="setup">
                <button class="btn btn-white rounded-pill">{{ translate('Go back', 'global') }}</button>
            </router-link>
            <span class="step-count">{{ translate('step 5 of 5', 'plans') }}</span>
			<button @click="finishPlan" class="btn btn-primary rounded-pill">{{ translate('Continue', 'global') }}</button>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import {db, routes} from "../../../../../utilities/database";

    export default {
        name: "Pricing",
        components: {
            DatePicker
        },
        data() {
            return {
                vat_options: null,
            }
        },
        props: {
            value: {
                required: true
            }
        },
        methods: {
            notBeforeToday(date) {
                return new Date() > date;
            },
			finishPlan() {
				db.post(`/modules/blockz/plans`, {...this.value, }).then(res => {
          this.$toast.requestSuccess('post', 'Order')
					this.$router.push('/plans/create/finished')
				}).catch(error => {
          this.$toast.error('Failed to create order!')
        })
			}
			// validateFields() {
			// 	if (Object.values(this.value.prices[0]).some(v => !v)) {
			// 		Object.keys(this.value.prices[0]).forEach((fieldKey) => {
			// 			if (!this.value.prices[0][fieldKey]){
			// 				document.querySelector(`[name="${fieldKey}"]`).addClass('is-invalid')
			// 			}
			// 		});
			// 		this.$toast.error('Please fill in the empty fields!')
			// 		return false
			// 	}
			// 	return true
			// }
        },
		// beforeRouteLeave(to, from, next) {
		// 	if (this.validateFields()) {
		// 		next()
		// 	}
		// },
        async mounted() {
            await db.get('select-options/as-array/14').then(res => {
                this.vat_options = res.data
            })

			document.addEventListener('input', function (e) {
				e.target.classList.remove('is-invalid')
			})
        }
    }
</script>

<style lang="scss">
    .pricing-body {
        .parent-border {
            border-bottom: 1px solid;
        }

        .period-input {
            border: 1px solid #000;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .pricing-label {
            font-size: 16px;
        }

        .pricing-value {
            border: 1px solid;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .vat-dropdown {
            .v-select .vs__dropdown-toggle {
                height: 100%;
                border-color: #000;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: unset;
            }
        }
    }

    .pricing-footer {
        .btn-cancel {
            background: #fff;
            border-color: #b5c4d9;
            color: #000;

            &:hover {
                background: var(--secondary-btn-color);
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>