<template>
  <div class="container entity-container">
    <page-title :page-title="translate('Content generator', 'global')"/>

    <div class="card rounded bg-white">
      <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible['regenerate_content']}" @click="visible['regenerate_content'] = !visible['regenerate_content']">
        <h5>{{ translate('Regenerate HTML content', 'global') }}</h5>
        <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['regenerate_content'] === true}"></i>
      </div>
      <transition-expand>
        <div class="card-body" v-show="visible['regenerate_content']">
          <regenerate-html/>
        </div>
      </transition-expand>
    </div>

  </div>
</template>

<script>
import RegenerateHtml from "@/modules/blockz/views/tools/components/RegenerateHtml.vue"

export default {
  name: "ContentGenerator",
  components: { RegenerateHtml },
  data() {
    return {
      visible: {
        'regenerate_content': true,
      },
    }
  },
}
</script>