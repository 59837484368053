<template>
    <div>
        <div class="pb-3" v-if="plans && plans.meta">
            <page-title :page-title="translate('Plans', 'plans')" :borderBottom="false" :counter="plans.meta.total">
                <template v-slot:actions>
                    <router-link to="/plans/create/overview">
                        <button class="btn btn-primary rounded-pill">{{ translate('Add new plan', 'plans') }}</button>
                    </router-link>
                </template>
            </page-title>
        </div>
        <div class="card filter-nav d-flex flex-row mt-3">
            <ul class="nav nav-tabs scope-tabs px-3">
                <li class="nav-item pt-3" :class="{'active': selectedFilter == null}">
                    <a href="javascript:;"
					   class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
					   @click="loadPlans(null)">
                        {{ translate('All', 'plans') }}
						<span class="counter ml-4">{{ plans.meta.total }}</span>
                    </a>
                </li>
                <li class="nav-item d-flex justify-content-between align-items-center pt-3 px-2" v-for="(status, si) in filterByPeriod" :class="{'active':
                        selectedFilter == status.key}">
                    <a href="javascript:;" class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
                       @click="loadPlans({key: 'period', value: status.key, condition: '='})">
                        {{ translate(status.value, 'plans') }}
						<span class="counter ml-4">?</span>
                    </a>
                </li>
                <div class="d-flex justify-content-end align-items-center h-100 ml-auto">
                    <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                        <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                            {{ translate('{count} per page', 'global', { count: tableParams.per_page }) }}
                            <i class="fa fa-chevron-down"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                            <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi"
                            @click="setItemsPerPage(per_page.key)">
                                {{ per_page.value }}
                            </a>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
        <div>
            <div class="container">
                <div class="row" v-if="plans && !loading">
                    <div class="col-md-4" v-for="(plan, p) in plans.data" :key="p">
                        <div class="plan-container my-4 p-3 pb-5">
                            <div class="plan-header text-center pt-4">
                                <span class="plan-type font-weight-bold">{{ plan.slug }}</span>
                                <div class="plan-price d-flex justify-content-center align-items-center">
                                    <span class="plan-currency font-weight-bold pr-1">€</span>
                                    <span class="plan-amount font-weight-bold">{{ plan.price.price }}</span>
                                </div>
                                <div>
                                    <span v-if="plan.readable_options.period === 1">{{ translate('per month', 'plans') }}</span>
                                    <span v-if="plan.readable_options.period === 12">{{ translate('per year', 'plans') }}</span>
                                    <span v-if="plan.readable_options.period === 24">{{ translate('per two years', 'plans') }}</span>
                                    <span v-if="plan.readable_options.period === 36">{{ translate('per three years', 'plans') }}</span>
                                </div>
                                <span class="plan-extra-user">$5,00 {{ translate('extra user per month', 'plans') }}</span>
                            </div>
                            <div class="plan-details">
                                <div v-for="(limit, l) in plan.limits" :key="l"
                                     class="plan-single-detail mx-3 py-1 d-flex justify-content-between">
                                    <span>{{ limit.pivot.inventory_name }}</span><span>{{ limit.pivot.qty }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loading" class="d-flex justify-content-center m-5">
                    <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
                </div>
                <pagination :disabled="loading" :container-class="'d-flex justify-content-between'" :value="pagination"
                            v-if="pagination" @goToPage="goToPage"></pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {db, routes} from "../../../../utilities/database";
import debounce from 'debounce';
import {createEntityFormObjectNoTabs, createEntityFormObject, urlEncoder} from "../../../../utilities/helper";

export default {
    name: "Plans",
    data() {
        return {
            plans: [],
            loading: false,
            pagination: null,
            tableParams: {
                per_page: 5,
                page: 1
            },
            selectedFilter: null,
        }
    },
    methods: {
        goToPage(page) {
            this.tableParams.page = page
            this.loadPlans()
        },
        setItemsPerPage(per_page) {
            this.tableParams.per_page = per_page
            this.loadPlans()
        },
        loadPlans: debounce(async function (filters) {
            this.loading = true
            this.$store.state.system.isLoading = true

            let params = null
            if (filters) {
                params = {
                    'filter_criteria': {
                        'filter_groups': {
                            0: {
                                'filters': {
                                    0: filters
                                }
                            }
                        }
                    }
                }
            }

            if (filters && filters.key === 'period') {
                this.selectedFilter = filters.value
            } else {
                this.selectedFilter = null
            }

            let columns = {column: 'created_at', direction: 'desc', type: 'text'}

            let response = await
                db.get(`${routes.plans.index}?page=${this.tableParams.page}&perpage=${this.tableParams.per_page}&${urlEncoder(columns)}&${urlEncoder(params)}`)

            response.data.data.forEach(plan => {
                plan.readable_options = createEntityFormObjectNoTabs(plan.options, 'key')
                plan.limits.forEach(pivot => {
                    plan.limit_options = pivot
                })
                this.plans = response.data
            })

            this.loading = false
            this.$store.state.system.isLoading = false
        }, 700),
    },
    computed: {
        filterByPeriod() {
            return [
                {key: 1, value: 'month'},
                {key: 12, value: 'year'},
                {key: 24, value: 'two_years'},
                {key: 36, value: 'three_years'}
            ]
        },
        perPage() {
            return [
              { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
              { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
              { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
              { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
            ]
        }
    },
    mounted() {
        this.loadPlans()
    }
}
</script>

<style scoped lang="scss">
.nav-tabs {
    border-bottom: none;
}

.plan-counter {
    background-color: #DEDEDE;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-container {
    border: 1px solid #333;
    border-radius: 10px;

    .plan-header {
        span {
            color: #b5c4d9;
            font-size: 12px;
        }

        .plan-type {
            text-transform: uppercase;
        }

        .plan-price {
            .plan-currency {
                font-size: 16px;
                color: #000;
            }

            .plan-amount {
                font-size: 36px;
                color: #000;
            }
        }

        .plan-extra-user {
            color: #a0a0a0;
        }
    }

    .plan-details {
        .plan-single-detail {
            border-bottom: 1px solid #bfbfbf;

            .fa-check-circle {
                color: #79eab9;
            }
        }
    }
}
</style>
