<template>
    <div class="comment-section d-flex flex-column">
        <!-- <div class="single-comment-foreign">
            <div class="creater-info">
                <avatar upload="true" :avatar-image="authData.user.avatarUrl"></avatar>
                <span>
              <i class="fas fa-thumbs-up"></i>
            </span>
            </div>
            <div class="foreign-comment">
                <div class="d-flex justify-content-between p-2">
                    <span>
                        <strong>{{authData.user.first_name}}</strong> commented:
                    </span>
                    <span class="date-posted">Date: Feb 28 at 3:01 pm</span>
                </div>
                <p class="p-2 mb-0">comment_body</p>
            </div>
        </div> -->

        <div class="single-comment-mine" v-for="message in messages" :key="message.id">
            <div class="creater-info">
                 <avatar upload="true" :avatar-image="authData.user.avatarUrl"></avatar>
            </div>
            <div class="my-comment">
                <div class="d-flex justify-content-between p-2">
                    <span>
                        <strong>{{authData.user.first_name}}</strong> {{ translate('commented', 'project') }}:
                    </span>
                    <span class="date-posted">{{ translate('Date', 'project') }}: {{message.date}} {{ translate('at', 'project') }} {{message.times}}</span>
                </div>
                <p class="p-2 mb-0">{{message.text}}</p>
            </div>
        </div>
<!-- 
        <div class="single-comment-mine">
            <div class="creater-info">
                <span>Avatar</span>
            </div>
            <div class="my-comment">
                <div class="d-flex justify-content-between p-2">
                    <span>
                        <strong>You</strong> commented:
                    </span>
                    <span class="date-posted">Date: Feb 28 at 3:01 pm</span>
                </div>
                <p class="p-2 mb-0">comment_body</p>
            </div>
        </div> -->

       
            <CreateMessage :name="authData.user.first_name"/>        
       
    </div>
</template>

<script>
    import moment from "moment";
    import CreateMessage from "./CreateMessage";
    import { mapState } from "vuex";
    export default {
        name: "Chat",
        components: {CreateMessage},
        data() {
            return {
                messages: [
                    {
                        id:1,
                        // avatarUrl:
                        name: "Superadmin",
                        date:"Feb 28",
                        times: "3:01 pm",
                        text: "Hello"
                    }
                ]
                
            };
            },
        computed: {
            ...mapState('system', ['authData'])
        },


        
    }
</script>

<style scoped>

</style>
