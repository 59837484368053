<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Column', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
                label="Height (leave empty for auto)"
                :input-type="'number'"
                :suffix="'PX'"
                :has-responsive-styles="true"
                :placeholder="getPlaceholder('height', 'px')"
                :value="getStyle('height', 'px')"
                @input="setStyle('height', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Vertical align"
              :options="generalVerticalAlignmentOptions"
              :has-responsive-styles="true"
              :placeholder="getPlaceholder('justify-content')"
              :value="getStyle('justify-content')"
              @input="setStyle('justify-content', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Link', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
                label="Link"
                :value="getSetting('link')"
                @input="setSetting('link', $event)"
            />
          </div>
          <div class="col-md-12">
            <craft-tab-pills
                label="Link behavior"
                :value="getSetting('link_target')"
                @input="setSetting('link_target', $event)"
                :options="generalLinkTargetOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"
import { CraftInput, CraftSelect, CraftTabPills } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: 'ColumnSettings',
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, CraftStyleSettingsMixin],
  components: {
    CraftInput, CraftSelect, CraftTabPills,
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
input + i.icon-erp-search {
  height: 58px;
  line-height: 58px;
  top: 0;
  position: absolute;
  right: 0;
  width: 58px;
  text-align: center;
}
</style>