import CanvasStyles from './CanvasStyles'

import AccordionStyles from "./AccordionStyles"
import BannerStyles from "./BannerStyles"
import GalleryStyles from "./GalleryStyles"
import IconBoxStyles from "./IconBoxStyles"
import SeachBarStyles from "./SeachBarStyles"
import SocialMediaIconsStyles from "./SocialMediaIconsStyles"
import TabsStyles from "./TabsStyles"

export default {
  Canvas: CanvasStyles,

  AccordionElement: AccordionStyles,
  BannerElement: BannerStyles,
  GalleryElement: GalleryStyles,
  IconBoxElement: IconBoxStyles,
  SearchBarElement: SeachBarStyles,
  SocialMediaIconsElement: SocialMediaIconsStyles,
  TabsElement: TabsStyles,
}