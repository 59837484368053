<template>
  <div class="nav-item dropdown dropdown-styled dropdown-animate " data-toggle="hover">
    <a href="#" class="btn btn-primary btn-sm btn-add-shortcode" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ translate('Add tag', 'entities') }}
      <span class="btn-inner--icon"><i class="fa fa-chevron-down"></i></span>
    </a>
    <div class="dropdown-menu dropdown-styled dropdown-menu-md dropdown-menu-right dropdown-menu-arrow mr-0">
      <button class="dropdown-item" v-for="(shortcode, idx) in data" :key="idx" @click="shortcode_send(shortcode.key)">
        {{ translate(shortcode.value, 'shortcodes') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortcodesModal",
  props: {
    entity: {
      required: false,
    },
    shortcodes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    shortcodesRoute() {
      const includeId = this.entity === 'email-templates' && this.$route.params.id
      return `/modules/shortcodes/${this.entity}` + (includeId ? `/${this.$route.params.id}` : '')
    },
  },
  async created() {
    if (this.shortcodes && this.shortcodes.length > 0) {
      this.data = this.shortcodes
    } else {
      this.erp.ext.request.axiosInstance.get(this.shortcodesRoute).then(res => {
        this.data = res.data
      })
    }
  },
  methods:{
    shortcode_send(shortcode){
      this.$emit('shortcodeAppend', shortcode)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-add-shortcode {
  margin-bottom: 6px;
}
.dropdown-menu {
  max-height: 350px; /* same as --vs-dropdown-max-height */
  overflow-y: auto;
}
</style>