import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      drag: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
  },
}