<template>
    <div>
        <div class="d-flex justify-content-end">
            <router-link :to="`/webshop-customers/${$route.params.id}/addresses/create`" class="btn btn-primary rounded-pill">
              {{ translate('Add new {entity}', 'entities', { entity: translate('Address', 'entities').toLowerCase() }) }}
            </router-link>
        </div>
        <div class="main-table no-search mt-4">
            <div class="table-responsive">
                <table class="table align-items-center m-0">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ translate('First name', 'entities') }}</th>
                        <th scope="col">{{ translate('Last name', 'entities') }}</th>
                        <th scope="col">{{ translate('Street address', 'entities') }}</th>
                        <th scope="col">{{ translate('City', 'entities') }}</th>
                        <th scope="col">{{ translate('Country', 'entities') }}</th>
                        <th scope="col">{{ translate('State', 'entities') }}</th>
                        <th scope="col">{{ translate('Phone', 'entities') }}</th>
                        <th scope="col">{{ translate('Type', 'entities') }}</th>
                        <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                    </tr>
                    </thead>
                    <tbody v-if="value.address_book">
                    <tr class="tr-1" v-for="(address, addressI) in value.address_book">
                        <td>
                            <router-link :to="`/webshop-customers/${$route.params.id}/addresses/${address.id}`" class="table">
                                {{ address.client_first_name }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="`/webshop-customers/${$route.params.id}/addresses/${address.id}`" class="table">
                                {{ address.client_last_name }}
                            </router-link>
                        </td>
                        <td>{{ formatAddress(address) }}</td>
                        <td>{{ address.address_city }}</td>
                        <td>{{ address.address_country }}</td>
                        <td>{{ address.address_state }}</td>
                        <td>{{ address.client_phone }}</td>
                        <td>
                        <span v-if="address.is_for_billing == '1'">
                            {{ translate('Billing', 'entities') }}
                        </span>
                            <span v-else>
                            {{ translate('Shipping', 'entities') }}
                        </span>
                        </td>
                        <td class="text-right">
                            <cta>
                                <action-button class-icon="fa-pen"
                                               @click.native="$router.push(`/webshop-customers/${$route.params.id}/addresses/${address.id}`)"></action-button>
                                <action-button class-icon="fa-trash-alt"
                                               @click.native="deleteRecord(address.id)"></action-button>
                            </cta>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="bg-white text-center p-5" v-if="!value.address_book">
              {{ translate('No data', 'global') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomerAddresses",
    props: {
        value: {
            required: true
        },
    },
    methods: {
        async deleteRecord(id) {
            const confirmDelete = await this.$alert.confirmDelete()
            if(!confirmDelete.isConfirmed) return

            this.erp.ext.request.axiosInstance.delete(`/customers/addressbook/${id}`).then(res => {
                this.$emit('reload')
            }).catch(error => {
                this.$emit('reload')
                this.$toast.error(error.message)
            })
        },
        formatAddress(address){
          return (address.address_street_number ? address.address_street_number + ' ' : '') + address.address_address
        }
    }
}
</script>

<style scoped>

</style>
