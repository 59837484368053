<template>
  <div>
    <modal
      name="builder"
      :pivot-x="1"
      :pivot-y="0"
      transition="slide-fade"
      overlay-transition="wait"
      height="100%"
      width="90%"
      scrollable
      adaptive
    >
      <builder
        v-if="visible"
        v-model="value"
        class="footer-builder"
      />
    </modal>
    <a
      href="javascript:;"
      @click="triggerVisibility('builder')"
    >
      <img
        src="/uploads/defaults/page-builder.png"
        width="200px"
      >
    </a>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "PageBuilderField",
  mixins: [FormFieldMixin],
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    triggerVisibility(name) {
      this.$modal.show(name)
      this.visible = true
    },
  },
}
</script>

