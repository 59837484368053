<template>
  <intro-layout :authLoading="authLoading">
    <form method="POST" @submit.prevent="resetPassword">
        <h6 class="h3 font-weight-bold mb-5">{{ translate('Password reset', 'system') }}</h6>
        <div class="form-group mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <label class="form-control-label">{{ translate('New password', 'system') }}</label>
                </div>
            </div>
            <div class="position-relative">
                <input :type="!showPassword ? 'password' : 'text'" tabindex="2" v-model="password" name="password" class="form-control" :placeholder="translate('New password', 'system')">
                <span class="password" @click="showPassword = !showPassword"><i class="fas fa-eye"></i></span>
            </div>
        </div>
        <div class="form-group mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <label class="form-control-label">{{ translate('Confirm new password', 'system') }}</label>
                </div>
            </div>
            <div class="position-relative">
                <input :type="!showConfirmedPassword ? 'password' : 'text'" tabindex="2" v-model="confirmPassword" name="password" class="form-control" :placeholder="translate('Confirm new password', 'system')">
                <span class="password" @click="showConfirmedPassword = !showConfirmedPassword"><i class="fas fa-eye"></i></span>
            </div>
        </div>
        <div class="mt-4 text-right">
            <button :disabled="authLoading" type="submit" tabindex="3" class="btn btn-primary rounded-pill login-btn">
                <span class="btn-inner--text font-weight-bold">{{ translate('Change password', 'system') }}
                    <span class="btn-inner--icon"></span>
                </span>
            </button>
        </div>
    </form>
  </intro-layout>
</template>

<script>
    import {environment} from "@/utilities/helper";
    import {db, routes} from "@/utilities/database";
    import {mapActions} from 'vuex';
    import IntroLayout from "@/modules/system/views/IntroLayout";

    export default {
        name: "Login",
      components: {IntroLayout},
        data() {
            return {
                confirmPassword: '',
                password: '',
                authLoading: false,
                showPassword: false,
                showConfirmedPassword: false
            }
        },
        methods: {
            resetPassword() {
                window.localStorage.removeItem('company')
                this.authLoading = true
                let route = ''
                let data = {}
                if (this.$route.query.email) {
                    data.password = this.password
                    data.password_confirmation = this.confirmPassword
                    data.token = this.$route.query.token
                    data.email = this.$route.query.email
                    route = '/reset/password'
                } else {
                    data.password = this.password
                    data.password_confirmation = this.confirmPassword
                    data.token = this.$route.query.token
                    route = '/generated-password-change'
                }

                this.erp.ext.request.axiosInstance.post(route, data).
                then(res => {
                    this.authLoading = false
                    this.$toast.requestSuccess('post', 'New password') // todo put custom message
                    this.$router.push('/login')
                })
                .catch((error) => {
                  this.$alert.formattedError(error)
                  this.authLoading = false
                })
            },
        },
        mounted() {
            Array.from(document.querySelectorAll('.form-control')).forEach(elm => {
                elm.addEventListener('focus', function (e) {
                    e.target.parentNode.parentNode.classList.add('focused')
                })
                elm.addEventListener('blur', function (e) {
                    e.target.parentNode.parentNode.classList.remove('focused')
                })
            })
        },
        created() {
            console.log(this.$route.query.token)
        }
    }
</script>