<template>
    <div>
        <div class="table-responsive">
            <table class="table align-items-center system-text-primary">
                <thead>
                <tr>
                    <th scope="col">{{ translate('Name', 'entities') }}</th>
                    <th scope="col">{{ translate('Slug', 'entities') }}</th>
                    <th scope="col">{{ translate('Model', 'entities') }}</th>
                    <th scope="col">{{ translate('Created at', 'global') }}</th>
                    <th scope="col" class="text-right">{{ translate('table', 'global') }}</th>
                </tr>
                </thead>
                <tbody class="list">
                <tr v-for="(entity, entityIndex) in entities">
                    <td scope="row">
                        <a href="javascript:;" @click="edit(entity.id)" class="name mb-0 h6 text-sm system-text-primary">{{entity.name}}</a>
                    </td>
                    <td>{{entity.slug}}</td>
                    <td>{{entity.model}}</td>
                    <td>{{entity.created_at}}</td>
                    <td class="text-right">
                        <cta>
                            <action-button class-icon="fa-users-cog" url="javascript:;" @click.native="editEntityPermissions(entity)"/>
                            <action-button class-icon="fa-pen" url="javascript:;" @click.native="edit(entity.id)"/>
                            <!-- TODO NEW permissions v-if="entity.actions.update" -->

                            <action-button class-icon="fa-trash-alt" url="javascript:;" @click.native="remove(entity.id)"/>
                            <!-- TODO NEW permissions v-if="entity.actions.delete" -->
                        </cta>
                    </td>
                </tr>
                </tbody>
            </table>
            <pagination :disabled="loading" :container-class="'d-flex justify-content-between'" @goToPage="fetchPaginatedEntities" v-if="uPagination.meta"
                      v-model="uPagination.meta"></pagination>
        </div>

        <modal name="update_entity_permissions"
               :pivot-x="1"
               :pivot-y="0"
               transition="slide-fade"
               overlayTransition="wait"
               height="100%"
               width="85%"
               :adaptive="true">
            <entity-permissions v-if="entity" :title="title" v-model="entity.object_permissions"
                 @update="updateEntityPermissions" @close="$modal.hide('update_entity_permissions')"/>
        </modal>
    </div>

</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {busEvent} from "../../utilities/eventBus";

import EntityPermissions from "./EntityPermissions";
import {db, routes} from './../../utilities/database'

import {setPageTitle} from "@/utilities/helper";
import {environment} from "../../utilities/helper";

import CTA from '../../components/page/table/CTA'
import ActionButton from '../../components/page/table/ActionButtons'

export default {
    name: 'entities-table',
    components: {
      EntityPermissions, CTA, ActionButton
    },

    data() {
        return {
            actions: [],
            hasResults: true,
            page: 1,
            filters: {},  // create filters object,
            entity: null,
            title: ''
        }
    },

    methods: {
      ...mapActions('entities', ['fetchPaginatedEntities', 'filterEntities']),
      edit(id) {
          this.$router.push(`/entities/${id}`)
      },
      editEntityPermissions(entity) {
          this.title = entity.name + ' permissions'
          this.entity = entity
          this.$modal.show('update_entity_permissions')
      },
      updateEntityPermissions(permissions) {
          environment.request.post(routes.entities.updatePermissions.replace(':id', this.entity.id), {perm: permissions}).then(res => {
            this.entity.object_permissions = res.data.perm
            this.$modal.hide('update_entity_permissions')
            this.entity = null
            this.$toast.requestSuccess(false, 'Permissions')
          }).catch(err => {
            this.$toast.errorGeneral()
          })
      },
      remove(id) {
          environment.request.delete(routes.entities.delete.replace(':id', id), {}).then(res => {
              this.fetchPaginatedEntities(1)
              this.$toast.requestSuccess('delete', 'Entity')
          }).catch(error => {
              this.fetchPaginatedEntities(1)
              this.$toast.error(error.message)
          })
      }
    },
    computed: {
        ...mapGetters('entities', ['allEntities', 'uPagination']),
        ...mapState('entities', ['entities', 'loading'])
    },
    async created() {
        await this.fetchPaginatedEntities(1);
        busEvent.$on('goToPage', (i) => {
            this.filters.page = i
            this.filterEntities(this.filters)
        })

        busEvent.$on('filterItems', data => {
            data.page = this.page
            this.filters = data
            this.filterEntities(data)
        })
    },

}
</script>

