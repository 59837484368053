<template>
    <div>
        <div class="extension-body py-4 b-b-pr-gr">
            <div class="d-flex flex-column">
                <h5 class="text-center font-weight-bold py-2">{{ translate('Total of', 'plans') }} <span
					class="extensions-length">{{value.extensions.length}} {{ translate('extentions', 'plans') }}</span>
                    {{ translate('added to package', 'plans') }}</h5>
                <div class="row">
                    <div class="col-md-4" v-for="(extension, exI) in extensions" :key="exI">
                        <div class="extension d-flex mb-3">
                            <div class="extension-checkbox mx-4 my-3">
                                <input v-model="value.extensions" :value="extension.id" :id="extension.id" type="checkbox"/>
                                <label :for="extension.id"></label>
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                                <span class="extension-title font-weight-bold">{{ extension.name }}</span>
                                <span class="extension-price text-secondary-color">{{ translate('Price', 'plans') }}: {{ extension.price.price }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-extension-footer d-flex justify-content-between py-4">
            <router-link to="basics">
                <button class="btn btn-white rounded-pill">{{ translate('Go back', 'global') }}</button>
            </router-link>
            <span class="step-count">{{ translate('steps 3 of 5', 'plans') }}</span>
            <router-link to="setup">
                <button class="btn btn-primary rounded-pill">{{ translate('Continue', 'global') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {db} from "../../../../../utilities/database";

    export default {
        name: "AddExtensions",
        props: {
            value: {
                required: true
            }
        },
        data() {
            return {
                extensions: []
            }
        },
        mounted() {
			let uniqueExt = []
            this.value.apps.forEach(async (module) => {
                let data = await db.get(`/modules/blockz/app-extensions/extensions/${module}`);
				data.data.data.forEach(ext => {
					if (!uniqueExt.some(item => item.id === ext.id)) {
						uniqueExt.push(ext)
					}
					this.extensions = uniqueExt
				})
			})
        }
    }
</script>

<style scoped lang="scss">
    .text-secondary-color {
        color: #b5c4d9;
    }

    .extension-body {
        .extensions-length {
            color: $primary;
        }

        .extension {
            border: 1px solid;
            border-radius: 5px;

            .extension-title {
                font-size: 16px;
            }

            .extension-price {
                font-size: 12px;
            }
        }

        .extension-checkbox {
            position: relative;
            padding: 20px 0 20px 40px;
            text-align: left;

            label {
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    border-radius: 50%;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    margin: -15px 0 0;
                    background: #f7f7f7;
                    box-shadow: 0 0 1px grey;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    border-radius: 50%;
                    left: 5px;
                    width: 20px;
                    height: 20px;
                    margin: -10px 0 0;
                    opacity: 0;
                    background: $primary;
                    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
                }
            }

            input {
                position: absolute;
                top: 0;
                left: -9999px;
                visibility: hidden;

                &:checked + label {
                    &:before {
                    }

                    &:after {
                        transform: translate3d(0, 0, 0);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .add-extension-footer {
        .btn-cancel {
            background: #fff;
            border-color: #b5c4d9;
            color: #000;
            &:hover {
                background: var(--secondary-btn-color);
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>
