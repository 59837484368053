import store from './../../store/index'
import { scopeSystemByCompany, scopeSystemByWebsite } from '../../utilities/helper'
import { erp } from "@/modules/erp_framework"

export default function redirectIfAuthenticated(router){
  router.beforeEach(async function(to, from, next) {
    let isAuthenticated = store.getters['system/isAuthenticated']

    /**
     * Checks if there's a token and the next page name is none of the following
     */

    // logged  && next is login
    if ((isAuthenticated) && (to.name === 'login')) {
      return next('/')
    }

    if (!isAuthenticated && (to.name === 'password-recovery' || to.name === 'password-reset')) {
      return next()
    }

    // not logged
    if (!isAuthenticated && (to.name !== 'login')) {
      return next('/login')
    }

    let localCompany = window.localStorage.getItem('company')

    if((isAuthenticated) && !localCompany){

      // If there is no selected company (localCompany) and user has 1 company only, preselect it
      // let authCompanies = store.state.system.companies
      // if(authCompanies.length == 1){
      //   scopeSystemByCompany(authCompanies[0])
      //   if (from.fullPath === '/login') {
      //     if (store.state.system.authData && store.state.system.authData.environments.includes('blockz')) {
      //       return next('/blockz-dashboard')
      //     } else if (store.state.system.authData && store.state.system.authData.environments.includes('sitecart')) {
      //       return next('/sitecart-dashboard')
      //     } else {
      //       next('/')
      //     }
      //   }
      //
      //   return next()
      // }

      // else choose company
      if(to.name !== 'select-company' && to.name !== 'logout'){
        return next('/select-company')
      }
    }

    return next()
  })
}
