import Errors from "./Errors"
import { environment } from "./helper"

class Form {
  /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
  constructor(data) {
    this.originalData = data

    for (let field in data) {
      this[field] = data[field]
    }

    this.errors = new Errors()
  }


  /**
     * Fetch all relevant data for the form.
     */
  data() {
    let data = {}

    for (let property in this.originalData) {
      data[property] = this[property]
    }

    return data
  }


  /**
     * Reset the form fields.
     */
  reset() {
    for (let field in this.originalData) {
      if(Array.isArray(this[field])){
        this[field]=[]
      }
      if (((typeof this[field]) == 'string') || ((typeof this[field]) == 'number')) {
        this[field] = ''
      } else {
        this.resetObject(this[field])
      }
    }
    this.errors.clear()
  }

  resetObject(obj) {
    let proprsToClear = ['r', 'w', 'd', 'x','n']

    for (var name in obj) {
      if (obj.hasOwnProperty(name)) {
        if (proprsToClear.includes(name)) {
          obj[name] = {}
        } else if (((typeof obj[name]) == 'string') || ((typeof obj[name]) == 'number')) {
          obj[name] = ''
        } else {
          this.resetObject(obj[name])
        }
      }
    }

  }


  /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
  post(url) {
    return this.submit('post', url)
  }


  /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
  put(url) {
    return this.submit('put', url)
  }


  /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
  patch(url) {
    return this.submit('patch', url)
  }


  /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
  delete(url) {
    return this.submit('delete', url)
  }


  /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {object} data
     */
  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      environment.request[requestType](url, data || this.data())
        .then(response => {
          this.onSuccess(response.data)
          resolve(response.data)
        })
        .catch(error => {
          this.onFail(error.response.data)
          reject(error.response.data)
        })
    })
  }


  /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
  onSuccess(data) {
    this.reset()
  }


  /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
  onFail(errors) {
    this.errors.record(errors.errors)
  }
}


export default Form
