<template>
  <codemirror
    :value="value"
    :options="cmOptions"
    @input="$emit('input', $event)"
  />
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/css/css'
import 'codemirror/mode/xml/xml'
import 'codemirror/theme/oceanic-next.css'
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/search/search'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/jump-to-line'


export default {
  name: "HtmlEditorField",
  components: {
    codemirror,
  },
  mixins: [FormFieldMixin],
  computed: {
    cmOptions() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/html',
        theme: 'oceanic-next',
        search: true,
        lineWrapping: true,
        readOnly: this.isReadonly,
      }
    },
  },
}
</script>

