<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-styling mb-3 bg-white">
        <div class="_title justify-content-between">
          <h6 class="font-weight-bold">{{ translate(label, 'orders') }}</h6>
          <slot name="actions"></slot>
        </div>
        <div class="_body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCard",
  props: {
    label: {
      required: true,
    },
  },
}
</script>
