<template>
    <div class="timeline-container col-styling bg-white mb-3" v-if="value">
        <span class="_title">{{ translate('Timeline', 'orders') }}</span>
        <div class="_body timeline">
            <div class="list-group list-group-flush list-group-activity">
                <div class="list-group-item" v-for="(timeline, tlI) in value">
                    <div class="row">
                        <div class="col-auto">
                            <div class="timeline-circle rounded-circle"></div>
                        </div>
                        <div class="col ml-n2">
                            <span class=" h-100">
                                <b>{{ formatDate(timeline.created_at, null, 'DD MMM YY - HH:mm') }}</b>
                                <p>{{ timeline.info }}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Timeline",
    props: {
        value: {
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.timeline-container {
    ._body {
        &.timeline {
            overflow: auto;
            //position: absolute;
            //height: calc(100% - 42px);
            right: 0;
            padding: 0 1.5rem;

            @media (max-width: 991px){
                position: static;
                max-height: 250px;
            }

            .list-group-activity {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-bottom: 0;
                border-radius: .375rem;
                max-height: 300px;

                .list-group-item {
                    position: relative;
                    display: block;
                    padding: 1rem 0;
                    border: 0;
                    background-color: transparent;

                    &:not(:last-child)::before {
                      content: '';
                      position: absolute;
                      top: 1rem;
                      left: 1.25rem;
                      height: 100%;
                      border-left: 1px solid #ccc;
                    }

                    .timeline-circle {
                        width: 2.5rem;
                        height: 2.5rem;
                        position: relative;
                        background-color: #ccc;
                    }
                }
            }
        }
    }
}

</style>
