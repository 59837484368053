<template>
    <div>
        <h5 class="node-styling--title" @click="visible.main = !visible.main">
            {{ translate('Main settings', 'page-builder') }}
            <i
                class="fa ml-auto fa-chevron-down chevron"
                :class="{'chevron-rotate': visible.main === true}"
            ></i>
        </h5>
        <transition-expand>
        <div v-show="visible.main">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ translate('Alignment', 'page-builder') }}</label>
                        <v-select
                            v-model="settings.alignment"
                            :options="['right', 'center', 'left']"
                            style="min-width: 120px;"
                        />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ translate('Number of items displayed', 'page-builder') }}</label>
                        <v-select :options="['1', '2', '3', '4']" :clearable="false"
                                  v-model.number="settings.items"></v-select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show avatar', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="avatar"
                                    id="avatar"
                                    class="custom-control-input"
                                    v-model="settings.avatar"
                                />
                                <label for="avatar" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show names', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="names"
                                    id="names"
                                    class="custom-control-input"
                                    v-model="settings.names"
                                />
                                <label for="names" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show company', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="company"
                                    id="company"
                                    class="custom-control-input"
                                    v-model="settings.company"
                                />
                                <label for="company" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show subject', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="subject"
                                    id="subject"
                                    class="custom-control-input"
                                    v-model="settings.subject"
                                />
                                <label for="subject" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show description', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="description"
                                    id="description"
                                    class="custom-control-input"
                                    v-model="settings.description"
                                />
                                <label for="description" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show stars', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="stars"
                                    id="stars"
                                    class="custom-control-input"
                                    v-model="settings.stars"
                                />
                                <label for="stars" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Auto loop', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="auto_loop"
                                    id="auto_loop"
                                    class="custom-control-input"
                                    v-model="settings.auto_loop"
                                />
                                <label for="auto_loop" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="d-flex justify-content-between py-2">
                            <label>{{ translate('Show pagination', 'page-builder') }}</label>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    name="usePagination"
                                    id="usePagination"
                                    class="custom-control-input"
                                    v-model="settings.usePagination"
                                />
                                <label for="usePagination" class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition-expand>

        <h5 class="node-styling--title" @click="visible.border = !visible.border">
            {{ translate('Border', 'page-builder') }}
            <i
                class="fa ml-auto fa-chevron-down chevron"
                :class="{'chevron-rotate': visible.border === true}"
            ></i>
        </h5>
        <transition-expand>
            <div v-show="visible.border">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Border color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="visible.borderColor = !visible.borderColor">
                                    <span :style="{'background-color': settings.borderColor}"></span>
                                    <input class="mx-2" :value="settings.borderColor" />
                                </span>
                            <color
                                v-click-outside="visible.borderColor"
                                v-if="visible.borderColor"
                                :value="settings.borderColor"
                                @input="style => settings.borderColor =  style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Border width', 'page-builder') }}:</label>
                            <input
                                type="number"
                                class="form-control form-control-sm"
                                @input="style => settings.borderWidth = style.target.value + 'px'"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Border style', 'page-builder') }}:</label>
                            <v-select
                                :options="['solid', 'dotted', 'dashed', 'double', 'groove', 'ridge']"
                                style="min-width: 120px;"
                                :clearable="false"
                                @input="style => settings.borderStyle = style"
                            ></v-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Border radius', 'page-builder') }}:</label>
                            <input
                                type="number"
                                class="form-control form-control-sm"
                                @input="style => settings.borderRadius = style.target.value + 'px'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <h5 class="node-styling--title" @click="visible.typography = !visible.typography">
            {{ translate('Typography', 'page-builder') }}
            <i
                class="fa ml-auto fa-chevron-down chevron"
                :class="{'chevron-rotate': visible.typography === true}"
            ></i>
        </h5>
        <transition-expand>
            <div v-show="visible.typography">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Title type', 'page-builder') }}</label>
                            <v-select v-model="settings.typography.title_h" :options="['h1', 'h2', 'h3', 'h4', 'h5', 'h6']"></v-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Description font size', 'page-builder') }}</label>
                            <input type="number" v-model="settings.typography.desc_size" class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Stars size', 'page-builder') }}</label>
                            <v-select v-model="settings.typography.star_size" :options="stars" label="value" :reduce="value => value.key"></v-select>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

<!--        <h5 class="node-styling&#45;&#45;title" @click="visible.stars = !visible.stars">-->
<!--            Stars-->
<!--            <i-->
<!--                class="fa ml-auto fa-chevron-down chevron"-->
<!--                :class="{'chevron-rotate': visible.stars === true}"-->
<!--            ></i>-->
<!--        </h5>-->
<!--        <transition-expand>-->
<!--            <div v-show="visible.stars">-->
<!--            </div>-->
<!--        </transition-expand>-->
    </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import TransitionExpand from '../../TransitionExpand'
import { Chrome } from 'vue-color'

export default {
    name: 'TestimonialsSettings',
    mixins: [ComponentSettingsMixin],
    components: {
        TransitionExpand, color: Chrome,
    },
    data() {
        return {
            settings: {
                items: 3,
                alignment: 'left',
                avatar: true,
                stars: true,
                subject: true,
                description: true,
                company: true,
                names: true,
                auto_loop: false,
                usePagination: true,
                borderStyle: 'solid',
                borderWidth: '1',
                borderColor: '#cccccc',
                borderRadius: 0,
                typography: {
                    title_h: 'h6',
                    desc_size: 14,
                    star_size: 14
                },
            },
            visible: {
                main: true,
                typography: false,
                stars: false,
                border: false,
                borderColor: false
            }
        }
    },
    computed: {
        stars() {
            return [
                {key: 14, value: 'Small'},
                {key: 16, value: 'Medium'},
                {key: 18, value: 'Large'},
                {key: 20, value: 'Extra Large'},
            ]
        }
    },
}
</script>