<template>
  <div>
    <element-settings-content-tabs :key="renderKey">
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-select
              :label="'Form type'"
              :options="formTypes"
              :value="formSettings.form.type"
              @input="changeFormType($event)"
              :clearable="false"
              :field-props="{ permission: formId ? 'r' : 'w' }"
            />
            <craft-input
              :label="'Form name'"
              :value="form.name"
              @input="form.name = $event"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Form', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <div v-if="formSettings.form.type === 'contact'" class="dropdown dropdown-animate" data-toggle="hover">
              <a href="javascript:;" role="button" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false">
                <span class="add-new-field"><i class="fa icon-erp-add mr-2"></i>{{'Add field' | translate('page-builder')}}</span>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-arrow mr-0">
                <div class="menu-dropdown-items">
                  <div class="d-flex" v-for="(fieldType, fieldTypeKey) in fieldTypes">
                    <div class="dropdown-item btn" @click.prevent="addField(fieldTypeKey)">
                      <span><i class="d-inline-block mr-1" :class="fieldType.icon"></i> {{fieldType.label}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <draggable
              v-model="form.default_properties"
              @start="drag=true"
              @end="dragEnd"
              :draggable="'.draggable-item'">
              <div v-for="(field, fieldId) in form.default_properties" :key="fieldId" class='card draggable-item' :class="'status-'+field.attributes.status">
                <h6 class="card-head d-flex align-items-center" @click="toggleVisibility(fieldId)">
                  <i class="fa icon-erp-drag-and-drop"></i>

                  <span>{{fieldTitle(field)}}</span>
                  <i
                    class="fa ml-auto fa-chevron-down chevron"
                    :class="{'chevron-rotate': visible[fieldId]}"
                  ></i>
                </h6>

                <transition-expand>
                  <div v-show="visible[fieldId]">
                    <div class="card-body pt-0" :class="{'snippets-holder' : visible[fieldId]}">
                      <form-field :value="field" :fieldId="fieldId" :form-type="formSettings.form.type "@removeField="e => removeField(e)"/>
                    </div>
                  </div>
                </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Button', 'page-builder')">
        <row class="row">
          <div class="col-12">
            <craft-input label="Button text"
                         v-model="formSettings['button']['text']"
            />
            <craft-tab-pills label="Button Alignment"
                             v-model="formSettings['button']['text-align']"
                             :options="buttonAlignmentOptions"
            />
            <craft-tab-pills label="Button size"
                             v-model="formSettings['button']['width']"
                             :options="buttonSizeOptions"
            />
            <craft-tab-pills label="Button behavior"
                             v-model="formSettings['form']['button_behavior']"
                             :options="buttonBehaviorOptions"
            />
            <craft-textarea v-if="formSettings['form']['button_behavior'] ===  'message'"
                            label="Message"
                            :placeholder="messagePlaceholder"
                            :field-props="{errors: errors.button ? errors.button.thankyou_message : false}"
                            v-model="formSettings['button']['thankyou_message']"
            />
            <craft-input v-else-if="formSettings['form']['button_behavior'] ===  'link'"
                         label="Link"
                         :field-props="{errors: errors.redirect ? errors.redirect.redirect_link : false}"
                         v-model="formSettings['redirect']['redirect_link']"
            />
          </div>
        </row>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')" :has-padding="false">
        <element-settings-content-tabs>
          <element-settings-content-tab :selected="true" :name="translate('Form', 'page-builder')">
            <div class="row">
              <div class="col-12">
                <craft-tab-pills label="Form layout"
                                 v-model="formSettings['form']['form_layout']"
                                 :options="layoutOptions"
                />
                <div class="row">
                  <div class="col-md-6">
                    <craft-input label="Label font size"
                                 input-type="number"
                                 suffix="PX"
                                 :value="formSettings['label']['font-size'].split('').slice(0, -2).join('')"
                                 @input="value => formSettings['label']['font-size'] = value+'px'"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Label font color"
                                        v-model="formSettings['label']['color']"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input label="Field font size"
                                 input-type="number"
                                 suffix="PX"
                                 :value="formSettings['field']['font-size'].split('').slice(0, -2).join('')"
                                 @input="value => formSettings['field']['font-size'] = value+'px'"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Field font color"
                                        v-model="formSettings['field']['color']"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Field background color"
                                        v-model="formSettings['field']['background-color']"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input label="Field border width"
                                 input-type="number"
                                 suffix="PX"
                                 :value="formSettings['field']['border-width'].split('').slice(0, -2).join('')"
                                 @input="value => formSettings['field']['border-width'] = value+'px'"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Field border color"
                                        v-model="formSettings['field']['border-color']"
                    />
                  </div>

                </div>
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Button', 'page-builder')">
            <div class="row">
              <div class="col-12">
                <craft-tab-pills label="Button layout"
                                 v-model="formSettings['form']['button_layout']"
                                 :options="layoutOptions"
                />
                <div class="row">
                  <div class="col-md-6">
                    <craft-input label="Font size"
                                 input-type="number"
                                 suffix="PX"
                                 :value="formSettings['field']['border-width'].split('').slice(0, -2).join('')"
                                 @input="value => formSettings['button']['font-size'] = value+'px'"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Font color"
                                        v-model="formSettings['button']['color']"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Background color"
                                        v-model="formSettings['button']['background-color']"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input label="Border width"
                                 input-type="number"
                                 suffix="PX"
                                 :value="formSettings['field']['border-width'].split('').slice(0, -2).join('')"
                                 @input="value => formSettings['button']['border-width'] = value+'px'"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker label="Border color"
                                        v-model="formSettings['button']['border-color']"
                    />
                  </div>

                </div>
              </div>
            </div>
          </element-settings-content-tab>
        </element-settings-content-tabs>
      </element-settings-content-tab>

      <element-settings-content-tab v-if="formSettings.form.type === 'contact'" :name="translate('Receivers', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-select label="Email receivers"
                          :options="[]"
                          :value="formSettings['email']['receivers'] ? JSON.parse(formSettings['email']['receivers']) : []"
                          @input="ev => formSettings['email']['receivers'] = JSON.stringify(ev)"
                          :clearable="false"
                          :taggable="true"
                          :multiple="true"
                          :reduce-data="false"
            >
              <template v-slot:no-options="{ search, searching }">
                {{ translate('Type an email and hit enter.', 'page-builder') }}
              </template>
            </craft-select>

            <!--
              <v-select multiple :options="users" label="email" taggable push-tags
              :reduce="user => user.email"
              @input="ev => formSettings['email']['receivers'] = JSON.stringify(ev)"
              :value="formSettings['email']['receivers'] ? JSON.parse(formSettings['email']['receivers']) : []"
              />
            -->
            <craft-toggle label="Enable auto reply"
                          id="enableAutoreply"
                          :value="parseInt(formSettings['email']['autoreply_enabled'])"
                          @input="formSettings['email']['autoreply_enabled'] = $event"
            />
          </div>

          <div class="col-12">
            <transition name="fade">
              <div v-show="parseInt(formSettings['email']['autoreply_enabled']) === 1">
                <craft-textarea label="Auto reply text"
                                :field-props="{errors: errors.email ? errors.email.autoreply_text : false}"
                                v-model="formSettings['email']['autoreply_text']"
                />
              </div>
            </transition>
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>

    <div class="text-right my-4">
      <!-- <a @click="$emit('closeSettingsPopup')" class="btn btn-cancel rounded-pill mr-2">{{ translate('Cancel', 'page-builder') }}</a> -->
      <button @click.prevent="saveForm" class="btn btn-primary rounded-pill">{{ translate('Save changes', 'global') }}</button>
    </div>

  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ComponentWithItemsMixin from "@/modules/pagebuilder/mixins/ComponentWithItemsMixin"

import TransitionExpand from '../../TransitionExpand.vue'
import { CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextarea, CraftToggle } from "@/modules/pagebuilder/components/craft-inputs"
import uniqid from "uniqid"
import draggable from 'vuedraggable'
import FormField from "./components/FormField.vue"

export default {
  inject: ['editor', 'node'],
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, ComponentWithItemsMixin],
  components: {
    FormField,
    CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextarea, CraftToggle,
    TransitionExpand,
    draggable,
  },
  data() {
    return {
      renderKey: 0,
      drag: false,
      form: {
        name: '',
        method: 'POST',
        slug: '',
        enctype: null,
        action: null,
        company_id: this.$store.state.system.company.key,
        actions: {
          create: true,
          update: true,
          delete: true,
          locked: false,
        },
        settings: [],
        default_properties: [],
      },
      formSettings: {
        form: {
          'type': 'contact',
          'button_behavior': 'message',
          'form_layout': '1rem',
          'button_layout': '1rem',
        },
        button: {
          'text': '',
          'text-align': 'left',
          'width': '25%',
          'thankyou_message': '',
          'font-size': '',
          'color': '',
          'background-color': '',
          'border-width': '',
          'border-color': '',
        },
        label: {
          'font-size': '',
          'color': '',
        },
        field: {
          'font-size': '',
          'color': '',
          'background-color': '',
          'border-width': '',
          'border-color': '',
        },
        redirect: {
          'redirect_link': '',
        },
        email: {
          'receivers': '',
          'autoreply_enabled': 0,
          'autoreply_text': '',
        },
      },
      errors: {},
      // users: [],
      formId: null,
      fieldTypes: {
        text: {
          label: 'Text', // Input field
          icon: 'icon-erp-text-block',
        },
        email: {
          label: 'Email',
          icon: 'icon-erp-email',
        },
        tel: {
          label: 'Phone number',
          icon: 'icon-erp-phone',
        },
        date: {
          label: 'Date',
          icon: 'icon-erp-calendar',
        },
        textarea: {
          label: 'Textarea',
          icon: 'icon-erp-add-text',
        },
        select: {
          label: 'Dropdown',
          icon: 'icon-erp-chevron icon-erp-rotate-90',
        },
        // checkbox: {
        //   label: 'Checkbox',
        //   icon: 'icon-erp-checkbox'
        // },
        file_picker: {
          label: 'File picker',
          icon: 'icon-erp-files-and-folders',
        },
      },
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "nav",
      }
    },
    // if you need it add the settings
    // formTemplate() {
    //     return (
    //         this.formSettings['email']['form_template'] ||
    //         `From: ${this.formSettings['email']['from']}
    //         \nSubject: ${this.formSettings['email']['subject']}
    //         \n\nMessage area:\n`
    //     )
    // },
    formTypes() {
      return [
        { key: 'contact', label: 'Contact Form' },
        { key: 'newsletter', label: 'Newsletter' },
      ]
    },
    // TODO Refactor - #15313854
    buttonAlignmentOptions() {
      return [
        { key: 'left', label: 'Left' },
        { key: 'center', label: 'Center' },
        { key: 'right', label: 'Right' },
      ]
    },
    // END TODO
    buttonSizeOptions() {
      return [
        { key: '25%', label: 'Normal' },
        { key: '100%', label: 'Full width'} ,
      ]
    },
    buttonBehaviorOptions() {
      return [
        { key: 'message', label: 'Thank you message' },
        { key: 'link', label: 'Link to page'},
      ]
    },
    // form & button layout options
    layoutOptions() {
      return [
        { key: '1rem', label: 'Rounded' },
        { key: '0', label: 'Square' },
        { key: '3rem', label: 'Round' },
      ]
    },
    messagePlaceholder(){
      return this.formSettings.form.type === 'contact' ? 'Thank you for your message' : 'Thank you for your newsletter sign up'
    },
    enctypeComputed(){
      if(this.form.default_properties.filter(field => field.type === 'file_picker').length > 0){
        return 'multipart/form-data'
      } else {
        return 'application/x-www-form-urlencoded'
      }
    },
  },
  async created() {
    this.formId = this.getSetting('id')

    if (this.formId) {
      const { data: formData } = await this.erp.ext.request.axiosInstance.get(`modules/forms/${this.formId}`)
      this.form = { ... this.form, ...formData.data }

      this.form.default_properties.forEach((field, i) => {
        //this.visible[i] = false todo
        let attributes = {}
        field.attributes.forEach(f => {
          attributes = { ...attributes, [f.key]: f.value }
        })
        field.attributes = attributes
      })

      this.form.settings.forEach(setting => {
        this.formSettings[setting.group][setting.key] = setting.value
      })
    }
  },
  methods: {
    dragStart() {
      this.drag = true
    },
    dragEnd() {
      this.drag = false
      this.form.default_properties.forEach((field,i) => {
        field.position = i
      })
    },
    fieldTitle(field){
      if(field.label !== ''){
        return field.label
      }
      let readableType = this.fieldTypes[field.type]
      let type_fields = this.form.default_properties.filter(form_field => form_field.type === field.type && form_field.label === '')
      let order = type_fields.findIndex(type_field => type_field.position === field.position) + 1
      let placeholder = order < 2 ? readableType.label : readableType.label + " " + order
      return placeholder
    },
    changeFormType(event) {
      this.$set(this.formSettings.form, 'type', event)
      this.resetFields()
      if(event === 'newsletter') {
        this.generateNewsletterFields()
      }

      this.renderKey++
    },
    setFormSetting(group, key, value) {
      const settingFound = this.form.settings.find(
        setting => setting.group === group && setting.key === key
      )

      if (settingFound) {
        settingFound.value = value
      } else {
        this.form.settings.push({ group, key, value })
      }
      this.$forceUpdate()
    },
    validateFields(){
      this.errors = {}

      if(this.formSettings['form']['button_behavior'] === 'message'){
        if(this.formSettings['button']['thankyou_message'].length < 1){
          this.errors.button = {}
          this.errors.button.thankyou_message = ['This field is required']
        }
      } else if(this.formSettings['form']['button_behavior'] === 'link'){
        if(this.formSettings['redirect']['redirect_link'].length < 1){
          this.errors.redirect = {}
          this.errors.redirect.redirect_link = ['This field is required']
        }
      }

      if(parseInt(this.formSettings['email']['autoreply_enabled']) === 1){
        if(this.formSettings['email']['autoreply_text'].length < 1){
          this.errors.email = {}
          this.errors.email.autoreply_text = ['This field is required']
        }
      }
    },
    async saveForm() {
      let res

      this.form.default_properties.forEach((field,i) => {
        if(field.attributes.placeholder === ''){
          delete field.attributes.placeholder;
        }
      })

      // form settings covert to array
      for (const [groupKey, group] of Object.entries(this.formSettings)) {
        for (const [key, value] of Object.entries(group)) {
          this.setFormSetting(groupKey, key, value)
        }
      }

      // hot fix settings without values
      this.form.settings.forEach((setting,i) => {
        if(typeof setting.value === 'undefined'){
          setting.value = ''
        }
      })

      this.form.enctype = this.enctypeComputed

      this.validateFields()
      if(Object.keys(this.errors).length > 0){
        return this.$toast.error('There are errors in the form')
      }

      if(this.formId) {
        res = await this.erp.ext.request.axiosInstance.put(`modules/forms/${this.formId}`, this.form).catch(error => this.$alert.formattedError(error))
      } else {
        this.form.slug = `${this.form.name.toLowerCase().replaceAll(' ', '-')}-${uniqid()}`
        res = await this.erp.ext.request.axiosInstance.post('modules/forms',{ ...this.form }).catch(error => this.$alert.formattedError(error))
      }

      if(res) {
        this.formId = res.data.data.id
        this.setSetting('id', this.formId)
        // todo refresh
        this.$toast.requestSuccess(false, 'Form')
      }
    },
    addField(fieldType, options = {}) {
      let newField =  {
        label: options?.label || '',
        name: options?.name || '',
        type: fieldType,
        position: this.form.default_properties.length,
        width: '12',
        attributes: {
          placeholder: '',
          required: 0,
          status: 1,
          // field_size: '100%' not used
        },
      }

      if (['multieselect', 'select'].includes(fieldType)) {
        newField.options = []
      }

      if(this.formId) {
        newField = { ...newField, form_id: this.formId }
      }
      this.form.default_properties.push(newField)
      // this.visible[this.visible.length] = true uncomment if you want to expand new fields by default
      this.$forceUpdate()
    },
    removeField(index) {
      this.form.default_properties.splice(index,1)
    },

    resetFields(){
      this.form.default_properties = []
    },
    generateNewsletterFields(){
      this.addField('text', {
        name: 'name',
        label: 'Name',
      })
      this.addField('text', {
        name: 'surname',
        label: 'Surname',
      })
      this.addField('email', {
        name: 'email',
        label: 'Email',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  &.status-0 {
    .card-head {
      opacity: .4;
    }
  }
  border-radius: 0;
  border-top: 1px solid $border-color;
  margin: 0;
  background: #fff; /* required for the drag and drop */
  .card-head {
    span {
      font-weight: bold;
      margin-left: 20px;
    }
  }
}
.card:last-child {
  border-bottom: 1px solid $border-color;
}
.add-new-field {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin-left: 1.5rem; /* same as .card */
  margin-bottom: 1.25rem; /* same as .card */
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

 //.btn-connect {
 //  display: inline-block;
 //  padding: 10px 32px;
 //  border-width: 2px;
 //  border-radius: 8px;
 //  background-color: white;
 //  border-style: solid;
 //  border-color: #ff004f;
 //  color: #ff004f;
 //}
</style>
