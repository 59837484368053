<template>
    <div v-if="data" class="font-settings-container">
        <div class="row font-settings">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Letter type', 'entities') }}</label>
                    <v-select :options="fonts" label="family" class="select2-form input-group-sm w-100"
                              :placeholder="translate('Letter type', 'entities')"
                              v-model="data.letterType" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Back up letter type', 'entities') }}</label>
                    <v-select :options="fonts" label="family" class="select2-form input-group-sm w-100"
                              :placeholder="translate('Back up letter type', 'entities')"
                              v-model="data.backUpLetterType" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font weight', 'entities') }}</label>
                    <v-select
                        :options="['100', '200', '300', '400', '500', '600', '700', '800', '900']"
                        :placeholder="translate('Font weight', 'entities')"
                        class="select2-form input-group-sm w-100" v-model="data.fontWeight" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font style', 'entities') }}</label>
                    <v-select
                        :options="['normal', 'italic']"
                        :placeholder="translate('Font style', 'entities')"
                        class="select2-form input-group-sm w-100" v-model="data.fontStyle" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Text alignment', 'entities') }}</label>
                    <v-select :options="['left', 'center', 'right']" class="select2-form input-group-sm w-100"
                              :placeholder="translate('Text alignment', 'entities')"
                              v-model="data.textAlign" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Font size', 'entities') }}</label>
                    <div class="input-group">
                        <input type="number" v-model="data.fontSize"
                               :placeholder="translate('Font size', 'entities')" class="form-control" :readonly="isReadonly">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ suffix }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Line height', 'entities') }}</label>
                    <div class="input-group">
                        <input type="number" v-model="data.lineHeight"
                               :placeholder="translate('Line height', 'entities')" class="form-control" :readonly="isReadonly">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ suffix }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Spacing characters', 'entities') }}</label>
                    <div class="input-group">
                        <input type="number" v-model="data.spacingCharacters"
                               :placeholder="translate('Spacing characters', 'entities')" class="form-control" :readonly="isReadonly">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ suffix }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Text transform', 'entities') }}</label>
                    <v-select :options="['capitalize', 'inherit', 'lowercase', 'uppercase', 'unset']"
                              :placeholder="translate('Text transform', 'entities')"
                              class="select2-form input-group-sm w-100" v-model="data.textTransform" :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="mb-3">
                    <label class="form-control-label">{{ translate('Text color', 'entities') }}</label>
                    <ee-form-color-picker
                        :value="data.textColor"
                        :is-readonly="isReadonly"
                        @input="color => $set(data, 'textColor', color)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import googleFonts from "@/assets/misc/googleFonts";
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker.vue";

export default {
  name: "FontSettingsField",
  mixins: [FormFieldMixin],
  components: {
    EeFormColorPicker,
  },
  data() {
    return {
      visible: false,
      data: {
        letterType: '',
        backUpLetterType: '',
        fontWeightStyle: '',
        subsetLetterType: '',
        textAlign: '',
        fontSize: '',
        lineHeight: '',
        spacingCharacters: '',
        textTransform: '',
        textColor: '',
      },
      suffix: 'PX',
    }
  },
  created() {
    if (this.value && typeof this.value == 'string') {
      this.data = JSON.parse(this.value)
    }
  },
  computed: {
    fonts() {
      return googleFonts[0].items
    },
    isDisabled() {
      return !this.canWrite
    },
  },
  watch: {
    data: {
      deep: true,
      handler(){
        this.$emit('input', JSON.stringify(this.data))
      }
    }
  }
}
</script>