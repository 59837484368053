<template>
  <span>
    <div class="text-right field-actions-container" v-if="hasActionShortcodes || hasActionLocales">
      <shortcodes-dropdown v-if="hasActionShortcodes" :entity="entity" @shortcodeAppend="shortcode => shortcodeAppend(shortcode)"/>
      <locales-dropdown v-if="hasActionLocales"/>
    </div>

    <template v-if="fieldProps && fieldProps.translatable == 1">
      <textarea v-model="translationModel" class="form-control" :class="fieldClasses" :placeholder="placeholderComputed" :ref="fieldRef" :readonly="isReadonly"></textarea>
    </template>
    <template v-else>
      <textarea v-model="model" class="form-control" :class="fieldClasses" :placeholder="placeholderComputed" :ref="fieldRef" :readonly="isReadonly"></textarea>
    </template>
  </span>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"
import LocalesDropdown from "./components/LocalesDropdown.vue"
import ShortcodesDropdown from "./components/ShortcodesDropdown.vue"

export default {
  name: 'TextareaSimpleField',
  mixins: [FormFieldMixin, FormInputMixin],
  components: { LocalesDropdown, ShortcodesDropdown },
  props: {
    fieldRef: {
      required: false,
      default: 'input',
    },
    entity: {
      required: false,
    },
  },
}
</script>
