<template>
    <div class="w-50 background-settings-container">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex align-items-center">
                    <image-field class="picker-secondary w-100"
                                 v-model="data.backgroundImage"
                                 :field-props="fieldProps"
                    />
                </div>
                <div class="mt-1 d-flex">
                    <v-select :options="['no-repeat', 'repeat', 'repeat-x', 'repeat-y']" class="mr-1"
                              :placeholder="translate('Background repeat', 'entities')" v-model="data.backgroundRepeat" style="min-width: 120px;"
                              :disabled="isDisabled"
                    />
                    <v-select :options="['center', 'top', 'bottom', 'left', 'right', 'top left', 'top right', 'bottom left', 'bottom right']"
                              :placeholder="translate('Background position', 'entities')"
                              class="mr-1" v-model="data.backgroundPosition" style="min-width: 120px;"
                              :disabled="isDisabled"
                    />
                    <v-select :options="['scroll', 'fixed', 'static']" v-model="data.backgroundAttachment"
                              :placeholder="translate('Background scroll', 'entities')" style="min-width: 120px;" class="mr-1"
                              :disabled="isDisabled"
                    />
                    <v-select :options="['cover', 'contain', 'auto']" v-model="data.backgroundSize"
                              :placeholder="translate('Background size', 'entities')" style="min-width: 120px;"
                              :disabled="isDisabled"
                    />
                </div>
                <div class="mt-1 d-flex">
                    <div>
                        <label class="form-control-label">{{ translate('Body background color', 'entities') }}</label>
                        <ee-form-color-picker
                            :value="data.backgroundColor"
                            :is-readonly="isReadonly"
                            @input="color => $set(data, 'backgroundColor', color)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import ImageField from "@/modules/erp_entities/components/page/form/fields/ImageField.vue"
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker.vue"

export default {
  name: "BackgroundSettingsField",
  mixins: [FormFieldMixin],
  components: {
    EeFormColorPicker, ImageField,
  },
  data() {
    return {
      visible: false,
      key: 0,
      data: {
        backgroundImage: '',
        backgroundRepeat: '',
        backgroundPosition: '',
        backgroundSize: '',
        backgroundAttachment: '',
        backgroundColor: '',
      }
    }
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
  methods: {
    selectImage() {
      this.key = Math.floor((Math.random() * 100) + 1);
      this.$fileManager.$emit('open', `element_image_${this.key}`)
      this.$fileManager.$on(`selected_element_image_${this.key}`, imgs => {
        this.data.backgroundImage = imgs[0]
      })
    }
  },
  created() {
    if(this.value && typeof this.value == 'string'){
      this.data = JSON.parse(this.value)
    }
  },
  watch: {
    data: {
      deep: true,
      handler(v){
        this.$emit('input', JSON.stringify(this.data))
      }
    }
  }
}
</script>
