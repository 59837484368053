<template>
    <div class="main-table mb-4">
        <div class="d-flex justify-content-between align-items-center table-header px-2">
            <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
                <h6>{{ translate('Best selling products', 'dashboard') }}</h6>
            </div>
            <div class="d-flex justify-content-end align-items-center h-100 py-2 flex-grow-1 flex-shrink-1 w-100">
                <button class="btn border">{{ translate('View reports', 'dashboard') }}</button>
            </div>
        </div>
        <div class="table-responsive" v-if="false">
            <table class="table align-items-center m-0">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ translate('Product', 'dashboard') }}</th>
                        <th scope="col">{{ translate('Stock', 'dashboard') }}</th>
                        <th scope="col">{{ translate('Price', 'dashboard') }}</th>
                        <th scope="col">{{ translate('Monthly sales', 'dashboard') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr-1">
                        <td>name</td>
                        <td>stock</td>
                        <td>Price</td>
                        <td>sales</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="p-4 d-flex align-items-center justify-content-center">
          {{ translate('No data', 'global') }}
        </div>
    </div>
</template>

<script>
export default {
    name: "BestSelling",
    props: {
        isWebsitePublished: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>
