<template>
  <div class="dropdown dropdown-animate" data-toggle="hover" v-if="allLocalesObject && translationLocales && hasManyTranslationLocales">
    <button class="btn btn-outline-secondary dropdown-toggle text-uppercase" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img :src="'/img/flags/locales/4x3/' + environment.store.state.system.translation_locale + '.svg'" alt="Change locale" class="language-flag">
    </button>
    <div class="dropdown-menu">
      <template v-for="(locale, key) in translationLocales">
        <a href="javascript:;" @click="changeTranslationLocale(key)" class="dropdown-item d-flex align-items-center">
          <img :src="'/img/flags/locales/4x3/' + key + '.svg'" alt="Change locale" class="language-flag">
          <span class="dropdown-item pt-0 pl-3">{{allLocalesObject[key].name}}</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>

import {changeTranslationLocale} from "@/utilities/helper";
import {environment} from '@/modules/erp_entities/utilities/helper'
import {allLocales, translationLocales, hasManyTranslationLocales} from "@/utilities/helper";

export default {
  name: "LocalesDropdown",
  data() {
    return {
      allLocalesObject: null,
    }
  },
  computed: {
    translationLocales,
    hasManyTranslationLocales,
    environment: () => environment
  },
  methods: {
    changeTranslationLocale,
  },
  async created() {
    this.allLocales = await allLocales()
    this.allLocalesObject = {}
    for (let locale of this.allLocales){
      this.allLocalesObject[locale.key] = {name: locale.name}
    }
  }
}
</script>

<style scoped>
  .dropdown {
    background: #fff;
  }
</style>
