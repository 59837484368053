<template>
  <div class="container">
    <div class="row">
      <div
        v-for="(layout, li) in layouts"
        class="col-md-6"
      >
        <div class="m-5">
          <div @click="chooseLayout(layout.property)">
            <img
              class="w-100"
              :src="`/img/onboarding/${li}.png`"
            >
          </div>
        </div>
        <h4 class="text-center font-weight-bold py-3">
          {{ layout.name }}
          {{ translate('layout', 'onboarding') }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    layouts() {
      return {
        boxed: {
          name: 'Boxed layout',
          property: 'container',
        },
        fullWidth: {
          name: 'Full width',
          property: 'container-fluid',
        },
      }
    },
  },
  methods: {
    chooseLayout(val) {
      this.value.layout = val
      this.$router.push(this.$router.currentRoute.meta.next)
    },
  },
}
</script>

<style scoped>
img {
    cursor: pointer;
}
</style>
