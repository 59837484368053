<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <icon-picker
      :value="value"
      :icons="icons"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import IconPicker from "@/modules/erp_entities/components/page/form/fields/components/IconPicker.vue"

export default {
  name: "CraftIconPicker",
  components: {
    IconPicker,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    icons: {
      required: false,
    },
  },
}
</script>