import {hasManyTranslationLocales} from '@/utilities/helper'

export default {
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: true,
    },
    placeholder: {
      required: false,
      default: '',
    },
  },
  computed: {
    placeholderComputed() {
      if(this.placeholder){
        return this.entityLabel(this.placeholder, this.fieldProps ? this.fieldProps.system_attribute == 1 : false)
        // todo on categories append_shortcode must be true
        // return this.entityLabel(this.placeholder, this.fieldProps ? this.fieldProps.system_attribute == 1 : false, true)
      }
      return ''
    },
    fieldClasses() {
      let classes = []
      if(this.errorsFormatted){
        classes.push('is-invalid')
      }
      if(this.fieldProps.class){ // new not in entities yet, todo remove the comment or change the name if added
        classes.push(...this.fieldProps.class.split(' '))
      }

      if(this.fieldProps.translatable == 1 && hasManyTranslationLocales()){
        classes.push('translatable')
      }

      return classes
    },
    // #01247177 same as errorsFormatted in InputEntityProps
    errorsFormatted() {
      return this.fieldProps.errors ? Object.values(this.fieldProps.errors).join(', ') : false
    },

    // Permissions
    hasPermissions(){
      return typeof this.fieldProps.permission !== 'undefined'
    },
    canRead(){
      return !this.hasPermissions || ['w', 'r'].includes(this.fieldProps.permission)
    },
    canWrite(){
      return !this.hasPermissions || this.fieldProps.permission === 'w'
    },
    isReadonly(){
      return !this.canWrite
    },
  },
  methods: {
    async confirmEdit(e) {
      if (this.fieldProps.confirmation_message) {
        e.preventDefault()

        const confirmEdit = await this.$alert.confirm(this.fieldProps.confirmation_message)
        if (confirmEdit.isConfirmed) {
          this.fieldProps.confirmation_message = ''

          // Trigger a click event on the element to simulate the default behavior
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })

          e.target.dispatchEvent(clickEvent)
        }
      }
    },
  },
}