<template>
    <li class="nav-item dropdown dropdown-animate" data-toggle="hover" v-if="palettes">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
           aria-expanded="false">
            {{ translate('Palette', 'global') }}
        </a>
        <div class="dropdown-menu dropdown-menu-arrow p-lg-0" style="padding: 20px">
            <div class="p-lg-4">
                <div class="delimiter-bottom pb-2 pt-1 d-flex align-items-center"
                     v-for="(palette, paletteIndex) in palettes" :key="paletteIndex">
                    <a @click="setThemeColor(palette.name)" :style="{background: palette.colors['primary-color']}" href="javascript:;"
                       class="theme-color"></a>
                    {{ translate(palette.name, 'global') }}
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import {setThemeColor, themes} from "@/utilities/helper";
    import { mapState } from 'vuex'

    export default {
        name: "ThemeSwitcher",
        methods: {
            setThemeColor
        },
        computed: {
            palettes(){
                return themes
            },
            // DEPRECATED
            // layouts() {
            //     return layouts
            // },
            // ...mapState('system', ['theme'])
        },
    }
</script>

<style scoped>
    .dropdown-menu {
        min-width: 10rem !important;
    }

    .theme-color {
        height: 30px;
        width: 50px;
        border-radius: 3px;
        margin-right: 15px;
    }
</style>
