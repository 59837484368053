<template>
	<div>
		<div class="container">
			<div v-if="companies" class="mb-4">
				<label>{{ translate('Choose client', 'orders') }}</label>
				<v-select
					:options="companies"
					label="value"
					:reduce="value => value.key"
					@input="v => setClient(v)"
				>
				</v-select>
			</div>

			<div class="extension-body mb-4">
				<div class="row" v-if="extensions.length != 0">
					<div class="col-md-4" v-for="(extension, exI) in extensions.data.data" :key="exI">
						<div class="extension d-flex mb-3">
							<div class="extension-checkbox mx-4 my-3">
								<input :id="extension.id" type="checkbox"
									@change="toggleCartExtension(extension, $event)"
								/>
								<label :for="extension.id"></label>
							</div>
							<div class="d-flex flex-column justify-content-center">
								<span class="extension-title font-weight-bold">
									{{ extension.slug }}
								</span>
								<span class="extension-price text-secondary-color">
									{{ translate('Appstore price', 'orders') }} {{ extension.price.price }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button v-if="selected_client" @click="finishOrder" class="btn btn-primary rounded-pill p-2 m-2 w-100">
				{{ translate('Complete order', 'orders') }}
			</button>
		</div>
	</div>
</template>

<script>
import {db} from "@/utilities/database";
import {mapActions, mapState} from "vuex";
import {clientDataToApiData} from "@/utilities/helpers/checkout";
import {createEntityFormObjectNoTabs} from "@/utilities/helper";

export default {
	name: "createOrderExtensionIndex",
	data() {
		return {
			companies: [],
			extensions: [],
			checked: [],
      form: {
        selected_client: {},
        additional_data: {}
      },
		}
	},
	mounted() {
		this.getCart()
	},
	async created() {
    const result = await this.erp.ext.request.axiosInstance.get('modules/dropdowns/company?perpage=9999')
    this.companies = result.data.data
	},
	methods: {
		...mapActions('blockzCheckout', ['addToCart', 'removeFromCart', 'getCart']),
		// setChecked(ext) {
		// 	if(this.checked.length) {
		// 		this.checked.map(id => {
		// 			if(id === ext.id) {
		// 				this.checked = this.checked.filter(item => item !== ext.id)
		// 			} else {
		// 				this.checked.push(ext.id)
		// 			}
		// 		})
		// 	} else {
		// 		this.checked.push(ext.id)
		// 	}
		// },
		toggleCartExtension(extension, event) {
			if (event.target.checked) {
				this.addToCart(extension)
			} else {
				this.removeFromCart(extension)
			}
		},
		async setClient(id) {
			let selected_company =  await this.erp.ext.request.axiosInstance.get(`/modules/companies/${id}`)
			if (!id) return this.clearForm()
			db.get(`modules/clients/${selected_company.data.data.source_client_id}`).then(res => {
				let contact = res.data.data
				contact.readable_options = createEntityFormObjectNoTabs(res.data.data.options, 'key')
				let contact_person = res.data.data.contacts[0]

				contact_person ? this.form.selected_client.first_name = contact_person.first_name : this.form.selected_client.first_name = contact.readable_options.contact_person_first_name
				contact_person ? this.form.selected_client.last_name = contact_person.last_name : this.form.selected_client.last_name = contact.readable_options.contact_person_last_name
				contact_person ? this.form.selected_client.phone = contact_person.phone : this.form.selected_client.phone = contact.readable_options.phone
				contact_person ? this.form.selected_client.email = contact_person.email : this.form.selected_client.email = contact.readable_options.email
				this.form.selected_client.address = contact.readable_options.address
				this.form.selected_client.country = contact.readable_options.country
				this.form.selected_client.state = contact.readable_options.city
				this.form.selected_client.city = contact.readable_options.city
				this.form.selected_client.postcode = contact.readable_options.zip_code
				this.form.selected_client.mol = contact.readable_options.mol
				this.form.selected_client.bulstat = contact.readable_options.kvk
				this.form.selected_client.vat = contact.readable_options.vat
				this.form.selected_client.company_name = contact.readable_options.name
				this.form.selected_client.company_address = contact.readable_options.address

				if (contact_person) {
					this.form.additional_client_info = {
						avatar: contact_person.avatar,
						contact_id: contact_person.id,
						client_created_at: contact_person.created_at
					}
				}
			})

			this.extensions = await this.erp.ext.request.axiosInstance.get(`/modules/blockz/app-extensions/company/${id}`)

			// Start session for cart
			let session = window.localStorage.getItem('session')
			if (!session) {
				db.get('modules/blockz/cart/start-session ').then(res => {
					window.localStorage.setItem('session', res.data['carttoken'])
					db.defaults.headers.common['carttoken'] = res.data['carttoken']
				})
			} else {
				db.defaults.headers.common['carttoken'] = session
			}
		},
		finishOrder() {
			db.post(`modules/blockz/cart/complete`, clientDataToApiData(
				{create_invoice: true},
				{...this.value.selected_client},
				this.data.additional_data
			)).then(res => {
				window.localStorage.removeItem('session')
				this.value = null
				this.data.additional_data = {}
        this.$toast.requestSuccess('post', 'Order')
			})
		}
	},
	computed: {
		...mapState('blockzCheckout', ['cart', 'data']),
		getCartState() {
			return this.getCart
		}
	}
}
</script>

<style scoped lang="scss">
	.extension-body {
	.module-name {
		font-size: 20px;
		margin: 0 .5rem;
		font-weight: 600;
		cursor: pointer;

		&.active, &:hover {
			color: #FF004F;
		}
	}

	.extensions-length {
		color: $primary;
	}

	.extension {
		border: 1px solid;
		border-radius: 5px;

		.extension-title {
			font-size: 16px;
		}

		.extension-price {
			font-size: 12px;
		}
	}

	.extension-checkbox {
		position: relative;
		padding: 20px 0 20px 40px;
		text-align: left;

		label {
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				border-radius: 50%;
				left: 0;
				width: 30px;
				height: 30px;
				margin: -15px 0 0;
				background: #f7f7f7;
				box-shadow: 0 0 1px grey;
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				border-radius: 50%;
				left: 5px;
				width: 20px;
				height: 20px;
				margin: -10px 0 0;
				opacity: 0;
				background: $primary;
				transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
			}
		}

		input {
			position: absolute;
			top: 0;
			left: -9999px;
			visibility: hidden;

			&:checked + label {
				&:before {
				}

				&:after {
					transform: translate3d(0, 0, 0);
					opacity: 1;
				}
			}
		}
	}
}
</style>