<template>
    <div v-show="isActiveTab">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "EditableTab",
  props: {
    name: {
      required: true,
    },
    tabKey: {
      required: true,
    },
    selected: {
      default: false,
    },
  },
  data() {
    return {
      isActiveTab: false,
    }
  },
  mounted() {
    this.isActiveTab = this.selected
  },
}
</script>
