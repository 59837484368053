<template>
  <tr>
    <td>
      <p v-for="(conditionProp, idx) in condition.conditions" :key="idx" class="m-0 text-wrap">
        {{ formattedCondition(conditionProp) }}
      </p>
    </td>
    <td>{{ formattedDestination }}</td>
    <td>{{ formattedBehaviours }}</td>
    <td class="text-center">
      <button
        class="btn btn-primary rounded-circle icon icon-sm icon-shape"
        @click="$emit('removeCondition')"
      >
        <i class="fas fa-minus" />
      </button>
    </td>
  </tr>
</template>

<script>
import { optionLabel } from "@/utilities/helper"
import { conditionOptions, conditionsWithoutInput } from "@/utilities/lists"

export default {
  name: "ConditionRow",
  props: {
    condition: {
      required: true,
    },
    properties: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      conditionsWithoutInput,
    }
  },
  computed: {
    allFieldLabels() {
      return this.properties.toKeyValue('name', 'label')
    },
    formattedDestination() {
      const destinations = []
      if (this.condition.destination_tab) {
        destinations.push(this.condition.destination_tab)
      }
      if (this.condition.destination_group) {
        destinations.push(this.condition.destination_group)
      }
      if (this.condition.destination) {
        destinations.push(this.entityLabel(this.allFieldLabels[this.condition.destination], 'entities'))
      }
      return destinations.join(' > ')
    },
    formattedBehaviours() {
      return this.condition.destination_behaviour.map(behaviour => this.translate(behaviour, 'entities')).join(', ')
    },
  },
  methods: {
    optionLabel,
    formattedCondition(condition) {
      if (this.conditionsWithoutInput.includes(condition.key)) {
        return optionLabel(conditionOptions, condition.key)
      }

      return `${optionLabel(conditionOptions, condition.key)}: ${this.translate(condition.value, 'entities')}`
    },
  },
}
</script>
