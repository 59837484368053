<template>
  <div class="modal-default">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <img src="/img/skitle-icon.png" height="36" class="mr-3"/>
        <h6 class="text-uppercase">{{ translate('Library', 'page-builder') }}</h6>
        <button type="button" style="margin-left: auto;" class="close" @click="$modal.hide('content-library-modal')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

        <tabs>
<!--          <tab :name="translate('My templates', 'page-builder')">-->
<!--            <tab-templates></tab-templates>-->
<!--          </tab>-->
          <tab :name="translate('My templates', 'page-builder')" :selected="true">
            <tab-my-templates
              entity-type="cms-templates"/>
          </tab>
<!--          <tab :name="translate('Cms pages', 'page-builder')">-->
<!--            <tab-my-templates-->
<!--              entity-type="cms-pages"/>-->
<!--          </tab>-->
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import TabTemplates from "./tabs/TabTemplates.vue"
import TabMyTemplates from "./tabs/TabMyTemplates.vue"

export default {
  name: "ContentLibraryModal",
  components: {
    TabTemplates, TabMyTemplates,
  },
}
</script>

<style lang="scss">
@import "assets/all";
</style>
