import settingMixin from "../craft/components/settingMixin"

export default {
  mixins: [settingMixin],
  methods: {
    attributeExist(name) {
      return this.elementProps
                && this.elementProps.attributes
                && this.elementProps.attributes[name] !== undefined
    },
    getAttribute(name, defaultValue = '') {

      return this.attributeExist(name)
        ? this.elementProps.attributes[name]
        : defaultValue


    },
    setAttribute(name, value) {

      this.elementPropsSetter({
        attributes: {
          ...this.elementProps.attributes,
          [name]: value,
        },

      })
    },
  },
}
