<template>
    <div class="appointment-modal">
        <div class="modal-default">
            <div class="modal-content">
                <div class="modal-header  align-items-center">
                    <h5 class="text-dark" style="width: 100%; text-align: center;">{{ translate('Add payment', 'invoices') }}</h5>
                    <i class="fas fa-times close-modal pr-2" @click="$modal.hide('payment_modal')"></i>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Amount in', 'invoices') }} {{curency}}</label>
                            <input type="number" v-model="newPayment.amount" placeholder="0.01" step="0.01" min="0.01" class="form-control">
                        </div>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Payment type', 'invoices') }}</label>
<!--                            <v-select v-model="newPayment.type" class="meeting-select" :placeholder="translate('Please select type', 'invoices')" :options="['bank transfer', 'creditcard', 'Paypal']" ></v-select>-->
                            <v-select v-model="newPayment.channel" class="meeting-select" :placeholder="translate('Please select type...', 'invoices')" :options="['bank_transfer', 'cash']" ></v-select>
                        </div>
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Payment date', 'invoices') }}</label>
                            <input type="date" v-model="newPayment.payment_date" class="form-control">
                        </div>

                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Note', 'invoices') }}</label>
                            <textarea type="text" v-model="newPayment.note" class="form-control"></textarea>
                        </div>
                        <div class="col-12 text-center">
                        <button class="btn btn-primary rounded-pill mt-3" @click="sendPayment()" >{{ translate('Save changes', 'global') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { db, modules, routes } from "@/utilities/database";
    import moment from "moment";
    export default {
        name: "PaymentModal",
        props: {
            curency: {
                required: true
            },
            object_id: {
                required: true
            }
        },
        data(){
            return {
                newPayment:{
                    amount:'',
                    channel:'',
                    payment_date:'',
                    note:''
                }

            }
        },
        methods: {
            sendPayment(){
                this.payment_date=moment(this.payment_date).format('YYYY-MM-DD')
                this.newPayment.object='invoice';
                this.newPayment.object_id=this.object_id;
                db.post(routes.invoice.payments,this.newPayment)
            .then(res=>{
                this.$emit('pyad')
                this.$modal.hide('payment_modal');
                Object.keys(this.newPayment).forEach(k => this.newPayment[k] = '')
             })
            .catch(err => {
                let answer=''

                Object.keys(err.response.data.errors).forEach(k => answer=answer+err.response.data.errors[k][0]+';')
                this.$toast.error(answer)
                return Promise.resolve(null);
            });

            }
        }
    }
</script>

<style lang="scss">
    .appointment-modal {
        border-radius: 1px;
        .modal-content {
            .modal-header {
                border-bottom: 1px solid $primary !important;

                .close-modal {
                    cursor: pointer;
                    color: #A3ADBD;
                }
            }

            .modal-body {
                label {
                    margin-bottom: 0.25rem;
                }

                .meeting-select .vs__dropdown-toggle {
                    padding: 11px 0 11px 10px;
                }
            }
        }
    }
</style>