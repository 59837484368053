import { db, modules } from './../utilities/database'
import { createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs, setPageTitle } from '../utilities/helper'


// This mixin requires component to have property -> this.type
// TODO: extend request-deprecated.js for methods used here, keep the rest
export default {
  async created(){
    await this.loadEntityColumns()
  },
  data(){
    return {
      columns: null,
      entityOptions: null,
    }
  },
  methods: {
    async show(type, id){
      this.$store.state.system.isLoading = true
      return db.get(`modules/${type}/${id}`, {}).then(res => {
        this.$store.state.system.isLoading = false
        let data = {
          options: createEntityFormObjectNoTabs(res.data.data.options, 'key'), // options as key => value
          data: res.data.data,
          widgets: {
            attachments: [],
          },
        }
        setPageTitle(`${data.options.name || data.options.title} - ${this.type}s`)
        return Promise.resolve(data)
      }).catch(err => {
        return Promise.resolve(null)
      })
    },
    async loadEntityColumns(){
      let res = await db.get(`modules/${this.type}s`)
      this.columns = res.data.columns
      this.entityOptions = createEntityFormObjectNoTabs(this.columns, 'name', 'data')
    },
  },
}

