<template>
  <div class="row" v-if="availableAttributes">
    <div class="col-md-6">
      <div class="nav-sides">
        <h4 class="mb-3">{{ translate('Attributes', 'attributes') }}</h4>
        <draggable :list="availableAttributes"
                   v-bind="dragOptions"
                   @start="dragStart"
                   @end="dragEnd"
        >
          <div v-for="(field, fieldIndex) in availableAttributes"
               :key="'nav-field-'+fieldIndex"
               class="draggable-item py-1">
            <div class="nav-link-title">
              {{ entityLabel(field.label) }}
            </div>
          </div>
        </draggable>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nav-sides">
        <h4 class="mb-3">{{ translate('Groups', 'attributes') }}</h4>

        <div class="row-table">
          <div class="row bg-white">
            <div class="col-md-6">{{ translate('Title', 'attributes') }}</div>
            <div class="col-md-3">{{ translate('Required', 'attributes') }}</div>
            <div class="col-md-3 text-right">{{ translate('Actions', 'attributes') }}</div>
          </div>
        </div>

        <right-navigation
          group="groups"
          :value="data"
          @input="e=>$emit('input', e)"
        />
        <div class="nav-link-title_add add-page-body mt-2" @click="addNewGroup">
          <span class="mr-3"><i class="fa fa-plus-circle"></i></span>
          <span>{{ translate('Add new Group', 'global') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import RightNavigation from "./RightNavigation.vue"
import {generateSlug} from "@/utilities/helper"

export default {
  name: "NavigationLinks",
  components: {
    draggable, RightNavigation,
  },
  props: {
    value: {
      required: true,
    },
    availableAttributes: {
      required: true,
    },
  },
  data() {
    return {
      data: null,
      drag: false,
      page: null,
    }
  },
  created() {
    this.data = this.value
  },
  computed: {
    dragOptions() {
      return {
        draggable: ".draggable-item",
        group: 'fields',
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        dragClass: "draggable-ghost",
      }
    },
  },
  methods: {
    addNewGroup() {
      this.$emit('input', [...this.value, {
        type: 'group',
        name: generateSlug(),
        label: { nl: 'New group' },
        fields: [],
      }])
    },
    dragStart(){
      this.drag = true
    },
    dragEnd(){
      this.drag = false
    },
  },
  watch: {
    'value': {
      deep: true,
      handler() {
        this.data = this.value
      },
    },
  },
}
</script>

<style lang="scss">
.draggable-ghost {
  .nav-link-title_add {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.draggable-item {
  padding-bottom: 10px;

  .nav-link-title {
    min-height: 54px; // hot fix because some categories doesn't have name
    background: #fff;
    padding: 1rem;
    border: 1px solid #dedede;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .handle {
      cursor: pointer;
    }
  }
}

.nav-link-title_add {
  background: #cccccc69;
  color: #69a3f6;
  padding: 1rem;
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    font-size: 20px;
  }
}

.add-page-body {
  border: 1px solid #dedede;
  border-radius: 5px;
}

.nav-sides {
  height: calc(100vh - 330px);
  overflow: auto;
}
</style>
