import store from './../../store/index'

export default function checkLayout(router){
    router.beforeEach((to, from, next) => {
        store.state.system.layout = to.meta.layout || 'master'

        /* NOT USED ANYMORE
        if(to.meta.layout && to.meta.layout == 'blank'){
            setTimeout(() => {
                document.getElementById('initial-loader').style.display = "none"
            }, 300);
        }
        */

        return next()
    })
}
