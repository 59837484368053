<template>
    <li class="nav-item dropdown dropdown-animate user-menu" data-toggle="hover" v-if="authData">
        <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
           aria-expanded="false">
            <div class="media media-pill align-items-center">
                <img v-if="authData.user.avatar" :src="authData.user.avatar" style="height:35px; width: 35px" class="rounded-circle">
                <img v-else :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`" style="height:35px; width: 35px" class="rounded-circle">
                <div class="d-flex flex-column">
                    <span class="mb-0 text-sm  font-weight-bold">{{authData.user.first_name}}</span>
                    <small>{{authData.roles[0].slug}}</small>
                </div>
            </div>
        </a>
        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-arrow mr-0">
                <div class="menu-dropdown-items">
                  <div class="d-flex">
                    <img v-if="authData.user.avatar" class="avatar-image rounded-circle mr-2" style="height:35px; width: 35px;" :src="authData.user.avatar">
                    <img v-else :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`" style="height:35px" class="rounded-circle">
                    <a href="javascript:;" @click="" class="dropdown-item px-2">
                      <span>{{ authData.user.first_name }} {{ authData.user.last_name }}</span>
                    </a>
                  </div>
                  <hr>
                  <div class="d-flex flex-column">
                    <router-link class="dropdown-item" :to="`/users/${authData.user.id}`">
                      <span>{{ translate('My profile', 'users') }}</span>
                    </router-link>
                    <router-link class="dropdown-item" to="/select-company" v-if="authData.user_companies.length > 1">
                      <span>{{ translate('Choose another company', 'users') }}</span>
                    </router-link>
                  </div>
                  <hr>

                  <template v-if="['blockzapp', 'rock'].includes($store.state.system.theme)">
                    <div class="d-flex flex-column">
                      <!-- <router-link class="dropdown-item" :to="`/my-account/${$store.state.system.authData.selected_company}/general`">
                        <span>{{ translate('Company details', 'users') }}</span>
                      </router-link> -->
                      <!-- <router-link class="dropdown-item" :to="`/my-account/${$store.state.system.authData.selected_company}/subscriptions`">
                        <span>{{ translate('My subscriptions', 'users') }}</span>
                      </router-link> -->
                      <!-- <router-link class="dropdown-item" :to="`/my-account/${$store.state.system.authData.selected_company}/payments`">
                        <span>{{ translate('Payment history', 'users') }}</span>
                      </router-link> -->
                      <a href="javascript:;" @click="$modal.show('contact_sales')" class="dropdown-item">
                        <span>{{ translate('Upgrade / renew plan', 'users') }}</span>
                      </a>
                    </div>
                    <hr>
                    <div class="d-flex flex-column">
                      <!-- <router-link class="dropdown-item" to="/websites/identity">
                        <span>{{ translate('Add new website', 'users') }}</span>
                      </router-link> -->
                      <a href="javascript:;" @click="$modal.show('contact_sales')" class="dropdown-item">
                        <span>{{ translate('Hire a professional', 'users') }}</span>
                      </a>
                    </div>
                    <hr>
                  </template>
                  <div class="d-flex justify-content-between">

                    <button class="btn btn-light rounded-pill border border-dark font-weight-bold bg-white px-5" @click="logout">{{ translate('Logout', 'users') }}</button>
                  </div>
                </div>
        </div>
    </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'
    import {unauthorize} from "@/utilities/auth";

    export default {
        name: "MenuDropdown",
        computed: {
            ...mapState('system', ['authData'])
        },
        methods: {
            ...mapActions('system', ['saveAuthenticationLog']),
            async logout() {
                if (this.$store.state.system.interface === 'rock') {
                  await this.saveAuthenticationLog({ type: 'logout_success', email: this.$store.state.system.authData?.user?.email })
                }
                unauthorize()
                this.$router.go({path: 'login'})
            }
        }
    }
</script>

<style scoped>
  .user-menu {
    padding: 0.5rem 1rem;
  }

  @media only screen and (max-height: 600px) {
    .user-menu .dropdown-menu {
      height: calc(80vh + 1px);
      max-height: 26em;
      overflow-y: auto;
      padding-bottom: 10px;
    }
  }
</style>
