<template>
  <div class="container">
    <div class="row">
      <div
        v-for="(font, fi) in fonts"
        :key="fi"
        class="col-md-6"
      >
        <div class="bg-white rounded border my-3">
          <div class="p-4 d-flex flex-column">
            <h3 class="font-weight-bold">
              {{ font.name }}
            </h3>
            <span class="text-muted">{{ font.description }}</span>
            <div class="mt-3">
              <button
                class="btn btn-primary rounded-pill"
                @click="setFont(font)"
              >
                {{ translate('Choose', 'onboarding') }} {{ font.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Font",
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    fonts() {
      return {
        montserrat: {
          name: 'Montserrat',
          description: 'For sophisticated websites.',
        },
        opensans: {
          name: 'Open Sans',
          description: 'For sophisticated websites.',
        },
      }
    },
  },
  methods: {
    setFont(val) {
      this.value.font = val
      this.$router.push(this.$router.currentRoute.meta.next)
      this.$forceUpdate()
    },
  },
}
</script>
