<template>
    <div class="row" v-if="!isWebsitePublished && website && info">
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-merge-domain icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    7. {{ translate('Shipping method', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Create shipping method description', 'dashboard') }}
                </span>
                <span class="mt-4" v-if="info.shipping_methods">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link to="/webshop-settings/shipping/list" type="button"
                             class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate('Create shipping method', 'dashboard') }}
                </router-link>
            </div>
        </div>

        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-merge-domain icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    8. {{ translate('Payment method', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Payment method description', 'dashboard') }}
                </span>
                <span class="mt-4" v-if="info.payment_methods">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link to="/payment-providers" type="button" class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate('Create payment method', 'dashboard') }}
                </router-link>
            </div>
        </div>

        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-merge-domain icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    9. {{ translate('VAT rates', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('VAT rates description', 'dashboard') }}
                </span>
                <span class="mt-4" v-if="info.vat_types">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link to="/vat-rates" type="button" class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate('Create VAT rate', 'dashboard') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SitecartSettingsBoxes",
    props: {
        isWebsitePublished: {
            required: true
        },
        website: {
            required: true
        },
        info: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>
