<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Add Portfolio', 'page-builder')">
        <div class="row">
          <div v-if="categories" class="col-12">
            <craft-select
              label="Categories"
              :options="categoryOptions"
              :value="getSetting('categories')"
              @input="setSetting('categories', $event)"
              :clearable="true"
              :multiple="true"
            />
          </div>

          <div class="col-12">
            <craft-select
              :label="'Choose layout'"
              :options="layoutOptions"
              :value="getSetting('layout')"
              @input="setSetting('layout', $event)"
            />
          </div>

          <div class="col-12">
            <craft-toggle
              :label="'Enable filters'"
              :value="getSetting('enable_filters')"
              id="enable_filters_portfolio"
              :value-off="0"
              :value-on="1"
              @input="setSetting('enable_filters', $event)"
            />
          </div>

          <transition name="fade">
            <div class="col-12" v-if="['table', 'carousel'].includes(getSetting('layout'))">
              <label class="label mb-2">{{ translate('Number of columns', 'categories') }}</label>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['desktop']"
                    :label="'Desktop'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_landscape']"
                    :label="'Tablet (landscape)'"
                    :options="columnOptions"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_portrait']"
                    :label="'Tablet (portrait)'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['mobile']"
                    :label="'Mobile'"
                    :options="columnOptions"
                  />
                </div>
              </div>
            </div>
          </transition>

          <div class="col-12">
            <craft-input
              :label="'How many portfolio posts you want to show'"
              :input-type="'number'"
              :value="getSetting('perpage')"
              @input="setSetting('perpage', $event)"
            />
          </div>

          <transition name="fade">
            <div class="col-12" v-if="getSetting('layout') == 'carousel'">
              <div class="row">
                <div class="col-md-12">
                  <craft-toggle
                    :id="`autoplay-${node.uuid}`"
                    label="Autoplay"
                    :value="getSetting('autoplay')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('autoplay', $event)"
                  />
                </div>
                <transition name="fade">
                  <div class="col-md-12" v-if="getSetting('autoplay')">
                    <craft-input
                      label="Carousel speed"
                      input-type="number"
                      :value="getSetting('carouselSpeed')"
                      @input="setSetting('carouselSpeed', $event)"
                    />
                  </div>
                </transition>
                <div class="col-md-12">
                  <craft-toggle
                    :id="`loop-${node.uuid}`"
                    label="Loop"
                    :value="getSetting('loop')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('loop', $event)"
                  />
                </div>
                <div class="col-md-12">
                  <craft-toggle
                    :id="`show-bullets-${node.uuid}`"
                    label="Show bullets"
                    :value="getSetting('navBulletsEnabled')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('navBulletsEnabled', $event)"
                  />
                </div>
                <div class="col-md-12">
                  <craft-toggle
                    :id="`show-navigation-${node.uuid}`"
                    label="Show navigation"
                    :value="getSetting('navArrowsEnabled')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('navArrowsEnabled', $event)"
                  />
                </div>
                <transition name="fade">
                  <div class="col-md-12" v-if="getSetting('navArrowsEnabled')">
                    <craft-tab-pills
                      label="Navigation position"
                      :options="generalVerticalAlignmentOptions"
                      :value="getSetting('navArrowsVerticalAlign')"
                      @input="setSetting('navArrowsVerticalAlign', $event)"
                    />
                  </div>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import { CraftSelect, CraftInput, CraftToggle, CraftTabPills } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  inject: ['editor', 'node'],
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  components: {
    CraftInput, CraftSelect, CraftToggle, CraftTabPills,
  },
  data() {
    return {
      entity: 'portfolios',
      categories: null,
      columns: {},
    }
  },
  computed: {
    categoryOptions() {
      return this.categories.reduce((options, { name, slug }) => {
        options.push({
          label: this.entityLabel(name, true),
          key: slug
        })
        return options
      }, [])
    },
  },
  async created() {
    this.normalizeComponentSettings() // required because of new settings

    let categoryEntity = this.entity === 'inventory' ? 'inventories' : this.entity

    const categories = await this.erp.ext.request.get(
      'modules/categories',
      this.erp.ext
        .query()
        .where('entity', '=', categoryEntity)
        .where('type', '=', 'categories')
        .toString()
    )

    this.categories = categories.objectData
  },
  mounted() {
    this.columns = this.getSetting('columns')
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        this.setSetting('columns', this.columns)
      },
    },
  },
}
</script>
