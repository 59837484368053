<template>
    <div>
        <page-title :page-title="translate('Reports', 'entities')"></page-title>
        <div class="card">
            <div class="data-table order-table">
                <div class="card-header actions-toolbar border-0">
                    <div class="row justify-content-between align-items-center">
                        <div class="col">
                            <h6 class="d-inline-block mb-0">{{ translate('Reports', 'orders') }}</h6>
                        </div>
                    </div>
                </div>
                <div class="p-3">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group" v-if="stores">
                                <label>{{ translate('Select store', 'orders') }}</label>
                                <v-select class="select2-form" :options="stores" label="value" :reduce="value => value.key" v-model="selected.store"></v-select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group" v-if="stores">
                                <label>{{ translate('Select start date', 'orders') }}</label>
                                <date-picker type="date" :minuteStep="15" v-model="selected.start" valueType="format" :input-class="['form-control']"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group" v-if="stores">
                                <label>{{ translate('Select end date', 'orders') }}</label>
                                <date-picker type="date" :disabled="!selected.start" :disabled-date="notBeforeTodaySecond" v-model="selected.end" valueType="format" :input-class="['form-control']"/>
                            </div>
                        </div>
                        <div class="col-sm-12 d-flex">
                            <button class="btn btn-primary rounded-pill btn-sm ml-auto" @click="loadReports">{{ translate('Search', 'global') }}</button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="chartOptions.series[0].data.length > 0">
                    <div class="col-md-2">
                        <div class="d-flex flex-column justify-content-center h-100 p-2">
                            <div class="d-flex flex-column align-items-center py-3">
                                <b>{{ translate('Selected period', 'orders') }}</b>
                                <span>{{ formatDate(selected.start, null, 'DD MMMM YYYY') }} - {{ formatDate(selected.end, null, 'DD MMMM YYYY') }}</span>
                            </div>
                            <div v-if="totalSales && totalSales">
                                <div class="d-flex flex-column align-items-center py-3">
                                    <b>{{ translate('Total amount', 'orders') }}: </b> <span>{{ totalAmount }} {{ translate('лв', 'orders') }}</span>
                                </div>
                                <div class="d-flex flex-column align-items-center py-3">
                                    <b>{{ translate('Total sales', 'orders') }}: </b> <span>{{totalSales}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <chart :options="chartOptions"></chart>
                    </div>
                </div>
                <div v-if="loading" class="d-flex flex-column align-items-center py-3">
                    <span><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                </div>
                <h4 v-if="!loading && chartOptions.series[0].data.length === 0" class="d-flex flex-column align-items-center py-3">{{ translate('No data', 'orders') }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import {db, modules, routes} from "@/utilities/database";
    import debounce from "debounce";
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment'
    import {Chart} from 'highcharts-vue'

    export default {
        name: "Reports",
        components: {
            DatePicker, 'chart': Chart
        },
        data() {
            return {
                chartOptions: {
                    title: {
                        text: 'Продажби'
                    },
                    yAxis: {
                        title: {
                            text: "Стойност на продажбите за деня"
                        },
                    },
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: '%e. %b',
                            year: '%b'
                        },
                        title: {
                            text: "Дати"
                        }
                    },

                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                        }
                    },
                    colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
                    series: [
                        {
                            name: 'Стойност на продажбите',
                            data: []
                        },
                        {
                            name: 'Поръчки',
                            data: []
                        }
                    ],
                    tooltip: {
                        headerFormat: '',
                        crosshairs: true,
                        shared: true
                    },
                },
                totalAmount: null,
                totalSales: null,
                loading: false,
                stores: null,
                selected: {
                    store: null,
                    start: moment().format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                }
            }
        },
        mounted() {
            this.loadReports()
        },
        methods: {
            loadReports: debounce(async function () {
                this.loading = true;

                await db.get(`modules/sales/reports/sales-report`, {
                    params: {
                        "store_id": this.selected.store,
                        "period_start": this.selected.start,
                        "period_end": this.selected.end
                    }
                }).then(res => {
                    console.log(res)
                    this.chartOptions.series.forEach(data => {
                        data.data = []
                    })
                    this.totalAmount = null
                    this.totalSales = null

                    this.totalAmount = res.data.data.totalAmount
                    this.totalSales = res.data.data.totalSales
                    delete res.data.data.totalAmount
                    delete res.data.data.totalSales

                    for (let property in res.data.data) {
                        for (const dates in res.data.data[property]) {
                            let date = moment(dates).subtract(1, 'months').format('YYYY,M,DD')

                            this.chartOptions.series[0].data.push([Date.UTC(...date.split(',')), Number(res.data.data[property][dates].amountPerDay || res.data.data[property][dates].totalAmountPerDay)])

                            this.chartOptions.series[1].data.push([Date.UTC(...date.split(',')), Number(res.data.data[property][dates].salesPerDay || res.data.data[property][dates].totalSalesPerDay)])
                        }
                    }
                })

                this.loading = false;
            }, 700),
            notBeforeTodaySecond(date) {
                return date < new Date(this.selected.start);
            }
        },
        created() {
            db.get(`modules/dropdowns/Store?perpage=9999`).then(res => {
                this.stores = res.data.data
                if(this.stores.length === 1) {
                    this.selected.store = this.stores[0].key
                }
            })
        }
    }
</script>

<style scoped>

</style>
