import settingMixin from "../craft/components/settingMixin"
import { mapGetters } from "vuex"

/* same as CraftSettingsMixin but reads and applies the changes to the elementStyle instead of settings */
export default {
  mixins: [settingMixin],
  computed: {
    ...mapGetters('layout', ['deviceType']),
  },
  methods: {
    _normalizeElementStyleStructure() {
      if (this.elementProps?.elementStyle) {
        const { elementStyle } = this.elementProps
        const devices = ['desktop', 'tablet', 'mobile']

        // Check if elementStyle is already structured for devices
        const hasDeviceKeys = devices.some(key => key in elementStyle)

        if (!hasDeviceKeys) {
          // Restructure elementStyle for backward compatibility
          this.elementProps.elementStyle = { desktop: { ...elementStyle } }
        }
      } else {
        this.elementProps.elementStyle = { [this.deviceType]: {} }
      }
    },

    _styleExist(name, device = this.deviceType) {
      this._normalizeElementStyleStructure()
      return this.elementProps.elementStyle?.[device]?.[name] !== undefined
    },

    getStyle(name, suffix = null, type = null, device = this.deviceType) {
      let style = this._styleExist(name, device) ? this.elementProps.elementStyle[device][name] : ''

      if (style && suffix) {
        if (suffix === '%') {
          suffix = /%/g
        }

        style = style.replace(suffix, '')
      }

      if (type === 'background-image') {
        style = style.replace('url(', '').replace(')', '')
      }

      return style
    },

    setStyle(name, value, suffix = null, device = this.deviceType) {
      if (!value && this._styleExist(name, device)) {
        delete this.elementProps.elementStyle[device][name]
        this.elementPropsSetter({
          elementStyle: {
            ...this.elementProps.elementStyle,
          },
        })
      } else {
        this.elementPropsSetter({
          elementStyle: {
            ...this.elementProps.elementStyle,
            [device]: {
              ...this.elementProps.elementStyle[device],
              [name]: suffix ? `${value}${suffix}` : value,
            },
          },
        })
      }
    },

    getPlaceholder(name, suffix) {
      this._normalizeElementStyleStructure()

      const desktopValue = this.elementProps.elementStyle?.desktop?.[name] || null
      const tabletValue = this.elementProps.elementStyle?.tablet?.[name] || null

      if (suffix === '%') {
        suffix = /%/g
      }

      if (this.deviceType === 'tablet' && desktopValue) {
        return suffix
          ? desktopValue.replace(suffix, '')
          : desktopValue

      } else if (this.deviceType === 'mobile') {
        const inheritedValue = tabletValue || desktopValue

        if (inheritedValue) {
          return suffix
            ? inheritedValue.replace(suffix, '')
            : inheritedValue
        }
      }

      return ''
    },
  },
}
