<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">
        <div class="row">
          <div class="col-12">
            <craft-input
                label="Height"
                :input-type="'number'"
                :suffix="'PX'"
                :has-responsive-styles="true"
                :placeholder="getPlaceholder('height', 'px')"
                :value="getStyle('height', 'px')"
                @input="setStyle('height', $event, 'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import {CraftInput} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    mixins: [ComponentSettingsMixin, CraftStyleSettingsMixin],
    inject: ['editor'],
    name: 'WhitespaceSettings',
    components: {
      CraftInput,
    }
}
</script>

<style>
</style>
