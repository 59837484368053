<template>
  <div class="tab-templates">
    <div class="row">

      <!-- Filters -->
      <div class="col-md-3">
        <div class="form-group">
          <label class="label">{{ translate('Search by name', 'page-builder') }}</label>
          <input-field
            v-model="search"
            :field-props="{}"
            @input="loadTemplates"
          />
        </div>

        <div class="form-group">
          <label class="label">{{ translate('Categories', 'page-builder') }}</label>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <input id="art" type="checkbox">
              <label for="art" class="mb-0 ml-2">{{ translate('Art', 'page-builder') }}</label>
            </div>
            <div class="d-flex align-items-center">
              <input id="business" type="checkbox">
              <label for="business" class="mb-0 ml-2">{{ translate('Business', 'page-builder') }}</label>
            </div>
            <div class="d-flex align-items-center">
              <input id="construction" type="checkbox">
              <label for="construction" class="mb-0 ml-2">{{ translate('Construction', 'page-builder') }}</label>
            </div>
            <div class="d-flex align-items-center">
              <input id="sport" type="checkbox">
              <label for="sport" class="mb-0 ml-2">{{ translate('Sport', 'page-builder') }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Data -->
      <div v-if="templates && templates.objectData" class="col-md-9">
        <loader v-if="loading"/>
        <template v-else>
          <div class="listed-templates">
            <div class="row">
              <div class="col-md-12">
                <h5 class="font-weight-bold pb-4">{{ templates.objectData.length }} {{ translate('Layouts pack', 'page-builder') }}</h5>
              </div>
              <div v-for="(template, ti) in templates.objectData" :key="ti" class="col-md-4">
                <div class="single-template-parent" @click="setContent(template.content_json)">
                  <img
                    style="height: 200px"
                    class="w-100"
                    src="https://images.pexels.com/photos/794064/pexels-photo-794064.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  >
                  <div class="d-flex flex-column py-2 px-3">
                    <span class="layout-pack">{{ translate('Layouts pack', 'page-builder') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import debounce from "debounce"
import { InputField } from "@/modules/erp_entities/components/page/form/fields"

export default {
  name: "TabTemplates",
  components: {
    InputField,
  },
  inject: ['editor'],
  data() {
    return {
      templates: [],
      categories: [],
      search: '',
      loading: true,
    }
  },
  created() {
    this.loadTemplates()
  },
  methods: {
    loadTemplates: debounce( async function () {
      this.loading = true
      this.templates = []

      let query = this.erp.ext.query().set('perpage', 'all')

      if (this.categories.length) {
        query.where('category', 'in', this.categories)
      }
      if (this.search) {
        query.where('name', 'like', `%${this.search}%`)
      }

      this.templates = (await this.erp.ext.request.get('modules/cms-templates', query.toString()))
      this.loading = false
    }, 800),
    setContent(content) {
      this.editor.import(content)
      this.editor.renderKey++
      this.$modal.hide('content-library-modal')
    },
  },
}
</script>
