import axios from 'axios'
import { parse } from 'node-html-parser'
import uniqid from 'uniqid'
import { erp } from "@/modules/erp_framework"

// export function getInitialContent() {
//     return '[{"componentName":"Canvas","props":{"component":"MainContainerElement"},"children":[],"addition":{},"uuid":"b9e36c92-e20c-426b-b239-9742f997e60b"}]'
// }

export function getInitialContent() {
  return JSON.stringify([{
    "componentName":"Canvas",
    "props":{ "component":"MainContainerElement" },
    "children":[],
    "addition":{},
    "uuid":"b9e36c92-e20c-426b-b239-9742f997e60b",
  }])
}

/* no longer needed
export function prefixCss(css) {
  css = css.replace(':root', '#app')

  let id = `#pb-wrapper`
  let char
  let nextChar
  let isAt
  let isIn
  let isImport, isFontFace
  const prefixIdLength = id.length

  // makes sure the id will not concatenate the selector
  id += ' '

  // removes comments
  css = css.replace(/\/\*(?:(?!\*\/)[\s\S])*\*\/|[\r\n\t]+/g, '')

  // makes sure nextChar will not target a space
  css = css.replace(/}(\s*)@/g, '}@')
  css = css.replace(/}(\s*)}/g, '}}')

  for (let i = 0; i < css.length - 2; i++) {
    char = css[i]
    nextChar = css[i + 1]

    if (char === '@' && nextChar !== 'f') isAt = true
    if (!isAt && char === '{') isIn = true
    if (isIn && char === '}') isIn = false

    if (char === '@' && (css[i+1]+css[i+2]+css[i+3]+css[i+4]+css[i+5]+css[i+6]) === 'import') isImport = true
    if(isImport && char === ')' && nextChar === ';') isImport = false

    if (char === '@' && (css[i+1]+css[i+2]+css[i+3]+css[i+4]+css[i+5]+css[i+6]+css[i+7]+css[i+8]+css[i+9]) === 'font-face') isFontFace = true
    if(isFontFace && char === '}') isFontFace = false

    if (
      !isImport && !isFontFace &&
            !isIn &&
            nextChar !== '@' &&
            nextChar !== '}' &&
            (char === '}' ||
                char === ',' ||
                ((char === '{' || char === ';') && isAt))
    ) {
      css = css.slice(0, i + 1) + id + css.slice(i + 1)
      i += prefixIdLength
      isAt = false
    }
  }

  // prefix the first select if it is not `@media` or `@import` and if it is not yet prefixed
  if (css.indexOf(id) !== 0 && css.indexOf('@') !== 0) css = id + css

  // replace body with something else because we cannot use 2 body's
  css = css.replace('#pb-wrapper body', '#pb-wrapper #pb-wrapper-body')

  return css
}
*/

export const range = range => {
  return Array.from(Array(range).keys())
}

export const kebabCase = text => {
  return text.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const snakeCase = text => {
  return text.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, '_')
    .toLowerCase()
}

export const getStyleRules = val => {
  const valToStyleRule = {
    left: '0 auto 0 0',
    right: '0 0 0 auto',
    center: '0 auto 0 auto',
  }

  return valToStyleRule[val]
}

function componentToHex(c) {
  var hex = Number(c).toString(16)
  return hex.length == 1 ? "0" + hex : hex
}

export function rgbToHex(rgb) {
  try {
    let [r, g, b] = rgb.split('(')[1].split(')')[0].split(', ')
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }
  catch (e) {
    return rgb
  }
}

export async function duplicateFormElement(id) {
  const response = await erp.ext.request.axiosInstance.get(`modules/forms/${id}`)

  const excludeProperties = ['created_at', 'created_by', 'deleted_at', 'id', 'status', 'updated_at']

  response.data.data = { ...response.data.data }

  excludeProperties.forEach(prop => {
    delete response.data.data[prop]
  })

  const excludeDefaultProperties = ['created_at', 'updated_at', 'filterable', 'error', 'id', 'options', 'form_id', 'group_by']

  response.data.data.default_properties = response.data.data.default_properties.map(item => {
    const newItem = { ...item }

    excludeDefaultProperties.forEach(prop => {
      delete newItem[prop]
    })

    newItem.attributes = newItem.attributes.reduce((acc, attribute) => {
      acc[attribute.key] = attribute.value
      return acc
    }, {})

    return newItem
  })

  response.data.data.settings = response.data.data.settings.map(item => ({ group: item.group, key: item.key, value: item.value }))
  response.data.data.slug = `${response.data.data.name.toLowerCase().replaceAll(' ', '-')}-${uniqid()}`

  return await erp.ext.request.axiosInstance.post(`modules/forms`, { ...response.data.data })
}

export const responsiveColumns = {
  12: 1,
  6: 2,
  4: 3,
  3: 4,
  2: 6,
  1: 12,
}
