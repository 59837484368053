<template>
    <div v-if="events.columns" class="system-agenda">
        <loading-circles v-if="loading"></loading-circles>
        <div class="d-flex justify-content-between align-items-center pb-3">
            <div class="">
                <h4 class="d-inline-block mb-0 system-text-primary">{{ translate('Calendar', 'agenda') }}</h4>
            </div>
            <div class="text-right">
                <div class="actions">
                    <button class="btn-plann-modal mr-3" @click="$modal.show('plan_modal')"><i class="fas fa-plus"></i></button>
                <a href="javascript:;" class="action-item mr-3 system-text-primary" v-if="show.filters == true"
                       @click="show.filters = false">
                        <i class="fas fa-times"></i>
                    </a>
                    <a href="javascript:;" class="action-item mr-3 system-text-primary" v-if="show.filters == false"
                       @click="show.filters = true">
                        <i class="fas fa-filter"></i>
                    </a>
                </div>
            </div>
        </div>
        <transition name="slide">
            <!-- <filters @filter="loadEvents" v-show="show.filters" :data="events.columns"></filters> -->
        </transition>

        <FullCalendar ref="calendar" :key="$store.state.system.locale"
                       :event-sources="eventSources"
                       @event-selected="eventSelected"
                       @event-created="eventCreated"
                       :config="config"
        ></FullCalendar>

        <modal name="add_new_events"
               :pivot-x="1"
               :pivot-y="0"
               transition="slide-fade"
               overlayTransition="wait"
               height="100%"
               width="85%"
               :adaptive="true">
            <EntityForm v-if="event.default_properties" :editable="false" @reload="loadEvents" :entity="'events'"
                         :value="event"></EntityForm>
        </modal>

        <modal name="plan_modal"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="500px"
               transition="slide-top"
               overlayTransition="wait"
               classes="remove-modal-height">
            <PlanModal></PlanModal>
        </modal>

        <modal name="appointment_modal"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="600px"
               transition="slide-top"
               overlayTransition="wait"
               classes="remove-modal-height">
            <AppointmentModal></AppointmentModal>
        </modal>

        <modal name="contact_modal"
                :pivot-x="0.5"
                :pivot-y="0.5"
                height="650px"
                width="800px"
                transition="slide-top"
                overlayTransition="wait"
                classes="remove-modal-height">
            <ContactModal></ContactModal>
        </modal>

        <!-- <scheduler @created="addEventToCalendar" v-if="event && !event.id" v-model="events.columns"></scheduler> -->
        <event v-if="event && event.id" v-model="event"></event>
    </div>
</template>

<script>
    import {FullCalendar} from 'vue-full-calendar'
    import moment from 'moment';
    import marked from 'marked'
    import Scheduler from '@/modules/project_management/components/agenda/Scheduler'
    import Event from "@/modules/project_management/components/agenda/Event";
    import fullcalendarBG from 'fullcalendar/dist/locale/bg';
    import fullcalendarEN from 'fullcalendar/dist/locale/bg';
    import {db, modules} from '@/utilities/database'
    import {createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs} from '@/utilities/helper';
    // import EntityForm from '@/components/page/form/EntityForm'
    import {mapActions} from 'vuex';
    import PlanModal from "./agenda/PlanModal";
    import AppointmentModal from "./agenda/AppointmentModal";
    import ContactModal from "./agenda/ContactModal";

    export default {
        name: 'app',
        components: {
            ContactModal, FullCalendar, Scheduler, Event,  PlanModal, AppointmentModal
        },
        data() {
            return {
                showPlanModal: false,
                loading: false,
                events: {},
                config: {
                    locale: this.$store.state.system.locale,
                    locales: [fullcalendarBG, fullcalendarEN],
                    eventClick: (event) => {
                        this.event = {
                            default_properties: event.options,
                            target: `events/${event.__id}`,
                            structure: [...this.events.columns],
                            mode: 'edit'
                        }
                        this.track({
                            type: 'event',
                            data: {...createEntityFormObjectNoTabs(event.options, 'key'), id: event.__id}
                        })
                        this.$modal.show('add_new_events')
                    },
                    eventResize: this.updateEventTime,
                    eventDrop: this.updateEventTime,
                    eventRender(e, el) {
                        if (el.length > 0 && e.description) {
                            let description = document.createElement('div')
                            description.classList.add('fc-event-description')
                            description.innerHTML = marked(e.description)
                            el[0].appendChild(description)
                            el[0].style.backgroundColor = '#efefef'
                        }

                        return el
                    },
                },
                event: {},
                show: {
                    filters: false
                }
            };
        },
        created() {
            this.loadEvents()
        },
        methods: {
            ...mapActions('tracker', ['track']),
            updateEventTime(e, a, b, c, d) {

                let properties = e.options
                properties.map(prop => {
                    if (prop.key == 'start') {
                        prop.value = e.start.format('YYYY-MM-DD HH:mm:ss')
                    }
                    if (prop.key == 'end') {
                        prop.value = e.end.format('YYYY-MM-DD HH:mm:ss')
                    }
                })

                db.put(`modules/events/${e.__id}`, {
                    options: createEntityFormObjectNoTabs(properties, 'key'),
                })
            },
            loadEvents: async function (filters) {
                this.loading = true
                this.$store.state.system.isLoading = true

                let events = await db.get(`modules/events`, {
                    params: filters
                })

                let testev = []
                events.data.data.forEach(ev => {
                    let event = createEntityFormObjectNoTabs(ev.options, 'key')
                    event.options = ev.options
                    event.__id = ev.id
                    testev.push(event)
                });

                events.data.events = testev

                this.events = events.data
                this.loading = false

                this.$store.state.system.isLoading = false
                // let testev = []

            },
            addEventToCalendar(e) {
                this.events.data.push(e)
            },
            eventSelected(event) {
                console.log('asdasd')
                this.event = event;
            },
            eventCreated(...event) {
                let data = [...this.events.columns];

                data.map(prop => {
                    if (prop.name == 'start') {
                        prop.value = event[0].start.format('YYYY-MM-DD HH:mm:ss')
                    }
                    if (prop.name == 'end') {
                        prop.value = event[0].end.format('YYYY-MM-DD HH:mm:ss')
                    }
                })

                this.event = {default_properties: data, target: `events`}
                this.$modal.show('add_new_events')
            },
        },
        computed: {
            eventSources() {
                let events = this.events.events
                console.log(this.events.events)
                return [{
                    events(start, end, tz, callback) {
                        if (!events) {
                            return callback([])
                        }

                        return callback(events)
                    }
                }]
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '~fullcalendar/dist/fullcalendar.css';


    .page-content {
        background: white;
        margin-top: 3px !important;
        padding-top: 40px;
    }

    .btn-plann-modal {
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 50px;
        height: 40px;
        width: 40px;

        &:hover {
            background-color: #fff;
            .fa-plus {
                color: $primary;
            }
        }

        &:focus {
            outline: none;
        }

        .fa-plus {
            color: #fff;
        }
    }
</style>
