import moment from 'moment'
import {db, routes} from "@/utilities/database";
import system from "./system";
import { SweetAlert, SweetToast } from "@/utilities/sweetalert"

export default {
    state: {
        started: null,
        trackedTime: 0,
        timeline: [],
        tracking: false,
        lastFocus: null
    },
    actions: {
        startTracking({state, dispatch}) {
            state.started = moment.now()
            state.tracking = true

            let focus = state.lastFocus ? state.lastFocus : {type: 'started'};
            dispatch('track', focus)
        },
        pauseTracking({state, dispatch}) {
            dispatch('track', {type: 'paused'})
            state.tracking = false
        },
        track({state}, {type="noType", data = {id:-1, name:"Empty track"}}) {
            let focus = {
                type, data,
                time: moment.now()
            }

            if (state.tracking) {
                state.timeline.push(focus)
            }

            if (type !== 'started' && type !== 'paused') {
                state.lastFocus = focus
            }
        },
        async stopTracker({state}) {
            const confirmResult = await SweetAlert.confirmDefault()
            if(!confirmResult.isConfirmed) return

            db.post(routes.tracker.stop, {timeline: state.timeline})
                .then(res => {
                    state.started = null,
                        state.trackedTime = 0,
                        state.timeline = [],
                        state.tracking = false,
                        state.lastFocus = null
                })
                .catch(error => SweetToast.error(error.message))

            // console.log(moment("1900-01-01 00:00:00").add(state.trackedTime, 'seconds').format("HH:mm:ss"))
            // console.log(state.timeline)


        },
        // startTackingLoop
        startTrackingLoop({state, commit, dispatch}) {
            window.tracking = setInterval(function () {
                commit('registerTime', 1)
            }.bind(this), 1000);

            window.tracking = setInterval(function () {
                dispatch('checkActivity')

            }.bind(this), 10 * 60 * 1000)
        },
        checkActivity({state}) {
            if (state.tracking) {
                state.tracking = false;

                let trackerActivity = new Notification('Hey! Are you active?', {
                    body: 'Close or click on the notification, if you are active!',
                    icon: ''
                })
                trackerActivity.onclick = function () {
                    state.tracking = true;
                }.bind(this)
            }
        },
        clearTracking({state}){
            state.started = null,
            state.trackedTime = 0,
            state.timeline = [],
            state.tracking = false,
            state.lastFocus = null
        }

    },
    mutations: {
        registerTime(state, time) {
            if (state.tracking) {
                state.trackedTime += time
            }
        }
    },
    getters: {
        timePassed(state) {
            if (state.started) {
                return moment("1900-01-01 00:00:00").add(state.trackedTime, 'seconds').format("HH:mm:ss")
            } else {
                return '00:00:00'
            }
        }
    },
    namespaced: true
}
