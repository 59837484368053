<template>
  <div class="container" v-if="customer">
    <page-title :page-title="`${customer.first_name} ${customer.last_name}`"
                :borderBottom="false"
                buttons="/webshop-customers">
      <template v-slot:actions v-if="$router.currentRoute.name == 'addresses-create' || $router.currentRoute.name == 'addresses-update' || $router.currentRoute.name == 'account-information'">

        <button class="btn btn-primary rounded-pill" @click="save">
          <span v-if="($router.currentRoute.name == 'account-information' && $router.currentRoute.params.id) || ($router.currentRoute.name == 'addresses-update')">{{ translate('Save changes', 'global') }}</span>
          <span v-else>{{ translate('Create', 'global') }}</span>
        </button>

      </template>
    </page-title>
    <tabs></tabs>

    <router-view v-model="customer" @reload="loadUser" ref="tabContent"></router-view>
  </div>
</template>

<script>
import Tabs from "@/modules/sitecart/views/customers/Tabs.vue"

export default {
  name: "ShowCustomer",
  components: {
    Tabs,
  },
  data() {
    return {
      customer: null,
    }
  },
  methods: {
    async loadUser() {
      this.$store.state.system.isLoading = true
      let customer = await this.erp.ext.request.axiosInstance.get(`/customers/${this.$route.params.id}`)
      this.customer = customer.data.data
      this.$store.state.system.isLoading = false
    },
    async save(){
      this.$refs.tabContent.save()
    },
  },
  async created() {
    await this.loadUser()
  }
}
</script>