import { routes, db } from './database'
import {
  changeLocale,
  scopeSystemByCompany,
  scopeSystemByWebsite,
  environment,
  setLayout,
  setThemeColor,
  themes,
  createEntityFormObjectNoTabs, scopeSystemByEnv,
} from '@/utilities/helper'

import store from './../store'

import request_service from "@/modules/erp_framework/services/request_service"
import { erp } from '@/modules/erp_framework'
import router from "@/router"

/**
 *  Authorization request using username and password
 *
 * @export
 * @param {string} username
 * @param {string} password
 * @returns {Promise} promise with bearer and refresh token
 */
/* NOT USED ANYMORE
export function authorizeWithUsername(username, password) {
    let res = erp.ext.request.axiosInstance.post(routes.oauth_token, {
        client_secret: environment.client_secret,
        client_id: environment.client_id,
        grant_type: environment.grant_type,
        username: username,
        password: password,
     })
     console.log(res)
     return res
}
*/

/**
 * Get current auth user's data and system variables
 *}
 * @returns {Promise} with current session data
 */
export async function loadSessionData() {

  // TODO: cache request data from last authorization bearer
  // TODO: if application is offline authenticate from cached data if we have bearer in storage
  let session = null
  try {
    session = await erp.ext.request.axiosInstance.get(routes.get_user_data)

  } catch (e) {
    if (e.response.status !== 200) {
      unauthorize()
      router.go({ path: 'login' })
    }
  }

  if(session.data.companies.length === 1){
    scopeSystemByCompany(session.data.companies[0])
  } else {

    let localCompany = window.localStorage.getItem('company')
    if (localCompany) {
      localCompany = JSON.parse(localCompany)
      // erp.ext.request.axiosInstance.defaults.headers.common['company'] = localCompany.key replaced with scopeSystemByCompany
      scopeSystemByCompany(localCompany)

    }
  }

  // Hot fix on sites with just one company, todo if backend return session.data.user_data.company this can be removed
  if(!session.data.user_data.company){
    console.log('no session.data.user_data.company')
    session = await erp.ext.request.axiosInstance.get(routes.get_user_data)
  }
  // END hot fix

  // Make company readable object
  let company_locale = null
  if (session.data.user_data.company) {
    session.data.user_data.company = createEntityFormObjectNoTabs(session.data.user_data.company.options, 'key')
    company_locale = session.data.user_data.company.default_language
  }

  // Set locale (from user or from company or default)
  changeLocale(session.data.user_data.locale || company_locale || 'en')

  store.state.system.authData = session.data.user_data

  if (session.data.user_data.entities.length) {
    store.state.system.entitiesPermissions = session.data.user_data.entities.toKeyValue('slug', 'permissions')
  }

  /*
   * check if system is scoped by company
   * if there is more than 1 company system is not scoped automatically
   */

  if(store.state.system.company){

    /*
     * Interface Scope
     */

    let interfaceFound = false
    let localInterface = window.localStorage.getItem('interface')
    if(localInterface){
      interfaceFound = store.state.system.authData.environments.filter(env => env === localInterface)[0]
    }

    if(interfaceFound){
      scopeSystemByEnv(interfaceFound)
    } else {
      // scope by priority
      if(store.state.system.authData.environments.includes('blockz')) {
        scopeSystemByEnv('blockz')
      } else if(store.state.system.authData.environments.includes('rock')){
        scopeSystemByEnv('rock')
      } else if (store.state.system.authData.environments.includes('sitecart')) {
        scopeSystemByEnv('sitecart')
      } else if (store.state.system.authData.environments.includes('skitle')) {
        scopeSystemByEnv('skitle')

      } else {
        scopeSystemByEnv('skitle')
      }
    }

    /*
     * Website Scope
     */

    // Load scope data(website) if there is any
    if (session.data.user_data.company) {

      store.state.system.scope = {
        model: 'cmswebsite',
        data: [],
        value: null,
        obj: null,
      }

      // Load records to scope by
      let filters = erp.ext.query()
      filters.set('sort', { column: 'created_at', direction: 'asc' })
      filters.where('company_id', '=', store.state.system.company.key) // required for blockz, otherwise it shows all websites because header env=blockz
      let scopeDataReq = await request_service.get('/modules/cms-websites', filters.toString())

      if (scopeDataReq.objectData.length > 0) {
        store.state.system.scope.data = scopeDataReq.objectData

        // check if there is website in local storage and if it exists in system.scope.data
        let websiteFound = false
        let localWebsite = parseInt(window.localStorage.getItem('website'))
        if(localWebsite){
          websiteFound = store.state.system.scope.data.filter(website => website.id === localWebsite)[0]
        }

        let website = websiteFound ? websiteFound : store.state.system.scope.data[0] // first website by default
        scopeSystemByWebsite(website)

        // request_service.axiosInstance.defaults.headers.common.cmswebsite = website.id not needed, it is already scoped in scopeSystemByWebsite
      }

    } else {
      store.state.system.scope = null
    }

  }


  // sort companies ascending
  session.data.companies.sort((a,b)=>a.key-b.key)

  await store.dispatch('system/setupStore')

  // TODO: attach entire session data
  store.state.system.locales = session.data.locales
  store.state.system.timeZones = session.data.timeZones
  store.state.system.companies = session.data.companies
  store.state.system.company_logo = session.data.company_logo

  store.state.system.services = session.data.services
  store.state.system.modules = Object.values(session.data.modules)

  // TODO: load settings
  store.state.system.settings = session.data.settings || ['contact_person']
  // setLayout(session.data.layout) DEPRECATED
  setThemeColor(store.state.system.theme)

  return session
}

/**
 * Logout function
 */

export function unauthorize() {
  window.localStorage.removeItem('authorizationToken')
  window.localStorage.removeItem('refreshToken')
  window.localStorage.removeItem('interface')
  window.localStorage.removeItem('company')
  window.localStorage.removeItem('website')

  window.localStorage.removeItem('translation_locale')
  window.localStorage.removeItem('locale')
}
