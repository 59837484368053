<template>
    <div class="container">
        <page-title :page-title="translate(($route.params.id ? 'Edit {entity}' : 'Create {entity}'), 'entities', { entity: translate('Product', 'entities') })" :buttons="`/products${$route.query.redirectProps ? ('?redirectProps=' + $route.query.redirectProps ) : ''}`">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="save">{{ translate('Save changes', 'global') }}</button>
            </template>
        </page-title>
        <div class="card rounded bg-white">
            <!-- <div class="card-header d-flex justify-content-between">
                <h5>{{ translate('Products', 'products') }}</h5>
                <i class="fa fa-chevron-down chevron"></i>
            </div> -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12" v-if="attributeSets">
                        <div class="form-group">
                            <label>{{ translate('Choose attribute set', 'products') }}</label>
                            <!-- todo use ee-form-select -->
                            <v-select class="select2-form" label="name" :reduce="value => value.id"
                              :key="attributeSetId"
                              v-model="attributeSetId"
                              :clearable="false"
                              :options="attributeSets">
                              <template #selected-option="{ name }">{{ entityLabel(name) }}</template>
                              <template v-slot:option="option">{{ entityLabel(option.name) }}</template>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        {{ JSON.stringify(options) }}-->

        <loading v-if="!attributeSetGroups || !optionsLoaded"></loading>

        <template v-else>

          <!-- todo refactor #00141-attributeSetGroups-1 -->
          <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups">
              <div
                v-if="attrSetGroupI !== 'variations' && isGroupEnabled(attrSetGroupI)"
                v-show="hasVisibleField(attrSetGroupI)"
                :key="attrSetGroupI"
                class="card rounded bg-white"
              >
                  <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible[attrSetGroupI]}" @click="visible[attrSetGroupI] = !visible[attrSetGroupI]">
                      <h5>{{ groupLabel(attrSetGroupI) }}</h5>
                      <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible[attrSetGroupI] === true}"></i>
                  </div>
                  <transition-expand>
                      <div v-show="visible[attrSetGroupI]">

                          <!-- Items with multiple sub groups -->
                          <!-- First used for Role Based Pricing -->
                          <template v-if="(typeof multipliedGroups[attrSetGroupI]) !== 'undefined'">
                              <div class="card-body inner-cards-as-accordion">
                                  <div class="row">
                                      <template v-for="(attrSetSubGroup, attrSetSubGroupI) in attributeSetGroups[attrSetGroupI]">
                                          <div
                                              :key="visibilityKey(attrSetGroupI, attrSetSubGroupI)"
                                              class="col-12 card"
                                              :class="{ active: visible[visibilityKey(attrSetGroupI, attrSetSubGroupI)] }">
                                              <div class="card-header d-flex justify-content-between" @click="toggleVisibility(visibilityKey(attrSetGroupI, attrSetSubGroupI))">
                                                  <h6>{{ attrSetSubGroupI }}</h6>
                                                  <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible[visibilityKey(attrSetGroupI, attrSetSubGroupI)] === true}"></i>
                                              </div>
                                              <transition-expand>
                                                  <div v-show="visible[visibilityKey(attrSetGroupI, attrSetSubGroupI)]">
                                                      <div class="card-body">
                                                          <div class="row">
                                                            <!-- todo refactor #00141-attributeSetGroups-2 -->
                                                              <template v-for="(field, fieldI) in attrSetSubGroup.fields">
                                                                  <field v-if="field && field.permission !== 'd'" @createOptions="v => createOptions = v"
                                                                      :class="`col-md-${field.width || '6'} ${(field && field.type === 'hidden') ? 'd-none' : ''}`"
                                                                      :key="field.name"
                                                                      @input="v => setValue(field, v)"
                                                                      :value="options[field.name]"
                                                                      :field-props="field"
                                                                      :entity="'inventory'"
                                                                      :placeholder="fieldPlaceholder(field)"/>
                                                              </template>

                                                          </div>
                                                      </div>
                                                  </div>
                                              </transition-expand>
                                          </div>
                                      </template>
                                  </div>
                              </div>
                          </template>

                          <!-- Items with 1 sub group -->
                          <template v-else>
                            <template v-for="(attrSetSubGroup, attrSetSubGroupI) in attributeSetGroups[attrSetGroupI]">
                              <div class="card-body">
                                  <div class="row">
                                        <!-- todo refactor #00141-attributeSetGroups-2 -->
                                          <template v-for="(field, fieldI) in attrSetSubGroup.fields">
                                              <field v-if="field && field.permission !== 'd'" @createOptions="v => createOptions = v"
                                                  :class="`col-md-${field.width || '6'} ${(field && field.type === 'hidden') ? 'd-none' : ''}`"
                                                  :key="field.name"
                                                  @input="v => setValue(field, v)"
                                                  :value="options[field.name]"
                                                  :field-props="field"
                                                  :entity="'inventory'"
                                                  :placeholder="fieldPlaceholder(field)"/>
                                          </template>
                                  </div>
                              </div>
                            </template>
                          </template>

                      </div>
                  </transition-expand>
              </div>
          </template>

          <!-- Preset fields -->

          <!-- Variations -->
          <div class="card rounded bg-white" v-if="attributeSetGroups && attributeSetGroups['variations'] && isGroupEnabled('variations')" :key="'variations'">
            <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible['variations']}" @click="visible['variations'] = !visible['variations']">
              <h5>{{ translate('Variations', 'products') }}</h5>
              <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['variations'] === true}"></i>
            </div>
            <transition-expand>
              <div v-show="visible['variations']">
                <template>
                  <div class="card-body">
                    <div class="row">

                      <!--
                      <field v-if="field && field.permission !== 'd'"
                        @createOptions="v => createOptions = v"
                        :field-props="{rules: field.rules}"
                             @input="v => setValue(attributeSetGroups[attrSetGroupI][attrSetSubGroupI][fieldI].name, v)"
                             />
                      -->

<!--                      <pre>-->
<!--                      configurable_attributes: {{ this.options.configurable_attributes }}-->
<!--                      <br/><br/>-->
<!--                      variations: {{ this.options.variations }}-->
<!--                      <br/><br/>-->
<!--                      </pre>-->

                      <div class="form-group col-md-12">
                        <product-variations
                            :configurable-attributes="this.options.configurable_attributes"
                            @inputConfigurableAttributes="v => this.options.configurable_attributes = v"

                            :variations="this.options.variations"
                            @inputVariations="v => this.options.variations = v"

                            @createOptions="v => createOptions = v"
                            :attributeSetId="attributeSetId"
                            :key="'product-variations'"
                            ref="productVariations"
                            :parentProduct="options"
                        />
                      </div>

                    </div>
                  </div>
                </template>

              </div>
            </transition-expand>
          </div>
          <!-- END Preset fields -->

        </template>

        <div class="d-flex justify-content-end mb-3">
            <router-link :to="`/products${$route.query.redirectProps ? ('?redirectProps=' + $route.query.redirectProps ) : ''}`" class="btn btn-white rounded-pill">
              {{ translate('Go back', 'global') }}
            </router-link>
            <button class="btn btn-primary rounded-pill" @click="save">{{ translate('Save changes', 'global') }}</button>
        </div>

        <modal name="create_new_options"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="auto"
               width="500px"
               transition="slide-top"
               overlayTransition="wait"
               classes="remove-modal-height">
            <create-new-option :value="createOptions"></create-new-option>
        </modal>
    </div>
</template>

<script>
import field from '@/modules/erp_entities/components/page/form/InputEntityProps'
import ErpModel from "@/modules/erp_framework/services/ErpModel";
import CreateNewOption from '@/modules/erp_entities/components/entities/CreateNewOption'
import ProductVariations from "./ProductVariations";
import { busEvent } from '@/modules/erp_entities/utilities/eventBus'
import Loading from "../../../system/components/utilities/Loader";
import { humanReadable, isVoidValue, generateSlug } from "@/utilities/helper"
import ProductMixin from "@/modules/sitecart/mixins/ProductsMixin";
import {getWebsiteSettings} from "@/utilities/helpers/websiteSettings";
import {presetProductConfigurableAttributes, presetProductVariations} from './presetGroups'

export default {
    name: "ProductsCreate",
    components: {
        field, CreateNewOption, Loading, ProductVariations
    },
    mixins: [ProductMixin],
    data() {
        return {
            attributeSets: null, // All product attribute sets
            attributeSetId: null,
            createOptions: null,
            enabledGroups: {}
        }
    },
    async created() {
        let attributeSets = await this.erp.ext.request.axiosInstance.get('/modules/attribute-sets')
        this.attributeSets = attributeSets.data.data

        let product = null

        // On edit
        if (this.$route.params.id) {
          product = await this.erp.ext.request.axiosInstance.get('/modules/inventory/' + this.$route.params.id)
          this.attributeSetId = parseInt(product.data.data.inventory_attribute_set_id)
          await this.getAttributeSetProperties(this.attributeSetId)

          let properties = await this.erp.ext.request.axiosInstance.get(`/modules/attribute-sets/${this.attributeSetId}/properties`)

          properties.data.data['variations'] = {
            label: { en: 'Variations NEW TODO', nl: 'Variations NEW TODO' },
            fields: [
              presetProductConfigurableAttributes(),
              presetProductVariations(),
            ],
          }

          Object.entries(properties.data.data).forEach(([groupKey, group]) => {
            for (const prop of group.fields) {
              let recordProp = product.data.data.options.filter(rec => rec.key === prop.name)
              if (recordProp.length && recordProp[0].key) {

                // hot fix clear null values
                if(Array.isArray(recordProp[0].value)){
                  recordProp[0].value = recordProp[0].value.filter(item => item !== null)
                }
                // END hot fix clear null values

                this.setValue({'name': recordProp[0].key, 'type': recordProp[0].type}, recordProp[0].value)
              }
            }
          })

          // variations hot fixes
          this.$set(this.options, 'configurable_attributes', product.data.data.configurable_attributes)
          this.$set(this.options, 'variations', product.data.data.variations)
          if (typeof this.options.variations === 'string'){
            this.options.variations = JSON.parse(this.options.variations)
          }
          // END variations hot fixes

          this.optionsLoaded = true

        // On create
        } else {
          // find default attribute set
          let inventory_attribute_set = this.attributeSets.find(x => x.slug === 'default')
          if(inventory_attribute_set === undefined){
            this.attributeSetId = this.attributeSets[0].id;
          }else {
            this.attributeSetId = inventory_attribute_set.id;
          }
          await this.getAttributeSetProperties(this.attributeSetId)

          this.$set(this.options, 'configurable_attributes', [])
          this.$set(this.options, 'variations', [])
          this.optionsLoaded = true
        }
    },
    methods: {
        humanReadable,
        isGroupEnabled(group){
          if(typeof this.enabledGroups[group] !== 'undefined'){
            return this.enabledGroups[group]
          }
          return true
        },
        hasVisibleField(attrSetGroupI) {
          return Object.values(this.attributeSetGroups[attrSetGroupI]).some(attrSetSubGroup => {
            return attrSetSubGroup.fields.some(field => field.type !== 'hidden')
          })
        },
        groupLabel(attrSetGroupI) {
          // get the label from the first sub group, ideally "label" must be one level higher but that requires a lot of refactor
          let label = Object.values(this.attributeSetGroups[attrSetGroupI])[0].label

          if(label) {
            return this.entityLabel(label)
          }

          // old logic in case we miss label somewhere
          return this.translate(attrSetGroupI, 'products')
        },
        async save() {
            busEvent.$emit('validate')
            this.$store.state.system.isLoading = true

            // on create generate unique slug
            if(isVoidValue(this.options.slug)){
              this.options.slug = generateSlug()
            }

            this.options.inventory_attribute_set_id = this.attributeSetId

            const allVariations = this.options.variations // used to return back all variations on error

            // If there are variations
            if(this.options.inventory_type === 'configurable' && this.options.configurable_attributes !== null && this.options.configurable_attributes.length){
              // skip newly created variations with status 0
              this.options.variations = this.options.variations.filter(variation => variation.status == 1 || !isVoidValue(variation.id))

            } else {
              // otherwise simple and don't sent variations
              delete this.options.variations
            }

            try {
                if (this.$route.params.id) {
                    await this.erp.ext.request.axiosInstance.put(`/modules/inventory/${this.$route.params.id}`, {options: this.options, entity_slug: 'product' })
                } else {
                    await this.erp.ext.request.axiosInstance.post(`/modules/inventory`, {options: this.options, entity_slug: 'product' })
                }
                const method = this.$route.params.id ? 'put' : 'post'
                this.$toast.requestSuccess(method, 'Product')

                this.$router.push(`/products${this.$route.query.redirectProps ? ('?redirectProps=' + this.$route.query.redirectProps ) : ''}`)
            }
            catch (error) {
                this.options.variations = allVariations // return back all variations
                this.$alert.formattedError(error)
            }
            this.$store.state.system.isLoading = false
        },
        toggleField(fieldName){
          if(fieldName === 'manage_stock'){

            const qtyAttr = this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'qty')
            const stockStatusAttr = this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'stock_status')

            if(this.options['inventory_type'] === 'configurable'){
              this.$set(this.attributeSetGroups[qtyAttr.group_by][qtyAttr.group_name].fields[qtyAttr.index], 'v_hide', true);
              this.$set(this.attributeSetGroups[stockStatusAttr.group_by][stockStatusAttr.group_name].fields[stockStatusAttr.index], 'v_hide', true);
              if(typeof this.$refs['productVariations'] !== 'undefined'){
                this.$refs.productVariations.toggleField(fieldName)
              }
            } else {
              if(this.options[fieldName] == 1){
                this.$set(this.attributeSetGroups[qtyAttr.group_by][qtyAttr.group_name].fields[qtyAttr.index], 'v_hide', false);
                this.$set(this.attributeSetGroups[stockStatusAttr.group_by][stockStatusAttr.group_name].fields[stockStatusAttr.index], 'v_hide', true);
              }else{
                this.$set(this.attributeSetGroups[qtyAttr.group_by][qtyAttr.group_name].fields[qtyAttr.index], 'v_hide', true);
                this.$set(this.attributeSetGroups[stockStatusAttr.group_by][stockStatusAttr.group_name].fields[stockStatusAttr.index], 'v_hide', false);
              }
            }
          }
        },
        setValue(fieldProps, v){
            this.options[fieldProps.name] = v

            // manage stock show/hide different fields
            if(fieldProps.name === 'manage_stock'){
              this.toggleField('manage_stock')
            }

            // Inventory type show/hide different groups & fields
            else if(fieldProps.name === 'inventory_type'){

              const pricingFieldsToToggle = [
                this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'price'),
                this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price'),
                this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price_from'),
                this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price_until')
              ]

              if(v === 'configurable'){
                // pricing
                pricingFieldsToToggle.forEach(attr => {
                  this.$set(this.attributeSetGroups[attr.group_by][attr.group_name].fields[attr.index], 'v_hide', true);
                })
                // inventory
                this.toggleField('manage_stock')

                // groups
                this.enabledGroups['Role Based Pricing'] = false
                this.enabledGroups['variations'] = true
              } else {
                // pricing
                pricingFieldsToToggle.forEach(attr => {
                  this.$set(this.attributeSetGroups[attr.group_by][attr.group_name].fields[attr.index], 'v_hide', false);
                })
                // inventory
                this.toggleField('manage_stock')

                // groups
                this.enabledGroups['Role Based Pricing'] = true
                this.enabledGroups['variations'] = false
              }
            }
        },
    },
    watch: {
        '$route.name': {
            handler() {
                if (this.$route.name.includes('create')) {
                    this.options = {}
                    this.attributeSetGroups = {}
                }
            }
        },
        'attributeSetId': {
          async handler(new_value, old_value) {
            // trigger only on change, on initial load we have to wait for it so it is not suitable as watcher
            if(old_value !== null){
              await this.getAttributeSetProperties(this.attributeSetId)
            }
          }
        }
    }
}
</script>

<style>
.file-picker_files .file-options_row{
  opacity: 1 !important;
  visibility: visible !important;
}
.file-picker_files .file-options_row .fa, .file-picker_files .file-options_row .fas {
  border-radius: 50%;
  font-size: 24px;
  background-color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 40px;
}
.file-picker_files .file-options_row .fa.fa-check{
  color: #09c897;
}
.file-picker_files .file-options_row .fas.fa-trash-alt{
  color: #FF0553;
}
@media only screen and (max-width: 1024px) {
  .file-picker_files .file-options_row .fa, .file-picker_files .file-options_row .fas{
    font-size: 18px;
    width: 30px;
    height: 30px;
  }
}
</style>
