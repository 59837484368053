/**
 * Builder request service
 *
 * Using index/find functions, to call your data instead of using axios,
 * will fetch your data and return it reworked to object
 */

import axios from "axios"
import ErpModel from './ErpModel'
import Cache from './request/RequestCaching'
import '../prototypes/array'
import { entityNameToModel } from "../utilities/helpers"


export default {
  axiosInstance: axios.create({
    baseURL: (process.env.VUE_APP_API_DOMAIN || process.env.MIX_APP_API_DOMAIN) + '/api',
  }),

  cache: Cache,

  /**
   *  Get ERP data as reworked object
   *
   * @param {String} route
   * @param {String} params
   * @param {Object} config
   * @returns
   */
  get: async function (route, params = '', config = {}) {
    // Retrieve data from cache
    const target = `${route}?${params}`

    if(this.cache.has(target)){
      return this.cache.get(target)
    }
    const { data } = await this.axiosInstance.get(`${route}?${params}`, config)

    const objectData = [...data.data].map(row => {
      if(row.options){
        const optionsObject = row.options.optionsToObject()
        optionsObject.id = row.id
        return optionsObject
      }
    })

    const dataModels = objectData.reduce((models, objData) => {
      models.push(new ErpModel(objData, data.columns, route))
      return models
    }, [])

    const returnData = {
      columns: data.columns, data: data.data,
      objectData, meta: data.meta,
      settings: data.settings_menu,
      dataModels,
      entity_slug: data.entity_slug,
      primary_fields: data.primary_fields,
    }

    this.cache.store(target, returnData)
    return this.cache.get(target)
  },

  /**
     *  Find record by id.
     *
     * @param {String} module
     * @param {Number} id
     * @param {Boolean} id create ErpModel
     * @returns
     */
  find: async function (module, id, createModel = true) {
    const res = await this.axiosInstance.get(`modules/${module}/${id}`)
    const objectData = res.data.data.options.optionsToObject()
    objectData.id = res.data.data.id

    // hot fix add the new columns in objectData
    if(['blogs', 'portfolios'].includes(module) && typeof objectData.locale === 'undefined'){
      objectData.locale = null
    }
    // END hot fix

    const additionalData = Object.assign({}, res.data)
    delete additionalData.data

    let model = null
    if (createModel) {
      let moduleResponse = await this.axiosInstance.get(`modules/columns/${entityNameToModel(module)}`)
      model = new ErpModel(objectData, moduleResponse.data, `modules/${module}`, 'put', additionalData)
    }

    return {
      data: res.data.data, objectData, model, additionalData,
    }
  },
}
