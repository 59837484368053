<template>
	<div class="row">
		<div class="col-md-12" v-if="clients && !loading">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Pick an existing client', 'orders') }}</label>
				<v-select class="select2-form" @input="v => selectedClient(v)" :options="clients" :reduce="value =>
                value.key" label="value"></v-select>
			</div>
		</div>
		<div class="col-md-12">
			<div v-if="loading" class="d-flex justify-content-center m-5">
				<span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
			</div>
		</div>
		<div class="col-md-12">
			<h4 class="text-center">{{ translate('Or create new client', 'orders') }}</h4>
		</div>
		<div class="col-md-12">
			<h5 class="font-weight-bold pb-2">{{ translate('Customer details', 'orders') }}</h5>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('First name', 'orders') }}<span class="font-italic">{{ translate('(included in contact person)', 'orders') }}</span></label>
				<input name="first_name" type="text" class="form-control" v-model="selected_client.first_name">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Last name', 'orders') }}<span class="font-italic">{{ translate('(included in contact person)', 'orders') }}</span></label>
				<input name="last_name" type="text" class="form-control" v-model="selected_client.last_name">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Address', 'orders') }}</label>
				<input name="address" class="form-control" v-model="selected_client.address">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Postcode', 'orders') }}</label>
				<input name="postcode" class="form-control" v-model="selected_client.postcode">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('City', 'orders') }}</label>
				<input name="city" class="form-control" v-model="selected_client.city">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('State', 'orders') }}</label>
				<input name="state" class="form-control" v-model="selected_client.state">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Country', 'orders') }}</label>
				<input name="country" class="form-control" v-model="selected_client.country">
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Phone', 'orders') }}</label>
				<input name="phone" class="form-control" v-model="selected_client.phone">

			</div>
		</div>
		<div class="col-md-4">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Email', 'orders') }}<span class="font-italic">{{ translate('(included in contact person)', 'orders') }}</span></label>
				<input name="email" class="form-control" v-model="selected_client.email">

			</div>
		</div>
		<div class="col-md-12"><hr></div>
		<div class="col-md-12">
			<h5 class="font-weight-bold pb-2">{{ translate('Company details', 'orders') }}<span class="font-italic">{{ translate('(optional if legal person)', 'orders') }}</span></h5>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="form-control-label">{{ translate('Company name', 'orders') }}</label>
				<input name="company_name" class="form-control" v-model="selected_client.company_name">

			</div>
		</div>
		<div class="col-md-6">
				<div class="form-group">
					<label class="form-control-label">{{ translate('Company address', 'orders') }}</label>
					<input name="company_address" class="form-control" v-model="selected_client.company_address">

				</div>
			</div>
		<div class="col-md-4">
				<div class="form-group">
					<label class="form-control-label">{{ translate('Manager', 'orders') }}</label>
					<input name="mol" class="form-control" v-model="selected_client.mol">
				</div>
			</div>
		<div class="col-md-4">
				<div class="form-group">
					<label class="form-control-label">{{ translate('Chamber of commerce', 'orders') }}</label>
					<input ref="input" name="bulstat" class="form-control" v-model="selected_client.bulstat">
				</div>
			</div>
		<div class="col-md-4">
				<div class="form-group">
					<label class="form-control-label">{{ translate('VAT', 'orders') }}</label>
					<input ref="input" name="vat" class="form-control" v-model="selected_client.vat">
				</div>
			</div>
<!--		<div class="col-md-12">-->
<!--			<div class="avatar-picker py-4">-->
<!--				<label class="avatar-label mb-0">Extension image</label>-->
<!--				<span class="d-block text-muted">Please use an image....</span>-->
<!--				<div class="">-->
<!--					<div class="image-holder m-3"-->
<!--						 :class="{'active-border': selected_client.contacts[0].avatar == '' || selected_client.contacts[0].avatar == null}">-->
<!--						<div v-if="!selected_client.contacts[0].avatar" class="d-flex justify-content-center p-2 my-4">-->
<!--                        <span @click="$fileManager.$emit('open', 'logo')" id="drop_file" class="d-flex justify-content-center py-4 px-5">-->
<!--                            Click here to upload files-->
<!--                        </span>-->
<!--						</div>-->
<!--						<img v-else :src="selected_client.contacts[0].avatar">-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		<div class="col-md-12">
			<div class="order-footer d-flex justify-content-between my-2">
				<button class="btn btn-white rounded-pill" @click="cancel">{{ translate('Cancel', 'orders') }}</button>
				<span class="step-count">{{ translate('Step', 'orders') }} {{ $router.currentRoute.meta.step }} {{ translate('of', 'orders') }} 5</span>
				<div>
					<router-link v-if="$router.currentRoute.name !== 'choose-plan'" class="btn btn-primary rounded-pill"
								 to="/blockz-order/create/extensions">
						{{ translate('Go back', 'orders') }}
					</router-link>
					<router-link class="btn btn-primary rounded-pill" @click="beforeLeaving" to="/blockz-order/create/order-final">
						{{ translate('Continue', 'orders') }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {db} from "@/utilities/database";
import {clientDataToApiData} from "@/utilities/helpers/checkout";
import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";

export default {
	name: "AssignClient",
	props: {
		value: {
			required: true
		}
	},
	data() {
		return {
			clients: null,
			loading: null,
			selected_client: {
				name: null,
				phone: null,
				email: null,
				company_address: null,
				country: null,
				state: null,
				city: null,
				postcode: null,
				company_name: null,
				address: null,
				mol: null,
				bulstat: null,
				vat: null,
			},
			create_invoice: true
		}
	},
	mounted() {
		db.get(`modules/dropdowns/client?perpage=9999`).then(res => {
			this.loading = true
			this.clients = res.data.data
			this.loading = false
		})

		document.addEventListener('input', function (e) {
			e.target.classList.remove('is-invalid')
		})
	},
	methods: {
		cancel() {
			this.$router.push('/blockz-order')
			window.localStorage.removeItem('session')
		},
        clearForm() {
		    Object.keys(this.selected_client).forEach(key => this.selected_client[key] = null)
        },
		selectedClient(client) {
		    if (!client) return this.clearForm()
			db.get(`modules/clients/${client}`).then(res => {
				let contact = res.data.data
				contact.readable_options = createEntityFormObjectNoTabs(res.data.data.options, 'key')
				let contact_person = res.data.data.contacts[0]

				contact_person ? this.selected_client.first_name = contact_person.first_name : this.selected_client.first_name = contact.readable_options.contact_person_first_name
				contact_person ? this.selected_client.last_name = contact_person.last_name : this.selected_client.last_name = contact.readable_options.contact_person_last_name
				contact_person ? this.selected_client.phone = contact_person.phone : this.selected_client.phone = contact.readable_options.phone
				contact_person ? this.selected_client.email = contact_person.email : this.selected_client.email = contact.readable_options.email
				this.selected_client.address = contact.readable_options.address
				this.selected_client.country = contact.readable_options.country
				this.selected_client.state = contact.readable_options.city
				this.selected_client.city = contact.readable_options.city
				this.selected_client.postcode = contact.readable_options.zip_code
				this.selected_client.mol = contact.readable_options.mol
				this.selected_client.bulstat = contact.readable_options.kvk
				this.selected_client.vat = contact.readable_options.vat
				this.selected_client.company_name = contact.readable_options.name
				this.selected_client.company_address = contact.readable_options.address

                if (contact_person) {
                    this.value.additional_client_info = {
                        avatar: contact_person.avatar,
                        contact_id: contact_person.id,
                        client_created_at: contact_person.created_at
                    }
                }
			})
		},
		validateFields() {
			const required = ['first_name', 'last_name', 'address', 'postcode', 'city', 'state', 'country', 'phone',
				'email']
			let emptyFields = required.filter(field => !this.selected_client[field])
            console.log(emptyFields)
				emptyFields.forEach(fieldKey => {
				    console.log(document.querySelector(`[name='${fieldKey}']`))
				document.querySelector(`[name='${fieldKey}']`).classList.add('is-invalid')
			})
			let formIsInvalid = emptyFields.length > 0
			if (formIsInvalid) {
        this.$toast.error('Please fill in the empty fields!')
			}
			return !formIsInvalid
		}
	},
	beforeRouteLeave: function(to, from, next) {
		if (to.name === 'extensions' || to.name === 'order-final') {
			if (this.validateFields()) {
				this.value.selected_client = this.selected_client
				next()
			}
		} else {
			window.localStorage.removeItem('session')
			this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
			this.$store.state.blockzCheckout.cart = []
			next();
		}
	}
}
</script>

<style scoped lang="scss">
.avatar-picker {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;

	.avatar-label {
		font-size: 16px;
	}
}

.order-footer {
	.btn-cancel {
		background: #fff;
		border-color: #b5c4d9;
		color: #000;

		&:hover {
			background: var(--secondary-btn-color);
			border-color: $primary;
			color: $primary;
		}
	}
}
</style>
