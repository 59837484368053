<template>
    <div class="add-comment">
        <form @submit.prevent="createMessage">
            <div class="comment-input-field">  
                <input type="text" name="message" class="comment-body w-100 p-2" placeholder="Comment here" v-model="newMessage">             
                <!-- <textarea class="comment-body w-100 p-2" placeholder="Comment here"></textarea> -->
                <p class="text-danger" v-if="errorText">{{ errorText }}</p>
            </div>
            <div class="comment-actions d-flex justify-content-between mt-2">
                <div>
                    <span class="mr-3"><i class="fas fa-at"></i></span>
                    <span class="mr-3"><i class="fas fa-sort"></i></span>
                    <span class="mr-3"><i class="far fa-smile"></i></span>
                    <span class="mr-3"><i class="far fa-circle"></i></span>
                    <span class="mr-3"><i class="fas fa-record-vinyl"></i></span>
                </div>

                <div>
                    <span class="mr-2"><i class="fas fa-paperclip"></i></span>
                    <button class="p-2" type="submit" name="action">{{ translate('Comment', 'project') }}</button>
                </div>
            </div>
        </form>
    </div>     
</template>

<script>
export default {
     name: 'CreateMessage',
        props: ['name'],
        data(){
            return {
                newMessage: null,
                errorText: null
            }
        },
        methods: {
            createMessage () {
                if (this.newMessage) {
                    // ({
                    //     message: this.newMessage,
                    //     name: this.name,
                    //     timestamp: Date.now()
                    // }).catch(err => {
                    //     console.log(err);
                    // });
                    console.log(Date());
                    
                    this.newMessage = null;
                    this.errorText = null;
                } else {
                    this.errorText = "A message must be entered!"
                }
            }
        }
}
</script>

<style>

</style>