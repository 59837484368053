<template>
    <div class="container">
        <page-title :page-title="translate(pageTitle, 'entities')"  :buttons="'/users'">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="save">
                    <span v-if="editMode">{{ translate('Save changes', 'global') }}</span>
                    <span v-else>{{ translate('Create', 'global')}}</span>
                </button>
            </template>
        </page-title>
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible['account_details'] }" @click="visible['account_details'] = !visible['account_details']">
            <h5>{{ translate('Account details', 'users') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['account_details'] === true}"></i>
          </div>
          <transition-expand>
            <div class="card-body" v-show="visible['account_details']">
                <div class="row">
                    <div class="col-12">

                      <div v-if="createMode" class="col-md-12 col-xs-12 pb-4">
                        {{ translate('Once the user account is created, an email will be sent to the user containing a link to set up a password.', 'users') }}
                      </div>

                      <image-field v-if="editMode"
                                   v-model="user.avatar"
                                   :field-props="{ default_value: `/img/themes/${$store.state.system.theme}/avatar_default.jpg` }"
                                   picker-style='avatar'
                                   class="picker-style-3" />
                    </div>
                  </div>

                    <div class="row pl-3 pr-3">
                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('First name', 'users') }}
                                </label>
                                <input type="text" class="form-control" name="name" autocomplete="off"
                                    :class="{'is-invalid': errors && errors.hasOwnProperty('first_name')}"
                                    :placeholder="translate('Enter first name', 'users')" v-model="user.first_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('first_name')">{{errors['first_name'][0]}}</span>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Last name', 'users') }}
                                </label>
                                <input type="text" class="form-control" name="last_name" autocomplete="off"
                                    :class="{'is-invalid': errors && errors.hasOwnProperty('last_name')}"
                                    :placeholder="translate('Enter last name', 'users')" v-model="user.last_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('last_name')">{{errors['last_name'][0]}}</span>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Email', 'users') }}
                                </label>
                                <input type="email" class="form-control" name="email" :placeholder="translate('Enter email', 'users')"
                                    :class="{'is-invalid': errors && errors.hasOwnProperty('email')}"
                                    autocomplete="off" v-model="user.email" :readonly="editMode">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('email')">{{errors['email'][0]}}</span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-control-label">{{ translate('Locale', 'users') }}</label>
                                <v-select :options="localesDropdown" :reduce="value => value.key" :clearable="false"
                                        :class="{'is-invalid': errors && errors.hasOwnProperty('locale')}"
                                        name="locale" class="select2-form" v-model="user.locale"></v-select>
                                <span class="text-danger" v-text="errors['locale'][0]"
                                    v-if="errors && errors.hasOwnProperty('locale') && !user.locale"></span>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Phone', 'users') }}
                                </label>
                                <input type="tel" class="form-control" name="phone" :placeholder="translate('Enter phone number', 'users')"
                                    :class="{'is-invalid': errors && errors.hasOwnProperty('phone')}"
                                    autocomplete="off" v-model="user.phone">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('phone')">{{errors['phone'][0]}}</span>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Role', 'users') }}
                                </label>
                                <v-select
                                  :options="roles"
                                  label="value"
                                  :reduce="value => value.key"
                                  :class="{'is-invalid': errors && errors.hasOwnProperty('roles')}"
                                  name="roles"
                                  class="select2-form"
                                  v-model="user.roles"></v-select>
                                <span class="text-danger" v-text="errors['roles'][0]"
                                    v-if="errors && errors.hasOwnProperty('roles') && !user.roles"></span>
                            </div>
                        </div>
                        <!--
                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Websites', 'users') }}
                                </label>
                                <v-select
                                    multiple
                                    :options="websites"
                                    label="value"
                                    :reduce="value => value.key"
                                    :class="{'is-invalid': errors && errors.hasOwnProperty('roles')}"
                                    name="websites" class="select2-form" v-model="user.website_id">
                                </v-select>
                                <span class="text-danger" v-text="errors['websites'][0]"
                                    v-if="errors && errors.hasOwnProperty('websites') && !user.website_id"></span>
                            </div>
                        </div>
                        -->
                    </div>

<!--                Password disabled for now for new customers -->
<!--                <div class="row pl-3 pr-3">-->
                    <div v-if="!createMode" class="row pl-3 pr-3">
                        <template v-if="selfAccount || createMode">
                            <div v-if="selfAccount" class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-700">
                                        {{ translate('Old Password', 'users') }}
                                    </label>
                                    <input type="password" class="form-control" name="password" autocomplete="old-password"
                                        :class="{'is-invalid': errors && errors.hasOwnProperty('old_password')}"
                                        v-model="user.old_password">
                                    <span class="text-danger" v-if="errors && errors.hasOwnProperty('old_password')">{{errors['old_password'][0]}}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-700">
                                        <span v-if="selfAccount">{{ translate('New Password', 'users') }}</span>
                                        <span v-else>{{ translate('Password', 'users') }}</span>
                                    </label>
                                    <input type="password" class="form-control" name="password" autocomplete="new-password"
                                        :class="{'is-invalid': errors && errors.hasOwnProperty('password')}"
                                        v-model="user.password">
                                    <span class="text-danger" v-if="errors && errors.hasOwnProperty('password')">{{errors['password'][0]}}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-700">
                                        {{ translate('Confirm new password', 'users') }}
                                    </label>
                                    <input type="password" class="form-control" name="password_confirmation"
                                        :class="{'is-invalid': errors && errors.hasOwnProperty('password_confirmation')}"
                                        autocomplete="off" v-model="user.password_confirmation">
                                    <span class="text-danger" v-if="errors && errors.hasOwnProperty('password_confirmation')">{{errors['password_confirmation'][0]}}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-700">{{ translate('Password reset', 'users') }}</label>
                                    <button type="button" class="btn btn-block btn-secondary rounded-pill" @click="sendPasswordReset">{{ translate('Send password reset link', 'users') }}</button>
                                </div>
                            </div>
                        </template>

                        <!--
                        <div class="delimiter-bottom delimiter-bottom-higher w-100 mt-5 mb-4"></div>

                        <div class="col-md-6 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12 mt-3">
                                    <div class="form-group">
                                        <label class="form-control-label">
                                            {{ translate('Password', 'users') }}
                                            <span v-if="pwStrength" :style="{color: pwStrength.color}">{{ pwStrength.strength }}</span>
                                        </label>
                                        <ul>
                                            <li>{{ translate('Atleast one special character', 'users') }}</li>
                                            <li>{{ translate('Atleast one uppercase', 'users') }}</li>
                                            <li>{{ translate('Atleast one number', 'users') }}</li>
                                            <li>{{ translate('Atleast one lowercase', 'users') }}</li>
                                        </ul>
                                        <input type="password" class="form-control" name="password" autocomplete="off"
                                            :class="{'is-invalid': errors && errors.hasOwnProperty('password')}"
                                            v-model="user.password">
                                        <span class="text-danger" v-if="errors && errors.hasOwnProperty('password')">{{errors['password'][0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12 mt-3">
                                    <div class="form-group">
                                        <label class="form-control-label">
                                            {{ translate('Confirm password', 'users') }}
                                        </label>
                                        <input type="password" class="form-control" name="password_confirmation"
                                            :class="{'is-invalid': errors && errors.hasOwnProperty('password_confirmation')}"
                                            autocomplete="off" v-model="user.password_confirmation">
                                        <span class="text-danger" v-if="errors && errors.hasOwnProperty('password_confirmation')">{{errors['password_confirmation'][0]}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        -->

                  </div>
            </div>
          </transition-expand>
        </div>

        <!-- Notifications, Rock only for now -->
        <div class="card rounded bg-white" v-if="$store.state.system.interface === 'rock' && editMode">
          <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible['notifications'] }" @click="visible['notifications'] = !visible['notifications']">
            <h5>{{ translate('Notifications', 'users') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['notifications'] === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible['notifications']" class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ translate('Notifications', 'users') }}</label>
                    <field
                      :field-props="notificationsFieldProps"
                      :value="user.notifications"
                      @input="user.notifications = $event"
                    />
                  </div>
                </div>
              </div>
            </div>
          </transition-expand>
        </div>
        <div class="col-md-12 col-xs-12">
            <div class="form-group text-right">
                <router-link to="/users" class="btn btn-white text-muted border rounded-pill router-link-active"> Go back </router-link>
                <button class="btn btn-primary rounded-pill" @click="save">
                    <span v-if="editMode">{{ translate('Save changes', 'global') }}</span>
                    <span v-else>{{ translate('Create', 'users')}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import { db } from "@/utilities/database"
import { validatePassword } from "@/utilities/helpers/formActions"
import { ImageField } from '@/modules/erp_entities/components/page/form/fields'
import {changeLocale, localesDropdown} from "@/utilities/helper"
import {SweetAlert} from "@/utilities/sweetalert"
import field from "@/modules/erp_entities/components/page/form/InputEntityProps.vue"

export default {
  name: "user-form",
  components: {
    vSelect,
    ImageField,
    field,
  },
  data() {
    return {
      visible: {
        'account_details': true,
        'notifications': true, // Rock only for now
      },
      form: {},
      errors: null,
      // websites: [],
      notificationsFieldProps: { // Rock only for now
        data_type: 'custom',
        data_module: 'user-notifications',
        type: 'table_multiselect',
      },
      user: {
        avatar: '',

        first_name: '',
        last_name: '',
        email: '',

        locale: null,
        phone: '',
        roles: null,

        old_password: '',
        password: '',
        password_confirmation: '',
        // company_id: '', not needed for now
        website_id: '',
        notifications: null, // Rock only for now
      },
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.loadUser(to.params.id);
    next();
  },

  computed: {
    ...mapState('system', ['authData', 'locales', 'timeZones', 'roles']),
    localesDropdown,
    pwStrength() {
      return validatePassword(this.user.password)
    },
    editMode() {
      return !this.createMode
    },
    createMode() {
      return !this.$route.params.id
    },
    selfAccount() {
      return this.authData.user.id == this.$route.params.id
    },
    pageTitle() {
      return this.createMode ? 'Create User' : (this.selfAccount ? 'My profile' : 'Edit User' )
    },
  },
  async created() {
    this.$store.state.system.isLoading = true
    if (this.$route.params.id) {
      this.loadUser(this.$route.params.id)
    } else {
      // default values
      this.user.locale = this.localesDropdown[0].key
    }

    // let websites = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CmsWebsite')
    // console.log('🚀 ~ file: UserForm.vue ~ line 290 ~ created ~ websites', websites)
    // this.websites = websites.data

    // don't send company id, some users don't have one, and we cannot choose one in the form
    // if(!this.editMode){
    //   this.user.company_id = this.$store.state.system.company.key
    // }

  },
  mounted(){
    // not needed for now
    /*
    if(!this.editMode) {
      this.user.password = Math.random().toString(36).slice(-9)
      this.user.password_confirmation = this.user.password
    }
    */
    this.$store.state.system.isLoading = false
  },
  methods: {
    async loadUser(userId) {
      let res = await this.erp.ext.request.axiosInstance.get(`/users/${userId}`)
      if(res) {
        this.user.id = res.data.data.id
        this.user.avatar = res.data.data.avatar
        this.user.first_name = res.data.data.first_name
        this.user.last_name = res.data.data.last_name
        this.user.email = res.data.data.email
        this.user.locale = res.data.data.locale
        this.user.phone = res.data.data.phone

        if(res.data.data.roles[0].id){
          this.user.roles = res.data.data.roles[0].id.toString()
        } else {
          this.user.roles = null
        }

        this.user.website_id = res.data.data.website_id
        this.user.notifications = res.data.data.notifications || null // Rock only for now
      }
    },
    save() {
      this.$store.state.system.isLoading = true

      let method = this.editMode ? 'put' : 'post'
      let route = this.editMode ? `/users/${this.user.id}` : '/users'

      // testing, todo remove me
      // if(this.createMode){
      //   this.user.website_id = 1
      // }

      if(this.createMode){
        this.user.company_id = this.$store.state.system.company.key
      }

      // hot fix - remove old password if empty
      if(this.user.old_password === ''){
        delete this.user.old_password
      }
      if (this.$store.state.system.interface !== 'rock') {
        delete this.user.notifications // Rock only for now
      }

      db[method](route, this.user)
        .then(response => {
          this.$toast.requestSuccess(method, 'User')
          this.$store.state.system.isLoading = false

          if(this.selfAccount){
            changeLocale(this.user.locale)
          } else {
            return this.$router.push(`/users`)
          }
        })
        .catch(error => {
          this.$alert.formattedError(error)
          // this.errors = error.response.data.errors // only alert for now, if enabled field errors must disappear on value change
          this.$store.state.system.isLoading = false
        })
    },
    async sendPasswordReset() {
      const confirmResult = await this.$alert.confirmDefault()
      if(!confirmResult.isConfirmed) return

      this.erp.ext.request.axiosInstance.post('/password/reset', { email: this.user.email }).then(res => {
        this.$toast.success('Password reset link email was sent successfully.')
      }).catch(err => {
        this.$toast.errorGeneral()
      })
    },
  },
}
</script>