<template>
  <span>
    <div class="background-settings-container">
      <div class="row mb-3 d-flex align-items-center" v-for="(usps, uspsKey) in data" :key="uspsKey">
        <div class="col-md-4">
          <icon-picker-field :field-props="fieldProps" v-model="usps.icon" />
        </div>
        <div class="col-md-7">
          <input-field
            v-model="usps.content"
            :field-props="fieldProps"
          />
        </div>
        <div class="col-md-1 text-right">
          <i class="icon-erp-trash text-danger cursor-pointer" @click="canWrite ? removeUsps(uspsKey) : null"></i>
        </div>
      </div>
      <div class="d-flex justify-content-end my-3">
        <button class="btn btn-primary rounded-pill ml-2" @click="canWrite ? addMoreUsps() : null">{{ translate('Add more usps', 'settings') }}</button>
      </div>
    </div>
  </span>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import IconPickerField from "./IconPickerField.vue"
import InputField from "./InputField.vue"

export default {
  name: "UspsSettingsField",
  mixins: [FormFieldMixin],
  components: {
    IconPickerField,
    InputField,
  },
  data() {
    return {
      visible: false,
      key: 0,
      fieldProps: {
        translatable: 1,
      },
      data: [],
    }
  },
  methods: {
    addMoreUsps(){
      let newUsps = {
        icon: '',
        content: {},
      }
      this.data.push(newUsps)
    },
    removeUsps(uspsKey){
      this.data.splice(uspsKey, 1)
    },
  },
  created() {
    if(this.value && typeof this.value == 'string'){
      this.data = JSON.parse(this.value)
    }
  },
  watch: {
    data: {
      deep: true,
      handler(v){
        this.$emit('input', JSON.stringify(this.data))
      },
    },
  },
}
</script>