<template>
  <div>
    <!--
    <div class="active-filters">
        <span class="ml-4 mr-5 system-text-primary">{{ translate('Active filters', 'global') }}:</span>
        <template v-for="(filter, filterIndex) in filters">
          <div class="mr-2" v-show="filter.filterable" v-if="filter.value">
              <span class="tag badge badge-primary">
                  <span class="text-capitalize">{{filter.label}}</span>:
                  <span v-if="typeof filter.value == 'string'">{{filter.value}}</span>
                  <span v-else>{{parseValue(JSON.stringify(filter.value))}}</span>
                  <span class="remove" @click="removeFilter(filterIndex)"><i class="fas fa-times"></i></span>
              </span>
          </div>
        </template>
    </div>
    -->
    <div class="d-flex">
      <div class="filter">
        <filters-accordion @input="filter" :value="filters" @onFiltersClose="$emit('onFiltersClose')"/>
      </div>

<!--            <div class="input-group-append">-->
<!--                <a href="#" class="input-group-text bg-transparent" @click="reset">-->
<!--                    <i class="fas fa-sync-alt"></i>-->
<!--                </a>-->
<!--            </div>-->
    </div>
  </div>
</template>

<script>
import { isVoidValue, parseValue, entityLabel } from '@/utilities/helper'
import FiltersAccordion from "@/modules/erp_entities/components/FiltersAccordion.vue"

export default {
  name: 'filters',
  components: {
    FiltersAccordion,
  },
  filters: {
    entityLabel,
  },
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      filters: null,
    }
  },
  created(){
    this.filters = this.data
  },
  methods: {
    createFilterObject(filterData) {
      let filterObj = {}

      for (const filter of filterData) {
        let value = filter.value

        if(isVoidValue(filter.value)){
          continue
        }

        let compare = '='
        if(['text', 'textarea', 'simple_textarea', 'text_with_caution'].includes(filter.type)){
          value = '%'+value+'%'
          compare = 'like'
        } else if(['multiselect'].includes(filter.type)){
          compare = 'in'
        }

        filterObj[filter.name] = {
          prop: filter.name,
          compare: compare,
          value: value,
        }
      }

      return filterObj
    },
    reset(){
      this.filters = this.data
      this.$emit('filter')
    },
    filter(filters){
      this.$emit('filter', this.createFilterObject(filters))
    },
    removeFilter(i) {
      this.filters[i].value = null
      this.filter()
    },
    parseValue,
  },
}
</script>

<style scoped>
    .filter {
        display: flex;
        flex-wrap: wrap;
    }

    .input-group-append {
        align-self: baseline;
        margin-left: auto;
    }

    .input-group-text {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid $border-color;
    }

    .active-filters {
        display: flex;
        padding: 0.6rem 0;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $border-color;
        /*border-top: 1px solid $border-color;*/
    }

    .filters-container {
        margin-bottom: 1.5rem;
    }

    .tag.badge {
        font-size: 15px;
    }

    .remove {
        visibility: hidden;
        opacity: 0;
        font-size: 14px;
        cursor: pointer;
        transition: 0.2s all ease;
    }

    .remove i {
        width: 0;
    }

    .tag.badge:hover > .remove {
        visibility: visible;
        opacity: 1;
        padding-left: 0.5rem;
        transition: 0.2s all ease;
    }

    .tag.badge:hover > .remove i {
        width: auto;
    }
</style>
