<template>
  <div class="container mb-3 px-0" v-if="shipping">
    <div class="card rounded bg-white">
      <div class="card-header">
        <h5>{{ translate('General info', 'shipping') }}</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ translate('Courier title', 'shipping') }}</label>
              <input v-model="shipping.courier" class="form-control">
            </div>
          </div>
          <div v-if="vatTypes" class="col-md-6" >
            <div class="form-group">
              <label>{{ translate('VAT Group', 'shipping') }}</label>
              <v-select
                  :options="vatTypes"
                  v-model="shipping.vat_type_id"
                  class="select2-form"
                  label="value"
                  :reduce="value => value.key">
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>{{ translate('Enable cash on delivery', 'shipping') }}</label>
              <div class="d-flex justify-content-between py-2">
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="cash_on_delivery"
                      id="cash_on_delivery"
                      class="custom-control-input"
                      true-value="1" false-value="0"
                      v-model="shipping.cash_on_delivery"
                  />
                  <label for="cash_on_delivery" class="custom-control-label"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>{{ translate('Status', 'shipping') }}</label>
              <div class="d-flex justify-content-between py-2">
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="status"
                      id="status"
                      class="custom-control-input"
                      true-value="1" false-value="0"
                      v-model="shipping.status"
                  />
                  <label for="status" class="custom-control-label"></label>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!isFlatRate">
            <div class="col-md-3">
              <div class="form-group mb-0">
                <label>{{ translate('Allow free shipping', 'shipping') }}</label>
                <div class="d-flex justify-content-between py-2">
                  <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        name="enable_free_shipping"
                        id="enable_free_shipping"
                        class="custom-control-input"
                        v-model="shipping.enable_free_shipping"
                        true-value="1"
                        false-value="0"
                    />
                    <label for="enable_free_shipping" class="custom-control-label"></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3" v-if="shipping.enable_free_shipping != 0">
              <div class="form-group">
                <label>{{ translate('Free shipping above', 'shipping') }}</label>
                <input class="form-control" type="number" v-model.number="shipping.free_shipping_above">
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Local Pickup - Pickup Location -->
    <template v-if="shippingType === 'local_pickup'">
      <div class="card rounded bg-white">
        <div class="card-header">
          <h5>{{ translate('Pickup Location', 'shipping') }}</h5>
        </div>

        <!-- Specific countries or regions always on -->

        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('Pickup location name', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.title">
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('Street name', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.address">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('Street number', 'shipping')}}</label>
                <input class="form-control" type="number" v-model="shipping.street_number">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('Address line 2', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.address_additional_information">
                <small class="form-text text-muted">{{ translate('Ex: Apartment, suite, unit, building, floor, etc.', 'global') }}</small>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('ZIP', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.zip">
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('City', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.city">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('State', 'shipping')}}</label>
                <input class="form-control" type="text" v-model="shipping.region">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{translate('Country', 'shipping')}}</label>
                <countries
                    v-model="countries"
                    :multiple="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- ELSE Flat Rate / Table Rate - Countries -->
    <template v-else>
      <div class="card rounded bg-white">
        <div class="card-header">
          <h5>{{ translate('Countries', 'shipping') }}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6" >
              <div class="form-group">
                <label>{{ translate('Ship to', 'shipping') }}</label>
                <v-select
                    :options="shipToOptions"
                    :clearable="false"
                    v-model="shipping.ship_to_all_countries"
                    class="select2-form"
                    label="label"
                    :reduce="value => value.key">
                  >
                </v-select>
              </div>
            </div>

            <div v-if="!shipping.ship_to_all_countries" class="col-md-8">
              <countries
                  v-model="countries"
              />
              <div class="col-md-12">
                <label>{{ translate('Shipping cost and courier to selected countries or regions are the same', 'shipping') }}</label>
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        name="same_pricing"
                        id="same_pricing"
                        class="custom-control-input"
                        v-model="shipping.same_pricing"
                        true-value="1"
                        false-value="0"
                    />
                    <label
                        for="same_pricing"
                        class="custom-control-label">
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>

    <div class="card rounded bg-white">
      <div class="card-header">
        <h5>{{ translate('Pricing', 'shipping') }}</h5>
      </div>
      <div class="card-body">
        <div class="row">

          <!-- FLAT RATE -->
          <template v-if="isFlatRate">
            <div class="col-md-12">

              <!-- FLAT RATE - SAME PRICE FOR ALL COUNTRIES -->
              <template v-if="shipping.same_pricing == 1">
                <FlatRate
                    v-model="defaultFlatRateShippingPrices"
                />
              </template>

              <!-- FLAT RATE - DIFFERENT PRICE PER COUNTRY -->
              <template v-else>
                <FlatRate
                    v-for="(p,i) in shippingPrices"
                    :key="i"
                    v-model="shippingPrices[i]"
                    :regionName="regionName(i)"
                />
              </template>
            </div>

          </template>

          <!-- TABLE RATE / LOCAL PICKUP -->
          <template v-else>
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ translate('Condition', 'shipping') }}</label>
                <v-select
                    :options="conditions" label="value" :reduce="value => value.key"
                    v-model="shipping.condition_name" class="select2-form"></v-select>
              </div>
            </div>

            <div class="col-md-12" v-if="shipping.condition_name">

              <!-- TABLE RATE - SAME PRICE FOR ALL COUNTRIES -->
              <div v-if="shipping.same_pricing == 1">
                <TableRates
                    :trkey="'ALL'"
                    :condition_name="shipping.condition_name"
                    v-model="defaultTableRateShippingPrices"
                />
              </div>

              <!-- TABLE RATE - DIFFERENT PRICE PER COUNTRY -->
              <div v-else>
                <TableRates
                    v-for="(p,i) in shippingPrices"
                    :key="i"
                    :trkey="i"
                    :condition_name="shipping.condition_name"
                    :regionName="regionName(i)"
                    v-model="shippingPrices[i]"
                />
              </div>
              <div class="d-flex mt-2" v-for="(displayError, key) in displayErrors" :key="key">
                <small class="help text-danger" v-if="displayErrors[key]">{{ translate(errorMessages[key], 'settings') }}</small>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-3">
      <router-link class="btn btn-white rounded-pill" to="/webshop-settings/shipping/list">
        {{ translate('Go back', 'global') }}
      </router-link>
      <button
          class="btn btn-primary rounded-pill"
          @click="save()"
      >
        {{ translate($route.params.slug? 'Update' : 'Create', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import Countries from "./Countries";
import FlatRate from "@/modules/sitecart/views/shipping/FlatRate";
import TableRates from "@/modules/sitecart/views/shipping/TableRates";

export default {
  name: "EditShipping",
  components: {
    FlatRate,
    Countries, TableRates
  },
  props: {
    initialShipping: {
      required: true,
    },
    shippingType: {
      required: true
    },
    shippingShipToSingleCountry: {
      required: true
    },

    // shipping price(s) for all countries
    initialDefaultFlatRateShippingPrices: {
      type: Object,
      required: true,
    },
    initialDefaultTableRateShippingPrices: {
      type: Object,
      required: true,
    },

    // Shipping price(s) per country
    initialShippingPrices: {
      type: Object,
      required: false,
    },
    initialCountries: {
      type: Array,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      shipping: this.initialShipping,
      countries: this.initialCountries,

      defaultFlatRateShippingPrices: this.initialDefaultFlatRateShippingPrices,
      defaultTableRateShippingPrices: this.initialDefaultTableRateShippingPrices,
      shippingPrices: this.initialShippingPrices,

      vatTypes: null,
      errors_count: 0,
      displayErrors: {},
    }
  },
  computed: {
    shipToOptions() {
      return [
        { label: this.translate('All countries', 'shipping'), key: 1 },
        { label: this.translate('Specific countries or regions', 'shipping'), key: 0 },
      ]
    },
    // Table rate Conditions
    conditions() {
      return [
        { key: 'package_weight', value: this.translate('Package weight', 'entities') },
        { key: 'package_value', value: this.translate('Package value', 'entities') },
        { key: 'package_qty', value: this.translate('Package quantity', 'entities') },
      ]
    },
    defaultPriceFields(){
      return {
        id: null,
        title: '',
        price: '',
        enable_free_shipping: 0,
        free_shipping_above: "",
        // only for table rate & local pickup
        selected: 0,
        condition_to_value: '',
        condition_from_value: '',
        // END only for table rate & local pickup
      }
    },
    isFlatRate() {
      return this.shippingType === 'flat_rate'
    },
    errorMessages() {
      return {
        required_fields: 'Please fill the required fields',
        price_from_till: 'Min cannot be higher than Max',
        overlapping: 'You cannot use those values because they are already overlapping',
      }
    },
  },
  async created() {

    // Override default values based on select shipping type
    if(this.shippingType === 'local_pickup'){
      this.shipping.ship_to_all_countries = 0
      this.shipping.same_pricing = 1 // just in case, it is 1 by default
    }

    let vatTypes = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/VatType?perpage=9999');
    this.vatTypes = vatTypes.data.data;
  },

  async mounted() {
    this.$parent.$on('save-courier', () => {
      this.save();
    })
  },
  methods: {
    generateSlug() {
      if(this.shipping.courier) {
        this.shipping.slug = this.shipping.courier.toLowerCase().replace(" ", '-');
      }
    },
    regionName: function(code){
      // todo human readable country/region name
      return code;
    },
    setInitialDisplayErrors(){
      this.displayErrors = {
        required_fields: false,
        price_from_till: false,
        overlapping: false,
      }
    },
    validateFields() {
      this.errors_count = 0;
      if(!this.shipping.ship_to_all_countries){
        if(this.countries === null || this.countries.length === 0){
          this.errors_count++;
          this.$toast.error('Please enter at least one country!')
        }
      }
      // todo validate ranges if table rate and

      if(!this.isFlatRate) {
        this.setInitialDisplayErrors();

        // SAME PRICE FOR ALL COUNTRIES
        if(this.shipping.same_pricing == 1) {
          let rates = []
          this.defaultTableRateShippingPrices.rates.forEach(price => {
            rates.push(this.validateRate(price));
          });
          this.$set(this.defaultTableRateShippingPrices, 'rates', rates);
        } else {
          // DIFFERENT PRICE PER COUNTRY
          Object.keys(this.shippingPrices).forEach(country_code => {
            let rates = []
            let ranges = [];
            this.shippingPrices[country_code].rates.forEach(price => {
              price = this.validateRate(price);
              // check ranges if the fields are good so far
              if (!price.errors.condition_from_value && !price.errors.condition_to_value) {
                // check if Price from	<= Price till
                if (price.condition_from_value > price.condition_to_value) {
                  price.errors.condition_from_value = true;
                  price.errors.condition_to_value = true;
                  this.errors_count++;
                  this.displayErrors.price_from_till = true;
                } else {
                  // else check if the range is overlapping{
                  if(ranges.length) {
                    ranges.forEach(range => {
                      let error_range = false

                      if(price.condition_from_value >= range.price_from && price.condition_from_value <= range.price_till){
                        error_range = true;
                        price.errors.condition_from_value = true;
                        this.errors_count++;
                        this.displayErrors.overlapping = true;
                      }

                      if(price.condition_to_value >= range.price_from && price.condition_to_value <= range.price_till){
                        error_range = true;
                        price.errors.condition_to_value = true;
                        this.errors_count++;
                        this.displayErrors.overlaping = true;
                      }

                      if(price.condition_from_value <= range.price_from && price.condition_to_value >= range.price_till){
                        error_range = true;
                        price.errors.condition_from_value = true;
                        price.errors.condition_to_value = true;
                        this.errors_count++;
                      }

                      // no need to check other ranges if there are errors
                      if(error_range){
                        return
                      }
                    });
                  }
                }
              }
              rates.push(price);
              ranges.push({
                price_from: price.condition_from_value,
                price_till: price.condition_to_value
              });
            });
            this.$set(this.shippingPrices[country_code], 'rates', rates);
            this.$forceUpdate();
          });
        }
      }
    },
    validateRate(price) {

      price['errors'] = {};

      if(price.condition_from_value === null || price.condition_from_value.length === 0){
        price.errors.condition_from_value = true;
        this.errors_count++;
        this.displayErrors.required_fields = true;
      }
      if(price.condition_to_value === null || price.condition_to_value.length === 0){
        price.errors.condition_to_value = true;
        this.errors_count++;
        this.displayErrors.required_fields = true;
      }
      if(price.price === null || price.price.length === 0){
        price.errors.price = true;
        this.errors_count++;
        this.displayErrors.required_fields = true;
      }
      // END check if the fields are empty

      // hot fix, todo remove me after the api is fixed to return float but not string
      if(!price.errors.condition_from_value){
        price.condition_from_value = parseFloat(price.condition_from_value)
      }
      if(!price.errors.condition_to_value){
        price.condition_to_value = parseFloat(price.condition_to_value)
      }
      // END hot fix
      return price;
    },
    async save() {
      await this.validateFields();
      if(this.errors_count > 0){
        return
      }

      this.$store.state.system.isLoading = true;

      if(!this.shipping.shipping_type) {
        this.shipping.shipping_type = this.shippingType
      }

      if(this.isFlatRate) {
        delete this.shipping.free_shipping_above
        await this.setFlatRatePrices();
      } else {
        await this.setTableRatePrices();
      }

      let isUpdate = typeof this.$route.params.slug !== 'undefined' ? true : null;
      if(!isUpdate){
        this.generateSlug()
      }
      this.erp.ext.request.axiosInstance.post('/modules/shipping/rates', this.shipping).then(() => {
        const method = isUpdate ? 'put' : 'post'
        this.$toast.requestSuccess(method, 'Shipping method')
        this.$router.push('/webshop-settings/shipping/list')
      }).catch(e => {
        this.$toast.error('Shipping method not saved successfully!')
      })
      this.$store.state.system.isLoading = false
    },

    // On save shipping must include prices in it
    async setFlatRatePrices() {
      let prices = [];

      // Ship to all countries
      if(this.shipping.ship_to_all_countries) {
        prices.push({...this.defaultPriceFields, ...this.shipping, ...this.defaultFlatRateShippingPrices,
          country: null,
          title: null,
          ship_to_all_countries: 1,
          same_pricing: 1,
        });
        // Ship to specific countries
      } else {

        // covert to array if ship to single country
        let countries = this.shippingShipToSingleCountry ? [this.countries] : this.countries

        await countries.forEach((country_code) => {
          // Same price for all countries
          if(this.shipping.same_pricing == 1) {
            prices.push({...this.defaultPriceFields, ...this.shipping,
              ...this.shippingPrices[country_code], // get the id from here
              price: this.defaultFlatRateShippingPrices.price,
              enable_free_shipping: this.defaultFlatRateShippingPrices.enable_free_shipping,
              free_shipping_above: this.defaultFlatRateShippingPrices.free_shipping_above,
              country: country_code
            });
            // Different price per country
          } else {
            prices.push({...this.defaultPriceFields, ...this.shipping,
              ...this.shippingPrices[country_code],
              country: country_code
            });
          }
        })
      }

      //this.$set(this.shipping, 'prices', prices);
      this.shipping.prices = prices;
    },

    // Table rate (also used in Local Pickup)
    async setTableRatePrices() {
      let prices = []

      // Ship to all countries
      if(this.shipping.ship_to_all_countries) {

        this.defaultTableRateShippingPrices.rates.forEach(rate => {
          prices.push({
            ...this.defaultPriceFields,
            ...this.shipping,
            ...rate,
            country: null
          });
        });

        // Ship to specific countries
      } else {

        // covert to array if ship to single country
        let countries = this.shippingShipToSingleCountry ? [this.countries] : this.countries

        await countries.forEach(country_code => {

          // Same price for all countries
          if (this.shipping.same_pricing == 1) {
            this.defaultTableRateShippingPrices.rates.forEach(rate => {
              prices.push({
                ...this.defaultPriceFields,
                ...this.shipping,
                ...rate,
                id: null, // hot fix, its hard to keep the ids if its same pricing
                country: country_code
              });
            });

            // else Different price per country
          } else {
            this.shippingPrices[country_code].rates.forEach(rate => {
              prices.push({
                ...this.shipping,
                ...rate,
                free_shipping_above: this.shipping.free_shipping_above,
                enable_free_shipping: this.shipping.enable_free_shipping,
                country: country_code
              });
            });
          }
        }); // end foreach countries
      }

      //this.$set(this.shipping, 'prices', prices);
      this.shipping.prices = prices

    }
  },
  watch: {
    countries: function () {

      // unset countries if any
      if(this.shippingPrices){
        Object.keys(this.shippingPrices).forEach(key => {
          if(!this.countries.includes(key)){
            delete this.shippingPrices[key]
          }
        });
      }

      // covert to array if ship to single country
      let countries = this.shippingShipToSingleCountry ? [this.countries] : this.countries

      countries.forEach(country_code => {
        if(this.shippingPrices === null){
          this.shippingPrices = {}
        }

        if(typeof this.shippingPrices[country_code] === 'undefined'){
          let prices = {}
          // FLAT RATE
          if(this.isFlatRate) {
            prices = {...this.defaultPriceFields,
              country: country_code,
              id: null
            };

            // TABLE RATE
          } else {
            prices = {rates: []}
            prices.rates.push({...this.defaultPriceFields,
              country: country_code,
              id: null
            })
          }

          // must use this.$set, otherwise there is no reactivity, allow free shipping does not work for example
          this.$set(this.shippingPrices, country_code, prices)
        }
      });

    }
  }
}
</script>

<style scoped>

</style>
