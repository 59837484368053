<template>
    <div class="container">

        <page-title :page-title="translate('Create {entity}', 'entities', { entity: translate('Customer', 'entities') })" buttons="/webshop-customers">

            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="save">
                    <span>{{ translate('Create', 'global') }}</span>
                </button>
            </template>
        </page-title>

        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible['account_details'] }" @click="visible['account_details'] = !visible['account_details']">
            <h5>{{ translate('Account details', 'customers') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible['account_details'] === true}"></i>
          </div>
          <transition-expand>
            <div class="card-body" v-show="visible['account_details']">
                <div class="row">
                    <div class="col-12">
                        <p>{{ translate('After the customer is created an automatic email will be send to the customer containing a random password.', 'entities') }}</p>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('First name', 'entities') }} *</label>
                            <input type="text" name="first_name" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('first_name')}"
                                v-model="value.first_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('first_name')">{{errors.first_name[0]}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Last name', 'entities') }} *</label>
                            <input type="text" name="last_name" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('last_name')}"
                                v-model="value.last_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('last_name')">{{errors.last_name[0]}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Email', 'entities') }} *</label>
                            <input type="text" name="email" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('email')}"
                                v-model="value.email">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('email')">{{errors.email[0]}}</span>
                        </div>
                    </div>
                    <!-- not in create for now
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Phone', 'entities') }}</label>
                            <input type="text" name="phone" class="form-control"
                                v-model="value.phone">
                        </div>
                    </div>
                     -->
                    <!-- moved to addresses
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Company name', 'entities') }}</label>
                            <input type="text" name="company_name" class="form-control"
                                v-model="value.company_name">
                        </div>
                    </div>
                    -->
                    <!-- not in create for now, moved to addresses
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('VAT Number', 'entities') }}</label>
                            <input type="text" name="vat_number" class="form-control"
                                v-model="value.vat_number">
                        </div>
                    </div>
                    -->
                    <!-- not in create for now, moved to addresses
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Chamber of commerce', 'entities') }}</label>
                            <input class="form-control" v-model="value.chamber_of_commerce">
                        </div>
                    </div>
                    -->
                    <!-- not in create for now, moved to addresses
                    <div class="col-md-4" v-if="websites">
                        <div class="form-group">
                            <label>{{ translate('Website', 'entities') }}</label>
                            <v-select :options="websites" :reduce="value => value.key" :value="value.website_id"
                                    @input="v => value.website_id = v" label="value" class="select2-form"></v-select>
                        </div>
                    </div>
                    -->
                    <div class="col-md-4" v-if="customer_groups">
                        <div class="form-group">
                            <label>{{ translate('Customer group', 'entities') }}</label>
                            <v-select :options="customer_groups" :reduce="value => value.key" :value="value.customer_group_id" :clearable="false"
                                    @input="v => value.customer_group_id = v" label="value" class="select2-form"></v-select>
                        </div>
                    </div>
                </div>
            </div>
          </transition-expand>
        </div>

        <div class="d-flex justify-content-end">
            <router-link class="btn btn-white rounded-pill" to="/webshop-customers">
                {{ translate('Go back', 'global') }}
            </router-link>

            <button class="btn btn-primary rounded-pill" @click="save">
                <span>{{ translate('Create', 'global') }}</span>
            </button>
        </div>

    </div>

</template>

<script>
export default {
    name: "CustomerCreate",
    data() {
        return {
          visible: {
            'account_details': true,
          },
            value: {
                first_name: '',
                last_name: '',
                email: '',
                //phone: '',
                company_name: '',
                //vat_number: '',
                //chamber_of_commerce: '',
                //website_id: '',
                customer_group_id: null,
            },
            websites: null,
            customer_groups: null,
            errors: {}
        }
    },
    async created() {
        /* if needed
        if (!this.$route.params.id) {
            this.data.company_id = this.$store.state.system.company.key
            this.data.website_id = this.$store.state.system.scope.value
        }*/
        let websites = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CmsWebsite?perpage=9999')
        this.websites = websites.data.data

        await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CustomerGroup?perpage=9999').then((res) => {
          this.customer_groups = res.data.data
        })

        // find default customer group
        let default_customer_group = this.customer_groups.find(x => x.value === 'Default') // not nice but we don't have slug or any other flag
        if(default_customer_group === undefined){
            default_customer_group = default_customer_group[0]
        }
        this.value.customer_group_id = default_customer_group.key

    },
    methods: {
    async save() {

        this.validateFields();

        if(Object.keys(this.errors).length){
            return;
        }

        let data = {
            first_name: this.value.first_name,
            last_name: this.value.last_name,
            email: this.value.email,
            //phone: this.value.phone,
            company_name: this.value.company_name,
            //vat_number: this.value.vat_number,
            //chamber_of_commerce_number: this.value.chamber_of_commerce_number,
            //website_id: this.value.website_id,
            customer_group_id: this.value.customer_group_id,
        }

        await this.erp.ext.request.axiosInstance.post(`/customers`, data)
            .then((res) => {
              this.$toast.requestSuccess('post', 'Customer')
              this.$router.push(`/webshop-customers`)
            }).catch(error => {
              this.$alert.formattedError(error)
              // this.errors = error.response.data.errors // only alert for now, if enabled field errors must disappear on value change
            })

    },
    validateFields(){
        this.errors = {};
        if(!this.value.first_name.replace(/\s/g,'').length) {
            this.errors.first_name = ['This field is required'];
        }
        if(!this.value.last_name.replace(/\s/g,'').length) {
            this.errors.last_name = ['This field is required'];
        }
        if(!this.value.email.replace(/\s/g,'').length) {
            this.errors.email = ['This field is required'];
        }
    },
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },
  }
}
</script>

<style scoped>

</style>
