<template>
  <div class="container">
    <page-title :page-title="translate('Webshop customers', 'entities')" v-if="records"
                :counter="records.meta.total">
        <template v-slot:actions>
          <router-link class="btn btn-primary rounded-pill" to="/webshop-customers/create">
            {{ translate('Add new {entity}', 'entities', { entity: translate('Customer', 'entities').toLowerCase() }) }}
          </router-link>
        </template>
      </page-title>

    <div class="row" v-if="records">
      <div class="col-md-12">
        <div class="main-table mt-4">
          <div class="d-flex justify-content-between align-items-center table-header px-4">
            <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
              <div class="search-icon">
                <span><i class="fa fa-search"></i></span>
              </div>
              <input type="text" class="form-control h-100 search-field"
                     :placeholder="translate('Search', 'customers')"
                     :value="userPreferences.search"
                     @input="e => setSearch(e.target.value)">
            </div>
            <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
              <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false" v-if="records">
                  {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                  <i class="fa fa-chevron-down"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                  <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi"
                     @click="setProp('perpage', per_page.key)">
                    {{ per_page.value }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center m-0">
              <thead class="thead-light">
              <tr>
                <th scope="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" @input="e => selectAll(e.target.checked)"
                           class="custom-control-input"
                           :id="`select_all`">
                    <label class="custom-control-label" :for="`select_all`"></label>
                  </div>
                </th>
                <th scope="col">{{ translate('Full name', 'entities') }}</th>
                <th scope="col">{{ translate('Email', 'entities') }}</th>
                <th v-if="manually_approve_registration" scope="col">{{ translate('Status', 'entities') }}</th>
                <th scope="col">{{ translate('Website', 'entities') }}</th>
                <th scope="col">{{ translate('Customer group', 'entities') }}</th>
                <th scope="col">{{ translate('Created at', 'global') }}</th>
                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-1" v-for="(customer, customerI) in records.objectData" :key="customerI">
                <td>
                  <div class="custom-control custom-checkbox">
                    <input :id="'row-' + customerI" type="checkbox" class="custom-control-input"
                           v-model="records.objectData[customerI].selected">
                    <label class="custom-control-label" :for="'row-' + customerI"></label>
                  </div>
                </td>
                <td scope="row">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <router-link :to="`/webshop-customers/${customer.id}/account-information`"
                                   class="user-name mb-0 table">
                        {{ customer.first_name }} {{ customer.last_name }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td>{{ customer.email }}</td>
                <td v-if="manually_approve_registration">
                  <div class="status-wrap">
                    <span
                      class="status-badge"
                      :class="approvalStatusClass(customer.approved)"
                    >
                      {{ approvalStatuses[customer.approved] }}
                    </span>
                  </div>
                </td>
                <td>{{ customer.website_id }}</td>
                <td>{{ getCustomerGroupName(customer.customer_group_id) }}</td>
                <td>{{ formatDate(customer.created_at, null, 'DD MMMM YYYY - HH:mm') }}</td>
                <td class="text-right">
                  <cta>
                    <action-button class-icon="fa-pen" @click.native="$router.push(`/webshop-customers/${customer.id}/account-information`)"></action-button>
                    <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(customer.id)"></action-button>
                  </cta>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                      :value="records.meta" v-if="records && records.meta"
                      @goToPage="v => setProp('page', v)"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntityIndexMixin from "@/mixins/EntityIndexMixin"
import { getWebsiteSettings } from "@/utilities/helpers/websiteSettings"

export default {
  name: "Customers",
  mixins: [EntityIndexMixin],
  data() {
    return {
      customer_groups: null,
      manually_approve_registration: false,
    }
  },
  async created() {
    this.entityType = 'customers'

    await this.loadUserPreferences()
    await this.loadData()

    await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CustomerGroup?perpage=9999').then((res) => {
      this.customer_groups = res.data.data
    })
  },
  mounted() {
    this.getRegistrationData();
  },
  computed: {
    approvalStatuses() {
      return {
        '-1': 'Declined',
        '0': 'Waiting approval',
        '1': 'Approved',
      }
    },
  },
  methods: {
    approvalStatusClass(approved){
      switch (approved.toString()) {
        case '-1': return 'status_approved_declined'
        case '0': return 'status_approved_waiting_approval'
        case '1': return 'status_approved_approved'
        default: return ''
      }
    },
    // remove me after customers start using datatable
    getCustomerGroupName(customer_group_id){
      if(!this.customer_groups) return ''

      let customer_group = this.customer_groups.filter(item => {
        return parseInt(item.key) === parseInt(customer_group_id)
      });
      if(customer_group[0] !== undefined){
        return customer_group[0].value || ""
      } else {
        return ""
      }
    },
    async getRegistrationData() {
      this.$store.state.system.isLoading = true

      // B2B settings
      const b2b_settings = await getWebsiteSettings('b2b-settings-cmswebsite')
      this.manually_approve_registration = Boolean(b2b_settings['registration.manually_approve_registration'])

      this.$store.state.system.isLoading = false
    },
  },
}
</script>