<template>
  <select-dynamic-field :field-props="{ data_type: 'module', data_filters: ['inventory_type']}"
                        :data-filters="{ inventory_type: { condition: '=', value: 'simple' } }"
                        :placeholder="translate('Search product', 'orders')"
                        :value="value"
                        @input="addToCart($event)"
                        customRoute="modules/inventory"
                        has-option-data-slot
                        class="dropdown-menu-advanced"
  >
    <template v-slot:option-data="option">
      <div class="row my-2">
        <div class="col-xl-2 col-lg-12">
          <div class="product-image">
            <img :src="getFirstImage(option.rawObject.images)" :alt="entityLabel(option.rawObject.name)">
          </div>
        </div>

        <div class="col-xl-7 col-lg-12">
          <div class="row">
            <div class="col-md-12">
              <span class="font-weight-bold">{{ entityLabel(option.rawObject.options.name) }}</span>
            </div>
            <div class="col-md-12">
              <span class="text-muted">{{ translate('SKU:', 'products') }} {{ option.rawObject.sku }}</span>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-12 text-right">
          <h6 v-html="option.rawObject.price.priceFormatted"></h6>
        </div>
      </div>
    </template>

  </select-dynamic-field>
</template>

<script>
import SelectDynamicField from "@/modules/erp_entities/components/page/form/fields/components/SelectDynamicField.vue"

export default {
  name: "ProductSelect",
  components: {
    SelectDynamicField,
  },
  props: {
    cartItems: {
      required: true,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  methods: {
    addToCart(product_id) {
      if(!product_id){
        return
      }

      if(this.cartItems.some(el => el.item_id === product_id.toString())){
        return this.$toast.error('This product is already added.')
      }

      this.$store.state.system.isLoading = true

      const item = {
        item_id: product_id,
        qty: 1,
      }

      this.erp.ext.request.axiosInstance.post('/modules/sales/cart/item/add', item)
        .then(res => this.$emit('updateCart', { cart_overview: res.data.data['cart_overview'], items: res.data.data['items'] }))
        .catch(err => this.$alert.errorsFormatted(err))
        .finally(() => this.$store.state.system.isLoading = false)
    },
    getFirstImage(images) {
      if (images && images.length > 0) {
        return images[0].url
      }
      return '/img/fallback.png'
    },
  },
}
</script>

<style lang="scss">
.dropdown-menu-advanced {
  .product-image {
    img {
      width: 100%;
      max-height: 100px;
    }
  }
}
</style>