<template>
  <div class="left-sidebar-content components-sidebar">
    <div class="left-sidebar-content--container components-sidebar--container nav-scrollbar">

      <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
        <div>
          <h6>{{ translate('Components', 'page-builder') }}</h6>
          <p><small class="info mt-1">{{ translate('Simply drag and drop any desired item into a column to start using it.', 'page-builder') }}</small></p>
        </div>
        <div class="close-sidebar">
          <a @click="closeLeftSidebar">
            <i class="icon-erp-plus icon-erp-rotate-45 text-bold"></i>
          </a>
        </div>
      </div>

      <div class="form-group">
        <input-field
          v-model="search"
          :field-props="{ type: 'text' }"
          :placeholder="translate('Search component', 'page-builder')"
          ref="componentSearch"
        />
      </div>

      <components-accordion
        components-type="General"
        :block-data="generalComponents"
        :search="search"
        @dragStart="closeLeftSidebar"
      />
      <components-accordion
        components-type="Premium"
        :block-data="premiumComponents"
        :search="search"
        @dragStart="closeLeftSidebar"
      />
    </div>
    <contact-sales-modal />
  </div>
</template>

<script>
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { mapState } from "vuex"
import ComponentsAccordion from "@/modules/pagebuilder/components/left-sidebar/entities/components/ComponentsAccordion.vue"
import ContactSalesModal from "@/components/ContactSalesModal.vue"

export default {
  name: 'Components',
  components: {
    ContactSalesModal, ComponentsAccordion, InputField,
  },
  inject: [
    'editor',
  ],
  data(){
    return {
      generalComponents: [],
      premiumComponents: [],
      search: '',
    }
  },
  computed: {
    ...mapState('builder', ['blocks']),
  },
  created() {
    if (this.blocks) {
      const validComponents = this.blocks.objectData.filter(objData => {
        return objData.name
          && (!objData.environments || objData.environments.includes(this.$store.state.system.interface))
          && objData.status === '1'
          && typeof objData.component !== 'undefined'
          && objData.component !== null
          && !['MainContainerElement', 'SectionElement', 'ColumnElement'].includes(objData.component) // skip system only components
      })

      validComponents.forEach(component => {
        if (component.premium === '1') {
          this.premiumComponents.push(component)
        } else {
          this.generalComponents.push(component)

        }
      })
    }
  },
  methods: {
    closeLeftSidebar() {
      // hot fix timeout added because when you scroll the sidebar and start to drag component from bellow (Embed Video, Form, Products) cannot be dragged.
      setTimeout(() => {
        this.$emit('closeLeftSidebar')
      }, 10)

    },
    focusInput() {
      this.$nextTick(() => this.$refs['componentSearch'].$refs['input'].focus())
    },
  },
}
</script>

<style scoped lang="scss">
.single-snippet img {
  object-fit: contain;

  &.snippet-image-normal {
    width: 90px !important;
  }

  &.snippet-image-dynamic {
    width: 40px !important;
  }
}
</style>
