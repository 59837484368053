<template>
  <div>
    <vue-dropzone
      class="rounded-lg"
      ref="myVueDropzone"
      :options="dropzoneOptions"
      use-custom-slot
      @vdropzone-success="(file, response) => vdropzoneSuccess(file, response)"
      @vdropzone-file-added-manually="(file) => vdropzoneFileAddedManually(file)"
      @vdropzone-removed-file="(file) => vdropzoneFileRemoved(file)"
      @vdropzone-mounted="vdropzoneMounted"
    >
<!-- @vdropzone-sending="(file, xhr, formData) => vdropzoneSending(file, xhr, formData)" -->
      <div class="dropzone-custom-content align-content-center">
        <h6 class="dropzone-custom-title align-middle">
          <i class='fa fa-cloud-upload'></i>
          {{ 'Drag and drop to upload content' | translate('entities') }}
        </h6>
        <div class="subtitle">{{ 'or click to select a file from your computer' | translate('entities') }}</div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import store from "@/store"
import { environment } from '@/utilities/helper'

export default {
  name: "FileUploadField",
  mixins: [FormFieldMixin],
  components: {
    VueDropzone,
  },
  data() {
    return {
      files: [],
      dropzoneOptions: {
        url: `${environment.api_domain}/api/modules/documents`,
        thumbnailWidth: 200,
        maxFilesize: 1000,
        addRemoveLinks: true,
        dictRemoveFile: `<i class='far fa-trash-can'> </i> ${this.translate('Delete', 'entities')}`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('authorizationToken')}`,
          user_id: store.state.system.authData.user_id,
          company: store.state.system.company.key,
          website: store.state.system.scope.value,
          package: 'Cms',
        },
      },
    }
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
  methods: {
    async vdropzoneMounted() {
      if (this.isDisabled) {
        this.$refs.myVueDropzone.disable()
        this.$refs.myVueDropzone.setOption('addRemoveLinks', false)
      }

      await this.fetchFiles()
      if (this.files.length) {
        this.files.forEach(file => {
          /*
          Just for reference if we want to show only files that current user has uploaded
          let currentUserId = this.$store.state.system.authData?.user?.id
          if (currentUserId == file.created_by) {}
           */
          this.$refs.myVueDropzone.manuallyAddFile(
            {
              name: file.original_filename,
              type: file.mime_type,
              id: file.id,
              url: file.url,
              // size: file.size,
            }, file.url)
        })
      }
    },
    /*
    vdropzoneSending(file, xhr, formData) {
      this.$refs.myVueDropzone.processQueue()
      formData.append('size', file.size)
    },
     */
    vdropzoneSuccess(file, response) {
      let fileIDs = JSON.parse(this.value) === null ? [] : JSON.parse(this.value)

      if (response && response.data.id) {
        this.changePreviewButtons({ ...response.data, ...file })
        fileIDs.push(response.data.id)
        this.$emit('input', JSON.stringify(fileIDs))
      }
    },
    vdropzoneFileAddedManually(file) {
      this.changePreviewButtons(file)
    },
    vdropzoneFileRemoved(file) {
      let fileIDs = JSON.parse(this.value)

      const index = fileIDs.indexOf(file.id)
      if (index !== -1) {
        fileIDs.splice(index, 1)
        this.$emit('input', JSON.stringify(fileIDs))
      }
    },
    async fetchFiles() {
      const filesIDs = JSON.parse(this.value)

      if (!filesIDs || !filesIDs.length) {
        return
      }

      const filterQuery = this.erp.ext.query()
        .where('id', 'IN', filesIDs)

      this.files = (await this.erp.ext.request.axiosInstance.get(`/modules/documents?${filterQuery.toString()}`)).data.data
    },
    changePreviewButtons(file) {
      const downloadButton = document.createElement('a')
      downloadButton.setAttribute('class', 'dz-download')
      downloadButton.innerHTML = `<i class="fa-solid fa-download"></i> ${this.translate('Download', 'entities')}`
      downloadButton.onclick = async () => {
        const response = await this.erp.ext.request.axiosInstance.get(file.url, {
          ...this.dropzoneOptions.headers,
          responseType: 'blob',
        })

        const url = window.URL.createObjectURL(response.data)
        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = file.name
        downloadLink.click()
      }

      file.previewTemplate.appendChild(downloadButton)
    },
  },
}
</script>