<template>
  <div>
    <field
      v-model="data.name"
      :field-props="columns.name"
      :entity="entity"
    />

    <field
      v-model="data.url_key"
      :field-props="columns.url_key"
    />

    <!--  Cannot be field for now parent_id must be array or null  -->
    <div class="form-group" v-if="!simpleForm">
      <label>{{ translate('Parent category', 'categories') }}</label>
      <treeselect-field
        v-model="data.parent_id"
        :multiple="false"
        :apply-default-val="false"
        :placeholder="translate('Choose parent category', 'entities')"
        :field-props="{ ...columns.parent_id, data_type: 'treeselectCategory', data_module: entity === 'products' ? 'inventories' : entity }"
      />
    </div>

    <field
      v-if="hasManyTranslationLocales()"
      v-model="data.enable_for_lang"
      :field-props="columns.enable_for_lang"
    />

    <field
      v-if="!simpleForm"
      v-model="data.description"
      :field-props="columns.description"
      :entity="entity"
    />

    <field
      v-if="!simpleForm"
      v-model="data.bottom_description"
      :field-props="columns.bottom_description"
      :entity="entity"
    />

    <field
      v-if="!simpleForm && entity === 'products'"
      v-model="data.image"
      :field-props="columns.image"
    />

    <field
      v-if="!simpleForm && entity === 'products'"
      v-model="data.category_thumbnail"
      :field-props="columns.category_thumbnail"
    />

    <field
      v-if="!simpleForm && (entityType === 'categories' && entity !== 'products')"
      v-model="data.color"
      :field-props="columns.color"
    />

    <!-- SEO -->
    <div v-if="!simpleForm" class="mt-4">
      <div class="card rounded bg-white">
        <div class="card-header d-flex justify-content-between align-items-center border-bottom-0" :class="{'border-bottom-0': !showSEO}"
             @click="showSEO = !showSEO">
          <h5>{{ translate('SEO', 'categories') }}</h5>
          <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': showSEO === true}"></i>
        </div>

        <transition-expand>
          <div v-show="showSEO">
            <div class="card-body p-0">
              <div class="client-info-row borer-0 last">
                <div class="p-3">

                  <field
                    v-model="data.meta_title"
                    :field-props="columns.meta_title"
                    :entity="entityForShortcodes"
                    :placeholder="data.name"
                  />

                  <field
                    :field-props="columns.meta_description"
                    v-model="data.meta_description"
                    :entity="entityForShortcodes"
                    :placeholder="htmlentitiesDecode(stripTags(entityLabel(data.description)))"
                  />

                  <field
                    v-model="data.meta_keywords"
                    :field-props="columns.meta_keywords"
                    :entity="entityForShortcodes"
                  />

                  <field
                    v-model="data.meta_revisit"
                    :field-props="columns.meta_revisit"
                    apply-default-val
                  />

                  <field
                    v-model="data.meta_enable_robots_crawl"
                    :field-props="columns.meta_enable_robots_crawl"
                    apply-default-val
                  />
                </div>
              </div>
            </div>
          </div>
        </transition-expand>
      </div>
    </div>
  </div>
</template>

<script>
import field from "@/modules/erp_entities/components/page/form/InputEntityProps.vue"
import TreeselectField from "@/modules/erp_entities/components/page/form/fields/TreeselectField.vue"
import { hasManyTranslationLocales } from "@/utilities/helper"

export default {
  name: 'CategoryForm',
  components: {
    field, TreeselectField,
  },
  props: {
    value: {
      required: true,
    },
    columns: {
      required: true,
    },
    entity: {
      required: true,
    },
    entityType: {
      required: true,
    },
    simpleForm: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showSEO: false,
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    entityForShortcodes(){ // required for fields with shortcodes
      return this.entity === 'products' ? 'inventory-categories' : (this.entity + '-categories')
    },
  },
  methods: {
    hasManyTranslationLocales,
  },
}
</script>