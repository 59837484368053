<template>
  <input
    :value="value"
    type="hidden"
    @input="$emit('input', $event)"
  >
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "HiddenField",
  mixins: [FormFieldMixin],
}
</script>

