<template>
  <div :key="renderKey" class="row">

    <template v-for="(field, fieldI) in value">
    <!-- settings don't have input.permission -->
      <div v-if="typeof field.permission === 'undefined' || field.permission !== 'd'"
           :key="fieldI"
           :class="['col-md-'+ (field.width || 6)]"
      >

        <!-- TODO: uncomment this when we have permissions -->
        <!-- <field v-if="input.permission !== 'd'" v-model="value[inputIndex]" :field-props="{rules: input.rules, permission: input.permission}"/> -->

        <!-- Entities have entity, settings use fieldProps.entity -->
        <div class="form-group">
          <label class="form-control-label">
            {{ translate(value[fieldI].label, 'settings') }}
            <br>
            <small v-if="value[fieldI].description" class="text-muted">{{ translate(value[fieldI].description, 'settings') }}</small>
          </label>
          <field
            v-model="value[fieldI].value"
            :field-props="value[fieldI]"
            :entity="entity || value[fieldI].entity"
            :show-label="false"
          />
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import field from './InputEntityProps.vue'

export default {
  name: 'EntitySettingsForm',
  components: {
    field,
  },
  props: {
    value: {
      required: true,
    },
    entity: { // required for fields with shortcodes
      required: false,
    },
  },
  data() {
    return {
      renderKey: 1,
    }
  },
  watch: {
    'value.0.id': {
      handler(){
        this.renderKey++
      },
    },
  },
}
</script>

<style lang="scss">
    .flip-list-move {
        transition: transform 0.5s;
    }

    .env:not(:last-child)::after {
        content: ", ";
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .row {
        .draggable-item {
            cursor: pointer;

            &.active {
                background: #c8ebfb;
            }
        }
    }

    .list-group {
        min-height: 20px;
    }

    .error-tab .nav-link:not(.active) {
        color: #dc3545 !important;
        opacity: 1 !important;
    }
</style>
