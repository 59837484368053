<template>
  <div class="container">
    <page-title :page-title="translate('Marketing Integrations', 'settings')" />
    <div class="row">
      <div v-for="(tool, tool_name) in tools" class="col-md-6" :key="tool_name">
        <div class="card bg-white">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            @click="tools[tool_name].expanded = !tools[tool_name].expanded"
          >
            <div>
              <h6>
                <img
                  :src="`/img/marketing/${tool_name}.png`"
                  width="36"
                  height="36"
                  :alt="tool.label"
                >
                {{ tool.label }}
              </h6>
            </div>
            <span
              class="mr-3"
              :class="{ success: tool.connected }"
            >{{ tool.connected ? 'Connected' : translate('Connect', 'settings')}}</span>
            <i class="fa fa-chevron-down chevron" :class="{ 'chevron-rotate': !!tools[tool_name].expanded }"></i>
          </div>
          <transition-expand>
            <div v-if="tools[tool_name].expanded">
              <div class="card-body">

                <div class="form-group" v-for="(field, field_name) in tool.fields">
                  <template v-if="field.type === 'textarea'">
                    <textarea class="form-control" rows="10" v-model="tools[tool_name].fields[field_name].value"></textarea>
                  </template>
                  <template v-else>
                    <label :for="field_name">
                      {{ field.label }}
                    </label>
                    <input
                      v-model="tools[tool_name].fields[field_name].value"
                      type="text"
                      :id="field_name"
                      :placeholder="tools[tool_name].fields[field_name].placeholder || field.label"
                      class="form-control mb-2"
                    >
                  </template>
                </div>

                <div class="desc"
                     v-html="$options.filters.translate(`${tool.label} description`, 'settings')"
                >
                </div>

                <div class="d-flex justify-content-end mt-4">
                  <button class="btn btn-primary rounded-pill"
                          @click="saveItem(tool_name)"
                  >
                    <span>{{ translate('Save changes', 'global') }}</span>
                  </button>
                </div>

              </div>
            </div>
          </transition-expand>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErpModel from "@/modules/erp_framework/services/ErpModel";
import {createEntityFormObjectNoTabs} from "@/utilities/helper";
import {createSettingsForm} from "@/utilities/helpers/websiteSettings";

export default {
  name: 'Integrations',
  data() {
    return {
      website :null,
      tools: {
        google_analytics: {
          label: 'Google analytics',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_analytics': {
              placeholder: 'G-XXXXXXXXXX',
              value: null,
            },
          },
        },
        google_tag_manager: {
          label: 'Google tag manager',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_tag_manager': {
              placeholder: 'GTM-XXXXXXX',
              value: null,
            },
          },
        },
        google_ads : {
          label: 'Google ads',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_ads': {
              value: null,
            },
          },
        },
        google_recaptcha : {
          label: 'Google reCAPTCHA',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_recaptcha_public_key': {
              placeholder: 'Public key',
              value: null,
            },
            'marketing_integrations.google_recaptcha_private_key': {
              placeholder: 'Private key',
              value: null,
            },
          },
        },
        google_reviews : {
          label: 'Google reviews',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_api_key': {
              placeholder: 'Api key',
              value: null,
            },
            'marketing_integrations.google_place_id': {
              placeholder: 'Place ID',
              value: null,
            },
          },
        },
        facebook_pixel: {
          label: 'Facebook pixel',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.facebook_pixel': {},
          },
        },
        /*
        google_maps: {
          label: 'Google maps',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.google_maps': {
              value: null,
            },
          },
        },*/
        connect_website_to_other_tools: {
          label: 'Connect your website to other tools',
          expanded: false,
          connected: false,
          fields: {
            'marketing_integrations.connect_website_to_other_tools': {
              value: null,
            },
          },
        },
      },
    }
  },
  mounted() {
    this.getMarketingSettingsData()
  },
  methods: {
    isConnected(tool_name){
      return Object.values(this.tools[tool_name].fields).every(field => !!field.value)
    },
    async saveItem(tool_name) {
      this.$store.state.system.isLoading = true

      let data = {
        model: "cms-websites",
        modelId: this.$store.state.system.scope.value,
        options: {},
        settingsSlug: "marketing-integrations-cmswebsite",
      }

      // fill options
      for (const [key, el] of Object.entries(this.tools[tool_name].fields)) {
        data.options[key] = el.value
      }

      await this.erp.ext.request.axiosInstance.post(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}`, data)
        .then(() => {
          this.$toast.requestSuccess(false, 'Marketing settings')
          this.$set(this.tools[tool_name], 'connected', this.isConnected(tool_name))
          this.$store.state.system.isLoading = false
        }).catch(error => {
          this.$alert.formattedError(error)
          this.$store.state.system.isLoading = false
        })
    },
    setInitialConnectStatus(){
      Object.keys(this.tools).forEach(tool_name => {
        this.tools[tool_name].connected = this.isConnected(tool_name)
      })
    },
    async getMarketingSettingsData() {
      this.$store.state.system.isLoading = true;
      await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}/marketing-integrations-cmswebsite/marketing_integrations`).then((res) => {
        let form = createSettingsForm(res)
        form.forEach(item => {
          if(item.name === 'marketing_integrations.google_recaptcha_public_key' || item.name === 'marketing_integrations.google_recaptcha_private_key'){
            this.tools['google_recaptcha'].fields[item.name].value = item.value
          } else if(item.name === 'marketing_integrations.google_api_key' || item.name === 'marketing_integrations.google_place_id'){
            this.tools['google_reviews'].fields[item.name].value = item.value
          }
          // else tools with single field
          else {
            if(this.tools[item.name.replace('marketing_integrations.', '')]) {
              this.tools[item.name.replace('marketing_integrations.', '')].fields[item.name].value = item.value
            }
          }
        })
      })

      // Set initial connect status
      this.setInitialConnectStatus()

      this.$store.state.system.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    min-height: 81px; /* applied only here because we don't have images on some cards */

    img {
      margin-right: 5px;
    }

    .success {
      color: green;
    }
  }

  .card-body {
    .desc {
      color: $primary-panel-text-color;
      font-size: 14px;
    }

    textarea {
      border-radius: 1.4rem;/* todo move this from here */
    }

    button {
      min-width: 25%;
    }
  }
}
</style>
