import { environment } from '../utilities/helper'
import { translationLocales, hasManyTranslationLocales } from "@/utilities/helper"
import store from "@/store"

export default {
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
    },
    required: {
      required: false,
      default: false,
    },
  },
  computed: {
    translationLocales,
    hasManyTranslationLocales,
    environment: () => environment,
    // non translatable model
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },

    // non-translatable model with backward compatibility
    // returns primary translation if the field is non-translatable but the old value is
    nonTranslationModel: {
      get() {
        if(this.value !== null && typeof this.value === 'object'){
          return this.value[store.state.system.scope.obj.website_primary_locale]
        }
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    // translatable model
    translationModel: {
      get() {
        // create array with locales on new entities
        if (this.value === undefined || this.value === null || this.value === '') {
          this.value = {}
          for (const [key, value] of Object.entries(translationLocales())) {
            this.value[key] = ""
          }
        }

        /* Convert string to JSON if is not yet, for example:
         * 1. Marketing > Default SEO Tags
         * 2. Settings > Account & Privacy > Cookie notice
         */
        if(typeof this.value === 'string' && this.value.startsWith('{')) {
          this.value = JSON.parse(this.value)
        }

        // add missing locales if any, editor requires '' instead of undefined to get updated
        for (const [key, value] of Object.entries(translationLocales())) {
          if(typeof this.value[key] === 'undefined'){
            this.value[key] = ""
          }
        }

        return this.value[environment.store.state.system.translation_locale]
      },
      set(v) {

        // covert string to array when on making translatable to not get {"0":"a","1":"b","2":"c","3":"d","4":"e", "en":"A"}
        if(typeof this.value === 'string'){
          this.value = {}
        }
        this.value = { ...this.value, [environment.store.state.system.translation_locale]: v }
        this.$emit('input', this.value)
      },
    },
    // field actions
    hasActionShortcodes(){
      return this.fieldProps && this.fieldProps.shortcodeable == 1
    },
    hasActionLocales(){
      return this.fieldProps && this.fieldProps.translatable == 1 && hasManyTranslationLocales()
    },
  },

  methods: {
    /*
     * Append shortcode at the current selection position
     */
    shortcodeAppend(shortcode){

      // Textarea (Editor)
      if(typeof this.$refs[this.fieldRef].editor !== 'undefined'){
        //this.$refs[this.fieldRef].editor.selection.setContent(shortcode)
        this.$refs[this.fieldRef].editor.commands.insertContent(shortcode)

        // NOT NEEDED FOR TIPTAP, LEAVED JUST IN CASE
        // hot fix update model value immediately, insertContent above sets the right content but the model value is not updated
        // You had to click outside the editor (to update the model value) before you save the form
        // // let newValue = this.$refs[this.fieldRef].editor.getContent() // tinymce
        // let newValue = this.$refs[this.fieldRef].editor.getHTML() // tiptap
        // if(this.fieldProps.translatable == 1) {
        //   this.translationModel = newValue
        // } else {
        //   this.nonTranslationModel = newValue
        // }
        // END hot fix update model value immediately

      // else input, Textarea simple
      } else {
        // check if it's empty, we don't want "null[shortcode]"
        let initialValue = ''
        if(this.fieldProps.translatable == 1) {
          initialValue = this.translationModel || ''
        } else {
          initialValue = this.nonTranslationModel || ''
        }

        let startPos = this.$refs[this.fieldRef].selectionStart,
          endPos = this.$refs[this.fieldRef].selectionEnd

        let newValue = initialValue.substring(0, startPos) + shortcode + initialValue.substring(endPos, initialValue.length)
        if(this.fieldProps.translatable == 1) {
          this.translationModel = newValue
        } else {
          this.nonTranslationModel = newValue
        }
      }
    },
  },
}