import store from '@/store'

export default function (node) {
  let styles = ''
  const settings = node.props?.settings

  // BACKGROUND IMAGE OVERLAY
  const hasBackgroundImage = typeof node.props?.elementStyle?.[store.state.layout.deviceType]?.['background-image'] !== 'undefined'
                             && node.props?.elementStyle?.[store.state.layout.deviceType]?.['background-image'] !== 'none'

  if (settings && hasBackgroundImage) {
    const { background_image_overlay_color, background_image_overlay_opacity } = settings

    let overlay = `
    [data-uuid="${node.uuid}"]:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${background_image_overlay_color ? `background-color: ${background_image_overlay_color};` : ''}
      ${background_image_overlay_opacity ? `opacity: ${parseFloat(background_image_overlay_opacity) / 100};` : ''}
    }`

    styles += overlay
  }

  // END BACKGROUND IMAGE OVERLAY

  return styles
}