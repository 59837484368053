<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">

        <div class="row">
          <div class="col-12">
            <craft-select
              :label="'Categories'"
              :dynamic="true"
              :field-props="{ type: 'multiselect', data_type: 'moduleNames', data_module: 'Category', data_filters: ['entity'], data: [] }"
              :data-filters="{ entity: { condition: '=', value: 'inventories' } }"

              :value="getSetting('categories', null, 'multiselect')"
              @input="setSetting('categories', $event)"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Sort"
              :options="sortOptions"
              :value="getSetting('sort')"
              @input="setSetting('sort', $event)"
            />
          </div>

          <div class="col-12">
            <craft-select
                :label="'Product type'"
                :options="productTypeOptions"
                :value="getSetting('products')"
                @input="setSetting('products', $event)"
                clearable
            />
          </div>

          <transition name="fade">
            <div v-if="getSetting('products') === 'custom'" class="col-12 form-group" >
              <!-- :multiple="true" - replaced with fieldProps.type=multiselect -->
              <craft-select
                  v-if="products"
                  :label="'Choose products'"
                  :dynamic="true"
                  :field-props="{ type: 'multiselect', data_type: 'moduleNames', data_module: 'Inventory', data: [] }"
                  :value="getSetting('whereIds', null, 'multiselect')"
                  @input="setSetting('whereIds', $event)"
              />
            </div>
          </transition>

          <div class="col-12">
            <craft-select
                :label="'Choose layout'"
                :options="layoutOptions"
                :value="getSetting('layout')"
                @input="setSetting('layout', $event)"
            />
          </div>

          <transition name="fade">
            <div class="col-12" v-if="['table', 'carousel'].includes(getSetting('layout'))">
              <label class="label mb-2">{{ translate('Number of columns', 'categories') }}</label>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['desktop']"
                    :label="'Desktop'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_landscape']"
                    :label="'Tablet (landscape)'"
                    :options="columnOptions"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <craft-select
                    v-model="columns['tablet_portrait']"
                    :label="'Tablet (portrait)'"
                    :options="columnOptions"
                  />
                </div>
                <div class="col-6">
                  <craft-select
                    v-model="columns['mobile']"
                    :label="'Mobile'"
                    :options="columnOptions"
                  />
                </div>
              </div>
            </div>
          </transition>

          <div class="col-12">
            <craft-input
                :label="'How many products you want to show'"
                :input-type="'number'"
                :value="getSetting('number_of_products')"
                @input="setSetting('number_of_products', $event)"
            />
          </div>

          <transition name="fade">
            <div class="col-12" v-if="getSetting('layout') == 'carousel'">
              <div class="row">
                <div class="col-md-12">
                  <craft-toggle
                    :id="`show-bullets-${node.uuid}`"
                    label="Show bullets"
                    :value="getSetting('navBulletsEnabled')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('navBulletsEnabled', $event)"
                  />
                </div>
                <div class="col-md-12">
                  <craft-toggle
                    :id="`show-navigation-${node.uuid}`"
                    label="Show navigation"
                    :value="getSetting('navArrowsEnabled')"
                    :value-on="1"
                    :value-off="0"
                    @input="setSetting('navArrowsEnabled', $event)"
                  />
                </div>
                <transition name="fade">
                  <div class="col-md-12" v-if="getSetting('navArrowsEnabled')">
                    <craft-tab-pills
                      label="Navigation position"
                      :options="generalVerticalAlignmentOptions"
                      :value="getSetting('navArrowsVerticalAlign')"
                      @input="setSetting('navArrowsVerticalAlign', $event)"
                    />
                  </div>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import { CraftInput, CraftSelect, CraftTabPills, CraftToggle } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  inject: ['editor', 'node'],
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  components: {
    CraftInput, CraftSelect, CraftTabPills, CraftToggle,
  },
  data() {
    return {
      products: [],
      columns: {},
    }
  },
  computed: {
    productTypeOptions() {
      return [
        { key: 'on_sale', label: 'Sale items' },
        { key: 'last_viewed', label: 'Last viewed' },
        { key: 'custom', label: 'Custom' },
      ]
    },
    categoryOptions() {
      return this.categories.reduce((options, { name, slug }) => {
        if (!options.some(category => category.key === slug)) {
          options.push({
            label: this.entityLabel(name),
            key: slug,
          })
        }
        return options
      }, [])
    },
    sortOptions() {
      return [
        { key: 'stock_status_asc', label: 'Default' },
        { key: 'best_selling', label: 'Popularity' },
        { key: 'newest', label: 'Last added' },
        { key: 'name_asc', label: 'Name ASC' },
        { key: 'name_desc', label: 'Name DESC' },
        { key: 'price_asc', label: 'Price ASC' },
        { key: 'price_desc', label: 'Price DESC' },
      ]
    },
  },
  async created() {
    let categoryEntity = this.entity === 'inventory' ? 'inventories' : this.entity

    const categories = await this.erp.ext.request.get(
      'modules/categories',
      this.erp.ext.query()
        .where('entity', '=', categoryEntity)
        .where('type', '=', 'categories')
        .set('perpage', '9999')
        .toString()
    )

    this.categories = categories.objectData
  },
  mounted() {
    this.columns = this.getSetting('columns')
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        this.setSetting('columns', this.columns)
      },
    },
  },
}
</script>
