<template>
  <entity-form
    :entity-route="entityRoute"
    :modal-data="value?.data"
    is-modal
    @loading="$emit('loading', $event)"
    @save="save"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import pluralize from 'pluralize'

export default {
  name: 'CreateEntityOption',
  components: {
    EntityForm: () => import('@/modules/erp_entities/components/page/form/EntityForm.vue'),
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      entityRoute: null,
    }
  },
  created() {
    this.entityRoute = pluralize(this.value.data_module).toKebabCase()
  },
  methods: {
    save() {
      this.$emit('save')
      this.$modal.hide('create_new_entity')
    },
  },
}
</script>
