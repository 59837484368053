
Object.assign(String.prototype, {
  capitalizeFirstLetter: function () {
    //return this.charAt(0).toUpperCase() + this.slice(1) // old
    return this.replace(/^\w/, s => s.toUpperCase())
  },

  toCamelCase: function () {
    return this.replace(/\s*-\s*\w/g, parts => parts[parts.length-1].toUpperCase())
  },

  toPascalCase: function () {
    return this.toCamelCase().capitalizeFirstLetter()
  },

  toKebabCase: function () {
    return this.toPascalCase().replace(/[A-Z]/g, '-$&').toLowerCase().slice(1)
  },

  toSnakeCase: function () {
    return this.toPascalCase().replace(/[A-Z]/g, '_$&').toLowerCase().slice(1)
  },

})