<template>
    <div>
        <page-title :page-title="translate('Orders', 'orders')" :borderBottom="false">
            <template v-slot:actions>
                <router-link to="/blockz-order/create/choose-plan">
                    <button class="btn btn-primary rounded-pill">{{ translate('Add new order', 'orders') }}</button>
                </router-link>
            </template>
        </page-title>

        <div class="card">
            <ul class="nav nav-tabs scope-tabs px-3">
                <li class="nav-item pt-3">
                    <a href="javascript:;" class="d-flex system-text-primary pb-3">
                        {{ translate('All', 'orders') }}
                    </a>
                </li>
                <li class="nav-item pt-3">
                    <a href="javascript:;" class="d-flex system-text-primary pb-3">
                        {{ translate('Pending', 'orders') }}
                    </a>
                </li>
                <li class="nav-item pt-3">
                    <a href="javascript:;" class="d-flex system-text-primary pb-3">
                        {{ translate('Processing', 'orders') }}
                    </a>
                </li>
                <li class="nav-item pt-3">
                    <a href="javascript:;" class="d-flex system-text-primary pb-3">
                        {{ translate('Refunded', 'orders') }}
                    </a>
                </li>
<!--                <li class="nav-item pt-3" v-for="(status, si) in filterByStatus" :class="{'active': selectedFilter == status}">-->
<!--                    <a href="javascript:;" class="d-flex system-text-primary pb-3" @click="filterStatus(status)">-->
<!--                        {{ translate(`orders.${status}`) }}-->
<!--                    </a>-->
<!--                </li>-->
            </ul>

            <div class="blockz-order-table mt-4 b-a-pr">
                <div class="card-header actions-toolbar border-0">
                    <div class="row justify-content-between align-items-center">
                        <div class="col">

                        </div>
                        <div class="col text-right">
                            <div class="actions d-flex align-items-center justify-content-end">
                                <div class="d-flex align-items-center per-page">
                                    <label class="mb-0 mr-2">{{ translate('Per page', 'global') }}</label>
                                    <v-select style="width: 150px" :options="['10', '20', '50', '100', '200']"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center">
                        <thead>
                        <tr>
                            <th scope="col">{{ translate('Order number', 'orders') }}</th>
                            <th scope="col">{{ translate('Description', 'orders') }}</th>
                            <th scope="col">{{ translate('Customer name', 'orders') }}</th>
                            <th scope="col">{{ translate('Date', 'orders') }}</th>
                            <th scope="col">{{ translate('Totals', 'orders') }}</th>
                            <th scope="col">{{ translate('Payment_method', 'orders') }}</th>
                            <th scope="col">{{ translate('Status', 'orders') }}</th>
                        </tr>
                        </thead>
                        <tbody class="list">
                        <tr >
                            <td><input type="checkbox"> order_number</td>
                            <td>2 subscriptions</td>
                            <td>billing_client_first_name billing_client_last_name</td>
                            <td>date</td>
                            <td>$79.59</td>
                            <td>Mastercard</td>
                            <td>Completed</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IndexOrder"
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/components/all.scss";
</style>
