<template>
  	<div id="left-sidebar">
		<template v-if="isAdvancedBuilder">
			<div
				class="left-sidebar-icon mt-4 ml-1">
				<button class="border-0 bg-white sidebar-btn"
					@click="toggleLeftSidebar('components')"
				>
					<span class="justify-content-center add-icon">
						<img src="../../assets/images/icons/sidebar/add_component.svg" alt="add icon" width="25" height="25">
					</span>
					<span class="sidebar-icon-info-box add-components">
						{{ translate('Add Component', 'page-builder') }}
					</span>
				</button>
			</div>
		</template>

		<div v-if="['cms-pages', 'cms-sections', 'blogs', 'portfolios', 'sliders', 'cms-templates', 'stores'].includes(builder.meta.entity)"
            class="mt-4 ml-1">
      		<button class="border-0 bg-white sidebar-btn"
              	@click="toggleLeftSidebar('pages')"
			>
        		<span class="justify-content-center add-icon">
					<i class="icon-erp-pages"></i>
        		</span>
				<span class="sidebar-icon-info-box add-pages">
					<template v-if="builder.meta.entity === 'cms-pages'">
						{{ translate('Pages', 'page-builder') }}
					</template>
                    <template v-if="builder.meta.entity === 'cms-sections'">
						{{ translate('Sections', 'page-builder') }}
					</template>
					<template v-else-if="builder.meta.entity === 'blogs'">
						{{ translate('Blog posts', 'page-builder') }}
					</template>
					<template v-else-if="builder.meta.entity === 'portfolios'">
						{{ translate('Portfolio posts', 'page-builder') }}
					</template>
                  <template v-else-if="builder.meta.entity === 'sliders'">
						{{ translate('Sliders', 'page-builder') }}
					</template>
          <template v-else-if="builder.meta.entity === 'cms-templates'">
            {{ translate('Templates', 'page-builder') }}
          </template>
          <template v-else-if="builder.meta.entity === 'stores'">
            {{ translate('Stores', 'page-builder') }}
          </template>
				</span>
      		</button>
    	</div>
    	<div class="mt-4 ml-1">
      		<button class="border-0 bg-white sidebar-btn"
              	@click="toggleLeftSidebar('settings')"
			>
        		<span class="justify-content-center add-icon">
					<i class="icon-erp-cog"></i>
        		</span>
				<span class="sidebar-icon-info-box add-pages">
					{{ translate('Settings', 'page-builder') }}
				</span>
      		</button>
    	</div>

		<!--
		<div class="mt-4 ml-1">
			<button class="border-0 bg-white sidebar-btn"
				@mouseenter="showAddPageSettingsInfo=true"
				@mouseleave="showAddPageSettingsInfo=false"
			>
				<img src="../../assets/images/icons/sidebar/settings.svg" alt="add icon" width="25" height="25">
			</button>
			<span
				v-if="showAddPageSettingsInfo"
					class="sidebar-icon-info-box add-page-settings"
			>
				{{ translate('Page settings', 'page-builder') }}
			</span>
		</div>
		-->

	    <div v-if="['cms-pages', 'blogs', 'portfolios', 'stores'].includes(builder.meta.entity)"
            class="mt-4 ml-1">
      		<button class="border-0 bg-white sidebar-btn"
              	@click="toggleLeftSidebar('seo')"
			>
        		<span class="justify-content-center add-icon">
					<i class="icon-erp-rocket icon-erp-2x"></i>
        		</span>
				<span
					class="sidebar-icon-info-box add-seo"
				>
					{{ translate('SEO', 'page-builder') }}
				</span>
      		</button>
    	</div>

        <div v-if="['cms-pages'].includes(builder.meta.entity)"
             class="mt-4 ml-1">
            <button class="border-0 bg-white sidebar-btn"
                @click="toggleLeftSidebar('css')"
            >
                <span class="justify-content-center add-icon">
                    <img src="../../assets/images/icons/sidebar/css_editor.svg" alt="add icon" width="25" height="25">
                </span>
                <span class="sidebar-icon-info-box add-css">
                    {{ translate('Custom CSS', 'page-builder') }}
                </span>
            </button>
        </div>
  	</div>
</template>

<script>
import BuilderMixin from '../../mixins/BuilderMixin'
import busEvent from "@/utilities/eventBus";

export default {
  	name: "LeftSidebar",
	mixins: [BuilderMixin],
  	methods: {
      toggleLeftSidebar(type){
        busEvent.$emit('toggleLeftSidebar', type)
      },
  	}
}
</script>

<style lang="scss" scoped>
#left-sidebar {
	width: 43px;
	height: 100%;
	position: fixed;
	float: left;
	border-right: 1px solid #dadada;
	z-index: 14;
	background-color: #fff;
}

.add-components{
  	margin-left: 51px;
}

.sidebar-btn {
  	margin: 0 auto;

	i {
		font-size: 25px;
	}
}
</style>
