<template>
  <!-- DEPRECATED, use textarea-field instead -->
  <textarea-field
    v-model="model"
    :field-props="fieldProps"
    :placeholder="placeholder"
    :entity="entity"
  />
</template>

<script>
import TextareaField from "@/modules/erp_entities/components/page/form/fields/TextareaField.vue"

export default {
  name: "EeFormTextarea",
  components: { TextareaField },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
      default: {},
    },

    placeholder: {
      required: false,
      default: '',
    },
    entity: {
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>