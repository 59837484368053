<template>
  <div class="container">
    <div class="row">
      <div v-if="!$store.state.layout.preview" class="col-md-12">
        <div class="mt-5">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ translate('First name', 'page-builder') }} <span style="color: red">*</span></label>
                    <input
                      v-model="entity.objectData.user_first_name"
                      type="text"
                      name="first_name"
                      class="form-control"
                      placeholder="First name"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ translate('Last name', 'page-builder') }} <span style="color: red">*</span></label>
                    <input
                      v-model="entity.objectData.user_last_name"
                      type="text"
                      name="title"
                      class="form-control"
                      placeholder="Last name"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ translate('Company name', 'page-builder') }}</label>
                    <input
                      v-model="entity.objectData.company_name"
                      type="text"
                      name="company_name"
                      class="form-control"
                      placeholder="Company name"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ translate('Date', 'page-builder') }}</label>
                    <input
                      v-model="entity.objectData.date"
                      type="date"
                      name="date"
                      class="form-control"
                      placeholder="Date"
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Subject', 'page-builder') }}</label>
                    <input
                      v-model="entity.objectData.subject"
                      type="text"
                      name="rating"
                      class="form-control"
                      placeholder="Subject"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <upload-image v-model="entity.objectData.user_image" :placeholder="translate('Choose image', 'page-builder')" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group mt-4">
                <label>{{ translate('Description', 'page-builder') }}</label>
                <text-editor-inline v-model="entity.objectData.description" />
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mt-4">
                    <label>{{ translate('Add review rating', 'page-builder') }}</label>
                    <star-rating
                      v-model.number="entity.objectData.rating"
                      :show-rating="false"
                      :star-size="40"
                      :padding="1.5"
                      :border-width="1"
                      border-color="#000"
                      inactive-color="#fff"
                      active-color="#000"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mt-4">
                    <label>{{ translate('Review category', 'page-builder') }}</label>
                    <treeselect
                      v-model="entity.objectData.categories"
                      :show-count="true"
                      :placeholder="translate('Choose category', 'page-builder')"
                      :normalizer="obj => normalizer(obj)"
                      value-consists-of="ALL"
                      multiple
                      :default-expand-level="10"
                      :clearable="false"
                      :options="nested_categories"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mt-4">
                    <label>{{ translate('Visibility', 'page-builder') }}</label>
                    <div class="d-flex">
                      <div class="switch-field-visibility">
                        <div v-for="(option, optionIndex) in visibility" :key="optionIndex" class="three-parts">
                          <input
                            :id="`option-${option.key}`"
                            v-model.number="entity.objectData.status"
                            name="visibility-option"
                            type="radio"
                            :value="option.value"
                          >
                          <label :for="`option-${option.key}`">{{ option.key }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--                                <div class="col-md-12"> -->
                <!--                                    <div class="form-group"> -->
                <!--                                        <div class="d-flex justify-content-between py-2"> -->
                <!--                                            <label>{{ translate('Publish testimonial', 'builder') }}</label> -->
                <!--                                            <div class="custom-control custom-switch"> -->
                <!--                                                <input -->
                <!--                                                    @change="e => e.target.checked ? entity.objectData.status = 1 : entity.objectData.status = 0" -->
                <!--                                                    type="checkbox" -->
                <!--                                                    name="publish_testimonial" -->
                <!--                                                    id="publish_testimonial" -->
                <!--                                                    class="custom-control-input" -->
                <!--                                                    :checked="entity.objectData.status == '1'" -->
                <!--                                                    :value="Number(entity.objectData.status)" -->
                <!--                                                /> -->
                <!--                                                <label for="publish_testimonial" class="custom-control-label"></label> -->
                <!--                                            </div> -->
                <!--                                        </div> -->
                <!--                                    </div> -->
                <!--                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <testimonial-show v-else v-model="data" />
    </div>
  </div>
</template>

<script>
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import EntityCrudMixin from '@/modules/pagebuilder/mixins/EntityCrudMixin'
import UploadImage from '../../components/UploadImage'
import { vSelect } from 'vue-select'
import TestimonialShow from './Show'
import StarRating from 'vue-star-rating/src/star-rating'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

const defaultData = {
  user_first_name: '',
  user_last_name: '',
  company_name: '',
  date: '',
  rating: 0,
  subject: '',
  description: '',
  user_image: '',
  website_id: null,
  status: 0,
  categories: [],
}

export default {
  name: 'CreateTestimonial',
  components: {
    UploadImage, TextEditorInline, VueSelect: vSelect, TestimonialShow, StarRating, Treeselect,
  },
  mixins: [BuilderMixin, EntityCrudMixin],
  data() {
    return {
      nested_categories: null,
    }
  },
  computed: {
    defaultData() {
      return { ...defaultData }
    },
    visibility() {
      return [
        { key: 'Visible', value: 1 },
        { key: 'Pending', value: 2 },
        { key: 'Not visible', value: 0 },
      ]
    },
  },
  async created() {
    let { data } = await this.erp.ext.request.axiosInstance.get(`/modules/treeselect/categories/${this.builder.meta.entity}`)
    this.nested_categories = data.data
  },
  beforeCreate() {
    defaultData.website_id = this.$route.params.website
  },
  methods: {
    normalizer(node) {
      return {
        id: node.key,
        label: node.value,
      }
    },
  },
}
</script>

<style lang="scss">
    .testimonial-description #quill-container {
        min-height: 300px;
    }

    .switch-field-visibility {
        width: 100%;
        display: flex;
        overflow: hidden;
        position: relative;
        border: 1px solid;
        border-radius: 5px;
    }

    .switch-field-visibility .three-parts {
        flex: 0 0 33.34%;
    }

    .switch-field-visibility .three-parts:last-of-type label {
        border-right: unset;
    }

    .switch-field-visibility input {
        position: absolute !important;
        opacity: 0;
        z-index: -1;
    }

    .switch-field-visibility input:checked + label {
        box-shadow: none;
        color: #f6f6f6;
        background: #000;
    }

    .switch-field-visibility label {
        background-color: var(--primary-panel-offset-color);
        color: #000;
        font-weight: bold;
        line-height: 1.5;
        padding: 0.75rem 0.2rem;
        transition: all 0.1s ease-in-out;
        flex: 0 0 25%;
        border-radius: unset !important;
        border-right: 1px solid;
        font-size: 17px;
        height: 100%;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
    }

    .switch-field-visibility label:hover {
        cursor: pointer;
    }

    .switch-field-visibility:not(:first-of-type):not(:last-of-type) {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }

    .switch-field-visibility:not(:first-of-type):not(:last-of-type) label {
        border-radius: 0;
    }
</style>
