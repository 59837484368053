<template>
  <div class="page-title">
    <small class="font-weight-600">{{ translate('Overview', 'global') }}</small>
    <div class="row justify-content-between align-items-center">
      <div
        class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
        <!-- Page title + Go Back button -->
        <div class="d-inline-block">
          <h5 class="h4 d-inline-block mb-0 system-page-title system-text-primary font-weight-300">{{ pageTitle }}</h5>
        </div>
        <!-- Additional info -->
        <div class="align-items-center ml-2 d-inline-flex">
          <span v-if="counter" class="h4 system-text-primary mb-0 mr-2 font-weight-300 system-title-counter">({{ counter }})</span>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end d-print-none">
        <router-link v-if="buttons" :to="buttons" class="btn btn-white rounded-pill">
          {{ translate('Go back', 'global') }}
        </router-link>
        <slot name="actions"></slot>
      </div>
    </div>
    <hr v-if="borderBottom">
  </div>
</template>

<script>
export default {
	name: "PageTitle",
	data() {
		return {

		}
	},
	props: {
		pageTitle: {
			type: String,
			required: true
		},
		counter: {
			required: false
		},
		borderBottom: {
			required: false,
			default: true
		},
		buttons: {
			required: false,
			default: false
		}
	},
}
</script>
<style scoped lang="scss">
	.page-title {
		small {
			color: #000;
		}

		.row {
			h5 {
				font-size: 2.2rem;
			}
		}

		hr {
			border-top-color: #000;
		}
	}
</style>