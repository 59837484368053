<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                    type="checkbox"
                                    name="enable_vat"
                                    id="enable_vat"
                                    class="custom-control-input"
                                    v-model="settings.enable_vat"
                            />
                            <label for="enable_vat" class="custom-control-label">
                                {{ translate('Enable VAT', 'onboarding') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="settings.enable_vat">
            <div class="col-12">
                <div class="alert alert-warning">
                    {{ translate('Please make sure to create VAT group before create VAT rate', 'onboarding') }}
                </div>
            </div>

            <vat-types class="mb-5" key="onboarding-vat-types"></vat-types>

            <vat-rates class="mb-5" key="onboarding-vat-rates"></vat-rates>

            <div class="col-12">
                <div class="form-group">
                    <label class="form-control-label">{{ translate('Calculate VAT on', 'onboarding')}}</label>
                    <div class="d-flex">
                        <div class="switch-field">
                            <input id="shop_address" name="shop_address" type="radio"
                                   value="shop_address" v-model="settings.calculate_price_on">
                            <label for="shop_address">{{ translate('Shop address', 'onboarding') }}</label>
                        </div>
                        <div class="switch-field">
                            <input id="customer_address" name="customer_address" type="radio"
                                   value="customer_address" v-model="settings.calculate_price_on">
                            <label for="customer_address">{{ translate('Customer address', 'onboarding') }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                    type="checkbox"
                                    name="include_vat_in_price"
                                    id="include_vat_in_price"
                                    class="custom-control-input"
                                    v-model="settings.include_vat_in_price"
                            />
                            <label for="include_vat_in_price" class="custom-control-label">
                                {{ translate('Enter prices incl VAT', 'onboarding') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                    type="checkbox"
                                    name="show_vat_separately"
                                    id="show_vat_separately"
                                    class="custom-control-input"
                                    v-model="settings.show_vat_separately"
                            />
                            <label for="show_vat_separately" class="custom-control-label">
                                {{ translate('Show VAT separately', 'onboarding') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                name="show_vat_in_checkout"
                                id="show_vat_in_checkout"
                                class="custom-control-input"
                                v-model="settings.show_vat_in_checkout"
                            />
                            <label for="show_vat_in_checkout" class="custom-control-label">
                                {{ translate('Show VAT in checkout', 'onboarding') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                name="show_vat_in_catalog"
                                id="show_vat_in_catalog"
                                class="custom-control-input"
                                v-model="settings.show_vat_in_catalog"
                            />
                            <label for="show_vat_in_catalog" class="custom-control-label">
                                {{ translate('Show VAT in catalog', 'onboarding') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {slug} from "../../../../utilities/helper";
import VatTypes from "@/modules/sitecart/views/taxes/VatTypes.vue"
import VatRates from "@/modules/sitecart/views/taxes/VatRates.vue"

export default {
    name: "VatSettings",
    components: {
      VatTypes, VatRates
    },
    data() {
        return {
            settings: {
                enable_vat: true,
                include_vat_in_price: false,
                show_vat_separately: false,
                show_vat_in_checkout: false,
                show_vat_in_catalog: false,
                calculate_price_on: ''
            }
        }
    },
    props: {
        value: {
            required: true
        },
    },
    methods: {
        slug,
        nextStep() {
          this.value.vat_settings = this.settings
          this.$router.push(this.$router.currentRoute.meta.next)
        }
    },
}
</script>

<style scoped>

</style>
