<template>
  <div v-if="records" class="container">
    <page-title :page-title="translate('Attribute sets', 'attributes')">
      <template #actions>
        <router-link class="btn btn-primary rounded-pill" to="/product-attributes-sets/create">
          {{ translate('Add new attribute set', 'attributes') }}
        </router-link>
      </template>
    </page-title>
    <div class="row">
      <div class="col-md-12">
        {{ translate('Attributes sets text', 'attributes') }}
      </div>
      <div class="col-md-12">
        <div class="main-table mt-4">
          <div class="d-flex justify-content-end align-items-center table-header px-1">
            <div
              class="bulk-actions nav-item dropdown dropdown-animate actions-dropdown"
              data-toggle="hover"
            >
              <a
                class="nav-link dropdown-toggle system-text-primary"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ translate('Bulk actions', 'global') }}
              </a>
              <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                <div class="p-lg-4">
                  <div class="pb-1 pt-1 delimiter-bottom">
                    <a
                      href="javascript:;"
                      class="d-flex align-items-center dropdown-item"
                    >
                      {{ translate('Delete', 'global') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table m-0">
              <thead class="thead-light">
                <tr>
                  <th scope="col">
                    <div class="custom-control custom-checkbox">
                      <input
                        id="select_all"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label class="custom-control-label" for="select_all" />
                    </div>
                  </th>
                  <th scope="col">
                    {{ translate('id', 'attributes') }}
                  </th>
                  <th scope="col">
                    {{ translate('name', 'attributes') }}
                  </th>
                  <th scope="col" class="text-right">
                    {{ translate('Actions', 'global') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(attr, attrI) in records.data" class="tr-1">
                  <td>
                    <div class="custom-control custom-checkbox">
                      <input id="all" type="checkbox" class="custom-control-input">
                      <label for="all" class="custom-control-label" />
                    </div>
                  </td>
                  <td>{{ attr.id }}</td>
                  <td>
                    <router-link :to="`/product-attributes-sets/${attr.id}`" class="table">
                      <title-with-shortcodes :value="entityLabel(attr.name, false, true)" />
                    </router-link>
                  </td>
                  <td class="actions-cta">
                    <cta>
                      <action-button class-icon="fa-pen" @click.native="$router.push(`/product-attributes-sets/${attr.id}`)" />
                      <action-button class-icon="fa-trash-alt" @click.native="deleteRecord(attr.id)" />
                    </cta>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            v-if="records && records.meta"
            :value="records.meta"
            :disabled="loading"
            container-class="d-flex justify-content-between"
            @goToPage="v => setProp('page', v)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntityIndexMixin from "../../../../mixins/EntityIndexMixin"
import TitleWithShortcodes from "@/modules/erp_entities/components/utilities/TitleWithShortcodes"

export default {
  name: "Attributes",
  components: { TitleWithShortcodes },
  mixins: [EntityIndexMixin],
  async created() {
    this.entityType = 'attribute-sets'
    await this.loadUserPreferences()
    await this.loadData()
  },
}
</script>

<style scoped>

</style>
