import Vue from 'vue'

import store from '@/store'

/*
* Register application global components
*/
import './utilities/prototype'
import builderModule from './store/builder'
import layoutModule from './store/layout'

store.registerModule("builder", builderModule)
store.registerModule("layout", layoutModule)

/*
* Register third party libs
*/

import vueTour from 'vue-tour'

Vue.use(vueTour)
require('vue-tour/dist/vue-tour.css')

import VueSkeletonLoader from 'vue-skeleton-loader'

// Extends also premade components for styling
Vue.use(VueSkeletonLoader)