<template>
    <div class="container">
        <div class="pt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="img-holder">
                            <img src="../../../img/tick.svg">
                        </div>
                        <div class="py-3">
                            <h4 class="font-weight-bold">{{ translate('Your plan', 'plans') }} <span class="your-plan">{{ value.name }}</span> {{ translate('success', 'plans') }}</h4>
                        </div>
                        <div class="d-flex justify-content-between">
                            <router-link to="plans">
                                <button class="btn btn-white rounded-pill m-2 px-5">{{ translate('Go back', 'global') }}</button>
                            </router-link>
                            <router-link to="create">
                                <button class="btn btn-primary rounded-pill m-2 px-5">{{ translate('Create another', 'plans') }}</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {db} from "../../../../../utilities/database";

    export default {
        name: "PlanFinished",
        // mounted() {
        //     db.post(`/modules/blockz/plans`, {...this.value, })
        // },
        props: {
            value: {
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    .img-holder img {
        width: 75px;
    }

    .your-plan {
        color: $primary;
    }
</style>