<template>
  <div class="left-sidebar-content pages-sidebar">
    <div class="left-sidebar-content--container pages-sidebar--container nav-scrollbar">

      <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
        <div>
          <h6>{{ translate(`Your {entityPlural}`, 'page-builder', { entityPlural: entityNamePluralTranslated }) }}</h6>
          <p><small class="info mt-1">{{ translate(`Summary of all the currently available {entityPlural} for your website. You can create a new {entity} or edit an existing one by selecting it.`, 'page-builder', { entity: entityNameTranslated, entityPlural: entityNamePluralTranslated }) }}</small></p>
        </div>

        <div class="close-sidebar">
          <a @click="closeLeftSidebar">
            <i class="icon-erp-plus icon-erp-rotate-45 text-bold"></i>
          </a>
        </div>
      </div>

      <ul v-if="entities" class="pages-sidebar--nav nav-scrollbar">
        <li class="pages-sidebar--item" v-for="(entityData, pi) in entities.objectData" :key="pi"
            :class="{'active': builder.meta.entityId == entityData.id, 'pages-sidebar--link-locked' : isEntityLocked(entityData) }">
          <!--          <span class="page-status" :class="`status-${entityData.status}`"></span>-->
          <router-link
            v-if="!isEntityLocked(entityData)"
            @click.native="closeLeftSidebar" class="pages-sidebar--link d-flex"
            :to="`/builder/${builder.meta.entity}/${entityData.id}`"
          >
            <i class="icon-erp-pages icon-erp-1x mr-2"></i>
            <span>{{entityData.name }}</span>
          </router-link>
          <div v-else class="pages-sidebar--link d-flex cursor-pointer" @click="unlockRow(entities.objectData[pi])">
            <i class="fa-solid fa-lock mr-2"></i>
            <span>{{ entityData.name }}</span>
          </div>
          <span class="home-page" v-if="entityData.url_key === null || entityData.url_key === '/'">
              <i class="icon-erp-home icon-erp-1x"></i>
          </span>
          <div class="pages-sidebar--options" v-if="!isEntityLocked(entityData)" @blur="showSettings = ''" :class="{'open': showSettings === pi}">
            <span class="icon-erp-cog icon-erp-1x" @click="e => openSettings(pi, e)"></span>
          </div>
          <div class="_container">
            <ul class="reset-list">
              <li v-if="builder.meta.entity === 'cms-pages'">
                <a href="javascript:;" @click="markAsHomePage(entityData.id)">
                  <i class="icon-erp-home icon-erp-1x"></i>
                  <span>{{ entityData.url_key === null || entityData.url_key === '/' ? 'This is home page' : 'Mark as home page'}}</span>
                </a>
              </li>
              <li>
                <router-link :to="`/builder/${builder.meta.entity}/${entityData.id}`">
                  <i class="icon-erp-edit-page"></i>
                  {{ translate('Edit {entity}', 'entities', { entity: entityNameTranslated }) }}
                </router-link>
              </li>
              <li>
                <!-- Check in duplicateContent method is needed to ensure that the correct content is duplicated -->
                <a href="javascript:;" @click="duplicateContent(entityData.id == $route.params.id ? entity.objectData : entityData)">
                  <i class="icon-erp-duplicate-page"></i>

                  {{ translate('Duplicate {entity}', 'entities', { entity: entityNameTranslated }) }}
                </a>
              </li>
              <li>
                <a href="javascript:;" @click="changeStatus(entityData)">
                  <i class="icon-erp-change-status"></i>
                  {{ translate('Change status', 'page-builder') }}
                </a>
              </li>
              <li>
                <a href="javascript:;" @click="deleteEntity(entityData.id)">
                  <i class="icon-erp-trash"></i>
                  {{ translate('Delete {entity}', 'page-builder', { entity: entityNameTranslated }) }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- we show all pages for now, uncomment and fix the design if you need pagination
            <pagination v-if="true" v-model="entities.meta" @goToPage="goToPage" class="mt-auto justify-content-between"></pagination>
      -->
      <div id="create-new-entity-container">
        <router-link tag="a" class="btn add-new-page" @click.native="closeLeftSidebar"
                     :to="`/builder/${builder.meta.entity}/create`">
          <i class="fa fa-plus fa-sm mx-1 text-normal"></i>{{ translate('Add new {entity}', 'entities', { entity: entityNameTranslated.toLowerCase() }) }}
        </router-link>
      </div>
    </div>

    <modal name="duplicate_content"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="auto"
           width="500px"
           transition="slide-top"
           overlayTransition="wait"
           classes="remove-modal-height">
      <duplicate-content
        v-model="duplicatedContent"
        @save="e => saveDuplicatedContent(e, fetchEntities, true)"
        :entity="builder.meta.entity"
        :entity-name-translated="entityNameTranslated"
      />
    </modal>

  </div>
</template>

<script>
import uniqid from 'uniqid'
import { mapActions, mapGetters, mapState } from 'vuex';
import BuilderMixin, {entities} from '../../../mixins/BuilderMixin'
import DuplicateContentMixin from "@/mixins/DuplicateContentMixin"
import {ErpModel} from '@/modules/erp_framework'
import DuplicateContent from "../../DuplicateContent.vue"
import { hasManyTranslationLocales, isEntityLocked } from "@/utilities/helper";

export default {
  name: "pages",
  components: {
    DuplicateContent,
  },
  mixins: [BuilderMixin, DuplicateContentMixin],
  data() {
    return {
      showSettings: '',
      duplicatedContent: {},
    }
  },
  computed: {
    ...mapState('builder', [
      'entities', 'entity',
    ]),
    ...mapGetters('system', ['isAdmin']),
    locale() {
      return hasManyTranslationLocales() ? this.$store.state.system.translation_locale : false
    },
  },
  methods: {
    ...mapActions('builder', [
      'fetchEntities'
    ]),
    isEntityLocked,
    // we show all pages for now, uncomment and fix the design if you need pagination
    goToPage(page) {
      this.fetchEntities({entity: this.builder.meta.entity, page: page, locale: this.locale })
    },
    searchEntity(v) {
      this.fetchEntities({entity: this.builder.meta.entity, name: v, locale: this.locale })
    },
    clearSearch() {
      this.$refs.entitySearch.value = ''
      this.searchEntity('')
    },
    closeLeftSidebar() {
      this.$emit('closeLeftSidebar')
    },
    openSettings(index, e) {
      if (this.showSettings === index) {
        this.showSettings = null
      } else {
        this.showSettings = index
      }

      let liPosition = e.target.offsetParent.offsetParent.getBoundingClientRect()
      let container = e.target.offsetParent.nextSibling
      let containerPosition = container.getBoundingClientRect()
      let windowBottom = window.innerHeight
      container.style.top = `calc(${liPosition.top + 22}px - ${containerPosition.height / 2}px)`
      container.style.left = `${liPosition.right - 215}px`
      if (containerPosition.bottom > windowBottom) {
        container.style.top = `calc(${liPosition.top}px - ${containerPosition.height * 1.5}px)`
      }
    },
    async deleteEntity(id){
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.$store.state.builder.originalState = this.entity.objectData // disable the secondary "unsaved changes" modal

      await this.erp.ext.request.axiosInstance.delete(`modules/${this.builder.meta.entity}/${id}`)

      this.showSettings = '' // Hide options menu after delete
      this.$toast.requestSuccess('delete', this.entityNameTranslated)

      //Load data
      await this.fetchEntities({ entity: this.builder.meta.entity, locale: this.locale })

      // If the currently selected entity item is deleted
      if(id == this.builder.meta.entityId){
        this.$store.commit('builder/SET_ENTITY', { objectData: {} })

        // if we have more entries in this.entities select the first one
        if(this.entities.objectData.length > 0) {
          return this.$router.push(`/builder/${this.builder.meta.entity}/${this.entities.objectData[0].id}`)
        } else {
          // If we have no more entries redirect to create page
          const route = `/builder/${this.builder.meta.entity}/create`
          if(this.$route.path !== route) {
            return this.$router.push(route)
          }
        }
      }

    },
    async markAsHomePage(id){
      let homePage = this.entities.objectData.filter(page => page.url_key === '/' || page.url_key === '' || page.url_key === null)
      if(homePage.length > 0){
        let updateData = {...homePage[0]}
        updateData.url_key = uniqid()
        delete updateData.id
        await this.erp.ext.request.axiosInstance.put('modules/cms-pages/' + homePage[0].id, {options: updateData})
      }

      let page = await ErpModel.find('cms-pages', id)
      await page.set('url_key', '/').save()
        .catch(error => {
          this.$alert.formattedError(error)
        })

      await this.fetchEntities({ entity: this.builder.meta.entity, locale: this.locale })
      this.showSettings = ''
    },

    async unlockRow(entity) {
      if (this.isAdmin) {

        const confirmUnlock = this.$alert.confirm({
          title: 'Are you sure? {entity} will be unlocked for editing!',
          translateArgs: { entity: this.entityNameTranslated },
        })

        if(!confirmUnlock.isConfirmed) return

        const options = { locked_by: null, locked_until: null }

        this.erp.ext.request.axiosInstance
          .put(`/modules/${this.builder.meta.entity}/${entity.id}`, { options })
          //.then(() => this.fetchEntities({ entity: this.builder.meta.entity, locale: this.locale })) // no need to re-fetch all, just set row properties
          .then(() => {
            entity.locked_by = null
            entity.locked_until = null
          })
      } else {
        this.$toast.error({
          title: 'This {entity} is already being edited by another user.',
          translateArgs: { entity: this.entityNameTranslated },
        })
      }
    },

    async changeStatus(page) {
      page.status = Number(!Number(page.status))
      let data = {options: { ...page }}
      delete data.options.id
      await this.erp.ext.request.axiosInstance.put(`/modules/cms-pages/${page.id}`, data)
      await this.$toast.success('Page status changed successfully!')
    }
  },
  filters: {
    // todo #183421413 make it global
    replace(value, pattern, replacement) {
      return value.replace(pattern, replacement)
    },
  },
}
</script>

<style lang="scss" scoped>

.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item .pages-sidebar--link {
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
}

.pages-sidebar--link-locked {
  background-color: $secondary;
}

.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item .home-page{
  color: #000;
  font-weight: bold;
}
.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item .pages-sidebar--options span{
  font-size: 1.5rem;
  color: #000;
}

.pages-sidebar .pages-sidebar--container .pages-sidebar--nav {
  height: calc(100% - 193px - 1.25rem); /* 78px is the height of #create-new-entity-container but somehow it needs 193px, 1.25rem is margin between elements */
}

.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item .pages-sidebar--options span.icon-erp-cog.icon-erp-1x{
  font-size: 1rem;
}

.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item:hover{
  background: #fff;
}

.icon-erp-pages.icon-erp-1x{
  margin-bottom: .2rem;
}
.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item.active{
  border-bottom: none;
}
.pages-sidebar .pages-sidebar--container .pages-sidebar--nav .pages-sidebar--item.active a{
  color: $primary;
}
</style>
