import Entities from "./views/entities/Entities.vue"
import EntityIndex from "./components/page/EntityIndex.vue"
import EntitiesForm from './views/entities/EntitiesForm.vue'

import EntityComposer from "./components/page/EntityComposer.vue"

import Settings from "./components/settings/Settings.vue"
import Dropdowns from "./views/dropdowns/Dropdowns.vue"
import DropdownsForm from "./views/dropdowns/DropdownsForm.vue"


export default [

  //Entities routes
  {
    path: '/entities',
    name: 'entities',
    component: Entities,
  },
  {
    path: '/entities/create',
    name: 'entities.create',
    component: EntitiesForm,
  },
  {
    path: '/entities/:id',
    name: 'entities.edit',
    component: EntitiesForm,
  },

  //Entity index and show routes, companies, deals, projects etc...
  {
    path: '/entity/:entityType',
    name: 'entity-table',
    component: EntityIndex,
  },
  {
    path: '/entity/:entityType/create',
    name: 'entity-create',
    component: EntityComposer,
  },
  {
    path: '/entity/:entityType/:id',
    name: 'entity-edit',
    component: EntityComposer,
  },

  // Entity settings
  {
    path: '/entity/:entityType/settings',
    name: 'entity-settings',
    component: Settings,
  },


  // Dropdowns
  {
    path: '/dropdowns',
    name: 'dropdowns',
    component: Dropdowns,
  },
  {
    path: '/dropdowns/create',
    name: 'dropdowns.create',
    component: DropdownsForm,
  },
  {
    path: '/dropdowns/:id',
    name: 'dropdowns.edit',
    component: DropdownsForm,
  },

]