import { erp } from "@/modules/erp_framework"
import store from '../store'

export default async function setColors() {
  let colors
  let options = {
    primary_color: '',
    secondary_color: '',
    third_color: '',
  }

  if (store.state.system.scope && store.state.system.scope.value) {
    colors = await erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${store.state.system.scope.obj.id}/colors-cmswebsite/general_info`)

    Object.keys(options).forEach(key => {
      colors.data.data.forEach(color => {
        if (color.key === key) options[key] = color.value
      })
    })

    window.localStorage.setItem('colors', JSON.stringify(options))
  }
}