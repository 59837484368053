export default [
    "fab fa-500px",
    "fab fa-accessible-icon",
    "fab fa-accusoft",
    "fab fa-acquisitions-incorporated",
    "fas fa-ad",
    "fas fa-address-book",
    "far fa-address-book",
    "fas fa-address-card",
    "far fa-address-card",
    "fas fa-adjust",
    "fab fa-adn",
    "fab fa-adobe",
    "fab fa-adversal",
    "fab fa-affiliatetheme",
    "fas fa-air-freshener",
    "fab fa-airbnb",
    "fab fa-algolia",
    "fas fa-align-center",
    "fas fa-align-justify",
    "fas fa-align-left",
    "fas fa-align-right",
    "fab fa-alipay",
    "fas fa-allergies",
    "fab fa-amazon",
    "fab fa-amazon-pay",
    "fas fa-ambulance",
    "fas fa-american-sign-language-interpreting",
    "fab fa-amilia",
    "fas fa-anchor",
    "fab fa-android",
    "fab fa-angellist",
    "fas fa-angle-double-down",
    "fas fa-angle-double-left",
    "fas fa-angle-double-right",
    "fas fa-angle-double-up",
    "fas fa-angle-down",
    "fas fa-angle-left",
    "fas fa-angle-right",
    "fas fa-angle-up",
    "fas fa-angry",
    "far fa-angry",
    "fab fa-angrycreative",
    "fab fa-angular",
    "fas fa-ankh",
    "fab fa-app-store",
    "fab fa-app-store-ios",
    "fab fa-apper",
    "fab fa-apple",
    "fas fa-apple-alt",
    "fab fa-apple-pay",
    "fas fa-archive",
    "fas fa-archway",
    "fas fa-arrow-alt-circle-down",
    "far fa-arrow-alt-circle-down",
    "fas fa-arrow-alt-circle-left",
    "far fa-arrow-alt-circle-left",
    "fas fa-arrow-alt-circle-right",
    "far fa-arrow-alt-circle-right",
    "fas fa-arrow-alt-circle-up",
    "far fa-arrow-alt-circle-up",
    "fas fa-arrow-circle-down",
    "fas fa-arrow-circle-left",
    "fas fa-arrow-circle-right",
    "fas fa-arrow-circle-up",
    "fas fa-arrow-down",
    "fas fa-arrow-left",
    "fas fa-arrow-right",
    "fas fa-arrow-up",
    "fas fa-arrows-alt",
    "fas fa-arrows-alt-h",
    "fas fa-arrows-alt-v",
    "fab fa-artstation",
    "fas fa-assistive-listening-systems",
    "fas fa-asterisk",
    "fab fa-asymmetrik",
    "fas fa-at",
    "fas fa-atlas",
    "fab fa-atlassian",
    "fas fa-atom",
    "fab fa-audible",
    "fas fa-audio-description",
    "fab fa-autoprefixer",
    "fab fa-avianex",
    "fab fa-aviato",
    "fas fa-award",
    "fab fa-aws",
    "fas fa-baby",
    "fas fa-baby-carriage",
    "fas fa-backspace",
    "fas fa-backward",
    "fas fa-bacon",
    "fas fa-bahai",
    "fas fa-balance-scale",
    "fas fa-balance-scale-left",
    "fas fa-balance-scale-right",
    "fas fa-ban",
    "fas fa-band-aid",
    "fab fa-bandcamp",
    "fas fa-barcode",
    "fas fa-bars",
    "fas fa-baseball-ball",
    "fas fa-basketball-ball",
    "fas fa-bath",
    "fas fa-battery-empty",
    "fas fa-battery-full",
    "fas fa-battery-half",
    "fas fa-battery-quarter",
    "fas fa-battery-three-quarters",
    "fab fa-battle-net",
    "fas fa-bed",
    "fas fa-beer",
    "fab fa-behance",
    "fab fa-behance-square",
    "fas fa-bell",
    "far fa-bell",
    "fas fa-bell-slash",
    "far fa-bell-slash",
    "fas fa-bezier-curve",
    "fas fa-bible",
    "fas fa-bicycle",
    "fas fa-biking",
    "fab fa-bimobject",
    "fas fa-binoculars",
    "fas fa-biohazard",
    "fas fa-birthday-cake",
    "fab fa-bitbucket",
    "fab fa-bitcoin",
    "fab fa-bity",
    "fab fa-black-tie",
    "fab fa-blackberry",
    "fas fa-blender",
    "fas fa-blender-phone",
    "fas fa-blind",
    "fas fa-blog",
    "fab fa-blogger",
    "fab fa-blogger-b",
    "fab fa-bluetooth",
    "fab fa-bluetooth-b",
    "fas fa-bold",
    "fas fa-bolt",
    "fas fa-bomb",
    "fas fa-bone",
    "fas fa-bong",
    "fas fa-book",
    "fas fa-book-dead",
    "fas fa-book-medical",
    "fas fa-book-open",
    "fas fa-book-reader",
    "fas fa-bookmark",
    "far fa-bookmark",
    "fab fa-bootstrap",
    "fas fa-border-all",
    "fas fa-border-none",
    "fas fa-border-style",
    "fas fa-bowling-ball",
    "fas fa-box",
    "fas fa-box-open",
    "fas fa-boxes",
    "fas fa-braille",
    "fas fa-brain",
    "fas fa-bread-slice",
    "fas fa-briefcase",
    "fas fa-briefcase-medical",
    "fas fa-broadcast-tower",
    "fas fa-broom",
    "fas fa-brush",
    "fab fa-btc",
    "fab fa-buffer",
    "fas fa-bug",
    "fas fa-building",
    "far fa-building",
    "fas fa-bullhorn",
    "fas fa-bullseye",
    "fas fa-burn",
    "fab fa-buromobelexperte",
    "fas fa-bus",
    "fas fa-bus-alt",
    "fas fa-business-time",
    "fab fa-buy-n-large",
    "fab fa-buysellads",
    "fas fa-calculator",
    "fas fa-calendar",
    "far fa-calendar",
    "fas fa-calendar-alt",
    "far fa-calendar-alt",
    "fas fa-calendar-check",
    "far fa-calendar-check",
    "fas fa-calendar-day",
    "fas fa-calendar-minus",
    "far fa-calendar-minus",
    "fas fa-calendar-plus",
    "far fa-calendar-plus",
    "fas fa-calendar-times",
    "far fa-calendar-times",
    "fas fa-calendar-week",
    "fas fa-camera",
    "fas fa-camera-retro",
    "fas fa-campground",
    "fab fa-canadian-maple-leaf",
    "fas fa-candy-cane",
    "fas fa-cannabis",
    "fas fa-capsules",
    "fas fa-car",
    "fas fa-car-alt",
    "fas fa-car-battery",
    "fas fa-car-crash",
    "fas fa-car-side",
    "fas fa-caravan",
    "fas fa-caret-down",
    "fas fa-caret-left",
    "fas fa-caret-right",
    "fas fa-caret-square-down",
    "far fa-caret-square-down",
    "fas fa-caret-square-left",
    "far fa-caret-square-left",
    "fas fa-caret-square-right",
    "far fa-caret-square-right",
    "fas fa-caret-square-up",
    "far fa-caret-square-up",
    "fas fa-caret-up",
    "fas fa-carrot",
    "fas fa-cart-arrow-down",
    "fas fa-cart-plus",
    "fas fa-cash-register",
    "fas fa-cat",
    "fab fa-cc-amazon-pay",
    "fab fa-cc-amex",
    "fab fa-cc-apple-pay",
    "fab fa-cc-diners-club",
    "fab fa-cc-discover",
    "fab fa-cc-jcb",
    "fab fa-cc-mastercard",
    "fab fa-cc-paypal",
    "fab fa-cc-stripe",
    "fab fa-cc-visa",
    "fab fa-centercode",
    "fab fa-centos",
    "fas fa-certificate",
    "fas fa-chair",
    "fas fa-chalkboard",
    "fas fa-chalkboard-teacher",
    "fas fa-charging-station",
    "fas fa-chart-area",
    "fas fa-chart-bar",
    "far fa-chart-bar",
    "fas fa-chart-line",
    "fas fa-chart-pie",
    "fas fa-check",
    "fas fa-check-circle",
    "far fa-check-circle",
    "fas fa-check-double",
    "fas fa-check-square",
    "far fa-check-square",
    "fas fa-cheese",
    "fas fa-chess",
    "fas fa-chess-bishop",
    "fas fa-chess-board",
    "fas fa-chess-king",
    "fas fa-chess-knight",
    "fas fa-chess-pawn",
    "fas fa-chess-queen",
    "fas fa-chess-rook",
    "fas fa-chevron-circle-down",
    "fas fa-chevron-circle-left",
    "fas fa-chevron-circle-right",
    "fas fa-chevron-circle-up",
    "fas fa-chevron-down",
    "fas fa-chevron-left",
    "fas fa-chevron-right",
    "fas fa-chevron-up",
    "fas fa-child",
    "fab fa-chrome",
    "fab fa-chromecast",
    "fas fa-church",
    "fas fa-circle",
    "far fa-circle",
    "fas fa-circle-notch",
    "fas fa-city",
    "fas fa-clinic-medical",
    "fas fa-clipboard",
    "far fa-clipboard",
    "fas fa-clipboard-check",
    "fas fa-clipboard-list",
    "fas fa-clock",
    "far fa-clock",
    "fas fa-clone",
    "far fa-clone",
    "fas fa-closed-captioning",
    "far fa-closed-captioning",
    "fas fa-cloud",
    "fas fa-cloud-download-alt",
    "fas fa-cloud-meatball",
    "fas fa-cloud-moon",
    "fas fa-cloud-moon-rain",
    "fas fa-cloud-rain",
    "fas fa-cloud-showers-heavy",
    "fas fa-cloud-sun",
    "fas fa-cloud-sun-rain",
    "fas fa-cloud-upload-alt",
    "fab fa-cloudscale",
    "fab fa-cloudsmith",
    "fab fa-cloudversify",
    "fas fa-cocktail",
    "fas fa-code",
    "fas fa-code-branch",
    "fab fa-codepen",
    "fab fa-codiepie",
    "fas fa-coffee",
    "fas fa-cog",
    "fas fa-cogs",
    "fas fa-coins",
    "fas fa-columns",
    "fas fa-comment",
    "far fa-comment",
    "fas fa-comment-alt",
    "far fa-comment-alt",
    "fas fa-comment-dollar",
    "fas fa-comment-dots",
    "far fa-comment-dots",
    "fas fa-comment-medical",
    "fas fa-comment-slash",
    "fas fa-comments",
    "far fa-comments",
    "fas fa-comments-dollar",
    "fas fa-compact-disc",
    "fas fa-compass",
    "far fa-compass",
    "fas fa-compress",
    "fas fa-compress-alt",
    "fas fa-compress-arrows-alt",
    "fas fa-concierge-bell",
    "fab fa-confluence",
    "fab fa-connectdevelop",
    "fab fa-contao",
    "fas fa-cookie",
    "fas fa-cookie-bite",
    "fas fa-copy",
    "far fa-copy",
    "fas fa-copyright",
    "far fa-copyright",
    "fab fa-cotton-bureau",
    "fas fa-couch",
    "fab fa-cpanel",
    "fab fa-creative-commons",
    "fab fa-creative-commons-by",
    "fab fa-creative-commons-nc",
    "fab fa-creative-commons-nc-eu",
    "fab fa-creative-commons-nc-jp",
    "fab fa-creative-commons-nd",
    "fab fa-creative-commons-pd",
    "fab fa-creative-commons-pd-alt",
    "fab fa-creative-commons-remix",
    "fab fa-creative-commons-sa",
    "fab fa-creative-commons-sampling",
    "fab fa-creative-commons-sampling-plus",
    "fab fa-creative-commons-share",
    "fab fa-creative-commons-zero",
    "fas fa-credit-card",
    "far fa-credit-card",
    "fab fa-critical-role",
    "fas fa-crop",
    "fas fa-crop-alt",
    "fas fa-cross",
    "fas fa-crosshairs",
    "fas fa-crow",
    "fas fa-crown",
    "fas fa-crutch",
    "fab fa-css3",
    "fab fa-css3-alt",
    "fas fa-cube",
    "fas fa-cubes",
    "fas fa-cut",
    "fab fa-cuttlefish",
    "fab fa-d-and-d",
    "fab fa-d-and-d-beyond",
    "fab fa-dailymotion",
    "fab fa-dashcube",
    "fas fa-database",
    "fas fa-deaf",
    "fab fa-delicious",
    "fas fa-democrat",
    "fab fa-deploydog",
    "fab fa-deskpro",
    "fas fa-desktop",
    "fab fa-dev",
    "fab fa-deviantart",
    "fas fa-dharmachakra",
    "fab fa-dhl",
    "fas fa-diagnoses",
    "fab fa-diaspora",
    "fas fa-dice",
    "fas fa-dice-d20",
    "fas fa-dice-d6",
    "fas fa-dice-five",
    "fas fa-dice-four",
    "fas fa-dice-one",
    "fas fa-dice-six",
    "fas fa-dice-three",
    "fas fa-dice-two",
    "fab fa-digg",
    "fab fa-digital-ocean",
    "fas fa-digital-tachograph",
    "fas fa-directions",
    "fab fa-discord",
    "fab fa-discourse",
    "fas fa-divide",
    "fas fa-dizzy",
    "far fa-dizzy",
    "fas fa-dna",
    "fab fa-dochub",
    "fab fa-docker",
    "fas fa-dog",
    "fas fa-dollar-sign",
    "fas fa-dolly",
    "fas fa-dolly-flatbed",
    "fas fa-donate",
    "fas fa-door-closed",
    "fas fa-door-open",
    "fas fa-dot-circle",
    "far fa-dot-circle",
    "fas fa-dove",
    "fas fa-download",
    "fab fa-draft2digital",
    "fas fa-drafting-compass",
    "fas fa-dragon",
    "fas fa-draw-polygon",
    "fab fa-dribbble",
    "fab fa-dribbble-square",
    "fab fa-dropbox",
    "fas fa-drum",
    "fas fa-drum-steelpan",
    "fas fa-drumstick-bite",
    "fab fa-drupal",
    "fas fa-dumbbell",
    "fas fa-dumpster",
    "fas fa-dumpster-fire",
    "fas fa-dungeon",
    "fab fa-dyalog",
    "fab fa-earlybirds",
    "fab fa-ebay",
    "fab fa-edge",
    "fas fa-edit",
    "far fa-edit",
    "fas fa-egg",
    "fas fa-eject",
    "fab fa-elementor",
    "fas fa-ellipsis-h",
    "fas fa-ellipsis-v",
    "fab fa-ello",
    "fab fa-ember",
    "fab fa-empire",
    "fas fa-envelope",
    "far fa-envelope",
    "fas fa-envelope-open",
    "far fa-envelope-open",
    "fas fa-envelope-open-text",
    "fas fa-envelope-square",
    "fab fa-envira",
    "fas fa-equals",
    "fas fa-eraser",
    "fab fa-erlang",
    "fab fa-ethereum",
    "fas fa-ethernet",
    "fab fa-etsy",
    "fas fa-euro-sign",
    "fab fa-evernote",
    "fas fa-exchange-alt",
    "fas fa-exclamation",
    "fas fa-exclamation-circle",
    "fas fa-exclamation-triangle",
    "fas fa-expand",
    "fas fa-expand-alt",
    "fas fa-expand-arrows-alt",
    "fab fa-expeditedssl",
    "fas fa-external-link-alt",
    "fas fa-external-link-square-alt",
    "fas fa-eye",
    "far fa-eye",
    "fas fa-eye-dropper",
    "fas fa-eye-slash",
    "far fa-eye-slash",
    "fab fa-facebook",
    "fab fa-facebook-f",
    "fab fa-facebook-messenger",
    "fab fa-facebook-square",
    "fas fa-fan",
    "fab fa-fantasy-flight-games",
    "fas fa-fast-backward",
    "fas fa-fast-forward",
    "fas fa-fax",
    "fas fa-feather",
    "fas fa-feather-alt",
    "fab fa-fedex",
    "fab fa-fedora",
    "fas fa-female",
    "fas fa-fighter-jet",
    "fab fa-figma",
    "fas fa-file",
    "far fa-file",
    "fas fa-file-alt",
    "far fa-file-alt",
    "fas fa-file-archive",
    "far fa-file-archive",
    "fas fa-file-audio",
    "far fa-file-audio",
    "fas fa-file-code",
    "far fa-file-code",
    "fas fa-file-contract",
    "fas fa-file-csv",
    "fas fa-file-download",
    "fas fa-file-excel",
    "far fa-file-excel",
    "fas fa-file-export",
    "fas fa-file-image",
    "far fa-file-image",
    "fas fa-file-import",
    "fas fa-file-invoice",
    "fas fa-file-invoice-dollar",
    "fas fa-file-medical",
    "fas fa-file-medical-alt",
    "fas fa-file-pdf",
    "far fa-file-pdf",
    "fas fa-file-powerpoint",
    "far fa-file-powerpoint",
    "fas fa-file-prescription",
    "fas fa-file-signature",
    "fas fa-file-upload",
    "fas fa-file-video",
    "far fa-file-video",
    "fas fa-file-word",
    "far fa-file-word",
    "fas fa-fill",
    "fas fa-fill-drip",
    "fas fa-film",
    "fas fa-filter",
    "fas fa-fingerprint",
    "fas fa-fire",
    "fas fa-fire-alt",
    "fas fa-fire-extinguisher",
    "fab fa-firefox",
    "fab fa-firefox-browser",
    "fas fa-first-aid",
    "fab fa-first-order",
    "fab fa-first-order-alt",
    "fab fa-firstdraft",
    "fas fa-fish",
    "fas fa-fist-raised",
    "fas fa-flag",
    "far fa-flag",
    "fas fa-flag-checkered",
    "fas fa-flag-usa",
    "fas fa-flask",
    "fab fa-flickr",
    "fab fa-flipboard",
    "fas fa-flushed",
    "far fa-flushed",
    "fab fa-fly",
    "fas fa-folder",
    "far fa-folder",
    "fas fa-folder-minus",
    "fas fa-folder-open",
    "far fa-folder-open",
    "fas fa-folder-plus",
    "fas fa-font",
    "fab fa-font-awesome",
    "fab fa-font-awesome-alt",
    "fab fa-font-awesome-flag",
    "fab fa-fonticons",
    "fab fa-fonticons-fi",
    "fas fa-football-ball",
    "fab fa-fort-awesome",
    "fab fa-fort-awesome-alt",
    "fab fa-forumbee",
    "fas fa-forward",
    "fab fa-foursquare",
    "fab fa-free-code-camp",
    "fab fa-freebsd",
    "fas fa-frog",
    "fas fa-frown",
    "far fa-frown",
    "fas fa-frown-open",
    "far fa-frown-open",
    "fab fa-fulcrum",
    "fas fa-funnel-dollar",
    "fas fa-futbol",
    "far fa-futbol",
    "fab fa-galactic-republic",
    "fab fa-galactic-senate",
    "fas fa-gamepad",
    "fas fa-gas-pump",
    "fas fa-gavel",
    "fas fa-gem",
    "far fa-gem",
    "fas fa-genderless",
    "fab fa-get-pocket",
    "fab fa-gg",
    "fab fa-gg-circle",
    "fas fa-ghost",
    "fas fa-gift",
    "fas fa-gifts",
    "fab fa-git",
    "fab fa-git-alt",
    "fab fa-git-square",
    "fab fa-github",
    "fab fa-github-alt",
    "fab fa-github-square",
    "fab fa-gitkraken",
    "fab fa-gitlab",
    "fab fa-gitter",
    "fas fa-glass-cheers",
    "fas fa-glass-martini",
    "fas fa-glass-martini-alt",
    "fas fa-glass-whiskey",
    "fas fa-glasses",
    "fab fa-glide",
    "fab fa-glide-g",
    "fas fa-globe",
    "fas fa-globe-africa",
    "fas fa-globe-americas",
    "fas fa-globe-asia",
    "fas fa-globe-europe",
    "fab fa-gofore",
    "fas fa-golf-ball",
    "fab fa-goodreads",
    "fab fa-goodreads-g",
    "fab fa-google",
    "fab fa-google-drive",
    "fab fa-google-play",
    "fab fa-google-plus",
    "fab fa-google-plus-g",
    "fab fa-google-plus-square",
    "fab fa-google-wallet",
    "fas fa-gopuram",
    "fas fa-graduation-cap",
    "fab fa-gratipay",
    "fab fa-grav",
    "fas fa-greater-than",
    "fas fa-greater-than-equal",
    "fas fa-grimace",
    "far fa-grimace",
    "fas fa-grin",
    "far fa-grin",
    "fas fa-grin-alt",
    "far fa-grin-alt",
    "fas fa-grin-beam",
    "far fa-grin-beam",
    "fas fa-grin-beam-sweat",
    "far fa-grin-beam-sweat",
    "fas fa-grin-hearts",
    "far fa-grin-hearts",
    "fas fa-grin-squint",
    "far fa-grin-squint",
    "fas fa-grin-squint-tears",
    "far fa-grin-squint-tears",
    "fas fa-grin-stars",
    "far fa-grin-stars",
    "fas fa-grin-tears",
    "far fa-grin-tears",
    "fas fa-grin-tongue",
    "far fa-grin-tongue",
    "fas fa-grin-tongue-squint",
    "far fa-grin-tongue-squint",
    "fas fa-grin-tongue-wink",
    "far fa-grin-tongue-wink",
    "fas fa-grin-wink",
    "far fa-grin-wink",
    "fas fa-grip-horizontal",
    "fas fa-grip-lines",
    "fas fa-grip-lines-vertical",
    "fas fa-grip-vertical",
    "fab fa-gripfire",
    "fab fa-grunt",
    "fas fa-guitar",
    "fab fa-gulp",
    "fas fa-h-square",
    "fab fa-hacker-news",
    "fab fa-hacker-news-square",
    "fab fa-hackerrank",
    "fas fa-hamburger",
    "fas fa-hammer",
    "fas fa-hamsa",
    "fas fa-hand-holding",
    "fas fa-hand-holding-heart",
    "fas fa-hand-holding-usd",
    "fas fa-hand-lizard",
    "far fa-hand-lizard",
    "fas fa-hand-middle-finger",
    "fas fa-hand-paper",
    "far fa-hand-paper",
    "fas fa-hand-peace",
    "far fa-hand-peace",
    "fas fa-hand-point-down",
    "far fa-hand-point-down",
    "fas fa-hand-point-left",
    "far fa-hand-point-left",
    "fas fa-hand-point-right",
    "far fa-hand-point-right",
    "fas fa-hand-point-up",
    "far fa-hand-point-up",
    "fas fa-hand-pointer",
    "far fa-hand-pointer",
    "fas fa-hand-rock",
    "far fa-hand-rock",
    "fas fa-hand-scissors",
    "far fa-hand-scissors",
    "fas fa-hand-spock",
    "far fa-hand-spock",
    "fas fa-hands",
    "fas fa-hands-helping",
    "fas fa-handshake",
    "far fa-handshake",
    "fas fa-hanukiah",
    "fas fa-hard-hat",
    "fas fa-hashtag",
    "fas fa-hat-cowboy",
    "fas fa-hat-cowboy-side",
    "fas fa-hat-wizard",
    "fas fa-hdd",
    "far fa-hdd",
    "fas fa-heading",
    "fas fa-headphones",
    "fas fa-headphones-alt",
    "fas fa-headset",
    "fas fa-heart",
    "far fa-heart",
    "fas fa-heart-broken",
    "fas fa-heartbeat",
    "fas fa-helicopter",
    "fas fa-highlighter",
    "fas fa-hiking",
    "fas fa-hippo",
    "fab fa-hips",
    "fab fa-hire-a-helper",
    "fas fa-history",
    "fas fa-hockey-puck",
    "fas fa-holly-berry",
    "fas fa-home",
    "fab fa-hooli",
    "fab fa-hornbill",
    "fas fa-horse",
    "fas fa-horse-head",
    "fas fa-hospital",
    "far fa-hospital",
    "fas fa-hospital-alt",
    "fas fa-hospital-symbol",
    "fas fa-hot-tub",
    "fas fa-hotdog",
    "fas fa-hotel",
    "fab fa-hotjar",
    "fas fa-hourglass",
    "far fa-hourglass",
    "fas fa-hourglass-end",
    "fas fa-hourglass-half",
    "fas fa-hourglass-start",
    "fas fa-house-damage",
    "fab fa-houzz",
    "fas fa-hryvnia",
    "fab fa-html5",
    "fab fa-hubspot",
    "fas fa-i-cursor",
    "fas fa-ice-cream",
    "fas fa-icicles",
    "fas fa-icons",
    "fas fa-id-badge",
    "far fa-id-badge",
    "fas fa-id-card",
    "far fa-id-card",
    "fas fa-id-card-alt",
    "fab fa-ideal",
    "fas fa-igloo",
    "fas fa-image",
    "far fa-image",
    "fas fa-images",
    "far fa-images",
    "fab fa-imdb",
    "fas fa-inbox",
    "fas fa-indent",
    "fas fa-industry",
    "fas fa-infinity",
    "fas fa-info",
    "fas fa-info-circle",
    "fab fa-instagram",
    "fab fa-instagram-square",
    "fab fa-intercom",
    "fab fa-internet-explorer",
    "fab fa-invision",
    "fab fa-ioxhost",
    "fas fa-italic",
    "fab fa-itch-io",
    "fab fa-itunes",
    "fab fa-itunes-note",
    "fab fa-java",
    "fas fa-jedi",
    "fab fa-jedi-order",
    "fab fa-jenkins",
    "fab fa-jira",
    "fab fa-joget",
    "fas fa-joint",
    "fab fa-joomla",
    "fas fa-journal-whills",
    "fab fa-js",
    "fab fa-js-square",
    "fab fa-jsfiddle",
    "fas fa-kaaba",
    "fab fa-kaggle",
    "fas fa-key",
    "fab fa-keybase",
    "fas fa-keyboard",
    "far fa-keyboard",
    "fab fa-keycdn",
    "fas fa-khanda",
    "fab fa-kickstarter",
    "fab fa-kickstarter-k",
    "fas fa-kiss",
    "far fa-kiss",
    "fas fa-kiss-beam",
    "far fa-kiss-beam",
    "fas fa-kiss-wink-heart",
    "far fa-kiss-wink-heart",
    "fas fa-kiwi-bird",
    "fab fa-korvue",
    "fas fa-landmark",
    "fas fa-language",
    "fas fa-laptop",
    "fas fa-laptop-code",
    "fas fa-laptop-medical",
    "fab fa-laravel",
    "fab fa-lastfm",
    "fab fa-lastfm-square",
    "fas fa-laugh",
    "far fa-laugh",
    "fas fa-laugh-beam",
    "far fa-laugh-beam",
    "fas fa-laugh-squint",
    "far fa-laugh-squint",
    "fas fa-laugh-wink",
    "far fa-laugh-wink",
    "fas fa-layer-group",
    "fas fa-leaf",
    "fab fa-leanpub",
    "fas fa-lemon",
    "far fa-lemon",
    "fab fa-less",
    "fas fa-less-than",
    "fas fa-less-than-equal",
    "fas fa-level-down-alt",
    "fas fa-level-up-alt",
    "fas fa-life-ring",
    "far fa-life-ring",
    "fas fa-lightbulb",
    "far fa-lightbulb",
    "fab fa-line",
    "fas fa-link",
    "fab fa-linkedin",
    "fab fa-linkedin-in",
    "fab fa-linode",
    "fab fa-linux",
    "fas fa-lira-sign",
    "fas fa-list",
    "fas fa-list-alt",
    "far fa-list-alt",
    "fas fa-list-ol",
    "fas fa-list-ul",
    "fas fa-location-arrow",
    "fas fa-lock",
    "fas fa-lock-open",
    "fas fa-long-arrow-alt-down",
    "fas fa-long-arrow-alt-left",
    "fas fa-long-arrow-alt-right",
    "fas fa-long-arrow-alt-up",
    "fas fa-low-vision",
    "fas fa-luggage-cart",
    "fab fa-lyft",
    "fab fa-magento",
    "fas fa-magic",
    "fas fa-magnet",
    "fas fa-mail-bulk",
    "fab fa-mailchimp",
    "fas fa-male",
    "fab fa-mandalorian",
    "fas fa-map",
    "far fa-map",
    "fas fa-map-marked",
    "fas fa-map-marked-alt",
    "fas fa-map-marker",
    "fas fa-map-marker-alt",
    "fas fa-map-pin",
    "fas fa-map-signs",
    "fab fa-markdown",
    "fas fa-marker",
    "fas fa-mars",
    "fas fa-mars-double",
    "fas fa-mars-stroke",
    "fas fa-mars-stroke-h",
    "fas fa-mars-stroke-v",
    "fas fa-mask",
    "fab fa-mastodon",
    "fab fa-maxcdn",
    "fab fa-mdb",
    "fas fa-medal",
    "fab fa-medapps",
    "fab fa-medium",
    "fab fa-medium-m",
    "fas fa-medkit",
    "fab fa-medrt",
    "fab fa-meetup",
    "fab fa-megaport",
    "fas fa-meh",
    "far fa-meh",
    "fas fa-meh-blank",
    "far fa-meh-blank",
    "fas fa-meh-rolling-eyes",
    "far fa-meh-rolling-eyes",
    "fas fa-memory",
    "fab fa-mendeley",
    "fas fa-menorah",
    "fas fa-mercury",
    "fas fa-meteor",
    "fab fa-microblog",
    "fas fa-microchip",
    "fas fa-microphone",
    "fas fa-microphone-alt",
    "fas fa-microphone-alt-slash",
    "fas fa-microphone-slash",
    "fas fa-microscope",
    "fab fa-microsoft",
    "fas fa-minus",
    "fas fa-minus-circle",
    "fas fa-minus-square",
    "far fa-minus-square",
    "fas fa-mitten",
    "fab fa-mix",
    "fab fa-mixcloud",
    "fab fa-mixer",
    "fab fa-mizuni",
    "fas fa-mobile",
    "fas fa-mobile-alt",
    "fab fa-modx",
    "fab fa-monero",
    "fas fa-money-bill",
    "fas fa-money-bill-alt",
    "far fa-money-bill-alt",
    "fas fa-money-bill-wave",
    "fas fa-money-bill-wave-alt",
    "fas fa-money-check",
    "fas fa-money-check-alt",
    "fas fa-monument",
    "fas fa-moon",
    "far fa-moon",
    "fas fa-mortar-pestle",
    "fas fa-mosque",
    "fas fa-motorcycle",
    "fas fa-mountain",
    "fas fa-mouse",
    "fas fa-mouse-pointer",
    "fas fa-mug-hot",
    "fas fa-music",
    "fab fa-napster",
    "fab fa-neos",
    "fas fa-network-wired",
    "fas fa-neuter",
    "fas fa-newspaper",
    "far fa-newspaper",
    "fab fa-nimblr",
    "fab fa-node",
    "fab fa-node-js",
    "fas fa-not-equal",
    "fas fa-notes-medical",
    "fab fa-npm",
    "fab fa-ns8",
    "fab fa-nutritionix",
    "fas fa-object-group",
    "far fa-object-group",
    "fas fa-object-ungroup",
    "far fa-object-ungroup",
    "fab fa-odnoklassniki",
    "fab fa-odnoklassniki-square",
    "fas fa-oil-can",
    "fab fa-old-republic",
    "fas fa-om",
    "fab fa-opencart",
    "fab fa-openid",
    "fab fa-opera",
    "fab fa-optin-monster",
    "fab fa-orcid",
    "fab fa-osi",
    "fas fa-otter",
    "fas fa-outdent",
    "fab fa-page4",
    "fab fa-pagelines",
    "fas fa-pager",
    "fas fa-paint-brush",
    "fas fa-paint-roller",
    "fas fa-palette",
    "fab fa-palfed",
    "fas fa-pallet",
    "fas fa-paper-plane",
    "far fa-paper-plane",
    "fas fa-paperclip",
    "fas fa-parachute-box",
    "fas fa-paragraph",
    "fas fa-parking",
    "fas fa-passport",
    "fas fa-pastafarianism",
    "fas fa-paste",
    "fab fa-patreon",
    "fas fa-pause",
    "fas fa-pause-circle",
    "far fa-pause-circle",
    "fas fa-paw",
    "fab fa-paypal",
    "fas fa-peace",
    "fas fa-pen",
    "fas fa-pen-alt",
    "fas fa-pen-fancy",
    "fas fa-pen-nib",
    "fas fa-pen-square",
    "fas fa-pencil-alt",
    "fas fa-pencil-ruler",
    "fab fa-penny-arcade",
    "fas fa-people-carry",
    "fas fa-pepper-hot",
    "fas fa-percent",
    "fas fa-percentage",
    "fab fa-periscope",
    "fas fa-person-booth",
    "fab fa-phabricator",
    "fab fa-phoenix-framework",
    "fab fa-phoenix-squadron",
    "fas fa-phone",
    "fas fa-phone-alt",
    "fas fa-phone-slash",
    "fas fa-phone-square",
    "fas fa-phone-square-alt",
    "fas fa-phone-volume",
    "fas fa-photo-video",
    "fab fa-php",
    "fab fa-pied-piper",
    "fab fa-pied-piper-alt",
    "fab fa-pied-piper-hat",
    "fab fa-pied-piper-pp",
    "fab fa-pied-piper-square",
    "fas fa-piggy-bank",
    "fas fa-pills",
    "fab fa-pinterest",
    "fab fa-pinterest-p",
    "fab fa-pinterest-square",
    "fas fa-pizza-slice",
    "fas fa-place-of-worship",
    "fas fa-plane",
    "fas fa-plane-arrival",
    "fas fa-plane-departure",
    "fas fa-play",
    "fas fa-play-circle",
    "far fa-play-circle",
    "fab fa-playstation",
    "fas fa-plug",
    "fas fa-plus",
    "fas fa-plus-circle",
    "fas fa-plus-square",
    "far fa-plus-square",
    "fas fa-podcast",
    "fas fa-poll",
    "fas fa-poll-h",
    "fas fa-poo",
    "fas fa-poo-storm",
    "fas fa-poop",
    "fas fa-portrait",
    "fas fa-pound-sign",
    "fas fa-power-off",
    "fas fa-pray",
    "fas fa-praying-hands",
    "fas fa-prescription",
    "fas fa-prescription-bottle",
    "fas fa-prescription-bottle-alt",
    "fas fa-print",
    "fas fa-procedures",
    "fab fa-product-hunt",
    "fas fa-project-diagram",
    "fab fa-pushed",
    "fas fa-puzzle-piece",
    "fab fa-python",
    "fab fa-qq",
    "fas fa-qrcode",
    "fas fa-question",
    "fas fa-question-circle",
    "far fa-question-circle",
    "fas fa-quidditch",
    "fab fa-quinscape",
    "fab fa-quora",
    "fas fa-quote-left",
    "fas fa-quote-right",
    "fas fa-quran",
    "fab fa-r-project",
    "fas fa-radiation",
    "fas fa-radiation-alt",
    "fas fa-rainbow",
    "fas fa-random",
    "fab fa-raspberry-pi",
    "fab fa-ravelry",
    "fab fa-react",
    "fab fa-reacteurope",
    "fab fa-readme",
    "fab fa-rebel",
    "fas fa-receipt",
    "fas fa-record-vinyl",
    "fas fa-recycle",
    "fab fa-red-river",
    "fab fa-reddit",
    "fab fa-reddit-alien",
    "fab fa-reddit-square",
    "fab fa-redhat",
    "fas fa-redo",
    "fas fa-redo-alt",
    "fas fa-registered",
    "far fa-registered",
    "fas fa-remove-format",
    "fab fa-renren",
    "fas fa-reply",
    "fas fa-reply-all",
    "fab fa-replyd",
    "fas fa-republican",
    "fab fa-researchgate",
    "fab fa-resolving",
    "fas fa-restroom",
    "fas fa-retweet",
    "fab fa-rev",
    "fas fa-ribbon",
    "fas fa-ring",
    "fas fa-road",
    "fas fa-robot",
    "fas fa-rocket",
    "fab fa-rocketchat",
    "fab fa-rockrms",
    "fas fa-route",
    "fas fa-rss",
    "fas fa-rss-square",
    "fas fa-ruble-sign",
    "fas fa-ruler",
    "fas fa-ruler-combined",
    "fas fa-ruler-horizontal",
    "fas fa-ruler-vertical",
    "fas fa-running",
    "fas fa-rupee-sign",
    "fas fa-sad-cry",
    "far fa-sad-cry",
    "fas fa-sad-tear",
    "far fa-sad-tear",
    "fab fa-safari",
    "fab fa-salesforce",
    "fab fa-sass",
    "fas fa-satellite",
    "fas fa-satellite-dish",
    "fas fa-save",
    "far fa-save",
    "fab fa-schlix",
    "fas fa-school",
    "fas fa-screwdriver",
    "fab fa-scribd",
    "fas fa-scroll",
    "fas fa-sd-card",
    "fas fa-search",
    "fas fa-search-dollar",
    "fas fa-search-location",
    "fas fa-search-minus",
    "fas fa-search-plus",
    "fab fa-searchengin",
    "fas fa-seedling",
    "fab fa-sellcast",
    "fab fa-sellsy",
    "fas fa-server",
    "fab fa-servicestack",
    "fas fa-shapes",
    "fas fa-share",
    "fas fa-share-alt",
    "fas fa-share-alt-square",
    "fas fa-share-square",
    "far fa-share-square",
    "fas fa-shekel-sign",
    "fas fa-shield-alt",
    "fas fa-ship",
    "fas fa-shipping-fast",
    "fab fa-shirtsinbulk",
    "fas fa-shoe-prints",
    "fab fa-shopify",
    "fas fa-shopping-bag",
    "fas fa-shopping-basket",
    "fas fa-shopping-cart",
    "fab fa-shopware",
    "fas fa-shower",
    "fas fa-shuttle-van",
    "fas fa-sign",
    "fas fa-sign-in-alt",
    "fas fa-sign-language",
    "fas fa-sign-out-alt",
    "fas fa-signal",
    "fas fa-signature",
    "fas fa-sim-card",
    "fab fa-simplybuilt",
    "fab fa-sistrix",
    "fas fa-sitemap",
    "fab fa-sith",
    "fas fa-skating",
    "fab fa-sketch",
    "fas fa-skiing",
    "fas fa-skiing-nordic",
    "fas fa-skull",
    "fas fa-skull-crossbones",
    "fab fa-skyatlas",
    "fab fa-skype",
    "fab fa-slack",
    "fab fa-slack-hash",
    "fas fa-slash",
    "fas fa-sleigh",
    "fas fa-sliders-h",
    "fab fa-slideshare",
    "fas fa-smile",
    "far fa-smile",
    "fas fa-smile-beam",
    "far fa-smile-beam",
    "fas fa-smile-wink",
    "far fa-smile-wink",
    "fas fa-smog",
    "fas fa-smoking",
    "fas fa-smoking-ban",
    "fas fa-sms",
    "fab fa-snapchat",
    "fab fa-snapchat-ghost",
    "fab fa-snapchat-square",
    "fas fa-snowboarding",
    "fas fa-snowflake",
    "far fa-snowflake",
    "fas fa-snowman",
    "fas fa-snowplow",
    "fas fa-socks",
    "fas fa-solar-panel",
    "fas fa-sort",
    "fas fa-sort-alpha-down",
    "fas fa-sort-alpha-down-alt",
    "fas fa-sort-alpha-up",
    "fas fa-sort-alpha-up-alt",
    "fas fa-sort-amount-down",
    "fas fa-sort-amount-down-alt",
    "fas fa-sort-amount-up",
    "fas fa-sort-amount-up-alt",
    "fas fa-sort-down",
    "fas fa-sort-numeric-down",
    "fas fa-sort-numeric-down-alt",
    "fas fa-sort-numeric-up",
    "fas fa-sort-numeric-up-alt",
    "fas fa-sort-up",
    "fab fa-soundcloud",
    "fab fa-sourcetree",
    "fas fa-spa",
    "fas fa-space-shuttle",
    "fab fa-speakap",
    "fab fa-speaker-deck",
    "fas fa-spell-check",
    "fas fa-spider",
    "fas fa-spinner",
    "fas fa-splotch",
    "fab fa-spotify",
    "fas fa-spray-can",
    "fas fa-square",
    "far fa-square",
    "fas fa-square-full",
    "fas fa-square-root-alt",
    "fab fa-squarespace",
    "fab fa-stack-exchange",
    "fab fa-stack-overflow",
    "fab fa-stackpath",
    "fas fa-stamp",
    "fas fa-star",
    "far fa-star",
    "fas fa-star-and-crescent",
    "fas fa-star-half",
    "far fa-star-half",
    "fas fa-star-half-alt",
    "fas fa-star-of-david",
    "fas fa-star-of-life",
    "fab fa-staylinked",
    "fab fa-steam",
    "fab fa-steam-square",
    "fab fa-steam-symbol",
    "fas fa-step-backward",
    "fas fa-step-forward",
    "fas fa-stethoscope",
    "fab fa-sticker-mule",
    "fas fa-sticky-note",
    "far fa-sticky-note",
    "fas fa-stop",
    "fas fa-stop-circle",
    "far fa-stop-circle",
    "fas fa-stopwatch",
    "fas fa-store",
    "fas fa-store-alt",
    "fab fa-strava",
    "fas fa-stream",
    "fas fa-street-view",
    "fas fa-strikethrough",
    "fab fa-stripe",
    "fab fa-stripe-s",
    "fas fa-stroopwafel",
    "fab fa-studiovinari",
    "fab fa-stumbleupon",
    "fab fa-stumbleupon-circle",
    "fas fa-subscript",
    "fas fa-subway",
    "fas fa-suitcase",
    "fas fa-suitcase-rolling",
    "fas fa-sun",
    "far fa-sun",
    "fab fa-superpowers",
    "fas fa-superscript",
    "fab fa-supple",
    "fas fa-surprise",
    "far fa-surprise",
    "fab fa-suse",
    "fas fa-swatchbook",
    "fab fa-swift",
    "fas fa-swimmer",
    "fas fa-swimming-pool",
    "fab fa-symfony",
    "fas fa-synagogue",
    "fas fa-sync",
    "fas fa-sync-alt",
    "fas fa-syringe",
    "fas fa-table",
    "fas fa-table-tennis",
    "fas fa-tablet",
    "fas fa-tablet-alt",
    "fas fa-tablets",
    "fas fa-tachometer-alt",
    "fas fa-tag",
    "fas fa-tags",
    "fas fa-tape",
    "fas fa-tasks",
    "fas fa-taxi",
    "fab fa-teamspeak",
    "fas fa-teeth",
    "fas fa-teeth-open",
    "fab fa-telegram",
    "fab fa-telegram-plane",
    "fas fa-temperature-high",
    "fas fa-temperature-low",
    "fab fa-tencent-weibo",
    "fas fa-tenge",
    "fas fa-terminal",
    "fas fa-text-height",
    "fas fa-text-width",
    "fas fa-th",
    "fas fa-th-large",
    "fas fa-th-list",
    "fab fa-the-red-yeti",
    "fas fa-theater-masks",
    "fab fa-themeco",
    "fab fa-themeisle",
    "fas fa-thermometer",
    "fas fa-thermometer-empty",
    "fas fa-thermometer-full",
    "fas fa-thermometer-half",
    "fas fa-thermometer-quarter",
    "fas fa-thermometer-three-quarters",
    "fab fa-think-peaks",
    "fas fa-thumbs-down",
    "far fa-thumbs-down",
    "fas fa-thumbs-up",
    "far fa-thumbs-up",
    "fas fa-thumbtack",
    "fas fa-ticket-alt",
    "fas fa-times",
    "fas fa-times-circle",
    "far fa-times-circle",
    "fas fa-tint",
    "fas fa-tint-slash",
    "fas fa-tired",
    "far fa-tired",
    "fas fa-toggle-off",
    "fas fa-toggle-on",
    "fas fa-toilet",
    "fas fa-toilet-paper",
    "fas fa-toolbox",
    "fas fa-tools",
    "fas fa-tooth",
    "fas fa-torah",
    "fas fa-torii-gate",
    "fas fa-tractor",
    "fab fa-trade-federation",
    "fas fa-trademark",
    "fas fa-traffic-light",
    "fas fa-trailer",
    "fas fa-train",
    "fas fa-tram",
    "fas fa-transgender",
    "fas fa-transgender-alt",
    "fas fa-trash",
    "fas fa-trash-alt",
    "far fa-trash-alt",
    "fas fa-trash-restore",
    "fas fa-trash-restore-alt",
    "fas fa-tree",
    "fab fa-trello",
    "fab fa-tripadvisor",
    "fas fa-trophy",
    "fas fa-truck",
    "fas fa-truck-loading",
    "fas fa-truck-monster",
    "fas fa-truck-moving",
    "fas fa-truck-pickup",
    "fas fa-tshirt",
    "fas fa-tty",
    "fab fa-tumblr",
    "fab fa-tumblr-square",
    "fas fa-tv",
    "fab fa-twitch",
    "fab fa-twitter",
    "fab fa-twitter-square",
    "fab fa-typo3",
    "fab fa-uber",
    "fab fa-ubuntu",
    "fab fa-uikit",
    "fab fa-umbraco",
    "fas fa-umbrella",
    "fas fa-umbrella-beach",
    "fas fa-underline",
    "fas fa-undo",
    "fas fa-undo-alt",
    "fab fa-uniregistry",
    "fab fa-unity",
    "fas fa-universal-access",
    "fas fa-university",
    "fas fa-unlink",
    "fas fa-unlock",
    "fas fa-unlock-alt",
    "fab fa-untappd",
    "fas fa-upload",
    "fab fa-ups",
    "fab fa-usb",
    "fas fa-user",
    "far fa-user",
    "fas fa-user-alt",
    "fas fa-user-alt-slash",
    "fas fa-user-astronaut",
    "fas fa-user-check",
    "fas fa-user-circle",
    "far fa-user-circle",
    "fas fa-user-clock",
    "fas fa-user-cog",
    "fas fa-user-edit",
    "fas fa-user-friends",
    "fas fa-user-graduate",
    "fas fa-user-injured",
    "fas fa-user-lock",
    "fas fa-user-md",
    "fas fa-user-minus",
    "fas fa-user-ninja",
    "fas fa-user-nurse",
    "fas fa-user-plus",
    "fas fa-user-secret",
    "fas fa-user-shield",
    "fas fa-user-slash",
    "fas fa-user-tag",
    "fas fa-user-tie",
    "fas fa-user-times",
    "fas fa-users",
    "fas fa-users-cog",
    "fab fa-usps",
    "fab fa-ussunnah",
    "fas fa-utensil-spoon",
    "fas fa-utensils",
    "fab fa-vaadin",
    "fas fa-vector-square",
    "fas fa-venus",
    "fas fa-venus-double",
    "fas fa-venus-mars",
    "fab fa-viacoin",
    "fab fa-viadeo",
    "fab fa-viadeo-square",
    "fas fa-vial",
    "fas fa-vials",
    "fab fa-viber",
    "fas fa-video",
    "fas fa-video-slash",
    "fas fa-vihara",
    "fab fa-vimeo",
    "fab fa-vimeo-square",
    "fab fa-vimeo-v",
    "fab fa-vine",
    "fab fa-vk",
    "fab fa-vnv",
    "fas fa-voicemail",
    "fas fa-volleyball-ball",
    "fas fa-volume-down",
    "fas fa-volume-mute",
    "fas fa-volume-off",
    "fas fa-volume-up",
    "fas fa-vote-yea",
    "fas fa-vr-cardboard",
    "fab fa-vuejs",
    "fas fa-walking",
    "fas fa-wallet",
    "fas fa-warehouse",
    "fas fa-water",
    "fas fa-wave-square",
    "fab fa-waze",
    "fab fa-weebly",
    "fab fa-weibo",
    "fas fa-weight",
    "fas fa-weight-hanging",
    "fab fa-weixin",
    "fab fa-whatsapp",
    "fab fa-whatsapp-square",
    "fas fa-wheelchair",
    "fab fa-whmcs",
    "fas fa-wifi",
    "fab fa-wikipedia-w",
    "fas fa-wind",
    "fas fa-window-close",
    "far fa-window-close",
    "fas fa-window-maximize",
    "far fa-window-maximize",
    "fas fa-window-minimize",
    "far fa-window-minimize",
    "fas fa-window-restore",
    "far fa-window-restore",
    "fab fa-windows",
    "fas fa-wine-bottle",
    "fas fa-wine-glass",
    "fas fa-wine-glass-alt",
    "fab fa-wix",
    "fab fa-wizards-of-the-coast",
    "fab fa-wolf-pack-battalion",
    "fas fa-won-sign",
    "fab fa-wordpress",
    "fab fa-wordpress-simple",
    "fab fa-wpbeginner",
    "fab fa-wpexplorer",
    "fab fa-wpforms",
    "fab fa-wpressr",
    "fas fa-wrench",
    "fas fa-x-ray",
    "fab fa-xbox",
    "fab fa-xing",
    "fab fa-xing-square",
    "fab fa-y-combinator",
    "fab fa-yahoo",
    "fab fa-yammer",
    "fab fa-yandex",
    "fab fa-yandex-international",
    "fab fa-yarn",
    "fab fa-yelp",
    "fas fa-yen-sign",
    "fas fa-yin-yang",
    "fab fa-yoast",
    "fab fa-youtube",
    "fab fa-youtube-square",
    "fab fa-zhihu"
]
