<template>
    <div class="container">
        <page-title :page-title="translate('my-apps', 'marketplace')">
            <template v-slot:actions>
                <router-link to="/app-store" class="btn btn-primary rounded-pill">{{ translate('Go to marketplace', 'marketplace') }}</router-link>
            </template>
        </page-title>
        <div class="app-store-main-content mt-4">
            <div class="store-search-field w-100 my-3">
                <span class="p-3"><i class="fas fa-search mr-3"></i></span>
                <input class="form-control w-100 pl-5" :placeholder="translate('Search', 'marketplace')">
            </div>

            <div class="row">
                    <div class="col-md-4">
                        <div class="app-holder d-flex flex-column zoom-in-hover bg-white">
                            <div class="img-holder">
                                <img src="/uploads/defaults/image-placeholder.jpg">
                            </div>
                            <div class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
                                <div class="d-flex flex-column">
                                    <span class="app-title">{{ translate('Visual page builder', 'marketplace') }}</span>
                                    <span class="app-status">{{ translate('Updated 4hr ago', 'marketplace') }}</span>
                                </div>
                                <span><i class="fas fa-ellipsis-v"></i></span>
                            </div>
                            <div class="w-100 app-actions">
                                <div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
                                    <div class="action-buttons">
                                        <span class="mr-2" v-tooltip="translate('Application settings', 'marketplace')"><i class="fas fa-cog"></i></span>
                                    </div>
                                    <button class="btn app-de-activate rounded-pill px-4 py-2">{{ translate('De-activate', 'marketplace') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="app-holder d-flex flex-column">
                            <div class="img-holder">
                                <img src="/uploads/defaults/image-placeholder.jpg">
                            </div>
                            <div class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
                                <div class="d-flex flex-column">
                                    <span class="app-title">{{ translate('Visual page builder', 'marketplace') }}</span>
                                    <span class="app-status">{{ translate('Updated 4hr ago', 'marketplace') }}</span>
                                </div>
                                <span><i class="fas fa-ellipsis-v"></i></span>
                            </div>
                            <div class="w-100 app-actions">
                                <div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
                                    <div class="action-buttons">
                                        <span class="mr-2" v-tooltip="translate('Application settings', 'marketplace')"><i class="fas fa-cog"></i></span>
                                        <span class="trash-icon" v-tooltip="translate('Delete application', 'marketplace')"><i class="fas fa-trash-alt"></i></span>
                                    </div>
                                    <button class="btn app-activate rounded-pill px-4 py-2">{{ translate('Activate', 'marketplace') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="app-holder d-flex flex-column">
                            <div class="img-holder">
                                <img src="/uploads/defaults/image-placeholder.jpg">
                            </div>
                            <div class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
                                <div class="d-flex flex-column">
                                    <span class="app-title">{{ translate('Visual page builder', 'marketplace') }}</span>
                                    <span class="app-status">{{ translate('Updated 4hr ago', 'marketplace') }}</span>
                                </div>
                                <span><i class="fas fa-ellipsis-v"></i></span>
                            </div>
                            <div class="w-100 app-actions">
                                <div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
                                    <div class="action-buttons">
                                        <span class="mr-2" v-tooltip="translate('Application settings', 'marketplace')"><i class="fas fa-cog"></i></span>
                                        <span class="trash-icon" v-tooltip="translate('Delete application', 'marketplace')"><i class="fas fa-trash-alt"></i></span>
                                    </div>
                                    <button class="btn app-activate rounded-pill px-4 py-2">{{ translate('Activate', 'marketplace') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyApps"
    }
</script>

<style scoped lang="scss">
    .app-store-main-content {
        /*span {*/
        /*    cursor: pointer;*/
        /*}*/

        .store-search-field {
            span {
                position: absolute;
                cursor: pointer;

                .fa-search {
                    color: #DEDEDE;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            input {
                border: 1px solid #DEDEDE;
                border-radius: 10px;
                font-size: 18px;
                color: $body-color;
                background-color: #fff;

                &:focus {
                    outline: none;
                }
            }
        }

        .app-holder {
            border: 1px solid #DEDEDE;
            border-radius: 10px;
            margin-bottom: 1rem;

            .img-holder {
                img {
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .info-holder {
                .app-title {
                    font-size: 18px;
                    font-weight: bold;
                }

                .app-status {
                    color: #DEDEDE;
                }

                i {
                    color: #DEDEDE;
                }
            }

            .app-actions {
                .action-buttons {
                    span.trash-icon {
                        background-color: $primary;
                        border: 1px solid $primary;
                        cursor: pointer;
                    }

                    span {
                        border: 1px solid #DEDEDE;
                        border-radius: 10px;
                        padding: 14px 12px 8px 12px;
                        cursor: pointer;

                        i {
                            font-size: 22px;
                            color: #DEDEDE;
                        }
                    }
                }

                .app-activate {
                    color: #fff;
                    background-color: $primary;
                    border: 1px solid $primary;
                    border-radius: 10px;
                    &:hover {
                        color: $primary;
                        background-color: #fff;
                        border-color: $primary;
                    }
                }

                .app-de-activate {
                    border: 1px solid #DEDEDE;
                    border-radius: 10px;
                    &:hover {
                        color: $primary;
                        background-color: #fff;
                        border-color: $primary;
                    }
                }
            }
        }
    }
</style>
