<template>
    <router-view/>
</template>

<script>
    export default {
        name: "DefaultBlank",
    }
</script>

<style lang="scss">
</style>
