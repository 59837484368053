<template>
  <div v-if="Object.keys(attributeSetGroups).length">
    <!-- fields without tabs on top -->
    <!-- Changed to div because v-show="show[visibilityKey('__default__')]" cannot be put on template -->
    <!--  <template v-if="attributeSetGroups['__default__']" v-show="show[visibilityKey('__default__')]"> -->
    <div v-if="attributeSetGroups['__default__']" v-show="show[visibilityKey('__default__')]">
      <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups['__default__']">
        <entity-form-group
          v-show="show[visibilityKey('__default__', attrSetGroupI)]"
          :attr-set-group-i="attrSetGroupI"
          :attr-set-group="attrSetGroup"
          :visible="visible[visibilityKey('__default__', attrSetGroupI)]"
          @toggleVisibility="visible[visibilityKey('__default__', attrSetGroupI)] = !visible[visibilityKey('__default__', attrSetGroupI)]"
        >
          <entity-form-row
            :attr-set-group="attrSetGroup"
            :data-filters="dataFilters"
            :options="model"
            @setValue="setValue"
            @createOptions="$emit('createOptions', $event)"
          />
        </entity-form-group>
      </template>
    </div>

    <!-- The rest of the tabs -->
    <tabs v-if="Object.keys(objectWithoutProp(attributeSetGroups, '__default__')).length">
      <template v-for="(tab, tabI, tabIndex) in objectWithoutProp(attributeSetGroups, '__default__')">
        <tab :name="translate(tabI, 'entities')" :selected="tabIndex === 0" :v_hide="!show[visibilityKey(tabI)]">
          <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups[tabI]">
            <entity-form-group
              v-show="show[visibilityKey(tabI, attrSetGroupI)]"
              :attr-set-group-i="attrSetGroupI"
              :attr-set-group="attrSetGroup"
              :visible="visible[visibilityKey(tabI, attrSetGroupI)]"
              @toggleVisibility="visible[visibilityKey(tabI, attrSetGroupI)] = !visible[visibilityKey(tabI, attrSetGroupI)]"
            >
              <entity-form-row
                :attr-set-group="attrSetGroup"
                :data-filters="dataFilters"
                :options="model"
                @setValue="setValue"
                @createOptions="$emit('createOptions', $event)"
              />
            </entity-form-group>
          </template>
        </tab>
      </template>
    </tabs>
  </div>
</template>

<script>
import EntityFormRow from "@/modules/erp_entities/components/page/form/EntityFormRow.vue"
import EntityFormGroup from "@/modules/erp_entities/components/page/form/EntityFormGroup.vue"
import { groupEntityForm } from "@/modules/erp_entities/utilities/helper"
import { isVoidValue, objectWithoutProp } from "@/utilities/helper"

export default {
  name: 'EntityFormContent',
  components: {
    EntityFormRow, EntityFormGroup,
  },
  props: {
    value: {
      required: true,
    },
    columns: {
      required: true,
    },
    entity: {
      required: false,
    },
    modalData: {
      required: false,
    },
  },
  data() {
    return {
      form: [],
      attributeSetGroups: {},
      show: {},
      visible: {},
      dataFilters: {},
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  created() {
    this.loadForm()
  },
  methods: {
    objectWithoutProp,
    visibilityKey(...params) { // todo refactor #1048715466
      return params.join('_')
    },
    configColumns() {
      this.form = []
      let fieldsToWatch = []

      for (const prop of this.columns) {
        let value = null
        if(!isVoidValue(prop.default_value)) {
          value = prop.default_value
        }

        if (this.modalData) {
          value = this.modalData[prop.name]
        }

        if(this.entity && this.entity.data){

          // first check in options (if any)
          let recordProp = this.entity.data.options ? this.entity.data.options.filter(rec => rec.key == prop.name) : []
          if (recordProp.length) {
            let propIndex = this.entity.data.options.indexOf(recordProp[0])

            if(!isVoidValue(this.entity.data.options[propIndex].value)){
              value = this.entity.data.options[propIndex].value
            }
          }
          // else check in root
          else if(typeof this.entity.data[prop.name] !== 'undefined'){
            value = this.entity.data[prop.name]
          }
        }

        if (isVoidValue(this.model[prop.name])) {
          this.$set(this.model, prop.name, value)
        }

        if(prop.data_filters && prop.data_filters.length){
          // we want only unique names
          fieldsToWatch = [...new Set([...fieldsToWatch, ...prop.data_filters])]
        }

        //this.form.push({ ...prop, ...{ value: value } })
        this.form.push({ ...prop })
      }

      this.watchFields(fieldsToWatch)
    },

    watchFields(fieldsToWatch){
      fieldsToWatch.forEach(el => {

        this.$set(this.dataFilters, el, {
          name: el,
          label: this.form[this.getIndexByFieldName(this.form, el)].label,
          value: this.model[el],
          group: el,
        })

        this.$watch(
          `value.${el}`, val => {
            this.$set(this.dataFilters[el], 'value', val)
          },
        )
      })
    },

    watchConditions() {
      const conditions = this.form.flatMap(field => {
        const conditions = field?.conditions || []
        return conditions.map(condition => ({ ...condition, source_field: field.name }))
      })

      conditions.forEach(condition => {

        // watch only valid tabs/groups/fields
        const destinationTab = condition.destination_tab ? this.attributeSetGroups[condition.destination_tab] : false
        const destinationGroup = (condition.destination_tab && condition.destination_group) ? this.attributeSetGroups[condition.destination_tab][condition.destination_group] : false
        const destinationField = condition.destination ? this.form[this.getIndexByFieldName(this.form, condition.destination)] : false

        if (destinationTab || destinationGroup || destinationField) {
          this.$watch(`value.${condition.source_field}`, () => {
            const conditionsSatisfied = this.areAllConditionsSatisfied(this.model[condition.source_field], condition.conditions)
            this.applyBehaviour(condition, conditionsSatisfied)
          }, { immediate: true })
        }
      })
    },

    getIndexByFieldName(fields, field_name){
      const attr = fields.filter(field => field.name == field_name)
      if(attr.length){
        return fields.indexOf(attr[0])
      }
      return false
    },

    areAllConditionsSatisfied(sourceFieldValue, conditions = []) {
      return conditions.every(condition => {
        switch (condition.key) {
          case 'lower':
            return sourceFieldValue < condition.value
          case 'greater':
            return sourceFieldValue > condition.value
          case 'equals':
            return sourceFieldValue == condition.value
          case 'not_equals':
            return !(sourceFieldValue == condition.value)
          case 'is_empty':
            return isVoidValue(sourceFieldValue)
          case 'is_not_empty':
            return !isVoidValue(sourceFieldValue)
          case 'one_of':
            return sourceFieldValue !== null && condition.value.split(/\s*,\s*/)
              .map(String)
              .includes(sourceFieldValue.toString())
          case 'not_one_of':
            return sourceFieldValue === null || !condition.value.split(',')
              .map(String)
              .includes(sourceFieldValue.toString())
          default:
            return true // Default case, consider it a match
        }
      })
    },

    applyBehaviour(condition, conditionsSatisfied) {
      const destinationField = condition.destination ? this.form[this.getIndexByFieldName(this.form, condition.destination)] : false

      for (const behaviour of condition.destination_behaviour) {
        if (behaviour === 'hide') {
          // field
          if (condition.destination) {
            this.$set(destinationField, 'v_hide', conditionsSatisfied)
            // tab/accordion
          } else if (condition.destination_tab) {
            const visibilityKey = condition.destination_group ? this.visibilityKey(condition.destination_tab, condition.destination_group) : this.visibilityKey(condition.destination_tab)
            this.$set(this.show, visibilityKey, !conditionsSatisfied)
          }
        } else if (behaviour === 'show') {
          // field
          if (condition.destination) {
            this.$set(destinationField, 'v_hide', !conditionsSatisfied)
            // tab/accordion
          } else if (condition.destination_tab) {
            const visibilityKey = condition.destination_group ? this.visibilityKey(condition.destination_tab, condition.destination_group) : this.visibilityKey(condition.destination_tab)
            this.$set(this.show, visibilityKey, conditionsSatisfied)
          }

        }
      }
    },

    // Used for conditional tabs & accordions
    setInitialShow() {
      for (let tabKey in this.attributeSetGroups) {
        this.$set(this.show, this.visibilityKey(tabKey), true)
        for (let groupKey in this.attributeSetGroups[tabKey]) {
          this.$set(this.show, this.visibilityKey(tabKey, groupKey), true)
        }
      }
    },

    // used for accordions content
    setInitialVisibility(){
      for (let tabKey in this.attributeSetGroups) {
        for (let groupKey in this.attributeSetGroups[tabKey]) {
          this.$set(this.visible, this.visibilityKey(tabKey, groupKey), true)
        }
      }
    },

    getVisibleFieldNames() {
      const visibleFields = []

      Object.keys(this.attributeSetGroups).forEach(tabKey => {
        if (this.show[this.visibilityKey(tabKey)]) {
          Object.keys(this.attributeSetGroups[tabKey]).forEach(groupKey => {
            if (this.show[this.visibilityKey(tabKey, groupKey)]) {
              this.attributeSetGroups[tabKey][groupKey]
                .filter(field => field && !field.v_hide)
                .forEach(field => visibleFields.push(field.name))
            }
          })
        }
      })

      return visibleFields
    },

    // copied from productsCreate, not needed things commented for now
    setValue(fieldProps, v){
      this.model[fieldProps.name] = v
    },

    loadForm() {
      this.configColumns()

      this.attributeSetGroups = groupEntityForm(this.form)

      this.setInitialShow()

      this.watchConditions()

      this.setInitialVisibility()
    },
  },
}
</script>

<style scoped lang="scss">

</style>