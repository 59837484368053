<template>
  <div class="cms-block" :class="'cms-block-'+blockName" :data-component="blockName">
    <template v-if="editor.enabled">
      <div v-if="resData" v-html="resData.output"/>
      <vs-skeleton v-else primary-color="#ebebeb" secondary-color="#f2f2f2" class="cms-placeholder" data-placeholder="Slider">
        <vs-line height="250" class="mt-1" b-radius='0'></vs-line>
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from '../../../../mixins/DynamicComponentMixin';

export default {
  name: 'SliderElement',
  mixins: [DynamicComponentMixin],
  inject: ['editor'],
  craft: {
    defaultProps: {
      settings: {
        id: null
      },
    }
  },
  data() {
    return {
      blockName: 'slider',
      renderUrl: 'sliders'
    }
  }
}
</script>