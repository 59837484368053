<template>
  <div class="form-group input-field">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <input
        :type="type"
        :value="value"
        class="form-control"
        @input="e => this.$emit('input', e.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "CraftIdOrClassInput",
  inject: ['editor'],
  props:{
    label:{
      type: String,
      required: true,
    },
    value:{},
    type:{
      type: String,
      default: 'text'
    },
    backgroundColor:{
      required: false,
      default: '#F5F8FB'
    }
  }
}
</script>

<style scoped>
.input-field input{
  border: none !important;
}
.input-field input:focus{
  outline: none !important;
  box-shadow: none;
}
</style>
