<template>
  <div class="container entity-container">
    <page-title v-if="pageTitle" :page-title="pageTitle" :buttons="isModal ? false : `/entity/${entityRoute}`">
      <template v-slot:actions>
        <button v-if="isModal" class="btn btn-white rounded-pill" @click="$emit('cancel')">
          {{ translate('Cancel', 'global') }}
        </button>
        <button v-if="canSave" class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
        <button v-else class="btn btn-primary rounded-pill" @click="print">
          {{ translate('Print', 'global') }}
        </button>
      </template>
    </page-title>

    <div v-if="entityIndex && (!isEditMode || (isEditMode && entity))">

      <entity-form-content
        ref="entityForm"
        v-model="options"
        :entity="entity?.data || null"
        :columns="entityIndex?.columns || []"
        :modal-data="modalData"
        @createOptions="v => createOptions = v"
      />

      <div class="d-flex justify-content-end d-print-none">
        <router-link v-if="!isModal" :to="`/entity/${entityRoute}`" class="btn btn-white rounded-pill">
          {{ translate('Go back', 'global') }}
        </router-link>

        <button v-if="canSave" class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
        <button v-else class="btn btn-primary rounded-pill" @click="print">
          {{ translate('Print', 'global') }}
        </button>
      </div>
    </div>

    <modal name="create_new_options"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="auto"
           width="500px"
           transition="slide-top"
           overlayTransition="wait"
           classes="remove-modal-height">
      <create-new-option :value="createOptions" />
    </modal>

    <modal
      name="create_new_entity"
      :pivot-x="0.5"
      :pivot-y="0.5"
      height="90%"
      width="80%"
      transition="slide-top"
      overlayTransition="wait"
      classes="remove-modal-height"
    >
      <create-new-option
        :value="createOptions"
        @save="updateFieldValue(createOptions)"
      />
    </modal>
  </div>
<!--    <div>-->
<!--        <div class="modal-content">-->
<!--            <div class="modal-header">-->
<!--                <h5 class="modal-title" v-if="value.mode != 'edit'">-->
<!--                    {{ translate('Create', 'global') }} {{$route.params.entityType}}-->
<!--                </h5>-->
<!--                <h5 class="modal-title" v-else>-->
<!--                    {{ translate('Update', 'global') }}-->
<!--                </h5>-->
<!--                <button type="button" class="close" @click="$modal.hide('add_new_' + entity)">-->
<!--                    <span aria-hidden="true">×</span>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="modal-footer py-2">-->
<!--                <button type="button" :disabled="loading" class="btn btn-white rounded-pill border"-->
<!--                        @click="$modal.hide('add_new_' + entity)">-->
<!--                    {{ translate('Go back', 'global') }}-->
<!--                </button>-->
<!--                <button type="submit" :disabled="loading" @click="save"-->
<!--                        class="btn btn btn-primary rounded-pill">-->
<!--                    {{ translate('Save changes', 'global') }}-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="modal-body overflow-auto pt-0" v-if="form">-->
<!--                <form id="trigger-logo" @submit.prevent="save">-->
<!--                    <input type="hidden" name="id">-->
<!--                    form render -->
<!--                </form>-->
<!--            </div>-->
<!--            <v-infinite-progressbar v-if="loading"></v-infinite-progressbar>-->
<!--        </div>-->

<!--    </div>-->
</template>

<script>
import {
  isVoidValue,
  setPageTitle,
  getEntityName, can, generateSlug
} from "@/utilities/helper"
import {environment, checkIfFormIsValidNoTabs} from '../../../utilities/helper'
const pluralize = require('pluralize')

import bus from './../../../utilities/eventBus'
import CreateNewOption from "@/modules/erp_entities/components/entities/CreateNewOption.vue"
import { validate } from "@/modules/erp_entities/utilities/validation/helper"
import EntityFormContent from "@/modules/erp_entities/components/page/form/EntityFormContent.vue"

export default {
  name: "EntityForm",
  components: {
    EntityFormContent,
    CreateNewOption,
  },
  props: {
    entityRoute: {
      required: true,
      type: String,
    },
    isModal: {
      required: false,
      type: Boolean,
      default: false,
    },
    modalData: {
      required: false,
    },
  },
  data() {
    return {
      options: {},
      loading: false,
      entityIndex: null,
      entity: null,
      primary_fields: false,
      createOptions: null,
    }
  },
  async created() {
    await this.loadEntity()
    // used to indicate that form is loaded in the entity create modal
    if (this.isModal) {
      this.$emit('loading', false)
    }
  },

  computed: {
    entitySlug() {
      if (this.entityIndex && this.entityIndex.entity_slug) {
        return this.entityIndex.entity_slug
      }

      return pluralize.singular(this.entityRoute)
    },
    // todo refactor #109378176
    entityName() {
      const entityName = getEntityName(this.entitySlug)
      if (entityName) {
        return entityName
      }

      return this.entitySlug.toPascalCase().split(/(?=[A-Z])/).join(" ")
    },
    entityNameTranslated(){
      return this.translate(this.entityName, 'entities')
    },
    entityNamePluralTranslated(){
      return this.translate(pluralize(this.entityName), 'entities')
    },
    // END todo refactor #109378176
    pageTitle() {
      if (!this.entityNameTranslated) {
        return null
      }

      return this.translate(`${this.currentAction} {entity}`, 'entities', { entity: this.titleContent })
    },

    isEditMode() {
      if (this.isModal) {
        return this.modalData?.id
      } else {
        return this.$route.params.id
      }
    },

    currentAction() {
      return this.isEditMode
        ? (can('update', this.entitySlug) ? 'Edit' : 'Show')
        : (can('create', this.entitySlug) ? 'Create' : 'Show')
    },

    titleContent() {
      return this.primaryFieldsComputed || this.entityNameTranslated
    },

    canSave() {
      if (this.$route.params.id && !this.isModal) {
        return can('update', this.entitySlug)
      } else {
        return can('create', this.entitySlug)
      }
    },
    primaryFieldsComputed() {
      if (!this.isEditMode || !this.primary_fields) {
        return false
      }

      const primaryFieldValues = this.primary_fields?.reduce((acc, field) => {
        if (!isVoidValue(this.options[field])) {
          acc.push(this.options[field])
        }

        return acc
      }, []) || []

      return primaryFieldValues.length ? primaryFieldValues.join(", ") : false
    },
  },

  methods: {
    async loadEntity() {
      this.$store.state.system.isLoading = true

      this.entityIndex = await this.erp.ext.request.get(`modules/${this.entityRoute}`)

      // similar to createSettingsForm(res)
      this.entity = null
      if (this.isEditMode) {
        const id = this.isModal ? this.modalData.id : this.$route.params.id
        this.entity = await this.erp.ext.request.axiosInstance.get(`modules/${this.entityRoute}/${id}`)
        if (this.entity?.data?.primary_fields) {
          this.primary_fields = this.entity.data.primary_fields
        }
      }

      setPageTitle(`${this.entityRoute}`)
      bus.$on('bulk', this.save)

      this.$store.state.system.isLoading = false
    },
    validate() {
      const visibleFieldNames = this.$refs['entityForm'].getVisibleFieldNames()
      for (const field of this.$refs['entityForm'].form) {
        if (visibleFieldNames.includes(field.name)) { // validate only the visible fields
          this.$set(field, 'errors', validate(this.options[field.name], field.rules || []))
        }
      }
    },
    async updateFieldValue(field) {
      bus.$emit(`record_created_${field.name}_${field.id}`)
    },
    save() {
      if (this.$refs['entityForm'].form.some(field => field.name === 'slug' && field.type === 'hidden') && isVoidValue(this.options.slug)) {
        this.options.slug = generateSlug()
      }

      this.validate()

      //let formIsValid = checkIfFormIsValid(this.attributeSetGroups) replaced because we now have tabs + accordions
      let formIsValid = checkIfFormIsValidNoTabs(this.$refs['entityForm'].form)

      if(!formIsValid){
        return this.$toast.error('Fix errors in form')
      }

      // todo fonts
      // if (!this.areAllFontsProvided()) {
      //   return this.$toast.error('Please provide fonts for selected variants')
      // }

      // Datatable Editable Fields, Checkbox field
      let datatableEditableFields = []
      this.$refs['entityForm'].form.forEach(field => {
        if(field.type === 'datatable_editable') {
          datatableEditableFields.push({
            module: field.data_module.toKebabCase() + 's',
            value: this.options[field.name],
          })
          this.options[field.name] = null // don't keep datatable_editable value in the main entity
        }
        // Default value for checkbox if its not clicked once
        if (field.type === 'checkbox' && !this.options[field.name]) {
          this.options[field.name] = 0
        }
      })
      // END Datatable Editable Fields, Checkbox field

      // send only the visible options
      const visibleOptions = this.$refs['entityForm'].getVisibleFieldNames().reduce((acc, fieldName) => {
        if (this.options.hasOwnProperty(fieldName)) {
          acc[fieldName] = this.options[fieldName]
        }

        return acc
      }, {})


      let method = (this.$route.params.id && !this.isModal) || this.modalData?.id ? 'put' : 'post'

      let target = this.entityRoute
      if (this.isModal && this.modalData?.id) {
        target += `/${this.modalData.id}`
      } else if (!this.isModal && this.$route.params.id) {
        target += `/${this.$route.params.id}`
      }

      environment.request[method](`modules/${target}`, { options: visibleOptions, entity_slug: this.entitySlug }).then(response => {

        // update related entities if any (datatable editable fields)
        this.updateRelations(datatableEditableFields, response.data.data.id).then(response => {
          this.$toast.requestSuccess(method, this.entityNameTranslated)

          if (this.isModal) {
            this.$emit('save')
          } else {
            return this.$router.push(`/entity/${this.entityRoute}`)
          }
        })

      }).catch(error => {
        this.$toast.error(error.message)
        this.loading = false
      })
    },

    print() {
      print()
    },

    // todo move to EntityMixin
    async updateRelations(datatableEditableFields, main_entity_id) {
      // disabled on create
      // const method = this.$route.params.id && !this.isModal ? 'put' : 'post'

      if(datatableEditableFields.length){
        datatableEditableFields.forEach(async field => {
          if(field.value){
            field.value.dataToCreate.forEach(async options => { // Create
              options[field.value.relationField] = main_entity_id
              await environment.request['post'](`modules/${field.module}`, { options: options })
            })
            field.value.dataToUpdate.forEach(async options => { // Update
              options[field.value.relationField] = main_entity_id
              await environment.request['put'](`modules/${field.module}/${options.id}`, { options: options })
            })
            field.value.dataToDelete.forEach(async id => { // Delete
              await environment.request['delete'](`modules/${field.module}/${id}`)
            })
          }
        })
      }
    },
    // END todo move to EntityMixin

    areAllFontsProvided() {
      const files = this.options['files'] ? JSON.parse(this.options['files']) : null

      return files && this.options['variants'].every(variant => !isVoidValue(files[variant]))
    },
  },
  watch: {
    'entityRoute': function(){
      this.loadEntity().then(() => this.$refs['entityForm'].loadForm())
    },
  },
}
</script>
