<template>
  <nav :class="containerClass" class="pagination-fluid">
    <div class="page-item">
      <button class="btn btn-pagination -left" :disabled="disabled || value.current_page == 1" @click="goToPage(value.current_page - 1)">
        <i class="fa fa-arrow-left" aria-hidden="true" />{{ translate('Prev', 'global') }}
      </button>
    </div>
    <ul class="pagination">
      <template v-for="pageNumber in totalPages">
        <li v-if="Math.abs(pageNumber - value.current_page) < 3 || pageNumber == totalPages - 1 || pageNumber == 1" class="page-item" :class="{'active': pageNumber == value.current_page}">
          <button class="page-link" :disabled="disabled || pageNumber == value.current_page" @click="goToPage(pageNumber)">
            {{ pageNumber }}
          </button>
        </li>
      </template>
    </ul>
    <div class="page-item">
      <button class="btn btn-pagination -right" :disabled="disabled || value.current_page == value.last_page" @click="goToPage(value.current_page + 1)">
        {{ translate('Next', 'global') }} <i class="fa fa-arrow-right" aria-hidden="true" />
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    value: {
      type: Object,
    },
    disabled: {
      required: false,
      default: true,
      type: Boolean,
    },
    containerClass: {
      required: false,
      default: 'd-flex',
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.value.total/this.value.per_page)
    },
  },
  methods: {
    goToPage(page) {
      this.$emit('goToPage', page)
    },
  },
}
</script>
