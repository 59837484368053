<template>
  <div>
    <div v-if="quote" class="container-fluid">
      <page-title
          :page-title="quote.quote_request_number"
          :overview="true"
          :buttons="'/request-quotes/'"
      />

      <div class="row">
        <div class="col-md-12">
          <div class="order-price d-flex justify-content-center align-items-center flex-column py-3">
            <span class="order-totals">{{ translate('Totals', 'orders') }}</span>
            <div class="order-single-price d-flex justify-content-between align-items-center">
              <span class="order-amount font-weight-bold mr-3">
                {{ parseFloat(quote.total_amount).toFixed(2) }}
              </span>
            </div>
            <span v-if="quote.tax_amount === '0.00' || quote.tax_amount === null" class="text-muted">
								/{{ translate('No VAT included', 'orders') }}
            </span>
            <span v-else class="text-muted">
              <span>/ {{ translate('incl', 'orders') }} </span>
              <span>{{ parseFloat(quote.tax_amount).toFixed(2) }}</span>
              &nbsp;<span>{{ translate('VAT', 'orders') }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-xl-4 col-lg-12 first-column">
          <div class="col-style">
            <div class="col-style-child d-flex justify-content-between align-items-center p-3">
              <span class="system-text-primary font-weight-bold">
                  {{ translate('Request quote number', 'orders') }}
              </span>
              <span class="order-number">{{ quote.quote_request_number }}</span>
            </div>
          </div>
          <div class="col-style">
            <div class="col-style-child d-flex justify-content-between align-items-center p-3">
              <span class="system-text-primary font-weight-bold">
                  {{ translate('Request quote date', 'orders') }}
              </span>
              <span>{{ formatDate(quote.created_at, null, 'DD MMM YY - HH:mm') }}</span>
            </div>
          </div>
          <div class="col-style">
            <div class="col-style-child d-flex justify-content-between align-items-center p-3">
              <span class="system-text-primary font-weight-bold">
                  {{ translate('Request quote status', 'orders') }}
              </span>
              <span>{{quote.status}}</span>
            </div>
          </div>
          <div class="row download-buttons">
            <div class="col-12">
              <button class="btn btn-white border py-3" @click="download(quote, 'download')">
                {{ translate('Download receipt', 'orders') }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-12 second-column">
          <div class="row">
            <div class="col-md-12">
              <div class="col-styling mb-3 bg-white">
                <span class="_title">{{ translate('Billing address', 'orders') }}</span>
                <div class="user-col px-4">
                  <div class="customer-info w-100 mt-2">
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Name', 'orders') }}</span>
                      <span class="info-value">
                          {{ quote.billing_client_first_name }}
                          {{ quote.billing_client_last_name }}
                      </span>
                    </div>
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Street number', 'orders') }}</span>
                      <span class="info-value">{{ quote.billing_address_address }}</span>
                    </div>
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Zip city', 'orders') }}</span>
                      <span class="info-value">
                          {{ quote.billing_address_postcode }} |
                          {{ quote.billing_address_city }}
                      </span>
                    </div>
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Country', 'orders') }}</span>
                      <span class="info-value">{{ quote.billing_address_country }}</span>
                    </div>
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Email', 'orders') }}</span>
                      <span class="info-value">{{ quote.billing_client_email }}</span>
                    </div>
                    <div class="single-info d-flex justify-content-between py-2">
                      <span>{{ translate('Phone', 'orders') }}</span>
                      <span class="info-value">{{ quote.billing_client_phone }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-md-12 mt-3">
          <div class="main-table border-0">
            <div class="table-responsive rounded">
              <table class="table m-0">
                <thead class="bg-white">
                <tr>
                  <th class="font-weight-bold" scope="col">
                    {{ translate('Item name', 'orders') }}
                  </th>
                  <th class="font-weight-bold" scope="col">
                    {{ translate('Single price', 'orders') }}
                  </th>
                  <th class="font-weight-bold" scope="col">
                    {{ translate('Item qty', 'orders') }}
                  </th>
                  <th class="font-weight-bold" scope="col">
                    {{ translate('VAT rate', 'orders') }}
                  </th>
                  <th class="font-weight-bold" scope="col">
                    {{ translate('VAT amount', 'orders') }}
                  </th>
                  <!-- Discount disabled
                  <th class="font-weight-bold" scope="col">
                      {{ translate('Discount', 'orders') }}
                  </th>
                  -->
                  <th class="font-weight-bold" scope="col">
                    {{ translate('Total', 'orders') }}
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(item, i) in quote.items" :key="i">
                  <td>
                    <span class="font-weight-bold">{{ item.item_name }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(item.single_price_without_vat).toFixed(2) }}
                    </span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ item.qty }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                        {{item.tax_percentage}}
                    </span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(item.tax_amount).toFixed(2) }}
                    </span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(item.total_amount).toFixed(2) }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-5 offset-xl-7 mt-3">
          <div class="main-table border-0">
            <div class="table-responsive rounded">
              <table class="table m-0 table-order-totals">
                <tbody>
                <tr>
                  <td>
                    <span class="font-weight-bold">{{ translate('Subtotal excl VAT', 'orders') }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(quote.subtotal_amount_without_vat).toFixed(2) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="parseFloat(quote.discount_amount_without_vat) > 0">
                  <td>
                    <span class="font-weight-bold">{{ translate('Discount Amount excl VAT', 'orders') }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(quote.discount_amount_without_vat).toFixed(2) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="font-weight-bold">{{ translate('Total VAT', 'orders') }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(quote.tax_amount).toFixed(2) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="font-weight-bold">{{ translate('Total', 'orders') }}</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ parseFloat(quote.total_amount).toFixed(2) }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Timeline from "../../components/Timeline";
import Comments from "../../components/Comments";
import {downloadFile} from "@/utilities/helper"

export default {
  name: "ShowOrder",
  components: {
    Timeline, Comments
  },
  data() {
    return {
      quote: null,
      statuses: null,
    }
  },
  methods: {
    async getQuote() {
      let quote = await this.erp.ext.request.axiosInstance.get(
          `/modules/sales/quote-requests/${this.$route.params.id}`
      )
      this.quote = quote.data.data
    },
    // async getStatuses() {
    //   // changed on 21.06 todo remove this comment
    //   // let statuses = await this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/states/${this.order.state}`)
    //   let statuses = await this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/statuses/${this.order.id}`)
    //   this.statuses = statuses.data
    // },
    download(quote, route) {
      this.erp.ext.request.axiosInstance.get(`/modules/sales/quote-requests/${route}/${quote.id}`, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      }).then(res => {
        const filename = `${route.replace('download', '').replace('-', '')}${quote.quote_request_number}.pdf`
        downloadFile(res.data, filename)
      })
    },
  },
  async created() {
    await this.getQuote()
    // await this.getStatuses()
  }
}
</script>

<style scoped lang="scss">

.main-table {
  .table-responsive {

    table {
      thead tr {
        border: none;
      }
      thead tr th {
        border-top: none;
        border-bottom: 1px solid $border-color;
      }

      tbody {
        tr {
          border: none;
        }
        tr td {
          border-bottom: 1px solid $border-color;
        }

        tr:last-of-type td {
          border-bottom: none;
        }
      }

      &.table-order-totals {
        tbody {
          tr {
            td:first-child {
              border-right: 1px solid $border-color;
            }
            td:last-child {
              text-align: right;
            }
          }
        }
      }
    }


  }
}


.col-styling {
  border-radius: 5px;

  ._title {
    border-bottom: 1px solid $border-color;
    display: flex;
    padding: .5rem 1.5rem;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  .user-col {
    .customer-info {
      .single-info {
        border-bottom: 1px solid $border-color;

        span:first-child {
          font-weight: 600;
        }

        .info-value {
          color: #000;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .btn-save-textarea {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.order-price {
  background-color: #000;
  border-radius: 5px;

  .order-totals {
    color: #fff;
    text-transform: uppercase;
  }

  .order-single-price {
    .order-amount {
      font-size: 46px;
      color: #fff;
    }
  }
}

.col-style {
  flex-grow: 1;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .col-style-child {
    background-color: white;
    border-radius: 5px;
  }

  .invoice-number {
    color: #ff004f;
  }
}

.download-buttons {
  button {
    margin-bottom: 1rem;
    width: 100%;
    border: 1px solid #000;
  }
}

.status-badge {
  width: 100px;
}

@media all and (min-width: 1200px) and (max-width: 1500px) {
  .first-column,
  .second-column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .third-column {
    flex: 0 0 100%;
    max-width: 100%;

    .timeline,
    .comments {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
