<template>
  <div>

    <!-- Filters -->
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Choose categories', 'page-builder') }}</label>
          <treeselect-field
            v-model="categories"
            :field-props="{ data_type: 'treeselectCategory', data_module: 'cms-templates' }"
            @input="filter"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Search by name', 'page-builder') }}</label>
          <input-field
            v-model="search"
            :field-props="{ type: 'text' }"
            @input="filter"
          />
        </div>
      </div>

      <div v-if="isAdmin" class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Show all user templates', 'page-builder') }}</label>
          <checkbox-field
            :field-props="{ name: 'show_all_user_templates'}"
            v-model="showAllUserTemplates"
            @input="filter"
          />
        </div>
      </div>
    </div>

    <!-- Data -->
    <div class="border-top py-4">

      <loader v-if="loading"/>

      <template v-else>
        <div v-if="templates.objectData.length" class="row">
          <div v-for="template in templates.objectData" :key="template.id" class="col-6">
            <div class="card p-2 border bg-light cursor-pointer card-cms-template" @click="importTemplate(template)">
              <p class="mb-2"><strong>{{ template.name }}</strong></p>
              <small v-if="template.description" class="mb-2">{{ template.description }}</small>
              <img v-if="template.preview_image" :src="template.preview_image">
            </div>
          </div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center font-weight-700">
          {{ translate('No data', 'global') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { InputField, TreeselectField } from "@/modules/erp_entities/components/page/form/fields"
import debounce from "debounce"
import Node from "@/modules/pagebuilder/craft/core/Node"
import Loader from "@/modules/system/components/utilities/Loader.vue"
import { mapGetters } from "vuex"
import CheckboxField from "@/modules/erp_entities/components/page/form/fields/CheckboxField.vue"

export default {
  name: "ImportTemplateSettings",
  components: {
    CheckboxField,
    InputField,
    TreeselectField,
    Loader,
  },
  inject: ['editor'],
  data() {
    return {
      filters: {},
      search: '',
      showAllUserTemplates: false,
      categories: [],
      templates: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters('system', ['isAdmin']),
    selectedComponentName() {
      return this.editor.selectedNode && this.editor.selectedNode.componentName === 'Canvas'
        ? this.editor.selectedNode.props.component
        : this.editor.selectedNode.componentName
    },
    selectedComponentId() {
      return this.$store.state.builder.blocks.objectData.find(item => item.component === this.selectedComponentName).id
    },
  },
  created() {
    this.filters['user_id'] = { prop: 'user_id', compare: '=', value: this.$store.state.system.authData.user_id }
    this.filters['cms_block_id'] = { prop: 'cms_block_id', compare: '=', value: this.selectedComponentId }

    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      // const headers_common = { ...this.erp.ext.request.axiosInstance.defaults.headers.common } // copy of the object

      let filterQuery = this.erp.ext.query()
      filterQuery.set('perpage', 'all')

      // admins only
      // filterQuery.set('company_id', '0')
      // filterQuery.set('website_id', '0')
      // this.filters['company'] = { prop: 'company_id', compare: '=', value: '0' }
      // this.filters['website'] = { prop: 'website_id', compare: '=', value: '0' }
      // this.erp.ext.request.axiosInstance.defaults.headers.common.company = 0
      // this.erp.ext.request.axiosInstance.defaults.headers.common.cmswebsite = 0

      Object.values(this.filters).forEach(filter => {
        filterQuery.where(filter.prop, filter.compare, filter.value, filter.group)
      })

      if (this.search) {
        filterQuery.where('name', 'like', `%${this.search}%`)
      }

      this.templates = (await this.erp.ext.request.get('modules/cms-templates', filterQuery.toString()))
      await this.fetchPreviewImages()

      // return back the old common headers
      // this.erp.ext.request.axiosInstance.defaults.headers.common = headers_common

      this.loading = false
    },

    // todo refactor #12098409178
    async fetchPreviewImages() {
      const imagePromises = this.templates.objectData.map(async template => {
        try {
          const { filepath, filename } = (await this.erp.ext.request.axiosInstance.get(`/modules/documents/${template.preview_image}`)).data.data
          template.preview_image = `${filepath}/${filename}`
        } catch (err) {}
      })

      await Promise.all(imagePromises)
    },

    importTemplate(ev){
      const nodesData = JSON.parse(ev.content_json)
      const templateNode = Node.unserialize(this.editor, nodesData[0])

      try {
        this.editor.replaceNode(templateNode)
        this.$toast.success('Template imported')
      } catch (err) {
        this.$toast.error(err.message)
      }

      setTimeout(() => {
        this.editor.selectNode(templateNode)
      })
    },

    filter: debounce(async function() {

      if (this.categories.length) {
        this.filters['categories'] = { prop: 'category', compare: 'in', value: this.categories }
      } else {
        delete this.filters['categories']
      }

      if (this.isAdmin && this.showAllUserTemplates) {
        delete this.filters['user_id']
      } else {
        this.filters['user_id'] = { prop: 'user_id', compare: '=', value: this.$store.state.system.authData.user_id }
      }

      this.loadData()
    }, 700),
  },
}
</script>