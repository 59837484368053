<template>
    <div>
        <page-title :page-title="translate('marketplace', 'marketplace')">
            <template v-slot:actions>
                <router-link class="btn btn-white rounded-pill" to="/app-store">{{ translate('Go back', 'global') }}</router-link>
                <button class="btn btn-primary rounded-pill" @click="$modal.show('app-plan-modal')">
                    {{ translate('Install module', 'marketplace') }}
                </button>
            </template>
        </page-title>

        <div class="marketplace-main-content mt-4" v-if="extension && !loading">
            <div class="row">
                <div class="col-md-9">
                    <div class="app-overview p-3">
                        <p>
                            {{ translate('What is', 'marketplace') }} {{ extension.name }}
                        </p>
                    </div>

                    <div class="app-reviews">
                        <div class="review-header mb-3 d-flex justify-content-between align-items-center p-3">
                            <span class="font-weight-bold">{{ translate('Reviews', 'marketplace') }}</span>
                            <button class="btn btn-sm btn-add-review">{{ translate('Write review', 'marketplace') }}</button>
                        </div>
                        <div class="single-review b-t-pr-gr p-3">
                            <div class="d-flex justify-content-between mb-2">
                                <span class="review-title font-weight-bold">{{ translate('Bug name', 'marketplace') }}</span>
                                <div class="review-rating d-flex">
                                    <span>{{ translate('Author', 'marketplace') }}</span>
                                    <span class="px-1">·</span>
                                    <span>{{ translate('Published on', 'marketplace') }}
										<span>{{ created_at }}</span>
									</span>
<!--                                    <span class="px-1">·</span>-->
<!--                                    <div>-->
<!--                                        <span><i class="far fa-star"></i></span>-->
<!--                                        <span><i class="far fa-star"></i></span>-->
<!--                                        <span><i class="far fa-star"></i></span>-->
<!--                                        <span><i class="far fa-star"></i></span>-->
<!--                                        <span><i class="far fa-star"></i></span>-->
<!--                                    </div>-->
                                </div>
                            </div>
                            <div>
                                <span class="review-info">
                                   /*
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 app-sidebar-info">
                    <div class="app-logo p-3">
                        <img v-if="extension.readable_options.logo" style="width: 100%" :src="extension.readable_options.logo">
                        <img v-else style="width: 100%" src="/uploads/defaults/image-placeholder.jpg">
                    </div>
                    <div class="app-price p-3">
                        <div class="d-flex justify-content-between">
                            <span class="price-label font-weight-bold">{{ translate('Price', 'marketplace') }}</span>
                            <span class="price font-weight-bold">
								{{ extension.price.currency }}
								{{ extension.price.price}}
								/{{ translate('per year', 'marketplace') }}
							</span>
                        </div>
                        <div class="d-flex justify-content-end pt-3">
                            <button class="btn btn-trial">14 {{ translate('days trial', 'marketplace') }}</button>
                        </div>
                    </div>
<!--                    <div class="app-rating p-3">-->
<!--                        <div class="d-flex justify-content-between">-->
<!--                            <span class="rating-label font-weight-bold">{{ translate('marketplace.rating') }}</span>-->
<!--                            <div>-->
<!--                                <span><i class="far fa-star"></i></span>-->
<!--                                <span><i class="far fa-star"></i></span>-->
<!--                                <span><i class="far fa-star"></i></span>-->
<!--                                <span><i class="far fa-star"></i></span>-->
<!--                                <span><i class="far fa-star"></i></span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <button @click="$modal.show('app-plan-modal')" class="btn btn-module w-100 py-3">{{ translate('Install module', 'marketplace') }}</button>

                    <modal name="app-plan-modal"
                           :pivot-x="0.5"
                           :pivot-y="0.5"
                           height="600px"
                           transition="slide-top"
                           overlayTransition="wait"
                           classes="remove-modal-height">
                        <AppPlanModal :value="extension"></AppPlanModal>
                    </modal>
                </div>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center m-5">
            <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
        </div>
    </div>
</template>

<script>
    import AppPlanModal from "./AppPlanModal";
    import {db} from "@/utilities/database";
	import debounce from "debounce";
	import moment from 'moment';
	import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";

    export default {
        name: "AppShowView",
        components: {
            AppPlanModal
        },
        data() {
            return {
                extension: null,
				created_at: '',
                loading: false
            }
        },
        methods: {
			loadExtension: debounce(async function (filters) {
				this.loading = true;

				let params = null
				if (filters) {
					params = {
						'filter_criteria': {
							'filter_groups': {
								0: {
									'filters': {
										0: filters
									}
								}
							}
						}
					}
				}

				if (filters && filters.key === 'status') {
					this.selectedFilter = filters.value
				}

				let columns = {column: 'created_at', direction: 'desc', type: 'text'}

				let response = await db.get(`modules/blockz/app-extensions/extensions-modules?page=1&perpage=200`)
				response.data.data.forEach(modules => {
					db.get(`modules/blockz/app-extensions/extensions-props/${modules.id}?page=1&perpage=200&${urlEncoder(columns)}&${urlEncoder(params)}`)
						.then(extensions => {
							extensions.data.data.forEach(ext => {
								if ( ext.id == this.$route.params.id ) {
									ext.readable_options = createEntityFormObjectNoTabs(ext.options, 'key')
									if (ext.readable_options.description) {
										ext.readable_options.description = ext.readable_options.description.replace(/(<([^>]+)>)/gi, "")
									}
									this.extension = ext
									this.created_at = moment(this.extension.created_at).format('YYY-MMM-DD')
								}
							})
						})
					this.loading = false;
				}).catch(error => {
          this.$toast.error(error.response.data.message)
					this.loading = false;
				})
			}, 700),
        },
        created() {
            this.loadExtension()
        }
    }
</script>

<style lang="scss">
    .btn-marketplace {
        background: #fff;
        border-color: #FF0553 !important;
        color: #FF0553 !important;
        &:hover {
            background: #fff;
            border-color: #FF0553;
            color: #FF0553;
        }
    }

    .install-module {
        color: #fff !important;
    }

    .app-overview, .app-reviews {
        background: #ffff;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        margin-bottom: 1.5rem;
    }

    .review-header {
        span {
            font-size: 18px;
        }

        .btn-add-review {
            background: #fff;
            border-color: #DEDEDE;
            color: #DEDEDE;
            &:hover {
                background: #DEDEDE;
                border-color: #DEDEDE;
                color: #fff;
            }
        }
    }

    .single-review {
        .review-title {
            font-size: 16px;
            text-transform: uppercase;
        }

        .review-rating span {
            color: #dedede;
        }
    }

    .fa-star {
        color: #ffc02e;
    }

    .app-sidebar-info {
        .app-logo, .app-price, .app-rating {
            background: #ffff;
            border: 1px solid #DEDEDE;
            border-radius: 5px;
            margin-bottom: 1.5rem;
        }

        .price-label, .rating-label {
            font-size: 16px;
            color: #DEDEDE;
        }

        .price {
            font-size: 17px;
        }

        .btn-trial {
            background: #69cefa;
            border-color: #69cefa;
            color: #fff;
            &:hover {
                background: #fff;
                border-color: #69cefa;
                color: #69cefa;
            }
        }

        .btn-module {
            background: #1ce67a;
            border-color: #1ce67a;
            color: #fff;
            &:hover {
                background: #fff;
                border-color: #1ce67a;
                color: #1ce67a;
            }
        }
    }
</style>
