<template>
  <div>
    <div class="container-fluid">
      <page-title :page-title="translate('Create {entity}', 'entities', { entity: translate('Order', 'entities') })" buttons="/webshop-orders"/>

      <div class="row my-4">
        <div class="col-xl-4 col-lg-12 first-column">
          <order-number />
          <order-date v-model="order.created_at" />
          <order-statuses v-model="order" />
          <order-shipping-methods v-model="order" />
          <order-payment-methods v-model="order" />
        </div>

        <div class="col-xl-4 col-lg-12 second-column">
          <!-- todo extract reset addresses method -->
          <order-customer-details
            ref="customerDetails"
            v-model="order"
            @updateShippingAndPaymentMethods="updateShippingAndPaymentMethods"
          />
        </div>

        <div class="col-xl-4 col-lg-12 third-column">
          <order-card label="Notes" class="notes-container">
            <order-notes v-model="order" />
          </order-card>
        </div>
      </div>

      <order-card label="Search and add product">
        <div class="row">
          <div class="col-sm-6">
            <product-select @updateCart="updateCart" :cart-items="cartItems"/>
          </div>
        </div>
      </order-card>



      <order-cart
        :cart-overview="cartOverview"
        :cart-items="cartItems"
        @reloadCart="reloadCart"
        @completeOrder="completeOrder"
      />
    </div>
  </div>
</template>

<script>
import ProductSelect from "@/modules/sitecart/components/ProductSelect.vue"
import OrderCard from "@/modules/sitecart/views/orders/CreateOrder/OrderCard.vue"
import OrderCustomerDetails from "@/modules/sitecart/views/orders/CreateOrder/OrderCustomerDetails.vue"
import OrderNotes from "@/modules/sitecart/views/orders/CreateOrder/OrderNotes.vue"
import OrderShippingMethods from "@/modules/sitecart/views/orders/CreateOrder/OrderShippingMethods.vue"
import OrderPaymentMethods from "@/modules/sitecart/views/orders/CreateOrder/OrderPaymentMethods.vue"
import OrderDate from "@/modules/sitecart/views/orders/CreateOrder/OrderDate.vue"
import OrderCart from "@/modules/sitecart/views/orders/CreateOrder/OrderCart.vue"
import OrderNumber from "@/modules/sitecart/views/orders/CreateOrder/OrderNumber.vue"
import OrderStatuses from "@/modules/sitecart/views/orders/CreateOrder/OrderStatuses.vue"

export default {
  name: "CreateOrders",
  components: {
    OrderCard, OrderCart, OrderDate, OrderPaymentMethods, OrderShippingMethods, OrderStatuses, OrderNotes, OrderNumber, OrderCustomerDetails, ProductSelect,
  },
  data() {
    return {
      order: {
        shipping_method_id: null,
        shipping_method: null,
        shipping_rates: null,
        discount_id: null, // TODO discounts
        status: 'pending',
        notes: null,
        payment_method: null,
        payment_methods: null,
        payment_provider_id: null,
        client_id: 0,

        billing_client_first_name: null,
        billing_client_last_name: null,
        billing_client_phone: null,
        billing_client_email: null,
        billing_address_country: null,
        billing_address_state: null,
        billing_address_city: null,
        billing_address_postcode: null,
        billing_address_id: null,
        billing_address_address: null,
        billing_address_street_number: null,
        billing_address_mol: null,
        billing_address_bulstat: null,
        billing_company_name: null,
        billing_vat_number: null,
        chamber_of_commerce: null,
        billing_address_additional_information: null,

        shipping_client_first_name: null,
        shipping_client_last_name: null,
        shipping_client_phone: null,
        shipping_address_country: null,
        shipping_address_state: null,
        shipping_address_city: null,
        shipping_address_postcode: null,
        shipping_address_id: null,
        shipping_address_address: null,
        shipping_address_street_number: null,
        shipping_company_name: null,
        shipping_address_additional_information: null,

        accept_privacy_policy: 0,
        accept_terms: 0,

        additional_data: null,
      },
      cartOverview: {},
      cartItems: [],
    }
  },
  methods: {
    async cartToken(){
      let session = window.localStorage.getItem('session')
      if (!session) {
        const res = await this.erp.ext.request.axiosInstance.get('/modules/sales/start-user-session')
        window.localStorage.setItem('session', res.data['carttoken'])
        this.erp.ext.request.axiosInstance.defaults.headers.common['carttoken'] = res.data['carttoken']
      } else {
        this.erp.ext.request.axiosInstance.defaults.headers.common['carttoken'] = session
      }
    },
    async reloadCart() {
      const { cart_overview, items } = (await this.erp.ext.request.axiosInstance.get('/modules/sales/cart')).data.data
      this.cartOverview = cart_overview
      this.cartItems = items
    },
    async updateShippingAndPaymentMethods() {
      const { payment_methods, shipping_rates } = (await this.erp.ext.request.axiosInstance.post('modules/sales/cart/update', {
        ...this.order,
        shipping_address_country: this.order.shipping_address_country || this.order.billing_address_country,
      })).data.data

      this.order.payment_methods = payment_methods
      this.order.shipping_rates = shipping_rates
    },
    updateCart({ cart_overview, items }) {
      this.cartOverview = cart_overview
      this.cartItems = items
    },
    validateOrder() {
      if (this.cartItems.length === 0) {
        return this.translate("Cannot create order without items")
      }
      return null
    },
    async completeOrder() {
      try {
        let payload = this.order
        if (this.$refs.customerDetails.sameAsBillingAddress) {
          payload = {
            ...payload,
            shipping_client_first_name: payload.billing_client_first_name,
            shipping_client_last_name: payload.billing_client_last_name,
            shipping_client_phone: payload.billing_client_phone,
            shipping_address_country: payload.billing_address_country,
            shipping_address_state: payload.billing_address_state,
            shipping_address_city: payload.billing_address_city,
            shipping_address_postcode: payload.billing_address_postcode,
            shipping_address_id: payload.billing_address_id,
            shipping_address_address: payload.billing_address_address,
            shipping_address_street_number: payload.billing_address_street_number,
            shipping_company_name: payload.billing_company_name,
            shipping_address_additional_information: payload.billing_address_additional_information,
          }
        }

        const error = this.validateOrder()
        if(error){
          return this.$toast.error(error)
        }

        const order = await this.erp.ext.request.axiosInstance.post('modules/sales/cart/complete', payload)
        await this.erp.ext.request.axiosInstance.put(`/modules/sales/orders/statuses/${order.data.data.id}`, { status: this.order.status })
        this.$toast.requestSuccess('post', 'Order')
        this.$router.push('/webshop-orders')
      } catch (error) {
        this.$toast.error(error)
      }
    },
  },
  async created() {
    await this.cartToken()
    await this.reloadCart()
  },
  beforeRouteLeave(to, from, next) {
    window.localStorage.removeItem('session')
    next()
  },
}
</script>

<style lang="scss" scoped>
@media all and (min-width: 1200px) and (max-width: 1500px) {
  .first-column,
  .second-column {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .third-column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>

<style lang="scss">
.col-style {
  flex-grow: 1;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .col-style-child {
    background-color: white;
    border-radius: 5px;
  }
}
</style>