// Applied to all component settings screens

import ElementSettingsContentTabs from "../components/element-settings/tabs/ElementSettingsContentTabs.vue"
import ElementSettingsContentTab from "../components/element-settings/tabs/ElementSettingsContentTab.vue"

export default {
  components: {
    ElementSettingsContentTabs,
    ElementSettingsContentTab,
  },
  computed: {
    generalFontOptions(){
      return [
        { key: 'h1', label: 'H1' },
        { key: 'h2', label: 'H2' },
        { key: 'h3', label: 'H3' },
        { key: 'h4', label: 'H4' },
        { key: 'h5', label: 'H5' },
        { key: 'h6', label: 'H6' },
        { key: 'h7', label: 'H7' },
        { key: 'p', label: 'P' },
      ]
    },
    generalOrientationOptions(){
      return [
        { key: 'horizontal', label: 'Horizontal' },
        { key: 'vertical', label: 'Vertical' },
      ]
    },
    generalAlignmentOptions() {
      return [
        { key: 'left', label: 'Left' },
        { key: 'center', label: 'Center' },
        { key: 'right', label: 'Right' },
      ]
    },
    // used for justify-content, align-items, navArrowsVerticalAlign
    generalVerticalAlignmentOptions() {
      return [
        { key: 'start', label: 'Top' },
        { key: 'center', label: 'Middle' },
        { key: 'end', label: 'Bottom' },
      ]
    },
    // TODO refactor #232357568657
    generalLinkTargetOptions() {
      return [
        { key: '_self', label: 'Open in same tab' },
        { key: '_blank', label: 'Open in new tab' },
      ]
    },
    // END TODO
    borderStyleOptions() {
      return [
        { key: 'solid', label: 'Solid' },
        { key: 'dotted', label: 'Dotted' },
        { key: 'dashed', label: 'Dashed' },
        { key: 'double', label: 'Double' },
        { key: 'groove', label: 'Groove' },
        { key: 'ridge', label: 'Ridge' },
      ]
    },
    roundTypeOptions() {
      return [
        { key: 'rounded', label: 'Rounded' }, // border-radius: 0.25rem
        { key: 'square', label: 'Square' }, // border-radius: 0
        { key: 'round', label: 'Round' }, // border-radius: 50rem
      ]
    },
    borderRadiusOptions() {
      return [
        { key: '.25rem', label: 'Rounded' },
        { key: '0', label: 'Square' },
        { key: '50rem', label: 'Pill' },
        { key: '50%', label: 'Ellipse' },
      ]
    },
    layoutOptions() {
      return [
        { key: 'table', label: 'Grid' },
        { key: 'list', label: 'List' },
        { key: 'carousel', label: 'Carousel' },
      ]
    },
    columnOptions() {
      return [
        { key: '12', label: '1' },
        { key: '6', label: '2' },
        { key: '4', label: '3' },
        { key: '3', label: '4' },
      ]
    },
  },
}