<template>
    <div>
        <page-title :page-title="translate('Roles', 'entities')" v-if="roles && roles.meta" :counter="roles.meta.total">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="$router.push('/roles/create')">
                    {{ translate('Add new {entity}', 'entities', { entity: translate('Role', 'entities').toLowerCase() }) }}
                </button>
            </template>
        </page-title>
        <loading-circles v-if="loading"></loading-circles>

        <div class="mt-3">
            <div class="card bg-white border">
                <div class="card-header actions-toolbar border-0">
                    <div class="row justify-content-between align-items-center">
                        <div class="col">
                            <h6 class="d-inline-block mb-0 system-text-primary">{{ translate('Roles', 'roles') }}</h6>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" v-if="roles">
                    <table class="table align-items-center system-text-primary">
                        <thead>
                        <tr>
                            <th>{{ translate('ID', 'roles')}}</th>
                            <th>{{ translate('Name', 'roles')}}</th>
                            <th>{{ translate('Slug', 'roles')}}</th>
                            <th>{{ translate('Level', 'roles')}}</th>
                            <th>{{ translate('Created at', 'global')}}</th>
                            <th class="text-right">{{ translate('Actions', 'global') }}</th>
                        </tr>
                        </thead>
                        <tbody class="list">
                        <tr v-for="(role, ri) in roles.data">
                            <td>{{role.id}}</td>
                            <td>{{role.value}}</td>
                            <td>{{role.slug}}</td>
                            <td>{{role.level}}</td>
                            <td>{{ formatDate(role.created_at) }}</td>
                            <td class="text-right">
                                <cta>
                                    <action-button v-tooltip="translate('Edit {entity_title}', 'entities', { entity_title: role.value })" @click.native="$router.push(`/roles/${role.id}`)" class-icon="fa-pen"/>
                                    <action-button v-tooltip="translate('Delete {entity_title}', 'entities', { entity_title: role.value })" @click.native="deleteRole(role)" class-icon="fa-trash-alt" v-if="role.slug !== 'admin'"/>
                                </cta>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {setPageTitle} from "@/utilities/helper";
import {db} from '@/utilities/database'

import RoleForm from "./RoleForm";

export default {
    name: "Roles",
    components: {
        RoleForm
    },
    data() {
        return {
            roles: null,
            loading: false,
        }
    },
    methods: {
        loadRoles() {
            this.loading = true
            this.$store.state.system.isLoading = true
            db.get('/modules/roles').then(res => this.roles = res.data)
            this.loading = false
            this.$store.state.system.isLoading = false
        },
        async deleteRole(role) {

            const confirmDelete = await this.$alert.confirmDelete()
            if(!confirmDelete.isConfirmed) return

            db.delete(`/modules/roles/${role.id}`).then(res => {
                this.$toast.requestSuccess('delete', 'Role')
                this.loadRoles()
            }).catch(err => {
                this.$toast.errorGeneral()
            })
        }
    },
    mounted() {
        this.loadRoles()
    },
    created() {
        setPageTitle('roles')
    }
}
</script>

<style scoped>

</style>
