<template>
  <div class="cms-block cms-block-icon-box">

    <compoment :is="this.getSetting('link') ? 'a' : 'span'" :id="iconBoxId" class="d-flex" :href="linkHref" :target="linkTarget">

      <div class="icon-box-icon-wrapper">
        <img
          v-if="getSetting('icon_custom')"
          :src="getSetting('icon_custom')"
          style="height: 45px; width: 45px;"
          class="icon-box-icon"
        >
        <i v-else :class="`${getSetting('icon')}`" class="fa icon-box-icon"></i>
      </div>

      <div class="icon-box-content-wrapper">
        <!-- TITLE AND DESCRIPTION -->
        <component :is="getSetting('title_font')" class="icon-box-title">{{ getSetting('title') }}</component>
        <div class="icon-box-description" v-html="getSetting('description')"></div>
      </div>

    </compoment>



    <!--
        <div class="d-flex" :style="settings.position">
          <a v-if="settings.linkTarget && settings.type === 'icon'" target="_blank"
             style="color: #212529" :href="settings.link">
            <i :style="`font-size: ${settings.iconFontSize}px; color: ${settings.iconColor};
						border: ${settings.iconBorderWidth}px solid ${settings.iconBorderColor};
						background-color: ${settings.iconBackgroundColor}; border-radius: ${settings.iconBorderRadius}px`"
               :class="`${settings.icon} fa-2x p-2`"></i>
          </a>
          <a v-if="!settings.linkTarget && settings.type === 'icon'"
             style="color: #212529" :href="settings.link">
            <i :style="`font-size: ${settings.iconFontSize}px; color: ${settings.iconColor};
						border: ${settings.iconBorderWidth}px solid ${settings.iconBorderColor};
						background-color: ${settings.iconBackgroundColor}; border-radius: ${settings.iconBorderRadius}px`"
               :class="`${settings.icon} fa-2x p-2`"></i>
          </a>
          <img v-if="settings.type === 'image'" :src="settings.image"
               :width="settings.imageWidth" :height="settings.imageHeight"
               :class="{'d-none' : settings.type === 'icon'}">
          <div class="d-flex justify-content-center align-items-center">
            <component :is="settings.titleTag" class="box-title mb-0 px-1"
                       :style="`color: ${settings.titleColor}; font-size: ${settings.titleFontSize}px`">
              {{ settings.title }}
            </component>
          </div>
        </div>
        <div class="box-description" :style="`text-align: ${settings.description_align};
				color: ${settings.descriptionColor}; font-size: ${settings.descriptionFontSize}px`" v-html="getDecodedDescription">
        </div>
    -->


  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";

export default {
  name: 'IconBoxElement',
  mixins: [
    CraftSettingsMixin
  ],
  inject: ['node'],
  craft: {
    defaultProps: {
      settings: {
        // Icon
        icon_position: 'left',
        icon: 'fa-check-square',
        icon_custom: '',
        icon_alignment: 'left', // shows only when icon_position=top
        icon_width: '20px',
        icon_margin: '',
        icon_color:  '',
        icon_background_color: '',
        icon_padding:  '15px',
        icon_border_radius: '',

        // Title
        title: 'Lorem ipsum',
        title_font: 'h4',
        title_alignment: 'left',
        title_margin_bottom: '',
        title_font_size: '',
        title_color: '',

        // Description
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

        // Link
        link: '',
        link_target: '_self',

      },
    }
  },
  computed: {
    iconBoxId(){
      return 'icon-box-'+this.node.uuid
    },

    linkHref() {
      return this.getSetting('link') ? this.getSetting('link') : null;
    },

    linkTarget() {
      return (this.getSetting('link_target') && this.getSetting('link_target') !== '_self') ? this.getSetting('link_target') : null;
    }
  },
}
</script>

<!--
<script>
export default {
	name: "IconBox",
    data() {
      return {
        blockName: 'icon-box'
      }
    },
    computed: {
        getDecodedDescription() {
            return decodeURIComponent(this.settings.description)
        }
    },
	props: {
		settings: {
			default: () => ({
				position: null,
				title: 'Title',
				description: encodeURIComponent('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'),
				description_align: 'left',
				link: '',
				linkTarget: false,
				type: 'icon',
				icon: 'fa fa-boxes',
				image: '/uploads/defaults/image-placeholder.jpg',
				titleTag: 'h4',
				titleFontSize: '24',
				titleColor: 'rgb(33, 37, 41)',
				descriptionFontSize: '16',
				descriptionColor: 'rgb(33, 37, 41)',
				iconFontSize: '24',
				iconColor: 'rgb(33, 37, 41)',
				iconBorderColor: 'rgba(0, 0, 0, 0)',
				iconBackgroundColor: 'rgba(0, 0, 0, 0)',
				iconBorderWidth: '0',
				iconBorderRadius: '0',
				imageHeight: '30',
				imageWidth: '30',
			}),
		}
	}
}
</script>
-->