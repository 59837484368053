<template>
  <div class="row">
    <template v-for="(times, day) in initialData">
      <div :key="day" class="col-12 col-xl-6">
        <div class="form-group px-3 py-2 border rounded border">
          <label class="form-control-label">{{ translate(day, 'global') }}</label>
          <div v-for="(time, index) in times" :key="index" class="d-flex align-items-center mb-2 time-container">
            <div class="w-100 d-flex">
              <date-picker
                v-model="times[index][0]"
                value-type="format"
                type="time"
                format="HH:mm"
                class="w-50 mr-2"
                :placeholder="translate('From', 'global')"
                :disabled-time="(date) => shouldDisableTime(date, times[index][1])"
                :disabled="isDisabled"
                :input-class="['form-control']"
              />
              <date-picker
                v-model="times[index][1]"
                value-type="format"
                type="time"
                format="HH:mm"
                class="w-50"
                :placeholder="translate('To', 'global')"
                :input-class="['form-control']"
                :disabled-time="(date) => shouldDisableTime(date, times[index][0], false)"
                :disabled="isDisabled"
              />
            </div>
            <div class="d-flex justify-content-between align-items-center time_manage ml-2">
              <button class="btn btn-clear rounded-pill p-0 m-0" @click="canWrite ? addTime(day) : null">
                <i class="icon-erp-add icon-erp-2x" />
              </button>
              <button class="btn btn-icon-only rounded-pill p-0 m-0" @click="canWrite ? removeTime(day, index) : null">
                <i class="icon-erp-trash icon-erp-2x red-text" />
              </button>
            </div>
          </div>
          <div v-if="!initialData[day].length" class="d-flex justify-content-between align-items-center mb-2 time-container">
            <span class="alert alert-dark dfc w-100 px-2 mb-0 rounded-lg ">{{ translate('Closed', 'global') }}</span>
            <div class="d-flex justify-content-end align-items-center time_manage ml-2">
              <button class="btn btn-clear rounded-pill p-0 m-0" @click="canWrite ? addTime(day) : null">
                <i class="icon-erp-add icon-erp-2x" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import DatePicker from 'vue2-datepicker'
import { isVoidValue } from "@/utilities/helper"

export default {
  name: "WeekTimesField",
  components: {
    DatePicker,
  },
  mixins: [FormFieldMixin],
  data() {
    return {
      initialData: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
    }
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
  watch: {
    'initialData': {
      deep: true,
      handler(v) {
        this.$emit('input', v)
      },
    },
  },
  created() {
    if (!isVoidValue(this.value)) {
      let value = {}
      if (typeof this.value === 'string') {
        value = JSON.parse(this.value)
      } else if (typeof this.value === 'object') {
        value = this.value
      }

      this.initialData = {
        ...this.initialData,
        ...value,
      }

      this.$emit('input', this.initialData)
    }
  },
  methods: {
    addTime(day) {
      this.initialData[day].push([])
    },
    async removeTime(day, index) {
      const confirmDelete = await this.$alert.confirmDelete()

      if (confirmDelete.isConfirmed) {
        this.initialData[day].splice(index, 1)
      }
    },
    shouldDisableTime(date, time, isStart = true) {
      if (!time) return false

      const [hours, minutes] = time.split(':')
      const referenceDate = new Date().setHours(hours, minutes, 0, 0);

      return isStart
        ? date > referenceDate
        : date < referenceDate
    },
  },
}
</script>

<style scoped lang="scss">
.time-container {
  height: 45px;
}

.time_manage {
  button {
    line-height: 1;
  }
}
</style>
