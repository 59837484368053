<template>
  <div class="invoice-table-index">
    <entity-index-content
      entity-type="invoices"
    />
  </div>
</template>

<script>
import EntityIndexContent from "@/modules/erp_entities/components/page/EntityIndexContent.vue"

export default {
  name: "InvoiceIndex",
  components: {
    EntityIndexContent,
  },
}
</script>