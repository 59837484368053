<template>
	<div>
		<page-title :page-title="translate('Entities', 'entities')" v-if="pagination && pagination.meta" :counter="pagination.meta.total">
			<template v-slot:actions>
				<button @click="$router.push(`/entities/create`)" class="btn btn-primary rounded-pill">
          <span class="btn-inner--icon p-3">
            {{ translate('Add new {entity}', 'entities', { entity: translate('Entity', 'entities').toLowerCase() }) }}
          </span>
				</button>
			</template>
		</page-title>


		<div class="card bg-white">
			<div class="card-header actions-toolbar border-0">
				<div class="row justify-content-between align-items-center">
					<div class="col">
						<h6 class="d-inline-block mb-0 system-text-primary">{{ translate('Entities', 'entities') }}</h6>
					</div>
				</div>
			</div>

			<entities-table></entities-table>

		</div>
	</div>

</template>

<script>
import EntitiesTable from "./EntitiesTable";
import Filters from "../../components/Filters";
import {mapState} from 'vuex'

export default {
	name: "Entities",
	data() {
		return {
			title: 'Create Entity',
			showFilters: false
		}
	},
	components: {
		'entities-table': EntitiesTable,
		'items-filter': Filters
	},
	computed: {
		...mapState('entities', ['pagination']),
	},
}
</script>

<style lang="scss">
@media (min-width: 1200px){
	.entities-modal{
		width: 75% !important;
	}
}
</style>
