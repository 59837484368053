<template>
    <div class="d-flex justify-content-center flex-column bg-white p-5">
        <div class="text-center py-3">
            <h3 class="font-weight-bold" v-if="creating">{{ translate('Hold on while we are setup your webshop', 'onboarding') }}!</h3>
            <h3 class="font-weight-bold" v-else>{{ translate('Webshop created!', 'onboarding') }}</h3>
        </div>
        <transition name="fade" mode="out-in">
          <div>
            <div class="circle-progress" v-if="creating">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">
                    <img src="/img/skitle-icon.png" alt="">
                </div>
            </div>
            <div v-if="!creating">
                <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                    <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                    <span class="swal2-success-line-tip"></span>
                    <span class="swal2-success-line-long"></span>
                    <div class="swal2-success-ring"></div>
                    <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                    <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                </div>
            </div>
          </div>
        </transition>
        <div class="text-center p-3" v-if="creating">
            <span>{{ translate('One moment, we will setup your site', 'onboarding') }} {{step_percentage}}% !</span>
        </div>
        <div class="d-flex justify-content-center" v-if="!creating">
            <router-link class="btn btn-white rounded-pill" to="/">
                {{ translate('Go to homepage', 'onboarding') }}
            </router-link>
            <button class="btn btn-primary rounded-pill" @click="redirectToBuilder">
                {{ translate('Start building your website', 'onboarding') }}
            </button>
        </div>
    </div>
</template>

<script>
import {slug} from "../../../../utilities/helper";

export default {
    name: "Finish",
    data() {
        return {
            step_percentage: 0,
            creating: true,
        }
    },
    async created() {

        let websiteSettings = await this.erp.ext.request.axiosInstance.get(`/modules/cms-websites`)
        let settingsMenu = websiteSettings.data.settings_menu.reduce((acc, setting) => {
            acc[slug(setting.name)] = setting.id
            return acc
        }, {})
        // End get settings ids

        this.step_percentage = 50

        if (this.value.vat_settings) {

            // Save vat settings in design options
            await this.erp.ext.request.axiosInstance.post(`/modules/settings/CmsWebsite/${this.value.website_id}`, {
                options: {
                    calculate_price_on: this.value.vat_settings.calculate_price_on,
                    enable_vat: this.value.vat_settings.enable_vat,
                    include_vat_in_price: this.value.vat_settings.include_vat_in_price,
                    show_vat_in_catalog: this.value.vat_settings.show_vat_in_catalog,
                    show_vat_in_checkout: this.value.vat_settings.show_vat_in_checkout,
                    show_vat_separately: this.value.vat_settings.show_vat_separately,
                    vat_label: this.value.vat_settings.vat_label
                },
                model: 'CmsWebsite',
                modelId: `${this.value.website_id}`,
                settingsId: settingsMenu.webshop_settings,
                name: ''
            })
        }

        this.step_percentage = 100
        this.creating = false
    },
    props: {
        value: {
            required: true
        }
    },
    methods: {
        redirectToBuilder() {
            this.$router.push(`/builder/cms-pages/create`)
        },
        percentageToDegrees(percentage) {
            return percentage / 100 * 360
        },
        loadCirclePerc() {
            let left = document.querySelector('.progress-left .progress-bar')
            let right = document.querySelector('.progress-right .progress-bar')

            if (this.step_percentage <= 50) {
                right.style.transform = `rotate(${this.percentageToDegrees(this.step_percentage)}deg)`
            } else {
                right.style.transform = 'rotate(180deg)'
                left.style.transform = `rotate(${this.percentageToDegrees(this.step_percentage - 50)}deg)`
            }
        }
    },
    watch: {
        'step_percentage': function () {
            this.loadCirclePerc()
        }
    }
}
</script>

<style scoped lang="scss">
.circle-progress {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress-left {
        left: 0;
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 4px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $primary;
    }

    .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        transform-origin: center left;
    }

    .progress-right{
        right: 0;
    }

    .progress-right .progress-bar{
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        transform-origin: center right;
    }

    .progress-value{
        width: 98.5%;
        height: 98.5%;
        display: flex;
        margin: auto;
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 50%;

        img {
            width: 65%;
            margin: auto;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
