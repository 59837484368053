<template>
    <div>
        <div class="card-header">
            <h4 class="font-weight-bold">{{ translate('Add new {entity}', 'entities', { entity: translate('Customer', 'entities').toLowerCase() }) }}</h4>
        </div>
        <ul class="list-group">
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Company name', 'clients') }}:</label>
                <input class="" type="text" @input="e => generateSlug(e.target.value)" v-model="form.name">
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Company email', 'clients') }}:</label>
                <input class="" type="text" v-model="form.email">
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Contact person first name', 'entities') }}:</label>
                <input class="" type="text" v-model="form.contact_person_first_name">
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Contact person last name', 'entities') }}:</label>
                <input class="" type="text" v-model="form.contact_person_last_name">
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Contact person email', 'clients') }}:</label>
                <input class="" type="text" v-model="form.contact_person_email">
                <small class="text-danger" v-if="errors && errors.hasOwnProperty('contact_person_email')">{{ errors.contact_person_email.email }}</small>
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Phone', 'clients') }}:</label>
                <input class="" type="text" v-model="form.phone">
            </li>
        </ul>
        <div class="d-flex p-3">
            <button class="btn btn-primary rounded-pill w-100" @click="create">{{ translate('Add new customer', 'clients') }}</button>
        </div>
    </div>
</template>

<script>
import ErpModel from '@/modules/erp_framework/services/ErpModel'

export default {
    name: "AddNewClient",
    data() {
        return {
            form: {},
            errors: null
        }
    },
    methods: {
        async create() {
            try {
                await ErpModel.create('clients', this.form, {create_company: true})
                this.$modal.hide('add_new_client')
                this.$toast.requestSuccess('post', 'Customer')
            }
            catch ({errors}) {
                this.errors = errors
            }
        },
        generateSlug(v) {
            this.form.slug = v.split(' ').join('-') + '-' + Math.random().toString(36).substring(7)
        }
    }
}
</script>