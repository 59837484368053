<template>
  <date-picker
    v-model="model"
    :type="datePickerType"
    :range="fieldProps.type === 'range'"
    :formatter="this.fieldProps.type === 'datetime_iso' ? datetimeIsoFormatter : null"
    value-type="format"
    :placeholder="placeholder"
    :class="{'is-invalid': errorsFormatted }"
    :disabled="isDisabled"
    :input-class="['form-control']"
  />
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { isVoidValue } from "@/utilities/helper"
import { applyShortcodes } from "@/modules/erp_entities/utilities/shortcodes"

export default {
  name: "DatePickerField",
  components: {
    DatePicker,
  },
  mixins: [FormFieldMixin],
  data() {
    return {
      datetimeIsoFormatter: {
        parse: value => {
          // Parse the ISO 8601 date and convert it to a JavaScript Date object
          return new Date(value)
        },
        stringify: value => {
          if (value instanceof Date) {
            return this.formatDate(value, null, "yyyy-MM-DD HH:mm:ss")
          }
          return value // Return the original value if not a Date object
        },
      },
    }
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
    model: {
      get() {
        return this.value
      },
      set(v) {
        if(this.fieldProps.type === 'datetime_iso'){
          const dateObj = new Date(v)
          this.$emit('input', dateObj.toISOString())
        } else {
          this.$emit('input', v)
        }
      },
    },
    datePickerType() {
      if(this.fieldProps.type === 'range') {
        return this.fieldProps.type.split('_')[1]
      } else if(this.fieldProps.type === 'datetime_iso'){
        return 'datetime'
      }

      return this.fieldProps.type
    },
  },
  created() {
    if(isVoidValue(this.value)) {
      this.setDefaultValue()
    }
  },
  methods: {
    setDefaultValue() {
      if (this.fieldProps.default_value && ['datetime', 'datetime_iso'].includes(this.fieldProps.type)) {
        this.$emit('input', applyShortcodes(this.fieldProps.default_value))
      }
    },
  },
}
</script>
