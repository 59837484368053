<template>
    <div>
        <div class="active-filters">
          <span class="ml-4 mr-5 system-text-primary">{{ translate('Active filters', 'global') }}:</span>
          <template v-for="(filter, filterIndex) in filters">
            <div v-if="filters[filterIndex].value" :key="filterIndex" class="mr-2">
                <span class="tag badge badge-primary">
                    <span class="text-capitalize">{{filter.key}}</span>:
                    <span>{{filters[filterIndex].value}}</span>
                    <span class="remove" @click="removeFilter(filterIndex)"><i class="fas fa-times"></i></span>
                </span>
            </div>
          </template>
        </div>
        <div class="d-flex px-4 filters-container">
            <div class="filter">
                <div class="single-filter" v-for="(filter, filterI) in filters" :key="filterI">
                    <div class="filter-field">
                        <label style="padding-top: 0.2rem">{{ translate(filter.key, 'invoices') }}</label>
                        <input @input="search()" :type="filter.type" step='0.01' style="padding-bottom: 0.2rem" required="" class="form-control" v-model="filters[filterI].value">
                    </div>
                </div>
            </div>

            <div class="input-group-append">
                <a href="#" class="input-group-text bg-transparent" @click="reset()">
                    <i class="fas fa-sync-alt"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InvoiceFilters",
        props: {
            data: {
                required: true
            }
        },
        data() {
            return {
                filters: null
            }
        },
        methods: {
            reset(){
                this.filters.forEach(element => {
                   element.value=null
                })
              this.search()
            },
            search(){
                this.$emit('filter', this.filters)
            },
            removeFilter(i) {
                this.filters[i].value = null
                this.search()
            },
        },
        created() {
            this.filters = [...this.data]
        },
    }
</script>

<style scoped>
    .filter {
        display: flex;
        flex-wrap: wrap;
    }

    .input-group-append {
        align-self: baseline;
        margin-left: auto;
    }

    .input-group-text {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid $border-color;
    }

    .active-filters {
        display: flex;
        padding: 0.6rem 0;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $border-color;
        /*border-top: 1px solid $border-color;*/
    }

    .filters-container {
        margin-bottom: 1.5rem;
    }

    .tag.badge {
        font-size: 15px;
    }

    .remove {
        visibility: hidden;
        opacity: 0;
        font-size: 14px;
        cursor: pointer;
        transition: 0.2s all ease;
    }

    .remove i {
        width: 0;
    }

    .tag.badge:hover > .remove {
        visibility: visible;
        opacity: 1;
        padding-left: 0.5rem;
        transition: 0.2s all ease;
    }

    .tag.badge:hover > .remove i {
        width: auto;
    }
</style>
