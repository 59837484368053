<template>
  <element-settings-content-tabs>
    <element-settings-content-tab :selected="true">
      <div class="row">
        <div v-if="categories" class="col-12">
          <craft-select
            label="Categories"
            :options="categoryOptions"
            :value="getSetting('categories')"
            :clearable="true"
            :multiple="true"
            @input="setSetting('categories', $event)"
          />
        </div>


        <div class="col-12">
          <craft-input
            label="How many items you want to show"
            input-type="number"
            :value="getSetting('number_of_items')"
            @input="setSetting('number_of_items', $event)"
          />
        </div>

        <div class="col-12">
          <craft-select
            label="Store locator type"
            :options="storeLocatorOptions"
            :value="getSetting('store_locator_type')"
            @input="setSetting('store_locator_type', $event)"
          />
        </div>

        <div class="col-12">
          <craft-select
            label="Choose layout"
            :options="layoutOptions"
            :value="getSetting('layout')"
            @input="setSetting('layout', $event)"
          />
        </div>

        <transition name="fade">
          <div v-if="['table'].includes(getSetting('layout'))" class="col-12">
            <label class="label mb-2">{{ 'Number of columns' | translate('categories') }}</label>

            <div class="row">
              <div class="col-6">
                <craft-select
                  v-model="columns['desktop']"
                  label="Desktop"
                  :options="columnOptions"
                />
              </div>
              <div class="col-6">
                <craft-select
                  v-model="columns['tablet_landscape']"
                  label="Tablet (landscape)"
                  :options="columnOptions"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <craft-select
                  v-model="columns['tablet_portrait']"
                  label="Tablet (portrait)"
                  :options="columnOptions"
                />
              </div>
              <div class="col-6">
                <craft-select
                  v-model="columns['mobile']"
                  label="Mobile"
                  :options="columnOptions"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </element-settings-content-tab>
  </element-settings-content-tabs>
</template>


<script>
import ElementSettingsContentTab from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTab.vue"
import ElementSettingsContentTabs from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTabs.vue"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import { CraftInput, CraftSelect, CraftToggle } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: "StoreLocatorSettings",
  components: {
    CraftToggle, CraftInput, CraftSelect, ElementSettingsContentTabs, ElementSettingsContentTab,
  },
  mixins: [CraftSettingsMixin, ComponentSettingsMixin],
  inject: ['node'],
  data() {
    return {
      entity: 'stores',
      categories: null,
      columns: {},
    }
  },
  computed: {
    categoryOptions() {
      return this.categories.reduce((options, { name, slug }) => {
        options.push({
          label: this.entityLabel(name, true),
          key: slug,
        })
        return options
      }, [])
    },

    layoutOptions() {
      return [
        { key: 'table', label: 'Grid' },
        { key: 'list', label: 'List' },
      ]
    },

    storeLocatorOptions() {
      return [
        { key: 'search', label: 'Search only' },
        { key: 'list', label: 'List only' },
        { key: 'search_and_list', label: 'Search and list' },
      ]
    },
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        this.setSetting('columns', this.columns)
      },
    },
  },
  async created() {
    const categories = await this.erp.ext.request.get(
      'modules/categories',
      this.erp.ext
        .query()
        .where('entity', '=', this.entity)
        .where('type', '=', 'categories')
        .toString(),
    )

    this.categories = categories.objectData
  },
  mounted() {
    this.columns = this.getSetting('columns')
  },
}
</script>
