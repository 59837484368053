<template>
    <div class="subscription-index" v-if="!loading">
        <page-title v-if="subscriptions && subscriptions.meta" :page-title="translate('Subscriptions', 'subscriptions')"
					:borderBottom="false" :counter="subscriptions.meta.total" >
            <template v-slot:actions>
                <router-link to="/blockz-order/create/choose-plan" class="btn btn-primary rounded-pill">
                    {{ translate('Add new order', 'subscriptions') }}
                </router-link>
            </template>
        </page-title>
        <div class="row" v-if="subscriptions">
            <div class="col-md-12 mt-2">
                <div class="card">
                    <ul class="nav nav-tabs scope-tabs">
                        <li class="nav-item pt-3"
							:class="{'active': selectedFilter == null}">
                            <a href="javascript:;"
							   class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
							   @click="loadSubscriptions(null)">
                                {{ translate(`All`, 'subscriptions') }}
								<span class="counter ml-4">{{ subscriptions.meta.total }}</span>
                            </a>
                        </li>
                        <li class="nav-item d-flex justify-content-between align-items-center pt-3 px-2"
							v-for="(status, si) in filterByStatus" :class="{'active':
                        selectedFilter == status.key}">
                            <a href="javascript:;" class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
                               @click="loadSubscriptions({key: 'status', value: status.key, condition: '='})">
                                {{ translate(`${status.value}`, 'subscriptions') }}
								<span class="counter ml-4">?</span>
							</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="main-table">
            <div class="d-flex justify-content-between align-items-center table-header px-4">
				<div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
					<div class="search-icon">
						<span><i class="fa fa-search"></i></span>
					</div>
					<input type="text" class="form-control h-100 search-field" :placeholder="translate('Search', subscriptions)"
						   @input="e => loadSubscriptions({key: 'name', value: `%${e.target.value}%`,condition: 'like'})">
				</div>
				<div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
					<div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
						<a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ translate('{count} per page', 'global', { count: tableParams.per_page }) }}
							<i class="fa fa-chevron-down"></i>
						</a>
						<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
							<a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setItemsPerPage(per_page.key)">
								{{per_page.value}}
							</a>
						</div>
					</div>
				</div>
            </div>
            <div class="table-responsive" v-if="subscriptions && !loading">
                <table class="table m-0">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <div class="custom-control custom-checkbox">
                                <input @input="selectAllSubscriptions" :id="'all-subscriptions'"
									   type="checkbox" class="custom-control-input">
                                <label class="custom-control-label" :for="'all-subscriptions'"></label>
                            </div>
                        </th>
                        <th scope="col">{{ translate('subscription', 'subscriptions') }}
							<i class="fa fa-sort" aria-hidden="true"></i>
						</th>
                        <th scope="col">{{ translate('customer_name', 'subscriptions') }}
							<i class="fa fa-sort" aria-hidden="true"></i>
						</th>
                        <th scope="col">{{ translate('price', 'subscriptions') }}
							<i class="fa fa-sort" aria-hidden="true"></i>
						</th>
                        <th scope="col">{{ translate('order_id', 'subscriptions') }}
							<i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th scope="col">{{ translate('date', 'subscriptions') }}
						</th>
                        <th scope="col">{{ translate('status', 'subscriptions') }}
							<i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th class="text-right" scope="col">
							{{ translate('Actions', 'global') }}
						</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="tr" v-for="(subscription, s) in subscriptions.data" :key="s">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input :id="`subscription-` + s" v-model="subscriptions.data[s].selected"
									   type="checkbox" class="custom-control-input">
                                <label :for="`subscription-` + s" class="custom-control-label"></label>
                            </div>
                        </td>
                        <td @click="navigateToShowView(subscription.id)" style="cursor: pointer">{{ subscription.plan.readable_options.name }}</td>
                        <td class="d-flex">
                          <template v-for="ord in subscription.orders">
                            <div v-if="ord.client">
                                <img :src="ord.client.contacts[0].avatar" alt="Avatar" class="avatar">
                                <span class="px-1">{{ ord.client.contacts[0].first_name }} {{ ord.client.contacts[0].last_name }}</span>
                            </div>
                          </template>
                        </td>
                        <td>{{ subscription.price }} {{ translate('Per year', 'subscriptions') }}</td>
                        <td><span v-for="order in subscription.orders">{{ order.order_number }}</span></td>
                        <td>{{ subscription.date }}</td>
                        <td v-if="subscription.status == 1">
							<div class="d-flex justify-content-center">
								<span class="active-sub w-100 text-center">{{ translate('Active', 'subscriptions') }}</span>
							</div>
						</td>
                        <td v-if="subscription.status == 0">
							<div class="d-flex justify-content-center">
								<span class="inactive-sub w-100 text-center">{{ translate('Inactive', 'subscriptions') }}</span>
							</div>
						</td>
                        <td class="text-right">
							<cta>
								<router-link :to="`/subscriptions/${subscription.id}`">
									<action-button class-icon="fa-eye"></action-button>
								</router-link>
							</cta>
						</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="d-flex align-items-center justify-content-center font-weight-700 border-top py-4">
              {{ translate('No data', 'global') }}
            </div>
            <div v-if="loading" class="d-flex justify-content-center m-5">
                <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
            </div>
            <pagination :disabled="loading" :container-class="'d-flex justify-content-between'" @goToPage="goToPage"
                        v-if="subscriptions && subscriptions.meta"
                        :value="subscriptions.meta"></pagination>
        </div>
    </div>
</template>
<script>
import debounce from 'debounce'
import {db, routes} from '@/utilities/database';
import {createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs, urlEncoder} from "@/utilities/helper";
import moment from 'moment'

export default {
    name: "SubscribtionIndex",
    data() {
        return {
            subscriptions: null,
            loading: false,
            tableParams: {
                per_page: 5,
                page: 1
            },
            selectedFilter: null
        }
    },
    methods: {
        goToPage(page) {
            this.tableParams.page = page
            this.loadSubscriptions()
        },
        setItemsPerPage(per_page) {
            this.tableParams.per_page = per_page
            this.loadSubscriptions()
        },
        loadSubscriptions: debounce(async function (filters) {
            this.loading = true;
            this.$store.state.system.isLoading = true;

            let params = null
            if(filters) {
                params = {
                    'filter_criteria': {
                        'filter_groups': {
                            0: {
                                'filters': {
                                    0: filters
                                }
                            }
                        }
                    }
                }
            }

            if (filters && filters.key === 'status') {
                this.selectedFilter = filters.value
                this.subscriptions.data = null
            } else {
                this.selectedFilter = null
            }

            let columns = {column: 'created_at', direction: 'desc', type: 'text'}

            let response = await
                db.get(`${routes.subscriptions.index}?page=${this.tableParams.page}&perpage=${this.tableParams.per_page}&${urlEncoder(columns)}&${urlEncoder(params)}`)

				response.data.data.forEach(sub => {
					sub.plan.readable_options = createEntityFormObjectNoTabs(sub.plan.options, 'key')
					sub.date = moment(sub.contract_start).format('DD MMM YYYY')

					this.subscriptions = response.data
				})

            this.loading = false;
            this.$store.state.system.isLoading = false
        }, 700),
		countSubscriptions() {
        	// db.get('/modules/info/subscription/status').then(res => {
				// console.log(res)
			// })
		},
		selectAllSubscriptions() {
			if (this.subscriptions && this.subscriptions.data) {
				this.subscriptions.data.forEach(sub => {
					this.$set(sub, 'selected' , !sub.selected)
				})
			}
		},
        navigateToShowView(id) {
            this.$router.push({ path: `/subscriptions/${id}`})
        }
    },
    computed: {
        filterByStatus() {
            return [
                {key: 1, value: 'active'},
                {key: 0, value: 'inactive'}
            ]
        },
        perPage() {
            return [
              { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
              { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
              { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
              { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
            ]
        }
    },
    mounted() {
        this.loadSubscriptions()
		this.countSubscriptions()
    }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 320px) {
    #media_wrap {
        flex-wrap: wrap;
    }
}
.subscription-index {
    .nav-tabs .active .link {
        border-bottom: 2px solid red;
        padding-bottom: 10px;
    }

    .nav-tabs li a {
        // color: #000;
		font-size: 16px;
    }

    .main-table {
        margin: 1rem 0;
        border-radius: 5px;
        background-color: #fff;
		box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);

		.table-header {
			height: 60px;

			.search-field {
				border: none;
				font-size: 16px;

				&:focus {
					background: transparent;
					box-shadow: none;
				}
			}
		}

        table {
            border-radius: 5px;

            thead {
                background-color: #fcfcfc;
            }

            tr {
                border-bottom: 1px solid #e5edf5;

                th {
                    border-bottom: 0;
					background-color: #f9fbfd;
					color: #000;
					line-height: 1.8rem;
                    border-color: #e5edf5;

					i {
						color: #95aac9;
					}
                }

                td {
                    background-color: #fff;

                    .avatar {
                        vertical-align: middle;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .to_22, .active_status {
        border: 1px solid #98FB98;
        border-radius: 10px;
        background-color: #98FB98;
        color: #228B22;
    }

    .to_66, .expire_soon {
        border: 1px solid #FFDEAD;
        border-radius: 10px;
        background-color: #FFDEAD;
        color: #F4A460;
    }

    .expired {
        border: 1px solid #FA8072;
        border-radius: 10px;
        background-color: #FA8072;
        color: #FF0000;
    }

    .fa-ellipsis-v {
        cursor: pointer;
		color: #122b3f;
    }
}

.active-sub {
    background-color: #cdf7e5;
    color: #0a6d3e;
    border-radius: 10px;
}

.inactive-sub {
    background-color: #f9d7dd;
    color: #ed7b90;
    border-radius: 10px;
}

.custom-checkbox .custom-control-label:before {
	border-radius: 6px;
}
</style>
