<template>
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex flex-column bg-white border rounded mt-3">
                <div class="d-flex justify-content-between border-bottom p-3">
                    <span class="text">{{ translate('Invoices', 'entities') }}</span>
                </div>
                <div class="table-responsive">
                    <div class="card">
                        <table class="table bg-white" v-if="payments">
                            <thead class="bg-gray-light">
                            <tr>
                                <th class="text-muted">{{ translate('Invoice ID', 'entities') }}</th>
                                <th class="text-muted">{{ translate('Date', 'entities') }}</th>
                                <th class="text-muted">{{ translate('Amount', 'entities') }}</th>
                                <th class="text-muted">{{ translate('Currency', 'entities') }}</th>
                                <th class="text-muted">{{ translate('Status', 'entities') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(payment, pi) in payments.data" :key="pi">
                                <td><router-link :to="`/my-account/${$route.params.id}/billing/${payment.invoice_id}`">{{ payment.invoice_number }}</router-link></td>
                                <td>{{ payment.payment_date }}</td>
                                <td>{{ payment.amount }}</td>
                                <td>{{ payment.currency_code }}</td>
                                <td v-if="payment.status">{{ payment.status }}</td>
                                <td v-else> {{ translate('No status', 'entities') }}
                                  / <span class="text-muted font-italic">{{ translate('Payment must be made', 'entities') }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyPayments",
    data() {
        return {
            payments: null
        }
    },
    async created() {
        this.$store.state.system.isLoading = true
        let payments = await this.erp.ext.request.axiosInstance.get('/modules/financial/payments')
        this.payments = payments.data
        this.$store.state.system.isLoading = false
    }
}
</script>

<style scoped>

</style>
