<template>
    <div>
        <div v-if="order" class="container-fluid">
            <page-title
                :page-title="order.order_number"
                :overview="true"
                :buttons="$route.query.redirectLocation ? `${$route.query.redirectLocation}` : '/webshop-orders'"
            />

                <div class="row">
                    <div class="col-md-12">
                        <div class="order-price d-flex justify-content-center align-items-center flex-column py-3">
                            <span class="order-totals">{{ translate('Totals', 'orders') }}</span>
                            <div class="order-single-price d-flex justify-content-between align-items-center">
                                <span class="order-amount font-weight-bold mr-3"
                                v-html="order.formatted_price_amounts.total_amount">
                                </span>
                            </div>
                            <span v-if="order.tax_amount === '0.00' || order.tax_amount === null" class="text-muted">
								/{{ translate('No VAT included', 'orders') }}
							</span>
                        <span v-else class="text-muted">
                          <span>/ {{ translate('incl', 'orders') }} </span>
                          <span v-html="order.formatted_price_amounts.tax_amount"></span>
                          &nbsp;<span>{{ translate('VAT', 'orders') }}</span>
                        </span>
                        </div>
                    </div>
                </div>
            <div class="row my-4">
                <div class="col-xl-4 col-lg-12 first-column">
                    <div class="col-style">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Order number', 'orders') }}
                            </span>
                            <span class="order-number">{{ order.order_number }}</span>
                        </div>
                    </div>
                    <div class="col-style">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Order date', 'orders') }}
                            </span>
                            <span>{{ formatDate(order.created_at, null, 'DD MMM YY - HH:mm') }}</span>
                        </div>
                    </div>
                    <div class="col-style">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Order status', 'orders') }}
                            </span>
                            <div class="d-flex align-items-center">
                                <div class="nav-item dropdown dropdown-styled dropdown-animate mr-0" data-toggle="hover">
                                    <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        <span class="status-badge" :class="`status_${order.status}`">{{ translate(orderStatusName, 'orders') }} <i class="fa fa-chevron-down align-middle"></i></span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                        <a href="#" class="dropdown-item" v-for="(status, si) in statuses" :key="si" @click="changeStatus(si)">
                                            {{ translate(status, 'orders')}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-style" v-if="order.invoices.length">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Invoice number', 'orders') }}
                            </span>
                            <span class="invoice-number">
                                <a href="javascript:;" @click="downloadInvoice(order.invoices[0])">
                                    {{ order.invoices[0].invoice_number}}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="col-style">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Payment', 'orders') }}
                            </span>
                            <span>{{ order.payment_method }}</span>
                        </div>
                    </div>
                    <div class="col-style">
                        <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                            <span class="system-text-primary font-weight-bold">
                                {{ translate('Shipping method', 'orders') }}
                            </span>
                            <span>{{ order.shipping_method }}</span>
                        </div>
                    </div>

                    <div class="col-style">
                      <div class="col-style-child d-flex justify-content-between align-items-center p-3">
                              <span class="system-text-primary font-weight-bold">
                                  {{ translate('Customer ID', 'orders') }}
                              </span>
                        <span>{{ order.client_id }}</span>
                      </div>
                    </div>

                    <div class="row download-buttons">
                        <div class="col-12">
                            <button class="btn btn-white py-3" @click="download(order, 'download')">
                                {{ translate('Download receipt', 'orders') }}
                            </button>
                        </div>
                        <!-- <div class="col-6">
                            <button class="btn btn-primary py-3" @click="download(order, 'download-picking-list')">
                                {{ translate('Download picking list', 'orders') }}
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary py-3" @click="download(order, 'download-slip-list')">
                                {{ translate('Download slip list', 'orders') }}
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 second-column">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-styling mb-3 bg-white">
                                <span class="_title">{{ translate('Billing address', 'orders') }}</span>
                                <div class="user-col px-4">
                                    <div class="customer-info w-100 mt-2">
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Name', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.billing_client_first_name }}
                                                {{ order.billing_client_last_name }}
                                            </span>
                                        </div>
                                      <!-- Show company details only if filled -->
                                      <div v-if="order.billing_company_name" class="single-info d-flex justify-content-between py-2">
                                        <span>{{ translate('Company name', 'orders') }}</span>
                                        <span class="info-value">{{ order.billing_company_name }}</span>
                                      </div>
                                      <div v-if="order.billing_vat_number" class="single-info d-flex justify-content-between py-2">
                                        <span>{{ translate('VAT Number', 'orders') }}</span>
                                        <span class="info-value">{{ order.billing_vat_number }}</span>
                                      </div>
                                      <div v-if="order.chamber_of_commerce" class="single-info d-flex justify-content-between py-2">
                                        <span>{{ translate('Chamber of commerce', 'orders') }}</span>
                                        <span class="info-value">{{ order.chamber_of_commerce }}</span>
                                      </div>
                                      <!-- END Show company details only if filled -->
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Street name', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_address_address }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Street number', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_address_street_number }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Address line 2', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_address_additional_information }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Zip', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.billing_address_postcode }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('City', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.billing_address_city }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('State/Province', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.billing_address_state }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Country', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_address_country }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Email', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_client_email }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Phone', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_client_phone }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="col-styling mb-3 bg-white">
                                <span class="_title">{{ translate('Shipping address', 'orders') }}</span>
                                <div class="user-col px-4">
                                    <div class="customer-info w-100 mt-2">
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Name', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.shipping_client_first_name }}
                                                {{ order.shipping_client_last_name }}
                                            </span>
                                        </div>
                                        <!-- Show company details only if filled -->
                                        <div v-if="order.shipping_company_name" class="single-info d-flex justify-content-between py-2">
                                          <span>{{ translate('Company name', 'orders') }}</span>
                                          <span class="info-value">{{ order.shipping_company_name }}</span>
                                        </div>
                                        <!-- END Show company details only if filled -->
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Street name', 'orders') }}</span>
                                            <span class="info-value">{{ order.shipping_address_address }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Street number', 'orders') }}</span>
                                            <span class="info-value">{{ order.shipping_address_street_number }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Address line 2', 'orders') }}</span>
                                            <span class="info-value">{{ order.shipping_address_additional_information }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Zip', 'orders') }}</span>
                                            <span class="info-value">
                                                {{ order.shipping_address_postcode }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('City', 'orders') }}</span>
                                            <span class="info-value">
                                                  {{ order.shipping_address_city }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('State/Province', 'orders') }}</span>
                                            <span class="info-value">
                                                  {{ order.shipping_address_state }}
                                            </span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Country', 'orders') }}</span>
                                            <span class="info-value">{{ order.shipping_address_country }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Email', 'orders') }}</span>
                                            <span class="info-value">{{ order.billing_client_email }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Phone', 'orders') }}</span>
                                            <span class="info-value">{{ order.shipping_client_phone }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 third-column">
                    <div class="row">

                      <div class="col-md-12 d-flex item">
                        <div class="col-styling bg-white mb-3" v-if="order.notes">
                          <span class="_title">{{ translate('Notes', 'orders') }}</span>
                          <div class="_body">
                            {{ order.notes }}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 d-flex item" v-if="order.timeline.length">
                        <timeline v-model="order.timeline"></timeline>
                      </div>

                      <div class="col-md-12 d-flex item">
                        <comments v-model="order.comments" @saveComment="addComment"></comments>
                      </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-12 mt-3">
                    <div class="main-table border-0">
                        <div class="table-responsive rounded">
                            <table class="table m-0">
                                <thead class="bg-white">
                                <tr>
                                    <th class="font-weight-bold" scope="col">
                                      {{ translate('SKU', 'orders') }}
                                    </th>
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('Item name', 'orders') }}
                                    </th>
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('Single price', 'orders') }}
                                    </th>
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('Item qty', 'orders') }}
                                    </th>
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('VAT rate', 'orders') }}
                                    </th>
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('VAT amount', 'orders') }}
                                    </th>
                                    <!-- Discount disabled
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('Discount', 'orders') }}
                                    </th>
                                    -->
                                    <th class="font-weight-bold" scope="col">
                                        {{ translate('Total', 'orders') }}
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                  <template v-for="(item, i) in itemsAscending">
                                    <show-order-item :item="item" :key="i"/>
                                    <template v-if="item.item_options">
                                      <show-order-item
                                        v-for="(itemOption, i2) in item.item_options"
                                        :item="itemOption"
                                        :key="i2"/>
                                    </template>
                                  </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                <div class="col-xl-5 offset-xl-7 mt-3">
                    <div class="main-table border-0">
                        <div class="table-responsive rounded">
                            <table class="table m-0 table-order-totals">
                                <tbody>
                                <tr>
                                    <td>
                                        <span class="font-weight-bold">{{ translate('Subtotal excl VAT', 'orders') }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold"
                                              v-html="order.formatted_price_amounts.subtotal_amount_without_vat"
                                        ></span>
                                    </td>
                                </tr>
                                <tr v-if="order.discount_amount_without_vat > 0">
                                    <td>
                                        <span class="font-weight-bold">{{ translate('Discount Amount excl VAT', 'orders') }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold"
                                              v-html="order.formatted_price_amounts.discount_amount_without_vat"
                                        ></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="font-weight-bold">{{ translate('Total VAT', 'orders') }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold"
                                              v-html="order.formatted_price_amounts.tax_amount"
                                        ></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="font-weight-bold">{{ translate('Shipping costs', 'orders') }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold"
                                              v-html="order.formatted_price_amounts.shipping_amount"
                                        ></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="font-weight-bold">{{ translate('Total', 'orders') }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold"
                                              v-html="order.formatted_price_amounts.total_amount"
                                        ></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ShowOrderItem from "./ShowOrderItem.vue"
import Timeline from "../../components/Timeline";
import Comments from "../../components/Comments";
import {downloadFile} from "@/utilities/helper"

export default {
    name: "ShowOrder",
    components: {
      ShowOrderItem,
      Timeline,
      Comments,
    },
    data() {
        return {
            order: null,
            statuses: null,
        }
    },
    computed: {
      itemsAscending(){
        return _.orderBy(this.order.items, ['sku'], ['asc'])
      },
      orderStatusName(){
        // first check if the data is loaded
        if(this.order && this.statuses){
          if(this.order.status && this.statuses[this.order.status]){
            return this.statuses[this.order.status]
          }
          return this.order.status
        }

        return ''
      }
    },
    methods: {
        async getOrder() {
            let order = await this.erp.ext.request.axiosInstance.get(
                `/modules/sales/orders/${this.$route.params.id}`
            )
            this.order = order.data.data
        },
        async getStatuses() {
            // changed on 21.06 todo remove this comment
            // let statuses = await this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/states/${this.order.state}`)
            let statuses = await this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/statuses/${this.order.id}`)
            this.statuses = statuses.data
        },
        download(order, route) {
            this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/${route}/${order.id}`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(res => {
              const filename = `${route.replace('download', '').replace('-', '')}${order.order_number}.pdf`
              downloadFile(res.data, filename)
            })
        },
        downloadInvoice(invoice){
            this.erp.ext.request.axiosInstance.get(`/modules/sales/invoices/download/${invoice.id}`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(res => {
              const filename = `${invoice.invoice_number}.pdf`
              downloadFile(res.data, filename)
            })
        },
        async changeStatus(status) {
            try {
                await this.erp.ext.request.axiosInstance.put(`/modules/sales/orders/statuses/${this.order.id}`, {status: status})
                this.$toast.success({
                  title: 'Order status is changed to {status} successfully!',
                  translateArgs: { status: status },
                })
            } catch (error) {
                this.$toast.error(error.response.data.message)
            }
            await this.getOrder()
            await this.getStatuses()
        },
        async addComment(comment) {
            try {
                await this.erp.ext.request.axiosInstance.post(`/modules/comments/orders/${this.order.id}`, {comment: comment})
                this.$toast.requestSuccess('post', 'Comment')
            } catch (error) {
                this.$toast.error(error.response.data.message)
            }
            await this.getOrder()
        }
    },
    async created() {
        await this.getOrder()
        await this.getStatuses()
    }
}
</script>

<style scoped lang="scss">

.main-table {
    .table-responsive {

        table {
            thead tr {
                border: none;
            }
            thead tr th {
                border-top: none;
                border-bottom: 1px solid $border-color;
            }

            tbody {
                tr {
                    border: none;
                }
                tr td {
                    border-bottom: 1px solid $border-color;
                }

                tr:last-of-type td {
                    border-bottom: none;
                }
            }

            &.table-order-totals {
                tbody {
                    tr {
                        td:first-child {
                            border-right: 1px solid $border-color;
                        }
                        td:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }


    }
}

.order-price {
    background-color: #000;
    border-radius: 5px;

    .order-totals {
        color: #fff;
        text-transform: uppercase;
    }

    .order-single-price {
        .order-amount {
            font-size: 46px;
            color: #fff;
        }
    }
}

.col-style {
    flex-grow: 1;
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    .col-style-child {
        background-color: white;
        border-radius: 5px;
    }

    .invoice-number {
        color: #ff004f;
    }
}

.download-buttons {
    button {
        margin-bottom: 1rem;
        width: 100%;
        border: 1px solid #000;
    }
}

.status-badge {
    width: 100px;
}

@media all and (min-width: 1200px) and (max-width: 1500px) {
    .first-column,
    .second-column {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .third-column {
        flex: 0 0 100%;
        max-width: 100%;

        .item {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
</style>

<style lang="scss">

.col-styling {
  flex: 1;
  border-radius: 5px;

  ._title {
    border-bottom: 1px solid $border-color;
    display: flex;
    padding: .5rem 1.5rem;
  }

  ._body {
    padding: .5rem 1.5rem;
  }

  .user-col {
    .customer-info {
      .single-info {
        border-bottom: 1px solid $border-color;

        span:first-child {
          font-weight: 600;
        }

        .info-value {
          color: #000;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .btn-save-textarea {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
  }
}

</style>