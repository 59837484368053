<template>
    <div class="container" id="user_invoice" v-if="invoice && invoice_sender">
        <page-title :page-title="invoice.invoice_number">
            <template v-slot:actions>
                <a :href="`${invoice.payment_link}`" class="btn btn-success rounded-pill px-4">
                    {{ translate('Pay', 'account') }}
                </a>
            </template>
        </page-title>
        <div class="invoice-alert border" :class="invoice.status">
            <h4 v-if="invoice.status === 'not_sent'">
                {{ translate('This invoice is not sent and invoice is created at', 'account') }}
                {{ invoice.created_at }}
            </h4>
            <h4 v-else>
                {{ translate('This invoice is sent on', 'account') }}
                {{invoice.sent_at}}
            </h4>
            <span v-if="invoice.status === 'sent'">
                {{ translate('And therefore can not be edited or deleted.', 'account') }}
            </span>
        </div>
        <div class="invoice-info border">
            <div class="row">
                <div class="col-md-12 _space">
                    <img :src="invoice_sender.objectData.logo" width="250px" alt="">
                </div>
                <div class="col-md-6">
                    <div class="d-flex flex-column ml-auto">
                        <span class="font-weight-700">{{ invoice_sender.objectData.name }}</span>
                        <span>{{ invoice_sender.objectData.street_number }}</span>
                        <span>{{ invoice_sender.objectData.zip_code }} {{ invoice_sender.objectData.city }}</span>
                        <span>{{ invoice_sender.billing_address_country }}</span>
                        <span class="mt-3">{{ translate('Tel', 'account') }}: {{ invoice_sender.objectData.phone }}</span>
                        <span>{{ translate('Email', 'account') }}: {{ invoice_sender.objectData.email }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex flex-column ml-auto">
                        <span class="font-weight-700">{{ invoice.client_name }}</span>
                        <span>{{ invoice.billing_address_address }}</span>
                        <span>{{ invoice.billing_address_postcode }} {{ invoice.billing_address_city }}</span>
                        <span>{{ invoice.billing_address_country }}</span>
                    </div>
                </div>
                <div class="col-md-3 offset-md-9">
                    <div class="d-flex flex-column ml-auto">
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Order number', 'account') }}:</span>
                            <span>{{ invoice.order.order_number }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Invoice number', 'account') }}:</span>
                            <span>{{ invoice.invoice_number }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Client id', 'account') }}:</span>
                            <span>{{ invoice.order.client_id }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Customer id', 'account') }}:</span>
                            <span>{{ invoice.invoice_date }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Invoice date', 'account') }}:</span>
                            <span>{{ invoice.due_date }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 _space">
                    <div class="d-flex flex-column _container-border">
                        <h3 class="font-weight-700 font-italic">{{ translate('Invoice due', 'account') }}</h3>
                        <span>{{ invoice.client_name }}</span>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr class="border-bottom">
                                <td class="font-weight-700">{{ translate('Invoice', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('QTY', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('Description', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('Unit', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('Price', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('VAT', 'account') }}</td>
                                <td class="font-weight-700">{{ translate('Incl. VAT', 'account') }}</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="border-bottom" v-for="(item, ii) in invoice.items">
                                <td>{{ item.qty }}</td>
                                <td>{{ item.item_name }}</td>
                                <td>{{ item.unit }}</td>
                                <td>{{ invoice.currency_code }} {{ item.single_price_without_vat }}</td>
                                <td>{{ invoice.currency_code }} {{ item.single_price - item.single_price_without_vat }}</td>
                                <td>{{ invoice.currency_code }} {{ item.single_price }}</td>
                                <td>{{ invoice.currency_code }} {{ item.total_amount }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="_container-border mb-2"></div>
                </div>
                <div class="offset-md-9 col-md-3">
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('Total excl VAT', 'account') }}: </span>
                            <span>{{ invoice.currency_code }} {{ invoice.total_amount_without_vat }}</span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="invoice.discount">
                            <span>{{ translate('Discount total', 'account') }}: </span>
                            <span>{{ invoice.currency_code }} {{ invoice.discount_amount }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>{{ translate('VAT total', 'account') }}: </span>
                            <span>{{ invoice.currency_code }} {{ invoice.tax_amount }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="font-weight-700">{{ translate('Total incl. VAT', 'account') }}: </span>
                            <span class="font-weight-700">{{ invoice.currency_code }} {{ invoice.total_amount }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InvoiceShowView",
    data() {
        return {
            invoice: null,
            invoice_sender: null
        }
    },
    async created() {
        this.$store.state.system.isLoading = true
        let invoice = await this.erp.ext.request.axiosInstance
            .get(`/modules/financial/invoices/${this.$route.params.invoiceId}`,
                {
                    headers: {
                        success_url: window.location.origin
                    }
                })
        this.invoice = invoice.data.data
        this.invoice_sender = await this.erp.ext.request.find('companies', this.invoice.company_id)
        this.$store.state.system.isLoading = false
    }
}
</script>

<style scoped lang="scss">
    #user_invoice {
        ._container-border {
            border-bottom: 3px solid rgba(60, 60, 60, 0.60);
        }

        ._space {
            margin-bottom: 7rem;
        }

        .invoice-alert {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1.5rem;
            margin-top: 1rem;
            border-radius: 0.375rem;

            &.not_sent {
                background-color: #f8d7da;
            }

            &.sent {
                background-color: #d4edda;
            }

            h4, span {
                color: #fff;
            }
        }

        .invoice-info {
            margin-top: 1rem;
            background-color: #fff;
            border-radius: 0.375rem;
            padding: 1.5rem;
        }
    }
</style>
