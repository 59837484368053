<template>
	<div class="container">
		<div class="alert alert-danger alert-dismissible fade show" v-if="errors">
			<div v-for="(error, ei) in errors">{{ error[0] }}</div>
			<button type="button" class="close" @click="errors = null"><span>&times;</span></button>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label class="extension-label">{{ translate('Type', 'extensions') }}</label>
					<v-select class="select2-form" @input="resetForm" v-model="form.type"
							  :options="['extension', 'module']"></v-select>
				</div>
			</div>

			<transition-expand>
				<div class="col-md-12" v-show="form.type">
					<div class="form-group">
						<label class="pricing-label">{{ translate('Select module', 'extensions') }}</label>
						<div class="modules-dropdown">
							<v-select v-if="modules && form.type === 'extension'" class="select2-form"
									  v-model="form.parent_module" label="value" @input="e => setExModule(e)"
									  :reduce="value => value.key" :options="modules"></v-select>
							<v-select v-if="modules && form.type === 'module'" class="select2-form"
									  :reduce="value => value.key" v-model="form.module" :options="modules"
									  label="value"></v-select>
						</div>
					</div>
				</div>
			</transition-expand>

			<transition-expand>
				<div class="col-md-12" v-if="form.type === 'module'">
					<div class="form-group">
						<div class="hide-app-store-holder custom-control custom-switch p-2">
							<input type="checkbox" v-model="showParentModule" name="auto_save" id="parent-module"
								   class="custom-control-input" value="1">
							<label for="parent-module" class="custom-control-label">{{ translate('Does your module have parent module', 'extensions') }}</label>
						</div>
					</div>
				</div>
			</transition-expand>

			<transition-expand>
				<div class="col-md-12" v-if="showParentModule === true">
					<div class="form-group">
						<label class="pricing-label">{{ translate('Select parent module', 'extensions') }}</label>
						<div class="modules-dropdown">
							<v-select class="select2-form" v-model="form.parent_module" :reduce="value => value.key"
									  :options="modules" label="value">
							</v-select>
						</div>
					</div>
				</div>
			</transition-expand>

			<transition-expand>
				<div class="col-md-12" v-if="module && form.type === 'extension'">
					<div class="form-group">
						<label class="pricing-label">{{ translate('Select model', 'extensions') }}</label>
						<div class="modules-dropdown">
							<v-select class="select2-form" v-model="form.model"
									  @input="e => getModelPremiumComponents(e)" :reduce="value => value.key"
									  :options="module" label="value"></v-select>
						</div>
					</div>
				</div>
			</transition-expand>
			<transition-expand>
				<div class="col-md-12" v-if="premiumComponents && form.type === 'extension'">
					<div class="form-group">
						<label class="pricing-label">{{ translate('Select premium component', 'extensions') }}</label>
						<div class="modules-dropdown">
							<v-select class="select2-form" @input="v => generateSlug(v)" :reduce="value => value.key"
									  :options="premiumComponents" label="value"></v-select>
						</div>
					</div>
				</div>
			</transition-expand>
			<div class="col-md-12">
				<div class="form-group">
					<label class="extension-label">{{ translate('Extension or Module name', 'extensions') }}</label>
					<input v-if="form.type === 'module'" @input="generateSlug" v-model="form.name"
						   class="create-plan-input form-control">
					<input v-else v-model="form.name" class="create-plan-input form-control">
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label class="extension-label">{{ translate('Slug', 'extensions') }}</label>
					<input class="form-control" :disabled="form.type === 'extension'" v-model="form.slug">
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<div class="d-flex flex-column">
						<label class="extension-label">{{ translate('Description', 'extensions') }}</label>
						<label class="text-secondary-color">{{ translate('This is how others will learn about the project, so make it good!', 'extensions') }}</label>
					</div>
					<div id="editor">
						<text-editor-inline v-model="form.description" />
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label class="extension-label">{{ translate('From', 'extensions') }}</label>
					<date-picker type="date" v-model="form.sale_price_from" valueType="format" :input-class="['form-control']"></date-picker>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label class="extension-label">{{ translate('To', 'extensions') }}</label>
					<date-picker type="date" v-model="form.sale_price_until" valueType="format" :input-class="['form-control']"></date-picker>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label class="extension-label">{{ translate('Price', 'extensions') }}</label>
					<div class="input-group mb-3">
						<input v-model="form.price" class="form-control" type="text">
						<div class="input-group-append">
							<span class="input-group-text">{{ form.currency }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12" v-if="vatOptions">
				<div class="form-group">
					<label class="pricing-label">{{ translate('VAT', 'extensions') }}</label>
					<div class="vat-dropdown">
						<v-select class="select2-form" v-model="form.vat"
								  :reduce="value => value.percentage_rate"
								  label="identifier"
								  :options="vatOptions"></v-select>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label class="pricing-label">{{ translate('Enviorment', 'extensions') }}</label>
					<div class="vat-dropdown">
						<v-select class="text-capitalize"
								  :reduce="value => value.key"
								  :options="environment"
								  label="value"
								  v-model="form.env">
						</v-select>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="avatar-picker py-4">
					<label class="extension-label mb-0">{{ translate('Extension image', 'extensions') }}</label>
					<span class="d-block text-muted">{{ translate('Please use an image....', 'extensions') }}</span>
					<div class="">
						<div class="image-holder m-3"
							 :class="{'active-border': form.logo == '' || form.logo == null}">
							<div v-if="!form.logo" class="d-flex justify-content-center p-2 my-4">
                        <span @click="$fileManager.$emit('open', 'logo')" id="drop_file"
							  class="d-flex justify-content-center py-4 px-5">
                            {{ translate('Click here to upload files', 'extensions') }}
                        </span>
							</div>
							<img v-else :src="form.logo">
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="basics-actions d-flex justify-content-between my-3 py-5">
					<div class="hide-appstore d-flex flex-column w-50">
						<label class="extension-label">{{ translate('Hide in appstore', 'extensions') }}</label>
						<label class="text-secondary-color">{{ translate('Hide the extension in the appstore', 'extensions') }}</label>
						<div class="hide-app-store-holder custom-control custom-switch px-2">
							<input type="checkbox" name="auto_save" id="autosave"
								   class="custom-control-input" value="1">
							<label for="autosave" class="custom-control-label"></label>
						</div>
					</div>
					<div class="warning w-50">
						<div class="content w-100 p-4">
							<div>
								<span><i class="fas fa-exclamation-triangle"></i></span>
								<span class="font-weight-bold">{{ translate('Warning', 'extensions') }}</span>
							</div>
							<span class="text-secondary-color">{{ translate('The product is only hidden in the appstore. Current subscriptions will still be active.', 'extensions') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="basics-footer d-flex justify-content-between my-2">
			<router-link to="/extensions" class="btn btn-white rounded-pill px-5">{{ translate('Cancel', 'extensions') }}</router-link>
			<button class="btn btn-primary rounded-pill px-5" @click="saveRecord">{{ translate('Confirm creation', 'extensions') }}</button>
		</div>
	</div>
</template>

<script>
import {db, routes} from "@/utilities/database";
import DatePicker from 'vue2-datepicker';
import {extension} from './extension';
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
	name: "CreateExtension",
	components: {
		TextEditorInline, DatePicker
	},
	data() {
		return {
			// Show parent module
			showParentModule: false,

			// Requests
			vatOptions: null,
			modules: null,
			premiumComponents: null,
			module: null,
			environment: [],
			// sale_modules: null,

			// Data for post request
			form: {
				type: null,
				module: null,
				parent_module: null,
				model: null,
				slug: null,
				name: null,
				description: null,
				sale_price_from: null,
				sale_price_until: null,
				recurring: 1,
				period: 12,
				price: null,
				currency: 'EUR',
				vat: null,
				logo: null
			},

			// errors
			errors: null
		}
	},
	// async mounted() {
		// const vatOptions = await db.get('/modules/dropdowns/TaxRule?perpage=9999')
		// this.vatOptions = vatOptions.data.data
		//
		// const modules = await db.get(`/modules/blockz/app-extensions/modules-models`)
		// this.modules = modules.data

		// const sale_modules = await db.get(`/modules/blockz/app-extensions`)
		// this.sale_modules = [...sale_modules.data.data].map(row => {
		// 	const optionsObject = row.options.optionsToObject()
		// 	optionsObject.id = row.id
		// 	return optionsObject
		// })
	// },
	mounted() {
		db.get('/modules/blockz/app-extensions/modules-models').then(res => {
			this.modules = res.data
		})

		db.get('/modules/tax-rules').then(res => {
			this.vatOptions = res.data.data
		})

		db.get(`modules/get-system-environments`).then(res => {
			this.environment = res.data
		})
	},
	created() {
		this.$fileManager.$on('selected_logo', function (images) {
			this.form.logo = images[0]
		}.bind(this))
	},
	methods: {
		generateSlug(v) {
			if (this.form.type === 'extension') {
				this.form.slug = v
			} else {
				this.form.slug = `${this.form.type}-${this.form.name.toLowerCase().split(' ').join('-')}-${Math.floor(Math.random() * Math.floor(1000))}`
			}
		},
		resetForm() {
			this.form.module = null
			this.form.parent_module = null
			this.form.model = null
			this.form.slug = null
			this.form.price = null
			this.form.vat = null
			this.showParentModule = false
		},

		async setExModule(e) {
			const res = await db.get(`/modules/blockz/app-extensions/modules-models/${e}`)
			this.module = res.data
		},

		async getModelPremiumComponents(e) {
			const res = await db.get(`/modules/blockz/app-extensions/modules-models/${this.form.model}/${e}`)
			this.premiumComponents = res.data
		},

		saveRecord() {
			db.post(`/modules/blockz/app-extensions`, {
				type: this.form.type,
				module: this.form.module,
				parent_module: this.form.parent_module,
				model: this.form.model,
				slug: this.form.slug,
				name: this.form.name,
				description: this.form.description,
				sale_price_from: this.form.sale_price_from,
				sale_price_until: this.form.sale_price_until,
				recurring: this.form.recurring,
				period: this.form.period,
				price: this.form.price,
				currency: this.form.currency,
				vat: this.form.vat,
				logo: this.form.logo

			}).then(res => {
        this.$toast.requestSuccess('post')
				this.$router.push('/extensions-finished')
			}).catch((err) => {
        this.$toast.errorGeneral()
				this.errors = err.response.data.errors // todo use custom this errors instead
			})
		}
	}
}
</script>

<style lang="scss">
.create-plan-input {
	border: 1px solid #000;
	background-color: #fff;
}

#editor {
	.quillWrapper {
		border-radius: 5px;
		border: 1px solid #000;

		.ql-toolbar.ql-snow {
			background-color: #fff;
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: 1px solid;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		#quill-container {
			background-color: #fff;
			height: 100px;
			border: none;
		}
	}
}

.extension-label {
	font-size: 16px;
}

.extension-value {
	border: 1px solid;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.modules-dropdown {
	height: 41px;

	.v-select .vs__dropdown-toggle {
		height: 100%;
		border-color: #000;
	}
}

.image-holder {
    border-radius: .375rem;
    cursor: pointer;

    &.active-border {
        border: 1px dotted #000;
	}

	img {
		width: 100%;
	}
}

.text-secondary-color {
	color: #444;
}
</style>
