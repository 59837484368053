<template>
  <div class="cms-block cms-block-gallery">
    <ul :id="galleryId">
      <li v-for="item in getSetting('items')" :key="item.position">
        <a :href="replaceExtension(item.src, 'webp')" :data-lightbox="'lb-'+galleryId">
          <picture>
            <source type="image/webp" :srcset="replaceExtension(item.src, 'webp')">
            <img :src="item.src" :alt="item.alt" :title="item.title" loading="lazy"/>
          </picture>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import {replaceExtension} from "@/utilities/formatters";

export default {
  name: 'GalleryElement',
  mixins: [
    CraftSettingsMixin,
  ],
  inject: ['node'],
  craft: {
    defaultProps: {
      settings: {
        items: [
          { src: '/uploads/defaults/cms-block-gallery/1.png', position: 0, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/2.png', position: 1, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/3.png', position: 2, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/4.png', position: 3, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/5.png', position: 4, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/6.png', position: 5, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/7.png', position: 6, alt: '', title: '' },
          { src: '/uploads/defaults/cms-block-gallery/8.png', position: 7, alt: '', title: '' },
        ],
        items_per_row: '4',
        gap: '20px',
      },
    },
  },
  data(){
    return {
      itemsPerRowMap: {
        1: 12,
        2: 6,
        3: 4,
        4: 3,
        6: 2,
        12: 1,
      },
    }
  },

  computed: {
    galleryId(){
      return 'gallery-'+this.node.uuid
    },
  },
  methods: {
    replaceExtension,
  },
}
</script>