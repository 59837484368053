<template>
  <div v-if="entity && entity.objectData" class="container-fluid">
    <div v-if="!$store.state.layout.preview">
      <advanced-builder />
      <div v-if="!isAdvancedBuilder" class="container py-5">
        <post-builder v-model="entity.objectData"/>
      </div>
    </div>
    <template v-else>
      <page-preview v-if="['cms-pages'].includes(builder.meta.entity)"/>
      <section-preview v-if="['cms-sections', 'cms-templates'].includes(builder.meta.entity)"/>
      <post-preview v-if="['blogs', 'portfolios'].includes(builder.meta.entity)"/>
      <!-- TODO preview cms-sections and cms-templates -->
    </template>
  </div>
</template>

<script>
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import EntityCrudMixin from '@/modules/pagebuilder/mixins/EntityCrudMixin'

import AdvancedBuilder from './AdvancedBuilder.vue'
import PostBuilder from './PostBuilder.vue'

import uniqid from 'uniqid'
import { mapState } from 'vuex'

import PagePreview from "./preview/PagePreview.vue"
import PostPreview from "./preview/PostPreview.vue"
import SectionPreview from "./preview/SectionPreview.vue"

export default {
  name: 'Create',
  components: { AdvancedBuilder, PostBuilder, PagePreview, PostPreview, SectionPreview },
  mixins: [BuilderMixin, EntityCrudMixin],
  props: {
    // used to pass content on create translation entity
    contentJson: {
      required: false,
    },
  },
  computed: {
    defaultData() {

      let data = {}
      if(['cms-pages', 'blogs', 'portfolios', 'stores'].includes(this.builder.meta.entity)){

        data = {
          website_id: null,
          locale: null,

          name: '',
          content_json: this.contentJson || '',
          content_html: '',

          slug: uniqid(),
          url_key: '', // it was uniqid(), in blogs
          status: '0',

          meta_title: '',
          meta_description: '',
          meta_keywords: '',
          meta_revisit: 7,
          meta_enable_robots_crawl: 'index,follow',
        }
      }

      // Pages
      if(this.builder.meta.entity === 'cms-pages'){
        return {
          ...data,
          name: '',
        }

      // Sections
      } else if(this.builder.meta.entity === 'cms-section'){
        return {
          locale: null,
          website_id: null,
          content_json: this.contentJson || '',
          content_html: '',
          name: '',
          status: '0',
          slug: 'footer', // default slug,
        }

      // Blogs
      } else if(this.builder.meta.entity === 'blogs'){
        return {
          ...data,
          image: null,
          content: '',
          author: null,
          advanced_builder: 0,
          layout: 'boxed',
          publish_at: '',
        }

      // Portfolios
      } else if(this.builder.meta.entity === 'portfolios'){
        return {
          ...data,
          image: null,
          url_key: uniqid(),
          content: '',
          categories: [],
          advanced_builder: 0,
          layout: 'boxed',
          publish_at: '',
        }

      // Sliders
      } else if(this.builder.meta.entity === 'sliders'){
        return {
          images: [],
          name: '',
          items: 1,
          mouse_drag: false,
          navigation: false,
          slider_pagination: false,
        }
      } else if(this.builder.meta.entity === 'cms-templates'){
        return {
          website_id: null,
          name: '',
          content_json: '',
          content_html: '',
          slug: uniqid(),
          preview_image: '',
          cms_block_id: null,
          user_id: this.$store.state.system.authData.user_id,
        }
      } else if (this.builder.meta.entity === 'stores') {
        return {
          ...data,
          images: null,
          content: '',
          advanced_builder: 0,
          layout: 'boxed',
          opening_times: {},
        }
      }

      return data
    },
  },
}
</script>