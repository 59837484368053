<template>
  <!-- TEMPLATE IS IDENTICAL TO InputTypes.vue -->
    <div class="list-group">
        <div class="list-group-item bg-primary text-white border-0">
            <div class="row align-items-center">
                <h6 class="system-text-primary">{{ translate('Widgets', 'entities') }}</h6>
            </div>
        </div>
        <div class="list-group-item" v-for="(widget, wi) in widgets" :key="wi">
            <div class="row align-items-center">
                <div class="col ml-n2">
                    <p class="card-text text-sm mb-0">{{ translate(widget.toLowerCase() + 's', 'entities') }}</p>
                </div>
                <div class="col-auto">
                    <button type="button" @click="$emit('add', widget)" class="btn btn-xs btn-secondary btn-icon">
                        <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
                        <span class="btn-inner--text">{{ translate('Add', 'entities') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: "Widgets",
        data() {
            return {

            }
        },
        computed: {
            ...mapState('system', ['widgets'])
        }
    }
</script>

<style scoped>

</style>
