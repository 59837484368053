<template>
    <div class="appointment-modal">
        <div class="modal-default">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="text-dark">{{ translate('Make appointment', 'agenda') }}</h5>
                    <i class="fas fa-times close-modal" @click="$modal.hide('appointment_modal')"></i>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Make appointment', 'agenda') }}</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Meeting or call?', 'agenda') }}</label>
                            <v-select class="meeting-select" :options="['meeting', 'call']" ></v-select>
                        </div>
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Assign people', 'agenda') }}</label>
                            <input readonly class="form-control" @click="$modal.show('contact_modal')">
                        </div>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Date', 'agenda') }}</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ translate('Time', 'agenda') }}</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Duration', 'agenda') }}</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-12 text-left">
                            <label class="form-control-label">{{ translate('Description', 'agenda') }}</label>
                            <textarea type="text" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppointmentModal"
    }
</script>

<style lang="scss">
    .appointment-modal {
        .modal-content {
            .modal-header {
                border-bottom: 1px solid $primary !important;

                .close-modal {
                    cursor: pointer;
                    color: #A3ADBD;
                }
            }

            .modal-body {
                label {
                    margin-bottom: 0.25rem;
                }

                .meeting-select .vs__dropdown-toggle {
                    padding: 11px 0 11px 10px;
                }
            }
        }
    }
</style>