<template>
  <component is="style" v-if="stylesComputed" type="text/css">
    {{ stylesComputed }}
  </component>
</template>

<script>
import StylesMap from "@/modules/pagebuilder/components/components/styles/index"

export default {
  name: "BuilderStyles",
  inject: ['editor'],
  computed: {
    stylesComputed() {
      const componentStyles = (node, r = []) => {
        if (node) {
          r.push(this.responsiveStyles(node))

          if (node.componentName in StylesMap) {
            r.push(StylesMap[node.componentName](node))
          }

          if (node.props.component in StylesMap) {
            r.push(StylesMap[node.props.component](node.props))
          }

          if (node.children && node.children.length) {
            node.children.forEach(c => r.push(...componentStyles(c)))
          }

        }

        return r
      }

      const styles = componentStyles(this.editor.nodes[0])

      return styles.filter(Boolean).join(" ")
    },
  },
  methods: {
    responsiveStyles(node) {
      const responsiveStyles = node.props?.elementStyle
      let styles = ''

      if (responsiveStyles) {
        const devicesOrder = ['desktop', 'tablet', 'mobile']

        devicesOrder.forEach(device => {
          if (device in responsiveStyles) {
            let deviceStyles = ''

            for (const [property, value] of Object.entries(responsiveStyles[device])) {
              if (value) {
                deviceStyles += `${property}: ${value};`
              }
            }

            if (device === 'desktop') {
              // Apply desktop styles directly without wrapping in a media query
              styles += `[data-uuid="${node.uuid}"] { ${deviceStyles} }\n`
            } else if (deviceStyles) {
              // Wrap non-desktop styles in a media query
              let mediaQuery = ''

              if (device === 'mobile') {
                mediaQuery = `@media (max-width: 767px)`
              } else if (device === 'tablet') {
                mediaQuery = `@media (max-width: 1199px)`
              }

              styles += `${mediaQuery} { [data-uuid="${node.uuid}"] { ${deviceStyles} } }\n`
            }
          }
        })
      }

      return styles
    },
  },
}
</script>
