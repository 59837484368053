<template>
  <div class="w-1 h-1 bg-primary">
      show event
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>