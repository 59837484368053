<template>
  <div class="row">
    <div class="col-12">
      <p>{{ translate('This tool helps to regenerate the html output of all cms-content entities.', 'global') }}</p>
    </div>

    <div class="col-6" v-if="companiesOptions">
      <ee-form-group
        :label="translate('Companies', 'global')">
        <select-static-field
          :field-props="{ type: 'multiselect', data: companiesOptions }"
          :placeholder="translate('All companies', 'global')"
          v-model="companies"/>
      </ee-form-group>
    </div>

    <div class="col-6" v-if="entitiesOptions">
      <ee-form-group
        :label="translate('Entities', 'global')">
        <select-static-field
          :field-props="{ type: 'multiselect', data: entitiesOptions }"
          :placeholder="translate('All entities', 'global')"
          v-model="entities"/>
      </ee-form-group>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-primary rounded-pill" @click="generate" :disabled="generateInProgress">
        {{ translate('Generate', 'global') }}
      </button>
    </div>

    <div class="col-12" v-if="generateInProgress">
      <h6 v-if="regeneratedCompaniesText">{{ regeneratedCompaniesText }} companies regenerated <div class="loader-dots"></div></h6>
      <h6 v-if="regeneratedWebsitesText">{{ regeneratedWebsitesText }} websites regenerated <div class="loader-dots"></div></h6>
    </div>

    <div class="d-none">
      <Editor component='div' :resolverMap="resolverMap" ref="editor">
        <Frame component="div" class="preview-panel pb-wrapper" id="app" :key="renderKeyCraft"/>
      </Editor>
    </div>

  </div>
</template>

<script>
import EeFormGroup from "@/modules/erp_entities/components/forms/FormGroup.vue"
import SelectStaticField from "@/modules/erp_entities/components/page/form/fields/components/SelectStaticField.vue"

import {
  Canvas, Editor, Frame,
} from "@/modules/pagebuilder/craft"
import BuilderElements from "@/modules/pagebuilder/components/components/elements"

export default {
  name: "RegenerateHtml",
  inject: ['editor'],
  components: { SelectStaticField, EeFormGroup, Editor, Frame },
  data() {
    return {
      resolverMap: {
        ...BuilderElements,
        Canvas,
      },
      renderKey: 0,
      companies: [],
      entities: [],

      companiesOptions: null,
      entitiesOptions: null,

      generateInProgress: false,
      companiesToRegenerate: 0,
      websitesToRegenerate: 0,
      regeneratedCompanies: 0,
      regeneratedWebsites: 0,
    }
  },
  computed: {
    renderKeyCraft(){
      return 'craft-'+this.renderKey
    },
    regeneratedCompaniesText() {
      if(this.companiesToRegenerate > 0){
        return this.regeneratedCompanies + '/' + this.companiesToRegenerate
      }
      return ''
    },
    regeneratedWebsitesText() {
      if(this.websitesToRegenerate > 0){
        return this.regeneratedWebsites + '/' + this.websitesToRegenerate
      }
      return ''
    },
  },
  methods: {
    async generate() {

      const headers_common = { ...this.erp.ext.request.axiosInstance.defaults.headers.common } // copy of the object

      const confirmResult = await this.$alert.confirm('Are you sure? It will regenerate and overwrite all html content for selected companies and entities.')

      if (confirmResult.isConfirmed) {
        this.$store.state.system.isLoading = true

        this.erp.ext.request.axiosInstance.defaults.headers.common.env = 'skitle'

        this.regeneratedCompanies = 0
        this.generateInProgress = true
        let entitiesProcessed = 0

        const selected_companies = this.companies.length ? this.companies : this.companiesOptions.reduce((acc, obj) => {
          acc.push(obj.key)
          return acc
        }, [])

        const selected_entities = this.entities.length ? this.entities : this.entitiesOptions.reduce((acc, obj) => {
          acc.push(obj.key)
          return acc
        }, [])

        this.companiesToRegenerate = selected_companies.length

        /*
         * For each company
         */
        for await (const company of selected_companies) {
          this.erp.ext.request.axiosInstance.defaults.headers.common.company = company

          let filterQuery = this.erp.ext.query()
          filterQuery.set('perpage', 9999)
          filterQuery.set('page', 1)
          //filterQuery.where('company', 'in', [company]) not needed anymore, we scope it with headers

          const websites = await this.erp.ext.request.axiosInstance.get(`/modules/dropdowns/CmsWebsite?${filterQuery.toString()}`)
          if(websites.data.data.length > 0) {
            this.regeneratedWebsites = 0
            this.websitesToRegenerate = websites.data.data.length

            /*
             * For each website
             */
            for await (const website of websites.data.data.map(obj => obj.key)) {
              this.erp.ext.request.axiosInstance.defaults.headers.common.cmswebsite = website

              /*
               * For each entity
               */
              for await (const entity of selected_entities) {

                let filterQuery = this.erp.ext.query()
                filterQuery.set('perpage', 99999)
                filterQuery.set('page', 1)
                // filterQuery.where('website', '=', website) // not needed anymore, we scope it with headers

                if(['blogs', 'portfolios'].includes(entity)){
                  filterQuery.where('advanced_builder', '=', 1)
                }
                let objects = await this.erp.ext.request.axiosInstance.get(`/modules/${entity}?${filterQuery.toString()}`)
                for await (const obj of objects.data.data) {
                  await this.regenerateHtml(entity, obj)
                  entitiesProcessed++
                }
              }

              this.regeneratedWebsites++
            }
          }
          this.websitesToRegenerate = 0

          this.regeneratedCompanies++
        }

        const entity_or_entities = entitiesProcessed === 1 ? this.translate('entity', 'global') : this.translate('entities', 'global')
        this.$toast.success({
          title: '{count} {entity} generated successfully!',
          translateArgs: { count: entitiesProcessed, entity: entity_or_entities },
        })

        this.companiesToRegenerate = 0

        // return back the old common headers
        this.erp.ext.request.axiosInstance.defaults.headers.common = headers_common

        this.generateInProgress = false
        this.$store.state.system.isLoading = false
      }
    },

    async regenerateHtml(entity, obj) {
      let options = obj.options.optionsToObject()

      if(options.content_json) { // skip empty pages
        await this.$refs.editor.editor.import(options.content_json)
        this.renderKey++
        await this.$nextTick()

        options.content_html = await this.$refs.editor.editor.exportAsHtml()

        try {
          await this.erp.ext.request.axiosInstance.put(`modules/${entity}/${obj.id}`, { options: options })
        } catch (error) {
          console.error('Failed to generate '+entity+' with ID: '+obj.id)
          console.log(error.response.data.errors)
        }
      }

    },
  },
  async created() {
    let filterQuery = this.erp.ext.query()
    filterQuery.set('perpage', 999999)
    filterQuery.set('page', 1)

    const result = await this.erp.ext.request.axiosInstance.get(`modules/dropdowns/Company?${filterQuery.toString()}`)
    this.companiesOptions = result.data.data.sort((a,b)=>a.key-b.key)

    this.entitiesOptions = [
      { key: 'cms-pages', value: 'CMS Page' },
      { key: 'cms-sections', value: 'CMS Section' },
      { key: 'blogs', value: 'Blog posts' },
      { key: 'portfolios', value: 'Portfolio posts' },
    ]

    // todo automatic (waiting on API fix)
    /*
    let filterQuery = this.erp.ext.query()
    filterQuery.where('model', '=', 'CmsContent')

    this.erp.ext.request.axiosInstance.get('/entities', { params: { 'perpage': 'all', 'model': 'CmsContent' } }).then(res => {
    //this.erp.ext.request.axiosInstance.get(`/entities?${filterQuery.toString()}`, { params: { 'perpage': 9999 } }).then(res => {
      res.data.data.forEach(entity => {
        console.log('entity')
        console.log(entity)
      })
    })
    */
  },
  mounted() {
    this.$refs.editor.editor.disable()
  },
}
</script>

<style lang="scss">
  .loader-dots {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;

    &:after {
      content: ".";
      position: absolute;
      top: 0;
      left: 0;
      animation: loader 1.5s infinite;
    }

  }

  @keyframes loader {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
</style>