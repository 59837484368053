import types from './types'

/* NOT USED ANYMORE, RETURN BACK IF NEEDED
export function createValidationObjectFromExternalData(properties){
  let validation = { form: [] }

  for (let propIndex in properties) {
    validation.form[propIndex] = {}

    for (let rule of properties[propIndex].rules) {
      if (!validation.form[propIndex]['value']) {
        validation.form[propIndex]['value'] = {}
      }

      validation.form[propIndex]['value'][rule.rule] = types[rule.rule].validation
    }
  }

  return validation
}
*/

function createValidationMessage(message, rule){
  if (rule.value) {
    // if you need multi values support update the code to work with the new format
    /*
    if (typeof rule.valueType === true) {
      message = message.replace(':v', rule.value)
    } else {
      for (const validation in rule.value) {
        message = message.replace(':v', rule.value[validation])
      }
    }*/
    // just one value
    message = message.replace(':v', rule.value)
  }

  return message
}

/**
 *
 *
 * @export
 * @param {*} [value]
 * @param {*} [rules=[]]
 */
export function validate(value, rules = []){
  let validations = {}

  if(rules) {
    rules.forEach(rule => {
      // convert string to object (in settings rule is not an object)
      if(typeof rule !== "object"){
        rule = { rule: rule }
      }

      if (types[rule.rule]) {
        let ruleValidation = types[rule.rule].validation(value, rule.value)
        if(!ruleValidation) {
          validations[rule.rule] = createValidationMessage(types[rule.rule].message, rule)
        }
      }
    })
  }
  return validations
}