<template>
  <div class="picker-component" :id="uuid" :class="isReadonly ? 'readonly' : ''">
    <span class="color-picker" :class="{'color-picker-with-input': !simple }" @click="!isReadonly ? togglePicker() : null">
        <span v-if="icon == 'text'" :style="{'background-color': value}" class="color-picker-preview"></span>
        <template v-else>
          <i class="icon-erp-ri-mark-pen-line" style="font-size: 18px;"></i>
          <div
            style="width: 100%; height: 2px;"
            :style="{'background-color': value || '#000'}"
          ></div>
        </template>

        <input
          v-if="!simple"
          class="mx-2 choose-color-text"
          v-model="value"
          :readonly="isReadonly"
          :placeholder="placeholder || translate('Choose color', 'entities')"
        >
    </span>
    <color
        v-if="show"
        :value="value || ''"
        @input="style => !isReadonly ? setStyle(style) : null"
    />
  </div>
</template>

<script>
import {Chrome} from 'vue-color'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EeFormColorPicker',
  components: {
    color: Chrome,
  },
  data() {
    return {
      show: false,
      uuid: uuidv4().split('-')[0],
    }
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
    },
    icon: {
      required: false,
      type: String,
      default: 'text',
    },
    placeholder: {
      required: false,
      type: String,
    },
  },
  methods: {
    togglePicker(e) {
      this.show = !this.show;
    },
    // does not work in the pagebuilder
    closePicker(e) {
      let clickedElement = e.target;
      let colorPickerEl = document.getElementById(this.uuid)

      let hasClass = clickedElement.getAttribute('class') && clickedElement.getAttribute('class').indexOf('vc-') !== -1;
      if(hasClass){
        return false;
      }

      if(!clickedElement || !colorPickerEl) {
        this.show = false;
      }

      if (!this.isDescendant(colorPickerEl, clickedElement)) {
        this.show = false;
      }
    },

    isDescendant(parent, child) {
      var node = child.parentNode;

      while (node != null) {
        if (node == parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    setStyle(style) {
      this.value = style.a !== 1 ? style.hex8 : style.hex
    },
  },
  async mounted() {
    await document.addEventListener('click', (e) => {
      this.closePicker(e);
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.togglePicker);
  },
  watch: {
    value: function(newVal) {
      this.$emit('input', newVal)
    },
  },
}
</script>