<template>
  <!-- DEPRECATED, use input-field instead -->
  <input-field
    v-model="model"
    :field-props="{ ...fieldProps, type: type }"
    :placeholder="placeholder"
    :entity="entity"
  />
</template>

<script>
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: "EeFormInput",
  components: {
    InputField,
  },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: false,
      default: {},
    },
    type: {
      required: false,
      default: 'text',
    },

    placeholder: {
      required: false,
      default: '',
    },
    entity: {
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

