<template>
  <div>
    <div class="d-flex">
      <div style="display: flex; width: 100%;">
        <span class="float-left">
          <avatar upload="true" :avatar-image="authData.user.avatarUrl"></avatar>
        </span>
        <select v-model="user" style="width:7rem; cursor: pointer;">
          <option v-for="(name, isIndex) in tracks.users" :key="isIndex" :value="name" style="cursor: pointer;">{{name}} </option>
        </select>
        <div style="padding: 13px;color: white; margin-left: auto;">
         <span> {{ translate('Total Hours', 'project') }} </span>
         <span style="margin-left: 10px; font-size: 17px" > {{sumTime.viewTime}} / {{user=="Allusers"?(workHours*(tracks.users.length-1)):workHours}}{{ translate('h', 'project') }} </span>
        </div>
        <button type="button"  style="background-color: blue; color: white;" @click="$modal.show('logTime')">
                      {{ translate('Log Time', 'project') }}
        </button>
      </div>
    </div>

    <div
      class="d-flex flex-row flex-nowrap"
      style="padding: 11px 20px 12px; border-bottom: 1px solid #ccc;"
    >
      <div class="inline-block" style="padding: 13px;">
        <button @click="substractMonth">
          <span>
            <i class="fas fa-chevron-left"></i>
          </span>
        </button>
        <button>
          <span>
            <i class="fas fa-calendar-alt"></i>
          </span>
          <span class="pl-2">1/{{month}} - {{monthDays}}/{{month}}</span>
        </button>
        <button @click="addMonth">
          <span>
            <i class="fas fa-chevron-right"></i>
          </span>
        </button>
      </div>
      <div style="padding: 13px;color: white;">
         <span style="margin-left: 10px; font-size: 17px" > {{sumTime.viewTime}} {{ translate('of', 'project') }} {{user=="Allusers"?(workHours*(tracks.users.length-1)):workHours}}{{ translate('h', 'project') }} </span>
      </div>
    </div>

    <div>
      <table style="color: #fff">
        <thead>
          <tr style="border-bottom: 1px solid;">
            <th style="width: 25%; height: 40px; line-height: 40px;">{{ translate('Issue', 'project') }}</th>
            <th style="width: 15%; height: 40px; line-height: 40px; pading-left: 10px;">{{ translate('Key', 'project') }}</th>
            <th
              style="width: 50px; height: 40px; line-height: 40px; text-align: right; padding-right: 10px; white-space: nowrap;"
            >Σ</th>
            <th class="d-flex">
              <div
                class="d-flex flex-column text-center"

                v-for="(day,dayIndex) in days"
                style=" height: 40px; width: 50px; line-height: 40px; border-left: 1px solid;"
                :style="{'background-color' : day.dayOff==true ? 'red' : 'blue'}"
                :key="dayIndex"
              >
                <span class="firstspan">{{day.number}}</span>
                <span class="secondspan">{{day.weekDay}}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="border-bottom: 1px solid; "
            v-for="(track, trackIndex) in readyTraks"
            :key="trackIndex"
          >
            <td style="width: 25%; height: 40px; line-height: 40px; white-space: nowrap;">{{track.name}}</td>
            <td style="width: 15%; height: 40px; line-height: 40px; color: #0052cc; pading-left: 10px;">{{track.key}}</td>
            <td
              style="width: 50px; height: 40px; line-height: 40px; text-align: right; padding-right: 10px; white-space: nowrap;"
            >{{track.viewTime}}</td>
            <td class="d-flex">
              <div
                class="d-flex flex-column text-center"
                style="height: 40px; width: 50px; line-height: 40px; border-left: 1px solid; cursor: pointer; "
                v-for="(time, timeIndex) in track.fullMonth"
                :key="timeIndex"
                :style="{'background-color' : days[timeIndex].dayOff==true ? 'red' : 'blue'}"
                @click="track.fullMonth[timeIndex]==' '? $modal.show('logTime',{day:timeIndex}):$modal.show('show_issues',{ foo: track.issuesPerDay[timeIndex+1] })"
              >
                <td>{{track.fullMonth[timeIndex]}}</td>
              </div>
            </td>
          </tr >
          <modal
            name="show_issues"
            :pivot-x="0.5"
            :pivot-y="0.5"
            height="auto"
            width="75%"
            :draggable="true"
            :scrollable="true"
            transition="slide-top"
            overlayTransition="wait"
            :adaptive="true"
            classes="modal-overflow remove-modal-height modal-absolute "

            @before-open="beforeOpen"

          >
            <table style="background-color: white; color: black">
              <thead>
                <tr style="border-bottom: 1px solid;">
                  <th style="width: 10%; height: 40px; line-height: 40px;">{{ translate('Date', 'project') }}</th>
                  <th style="width: 10%; height: 40px; line-height: 40px;">{{ translate('User', 'project') }}</th>
                  <th style="width: 30%; height: 40px; line-height: 40px; ">{{ translate('Issue', 'project') }}</th>
                  <th style="width: 30%; height: 40px; line-height: 40px;">{{ translate('Description', 'project') }}</th>
                  <th style="width: 10%; height: 40px; line-height: 40px;">{{ translate('Worked', 'project') }}</th>
                  <th style="width: 10%; height: 40px; line-height: 40px; ">{{ translate('Actions', 'global') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="border-bottom: 1px solid; "
                  v-for="(issue, issueIndex) in daylyIssue"
                  :key="issueIndex"
                >
                  <td style="width: 12%; height: 40px; line-height: 40pxc;">
                    <div class="hover-div" @click="$modal.show('calendar',{ changeIssue: issue }); calendar=true">
                      {{issue.date}}
                      <div class="div-to-hover" >
                        <i class="fas fa-pen" aria-hidden="true"></i>
                      </div>
                    </div>
                  </td>

                  <td style="width: 10%; height: 40px; line-height: 40px;">{{issue.user}}</td>
                  <td style="width: 30%; height: 40px; line-height: 40px; white-space: nowrap">
                    <select v-model="issueName" @change="selectIssueName(issue)">
                      <option disabled value="">{{issue.nameIssue}}</option>
                      <option v-for="(issueName, isIndex) in tracks.issues" :key="isIndex" v-bind:value="issueName.id">{{issueName.name}} </option>
                     </select>
                  </td>
                  <td style="width: 30%; height: 40px; line-height: 40px;">
                    <div :ref="'d'+issue.id" class="hover-div" @click="openInputDescr(issue)"  >
                      {{issue.description}}
                      <div class="div-to-hover">
                        <i class="fas fa-pen" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input :ref="'descr'+issue.id" type="text" v-model="description" :placeholder="issue.description" style="width: 100%; display: none;" @change="changeDescriprion(issue)" >
                  </td>
                  <td style="width: 77px; height: 40px; line-height: 40px; text-align: right">
                    <div :ref="issue.id" class="hover-div" @click="openInputTime(issue)" >
                      <div class="div-to-hover" >
                        <i class="fas fa-pen" aria-hidden="true"></i>
                      </div>
                      {{issue.time}}
                    </div>
                    <input :ref="'time'+issue.id" type="text" v-model="time" :placeholder="issue.time" style="width: 77px; display: none;" @change="changeTime(issue)" >
                  </td>
                  <td style="width: 10%; height: 40px; line-height: 40px; text-align: right">
                    <cta :collapsed="true" :buttons-num="4" >
                                <action-button  class-icon="fa-pen" @click.native="$modal.show('edit',{ changeIssue: issue });$modal.hide('show_issues')" v-tooltip="translate('Edit', 'global')">
                                </action-button>
                                <action-button  class-icon="fa-trash-alt" @click.native="askForDelete()" v-tooltip="translate('Delete', 'global')">
                                </action-button>
                            </cta>
                  </td>
                </tr>
                <tr style="border-bottom: 1px solid; ">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="width: 10%; height: 40px; line-height: 40px; ">
                    <button type="button"  style="background-color: blue; color: white;" @click="$modal.show('logTime');$modal.hide('show_issues')">
                      {{ translate('Log Time', 'project') }}
                    </button>
                  </td>
                  <td style="width: 10%; height: 40px; line-height: 40px;">
                    <button type="button" class="close" @click="$modal.hide('show_issues')">
                      {{ translate('Close', 'project') }}
                      <!-- <span aria-hidden="true">Close</span> -->
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </modal>
          <tr style="border-bottom: 1px solid;">
            <td style="width: 25%; height: 40px; line-height: 40px;">{{ translate('Total', 'project') }}</td>
            <td style="width: 5%; height: 40px; line-height: 40px;"></td>
            <td
              style="width: 5%; height: 40px; line-height: 40px; text-align: right; padding-right: 10px; white-space: nowrap;"
            >{{sumTime.viewTime}}</td>
            <td class="d-flex">
              <div
                class="d-flex flex-column text-center"
                style="height: 40px; width: 50px; border-left: 1px solid;"
                v-for="(day,dayIndex) in sumDay"
                :key="dayIndex"
              >
                <span class="firstspan">{{day.time.viewTime}}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <modal
            name="logTime"
            :pivot-x="0.5"
            :pivot-y="0.3"
            height="auto"
            width="500px"
            :draggable="true"
            :scrollable="true"
            transition="slide-top"
            overlayTransition="wait"
            :adaptive="true"
            classes="remove-modal-height modal-absolute"
            @before-open="beforeOpenLogTime"
          >
           <div style="background-color: white; font-size: 1.1rem;">
            <div style="display:flex;" class="padding-1">
              <i class="fa fa-check" aria-hidden="true" style="color: white; background-color: lightgreen; padding: 1.1rem; border-radius: 2.25rem; margin: 0.2rem;" ></i>
              <h2 style="padding: 0.5rem;">{{ translate('Log Time', 'project') }}</h2>
            </div>
            <div class="padding-1">
              <select v-model="issueName" >
                <option disabled value="">{{ translate('Search issues...', 'project') }}</option>
                <option v-for="(issueName, isIndex) in tracks.issues" :key="isIndex" v-bind:value="issueName.id">{{issueName.name}} </option>
              </select>
            </div>
            <div class="padding-1">
              <textarea rows="3" v-model="description" :placeholder="translate('Description', 'project')" style="width: 100%" ></textarea>
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('User', 'project') }}</label>
              <select v-model="userName" >
                <option disabled value="">{{ translate('Search user...', 'project') }}</option>
                <option v-for="(userName, isIndex) in tracks.users" :key="isIndex" >{{userName}} </option>
              </select>
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Date', 'project') }}</label>
              <input type="text"  :placeholder="newDate" @click="$modal.show('calendar',{ changeIssue: updateIssue })" >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Worked', 'project') }}</label>
              <input type="text" v-model="time" placeholder="0h" >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Billable', 'project') }}</label>
              <input type="text" v-model="billableTime" placeholder="0h"  >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Remaining estimate', 'project') }}</label>
              <input type="text" v-model="estimate" placeholder="0h"  >
            </div>
            <div style="display:flex; border-top: 1px solid #d9d9d9; padding: 2rem">
              <button type="button" class="rounded-pill" style="background-color: blue; color: white; margin-left: auto; margin-right: 1.2rem" @click="newTrack">
                      {{ translate('Log Time', 'project') }}
              </button>
              <button type="button" class="close rounded-pill" @click="$modal.hide('logTime');clearProp()">
                      {{ translate('Go back', 'global') }}
              </button>
            </div>
           </div>
      </modal>
      <modal
            name="edit"
            :pivot-x="0.5"
            :pivot-y="0.3"
            height="auto"
            width="500px"
            :draggable="true"
            :scrollable="true"
            transition="slide-top"
            overlayTransition="wait"
            :adaptive="true"
            classes="remove-modal-height modal-absolute"
            @before-open="beforeOpenEdit"

          >
           <div style="background-color: white; font-size: 1.1rem;">
            <div style="display:flex;" class="padding-1">
              <i class="fa fa-check" aria-hidden="true" style="color: white; background-color: lightgreen; padding: 1.1rem; border-radius: 2.25rem; margin: 0.2rem;" ></i>
              <h2 style="padding: 0.5rem;">{{ translate('Log Time', 'project') }}</h2>
            </div>
            <div class="padding-1">
              <textarea rows="3" v-model="description" :placeholder="updateIssue.description" style="width: 100%" ></textarea>
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Date', 'project') }}</label>
              <input type="text"  :placeholder="updateIssue.date" @click="$modal.show('calendar',{ changeIssue: updateIssue })" >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Worked', 'project') }}</label>
              <input type="text" v-model="time" :placeholder="updateIssue.time"  >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Billable', 'project') }}</label>
              <input type="text" v-model="billableTime" :placeholder="updateIssue.time"  >
            </div>
            <div class="padding-1">
              <label class="label-update">{{ translate('Remaining estimate', 'project') }}</label>
              <input type="text" v-model="estimate" placeholder="0h"  >
            </div>
            <div style="display:flex; border-top: 1px solid #d9d9d9; padding: 2rem">
              <button type="button"  style="background-color: blue; color: white; margin-left: auto; margin-right: 1.2rem" @click="editIssue">
                      {{ translate('Update', 'project') }}
              </button>
              <button type="button" class="close" @click="$modal.hide('edit')">
                      {{ translate('Cancel', 'project') }}
              </button>
            </div>
           </div>
      </modal>
      <modal
            name="calendar"
            :pivot-x="0.5"
            :pivot-y="0.5"
            height="auto"
            width="250px"
            :draggable="true"
            :scrollable="true"
            transition="slide-top"
            overlayTransition="wait"
            :adaptive="true"
            classes="remove-modal-height modal-absolute"
            @before-open="beforeOpenCalendar"
          >
           <div >
                <DatePicker v-model="newDate" format="YYYY-MM-DD" type="date" :editable="false" :clearable="false"  :open="true" :inline="true"  @pick="handlePick"></DatePicker>

           </div>
      </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { db, modules, routes } from "@/utilities/database";
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';

export default {
  name: "Calendar",
  components: {
    DatePicker,vSelect
  },

  props: {
    tracks: {
      required: true
    },
    tasks: {
     required: true
    }
  },
  data() {
    return {
      userName: '',
      calendar: false,
      estimate:'',
      billableTime:'',
      workHours:0,
      time:'',
      description: '',
      issueName: '',
      date: moment().locale("en"),
      visible: {
        currentBody: "opened"
      },
      weekday: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
      monthDays: Number,
      days: [],
      totalHours: {},
      daylyIssue: Array,
      month: String,
      monthYear: String,
      user: "Allusers",
      updateIssue: Object,
      newDate: moment(),
      correction:{},
      holidays:["2020-01-01","2020-03-03","2020-04-17","2020-04-20","2020-05-01","2020-05-06","2020-05-25","2020-09-07","2020-09-22","2020-12-24","2020-12-25"]
    };
  },
  created() {
    let part = this.weekday.slice().concat(this.weekday);
    this.weekday = this.weekday.concat(part).concat(part);
    moment.locale("en");
    this.monthYear = this.date.format("YYYY-MM");
    this.month = this.date.format("MMM/YY");
    this.monthDays = moment(this.monthYear, "YYYY-MM").daysInMonth();
    this.readyDays();
  },
  methods: {
    newTrack(){
      let date=moment(this.newDate.toLocaleString("en-US"));
      db.post(routes.tracker.log,
      {log: {
        type: 'task',
        id: this.issueName,
        date: date.format("YYYY-MM-DD"),
        worked: this.time,
        user: this.userName,
        description: this.description,
        billable: this.billableTime
      }})
         .then(res=>{
              this.$emit('logTime',res)
          })
         .catch(error => this.$toast.error(error.message))
         this.$modal.hide('logTime')
         this.clearProp()
    },
    clearProp(){
      this.userName='',
      this.billableTime='',
      this.estimate='',
      this.time='',
      this.description='',
      this.issueName='',
      this.correction={},
      this.updateIssue={},
      this.newDate=moment()
    },
    openInputDescr(event){
      let id="descr"+event.id
      let originId="d"+event.id
      this.$refs[id][0].style.display="block"
      this.$refs[originId][0].style.display="none"
    },
    openInputTime(event){
     let id="time"+event.id
     this.$refs[id][0].style.display="block"
     this.$refs[event.id][0].style.display="none"
    },
    changeTime(event){
      this.updateIssue=event;
      this.correction.worked=this.time;
      this.changeIssue();
      this.$modal.hide('show_issues');
    },
    changeDescriprion(event){
      this.updateIssue=event;
      this.correction.description=this.description;
      this.changeIssue();
      this.$modal.hide('show_issues');
    },
    selectIssueName(event){
      this.updateIssue=event;
      this.correction.id=this.issueName;
      this.changeIssue();
      this.$modal.hide('show_issues');
    },
    handlePick(){
      if (this.calendar) {
      let date=moment(this.newDate.toLocaleString("en-US"))
      this.correction.date=date.format("YYYY-MM-DD")
      this.$modal.hide('calendar');
      this.changeIssue();
      this.calendar=false;
      }
      else{
        let date=moment(this.newDate.toLocaleString("en-US"));
      this.updateIssue.date=date.format("DD-MMM-YY");
      this.$modal.hide('calendar');
      }
      this.$modal.hide('show_issues');
    },
    editIssue(){
      let date=moment(this.newDate.toLocaleString("en-US"));
      this.correction.date=date.format("YYYY-MM-DD");
      this.correction.description=this.description;
      this.correction.worked=this.time;
      this.correction.billable=this.billableTime;
      this.changeIssue();
      this.$modal.hide('edit');
    },
    changeIssue(){

       db.put(routes.tracker.update.replace(':id',this.updateIssue.id),
              {
                "update":  this.correction
              }
            ).then(res=>{
              this.$emit('update',res)
            }

            )
            this.clearProp()
    },
    beforeOpen(event) {
      this.daylyIssue = event.params.foo;
    },
    beforeOpenCalendar(event){
      this.updateIssue=event.params.changeIssue;
      this.newDate=this.updateIssue.calDate;
    },
    beforeOpenEdit(event){
      this.newDate=event.params.changeIssue.date;
      this.updateIssue=event.params.changeIssue;
    },
    beforeOpenLogTime(event){
      if(event.params){
        let day=event.params.day+1
        this.newDate=`${day}/${this.month}`
              }

      if(this.daylyIssue[0]){
        this.newDate=this.daylyIssue[0].date;
        this.daylyIssue=[]
      }

    },
    addMonth() {
      moment.locale("en");
      this.date = moment(this.date).add(1, "months");
      this.monthYear = this.date.format("YYYY-MM");
      this.month = this.date.format("MMM/YY");
      this.monthDays = moment(this.monthYear, "YYYY-MM").daysInMonth();
      this.readyDays();
    },
    substractMonth(){
       moment.locale("en");
      this.date = moment(this.date).subtract(1, "months");
      this.monthYear = this.date.format("YYYY-MM");
      this.month = this.date.format("MMM/YY");
      this.monthDays = moment(this.monthYear, "YYYY-MM").daysInMonth();
      this.readyDays();
    },
    readyDays() {
        this.days=[]
      this.workHours=0;
      let numDay;
      let dayOff
      let d = new Date(`${this.monthYear}-01`);
      for (let i = 0; i < this.monthDays; i++) {
        let firstDay = this.weekday[d.getDay() + i];
        if (firstDay!=="SA"&firstDay!=="SU"){
         this.workHours=this.workHours+8
          dayOff=false
        }
        else{dayOff=true}
        if (i < 9) {
          numDay = "0" + Number(i + 1);
        } else {
          numDay = i + 1;
        }
        if (this.holidays.includes(`${this.monthYear}-${numDay}`)){
          this.workHours=this.workHours-8;
          dayOff=true
        }
        let newDay = {
          dayOff: dayOff,
          number: numDay,
          weekDay: firstDay,
          time: { seconds: 0 }
        };
        this.days.push(newDay);
      }
      return this.days;
    }
  },

  computed: {

       ...mapState('system', ['authData'])
      ,
       readyTraks() {
        this.tracks.ready=[]
        this.tracks.real.forEach(el => {
        let curMonth = new Date(this.monthYear).getMonth();
        let realTrack
        if (this.user!=="Allusers") {
          let userTrack=el.tracks.filter(el => el.user == this.user)
          realTrack=userTrack.filter(el => el.month == curMonth)
        }
        else{realTrack = el.tracks.filter(el => el.month == curMonth)}
        if (realTrack.length > 0) {
          el.issuesPerDay = {};
          el.fullMonth = [];
          for (let i = 0; i < this.monthDays; i++) {
            el.fullMonth.push(' ');
          }
          realTrack.forEach(element => {
            if (el.fullMonth[element.day - 1] == ' ') {
              el.issuesPerDay[element.day] = [element];
              el.fullMonth[element.day - 1] = element.time;
            } else {
              el.issuesPerDay[element.day].push(element);
              el.fullMonth[element.day - 1] += element.time;
            }
          });
          this.tracks.ready.push(el);
        }
      });

      this.tracks.ready.forEach(element => {
        let allSeconds = element.fullMonth.reduce(function(acc, el) {
          if (el==' '){
            return acc ;
         }
          else {
            return acc + el;
          }
        }, 0);
        element.issueTotal = allSeconds;
        let seconds = allSeconds % 60;
        let minutes = Math.floor(allSeconds / 60) % 60;
        let hours = Math.floor(Math.floor(allSeconds / 60) / 60);
        if (hours > 0) {
          element.viewTime = `${hours}h ${minutes}m ${seconds}s`;
        } else if (minutes > 0) {
          element.viewTime = `${minutes}m ${seconds}s`;
        } else {
          element.viewTime = `${seconds}s`;
        }
      });
      return this.tracks.ready;
    },
    sumTime() {
      let totalHours = this.tracks.ready.reduce(function(acc, el) {
        return acc + el.issueTotal;
      }, 0);
      this.totalHours.seconds = totalHours;
      let seconds = totalHours % 60;
      let minutes = Math.floor(totalHours / 60) % 60;
      let hours = Math.floor(Math.floor(totalHours / 60) / 60);
      if (hours > 0) {
        this.totalHours.viewTime = `${hours}h ${minutes}m ${seconds}s`;
      } else if (minutes > 0) {
        this.totalHours.viewTime = `${minutes}m ${seconds}s`;
      } else {
        this.totalHours.viewTime = `${seconds}s`;
      }
      return this.totalHours;
    },
    sumDay() {
      this.days.forEach(element => {
           element.time.seconds=0
         });
      this.tracks.ready.forEach(el => {
        for (let ind = 0; ind < this.days.length; ind++) {
          this.days[ind].time.seconds += Number(el.fullMonth[ind]);
        }
      });
      this.days.forEach(el => {
        let seconds = el.time.seconds % 60;
        let minutes = Math.floor(el.time.seconds / 60) % 60;
        let hours = Math.floor(Math.floor(el.time.seconds / 60) / 60);
        if (hours > 0) {
          el.time.viewTime = `${hours}h ${minutes}m ${seconds}s`;
        } else if (minutes > 0) {
          el.time.viewTime = `${minutes}m ${seconds}s`;
        } else {
          el.time.viewTime = `${seconds}s`;
        }
      });
      return this.days;
    }
  }
};
</script>

<style scoped>
/* @import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/timeline/main.css";
@import "~@fullcalendar/resource-timeline/main.css"; */
.firstspan {
  line-height: 25px;
}

.secondspan {
  line-height: 7px;
}
.v--modal-overlay .v--modal-box {

  overflow: visible;
  box-sizing: border-box;
}
.hover-div{
  display: flex;
  cursor: pointer;
  border-radius: 3px;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
}
.div-to-hover {
  display: none;
}
.hover-div:hover .div-to-hover{
  display: block;
}
.hover-div:hover {
    border-width: 1px;
}
.padding-1 {
  padding: 1rem;
}
.label-update {
    flex: 0 0 22.5%;
    max-width: 22.5%;
    width: 22.5%;
    padding-top: .5em;
    padding-right: .71428571em;
    text-align: right;
}

.hide{
  display: none;
}
.show{
  display: block;
}


</style>