<template>
  <div class="row">
    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('border-top-width', 'px')"
          :secondValue="getStyle('border-bottom-width', 'px')"
          @inputFirstValue="setStyle('border-top-width', $event, 'px')"
          @inputSecondValue="setStyle('border-bottom-width', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Border top width'"
              :has-responsive-styles="true"
              :input-type="'number'"
              :suffix="'PX'"
              :placeholder="getPlaceholder('border-top-width', 'px')"
              :value="getStyle('border-top-width', 'px')"
              @input="setStyle('border-top-width', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Border bottom width'"
              :has-responsive-styles="true"
              :input-type="'number'"
              :suffix="'PX'"
              :placeholder="getPlaceholder('border-bottom-width', 'px')"
              :value="getStyle('border-bottom-width', 'px')"
              @input="setStyle('border-bottom-width', $event, 'px')"
          />
        </template>

      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('border-left-width', 'px')"
          :secondValue="getStyle('border-right-width', 'px')"
          @inputFirstValue="setStyle('border-left-width', $event, 'px')"
          @inputSecondValue="setStyle('border-right-width', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Border left width'"
              :has-responsive-styles="true"
              :input-type="'number'"
              :suffix="'PX'"
              :placeholder="getPlaceholder('border-left-width', 'px')"
              :value="getStyle('border-left-width', 'px')"
              @input="setStyle('border-left-width', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Border right width'"
              :has-responsive-styles="true"
              :input-type="'number'"
              :suffix="'PX'"
              :placeholder="getPlaceholder('border-right-width', 'px')"
              :value="getStyle('border-right-width', 'px')"
              @input="setStyle('border-right-width', $event, 'px')"
          />
        </template>

      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-color-picker
          :label="'Border color'"
          :has-responsive-styles="true"
          :value="getStyle('border-color')"
          @input="setStyle('border-color', $event)"
      />
    </div>

    <div class="col-md-6">
      <craft-select
          :label="'Border style'"
          :has-responsive-styles="true"
          :placeholder="getBorderPlaceholder()"
          :options="borderStyleOptions"
          :value="getBorderStyle()"
          @input="setBorderStyles($event)"
          :clearable="true"
      />
    </div>

    <div class="col-md-6">
      <craft-input
          :label="'Border radius'"
          :has-responsive-styles="true"
          :input-type="'number'"
          :suffix="'PX'"
          :placeholder="getPlaceholder('border-radius', 'px')"
          :value="getStyle('border-radius', 'px')"
          @input="setStyle('border-radius', $event, 'px')"
      />
    </div>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import {CraftSelect, CraftInput, CraftColorPicker, CraftChainedInputs} from "@/modules/pagebuilder/components/craft-inputs"
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"

export default {
  name: "Border",
  mixins: [ComponentSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor'],
  components: {
    CraftColorPicker,
    CraftInput,
    CraftSelect,
    CraftChainedInputs,
  },
  methods: {
    getBorderPlaceholder() {
      const placeholder = (
        this.getPlaceholder('border-top-style') ||
        this.getPlaceholder('border-left-style') ||
        this.getPlaceholder('border-bottom-style') ||
        this.getPlaceholder('border-right-style')
      )

      return placeholder.toPascalCase() || ''
    },
    getBorderStyle(){
      const hasBorder = (
        this.getStyle('border-top-style') ||
        this.getStyle('border-left-style') ||
        this.getStyle('border-bottom-style') ||
        this.getStyle('border-right-style')
      )

      return hasBorder || ''
    },

    setBorderStyles(style){
      this.setStyle('border-top-style', style)
      this.setStyle('border-bottom-style', style)
      this.setStyle('border-left-style', style)
      this.setStyle('border-right-style', style)
    },
  },
  created() {
    // backward compatibility border width
    if(this.getStyle('border-width', 'px')){
      let borderWidth = this.getStyle('border-width', 'px')
      this.setStyle('border-width', '') // remove old values
      this.setStyle('border-top-width', borderWidth, 'px')
      this.setStyle('border-bottom-width', borderWidth, 'px')
      this.setStyle('border-left-width', borderWidth, 'px')
      this.setStyle('border-right-width', borderWidth, 'px')
    }

    // backward compatibility border style
    if(this.getStyle('border-style')){
      let borderStyle = this.getStyle('border-style')
      this.setStyle('border-style', '') // remove old values
      this.setBorderStyles(borderStyle)
    }
  },
}
</script>