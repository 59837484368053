<template>
    <div class="plan-modal">
        <div class="modal-default">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="text-dark">{{ translate('Plan new item', 'agenda') }}</h5>
                    <i class="fas fa-times close-modal" @click="$modal.hide('plan_modal')"></i>
                </div>
                <div class="modal-body">
                    <div class="text-center d-flex flex-column">
                        <button class="btn btn-primary my-3" @click="$modal.show('appointment_modal')">{{ translate('Plan a call', 'agenda') }}</button>
                        <button class="btn btn-primary ml-0 my-3" @click="$modal.show('appointment_modal')">{{translate('Plan a meeting', agenda)}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlanModal",
    }
</script>

<style lang="scss">
    .plan-modal {
        .modal-content {
            .modal-header {
                border-bottom: 1px solid $primary !important;

                .close-modal {
                    cursor: pointer;
                    color: #A3ADBD;
                }
            }

            .modal-body {
                button {
                    height: 50px;
                }
            }
        }
    }
</style>