<template>
    <div v-if="value" class="container">
        <page-title :page-title="translate('Order number', 'orders')"></page-title>

        <div>
            <div class="row">
                <div class="col-md-12">
                    <div class="order-price d-flex justify-content-center align-items-center flex-column py-3">
                        <span class="order-totals">{{ translate('Totals', 'orders') }}</span>
                        <div class="order-single-price d-flex justify-content-between align-items-center">
                            <span class="order-currency font-weight-bold mr-3">€</span>
                            <span class="order-amount font-weight-bold mr-3">{{total}}</span>
                        </div>
<!--                        <span class="order-vat">/incl 5.99 VAT</span>-->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-4">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="col-styling user-col d-flex flex-column justify-content-center align-items-center bg-white h-100 p-4">
                                    <img v-if="value.additional_client_info && value.additional_client_info.avatar"
                                         :src="value.additional_client_info.avatar" alt="user avatar">
                                    <img v-else src="/img/default_logo.png" alt="user avatar">
                                    <h3>{{ value.selected_client.first_name }} {{ value.selected_client.last_name }}</h3>
                                    <span class="customer-number" v-if="value.additional_client_info">({{ translate('customer number', 'orders') }}: {{value.additional_client_info.contact_id}})</span>
                                    <div class="customer-info w-100 mt-2">
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Street number', 'orders') }}</span>
                                            <span class="info-value">{{ value.selected_client.company_address }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Zip city', 'orders') }}</span>
                                            <span class="info-value">{{ value.selected_client.city }} {{ value.selected_client.zip_code }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Email', 'orders') }}</span>
                                            <span class="info-value">{{ value.selected_client.email }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2">
                                            <span>{{ translate('Phone', 'orders') }}</span>
                                            <span class="info-value">{{ value.selected_client.phone }}</span>
                                        </div>
                                        <div class="single-info d-flex justify-content-between py-2" v-if="value.additional_client_info">
                                            <span>{{ translate('Customer since', 'orders') }}</span>
                                            <span class="info-value">{{ formatDate(value.additional_client_info.created_at) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="col-styling note-col bg-white h-100 p-4">
                                    <textarea v-model="data.additional_data.additional_notes" class="order-note w-100 h-100" type="text" :placeholder="translate('Put a note to this order', 'orders')"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <div  class="w-100">

						<div class="main-table border-0">
							<div class="table-responsive border border-dark rounded">
								<table class="table m-0">
									<thead class="bg-white">
										<tr>
											<th class="font-weight-bold" scope="col">{{ translate('Subscriptions', 'orders') }}</th>
											<th class="font-weight-bold" scope="col">{{ translate('Payment terms', 'orders') }}</th>
											<th class="font-weight-bold" scope="col">{{ translate('Contract years', 'orders') }}</th>
										</tr>
									</thead>

									<tbody>
                  <template v-for="(item, i) in value.plan.apps">
                    <tr :key="i" v-if="item.inventory_type == 'module'">
                      <td>
                        <div>
                          <span class="font-weight-bold">{{ item.readable_options.name }}</span>
                        </div>
                        <div>
                          <span class="text-secondary">{{ translate('Includes', 'orders') }}:
                          <span>
                            {{ value.plan.apps.filter(a => a.inventory_type == 'extension').length }}
                          </span>f
                          {{ translate('extensions from Appstore', 'orders') }}</span>
                        </div>
                      </td>
                      <td>
                        <span class="font-weight-bold">€ {{ item.readable_options.price }}</span><br>
                        <span class="font-weight-bold" v-if="item.readable_options.period == 1">{{ translate('monthly', 'orders') }}</span>
                        <span class="font-weight-bold" v-if="item.readable_options.period == 12">{{ translate('yearly', 'orders') }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bold" v-if="item.readable_options.period == 12">1 {{ translate('Year', 'orders') }}</span>
                        <span class="font-weight-bold" v-if="item.readable_options.period == 24">2 {{ translate('Year', 'orders') }}</span>
                        <span class="font-weight-bold" v-if="item.readable_options.period == 36">3 {{ translate('Year', 'orders') }}</span>
                      </td>
                    </tr>
                  </template>
									</tbody>
								</table>
							</div>
						</div>
                    </div>
                </div>
                <div class="col-md-12">
					<div class="order-footer d-flex flex-column w-100 py-3">
						<button @click="finishOrder" class="btn btn-primary rounded-pill p-2 m-2">
							{{ translate('Complete order', 'orders') }}</button>
						<button @click="cancel" class="btn btn-white text-muted rounded-pill p-2 m-2">{{ translate('Cancel', 'orders') }}</button>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {db} from "@/utilities/database";
    import {clientDataToApiData} from "@/utilities/helpers/checkout";
    import {mapState, mapGetters} from 'vuex';

    export default {
        name: "OrderFinalView",
        props: {
            value: {
                required: true
            }
        },
        computed: {
            ...mapState('blockzCheckout', ['cart', 'data']),
            ...mapGetters('blockzCheckout', ['total'])
        },
        methods: {
            finishOrder() {
                db.post(`modules/blockz/cart/complete`, clientDataToApiData(
                    {create_invoice: true},
                    {...this.value.selected_client},
                    this.data.additional_data
                )).then(res => {
                    window.localStorage.removeItem('session')
                    this.value = null
                    this.data.additional_data = {}
                    this.$router.push('/blockz-order/create/order-finish')
                    this.$toast.requestSuccess('post', 'Order')

                })
            },
            cancel() {
                this.$router.push('/blockz-order')
                window.localStorage.removeItem('session')
            }
        }
    }
</script>

<style scoped lang="scss">
    small {
        font-size: 70%;
    }

    .col-styling {
        border: 1px solid #000;
        border-radius: 5px;
        margin-top: 1.25rem;

        &.user-col {
            img {
                width: 100px;
                border-radius: 50%;
            }

            .customer-number {
                color: #9a9a9a;
            }

            .customer-info {
                .single-info {
                    border-bottom: 1px solid #000;

                    &:first-of-type {
                        border-top: 1px solid #000;
                    }

                    .info-value {
                        color: $primary;
                    }
                }
            }
        }

        &.note-col {
            .order-note {
                border: none;
                background: transparent;
                resize: none;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .order-price {
        background-color: #000;
        border-radius: 5px;

        .order-totals {
            color: #fff;
            text-transform: uppercase;
        }

        .order-single-price {
            .order-currency {
                font-size: 24px;
                color: #ff004f;
            }

            .order-amount {
                font-size: 46px;
                color: #fff;
            }
        }

        .order-vat {
            color: #9a9a9a;
        }
    }

    .order-footer {
        .btn + .btn {
            margin: 0;
        }
    }
</style>
