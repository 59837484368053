<template>
    <div>
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ translate('Multiple edit', 'global') }}
                </h5>
                <button type="button" class="close" @click="$modal.hide('bulk_edit')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body b-b-pr">
                <v-select :options="columns.columns" v-model="selected">
                  <template #selected-option="{ name }">{{ entityLabel(name) }}</template>
                  <template v-slot:option="option">{{ entityLabel(option.name) }}</template>
                </v-select>
                <field v-model="selected.value" :field-props="selected" v-if="selected"></field>
            </div>
            <div class="modal-footer remove-border">
                <button type="button" class="btn btn-white rounded-pill"
                        @click="$modal.hide('bulk_edit')">{{ translate('Go back', 'global') }}
                </button>
                <button type="submit" @click="$emit('editAll', selected)" :disabled="!selected"
                        class="btn btn-primary rounded-pill">
                    {{ translate('Save changes', 'global') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import field from '../../components/page/form/InputEntityProps'

    export default {
        name: "BulkEdit",
        components: {
            field
        },
        data() {
            return {
                selected: null
            }
        },
        props: {
            columns: {
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
