<template>
  <div class="conditions">
    <create-condition
      :properties="properties"
      @createCondition="$emit('input', [...value, $event])"
    />
    <conditions-table
      :value="value"
      :properties="properties"
      @removeCondition="removeCondition($event)"
    />
  </div>
</template>

<script>
import CreateCondition from "@/modules/erp_entities/components/entities/Conditions/CreateCondition.vue"
import ConditionsTable from "@/modules/erp_entities/components/entities/Conditions/ConditionsTable.vue"

export default {
  name: 'Conditions',
  components: { ConditionsTable, CreateCondition },
  props: {
    value: {
      required: true,
      type: Array,
      default: () => [],
    },
    properties: {
      required: true,
    },
  },
  methods: {
    removeCondition(idx) {
      const newValue = [...this.value]
      newValue.splice(idx, 1)
      this.$emit('input', newValue)
    },
  },
}
</script>
