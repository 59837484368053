<template>
	<div></div>
</template>

<script>
export default {
name: "Knowledgebase"
}
</script>

<style scoped>

</style>
