<template>
  <div class="bg-white p-3 add-new-option" :class="{'p-5': loading, 'nav-scrollbar h-100': value.data_type === 'moduleNames'}">
    <template v-if="value">
      <create-custom-option
        v-if="value.data_type === 'custom'"
        :value="value"
        @loading="loading = $event"
      />
      <create-entity-option
        v-else-if="value.data_type === 'moduleNames'"
        :value="value"
        @cancel="$parent.toggle(false)"
        @loading="loading = $event"
        @save="$emit('save')"
      />
    </template>
    <div v-if="loading" class="loader">
      <span><i class="fa fa-spin fa-spinner fa-3x" /></span>
    </div>
  </div>
</template>

<script>
import CreateCustomOption from "@/modules/erp_entities/components/entities/CreateNewOption/CreateCustomOption.vue"
import CreateEntityOption from "@/modules/erp_entities/components/entities/CreateNewOption/CreateEntityOption.vue"

export default {
  name: "CreateNewOption",
  components: { CreateEntityOption, CreateCustomOption },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
}
</script>

<style scoped lang="scss">
.add-new-option, .loader {
  border-radius: .375rem;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .15);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
