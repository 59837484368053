<template>
  <div class="col-style">
    <div class="col-style-child d-flex justify-content-between align-items-center p-3">
      <span class="system-text-primary font-weight-bold">
        {{ translate('Order number', 'orders') }}
      </span>
      <!--
            <div class="col-md-7 align-items-center">
              <input
                v-model="order.order_number"
                class="form-control"
                type="text"
                :placeholder="translate('Order number', 'orders')"
                :required="true"
              >
            </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderNumber",
}
</script>
