<template>
    <div class="container" :class="`design-options-${$route.params.entity}`" v-if="entity.menu">
        <page-title :page-title="translate(humanReadable(title), 'settings')">
          <template v-slot:actions>
            <button class="btn btn-primary rounded-pill" @click="save">
              {{ translate('Save changes', 'global') }}
            </button>
          </template>
        </page-title>

        <EntitySettings v-model="entity" ref="entitySettings"/>
    </div>
</template>

<script>
import EntitySettings from '@/modules/erp_entities/components/page/form/EntitySettings';
import {humanReadable} from "@/utilities/helper";

export default {
    name: "entity-settings",
    components: {
        EntitySettings
    },
    data() {
        return {
            entity: {
                id: this.$store.state.system.scope.value,
                entity: this.$router.currentRoute.params.entity,
                menu: null
            },
        }
    },
    methods: {
        humanReadable,
        async getEntityMenu() {
            let res = await this.erp.ext.request.axiosInstance.get(`/modules/settings-groups/${this.$route.params.entity}`)
            this.entity.menu = res.data.data
        },
        save(){
          this.$refs.entitySettings.save()
        }
    },
    computed: {
      title(){
        let routeValue = this.$router.currentRoute
        this.groupObjects = this.entity.menu.filter(item => item.slug === routeValue.params.setting);
        if (this.groupObjects.length > 0) {
          if(!this.groupObjects[0].name.includes('setting')){
            return this.groupObjects[0].name + " settings"
          } else {
            return this.groupObjects[0].name
          }
        }
      }
    },
    mounted() {
        this.getEntityMenu()
    },
    watch: {
        '$route.params': {
            deep: true,
            handler() {
                this.entity.entity = this.$route.params.entity
                this.getEntityMenu()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    //.design-options-inventory, .design-options-cms-websites, .design-options-portfolios, .design-options-blogs, .design-options-testimonials {
    //    /deep/ .d-flex.h-100 {
    //        flex-direction: column;
    //
    //        .design-devider {
    //            border-right: 0;
    //            width: 100%;
    //            display: flex;
    //            border-bottom: 1px solid #efefef;
    //
    //            .category {
    //                padding: 1rem;
    //
    //                &.active {
    //                    color: $primary !important;
    //                }
    //            }
    //        }
    //
    //        .form-group {
    //            padding: 0 !important;
    //            margin: 0;
    //            align-items: normal !important;
    //
    //            .switch-field {
    //                align-items: center;
    //            }
    //
    //            > label.form-control-label {
    //                border-right: 1px solid #efefef;
    //                display: flex;
    //                flex-direction: column;
    //                justify-content: center;
    //                min-width: 300px;
    //                padding: 1rem;
    //
    //                +input, +.select2-form {
    //                    margin: 1rem;
    //
    //                    .vs__selected-options {
    //                        padding: 0 15px;
    //                    }
    //
    //                    .vs__actions {
    //                        padding: 4px 15px 0 3px;
    //                    }
    //                }
    //
    //                +.background-settings-container, +.font-settings-container {
    //                    width: 100% !important;
    //                    margin: 1rem;
    //                }
    //            }
    //        }
    //    }
    //}
    //
    //.select2-form {
    //    .vs__dropdown-toggle {
    //        display: -webkit-box;
    //        display: -ms-flexbox;
    //        display: flex;
    //        -webkit-box-align: center;
    //        -ms-flex-align: center;
    //        align-items: center;
    //        width: 100%;
    //        height: 40px;
    //        padding: 8px 16px;
    //        -webkit-transition: border .2s ease,background-color .2s ease,color .2s ease;
    //        transition: border .2s ease,background-color .2s ease,color .2s ease;
    //        border: 1px solid #adb1b5;
    //        border-radius: 4px;
    //
    //
    //        .vs__search {
    //            line-height: normal;
    //            line-height: 24px;
    //            font-size: 14px;
    //            background-color: #fff;
    //            color: #323b43;
    //        }
    //    }
    //}
</style>

