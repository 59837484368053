<template>
  <div class="left-sidebar-content seo-sidebar">
    <div class="left-sidebar-content--container seo-sidebar--container nav-scrollbar">

      <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
        <div>
          <h6>{{ 'SEO & Links' | translate('page-builder') }}</h6>
          <p><small class="info mt-1">{{ 'With the built in SEO Module you can modify the link to your {entity} and optimise it for search engines.' | translate('page-builder', { entity: entityNameTranslated }) }}</small></p>
        </div>
        <div class="close-sidebar">
          <a @click="closeLeftSidebar">
            <i class="icon-erp-plus icon-erp-rotate-45 text-bold"></i>
          </a>
        </div>
      </div>
      <div v-if="value" class="pagebuilder-settings-fields">

        <!-- Title -->
        <template v-if="isAdvancedBuilder">
          <ee-form-group
            :label="translate('{entity} Title', 'page-builder', { entity: entityNameTranslated })"
            class="mt-4">
            <input-field
              v-model="model.name"
              :field-props="fieldProps"
              :entity="builder.meta.entity"
            />
          </ee-form-group>
        </template>

        <!-- Meta title-->
        <ee-form-group
          :label="translate('Meta title', 'page-builder')"
          class="mt-4"
        >
          <input-field
            v-model="model.meta_title"
            :field-props="fieldProps"
            :placeholder="model.name"
            :entity="builder.meta.entity"
          />
        </ee-form-group>

        <!-- Page link -->
        <ee-form-group
          :label="translate('{entity} link', 'page-builder', { entity: entityNameTranslated })"
          class="mt-4">
          <text-with-caution-field
            v-model="model.url_key"
            :field-props="{
              label: translate('{entity} link', 'page-builder', { entity: entityNameTranslated }),
              id: entity.data?.id || null
            }"
          />
        </ee-form-group>

        <!-- Meta description -->
        <ee-form-group
          :label="translate('Meta description', 'page-builder')"
          class="mt-4"
        >
          <textarea-simple-field
            v-model="model.meta_description"
            :field-props="fieldProps"
            :entity="builder.meta.entity"
          />
        </ee-form-group>

        <!-- Meta keywords -->
        <ee-form-group
          :label="translate('Keywords', 'page-builder')"
          class="mt-4"
        >
          <input-field
            v-model="model.meta_keywords"
            :placeholder="model.meta_keywords"
            :field-props="fieldProps"
            :entity="builder.meta.entity"
          />
        </ee-form-group>

        <!-- Meta revisit -->
        <ee-form-group
          :label="translate('Meta revisit', 'page-builder')">
          <v-select
            v-model="model.meta_revisit"
            class="select-component bg-white"
            :options="metaRevisitOptions"
            style="min-width: 120px;"
            :clearable="false"
            :reduce="metaRevisitOptions => metaRevisitOptions.key"
          />
        </ee-form-group>

        <!-- Enable robots to crawl this page -->
        <ee-form-group
          :label="translate('Enable robots to crawl this page', 'page-builder')">

          <!-- OLD WAY WITH RADIO BUTTON
          <div class="custom-control custom-switch">
            <input type="checkbox"
                   class="custom-control-input"
                   :id="'meta_enable_robots_crawl'"
                   v-model="model.meta_enable_robots_crawl"
                   true-value="index,follow"
                   false-value="noindex,nofollow"
            >
            <label class="custom-control-label" :for="'meta_enable_robots_crawl'"></label>
          </div>
          -->
          <div class="d-flex">
            <div class="switch-field" v-for="(option, optionIndex) in metaEnableRobotsCrawlOptions" :key="optionIndex" >
              <input :id="`option-meta_enable_robots_crawl-${option.key}`" :name="`meta_enable_robots_crawl`"
                     type="radio" :value="option.key" v-model="model.meta_enable_robots_crawl">
              <label :for="`option-meta_enable_robots_crawl-${option.key}`">{{ translate(option.value, 'entities') }}</label>
            </div>
          </div>
        </ee-form-group>

      </div>
    </div>
  </div>
</template>

<script>
import { EeFormGroup } from "@/modules/erp_entities/components/forms"
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import TextWithCautionField from "@/modules/erp_entities/components/page/form/fields/TextWithCautionField.vue"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import TextareaSimpleField from "@/modules/erp_entities/components/page/form/fields/TextareaSimpleField.vue"
import { mapState } from "vuex"

export default {
  name: 'Seo',
  components: { TextareaSimpleField, InputField, TextWithCautionField, EeFormGroup },
  mixins: [BuilderMixin],
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      fieldProps: {
        shortcodeable: 1,
      },
    }
  },
  computed: {
    ...mapState('builder', [
      'entity',
    ]),
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    metaRevisitOptions(){
      return [
        { key: 1, label: '1 day' },
        { key: 3, label: '3 days' },
        { key: 7, label: '7 days' },
        { key: 14, label: '14 days' },
        { key: 30, label: '30 days' },
      ]
    },
    metaEnableRobotsCrawlOptions(){
      return [
        { key: 'index,follow', value: 'Yes' },
        { key: 'noindex,nofollow', value: 'No' },
      ]
    },
  },
  methods: {
    closeLeftSidebar() {
      this.$emit('closeLeftSidebar')
    },
  },
}
</script>