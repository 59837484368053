<template>
    <div>
        <div class="card rounded bg-white">

            <div class="card-header">
                <h5>{{ translate('Address', 'entities') }}</h5>
            </div>

            <div class="card-body">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Address identification', 'entities') }}</label>
                            <input class="form-control" v-model="address.name">
                            <small class="form-text text-muted">{{ translate('Ex: Shipping address', 'entities') }}</small>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('First name', 'entities') }}</label>
                            <input class="form-control" v-model="address.client_first_name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Last name', 'entities') }}</label>
                            <input class="form-control" v-model="address.client_last_name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Phone', 'entities') }}</label>
                            <input class="form-control" v-model="address.client_phone">
                        </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>{{ translate('Company name', 'entities') }}</label>
                        <input type="text" class="form-control" v-model="address.billing_company_name">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>{{ translate('VAT Number', 'entities') }}</label>
                        <input type="text" class="form-control" v-model="address.billing_vat_number">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>{{ translate('Chamber of commerce', 'entities') }}</label>
                        <input class="form-control" v-model="address.chamber_of_commerce">
                      </div>
                    </div>

                    <div class="col-md-8">
                        <div class="form-group">
                            <label>{{ translate('Street Name', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_address">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Street Number', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_street_number">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ translate('Address line 2', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_additional_information">
                            <small class="form-text text-muted">{{ translate('Ex: Apartment, suite, unit, building, floor, etc.', 'global') }}</small>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('ZIP', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_postcode">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('City', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_city">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('State', 'entities') }}</label>
                            <input class="form-control" v-model="address.address_state">
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-4" v-if="countries">
                        <div class="form-group">
                            <label>{{ translate('Country', 'entities') }}</label>
                            <v-select :options="countries" v-model="address.address_country" class="select2-form"
                                label="value" :reduce="value => value.key"></v-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ translate('Is for billing', 'entities') }}</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                            type="checkbox"
                                            name="is_for_billing"
                                            id="is_for_billing"
                                            :true-value="1"
                                            :false-value="0"
                                            class="custom-control-input"
                                            v-model="address.is_for_billing"
                                    />
                                    <label for="is_for_billing" class="custom-control-label">

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ translate('Is for billing default', 'entities') }}</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                            type="checkbox"
                                            name="is_for_billing_default"
                                            id="is_for_billing_default"
                                            :true-value="1"
                                            :false-value="0"
                                            class="custom-control-input"
                                            v-model="address.is_for_billing_default"
                                    />
                                    <label for="is_for_billing_default" class="custom-control-label">

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ translate('Is for shipping', 'entities') }}</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                            type="checkbox"
                                            name="is_for_shipping"
                                            id="is_for_shipping"
                                            :true-value="1"
                                            :false-value="0"
                                            class="custom-control-input"
                                            v-model="address.is_for_shipping"
                                    />
                                    <label for="is_for_shipping" class="custom-control-label">

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ translate('Is for shipping default', 'entities') }}</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                            type="checkbox"
                                            name="is_for_shipping_default"
                                            id="is_for_shipping_default"
                                            :true-value="1"
                                            :false-value="0"
                                            class="custom-control-input"
                                            v-model="address.is_for_shipping_default"
                                    />
                                    <label for="is_for_shipping_default" class="custom-control-label">

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end">
          <router-link class="btn btn-white rounded-pill" :to="`/webshop-customers/${$route.params.id}/addresses`">
              {{ translate('Go back', 'global') }}
          </router-link>

          <!-- On refactor please note that here we check 'addressId' but not 'id'-->
          <button class="btn btn-primary rounded-pill" @click="save">
            <span v-if="$route.params.addressId">{{ translate('Save changes', 'global') }}</span>
            <span v-else>{{ translate('Create', 'global') }}</span>
          </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditCustomerAddress",
    data() {
        return {
            countries: null,
            address: {
                is_for_billing: 0,
                is_for_billing_default: 0,
                is_for_shipping: 0,
                is_for_shipping_default: 0,
            }
        }
    },
    methods: {
        async loadAddress() {
            if (this.$route.params.addressId) {
                this.$store.state.system.isLoading = true
                let address = await this.erp.ext.request.axiosInstance.get(`/customers/addressbook/${this.$route.params.addressId}`)
                this.address = address.data.data
                this.$store.state.system.isLoading = false
            }
        },
        async save() {
            try {

                if(this.$route.params.addressId){
                    await this.erp.ext.request.axiosInstance.put(`/customers/addressbook/${this.$route.params.addressId}`, this.address).then(res => {
                        this.$toast.requestSuccess('put', 'Address')
                        this.$emit('reload')
                        this.$router.push(`/webshop-customers/${this.$route.params.id}/addresses`)
                    }).catch(error=>{
                        this.$alert.formattedError(error)
                    })
                }else{
                    await this.erp.ext.request.axiosInstance.post(`/customers/addressbook`, this.address).then(res => {
                        this.$toast.requestSuccess('post', 'Address')
                        this.$emit('reload')
                        this.$router.push(`/webshop-customers/${this.$route.params.id}/addresses`)
                    }).catch(error=>{
                        this.$alert.formattedError(error)
                    })
                }
            } catch (e) {

            }
        }
    },
    async created() {
        await this.loadAddress()
        if (this.$route.fullPath.includes('create')) this.address.customer_id = this.$route.params.id

        let countries = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country?perpage=9999')
        this.countries = countries.data.data
    },
    watch: {
        '$route.params.addressId': {
            handler() {
                this.loadAddress()
            }
        }
    }
}
</script>

<style scoped>

</style>
