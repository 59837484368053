<template>
  <div class="invoice-table-index">
    <div class="row">
      <div class="col-md-12 d-flex">
        <div class="flex-column align-items-start">
          <page-title :page-title="translate('Invoices', 'entities')" :borderBottom="false"></page-title>
        </div>
        <div class="col text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="btn-group">
              <span class="caret" style="padding:0.3rem">{{ translate('Select a period', 'invoices') }}</span>
              <v-select
                style="width: 150px;"
                :options="['this week', 'this month','this quarter', 'this year', 'All']"
                v-model="period"
                :clearable="false"
                @input="choosePeriod()"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="align-items-end" style="margin-left:auto">
          <button class="btn btn-primary rounded-pill"
            @click="createInvoice()"
          >{{ translate('Add new invoice', 'invoices') }}</button>
        </div>
      </div>
      <div class="col-md-4" v-for="item in amount">
        <div class="main-table">
          <div class="d-flex flex-column tab-amaount">
            <div class="d-flex justify-content-between align-items-center p-3">
              <div class="d-flex flex-column">
                <span >{{item.name}}</span>
                <span  v-if="item.name=='Earnings'">€ {{item.total}}</span>
                <span  v-else>{{item.count}} {{ translate('items', 'invoices') }}</span>
                <span  v-if="item.name=='Earnings'">{{ translate('Includes', 'invoices') }} € {{item.vat}} {{ translate('VAT', 'invoices') }}</span>
                <span  v-else>€ {{item.total}} {{ translate('incl', 'invoices') }} {{item.vat}} {{ translate('VAT', 'invoices') }}</span>
              </div>
              <i :class="item.class"   style="color: #ff0553; font-size: 1.5rem" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card filter-nav">
            <div class="card-body project-showview px-2 py-0">
                <ul class="nav nav-tabs scope-tabs">
                <li class="nav-item pt-3" v-for="tab in tabs">
                    <a
                    href="javascript:;"
                    :style="{color: tab.color}"
                    :class="{'active' : tab.isActiveTab}"
                    class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
                    @click="selectTab(tab)"
                    >{{tab.name.replace('_', ' ')}}
                    <span class="counter ml-4">
                    {{tab.number}}
                    </span>
                    </a>
                </li>
                </ul>
            </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="main-table">
          <div style="display: flex">
            <div id="search-field" class="d-flex justify-content-between p-2">

                <span>
                  <i class="fa fa-search mr-3 p-2"></i>
                </span>

                <input type="text" id="search" placeholder="Search" name="search" @input="e => loadSearch(e.target.value)" />
              </div>
              <div class="col text-right p-2">
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <span class="caret" style="padding:0.3rem">{{ translate('Per page', 'global') }}</span>
                    <v-select
                      style="width: 150px;"
                      :options="['5','10', '20', '50']"
                      v-model="tableParams.perpage"
                      :clearable="false"
                      @input="loadInvoices(null)"
                    ></v-select>
                  </div>
                </div>
              </div>
              <a
                href="javascript:;"
                v-tooltip="show.tool"
                class="action-item mr-3"
                style="padding: 0.5rem;"
                v-if="show.filters"
                @click="show.filters = false; show.tool='Show filters'"
              >
                <i class="fas fa-times" style="padding: 0.5rem;"></i>
              </a>
              <a
                href="javascript:;"
                v-tooltip="show.tool"
                class="action-item mr-3"
                style="padding: 0.5rem;"
                v-else
                @click="show.filters = true; show.tool='Close filters'"
              >
                <i class="fas fa-filter" style="padding: 0.5rem;"></i>
              </a>
            </div>
            <transition name="slide">
              <invoice-filters @filter="loadFilters(filters)" v-if="show.filters" key="Invoices" :data="filters || []"></invoice-filters>
            </transition>
            <div class="table-responsive" style="overflow-x: visible;">
              <table class="table m-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" class="sortable" @click="sortByColumn('invoice_number')">
                        {{ translate('NR', 'invoices') }}
                        <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col" class="sortable" @click="sortByColumn('description')">
                      {{ translate('Description', 'invoices') }}
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col" class="sortable" @click="sortByColumn('client_name')">
                      {{ translate('CUSTOMER NAME', 'invoices') }}
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col" class="sortable" @click="sortByColumn('invoice_date')" >
                      {{ translate('SENT ON', 'invoices') }}
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col" class="sortable" @click="sortByColumn('logs')">
                      {{ translate('REMINDERS', 'invoices') }}
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col">{{ translate('STATUS', 'invoices') }}</th>
                    <th scope="col">{{ translate('PAID or TOTALS', 'invoices') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr-1" v-for="(invoice, i) in invoicies" :key="i">
                    <td>
						<div class="custom-control custom-checkbox">
							<input :id="`invoice-` + i"
								   type="checkbox" class="custom-control-input">
							<label :for="`invoice-` + i" class="custom-control-label"></label>
						</div>
                    </td>
                    <td @click="showInvoice(invoice.id)">
                      <span role="button">{{invoice.number}}</span>
                    </td>
                    <td>{{invoice.description}}</td>
                    <td>
                      <img :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`" alt="Avatar" class="avatar" />
                      {{invoice.client_name}}
                    </td>
                    <td>{{invoice.date}}</td>
                    <td>{{invoice.reminders}}</td>
                    <td :style="{color: invoice.color}">{{invoice.status}}</td>
                    <td style="border-radius:1.4rem;">
						<div class="vag w-75"
						  :style="{color: invoice.color,'background-color': invoice.backColor, 'border': '1px solid' +
						   invoice.backColor}">
						{{invoice.currency_code}} {{invoice.payd}} / {{invoice.currency_code}} {{invoice.total_amount}}
						</div>
					</td>
					  <td class="actions-cta">
						  <cta>
							  <action-button
								  @click.native="showInvoice(invoice.id)"
								  class-icon="fa-eye">
							  </action-button>
							  <action-button
							  	@click.native="sendReminder()"
								class-icon="fa-exclamation-triangle">
							  </action-button>
							  <action-button
								  @click.native="sendAgain()"
								  class-icon="fa-paper-plane">
							  </action-button>
							  <action-button
								  @click.native="creditNote()"
								  class-icon="fa-sticky-note">
							  </action-button>
						  </cta>
					  </td>
<!--                    <td>-->
<!--                    <div style="vertical-align: middle; position: absolute;">-->
<!--                      <div style="cursor:pointer" @click="invoice.open=!invoice.open">-->
<!--                        <i class="fa fa fa-ellipsis-v"></i>-->
<!--                      </div>-->
<!--                      <div-->
<!--                        v-if="invoice.open"-->
<!--                        class="dropdown-menu dropdown-menu-arrow dropdown-menu-right p-lg-4"-->
<!--                        style="display:block; margin-top:0.7rem"-->
<!--                      >-->
<!--                        <div class="pb-1 pt-1 delimiter-bottom">-->
<!--                          <a-->
<!--                            href="javascript:;"-->
<!--                            class="d-flex align-items-center dropdown-item"-->
<!--                            @click="showInvoice(invoice.id)"-->
<!--                          >-->
<!--                            <span style="padding-left:0.5rem">Open invoice</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="pb-1 pt-1 delimiter-bottom">-->
<!--                          <a-->
<!--                            href="javascript:;"-->
<!--                            class="d-flex align-items-center dropdown-item"-->
<!--                            @click="sendReminder()"-->
<!--                          >-->
<!--                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>-->
<!--                            <span style="padding-left:0.5rem">Send reminder</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="pb-1 pt-1 delimiter-bottom">-->
<!--                          <a-->
<!--                            href="javascript:;"-->
<!--                            class="d-flex align-items-center dropdown-item"-->
<!--                            @click="sendAgain()"-->
<!--                          >-->
<!--                            <i class="fa fa-paper-plane" aria-hidden="true"></i>-->
<!--                            <span style="padding-left:0.5rem">Send again</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="pb-1 pt-1 delimiter-bottom">-->
<!--                          <a-->
<!--                            href="javascript:;"-->
<!--                            class="d-flex align-items-center dropdown-item"-->
<!--                            @click="creditNote()"-->
<!--                          >-->
<!--                            <i class="fas fa-sticky-note"></i>-->
<!--                            <span style="padding-left:0.5rem">Credit note</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    </td>-->
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :disabled="loading"
              :container-class="'d-flex justify-content-between'"
              @goToPage="loadPage"
              v-if="value.meta"
              :value="value.meta"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { db, modules, routes } from "@/utilities/database";
import { debounce } from "debounce";
import { buildHttpFilterObject , urlEncoder} from "@/utilities/helper";
import InvoiceFilters from "@/modules/inventory/views/invoices/InvoiceFilter";
import moment from "moment";

import { ErpModel } from '@/modules/erp_framework'

export default {
  name: "InvoiceIndex",
  components: {
    InvoiceFilters
  },
  data() {
    return {
      tabs: [
        {name: "All", number: 123, isActiveTab: true}
      ],
      amount: [{name: 'Earnings', class: "fas fa-money-bill-wave"}, {
        name: 'Sent items',
        class: "fa fa-paper-plane"
      }, {name: 'To late', class: "fa fa-ban"}],

      invoicies: [],
      loading: false,
      page: 1,
      value: {},
      tableParams: {
        page: 1,
        column: 'invoice_number',
        direction: "asc",
        type: "number",
        perpage: 5,
      },
      filter_groups: {},
      statusKey: '',
      period: 'All',
      show: {filters: false, tool: "Show filters"},
      filters: [{key: 'client name', value: null, condition: 'like', type: 'text', numGroup: 3}, {
        key: 'invoice number',
        value: null,
        type: 'number',
        condition: 'like',
        numGroup: 4
      },
        {key: 'invoice date', value: null, condition: 'like', type: 'date', numGroup: 5}, {
          key: 'total amount from',
          value: null,
          type: 'number',
          condition: '>',
          numGroup: 6
        },
        {key: 'total amount till', value: null, type: 'number', condition: '<', numGroup: 7}, {
          key: 'invoice date from',
          value: null,
          condition: '>',
          type: 'date',
          numGroup: 8
        },
        {key: 'invoice date till', value: null, condition: '<', type: 'date', numGroup: 9}]
    };
  },
  computed: {},
  async created() {

    this.choosePeriod();
    // this.loadInvoices();
    this.loadInfo();

  },
  methods: {
    choosePeriod() {
      if (this.period == 'All') {
        delete this.filter_groups[0];
        this.tableParams.page = 1;
        this.loadInvoices(this.filter_groups);
      } else {
        let newPeriod = this.period.replace('this ', '')
        let date = moment().startOf(newPeriod).subtract(1, "days").format("YYYY-MM-DD")
        this.filter_groups[0] = {
          'filters': {
            0: {'key': 'invoice_date', 'value': date, 'condition': '>'}
          }
        }
        this.tableParams.page = 1;
        this.loadInvoices(this.filter_groups);
      }
    },
    sortByColumn(column) {
      let direction = (this.tableParams.direction =
          this.tableParams.direction == "desc" ? "asc" : "desc");
      this.tableParams.column = column;
      if (column == 'client_name' || column == 'description' || column == 'logs') {
        this.tableParams.type = "text"
      } else if (column == 'invoice_number') {
        this.tableParams.type = "number"
      } else if (column == 'invoice_date') {
        this.tableParams.type = "date"
      }
      this.tableParams.direction = direction;
      this.loadInvoices(this.filter_groups);
    },
    showInvoice(id) {
      this.$router.push("showview-send/" + id);
    },
    createInvoice() {
      this.$router.push("/invoice-create");
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActiveTab = tab.name == selectedTab.name;
      });
      if (selectedTab.name == 'All') {
        delete this.filter_groups[1];
        this.tableParams.page = 1;
        this.loadInvoices(this.filter_groups);
      } else {
        this.filter_groups[1] = {
          'filters': {
            0: {'key': 'status', 'value': selectedTab.name, 'condition': '='}
          }
        }
        this.tableParams.page = 1;
        this.loadInvoices(this.filter_groups);
      }

    },
    loadPage(page) {
      this.tableParams.page = page;
      this.loadInvoices(this.filter_groups);
    },
    loadFilters(filters) {
      filters.forEach(elem => {
        if (!elem.value) {
          delete this.filter_groups[elem.numGroup]
        } else {
          let el = Object.assign({}, elem);
          el.key = el.key.replace(' till', '')
          el.key = el.key.replace(' from', '')
          el.key = el.key.replace(' ', '_');
          delete el.type;
          delete el.numGroup;
          if (el.condition == 'like') {
            el.value = `%${el.value}%`
          }
          this.filter_groups[elem.numGroup] = {
            'filters': {
              0: el
            }
          }
        }
      })
      this.tableParams.page = 1
      this.loadInvoices(this.filter_groups)
    },
    loadSearch(value) {

      this.filter_groups[2] = {
        'filters': {
          0: {key: 'billing_client_first_name', value: `%${value}%`, condition: 'like'},
          1: {key: 'invoice_number', value: `%${value}%`, condition: 'like'}
        }
      }

      this.tableParams.page = 1
      this.loadInvoices(this.filter_groups)
    },
    loadInfo() {
      db.get(`${routes.invoice.index}/info`)
          .then(res => {
            this.tabs[0].number = res.data.count
            for (const [key, value] of Object.entries(res.data.statuses)) {

              let status = {
                name: key,
                number: value,
                isActiveTab: false,
              }
              this.tabs.push(status)
            }
            this.amount[0].total = res.data.received_amount.toFixed(2);
            this.amount[0].vat = res.data.received_tax.toFixed(2);
            this.amount[1].total = res.data.sent_amount.toFixed(2);
            this.amount[1].vat = res.data.sent_tax_amount.toFixed(2);
            this.amount[1].count = res.data.statuses.sent.toFixed(2);
            this.amount[2].total = res.data.late_amount.toFixed(2);
            this.amount[2].vat = res.data.late_tax_amount.toFixed(2);
            this.amount[2].count = res.data.statuses.late.toFixed(2);
          })
          .catch((err) => {
            return Promise.resolve(null);
          });
    },
    loadInvoices: debounce(async function (filter_groups) {
      if (filter_groups === null) {
        filter_groups = this.filter_groups
      }

      let length = this.invoicies.length;
      this.invoicies.splice(0, length);
      this.loading = true;
      this.$store.state.system.isLoading = true;
      let params = null
      if (filter_groups) {
        params = {
          'filter_criteria': {
            filter_groups
          },
          'sort': [{
            column: 'created_at',
            direction: 'desc',
          }]
        }
      }
      db.get(
          `${routes.invoice.index}?${buildHttpFilterObject({...this.tableParams})}&${urlEncoder(params)}`
      ).then((res) => {
        res.data.data.forEach((el) => {
          let invoice = {};
          invoice.open = false;
          invoice.number = el.invoice_number;
          el.description
              ? (invoice.description = el.description)
              : (invoice.description = "No description");
          el.client != null && el.client.options[0].value != ""
              ? (invoice.client_name = el.client.options[0].value)
              : (invoice.client_name = `${el.billing_client_first_name} ${el.billing_client_last_name}`);
          invoice.date = el.invoice_date;
          invoice.reminders = "";
          el.logs.forEach(rem => {
            invoice.reminder.cocncat(`${rem};`)
          });
          invoice.status = el.status;
          el.currency_code == 'EUR' ? invoice.currency_code = '€' : invoice.currency_code = el.currency_code;
          el.payd ? (invoice.payd = el.payd) : (invoice.payd = 0.00.toFixed(2));
          invoice.total_amount = el.total_amount;
          invoice.id = el.id;
          if (el.status.includes('sent')) {
            invoice.color = '#c38a0d',
                invoice.backColor = '#fdf3da'
          } else if (el.status.includes('paid')) {
            invoice.color = 'green',
                invoice.backColor = '#00800061'
          }

          this.invoicies.push(invoice);
        });
        this.value = Object.assign({}, res.data);
        this.loading = false;
        this.$store.state.system.isLoading = false
      }).catch((err) => {
        this.loading = false;
        this.$store.state.system.isLoading = false
        return Promise.resolve(null);
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
    .invoice-table-index {
        .main-table {
                margin: 1rem 0;
                border-radius: 5px;
                background-color: #fff;

                table {
                    border-radius: 5px;

                    thead {
                        background-color: #fcfcfc;
                    }

                    tr {
                        border-bottom: 1px solid #e5edf5;

                        th {
                            border-bottom: 0;
                            border-color: #e5edf5;
                        }

                        td {
                            background-color: #fff;

                            .avatar {
                                vertical-align: middle;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }
                        }
                    }

                }
            }


        // .txt_1 {
        //     font-weight: 300;
        // }

        // .txt_3 {
        //     font-weight: 300;
        // }

        // #search-field,
        // .table,
        // .tr-1,
        // .tr-2,
        // .tr-3,
        // .tr-4 {
        //     border-bottom: 1px solid black;
        // }

        #search {
            border: none;
        }

        .vag {
            border: 1px solid;
            border-radius: 10px;
            text-align: center;
        }

        // #filter {
        //     border-radius: 5px;
        //     border: 1px solid grey;
        // }

        // .thead-light {
        //     background-color: #f5f5f5;
        // }

        // .avatar {
        //     vertical-align: middle;
        //     width: 30px;
        //     height: 30px;
        //     border-radius: 50%;
        // }

        // #prev,
        // #next {
        //     border: 1px solid black;
        //     background-color: white;
        //     font-weight: 700;
        // }

        // .pagination .active {
        //     box-shadow: none;
        //     border-bottom: 2px solid red;
        // }
        // .tab-amaount {
        //     border-radius: 10px;
        // }

		.actions-cta {
			.cta-container .action-container {
				top: 0;
			}
		}
    }
</style>
