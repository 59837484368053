// Onboarding

/*
 * if validation_required is enabled 'prev' and 'next' buttons are inside the respective template
 */

import Index from "@/modules/onboarding/views/Index.vue";
import Identity from "@/modules/onboarding/views/Identity.vue";
import ColorSchema from "@/modules/onboarding/views/ColorSchema.vue";
import Font from "@/modules/onboarding/views/Font.vue";
import Layout from "@/modules/onboarding/views/Layout.vue";
import Logo from "@/modules/onboarding/views/Logo.vue";
import WebsiteFinish from "@/modules/onboarding/views/WebsiteFinish.vue";

// Sitecart only
import CompanySettings from "@/modules/onboarding/views/sitecart/CompanySettings.vue";
import VatSettings from "@/modules/onboarding/views/sitecart/VatSettings.vue";
import ShippingSettings from '@/modules/onboarding/views/sitecart/ShippingSettingsTODO.vue'
import PaymentSettings from "@/modules/onboarding/views/sitecart/PaymentSettingsTODO.vue";
import Finish from "@/modules/onboarding/views/sitecart/Finish.vue";
// END Sitecart only

export default [
  {
    path: '/onboarding/',
    name: 'onboarding',
    component: Index,
    children: [
      {
        path: 'identity',
        name: 'identity',
        component: Identity,
        meta: {
          title: 'Enter site identity',
          validation_required: true,
          next: 'color-schema',
        },
      },
      {
        path: 'color-schema',
        name: 'color-schema',
        component: ColorSchema,
        meta: {
          title: 'Let\'s start designing your project',
          description: 'Kickstart with a color schema, you can change this anytime',
          prev: 'identity',
          next: 'font',
        },
      },
      {
        path: 'font',
        name: 'font',
        component: Font,
        meta: {
          title: 'Style is everything',
          description: 'Choose font set and colors, you can change this anytime',
          prev: 'color-schema',
          next: 'layout',
        },
      },
      {
        path: 'layout',
        name: 'layout',
        component: Layout,
        meta: {
          title: 'Layout',
          description: 'Choose the layout you like, you can change this anytime',
          prev: 'font',
          next: 'logo',
        },
      },
      {
        path: 'logo',
        name: 'logo',
        component: Logo,
        meta: {
          title: 'Logo',
          description: 'Choose a logo you want to use in your website',
          prev: 'layout',
          next: 'website-finish',
        },
      },
      {
        path: 'website-finish',
        name: 'website-finish',
        component: WebsiteFinish,
        meta: {
          title: '',
        },
      },

      // Sitecart only - Additional steps

      {
        path: 'company-settings',
        name: 'company-settings',
        component: CompanySettings,
        meta: {
          title: 'Company settings',
          description: 'Enter company / shipping address where products are shipped from!',
          validation_required: true,
          next: 'vat-settings',
        },
      },
      {
        path: 'vat-settings',
        name: 'vat-settings',
        component: VatSettings,
        meta: {
          title: 'VAT settings',
          description: 'Enter VAT settings',
          prev: 'company-settings',
          //next: 'shipping-settings',
          next: 'finish',
        },
      },
      /* TODO
      {
        path: 'shipping-settings',
        name: 'shipping-settings',
        component: ShippingSettings,
        meta: {
          title: 'Shipping settings',
          description: 'Enter shipping settings',
          prev: 'vat-settings',
          next: 'payment-settings',
        },
      },
      {
        path: 'payment-settings',
        name: 'payment-settings',
        component: PaymentSettings,
        meta: {
          title: 'Payment settings',
          description: 'Enter payment settings',
          prev: 'shipping-settings',
          next: 'finish',
        },
      },
      */
      {
        path: 'finish',
        name: 'finish',
        component: Finish,
      },

      // END Sitecart only - Additional steps
    ],
  },
]