<template>
	<div class="media-fm app-filemanager">
        <page-title :page-title="translate('Media library', 'global')"></page-title>
		<file-manager :settings="fileManagerSettings()" :show="true"></file-manager>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import { fileManagerSettings } from '@/utilities/helper'

export default {
  name: 'MediaLibrary',
  created(){
    this.$store.state.fm.left.viewType = 'grid'
  },

  /* hot fix file manager modal (FileManagerModal),
   * on destroying the file manager above the FileManagerModal gets broken and does not show any files
   * so we need to initialize it again
   */
  methods: {
    fileManagerSettings,
    ...mapMutations('system', ['rerenderFileManager']),
  },
  destroyed() {
    this.rerenderFileManager()
  },
  // END hot fix

}
</script>

<style scoped lang="scss">
.media-fm .fm {
	height: 80vh;
}

.app-filemanager {
	.fm .fm-body .fm-modal {
		.btn-light {
			color: #fff !important;
			border-radius: 50rem;
			background-color: $primary;
			border: 1px solid $primary;
			-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
			transition: all .3s ease;

			&:hover, &.focus, &:focus, &:active {
				color: $primary !important;
				background-color: $primary-offset-color;
				border-color: $primary !important;
				-webkit-box-shadow: none;
				box-shadow: none;
				outline: none;
			}

			&.disabled, &:disabled {
				color: #fff;
				background-color: $primary-offset-color;
				border-color: $primary-offset-color;
			}

			&:not(:disabled):not(.disabled) {
				&:hover, &.focus, &:focus, &:active {
					color: $primary !important;
					background-color: $primary-offset-color;
					border-color: $primary-offset-color;
					-webkit-box-shadow: none !important;
					box-shadow: none !important;
					outline: none;
				}
			}
		}
	}
}

.btn-light {
	color: #fff !important;
	background-color: $primary;
	border: 1px solid $primary;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
	transition: all .3s ease;

	&:hover, &.focus, &:focus, &:active {
		color: $primary !important;
		background-color: $primary-offset-color;
		border-color: $primary !important;
		-webkit-box-shadow: none;
		box-shadow: none;
		outline: none;
	}

	&.disabled, &:disabled {
		color: #fff;
		background-color: $primary-offset-color;
		border-color: $primary-offset-color;
	}

	&:not(:disabled):not(.disabled) {
		&:hover, &.focus, &:focus, &:active {
			color: $primary !important;
			background-color: $primary-offset-color;
			border-color: $primary-offset-color;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			outline: none;
		}
	}
}
</style>
