<template>
    <div class="row" v-if="!isWebsitePublished && website && info">
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-paint icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    1. {{ translate('Design your template title', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Design your template description', 'dashboard') }}
                </span>
                <span id="done" class="mt-4" v-if="info.template">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link :to="`/entity/cms-websites/settings/${$store.state.system.scope.value}/18/general_info`" type="button"
                             class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate('Design template', 'dashboard') }}
                </router-link>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-pages icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    2. {{ translate('Create page title', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Create page description', 'dashboard') }}
                </span>
                <span class="mt-4" v-if="info.pages">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link :to="`/builder/cms-pages/create`" type="button"
                             class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate('Create page', 'dashboard') }}
                </router-link>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-menu icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    3. {{ translate('Setup menu title', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Setup menu description', 'dashboard') }}
                </span>
                <span class="mt-4" v-if="info.navigation">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <router-link to="/navigation"
                             type="button" class="btn btn-primary rounded-pill mt-4" v-else>
                    {{ translate(' Create menu', 'dashboard') }}
                </router-link>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-merge-domain icon-erp-2x mb-4"></i>
                <span class="steps_number">
                    4. {{ translate('Setup domain', 'dashboard') }}
                </span>
                <span class="system-text-primary mb-auto text-center">
                    {{ website.objectData.url }}
                </span>
                <span class="mt-4" v-if="website.objectData.url">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{ translate('Done', 'dashboard') }}
                </span>
                <button type="button" class="btn btn-primary rounded-pill my-4" v-else>
                    {{ translate('Add domain', 'dashboard') }}
                </button>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-setup-account icon-erp-2x mb-4"></i>
                <span class="steps_number">5. {{ translate('Setup account title', 'dashboard') }}</span>
                <span class="system-text-primary mb-auto text-center">
                    {{ translate('Setup account description', 'dashboard') }}
                </span>
                <router-link :to="`/my-account/${$store.state.system.authData.selected_company}/general`" type="button"
                             class="btn btn-primary rounded-pill mt-4">
                    {{ translate('Settings', 'dashboard') }}
                </router-link>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="style-comp d-flex flex-column align-items-center p-4 mb-4 flex-grow-1">
                <i class="icon-erp-rocket icon-erp-2x mb-4"></i>
                <span class="steps_number">6. {{ translate('Launch website title', 'dashboard') }}</span>
                <span class="system-text-primary mb-auto text-center">
                        {{ translate('Launch website description', 'dashboard') }}
                    </span>
                <button class="mt-4 btn rounded-pill" @click="$emit('publish')"
                        :class="{'btn-primary': isWebsitePublished, 'btn-secondary': !isWebsitePublished}">
                    <span v-if="isWebsitePublished">
                        {{ translate('Unpublish website', 'dashboard') }}
                    </span>
                    <span v-else>
                        {{ translate('Publish website', 'dashboard') }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SettingsBoxes",
    props: {
        isWebsitePublished: {
            required: true
        },
        website: {
            required: true
        },
        info: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>
