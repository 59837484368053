<template>
  <div class="container">
    <page-title :page-title="translate(`${isImport ? 'Import' : 'Export'} Design Options`, 'settings')">
      <template v-if="isImport" #actions>
        <button class="btn btn-primary rounded-pill" @click="performAction('import')">
          {{ translate('Import Settings', 'global') }}
        </button>
      </template>
    </page-title>
    <div class="app-entities-settings">
      <div class="card rounded bg-white">
        <div class="card-header d-flex justify-content-between align-items-center" @click="visible = !visible">
          <h5>{{ translate(`${isImport ? 'Import' : 'Export'} Design Options`, 'settings') }}</h5>
          <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible }" />
        </div>
        <transition-expand>
          <div v-show="visible" class="card-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-control-label">
                    {{ translate(`${isImport ? 'Import' : 'Export'}`, 'settings') }}
                  </label>
                  <div>
                    <div class="card-body">
                      <textarea
                        v-model="plainData"
                        :placeholder="translate('Paste your JSON', 'entities')"
                        class="form-control mb-2"
                        :readonly="!isImport"
                      />
                      <div class="d-flex">
                        <button v-if="isImport" class="btn btn-primary rounded-pill" @click="performAction('paste')">
                          <i class="fas fa-file-import" /> {{ translate('Paste', 'settings') }}
                        </button>
                        <button v-else class="btn btn-primary rounded-pill" :disabled="!plainData" @click="copyContent">
                          {{ translate('Copy to clipboard', 'entities') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-expand>
      </div>
      <div v-if="isImport" class="d-flex justify-content-end my-3">
        <button class="btn btn-primary rounded-pill" @click="performAction('import')">
          {{ translate('Import Settings', 'global') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { IsJsonString } from "@/utilities/helper"

export default {
  name: 'ImportExportDesignOptions',
  data() {
    return {
      visible: true,
      plainData: '',
    }
  },
  computed: {
    isImport() {
      return this.$route.params.action.includes('import')
    },
  },
  async created(){
    if (!this.isImport) {
      await this.performAction('export')
    }
  },
  methods: {
    async performAction(action) {
      this.$store.state.system.isLoading = true
      if (action === 'import') {
        await this.importSettings()
      } else if (action === 'export') {
        await this.exportSettings()
      } else if (action === 'paste') {
        await this.pasteFromClipboard()
      } else if (action === 'copy') {
        await this.copyContent()
      }
      this.$store.state.system.isLoading = false
    },
    async pasteFromClipboard(){
      this.plainData = await navigator.clipboard.readText()
    },
    async importSettings() {
      if (!this.plainData || !this.plainData.length || !IsJsonString(this.plainData)) {
        this.$toast.error('Invalid input')
        return
      }

      try {
        await this.erp.ext.request.axiosInstance.post('/modules/import/settings', { settings: JSON.parse(this.plainData) })
        this.$toast.success(`Design settings saved`)
      } catch(err) {
        this.$toast.error(err?.message)
      }
    },
    async exportSettings(){
      try {
        const resData = await this.erp.ext.request.axiosInstance.get('/modules/export/settings')
        this.plainData = JSON.stringify(resData.data, null, 2)
      } catch(err) {
        this.$toast.error(err?.message)
      } finally {
        this.$store.state.system.isLoading = false
      }
    },
    // todo refactor #4552213
    async copyContent() {
      try {
        await navigator.clipboard.writeText(this.plainData)
        this.$toast.success('Content copied')
      } catch (err) {}
    },
  },
  watch: {
    '$route.params.action': function (newVal) {
      this.plainData = ''
      if (newVal === 'export') {
        this.performAction(newVal)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card-body textarea {
  height: 300px;
}
</style>