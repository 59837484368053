<template>
  <transition-group
    v-if="group"
    name="slide-toggle"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot></slot>
  </transition-group>

  <transition v-else name="slide-toggle" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "TransitionExpand",
  props: {
    group: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style>
.slide-toggle-enter-active,
.slide-toggle-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.slide-toggle-enter,
.slide-toggle-leave-to {
  height: 0;
}
</style>