<template>
    <div class="container mt-5">
        <div class="row" v-if="payment">
            <div class="offset-md-3 col-md-6">
                <div class="d-flex justify-content-center flex-column border rounded bg-white">
                    <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;" v-if="payment.status === 'paid'">
                        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                        <span class="swal2-success-line-tip"></span>
                        <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                    </div>
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;" v-else>
                        <span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                    <div class="text-center border-bottom py-4">
                        <h6 class="font-weight-bold">
                            {{ translate('You paid', 'orders') }} {{ payment.currency_code }} {{ payment.amount }} {{ translate('successfully', 'orders') }}!
                        </h6>
                    </div>
                    <div class="border-bottom">
                        <div class="p-4">
                            <div class="row">
                                <div class="col-md-6 mb-3"><span class="font-weight-700">{{ translate('Order number', 'orders') }}:</span></div>
                                <div class="col-md-6">{{ payment.order_number }}</div>
                                <div class="col-md-6 mb-3"><span class="font-weight-700">{{ translate('Payment method', 'orders') }}:</span></div>
                                <div class="col-md-6">{{ payment.channel }} ({{ payment.payment_date }})</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center p-4">
                        <button class="btn btn-secondary border rounded-pill" @click="downloadInvoice" v-if="payment.status === 'paid'">{{ translate('Download invoice', 'orders') }}</button>
                        <router-link class="btn btn-primary rounded-pill" to="/">{{ translate('Go to Dashboard', 'orders') }}</router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "Payment",
    data() {
        return {
            payment: null
        }
    },
    async created() {
        this.$store.state.system.isLoading = true
        let payment = await this.erp.ext.request.axiosInstance.get(`modules/financial/invoice-payments/${this.$route.params.id}`)
        this.payment = payment.data.data[payment.data.data.length - 1]
        this.$store.state.system.isLoading = false

    },
    methods: {
        downloadInvoice() {

        }
    }
}
</script>
