<template>
  <div class="container app-entities-settings">
    <page-title :page-title="interfaceName+translate(' & Localization', 'settings')">
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div class="row" v-if="website">

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.website_details = !visible.website_details">
            <h5>{{ translate(interfaceName + ' details', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.website_details === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.website_details" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Name', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.name" class="bg-white form-control" name="name"
                             @input="v => website.set('name', v.target.value)">
                    </div>
                  </div>
                </div>

              <div class="col-md-12">
                <div class="form-group">
                    <label>{{ translate('Description', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.description" class="form-control bg-white" name="email"
                             @input="v => website.set('description', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

              <div class="col-md-12">
                <div class="form-group">
                    <label>{{ translate('VAT ID', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.vat" class="form-control bg-white" name="vat"
                             @input="v => website.set('vat', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Chamber of commerce', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.chamber_of_commerce" class="form-control bg-white"
                             name="chamber_of_commerce"
                             @input="v => website.set('chamber_of_commerce', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Language', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2" v-if="allLocales">

                      <!-- Sitecart -->
                      <v-select :options="allLocales"
                                label="name"
                                :reduce="value => value.key"
                                v-model="website.data.website_primary_locale"
                                @input="v => website.set('website_primary_locale', v)"
                                :clearable="false">
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Logo', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <image-field
                        class="picker-secondary w-100"
                        :field-props="{}"
                        v-model="website.data.logo"
                        @input="v => website.set('logo', v)"
                      />
                    </div>
                  </div>
                </div>

                <!--
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Country', 'settings') }} *</label>
                    <div class="d-flex justify-content-between py-2" v-if="countries">
                      <v-select :options="countries"
                                label="value"
                                :reduce="value => value.key"
                                v-model="website.data.country"
                                @input="v => website.set('country', v)">
                      </v-select>
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.website_address = !visible.website_address">
            <h5>{{ translate(interfaceName + ' address', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.website_address === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.website_address" class="card-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Address', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.street_number" class="bg-white form-control" name="street_number"
                             @input="v => website.set('street_number', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('ZIP', 'settings') }} *</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.zip_code" class="bg-white form-control" name="zip_code"
                             @input="v => website.set('zip_code', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('City', 'settings') }} *</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.city" class="bg-white form-control" name="city"
                             @input="v => website.set('city', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('State / Province', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.state" class="bg-white form-control" name="state"
                             @input="v => website.set('state', v.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Country', 'settings') }} *</label>
                    <div class="d-flex justify-content-between py-2" v-if="countries">
                      <v-select :options="countries"
                                label="value"
                                :reduce="value => value.key"
                                v-model="website.data.country"
                                @input="v => website.set('country', v)">
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Phone number', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input v-model="website.data.phone" class="bg-white form-control" name="phone"
                             @input="v => website.set('phone', v.target.value)">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.finances = !visible.finances">
            <h5>{{ translate('Finances', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.finances === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.finances" class="card-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ translate('Primary currency', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2" v-if="currencies">
                      <v-select :options="currencies" label="value"
                                :reduce="value => value.key"
                                v-model="website.data.currency"
                                @input="v => website.set('currency', v)">
                      </v-select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.emails = !visible.emails">
            <h5>{{ translate('Emails', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.emails === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.emails" class="card-body">
              <div class="row">
                <div class="col-md-12 border-bottom">
                  <div class="form-group">
                    <label>{{ translate(interfaceName + ' e-mail', 'settings') }}</label>
                    <field
                        v-model="website.data.email"
                        :field-props="websiteEmailFieldProps"
                        :show-label="false"
                        @input="v => website.set('email', v)"
                    />
                  </div>
                </div>
              </div>

              <entity-settings-form
                :value="emails_to_receive"
                :defaultInputSize="12"
              />
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import EntityForm from '@/modules/erp_entities/components/page/form/EntityForm'
import EntitySettingsForm from "@/modules/erp_entities/components/page/form/EntitySettingsForm.vue";
import ErpModel from "@/modules/erp_framework/services/ErpModel";
import field from "@/modules/erp_entities/components/page/form/InputEntityProps";
import ImageField from "@/modules/erp_entities/components/page/form/fields/ImageField.vue"

import {
  allLocales,
  createEntityFormObjectNoTabs,
  createEntityTypesObjectNoTabs,
  humanReadable
} from "@/utilities/helper";

export default {
  name: 'SettingsWebsiteLocalization',
  components: {
    field, EntityForm, EntitySettingsForm, ImageField,
  },
  data() {
    return {
      website: null,
      countries: null,
      allLocales: null,
      currencies: null,
      visible: {
        website_details: true,
        website_address: true,
        finances: true,
        emails: true,
      },
      websiteEmailFieldProps: {
        type: 'text',
        errors: '',
        rules: ['email'],
      },
      emails_to_receive: [],
    }
  },
  computed: {
    interfaceName() {
      if(this.$store.state.system.interface === 'sitecart') {
        return 'Webshop'
      } // else skittle or blockz
      return 'Website'
    }
  },
  methods: {
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },
    async getEmailsToReceiveSettings(website_id){
      this.$store.state.system.isLoading = true;
      const resEntitiesResponse = await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${website_id}/webshop-settings-cmswebsite/emails_to_receive_notifications`).catch(() => false);
      if(resEntitiesResponse) {
        for (const prop of resEntitiesResponse.data.inputs) {
          let recordProp = resEntitiesResponse.data.data.filter(rec => rec.key == prop.name)
          if (recordProp) {
            let propIndex = resEntitiesResponse.data.data.indexOf(recordProp[0])
            this.emails_to_receive.push({...prop, ...resEntitiesResponse.data.data[propIndex]})
          } else {
            this.emails_to_receive.push({...prop})
          }
        }
        this.$store.state.system.isLoading = false
      }
    },
    async saveEmailsToReceiveSettings(website_id) {

      let data = {
        options: createEntityFormObjectNoTabs(this.emails_to_receive, 'name', 'value', false),
        model: 'cms-websites',
        modelId: website_id,
        settingsSlug: 'webshop-settings-cmswebsite',
      }

      this.erp.ext.request.axiosInstance.post(`/modules/settings/cms-websites/${website_id}`, data).then(response => {

      }).catch(error => {
        console.log(error)
      })
    },
    async save() {
      this.$store.state.system.isLoading = true
      try {
        //Alert success if both requests go through
        await this.website.save()

        // only on sitecart
        if(this.$store.state.system.interface === 'sitecart') {
          await this.saveEmailsToReceiveSettings(this.website.data.id)
        }
        this.$toast.requestSuccess('put', this.interfaceName) // explicit put
      } catch (errors) {
        Object.entries(errors.errors).forEach(([name, errors]) => {
          document.querySelector(`[name="${name}"]`).addClass('is-invalid')
          Object.values(errors).forEach(err => {
            document.querySelector(`[name="${name}"]`).parentElement
                .insertAdjacentHTML('beforeend', `<p class="mb-0" style="color: #dc3545; font-size: 12px">${err}</p>`)
          })
        })
      }
      this.$store.state.system.isLoading = false
    }
  },
  async created() {
    this.$store.state.system.isLoading = true
    await this.findWebsite()

    // only on sitecart
    if(this.$store.state.system.interface === 'sitecart') {
      await this.getEmailsToReceiveSettings(this.website.data.id)
    }

    this.allLocales = await allLocales()
    let countries = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country?perpage=9999')
    this.countries = countries.data.data
    let currencies = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Currency?perpage=9999')
    this.currencies = currencies.data.data
    this.$store.state.system.isLoading = false
  }
}
</script>