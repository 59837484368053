<template>
  <span>
<!--    NO EDIT FOR NOW -->
<!--    <template v-if="!isReadonly">-->
<!--      <textarea v-model="model" class="form-control" :class="fieldClasses" :placeholder="placeholderComputed"></textarea>-->
<!--    </template>-->

    <template>
      <div class="table-responsive expand-field mt-2">
        <table class="table">
          <tr v-for="(value, key) in model" :key="key">
            <td><label>{{ key }}</label></td>
            <td class="text-wrap">
                {{ value }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </span>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"

export default {
  name: 'JsonField',
  mixins: [FormFieldMixin, FormInputMixin],
}
</script>