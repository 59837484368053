import { erp } from "@/modules/erp_framework"
import store from "@/store"
import { isVoidValue } from "@/utilities/helper"

// If there is no data or value, default_value will be set as value
export function createSettingsForm(res) {
  let form = []

  if(res) {
    for (const prop of res.data.inputs) {
      if(prop.environments.includes(store.state.system.interface)){
        // all settings must be treated as system attributes (because of the translations)
        prop.system_attribute = 1

        let value = null
        if(!isVoidValue(prop.default_value)) {
          value = prop.default_value
        }

        let recordProp = res.data.data.filter(rec => rec.key == prop.name)
        if (recordProp.length) {
          let propIndex = res.data.data.indexOf(recordProp[0])
          if(!isVoidValue(res.data.data[propIndex].value)){
            value = res.data.data[propIndex].value
          }
        }

        if(prop.type === 'checkbox'){
          value = parseInt(value)
        } else if(prop.type === 'radio' && !prop.data) {
          value = parseInt(value)
        }

        form.push({ ...prop, ...{ value: value } })
      }
    }
  }

  return form
}

export async function getWebsiteSettings(settingsSlug){
  let websiteSettings = {}
  await erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${store.state.system.scope.value}/${settingsSlug}`).then((res) => {
    websiteSettings = createSettingsForm(res).toKeyValue()
  })
  return websiteSettings
}