<template>
  <div>
    <slot />

    <div v-if="editor.enabled && !$slots.default" id="exampleColumn" class="element-placeholder">
      <h1>{{ translate('Add your first section', 'page-builder') }}</h1>

      <p v-if="can('access', 'cms-template')">{{ translate('Click on the plus button to add a row or use the browse button to choose a template.', 'page-builder') }}</p>
      <p v-else>{{ translate('Use the plus button to add your first row to the website', 'page-builder') }}</p>

      <div class="d-flex">
        <button @click="startBuilding" class="start-button start-button-row mr-2">
          <i class="fas fa-plus fa-2x"></i>
        </button>

        <button v-if="can('access', 'cms-template')"
                @click="$modal.show('content-library-modal')"
                class="start-button start-button-section">
          <i class="icon-erp-folder fa-2x" style="font-size: 0.7rem"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Canvas } from '../../../craft'
import { createNodeFromComponentName } from "@/modules/pagebuilder/craft/utils/createNodeFromVNode"
import busEvent from "@/utilities/eventBus"
import { can } from "@/utilities/helper"

export default {
  name: 'MainContainerElement',
  inject: ['editor', 'node'],
  craft: {
    rules: {
      // Allow only Sections to be placed in MainContainer
      canMoveIn(incommingNode, thisNode) {
        if(incommingNode.componentName === 'Canvas' && incommingNode.props.component === 'SectionElement'){
          return true
        }
        return false
      }
    }
  },
  methods: {
    can,
    startBuilding() {

      const section = createNodeFromComponentName(this.editor, 'SectionElement')
      const row = createNodeFromComponentName(this.editor,'RowElement')
      // column removed on 15.09.2022
      //row.props.settings.grid_layout = '12'
      //const column = createNodeFromComponentName(this.editor,'ColumnElement')

      // row.append(column) // column removed on 15.09.2022
      section.append(row)
      this.node.append(section)

      // does not work without timeout
      setTimeout(() => {
        // column removed on 15.09.2022
        // old
        //this.editor.selectNode(column)
        // new
        this.editor.selectNode(row)
        setTimeout(() => { // does not work without timeout
          busEvent.$emit('rowToolsToggleSettings')
        }, 10)
        // END column removed on 15.09.2022

      }, 10)
    },
  }
};
</script>