<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <devices-dropdown v-if="hasResponsiveStyles" />
    <div class="input-group">
      <input
          :value="value"
          :type="inputType"
          :min="min"
          :max="max"
          :placeholder="translate(placeholder, 'page-builder')"
          @input="e => $emit('input', e.target.value)"
          :style="[inputType==='range' ? {'flex': '0 0 310px', 'margin-right': '20px'} : '']"
          class="form-control"
          :class="{'is-invalid': errors }"
          :ref="fieldRef"
      >

      <template v-if="inputType === 'range'">
        <input
            :value="value"
            type="number"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            @input="e => $emit('input', e.target.value)"
            class="form-control leave-radius"
            :class="{'is-invalid': errors }"
            :ref="fieldRef"
        >
        <div v-if="suffix" class="input-group-append">
          <span class="input-group-text">{{ suffix }}</span>
        </div>
      </template>

      <template v-if="inputType !== 'range'">
        <div v-if="suffix" class="input-group-append">
          <span class="input-group-text">{{ suffix }}</span>
        </div>
      </template>

    </div>
    <small class="help text-danger" v-if="errors" v-text="errors"></small>
  </div>
</template>

<script>
import DevicesDropdown from "@/modules/pagebuilder/components/craft-inputs/DevicesDropdown.vue"

export default {
  name: "CraftInput",
  components: { DevicesDropdown },
  inject: ['editor'],
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    suffix: {
      required: false,
    },
    inputType: {
      default: 'text'
    },
    min: {
      required: false,
    },
    max: {
      required: false,
    },
    fieldProps: {
      type: Object,
      required: false,
    },
    fieldRef: {
      required: false,
      default: 'input',
    },
    placeholder: {
      required: false,
    },
    hasResponsiveStyles: {
      required: false,
      default: false,
    },
  },
  computed: {
    errors() {
      return this.fieldProps && this.fieldProps.errors ? Object.values(this.fieldProps.errors).join(', ') : false
    },
  }
}
</script>
