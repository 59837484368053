function clickToClose(e, el, binding, vNode) {
  if (!(el == e.target || el.contains(e.target))) {
    eval('vNode.context.' + binding.expression + '= false')
  }
}

export default {
  'click-outside': {
    bind(el, binding, vNode) {
      window.addEventListener('click', e => clickToClose(e, el, binding, vNode))
    },

    unbind(el, binding, vNode) {
      window.removeEventListener('click', e => ()=>{}, false)
    },
  },
  'tooltip': {
    bind(el, binding) {
      //Create span element which will have tooltip text
      let insideEl = document.createElement('span')

      //Bind the tooltip text value to the span
      insideEl.innerHTML = binding.value

      //Add class to the span to customize the element
      insideEl.classList.add('tooltip-box')

      //When hover the element append span, so there will be 0 spans when document is ready
      el.onmouseover = function() {
        insideEl.style.display = 'block'
        insideEl.style.zIndex = '999999'
        el.appendChild(insideEl)
      }

      //Remove span when element is not hovered
      el.onmouseout = function() {
        el.removeChild(insideEl)
      }

      //Tooltip box follow's the mouse cursor and if it's on the end of the page, it doesn't go out
      el.addEventListener('mousemove', function (e) {
        insideEl.style.left = (e.pageX + insideEl.clientWidth - 20 < document.body.clientWidth) ? (e.clientX - 20 + "px") : (document.body.clientWidth + 5 - insideEl.clientWidth + "px")
        insideEl.style.top = (e.pageY + insideEl.clientHeight + 20 < document.body.clientHeight) ? (e.clientY + 20 + "px") : (document.body.clientHeight + 5 - insideEl.clientHeight + "px")
      })
    },
  },
}
