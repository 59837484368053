<template>
    <div v-if="entity" class="task-show-view">
        <div class="task-inner">
            <div class="task-header-mobile b-b-pr btn-group">
                <a class="task-header-tabs btn" @click="visible.currentBody = 'left'"
                   :class="{'active': visible.currentBody == 'left'}"> {{ translate('Info', 'tasks') }}
                </a>
                <a class="task-header-tabs btn" @click="visible.currentBody = 'right'"
                   :class="{'active': visible.currentBody == 'right'}"> {{ translate('Activity', 'tasks') }}
                </a>
            </div>
            <div class="task-header b-b-pr">
                <div class="task-left-column">
                    <div class="task-status mr-4 position-relative">
                        <a href="javascript:;" class="btn btn-panel-offset badge-button" v-tooltip="translate('Change task status', 'tasks')"
                           @click="visible.type = !visible.type">
                            {{ translate(entity.options.type.value, 'tasks') }}
                        </a>
                        <div class="task-dropdown priority-dropdown" v-if="visible.type && entityOptions">
                            <a href="javascript:;" @click="updateOption('type', type)"
                               class="system-text-primary mb-2 divider-bottom-fade"
                               v-for="(type, ti) in entityOptions.type" :key="ti">{{type.value}}
                            </a>
                        </div>
                    </div>
                    <div class="task-assignee mr-4">
                        <a href="javascript:;" class="btn-icon-only system-text-primary" v-tooltip="translate('See users and set them what to do', 'tasks')" @click="$modal.show('task_users')">
                            <span v-if="entity.options.assignee">{{ entity.options.assignee.value }}</span>
                            <span v-else>{{ translate('No assignee', 'tasks') }}</span>
                            <span class="ml-2 btn-inner--icon task-icons">
                                <i class="fas fa-user-plus"></i>
                            </span>
                        </a>
                    </div>
                    <div class="task-priority position-relative">
                        <a href="javascript:;" :class="getPriorityKey" v-tooltip="translate('Set priority of the task', 'tasks')"
                           @click="visible.priority = !visible.priority">
                            <span class="badge task-priority-badge">{{ translate(getPriorityKey, 'tasks') }}</span>
                        </a>
                        <div class="task-dropdown priority-dropdown" v-if="visible.priority && entityOptions">
                            <a href="javascript:;" @click="updateOption('priority', priority)"
                               class="system-text-primary mb-2 divider-bottom-fade"
                               v-for="(priority, pi) in entityOptions.priority" :key="pi">{{priority.value}}
                            </a>
                        </div>
                    </div>

                    <div class="task-settings ml-auto">
                        <a href="javascript:;" class="btn-icon-only system-text-primary">
                            <span class="ml-2 btn-inner--icon">
                                <i class="fas fa-ellipsis-h"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="task-right-column">
                    <div class="cu-task-info-row">
                        <div class="cu-task-info">
                            <div class="cu-task-info_created">
                                <span>{{ translate('Created', 'tasks')}}</span>
                                <span>{{ formatDate(entity.data.created_at, null, 'DD MMMM hh:mm') }}</span>
                            </div>
                        </div>
                        <div class="cu-task-info position-relative">
                            <a href="javascript:;" class="system-text-primary d-flex align-items-center" @click="visible.timePicker = !visible.timePicker" v-tooltip="translate('Update estimation', 'tasks')">
                                <span class=" task-icons estimated_time">
                                    <i class="fas fa-clock"></i>
                                </span>
                            </a>
                            <date-picker class="time-picker--est" @change="save" :open="visible.timePicker" v-model="entity.options.estimation" type="time" valueType="format" :input-class="['form-control']"/>
                        </div>
                        <div class="cu-task-info pl-2">
                            <div class="cu-task-info_estimated">
                                <span>{{ translate('Estimation', 'tasks')}}</span>
                                <span>{{ formatDate(entity.options.estimation, 'HH:mm', 'HH:mm') }}</span>
                            </div>
                        </div>
                        <div class="cu-task-info">
                            <div class="cu-task-info_due-date">
                                <span>{{ translate('Deadline', 'tasks')}}</span>
                                <span>{{ timeAgo(entity.options.deadline) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cu-task-info_watchers ml-auto position-relative" v-if="entity.options.watchers">
                        <a href="javascript:;" class="system-text-primary" v-tooltip="translate('Watchers', 'tasks')"
                           @click="visible.watchers = !visible.watchers">
                            <span class="task-icons estimated_time position-relative">
                                <i class="fas fa-eye"></i>
                                <span class="counter-badge">{{ entity.options.watchers.length }}</span>
                            </span>
                        </a>
                        <div class="task-dropdown dropdown-menu-right" v-if="visible.watchers">
                            <a href="javascript:;" class="system-text-primary mb-2 divider-bottom-fade"
                               v-for="(watcher, wi) in entity.options.watchers">
                                {{watcher.value}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="task-body">
                <div :class="{'task-left-column_mobile': true, 'active-mobile': visible.currentBody == 'left'}"
                     class="task-body-main">
                    <div class="task-column_body">
                        <div class="task-name">
                            <div class="task-name-container position-relative" @click="edit.name = true">
                                <div class="task-name_overlay" v-if="!edit.name">{{ entity.options.name }}</div>
                                <input class="task-name_overlay-edit" @focusout="save" @keypress.enter="save"
                                       v-if="edit.name" v-model="entity.options.name">
                                <span class="edit-task-property"><i class="fas fa-pen"></i></span>
                            </div>
                        </div>

                        <div class="task-description mb-4">
                            <div class="task-description-container position-relative">
                                <div class="task-description_overlay" @click="edit.description = true"
                                     v-if="!edit.description">{{ entity.options.description }}
                                </div>
                                <span class="edit-task-property"><i class="fas fa-pen"></i></span>
<!--                                vue-simplemde is removed, if needed use different editor-->
<!--                                <vue-simplemde class="task-description_overlay-edit" v-if="edit.description"-->
<!--                                               v-model="entity.options.description" ref="markdownEditor"/>-->
                                <div class="task-description_actions">
                                    <button v-if="edit.description"
                                            class="btn btn-panel-offset badge-button task-description_actions-button mr-2"
                                            @click="cancel">{{ translate('Go back', 'global') }}
                                    </button>
                                    <button v-if="edit.description"
                                            class="btn btn-panel-offset badge-button task-description_actions-button"
                                            @click="save">{{ translate('Save changes', 'global') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="task-attachments">
                            <div class="task-attachments-container">
                                <div class="task-attachments-header">
                                    <div class="task-attachments_title mb-2">{{ translate('Attachments', 'tasks')}}</div>
                                    <div class="task-attachments-order">
                                        <a href="javascript:;" @click="grid= 'list'" class="system-text-primary mr-2">
                                            <i class="fas fa-list"></i>
                                        </a>
                                        <a href="javascript:;" @click="grid = 'grid'" class="system-text-primary">
                                            <i class="fas fa-th"></i>
                                        </a>
                                    </div>
                                </div>
                                <vue-dropzone multiple v-model="entity.options.attachment" ref="attachments" id="dropzone" @vdropzone-success="addFile" :duplicateCheck="true" :options="dropzoneOptions" :useCustomSlot="true"></vue-dropzone>
                                <div class="task-attachments_grid"
                                     :class="{'grid': grid == 'grid', 'list': grid == 'list'}">
                                    <div class="task-attachments_grid-item">
                                        <img src="">
                                        <div class="task-attachments_grid-item_overlay">
                                            <a href="javascript:;" class="system-text-primary mr-2">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:;" class="system-text-primary">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="task-attachments_grid-item">
                                        <img src="">
                                        <div class="task-attachments_grid-item_overlay">
                                            <a href="javascript:;" class="system-text-primary mr-2">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:;" class="system-text-primary">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="task-attachments_grid-item">
                                        <img src="">
                                        <div class="task-attachments_grid-item_overlay">
                                            <a href="javascript:;" class="system-text-primary mr-2">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:;" class="system-text-primary">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{'task-right-column_mobile': true, 'active-mobile': visible.currentBody == 'right'}"
                     class="task-body-activity">
                    <div class="task-column_body">
                        <div class="task-history-container">
                          <template v-for="(history, hI) in historyTimeline">
                            <div v-if="history.value && history.value != history.previous_value" :key="hI" class="task-history">
                                <b>{{ timeAgo(history.updated_at) }}:</b> {{history.creator_name }}
                                {{ history.previous_value ? 'changed' : 'set' }} {{ history.key }} <span v-if="history.previous_value">{{ translate('from', 'tasks') }} </span>
                                <b>{{ parseValue(history.previous_value) }}</b> {{ translate('to', 'tasks') }}
                                <b>{{ parseValue(history.value) }}</b>
                            </div>
                          </template>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <modal name="task_users"
               :pivot-x="0.5"
               :pivot-y="0.5"
               height="auto"
               :scrollable="true"
               transition="slide-top"
               overlayTransition="wait"
               :adaptive="true"
               classes="remove-modal-height task-users-modal">
            <TaskUsers :entity="entity.options" :entity-options="entityOptions" @saveUsers="updateUsers"></TaskUsers>
        </modal>

    </div>
</template>

<script>
    import EntityMixin from '@/mixins/EntityMixin'
    import TrackableMixin from '@/mixins/TrackableMixin'
    import {mapState} from 'vuex'
    import {db, modules} from "@/utilities/database";
    import {environment, searchArrayByKey, parseValue, searchArrayByName} from "@/utilities/helper";
    import TaskUsers from "./TaskUsers";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'TaskShowView',
        mixins: [TrackableMixin, EntityMixin],
        components: {TaskUsers, DatePicker},
        async created() {
            this.entity = await this.show(this.type + 's', this.$route.params.id)
            this.trackEntity(this.type, this.entity.data.options)
        },
        data() {
            return {
                entity: null,
                visible: {
                    currentBody: 'left',
                    watchers: false,
                    priority: false,
                    type: false,
                    timePicker: false
                },
                edit: {
                    name: false,
                    description: false
                },
                grid: 'grid',
            }
        },
        methods: {
            async save() {
                this.edit.name = false
                this.edit.description = false
                await db.put(`modules/${this.type}s/${this.$route.params.id}`, {
                    options: this.entity.options,
                    widgets: this.entity.widgets
                })
                await db.get(`modules/${this.type}s/${this.$route.params.id}`).then(res => {
                    this.entity.data.options_history = res.data.data.options_history
                })
            },
            addFile(e, res) {
                this.entity.widgets.attachments.push(res.id)
                this.save()
            },
            cancel() {
                this.edit.description = false
                this.entity.options.description = searchArrayByKey(this.entity.data.options, 'description')
            },
            parseValue,
            updateOption(key, value) {
                this.entity.options[key] = value
                this.save()
                this.visible[key] = false
            },
            updateUsers(value) {
                this.entity.options = value
                this.save()
            }
        },
        computed: {
            ...mapState('tracker', ['timeline']),
            dropzoneOptions() {
                return {
                    url: environment.api_domain + `api/modules/attachments/upload`,
                    method: 'post',
                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('authorizationToken')
                    },
                    addRemoveLinks: true,
                }
            },
            historyTimeline() {
                return this.entity.data.options_history.reverse()
            },
            getPriorityKey() {
                if(this.entity.options.priority) {
                    return this.entity.options.priority.key
                }
                return 'none'
            }
        },
        props: {
            type: {
                required: false,
                type: String,
                default: 'task'
            }
        }
    }
</script>

<style scoped>
    .time-picker--est {
        position: absolute;
        visibility: hidden;
        right: -100px;
    }
</style>
