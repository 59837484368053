<template>
  <span>
<!--    NO EDIT FOR NOW -->
<!--    <template v-if="!isReadonly">-->
<!--      <textarea v-model="model" class="form-control" :class="fieldClasses" :placeholder="placeholderComputed"></textarea>-->
<!--    </template>-->

    <template>
      <div v-if="model" class="table-responsive expand-field mt-2">
        <table class="table">
          <tr v-for="(value, key) in model" :key="key">
            <td class="text-wrap">
              {{ fileName(value) }}
            </td>
            <td class="text-right">
              <button @click="downloadFile(fileUrl(value))" class="btn-sm btn-primary rounded-pill">
                <i class="fas fa-download"></i> {{ translate('Download', 'entities') }}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <p v-else>{{ translate('No attachments', 'entities') }}</p>
    </template>
  </span>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"
import {downloadFile} from "@/utilities/helper"

export default {
  name: 'FileUrlsField',
  mixins: [FormFieldMixin, FormInputMixin],
  methods: {
    fileUrl(url){
      return url.startsWith('/') ? url : ('/'+ url)
    },
    fileName(url){
      return url.split('/').pop()
    },

    async downloadFile(url){
      try {
        const response = await fetch(url)
        const blob = await response.blob()

        downloadFile(blob, url.split('/').pop())
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>