<template>
  <span>
    <div class="form-group">
      <image-field
        v-model="image.url"
        :field-props="fieldProps"
      />
    </div>
    <template v-if="image.url">
      <div class="form-group">
        <label>{{ translate('Image alt text', 'page-builder') }}</label>
        <input-field
          v-model="image.alt"
          :field-props="fieldProps"
        />
      </div>
      <div class="form-group">
        <label>{{ translate('Image title', 'page-builder') }}</label>
        <input-field
          v-model="image.title"
          :field-props="fieldProps"
        />
      </div>
    </template>
  </span>
</template>

<script>
import ImageField from "@/modules/erp_entities/components/page/form/fields/ImageField.vue"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { isVoidValue } from "@/utilities/helper"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "ImageAdvancedField",
  components: { ImageField, InputField },
  mixins: [FormFieldMixin],
  data() {
    return {
      image: null,
    }
  },
  created() {
    if (isVoidValue(this.value)) {
      this.image = { url: '', alt: '', title: '' }
      return
    }
    try {
      this.image = JSON.parse(this.value)
    } catch (e) {
      this.image = { url: this.value, alt: '', title: '' }
    }
  },
  watch: {
    image: {
      deep: true,
      handler(val) {
        if (isVoidValue(val.url)) {
          val.alt = null
          val.title = null
          return this.$emit('input', null)
        }
        this.$emit('input', JSON.stringify(val))
      },
    },
  },
}
</script>
