<template>
    <div>
        <div class="btn-group">
            <button @click="visible.currentBody = 'opened'" class="btn btn-primary mr-2 mb-2">{{ translate('Opened Budget', 'project') }}(2)</button>
            <button @click="visible.currentBody = 'closed'" class="btn btn-primary mr-2 mb-2">{{ translate('Closed Budget', 'project') }}(1)</button>
        </div>
        <button class="float-right btn btn-primary btn-sm btn-icon-only rounded-circle ml-4">
            <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
        </button>
        <div v-show="visible.currentBody == 'opened'">
            <div class="w-100 h-auto d-flex mb-3 budget-list">
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Revenue', 'project') }}</span>
                    <span class="pricing">$54.500,00</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Profit', 'project') }}</span>
                    <span class="pricing">28.250,00</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('For invocing', 'project') }}</span>
                    <span class="pricing">17.000,00</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Profit Margin', 'project') }}</span>
                    <span class="pricing">51,8%</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Time', 'project') }}</span>
                    <span class="pricing">245:00</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Billable', 'project') }}</span>
                    <span class="pricing">245:00</span>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Avg. Rate', 'project') }}</span>
                    <span class="pricing">$157,15/{{ translate('h', 'project') }}</span>
                </div>
            </div>

            <div class="w-100 h-auto d-flex budget-list">
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Budget', 'project') }} #</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">5</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">1</a>
                                    <a class="d-flex align-items-center dropdown-item">2</a>
                                    <a class="d-flex align-items-center dropdown-item">3</a>
                                    <a class="d-flex align-items-center dropdown-item">4</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Po Number', 'project') }}</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">/</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">//</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Budget Date', 'project') }}</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">28 Aug, 2019</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">29 Aug, 2019</a>
                                    <a class="d-flex align-items-center dropdown-item">20 Aug, 2019</a>
                                    <a class="d-flex align-items-center dropdown-item">1 Mar, 2019</a>
                                    <a class="d-flex align-items-center dropdown-item">2 Mar, 2019</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Currency', 'project') }}</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">{{ translate('Eur', 'project') }}</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">{{ translate('Dollar', 'project') }}</a>
                                    <a class="d-flex align-items-center dropdown-item">{{ translate('Peso', 'project') }}</a>
                                    <a class="d-flex align-items-center dropdown-item">{{ translate('Lev', 'project') }}</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Client Access', 'project') }}</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">{{ translate('Disabled', 'project') }}</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">{{ translate('Enabled', 'project') }}</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="d-flex flex-column p-3 mr-5 text-center">
                    <span class="pricing-categories text-left">{{ translate('Time Warning', 'project') }}</span>
                    <li class="nav-item dropdown dropdown-animate pricing-categories text-left" data-toggle="hover">
                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false">{{ translate('Off', 'project') }}</a>
                        <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                            <div class="p-lg-4">
                                <div class="pb-1 pt-1 delimiter-bottom">
                                    <a class="d-flex align-items-center dropdown-item">{{ translate('On', 'project') }}</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
            <div class="w-100 d-flex flex-row">
                <div class="budget-box p-3">
                    <div class="d-flex justify-content-between mb-2 sharo">
                        <span class="box-title">{{ translate('Remaining', 'project') }} (50%)</span>
                        <span class="box-main-price">$3.000,00</span>
                    </div>
                    <div class="budget-progress-bar mb-2">
                        <div class="progress-tracker">
                            <div class="budget-progress">
                                <div class="progress-bar-remaining w-25"></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <span>{{ translate('Total budget', 'project') }}</span>
                        <span class="box-price-time">$57.500,00</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>{{ translate('Billable time', 'project') }}</span>
                        <span class="box-price-time"><i class="far fa-circle"></i> 245:00/250:00 {{ translate('hrs', 'project') }}</span>
                    </div>
                </div>
                <div class="budget-box p-3">
                    <div class="d-flex justify-content-between mb-2">
                        <span class="box-title">{{ translate('Revenue', 'project') }}</span>
                        <span class="box-main-price">$54.500,00</span>
                    </div>
                    <div class="budget-progress-bar mb-2">
                        <div class="progress-tracker">
                            <div class="budget-progress">
                                <div class="progress-bar-revenue w-50"></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <span>{{ translate('Current profit', 'project') }} (50%)</span>
                        <span class="box-price-time">$28.250,00</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>{{ translate('Worked time', 'project') }}</span>
                        <span class="box-price-time"><i class="far fa-circle"></i> 245:00/250:00 {{ translate('hrs', 'project') }}</span>
                    </div>
                </div>
                <div class="budget-box p-3">
                    <div class="d-flex justify-content-between mb-2">
                        <span class="box-title">{{ translate('Invoiced', 'project') }} (75%)</span>
                        <span class="box-main-price">$37.500,00</span>
                    </div>
                    <div class="budget-progress-bar mb-2">
                        <div class="progress-tracker">
                            <div class="budget-progress">
                                <div class="progress-bar-invoiced w-75"></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <span>{{ translate('For invoicing', 'project') }}</span>
                        <span class="box-price-time">$17.500,00</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="invoice-action">{{ translate('New invoice or View invoice', 'project') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="visible.currentBody == 'closed'">
            {{ translate('Currency', 'project') }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "Budget",

        data() {
            return {
                visible: {
                    currentBody: 'opened'
                }
            }
        }
    }
</script>

<style scoped>

</style>