<template>
  <!-- 'text_responsive', 'number_responsive', 'int_responsive' -->
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label class="form-control-label">{{ 'Desktop' | translate('entities') }}</label>
        <input-field v-model="data.desktop" :field-props="{ ...fieldProps, type: fieldType }"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label class="form-control-label">{{ 'Tablet Landscape' | translate('entities') }}</label>
        <input-field v-model="data.tabletLandscape" :field-props="{ ...fieldProps, type: fieldType }"/>
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-3">
        <label class="form-control-label">{{ 'Tablet Portrait' | translate('entities') }}</label>
        <input-field v-model="data.tabletPortrait" :field-props="{ ...fieldProps, type: fieldType }"/>
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-3">
        <label class="form-control-label">{{ 'Mobile' | translate('entities') }}</label>
        <input-field v-model="data.mobile" :field-props="{ ...fieldProps, type: fieldType }"/>
      </div>
    </div>

  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: 'InputResponsiveField',
  mixins: [FormFieldMixin, FormInputMixin],
  components: {
    InputField,
  },
  data() {
    return {
      data: {
        desktop: '',
        tabletLandscape: '',
        tabletPortrait: '',
        mobile: '',
      },
    }
  },
  computed: {
    fieldType() {
      return this.fieldProps.type.replace('_responsive', '')
    },
  },
  created() {
    if (this.value && typeof this.value == 'string') {
      this.data = JSON.parse(this.value)
    }
  },
  watch: {
    data: {
      deep: true,
      handler(){
        this.$emit('input', JSON.stringify(this.data))
      },
    },
  },
}
</script>