<template>
  <div class="validation">
    <div class="row">
      <div class="col-10">
        <div class="form-group required col-12">
          <label class="control-label">
            {{ translate('Rule', 'entities') }}
          </label>
          <v-select
            :options="validationRulesList"
            :reduce="value => value.key"
            v-model="selectedRule"
            class="select2-form"/>
        </div>
      </div>
      <div class="col-2 dfc">
        <a href="javascript:;" @click="addRule" class="icon btn-primary rounded-circle icon-sm icon-shape">
          <i class="fas fa-plus"></i>
        </a>
      </div>
    </div>

    <div class="table-responsive mt-3">
      <table class="table system-text-primary">
        <thead>
          <tr>
            <th>
              {{ translate('Rule', 'entities') }}
            </th>
            <th>
              {{ translate('Value', 'entities') }}
            </th>
            <th>
              {{ translate('Source field', 'entities') }}
            </th>
            <th>
              {{ translate('Source value', 'entities') }}
            </th>
            <th>
              {{ translate('Actions', 'global') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="model">
          <tr v-for="(rule, ri) in model" :key="ri">
            <td>
              <span v-tooltip="translate(validationRulesMap[rule.rule].tooltip, 'entities')">
                {{ rule.rule }}
                <span class="ml-1">
                  <i class="fa-solid fa-circle-question" />
                </span>
              </span>
            </td>
            <td>
              <!-- TODO: move to another component with input types -->
              <template v-if="validationRulesMap[rule.rule].inputs.includes('value')">
                <input class="form-control" type="text" v-model="model[ri].value">
              </template>

<!--              OLD WAY -->
<!--              <v-select v-if="rule.input == 'select'" v-model="rule.value" :multiple="rule.valueType == 'multiple'"-->
<!--                        :options="rule.data" class="input-group-sm select2-form"></v-select>-->
<!--              <input class="form-control" type="text" v-if="rule.valueType === true" v-model="rule.value">-->
<!--              <div class="d-flex input-group-sm" v-if="rule.valueType == 'two'">-->

<!--                <input class="form-control mr-3" type="text" v-model="model[ri].value['first']" placeholder="start">-->
<!--                <input class="form-control" type="text" v-model="model[ri].value['second']" placeholder="end">-->

<!--              </div>-->
<!--              <span v-if="!rule.valueType">{{ translate('No validation for this rule', 'entities') }}</span>-->

            </td>
            <td>
              <template v-if="validationRulesMap[rule.rule].inputs.includes('source_name')">

                <v-select
                  v-model="model[ri].source_name"
                  :options="allFieldList"
                  :reduce="option => option.key"
                  :multiple="validationRulesMap[rule.rule].source_type === 'multiselect'"
                  class="select2-form"
                />
              </template>
            </td>
            <td>
              <template v-if="validationRulesMap[rule.rule].inputs.includes('source_value')">
                <input class="form-control" type="text" v-model="model[ri].source_value">
              </template>
            </td>
            <td class="text-center">
              <a href="javascript:;" @click="remove(ri)" class="btn btn-primary rounded-circle icon icon-sm icon-shape">
                <i class="fas fa-minus"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import validationTypes from './../../utilities/validation/types'
import { mapState } from "vuex"
import DataModelMixin from "@/modules/erp_entities/mixins/DataModelMixin"

export default {
  mixins: [DataModelMixin],
  props: {
    properties: {
      required: true,
    },
    currentPropertyName: {
      required: true,
    },
  },
  data() {
    return {
      selectedRule: null,
    }
  },
  computed: {
    ...mapState('entities', ['validationRules']),
    types: () => validationTypes,
    allFieldList() {
      return this.properties.reduce((acc, obj) => {
        if(obj.name !== this.currentPropertyName){ // skip current property
          acc.push({
            key: obj.name, label: this.entityLabel(obj.label),
          })
        }
        return acc
      }, [])
    },
    validationRulesList() {
      return this.validationRules.reduce((acc, data) => {
        acc.push({ key: data.rule, label: data.rule })
        return acc
      }, [])
    },
    validationRulesMap() {
      return this.validationRules.reduce((acc, data) => {
        acc[data['rule']] = data
        return acc
      }, {})
    },
  },
  methods: {
    addRule() {
      if (!this.selectedRule) {
        return this.$toast.error('Select validation rule')
      }
      // todo check if still needed, some rules can be added more than once now
      // alert when try to add same validation twice
      // if (this.value && this.value.some(val => val.name == this.selectedRule)) {
      //   return this.$toast.error('Validation already exists')
      // }

      const newRule = {
        rule: this.selectedRule,
        source_name: '',
        source_value: '',
        value: this.validationRulesMap[this.selectedRule].value, // default value
      }

      this.model.push(newRule)
    },
    remove(index) {
      this.model.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
/*.validation {*/
/*    !*.add {*!*/
/*    !*    display: flex;*!*/
/*    !*}*!*/
/*}*/
</style>
