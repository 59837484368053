<template>
    <a class="fc-time-grid-event fc-v-event fc-event fc-start fc-end">
        <div class="fc-content">
            <div class="fc-time"><span>{{start}} - {{end}}</span></div>
            <div class="fc-title">{{ 'tracker.started_progress_on_event'}}: {{value.data.title}}</div>
        </div>
        <div class="fc-bg"></div>
        <div class="fc-event-description">
            <p>{{value.data.description}}</p>
        </div>
    </a>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        value: {
            required: true
        }
    },
    computed: {
        start(){
            return moment(this.value.data.start).format('H:mm')
        },
        end(){
            return moment(this.value.data.end).format('H:mm')
        },
    }
}
</script>

<style lang="scss" scoped>
.tracker-event {
    background-color: $primary;
    color: $body-color;
    // box
}
</style>
