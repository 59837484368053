<template>
  <div class="bg-white p-3 add-new-category">
    <div v-if="columns">
      <div class="col-md-12">
        <span class="font-weight-bold mb-3 d-block">
          <span>{{ translate('Add new {entity}', 'entities', { entity: entityType }) }}</span>
        </span>
      </div>
      <div class="col-md-12">
        <category-form
            v-model="data"
            :columns="columns"
            :entity="entityName"
            :entity-type="entityType"
            :simple-form="true"
        />
      </div>
      <div class="d-flex justify-content-end mr-3 mt-3">
        <button class="btn btn-white rounded-pill " @click="$modal.hide('create_category')">
          {{ translate('Go back', 'global') }}
        </button>
        <button class="btn btn-primary rounded-pill" @click="create()">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>
    <div class="loader" v-if="$store.state.system.isLoading">
      <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
    </div>
  </div>
</template>

<script>
import { generateSlug, isVoidValue, translationLocales } from "@/utilities/helper"
import CategoryForm from "@/modules/cms/views/categories_and_tags/CategoryForm";

export default {
  name: "CreateCategoryModal",
  components: {
    CategoryForm
  },
  props: ['entityName', 'entityType'],
  data() {
    return {
      data: {},
      type: null,
      columns: null,
      categoriesDropdown: null,
    }
  },
  computed: {
    translationLocales,
  },
  methods: {
    async create(){
      this.$store.state.system.isLoading = true

      // todo refactor #102845167
      if (JSON.stringify(this.data) === '{}' || !this.data.name) {
        this.$store.state.system.isLoading = false
        return this.$toast.info('Title field is required')
      }

      if (JSON.stringify(this.data) === '{}' || !this.data.url_key) {
        this.$store.state.system.isLoading = false
        return this.$toast.info('URL field is required')
      }

      let locales = Object.keys(this.translationLocales)
      for(let i=0; i < locales.length; i++){
        if(isVoidValue(this.data.url_key[locales[i]])){
          this.$store.state.system.isLoading = false
          return this.$toast.info('URL field is required')
        }
      }

      this.data.slug = generateSlug()
      this.data.website_id = this.$store.state.system.scope.value // always send the current website id

      let data = {
        options: {...this.data}
      }
      // END todo refactor #102845167

      data.options.type = this.entityType
      data.options.entity = this.entityName

      await this.erp.ext.request.axiosInstance.post(`/modules/categories`, data)
          .then(res => {
            this.$store.state.system.isLoading = false
            this.$emit('refreshCategories')
            this.data = {}
            this.$toast.requestSuccess('post', 'Category')
            this.$modal.hide('create_category')
          })
          .catch(error => {
            this.$store.state.system.isLoading = false
            this.$alert.formattedError(error)
          })
    },
    async loadTypes() {
      let response = await this.erp.ext.request.get(
          `/modules/categories/nested/categories`,
          this.erp.ext.query()
              .where('entity', '=', this.entityName)
              .where('type', '=', this.entityType)
              .set('perpage', 9999)
              .toString()
      );
      this.type = response;

      if (response && response.data.length) {
        this.type.data = await this.assignTypes(response.data);
      }

      if (response && response.columns) {
        this.columns = response.columns.reduce((acc, column) => {
          acc[column.name] = column
          return acc
        }, {})
      }

    },
    async assignTypes(response) {
      response.forEach(type => {
        type.options = type.options.optionsToObject()
        if (type.children && type.children.length) {
          this.assignTypes(type.children);
        }
      })
      return response;
    },
  },
  async created() {
    this.$store.state.system.isLoading = true
    await this.loadTypes()
    this.$store.state.system.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.add-new-category, .loader {
  border-radius: .375rem;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
