import { render, staticRenderFns } from "./ShippingSettingsTODO.vue?vue&type=template&id=28dd0a41&scoped=true&"
import script from "./ShippingSettingsTODO.vue?vue&type=script&lang=js&"
export * from "./ShippingSettingsTODO.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28dd0a41",
  null
  
)

export default component.exports