<template>
    <div class="container">
        <div class="plan-setup-body py-4 b-b-pr-gr">
            <div class="row">
                <div class="col-md-12" v-for="(limit, li) in get_limits" :key="li">
                    <label class="input-label font-weight-bold">{{ limit.limit_option.name }}</label>
                    <div class="d-flex">
                        <div class="switch-field w-100 d-flex justify-content-between">
                            <div v-for="(option, oi) in limits.options" :key="oi">
                                <input v-model="limit.selected_option" :id="`${li}-${oi}`" :name="`${li}`" :value="option" type="radio">
                                <label class="w-100" :for="`${li}-${oi}`">{{ option }}</label>
                            </div>
                            <input v-model="limit.selected_option" class="custom-input" placeholder="Custom" type="text">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="plan-setup-footer d-flex justify-content-between py-4">
            <router-link to="extensions">
                <button class="btn btn-white rounded-pill">{{ translate('Go back', 'global') }}</button>
            </router-link>
            <span class="step-count">{{ translate('step 4 of 5', 'plans') }}</span>
            <router-link to="pricing">
                <button class="btn btn-primary rounded-pill" @click="setData">{{ translate('Continue', 'global') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {db} from "../../../../../utilities/database";
    import {createEntityFormObjectNoTabs} from "../../../../../utilities/helper";

    export default {
        name: "PlanSetup",
        props: {
            value: {
                required: true
            }
        },
        data() {
            return {
                get_limits: [
                    {
                        id: null,
                        slug: '',
                        selected_option: ''
                    }
                ],
                limits: {
                    options: [1, 3, 5, 10]
                },
            }

        },
        methods: {
            setData() {
                this.get_limits.forEach(limit => {
                    if (limit.selected_option > 0) {
                        this.value.limits.push({
                            id: limit.id,
                            limit: limit.selected_option,
                            name: limit.limit_option.name
                        })
                    }
                })
            },
            loadLimits() {
                db.get(`/modules/blockz/limits`).then(res => {
                    res.data.data.forEach(limit => {
                        limit.limit_option = createEntityFormObjectNoTabs(limit.options, 'key')
                    })
                    this.get_limits = res.data.data
                })
            }
        },
        mounted() {
            this.loadLimits()
        }
    }
</script>

<style scoped lang="scss">
    .switch-field {
        border: 1px solid !important;
        border-radius: 5px !important;

        div, input {
            flex-grow: 1;
            flex-basis: 0;
        }

        .custom-input {
            position: relative !important;
            background-color: $primary-panel-offset-color;
            z-index: 1;
            opacity: 1;
            right: 0;
            height: 100%;
            width: 100%;
            border: none;
            border-left: 1px solid $primary;
            text-align: center;

            &:focus {
                outline: none;
            }
        }

        label {
            margin: 0;
            border-radius: unset !important;
            border-right: 1px solid;

            &:last-of-type {
                border-right: none;
            }
        }
    }

    .step-count {
        font-size: 12px;
        text-transform: uppercase;
        color: #b5c4d9;
    }

    .plan-position-input {
        border: 1px solid #000;
        background-color: #fff;
    }

    .plan-setup-footer {
        .btn-cancel {
            background: #fff;
            border-color: #b5c4d9;
            color: #000;

            &:hover {
                background: var(--secondary-btn-color);
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>