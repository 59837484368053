<template>
  <div class="_responsive-options">
<!--
    <div
      class="desktop"
      :class="{'active': deviceType === 'desktop'}"
      @click="changeDeviceViewport('desktop')"
      v-tooltip="tooltipText('desktop')"
    >
      <span class="ripple">
        <i class="icon-erp-device-responsive" />
      </span>
    </div>
-->

    <div
      class="cursor-pointer"
      v-for="device in devices"
      :class="[device.device, { 'active': deviceType === device.device }]"
      @click="changeDeviceViewport(device.device)"
      v-tooltip="tooltipText(device.device)"
    >
      <span class="ripple">
        <i :class="device.icon" />
      </span>
    </div>

    <span class="block-b-r" />

    <div class="align-items-center">
      <span class="ripple cursor-pointer" @click="zoomOut">
        <i class="fas fa-minus" />
      </span>
      <span class="text-center">{{ zoomLevel }}</span>
      <span class="ripple cursor-pointer" @click="zoomIn">
        <i class="fas fa-plus" />
      </span>
      <span class="text-center cursor-pointer" :class="{ 'ripple' : canRotate }" @click="rotateLayout">
        <i class="fa-solid fa-rotate-left" />
      </span>
    </div>

    <span class="block-b-r" />

    <div class="align-items-center">
      <span class="text-center">W</span>
      <input-field
        :value="size.width"
        :field-props="{ type: 'int', class: 'form-control-sm' }"
        @input="updateFrameWidth"
      />

      <span class="text-center">H</span>
      <input-field
        :value="size.height"
        :field-props="{ type: 'int', class: 'form-control-sm' }"
        @input="updateFrameHeight"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: "EmulatorOptions",
  components: { InputField },
  data() {
    return {
      devices: [
        { device: 'desktop', icon: 'icon-erp-device-desktop' },
        { device: 'tablet', icon: 'icon-erp-device-tablet-portrait' },
        { device: 'mobile', icon: 'icon-erp-device-mobile-portrait' },
      ],
    }
  },
  computed: {
    ...mapState('layout', ['size']),
    ...mapGetters('layout', ['zoomLevel', 'deviceType']),
    canRotate() {
      return typeof this.size.height === 'number'
    },
  },
  methods: {
    ...mapActions('layout', [
      'changeDeviceViewport',
      'updateFrameWidth',
      'updateFrameHeight',
      'zoomOut',
      'zoomIn',
      'rotateLayout',
    ]),
    tooltipText(deviceType) {
      if(deviceType === 'responsive'){
        return this.translate(`Changes will be made for all devices`, 'page-builder')
      }

      // return this.translate(`Changes will be made only for {deviceType} devices`, 'page-builder', { deviceType: deviceType })
      if (deviceType === 'desktop') {
        // Elementor - Desktop <br> Settings added for the base device will apply to all breakpoints unless edited
        return this.translate('Styles applied to the base device will be effective across all breakpoints unless modified.', 'page-builder')
      } else if (deviceType === 'tablet') {
        // Elementor - Tablet Portrait <br> Settings added for the Tablet Portrait device will apply to 1024px screens and down
        return this.translate('Styles applied to the Tablet Portrait device will affect screens of 1199px and smaller.', 'page-builder')
      } else if (deviceType === 'mobile') {
        // Elementor - Mobile Portrait <br> Settings added for the Mobile Portrait device will apply to 767px screens and down
        return this.translate('Styles applied to the Mobile Portrait device will affect screens of 767px and smaller.', 'page-builder')
      }
    },
  },
}
</script>
