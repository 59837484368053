// import Swal from 'sweetalert2/dist/sweetalert2.min'
import { erp } from "@/modules/erp_framework"

// todo rename to CmsContentMixin

export default {
  methods: {
    /* NOT USED ANYMORE
    async savePage() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        })

        let result
        if (!this.data.autosave) {
            result = await swalWithBootstrapButtons.fire({
                title: 'You have items to save',
                text:
                    'You have changes that might you want to save! Do you want to save changes?',
                showCancelButton: true,
                confirmButtonText: 'Save and leave!',
                cancelButtonText: "Don't save and leave",
                reverseButtons: true,
            })
        }

        if (result.dismiss === Swal.DismissReason.backdrop) {
            return Promise.resolve({
                message: 'backdrop',
            })
        }

        if (result.dismiss === Swal.DismissReason.cancel) {
            return swalWithBootstrapButtons.fire({
                title: 'Cancelled',
                text: 'Your page was not saved',
                customClass: {
                    confirmButton: 'btn btn-success cancel-save',
                },
            })
        }

        if ((result && result.value) || this.data.autosave) {
            let data = {
                options: {
                    ...this.data.page.objectData,
                },
            }

            data.options.status = 2
            data.options.content_html = this.dom.outerHTML
            delete data.options.id

            await this.erp.ext.request.axiosInstance.put(
                `/modules/cms-pages/${this.data.page.objectData.id}`,
                data
            )

            if (!this.data.autosave) {
                swalWithBootstrapButtons.fire({
                    title: 'Page saved',
                    text: 'Your page was saved!',
                    customClass: {
                        confirmButton: 'btn btn-success cancel-save',
                    },
                })
            }
        }
    },
    */

    async findCmsContentBySlug(entity, slug, locale) {
      let query = erp.ext.query()
        // .where('website_id', '=', websiteId) todo check with backend team if website id is required
        .where('slug', '=', slug)
        .where('locale', '=', locale)
        .set('perpage', 9999)

      let entities = await erp.ext.request.get(`modules/${entity}`,
        query.toString()
      )

      return entities.data.length ? entities.data[0] : false;
    },

    alertCmsContentRelationExists(){
      this.$toast.error('Translation already exists!')
    },
  },
}