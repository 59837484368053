import localization from "@/localization/all"
import store from "@/store"

const translations = JSON.parse(window.localStorage.getItem('translations'))

export function translate(v, translationGroup = '', args = {}){
  let storageLocale = store.state.system.locale
  let currentLocale = storageLocale ? storageLocale : localization.meta.default
  let result = v

  if (translations && translationGroup) {
    if (translations[currentLocale][translationGroup] && translations[currentLocale][translationGroup][v]) {
      result = translations[currentLocale][translationGroup][v]
    }
  }

  for (const [key, value] of Object.entries(args)) {
    result = result.replaceAll(`{${key}}`, value)
  }

  return result
}

export function entityLabel(value, system_attribute = false, append_shortcodes = false) {
  if (value === null) {
    return ''
  }

  // if object then its translatable label
  if (typeof value === 'object') {
    if (system_attribute) {
      // if system attribute return the english value and translate it through the translation manager
      return translate(value[Object.keys(value)[0]], 'entities')
    } else {
      if(!store.state.system.translation_locale){ // force disable shortcodes if its not multilanguage website
        append_shortcodes = false
      }

      // is translation present
      if(value[store.state.system.translation_locale]) {
        return value[store.state.system.translation_locale]
        // fallback to the default locale
      }else if(value[store.state.system.scope.obj.website_primary_locale]){
        return value[store.state.system.scope.obj.website_primary_locale] + (append_shortcodes ? ' [non_translated]' : '')
        // fallback to first found locale
      } else {
        return (value[Object.keys(value)[0]] || 'Not translated') + (append_shortcodes ? ' [non_translated]' : '')
      }
    }
  } else {
    if (system_attribute) {
      // if system attribute translate it through the translation manager, most settings labels are not objects
      return translate(value, 'entities')
    }
    return value
  }
}

export const erp_translations = {
  // When every package is included run initialize method. should be at very bottom
  initialize({ vue, locale, translation_locale }) {

    // Create filter
    vue.filter('entityLabel', entityLabel)
    vue.filter('translate', translate)

    // Make function available in "this".
    vue.prototype.entityLabel = entityLabel
    vue.prototype.translate = translate
  },
}