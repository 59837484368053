import Swal from 'sweetalert2/dist/sweetalert2.min'
import { translate } from "@/modules/erp_translations"

class SwalMain {
  static show() {
    throw new Error('You must implement the show() in the subclass.')
  }

  static processOptions(options) {
    if (typeof options === 'string') {
      return {
        title: translate(options, 'messages'),
      }
    } else if (typeof options === 'object') {
      return {
        title: translate(options.title, 'messages', options.translateArgs ),
      }
    } else {
      console.error('Invalid parameter type. Expected string or object.')
    }
  }

  static success(title) {
    this.show(title, 'success')
  }
  static error(title) {
    this.show(title, 'error')
  }
  static warning(title) {
    this.show(title, 'warning')
  }
  static info(title) {
    this.show(title, 'info')
  }
  static question(title) {
    this.show(title, 'question')
  }
}

// Basic layouts

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
  showCloseButton: true,
})

const SimpleAlert = Swal.mixin({
  allowEnterKey: false, // disabled because we don't want the styles of focused button
  customClass: {
    confirmButton: 'btn btn-primary rounded-pill',
  },
})

const ConfirmAlert = Swal.mixin({
  allowEnterKey: false, // disabled because we don't want the styles of focused button
  customClass: {
    cancelButton: 'btn btn-white rounded-pill',
    confirmButton: 'btn btn-primary rounded-pill',
    denyButton: 'btn btn-secondary rounded-pill',
  },
  reverseButtons: true,
  showConfirmButton: true,
  showCancelButton: true,
})

class SweetAlert extends SwalMain {
  // title - String or Object
  static show(options, icon) {
    options = this.processOptions(options)

    SimpleAlert.fire({
      title: options.title,
      icon: icon,
    })
  }

  static formattedError(error){
    let allErrors = error.response.data.errors
    let error_message = ""

    if (allErrors) {
      for (const iterator in allErrors) {
        let newErr = allErrors[iterator][0]
        error_message += newErr.replace(/\./g, ' ')
        error_message += "<br>"
      }
    } else if (error.response.data.message && error.response.data.message.length) {
      error_message = error.response.data.message
    } else if (error.response.data.messages && error.response.data.messages.length) {
      error_message = error.response.data.messages.join(', ')
    }

    this.show(error_message, 'error')
  }

  static async confirm(options){
    options = this.processOptions(options)
    return await ConfirmAlert.fire({ title: options.title, icon: 'warning' })
  }

  static async confirmDefault(){
    return await this.confirm('Are you sure?')
  }

  static async confirmDelete(options=false){
    if (!options) {
      options = 'Are you sure? <br>Item will be deleted permanently!'
    }
    return await this.confirm(options)
  }

  static async confirmSaveChanges(){
    return await ConfirmAlert.fire({
      title: translate('Do you want to save the changes?', 'messages'),
      showDenyButton: true,
      icon: 'warning',
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    })
  }

  static async confirmWithInput(message, inputValue){
    return await ConfirmAlert.fire({
      title: translate(message, 'messages'),
      icon: 'info',
      confirmButtonText: 'Continue',
      input: 'text',
      inputAttributes: {
        required: 'true',
      },
      validationMessage: translate('This field is required', 'entities'),
      inputValue: inputValue,
    })
  }

}

class SweetToast extends SwalMain {

  static show(options, icon) {
    options = this.processOptions(options)

    Toast.fire({
      title: options.title,
      icon: icon,
    })
  }

  static formattedActionName(method){
    if(method === 'post') {
      return 'created'
    } else if(method === 'put'){
      return 'updated'
    } else if(method === 'delete'){
      return 'deleted'
    } else {
      return 'saved'
    }
  }

  static errorGeneral(){
    this.error('Something went wrong!')
  }

  // Ajax requests Create, Update, Delete
  static requestSuccess(method='', entity_name='') {
    let message = ''

    if (entity_name) {
      message = {
        title: `{entity} ${this.formattedActionName(method)} successfully!`,
        translateArgs: { entity: entity_name },
      }
    } else {
      message = `${this.formattedActionName(method).capitalizeFirstLetter()} successfully!`
    }

    this.success(message)
  }

  // Ajax requests Create, Update, Delete
  static requestFailed(method='', entity_name='') {
    let message = ''

    if (entity_name) {
      message = {
        title: `{entity} was not ${this.formattedActionName(method)} successfully!`,
        translateArgs: { entity: entity_name },
      }
    } else {
      message = `Not ${this.formattedActionName(method)} successfully!`
    }

    this.error(message)
  }

  static appIsOffline(){
    this.error('No internet connection found. App is running in offline mode.')
  }
}

export {
  SweetAlert,
  SweetToast,
}

// Object.defineProperties(Vue.prototype, {
//   $alert: {
//     get() {
//       return Sweetalert
//     },
//   },
//   $toast: {
//     get() {
//       return Toast
//     },
//   },
// })
