<template>
    <div class="container">
        <div class="card rounded bg-white">
            <div class="card-header d-flex justify-content-between">
                <h5>{{ translate('Export', 'global') }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>{{ translate('Select entity', 'global') }}</label>
                            <select-field
                              v-model="type"
                              :field-props="{ data: entityOptions, system_attribute: 1 }"
                            />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>{{ translate('Select start date', 'global') }}</label>
                            <date-picker type="date" v-model="startDate" valueType="format" :input-class="['form-control']"/>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>{{ translate('Select end date', 'global') }}</label>
                            <date-picker type="date" :disabled="!startDate" :disabled-date="notBeforeFirstDate" v-model="endDate" valueType="format" :input-class="['form-control']"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary rounded-pill" @click="exportOrders">{{ translate('Export', 'global') }}</button>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import SelectField from "@/modules/erp_entities/components/page/form/fields/SelectField.vue"

export default {
    name: "Export",
    components: {
      DatePicker,
      SelectField,
    },
    data() {
        return {
            type: null,
            startDate: null,
            endDate: null,
        }
    },
    created() {
        if (this.$route.params.type) this.type = this.$route.params.type
    },
    computed: {
      entityOptions() {
        return [
          { key: 'orders', value: 'Orders' },
          { key: 'invoices', value: 'Invoices' },
        ]
      },
    },
    methods: {
        notBeforeFirstDate(date) {
            return date < new Date(this.startDate);
        },
        async exportOrders() {
            try {
                await this.erp.ext.request.axiosInstance.post('/modules/sales/export', {
                    type: this.type,
                    start_date: this.startDate,
                    end_date: this.endDate
                }).then(res => {
                    let encodedUri = encodeURI(res.data);
                    let link = document.createElement("a");
                    link.setAttribute("href", `data:text/csv;charset=utf-8,'${encodedUri}'`);
                    link.setAttribute("download", `Exported ${this.type} from ${this.startDate} to ${this.endDate}.csv`);
                    document.body.appendChild(link);
                    link.click();
                })
                this.$toast.success({
                  title: '{type} exported.',
                  translateArgs: { type: this.type },
                })
            } catch (e) {
                this.$toast.error({
                  title: 'No {type} for this dates.',
                  translateArgs: { type: this.type },
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
