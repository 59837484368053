<template>
    <div v-if="entity && entity.options">
<!--        <router-link class="my-2 d-block" to="/entity/inventory-categories">-->
<!--            <i class="fa fa-chevron-left"></i>-->
<!--            {{ translate('inventory.back_to_inventory') }}-->
<!--        </router-link>-->
        <div class="card">
            <div class="card-header cursor-pointer" @click="visible.header = !visible.header">
                <div class="row justify-content-between align-items-center">
                    <div class="col">
                        <h6 class="d-inline-block mb-0" v-if="entity && entity.options">
                            {{entity.options.name}}
                            <span class="ml-2">
                                <i class="fa fa-chevron-down chevron-relative" :class="{'chevron-rotate': visible.header == true}"></i>
                            </span>
                        </h6>
                    </div>
                    <div class="col text-right">

                    </div>
                </div>
            </div>
            <transition-expand>
                <div v-show="visible.header">
                    <div class="card-body" v-if="entity && entity.options">
                        <div class="row">
                            <div class="col-md-3" v-if="entity.options.is_free == 1">
                                <div class="form-group">
                                    <div class="form-control-label mb-2">{{ translate('inventory.set_free_umbrellas') }}</div>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" v-model="entity.options.free_beach_umbrellas">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary btn-sm" @click="save" type="button">{{ translate('global.save') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="form-control-label mb-2">{{ translate('inventory.change_flag') }}</div>
                                    <div class="d-flex align-items-center" v-if="columns && flag">
                                        <v-select class="w-75 select2-form" :options="flag" :reduce="flag => flag.key" label="value" v-model="entity.options.flag" @input="save"></v-select>
                                        <span class="text-center w-25 flag">
                                            <i class="fa fa-flag" v-if="entity && entity.options" :class="entity.options.flag"></i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-expand>
        </div>
        <div class="card" v-if="entity.options.is_free == 2">
            <div class="card-header" v-if="cart.length">
                <div class="row justify-content-between align-items-center cursor-pointer" @click="expand_reservation = !expand_reservation">
                    <div class="col">
                        <h6 class="d-inline-block mb-0">
                            {{ translate('inventory.make-reservation') }}
                            <span class="ml-2">
                                <i class="fa fa-chevron-down chevron-relative" :class="{'chevron-rotate': expand_reservation == true}"></i>
                            </span>
                        </h6>
                    </div>
                </div>
                <transition-expand>
                    <div v-show="expand_reservation">
                        <reservation @successReservation="refresh" @close="expand_reservation = false" v-model="selected_umbrella"></reservation>
                    </div>
                </transition-expand>
            </div>
            <div class="card-body svg-container" v-dragscroll.x="true">
                <svg version="1.1" id="Layer_1"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="1200" height="1024" viewBox="0 0 1200 1024"
                     style="enable-background:new 0 0 1200 1024;" xml:space="preserve">
                  <template v-for="(umbrella, ui) in umbrellas">
                    <um v-if="umbrella.type != 'tent'" :key="ui" @click.native="addToCart(umbrella)" :value="umbrella"></um>
                  </template>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import {db, modules} from "@/utilities/database";
    import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";
    import EntityMixin from "@/mixins/EntityMixin";
    import BeachUmbrella from "@/modules/inventory/views/inventory/show/BeachUmbrella";
    import Reservation from "@/modules/inventory/views/inventory/show/Reservation";
    import {mapActions, mapState} from "vuex";
    import { dragscroll } from 'vue-dragscroll'

    export default {
        name: "ShowInventory",
        directives: {
           dragscroll
        },
        components: {
            'um': BeachUmbrella, Reservation
        },
        mixins: [ EntityMixin],
        props: {
            type: {
                required: false,
                type: String,
                default: 'inventory-categorie'
            }
        },
        data() {
            return {
                visible: {
                    header: true
                },
                entity: null,
                umbrellas: null,
                selected_umbrella: null,
                expand_reservation: false,
                width: 0,
            }
        },
        computed: {
            ...mapState('beachCheckout', ['data', 'cart']),
            flag() {
                if(!this.columns) {
                    return null
                }
                let flags = this.columns.filter(c => c.name == 'flag')
                return flags.length ? flags[0].data : null
            },
        },
        methods: {
            ...mapActions('beachCheckout', ['addToCart', 'getCart']),
            reserve(item) {
                item.reserved = true
                this.expand_reservation = true
                this.selected_umbrella = item
                this.$modal.show('reserve')
            },
            save() {
                let data = {
                    options: this.entity.options,
                }

                delete data.options.map
                delete data.options.svg_mapper
                delete data.options.google_maps

                db.put(`modules/inventory-categories/${this.$route.params.id}`, data)
            },

            refresh() {
                this.umbrellas = []
                this.expand_reservation = false
                this.selected_umbrella = null
                this.loadUmbrellas()
            },

            loadUmbrellas(filter = {'key': 'sector', 'value': '1', 'condition': '='}) {
                this.umbrellas = []

                let filterParams = {
                    'filter_criteria': {
                        'filter_groups': {
                            0: {
                                'filters': {
                                    0: [filter]
                                }
                            },
                        }
                    },
                    'perpage': 1000
                }


                db.get(`/modules/inventory-categories/${this.$router.currentRoute.params.id}/inventories?${urlEncoder(filterParams)}`).then((res, i) => {
                    res.data.data.forEach(i => {
                        let item = createEntityFormObjectNoTabs(i.options, 'key')
                        item = {
                            ...item,
                            id: i.id,
                            qty: 1,
                            reserved: false
                        }
                        item.company_id = i.company_id
                        item.is_available = i.is_available
                        this.umbrellas.push(item)
                        let a = this.umbrellas.map(x => x.position_column)
                        var counts = {};
                        a.forEach(function (x) {
                            counts[x] = (counts[x] || 0) + 1;
                        });

                        this.width = Object.values(counts).length * 50

                    })
                })
            }
        },
        async created() {
            this.entity = await this.show(this.type + 's', this.$route.params.id)
            await this.loadUmbrellas()
            this.getCart()

            //Start session for cart
            let session = window.localStorage.getItem('session')
            if(!session) {
                db.get('/modules/sales/start-user-session').then(res => {
                    window.localStorage.setItem('session', res.data['carttoken'])
                    db.defaults.headers.common['carttoken'] = res.data['carttoken']
                })
            } else {
                db.defaults.headers.common['carttoken'] = session
            }
        },
        watch: {
            '$route.params.id': async function () {
                this.entity = await this.show(this.type + 's', this.$route.params.id)
                this.refresh()
            }
        }
    }
</script>

<style scoped lang="scss">
    .svg-container {
        height: 717px;
        overflow: hidden;
        cursor: grab;
    }

    .flag {
        font-size: 30px;

        .red {
            color: red;
        }
        .green {
            color: green;
        }
        .yellow {
            color: #e4d700;
        }
    }
</style>
