<template>
  <div>
    <!-- KANBAN USEr -->
    <div class="single-table-scroll">
      <table class="table kanban-table">

        <tbody>
          <tr class="grid-board">
            <td
              @click="status.fixed = !status.fixed"
              v-for="(status, statusIndex) in statuses"
              :key="statusIndex"
            >
              <!-- KANBAN COLUMN -->
              <div class="kanban-status b-b-pr">
                <div>{{status.value}}</div>
                <div>{{status.tasks.length}} tasks</div>
              </div>
              <!-- KANBAN TASK -->
              <draggable
                :id="status.key"
                group="tasks"
                v-model="status.tasks"
                class="list-group tasks"
                tag="div"
                v-bind="dragOptions"
                @start="drag = true"
                @end="changeStatus"
              >
                <div
                  :id="task.id"
                  :class="task.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
                  @click="task.fixed = !task.fixed"
                  v-for="(task, taskIndex) in status.tasks"
                  :key="taskIndex"
                >
                  <kanbanTask v-model="status.tasks[taskIndex]" :task="task"></kanbanTask>
                </div>
              </draggable>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal
      name="create_task"
      :pivot-x="1"
      :pivot-y="0"
      transition="slide-fade"
      overlayTransition="wait"
      height="100%"
      width="85%"
      :adaptive="true"
    >
      <TaskForm :title="title"  @create="addTask"></TaskForm>
    </modal>
  </div>
</template>
<script>
import { db, modules, routes } from "@/utilities/database";
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import kanbanTask from "@/modules/project_management/components/task/KanbanTask";
import TaskForm from "./TaskForm";
import moment from "moment";
import {createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs, searchArray} from "@/utilities/helper";

export default {
  name: "KanbanBoard",
  components: {
    draggable,
    kanbanTask,
    TaskForm
  },


  async created() {
    db.get("modules/tasks", {
      params: {
        project_id: this.$router.currentRoute.params.id
      }
    })
      .then(res => {
        searchArray(res.data.columns, 'name', 'status', 'data').forEach(el=>{
          el["tasks"] = [];
          this.statuses.push(el)
        })

        res.data.data.forEach(rec => {
          let task={}
          task.options = createEntityFormObjectNoTabs(rec.options, "key");
          task.id=rec.id;
          if(task.options.status) {
          this.statuses.filter(status=>status.key==[task.options.status])[0].tasks.push(task)};
      })

      })
      .catch(err => {
        return Promise.resolve(null);
      });
    },
  mounted() {

  },
  data() {
    return {
      drag: false,
      title: "Create Task",
      statuses: [],
    };
  },
  methods: {
    changeStatus(event){
       let taskId=event.item.id;
       let parentkey=event.to.id
       let ind=event.newIndex
       let task=this.statuses.filter(status=>status.key==parentkey)[0].tasks[ind]
       task.options.status=parentkey;
       db.put(routes.tasks.update.replace(':id',taskId),{
            options: task.options,
            })
       this.drag = false
     },

    modalClose(event) {
      this.title = "Create Task";
    },
    addTask(task) {
      let kanbanUser = this.task.users.filter(
        user => user.id == task.asignee.id
      );
      if (kanbanUser.length > 0) {
        // kanbanUser[0]
      }
    },

    ...mapActions("tracker", ["track"]),

  },
  computed: {
    ...mapState("tracker", ["timeline"]),

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    sprintCompletePercentage() {
      let tasks = [];
      let completeTasksLength = 0;
      for (const status of this.project.statuses) {
        let statusTasks = this.tasksByStatus(status.id);
        tasks.push(...statusTasks);
        if (status.id == 4) {
          completeTasksLength = statusTasks.length;
        }
      }
      let totalTasks = tasks.length;
      return Math.floor((completeTasksLength / totalTasks) * 100);
    }
  }
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border-radius: 0.375rem;
}

.list-group {
  display: block;
}
</style>
