<template>
  <div class="row">
    <div class="col-12 p-0">
      <div class="form-group mt-3">
        <input
          v-model="value.name"
          type="text"
          name="name"
          class="form-control blog-title-input shadow-none border-0"
          :placeholder="translate('Enter a title here', 'page-builder')"
        >
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <text-editor-inline v-model="value.content" :bordered="false" :show-drag="false" />
      </div>
    </div>
  </div>
</template>

<script>
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin" // required because of builder.meta.entity above, remove me once all entities use the same field name
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
  name: 'PostBuilder',
  components: {
    TextEditorInline,
  },
  mixins: [BuilderMixin],
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style  lang="scss" scoped>
.blog-title-input {
	font-size: 24px;
	font-weight: bold;
}
</style>