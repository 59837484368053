<template>
	<div class="w-100 d-flex justify-content-center align-items-center">
		<span class="text-muted mt-5" style="font-size: 28px">{{ translate('Comming soon', 'settings') }}</span>
	</div>
</template>

<script>
export default {
name: "EmailAndNotificationsSettings"
}
</script>

<style scoped>

</style>