<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12" v-for="payment in payments">

                    <div class="card col-style" >
                        <div id="tab-1" class="d-flex justify-content-between p-2">
                            <span class="pay-tab">{{ translate('Payment type', 'invoices') }}</span>
                            <span class="pay-tab">{{ translate('Payment date', 'invoices') }}</span>
                            <span class="pay-tab">{{ translate('Amount', 'invoices') }}</span>
                            <span class="pay-tab">{{ translate('Registered by', 'invoices') }}</span>
                        </div>
                        <div class="d-flex justify-content-between p-2 m-2">
                            <span class="pay-tab">{{payment.type}}</span>
                            <span class="pay-tab">{{payment.date}}</span>
                            <span class="pay-tab">{{curency}} {{payment.amount.toFixed(2)}}</span>
                            <span style=" width: 25%;   padding-left: 1rem">
                              <img class="avatar rounded-circle p-1" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">{{ translate('System registration', 'invoices') }}
                            </span>
                        </div>
                    </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"Payments",
        props: {
            payments: {
                required: false
            },
            curency: {
                required: false
            }
        },
    }
</script>
<style scoped>
    #tab-1, #tab-2 {
        border-bottom: 1px solid black;
    }
    .pay-tab {
        width: 25%;
        padding: 1rem;

    }
    .col-style {
        background-color: white;
        margin-bottom: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.88);
        border-radius: 5px;
    }
</style>