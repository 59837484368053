<template>
  <tr>
    <td class="font-weight-bold">
      {{ item.sku }}
    </td>
    <td>
      <span class="font-weight-bold">{{ item.item_name }}</span>
      <div v-if="item.configurable_attributes_names">
        <small v-for="(attribute_value, attribute_name) in item.configurable_attributes_names" class="mr-2">
          <span class="font-weight-bold">{{ attribute_name }}:</span> <span class="text-nowrap">{{ attribute_value }}</span>
        </small>
      </div>
    </td>
    <td
      class="font-weight-bold"
      v-html="item.formatted_price_amounts.single_price_without_vat"
    ></td>
    <td class="font-weight-bold text-nowrap">
      {{ item.qty }}
    </td>
    <td class="font-weight-bold text-nowrap">
      {{ item.tax_percentage }}
    </td>
    <td class="font-weight-bold text-nowrap"
        v-html="item.formatted_price_amounts.subtotal_tax_amount"
    ></td>
    <!-- Discount disabled
    <td class="font-weight-bold text-nowrap" v-html="item.formatted_price_amounts.discount_amount">
    </td>
    -->
    <td class="font-weight-bold text-nowrap"
        v-html="item.formatted_price_amounts.subtotal"
    ></td>
  </tr>
</template>

<script>
export default {
  name: "ShowOrderItem",
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
}
</script>