<template>
    <div class="card card-progress draggable-item shadow-none" >
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="badge badge-pill " >{{value.options.priority}}</span>
                <!-- <a class="avatar avatar-sm rounded-circle m-0">
                    <img alt="Image placeholder" :src="user.avatar" height="35px" width="20px">
                </a> -->
            </div>
            <div class="d-flex flex-column">
                <router-link :to="`/entity/tasks/${value.id}`">
                <h6 class="system-text-primary">{{value.options.name}}</h6>
                </router-link>
                <span class="task-description" v-text="value.options.description"></span>
            </div>
            <!-- <div class="d-flex align-items-center mt-3">
                <span v-for="(label, labelIndex) in value.labels" :key="labelIndex" class="badge ml-1 badge-pill "
                      :style="{'background': label.color, 'color': 'white'}">
                    {{label.name}}
                </span>
            </div> -->
            <a class="system-text-primary d-flex justify-content-end mt-2" href="javascript:;" @click="track({type: 'task', data: {id: value.id, ...value.options}})">
                show more
            </a>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    import {mapState, mapActions} from 'vuex';

    export default {
        methods: {
            ...mapActions('tracker', [
                'track'
            ])
        },
        props: {
            value: {
                required: true
            },
           
        },
        computed: {
            ...mapState('tracker', [
                'timeline'
            ])
        }
    }
</script>

<style scoped>

</style>
