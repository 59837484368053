<template>
  <div class="container-fluid">
    <div v-if="componentData && componentData.content_html" class="app-page-preview" :emulator="deviceType">
      <render-html id="dom" :class="deviceType" :html="componentData.content_html" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EntityShowMixin from '../../mixins/EntityShowMixin'

export default {
  name: 'SectionPreview',
  mixins: [EntityShowMixin],
  props: {
    value: {
      required: false,
    },
  },
  computed: {
    ...mapGetters('layout', [
      'deviceType',
    ]),
  },
}
</script>