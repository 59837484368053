import settingMixin from "../craft/components/settingMixin"
import {isVoidValue} from "@/utilities/helper"
import {getCraftConfig} from "@/modules/pagebuilder/craft/utils/createNodeFromVNode"

export default {
  mixins: [settingMixin],
  methods: {
    settingExist(name) {
      return this.elementProps && this.elementProps.settings && this.elementProps.settings[name] !== undefined
    },
    getSetting(name, suffix = null, type = null) {

      let setting = this.settingExist(name)
        ? this.elementProps.settings[name]
        : ''

      // If suffix, replace it so only the value is returned, if value is available
      if (suffix) {
        // Percentage fix
        if (suffix == '%')
          suffix = /%/g

        if(setting === null) setting = '' // hot fix settings kept as null in the API
        setting = setting.replace(suffix, '')
      }

      if (type && type === 'background-image') {
        setting = setting.replace('url(', '').replace(')', '')
      }

      // get rid of null values that sometimes appear and brake selects
      if (type && type === 'multiselect' && Array.isArray(setting)) {
        setting = setting.filter(element => !isVoidValue(element)).map(element => {
          return element
        })
      }

      return setting
    },
    setSetting(name, value, suffix = null) {
      this.elementPropsSetter({
        settings: {
          ...this.elementProps.settings,
          [name]: suffix
            ? `${value}${suffix}`
            : value
        },
      })
    },

    normalizeComponentSettings() {
      const { defaultProps } = getCraftConfig(this.node.componentName, this.node.props, this.editor)

      /*
       * 1. Apply new default settings on existing components
       */
      this.elementProps.settings = { ...defaultProps.settings, ...this.elementProps.settings }

      /*
       * 2. Migrate existing settings to new format
       */

      if (['BlogElement', 'PortfolioElement'].includes(this.node.componentName)) {

        // columns
        if (typeof this.elementProps.settings.columns !== 'object') {
          const oldValue = this.elementProps.settings.columns

          if(oldValue) {
            this.elementProps.settings.columns = {
              desktop: oldValue,
              tablet_landscape: oldValue,
              tablet_portrait: oldValue,
              mobile: oldValue,
            }
          } else { // else default value
            this.elementProps.settings.columns = defaultProps.settings.columns
          }

        }

        // categories
        if (!Array.isArray(this.elementProps.settings.categories)) {
          const oldValue = this.elementProps.settings.categories

          if (oldValue) {
            this.elementProps.settings.categories = [oldValue]
          }
        }
      }
    },
  },
}