/**
 * Builder request service
 *
 * Using index/find functions, to call your data instead of using axios,
 * will fetch your data and return it reworked to object
 */

import { createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs } from "./helper"

export default {
  axiosInstance: null,

  get: async function(route){
    const { data } = await this.axiosInstance.get(route)

    const objectData = [...data.data].map(row => ({
      ...createEntityFormObjectNoTabs(row.options, 'key'),
      id: row.id,
    }))

    return {
      columns: data.columns, data: data.data,
      objectData,
    }
  },
  find: async function(module, id){
    const { data } = await this.axiosInstance.get(`modules/${module}/${id}`)

    const objectData = createEntityFormObjectNoTabs(data.data.options, 'key')
    objectData.id = data.data.id

    return {
      data: data.data, objectData,
    }
  },
}


export const routes = {
  website: {
    index: '/modules/cms-websites',
  },
  blocks: {
    index: '/modules/cms-blocks',
  },

  // website: {
  //     index: '/modules/websites',
  // }
}
