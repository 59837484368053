<template>
    <div>
        <page-title :page-title="`${$route.params.entityType}_settings`"></page-title>
        <component :is="`${$route.params.entityType}-settings`"></component>
    </div>
</template>

<script>
    // import CompaniesSettings from "./CompaniesSettings";
    // import ProjectsSettings from "./ProjectsSettings";

    export default {
        name: "Settings",
        // components: { CompaniesSettings, ProjectsSettings },
        created() {
            console.log(this.$route.params.entityType)
        }
    }
</script>

<style scoped>

</style>
