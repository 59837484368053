export default function (node) {
  const tabsId = 'tabs-' + node.uuid

  let styles = ''

  styles += `#${tabsId}.nav-tabs .nav-link {align-items: center}`
  if (node.props.settings['icon_position'] !== 'top') {
    styles += `#${tabsId}.nav-tabs .nav-link {display: flex}`
  }

  if (node.props.settings['title_font_size']) {
    styles += `#${tabsId}.nav-tabs .nav-link ${node.props.settings['title_font']} {font-size: ${node.props.settings['title_font_size']}}`
  }
  if (node.props.settings['icon_size']) {
    styles += `#${tabsId}.nav-tabs .nav-link i {font-size: ${node.props.settings['icon_size']}}`
  }
  // tab_background_color, tab_active_background_color
  if (node.props.settings['tab_background_color']) styles += `#${tabsId}.nav-tabs .nav-link {background: ${node.props.settings['tab_background_color']}}`
  if (node.props.settings['tab_active_background_color']) {
    styles += `#${tabsId}.nav-tabs .nav-link.active {background: ${node.props.settings['tab_active_background_color']}}`
  }

  // tab_font_color, tab_active_font_color
  if (node.props.settings['tab_font_color']) {
    styles += `#${tabsId}.nav-tabs .nav-link ${node.props.settings['title_font']} {color: ${node.props.settings['tab_font_color']}}`
  }
  if (node.props.settings['tab_active_font_color']) {
    styles += `#${tabsId}.nav-tabs .nav-link.active ${node.props.settings['title_font']} {color: ${node.props.settings['tab_active_font_color']}}`
  }

  // tabs_width
  if (node.props.settings['tabs_width'] && node.props.settings['orientation'] === 'horizontal') {
    let width = ''
    if (node.props.settings['tabs_width'] === 'small') {
      width = '15%'
    } else if (node.props.settings['tabs_width'] === 'medium') {
      width = '20%'
    } else if (node.props.settings['tabs_width'] === 'large') {
      width = '25%'
    }
    if (width) {
      styles += `#${tabsId}.nav-tabs .nav-item {width: ${width}}`
    }
  }

  // corners
  if (node.props.settings['corners']) {
    if (node.props.settings['orientation'] === "horizontal") {
      styles += `#${tabsId}.nav-tabs .nav-link {border-top-left-radius: ${node.props.settings['corners']}; border-top-right-radius: ${node.props.settings['corners']}}`
    } else {
      styles += `#${tabsId}.nav-tabs .nav-link {border-radius: ${node.props.settings['corners']}}`
    }
  }

  // space_between_tabs
  if (node.props.settings['space_between_tabs']) {
    if (node.props.settings['orientation'] === "horizontal") {
      styles += `#${tabsId}.nav-tabs .nav-item:not(:first-child) {margin-left: ${node.props.settings['space_between_tabs']}}`
      styles += `#${tabsId}.nav-tabs .nav-item:not(:last-child) {margin-right: ${node.props.settings['space_between_tabs']}}`
    } else {
      styles += `#${tabsId}.nav-tabs .nav-item:not(:first-child) {margin-top: ${node.props.settings['space_between_tabs']}}`
      styles += `#${tabsId}.nav-tabs .nav-item:not(:last-child) {margin-bottom: ${node.props.settings['space_between_tabs']}}`
    }
  }

  // tab_icon_color, tab_active_icon_color
  if (node.props.settings['tab_icon_color']) {
    styles += `#${tabsId}.nav-tabs .nav-link i {color: ${node.props.settings['tab_icon_color']}}`
  }
  if (node.props.settings['tab_active_icon_color']) {
    styles += `#${tabsId}.nav-tabs .nav-link.active i {color: ${node.props.settings['tab_active_icon_color']}}`
  }

  // content_background_color
  if (node.props.settings['content_background_color']) {
    styles += `#tab-content-${node.uuid} {background-color: ${node.props.settings['content_background_color']}}`
  }

  if (node.props.settings['content_font_color']) {
    styles += `#tab-content-${node.uuid} .tab-pane {color: ${node.props.settings['content_font_color']}}`
  }

  return styles
}