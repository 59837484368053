<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="pb-5"
        :src="logo"
      >
      <button
        class="btn btn-primary"
        @click="$fileManager.$emit('open', 'logo')"
      >
        {{ translate('Upload your logo', 'onboarding') }}
      </button>
      <button
        class="btn btn-primary mt-3"
        @click="nextStep"
      >
        {{ translate('Confirm using this logo', 'onboarding') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      logo: '',
    }
  },
  mounted() {
    this.$fileManager.$on('selected_logo', function (images) {
      this.logo = images[0]
      this.value.logo = images[0]
    }.bind(this))
  },
  methods: {
    nextStep() {
      this.$router.push(this.$router.currentRoute.meta.next)
    },
  },
}
</script>

<style scoped>
img {
    width: 250px;
}

.btn + .btn {
    margin: 0;
}
</style>
