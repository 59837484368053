<template>
  <div class="cms-vbg-container">
    <div class="cms-vbg-wrapper">
      <iframe
        allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameborder="0"
        allowfullscreen=""
        :src="generateEmbeddedSrc(getSetting('background_video_link'), embeddedSrcOptions)"
        :style="{ opacity: videoOpacity }"
      />
    </div>
    <div v-if="getSetting('background_video_overlay_color')" :style="overlayStyles" class="cms-abs-cover-overlay" />
  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import { generateEmbeddedSrc } from "@/utilities/helper"

export default {
  name: 'VideoBackground',
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  computed: {
    overlayStyles() {
      if (this.getSetting('background_video_overlay_color')) {
        return {
          backgroundColor: this.getSetting('background_video_overlay_color'),
          opacity: this.overlayOpacity,
        }
      }
      return null
    },

    videoOpacity() {
      return parseFloat(this.getSetting('background_video_opacity')) / 100
    },
    overlayOpacity() {
      return parseFloat(this.getSetting('background_video_overlay_opacity')) / 100
    },
    embeddedSrcOptions() {
      return {
        start_time: this.getSetting('background_video_start_time'),
        end_time: this.getSetting('background_video_end_time'),
        loop: this.getSetting('background_video_loop'),
        hide_controls: 1,
        autoplay: this.editor.enabled ? 0 : 1,
        mute: 1,
      }
    },
  },
  methods: {
    generateEmbeddedSrc,
  },
}
</script>