import Users from "@/modules/users_management/views/users/Users"
import UserForm from "@/modules/users_management/views/users/UserForm"

import Roles from "@/modules/users_management/views/roles/Roles"
import RolePermissions from "@/modules/users_management/views/roles/RolePermissions"
import RoleForm from "@/modules/users_management/views/roles/RoleForm"

export default [
  //Users routes
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: UserForm,
  },
  {
    path: '/users/:id',
    name: 'users.show',
    component: UserForm,
  },
  {
    path: '/roles',
    name: 'roles',
    component: Roles,
  },
  {
    path: '/roles/create',
    name: 'roles-create',
    component: RoleForm,
  },

  {
    path: '/roles/:id',
    name: 'roles-permission',
    component: RoleForm,
  },
]
