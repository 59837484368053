import store from './../../store/index'

export default function authenticateFromLocalToken(router){
  router.beforeEach(async function(to, from, next) {
    let isAuthenticated = store.getters['system/isAuthenticated']

    if(isAuthenticated){
      return next()
    }

    let storageAuthorizationToken = window.localStorage.getItem('authorizationToken')

    if(storageAuthorizationToken){
      await store.dispatch('system/authenticate', { token: storageAuthorizationToken })
      // If user is logged in from token then continue to application
      if(store.getters['system/isAuthenticated']){
        return next()
      }
    }

    return next()
  })

}
