<template>
  <transition name="slide-fade-tools" mode="in-out">
    <div id="row-tools-left" class="row-tools" :style="positionClassObject">
      <template v-if="showRowTools">
        <button
          id="row-tools_edit"
          class="btn icon icon-sm text-bold"
          @click.stop="toggleSettings"
        >{{ translate('Edit {component}', 'page-builder', { component: translate(editedElementName, 'page-builder') }) }}</button>
        <button
          v-if="canDuplicateSelectedElement"
          @click.stop="duplicateSelectedElement"
          id="row-tools_duplicate"
          class="btn icon rounded-circle icon-sm icon-shape"
        >
          <i class="icon-erp-duplicate"></i>
        </button>
        <template v-if="!['cms-templates'].includes(builder.meta.entity) && editedElement !== 'ColumnElement'">
          <button
            v-if="can('create', 'cms-template')"
            class="btn icon rounded-circle icon-sm icon-shape"
            @click.stop="toggleSettingsPopup('save')"
          >
            <i class="icon-erp-floppy-disk"></i>
          </button>
          <button
            v-if="can('access', 'cms-template')"
            class="btn icon rounded-circle icon-sm icon-shape"
            @click.stop="toggleSettingsPopup('import')"
          >
            <i class="icon-erp-books"></i>
          </button>
        </template>
        <button
          @click.stop="toggleSettingsPopup('design')"
          id="row-tools_settings"
          class="btn icon rounded-circle icon-sm icon-shape"
        >
          <i class="icon-erp-cog"></i>
        </button>

        <button
          @click.stop="toggleSettingsPopup('visibility')"
          id="row-tools_visibility"
          class="btn icon rounded-circle icon-sm icon-shape"
        >
          <i class="icon-erp-open-eye"></i>
        </button>

        <button
          v-if="canDeleteSelectedElement"
          @click.stop="deleteSelectedElement"
          id="row-tools_delete"
          class="btn icon rounded-circle icon-sm icon-shape"
        >
          <i class="icon-erp-trash"></i>
        </button>

        <element-settings
          v-if="this.editor.showSettingsPopup === 'general'"
          :node="this.editor.selectedNode"
          :element="this.editedElement"
          :element-name="editedElementName"
          @closeSettingsPopup="toggleSettingsPopup('general')"
        />

        <element-settings
          v-if="this.editor.showSettingsPopup === 'design'"
          :node="this.editor.selectedNode"
          :element="this.editedElement"
          :element-name="`${editedElementName} settings`"
          settings-component="DesignSettings"
          @closeSettingsPopup="toggleSettingsPopup('design')"
        />

        <element-settings
          v-if="this.editor.showSettingsPopup === 'save' && !['cms-templates'].includes(builder.meta.entity) && editedElement !== 'ColumnElement'"
          :node="this.editor.selectedNode"
          :element="this.editedElement"
          element-name="Save your template"
          settings-component="ExportTemplateSettings"
          @closeSettingsPopup="toggleSettingsPopup('save')"
        />

        <element-settings
          v-if="this.editor.showSettingsPopup === 'import' && !['cms-templates'].includes(builder.meta.entity) && editedElement !== 'ColumnElement'"
          :node="this.editor.selectedNode"
          :element="this.editedElement"
          element-name="Add item from library"
          settings-component="ImportTemplateSettings"
          @closeSettingsPopup="toggleSettingsPopup('import')"
        />

        <element-settings
          v-if="this.editor.showSettingsPopup === 'visibility'"
          :node="this.editor.selectedNode"
          :element="this.editedElement"
          element-name="Visibility settings"
          settings-component="VisibilitySettings"
          @closeSettingsPopup="toggleSettingsPopup('visibility')"
        />
      </template>
      <text-editor-actions
        v-else-if="editor.hasTexteditorActive"
        :editor="this.editor.selectedNode.textEditor"
        show-drag
        bordered="false"/>

    </div>
  </transition>
</template>

<script>
import busEvent from "@/utilities/eventBus";
import {can, humanReadable} from "@/utilities/helper"
import ElementSettings from "./element-settings/ElementSettings";
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import TextEditorActions from "@/modules/erp_entities/components/TextEditorActions.vue"

export default {
  name: 'RowTools',
  mixins: [BuilderMixin],
  inject: ['editor'],
  components:{
    ElementSettings, TextEditorActions,
  },
  data() {
    return {
      editedElement: '',
      toolsPosition: '',
    }
  },
  mounted() {
    busEvent.$on("rowToolsToggleSettings", () => {
      this.toggleSettings();
    });
  },
  methods: {
    can,
    humanReadable,
    getCoords(uuid) {
      const iframe = document.getElementById("pb-frame")
      const iframeRect = iframe.getBoundingClientRect()
      const iframeDoc = iframe.contentDocument

      const editedElem = iframeDoc.querySelector(`[data-uuid='${uuid}']`)
      const elemRect = editedElem.getBoundingClientRect()

      const headerHeight = document.getElementsByClassName('builder-header')[0].offsetHeight

      // Adjust for iframe position and scroll
      const top = elemRect.top + iframeRect.top + iframeDoc.documentElement.scrollTop - headerHeight
      const left = elemRect.left + iframeRect.left - 50

      // Check if the element is too close to the top
      const adjustedTop = top < 55 ? top + 55 : top

      return { top: Math.round(adjustedTop), left: Math.round(left) }
    },


    async deleteSelectedElement() {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.editor.removeNode(this.editor.selectedNode)
      this.$toast.requestSuccess('delete')
    },
    async duplicateSelectedElement() {
      const duplicatedNode = await this.editor.selectedNode.duplicate()
      duplicatedNode.insertAfter(this.editor.selectedNode)

      // does not work without timeout
      setTimeout(() => {
        this.editor.selectNode(duplicatedNode)
      }, 10)
    },
    toggleSettings() {
      if (this.editedElement === 'TextElement') {
        this.editor.hasTexteditorActive = true
        this.editor.selectedNode.props.showActions = true
      } else {
        this.toggleSettingsPopup('general')
      }
    },
    toggleSettingsPopup(settingName) {
      this.editor.showSettingsPopup = this.editor.showSettingsPopup === settingName ? null : settingName

      if (this.editor.showSettingsPopup) {
        this.hideAddButtons('add_new_row')
        this.hideAddButtons('add_new_section')
      } else {
        this.showAddButtons('add_new_row')
        this.showAddButtons('add_new_section')
      }
    },
    hideAddButtons(className) {
      let buttons = document.getElementsByClassName(className)
      buttons.forEach(element => {
        element.style.opacity = "0"
      })
    },
    showAddButtons(className) {
      let buttons = document.getElementsByClassName(className)
      buttons.forEach(element => {
        element.style.opacity = "1"
      })
    },
  },
  computed: {
    showRowTools(){
      if(this.editedElement && !['MainContainerElement'].includes(this.editedElement)){
        // on TextElement check if actions are showed
        if(this.editor.selectedNode.componentName === 'TextElement'){
          return !this.editor.selectedNode.props.showActions
        }
        return true
      }
      return false
    },
    canDuplicateSelectedElement(){
      return !['ColumnElement'].includes(this.editedElement)
    },
    canDeleteSelectedElement(){
      return this.editedElement !== 'ColumnElement'
    },
    positionClassObject() {
      if (this.toolsPosition) {
        return {
          left: `${this.toolsPosition.left}px`,
          top: `${this.toolsPosition.top}px`,
        }
      }
      return null
    },
    editedElementName() {
      return humanReadable(this.editedElement.replace('Element', ''))
    },
  },
  watch: {
    'editor.selectedNode': function() {
      // Change edited element
      if(!this.editor.selectedNode){
        this.editedElement = ''
      } else if(this.editor.selectedNode.isCanvas()){
        this.editedElement = this.editor.selectedNode.props.component;
      } else {
        this.editedElement = this.editor.selectedNode.componentName;
      }

      // Change row tools position
      if(this.editor.selectedNode){
        this.toolsPosition = this.getCoords(this.editor.selectedNode.uuid)
      } else {
        this.toolsPosition = ''
      }

      // hide settings windows
      this.toggleSettingsPopup(null)
    },
  },
}
</script>

<style>

.slide-fade-tools-enter-active {
  transition: all .3s ease;
}

.slide-fade-tools-leave-active {
  transition: all 0s ease;
}

.slide-fade-tools-enter {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fade-tools-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>


