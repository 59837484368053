<template>
    <div class="d-flex justify-content-center m-5">
        <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
    </div>
</template>

<script>
export default {
    name: "Loader"
}
</script>

<style scoped>

</style>
