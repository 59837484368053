<template>
  <!-- classes like col-md-6 are automatically passed to this div -->
  <div v-show="typeof fieldProps.v_hide === undefined || !fieldProps.v_hide">

    <div :class="fieldClasses" class="form-group">
      <!-- LABEL -->
      <label class="form-control-label" v-if="showLabel && !['datatable', 'datatable_editable', 'week_times'].includes(this.fieldProps.type)">
        {{ translate(entityLabel(fieldProps.label, fieldProps.system_attribute == 1), 'entities') }}
        <span v-if="fieldProps.label && fieldProps.label_tooltip"
          v-tooltip="labelTooltip"
              class="m-3 cursor-pointer align-middle">
          <i class="fa-solid fa-circle-question" />
        </span>
        <template v-if="fieldProps.description">
          <br>
          <small class="text-muted">{{ fieldProps.description }}</small>
          <span v-if="fieldProps.description_tooltip"
                v-tooltip="descriptionTooltip"
                class="m-3 cursor-pointer align-middle">
            <i class="fa-solid fa-circle-question" />
          </span>
        </template>
      </label>

      <component
        v-if="componentName"
        :is="componentName"
        v-model="value"
        :field-props="fieldProps"
        :name="fieldProps.name"
        :placeholder="placeholder"
        :entity="entity"
        :data-filters="dataFilters"
        :apply-default-val="applyDefaultVal"
        @createOptions="v => $emit('createOptions', v)"
      />

      <small class="help text-danger" v-if="errorsFormatted" v-text="errorsFormatted"></small>

    </div>
  </div>
</template>
<script>
import { inputTypes } from '../../../utilities/helper'
import { validate } from '../../../utilities/validation/helper'
import bus from './../../../utilities/eventBus'

import * as Fields from '@/modules/erp_entities/components/page/form/fields'

export default {
  name: "InputEntityProps",
  components: {
    ...Fields,
    // if needed add webpackPrefetch like this: DataTableField: () => import(/* webpackPrefetch: true */ '@/modules/erp_entities/components/page/form/fields/DataTableField.vue'),
    DataTableField: () => import('@/modules/erp_entities/components/page/form/fields/DataTableField.vue'), // datatable must be lazy loaded because the filters use current file
    DataTableEditableField: () => import('@/modules/erp_entities/components/page/form/fields/DataTableEditableField.vue'), // datatable editable must be lazy loaded because the table use current file to show inputs
  },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    applyDefaultVal: {
      required: false,
      default: true,
      type: Boolean,
    },
    showLabel: {
      required: false,
      default: true,
      type: Boolean,
    },
    siblings: {
      required: false,
    },
    entity: { // required for fields with shortcodes
      required: false,
    },
    dataFilters: {
      type: Object,
      required: false,
    },
  },
  methods: {
    validate(){
      this.$set(this.fieldProps, 'errors', validate(this.value, this.fieldProps.rules))
    },
  },
  // TODO: move each input to it's own component so we dont mix functionalities
  async created(){
    try {

      // Remove stuck data in database and prevent wrong behaviour
      this.$set(this.fieldProps, 'errors', null)

    } catch(e){

    }
    // Listen for validation call
    bus.$on('validate', this.validate)
  },
  computed: {
    // #01247177 same as errorsFormatted in FormFieldMixing
    errorsFormatted() {
      return this.fieldProps.errors ? Object.values(this.fieldProps.errors).join(', ') : false
    },
    pickerTypes: () => Object.keys(inputTypes.pickers.subInputTypes),
    labelTooltip(){
      return this.translate(this.fieldProps.label_tooltip, 'entities')
    },
    descriptionTooltip(){
      return this.translate(this.fieldProps.description_tooltip, 'entities')
    },
    fieldClasses() {
      const classes = []

      if (this.fieldProps.type === 'checkbox' || this.fieldProps.type === 'group_checkbox') {
        classes.push(this.fieldProps.name)
      }

      if (this.fieldProps.rules && this.fieldProps.rules.some(rule => rule.rule === 'required')) {
        classes.push('required')
      }

      return classes.join(' ')
    },
    componentName() {
      if (this.pickerTypes.includes(this.fieldProps.type)) {
        return inputTypes.pickers.component
      }

      return inputTypes[this.fieldProps.type].component
    },
  },
  watch: {
    'value': function(new_value, old_value){
      this.validate()
      this.$emit('input', this.value)
    },
  },
}
</script>
