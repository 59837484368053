<template>
  <div id="intro-layout" class="bg-white container-application">
    <!-- Sidenav -->
    <!-- Content -->
    <div class="main-content container-fluid w-100">
      <!-- Main nav -->
      <!-- Page content -->
      <div class="row justify-content-center overflow-hidden h-100">
        <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-4 d-flex flex-column justify-content-between">
          <v-infinite-progressbar v-if="authLoading"></v-infinite-progressbar>

          <div>
            <template v-if="$store.state.system.theme !== 'rock'">
              <img class="mb-5" height="38" :src="`/img/themes/${$store.state.system.theme}/logo.png`" alt="Logo">
            </template>
          </div>

          <slot/>

          <div>
            <template v-if="$store.state.system.theme !== 'rock'">
              <div class="mt-4 mb-4">
                <template v-if="$store.state.system.theme === 'blockzapp'">
                  <img class="mr-2" width="103" height="29" src="/img/SitecartLogo.png" alt="Blockzapps">
                  <img width="109" height="29" src="/img/Skitle-Logo.png" alt="Blockzapps">
                </template>
                <template v-else>
                  <img :alt="translate('Logo', 'global')" :src="`/img/themes/${$store.state.system.theme}/logo-footer.png`">
                </template>
              </div>

              <div class="pl-0">
                &copy; {{ translate('Copyright', 'global') }}
                {{ new Date().getFullYear() }} - {{ translate('All rights reserved', 'global') }}
                -
                <template v-if="$store.state.system.theme === 'rock'">
                  Rock 2.0 Client Volg Systeem
                </template>
                <template v-if="$store.state.system.theme === 'trucks'">
                  Trucks.nl B.V.
                </template>
                <template v-else>
                  BlockzApplications B.V.
                </template>

              </div>
            </template>
          </div>

        </div>

        <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <!-- Image -->
          <div class="bg-cover vh-100 mt-n1 mr-n3" :style="{backgroundImage: `url(/img/themes/${$store.state.system.theme}/bg-intro.jpg)`}"></div>
        </div>
      </div>
      <!-- Footer -->
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroLayout",
  props: {
    authLoading: {
      required: true
    }
  }
}
</script>