<template>
    <div>
        <div class="heading-spint-board">
            <h1 class="system-text-primary">{{ translate('Sprint Planning Board', 'project') }}</h1>
            <a class="board-options">
                <i class="fas fa-cogs"></i>
            </a>
            <a class="board-options">
                <i class="fas fa-users"></i>
            </a>
        </div>
         <transition-expand :group="true">
        <div @click="status.fixed = !status.fixed"
              v-for="(status, statusIndex) in statuses"
              :key="statusIndex">
            <div class="table-responsive">
                <table class="sprint-table table align-items-center system-text-primary text-center">
                      
                    <thead>
                    <tr>
                        <th class="sprint-done text-left">
                            <span class="table-done"  @click="status.show = !status.show"><i
                                    class="fas fa-chevron-down"></i></span>
                            {{ status.value}}</th>
                        <th>{{ translate('Assignee', 'project')}}</th>
                        <th>{{ translate('Client', 'project')}}</th>
                        <th>{{ translate('Approved', 'project')}}</th>
                        <th>{{ translate('Timeline', 'project')}}</th>
                        <th>{{ translate('Progress', 'project')}}</th>
                        <th>{{ translate('Estimated', 'project')}}</th>
                        <th>{{ translate('Tijdegistratie', 'project')}}</th>
                    </tr>
                    </thead>
                                         
                            <draggable  v-show="status.show" :id="status.key" group="tasks" v-model="status.tasks" :key="statusIndex"
                                        tag="tbody" v-bind="dragOptions" @start="drag = true" @end="changeStatus">
                                 <tr :id="task.id" :class="task.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
                                       @click="task.fixed = !task.fixed" v-for="(task, taskIndex) in status.tasks" :key="taskIndex"
                                 >
                                     
                                        <td class="sprint-name text-left">{{task.options.name}}
                                          <span><i class="far fa-comment"></i></span>
                                        </td>
                                        <td>user-avatar-rounded</td>
                                        <td>client-avatar-rounded</td>
                                        <td class="approved">Done</td>
                                        <td class="td-date"><span>jan. 29-feb. 1</span></td>
                                        <td class="td-progress-bar">
                                            <div class="progress-tracker">
                                               <div class="progress">
                                                    <div class="progress-bar w-75"></div>
                                              </div>
                                                 <span class="progress-value">75%</span>
                                            </div>
                                        </td>
                                         <td>{{task.options.estimation}}</td>
                                         <td>worked on</td>
                                 </tr>
                     
                                <tr class="add-sprint-row" >
                                    <td class="add-sprint text-left">{{ translate('Add sprint', 'project')}}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="td-date"></td>
                                     <td class="td-progress-bar"></td>
                                    <td></td>
                                     <td></td>
                                </tr>  
                                <tr  >
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td >    
                                        <div class="approved-all">
                                            <div class="progress"> 
                                                 <div class="approved" :style="styleProgress"> </div>                                                
                                            </div>
                                        </div>
                                    </td>
                                    <td class="td-date"></td>
                                    <td class="td-progress-bar">
                                        <div class="progress-tracker">
                                               <div class="progress">
                                                    <div class="progress-bar" :style="styleProgress"></div>
                                              </div>
                                                 <span class="progress-value">{{progress}}%</span>
                                            </div>
                                    </td>
                                    <td></td>
                                     <td></td>
                                </tr>      
                            </draggable>                        
                    
                </table>
            </div>
        </div>
        </transition-expand>
        <div>
            <div class="table-responsive">
                <table class="sprint-table-qa table align-items-center system-text-primary text-center">
                    <thead>
                    <tr>
                        <th class="sprint-qa text-left">
                            <span class="table-qa" @click="show.sprintTableQA = !show.sprintTableQA"><i
                                    class="fas fa-chevron-down"></i></span>
                            {{ translate('Ready for testing qa', 'project')}}</th>
                        <th>{{ translate('Assignee', 'project')}}</th>
                        <th>{{ translate('Client', 'project')}}</th>
                        <th>{{ translate('Approved', 'project')}}</th>
                        <th>{{ translate('Timeline', 'project')}}</th>
                        <th>{{ translate('Progress', 'project')}}</th>
                        <th>{{ translate('Estimated', 'project')}}</th>
                        <th>{{ translate('Tijdegistratie', 'project')}}</th>
                    </tr>
                    </thead>
                    <transition-expand>
                        <tbody >
                        <tr>
                            <td class="sprint-name text-left">Print Flyers
                                <span><i class="far fa-comment"></i></span>
                            </td>
                            <td>user-avatar-rounded</td>
                            <td>client-avatar-rounded</td>
                            <td class="approved">Done</td>
                            <td class="td-date"><span>jan. 29-feb. 1</span></td>
                            <td class="td-progress-bar">
                                <div class="progress-tracker">
                                    <div class="progress">
                                        <div class="progress-bar w-0"></div>
                                    </div>
                                    <span class="progress-value">0%</span>
                                </div>
                            </td>
                            <td>Estimated time</td>
                            <td>worked on</td>
                        </tr>
                        <tr>
                            <td class="sprint-name text-left">Weekly team meeting</td>
                            <td>user-avatar-rounded</td>
                            <td>client-avatar-rounded</td>
                            <td class="stuck">Stuck</td>
                            <td class="td-date"><span>aug. 14-21</span></td>
                            <td class="td-progress-bar">
                                <div class="progress-tracker">
                                    <div class="progress">
                                        <div class="progress-bar w-50"></div>
                                    </div>
                                    <span class="progress-value">50%</span>
                                </div>
                            </td>
                            <td>Estimated time</td>
                            <td>worked on</td>
                        </tr>
                        <tr class="add-sprint-row">
                            <td class="add-sprint text-left">{{ translate('Add sprint', 'project')}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="td-date"></td>
                            <td class="td-progress-bar"></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </transition-expand>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import { db, modules, routes } from "@/utilities/database";
    export default {
    name: "Sprint",
    components: {
        draggable,
    },
    props: {
            statuses: {
                required: true
            },
        },
    data() {
          return{
             progress : 0, 
             styleProgress :{
                 width: 0
             }
          }
    },
    created(){
          this.progress=70;
         this.styleProgress.width=`${this.progress}%`;
         console.log(this.styleProgress); 
    },
    methods: {
     changeStatus(event){
      console.log(event);
       let taskId=event.item.id;
       let parentkey=event.to.id
       let ind=event.newIndex
       let task=this.statuses.filter(status=>status.key==parentkey)[0].tasks[ind]
       task.options.status=parentkey;
       db.put(routes.tasks.update.replace(':id',taskId),{
            options: task.options,
            })
       this.drag = false
     }  
    },
    computed: {
     
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
    }  
}
</script>

<style scoped>

</style>
