<template>

  <div v-show="typeof fieldProps.v_hide === undefined || !fieldProps.v_hide">

    <label class="form-control-label" :title="fieldProps.name">
      {{ entityLabel(fieldProps.label, fieldProps.system_attribute == 1) }}
      <template v-if="fieldProps.description">
        <br>
        <small class="text-muted">{{ fieldProps.description }}</small>
      </template>
    </label>

    <div class="form-group">
      <span class="form-control text-limit">
        {{ inputTypeLabel(fieldProps.type) }}
      </span>
    </div>

    <!-- remove silent save and think of better way -->
    <!-- TODO

    <component v-else-if="(cmsTypes.includes(fieldProps.type) || erpTypes.includes(fieldProps.type))"
      :is="fieldProps.type" v-model="value" :data="fieldProps.data"></component>

    -->


  </div>
</template>

<script>
import { inputTypes } from "@/modules/erp_entities/utilities/helper";

export default {
  name: "EditableInputFields",
  props: {
    value: {
      required: true
    },
    fieldProps: {
      required: true
    },
  },
  methods: {
    inputTypeLabel(type) {
      let label = ''
      if(this.pickerTypes.includes(type)){
        label += 'Picker ';
      }
      if (this.inputTypes[type] && this.inputTypes[type].label) {
        label += this.inputTypes[type].label;
      } else {
        label += type
      }
      return label
    }
  },
  computed: {
    pickerTypes: () => Object.keys(inputTypes.pickers.subInputTypes),
    cmsTypes: () => Object.keys(inputTypes.cms),
    erpTypes: () => Object.keys(inputTypes.erp),
    inputTypes: () => inputTypes,
  }
}
</script>

<style scoped>

</style>