<template>
    <div class="upload-img-wrapper" :class="{'no-value-border': !value}">
        <div v-if="!value" @click="$fileManager.$emit('open', `featured_image`)" class="w-100 h-100 text-center">
            <p class="mb-3">{{ placeholder }}</p>
            <div class="icon-round-wrapper">
                <div>
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </div>
        <div class="upload-img-container" v-else>
            <img class="featured-img" :src="image" alt="featured-image" />
            <span class="clear-input-img" @click="$emit('input', '')"><i class="fas fa-trash-alt"></i></span>
        </div>
    </div>
</template>

<script>
import { ErpModel } from '@/modules/erp_framework'

export default {
    name: 'UploadImage',
    created(){
        this.$fileManager.$on(`selected_featured_image`, imgs => this.$emit('input', `${imgs[0]}`))
    },
    computed:{
        image(){
            if(!this.value || this.value === null || !this.value)
                return '/uploads/defaults/image-placeholder.jpg'
            return `http://${this.$store.state.builder.website.data.url}/${this.value}`
        }
    },
    props: {
        placeholder: {
            type: String,
            default: 'Add image',
            required: false
        },
        value: {
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.upload-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 32rem;
    min-height: 16rem;
    margin: 0 auto;
    border-radius: 16px;
    cursor: pointer;

    &.no-value-border {
        border: 2px dashed gray;
    }
}

.icon-round-wrapper {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff004f;
    border-radius: 50%;
    margin: auto;

    i {
        color: white;
    }
}

.featured-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 16px;
    object-fit: contain;
    width: 100%;

    &:hover + .delete-img {
        opacity: 1;
    }
}

.upload-img-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    .clear-input-img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        background-color: #ffffff85;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
        border-radius: 16px;
    }

    &:hover {
        .clear-input-img {
            opacity: 1;
            visibility: visible;
        }
    }
}
</style>
