<template>
  <input
    type="number"
    class="form-control w-50"
    v-model="displayedValue"
    :min="1"
    @input="changeQuantity($event.target.value)"
  />
</template>

<script>
import debounce from "debounce"
import axios from "axios"

let abortController = null

export default {
  name: "OrderItemQtyInput",
  props: {
    value: {
      required: true,
    },
    itemId: {
      required: true,
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  computed: {
    displayedValue: {
      get() {
        // Return the input value if it's not empty, otherwise use the initial value from the prop
        return this.inputValue !== '' ? this.inputValue : this.value
      },
      set(value) {
        this.inputValue = value
      },
    },
  },
  methods: {
    changeQuantity: debounce(function (qty) {
      if (Number(qty) < 1) {
        this.displayedValue = this.value
        return
      }

      this.$store.state.system.isLoading = true

      // Cancel the previous request, if it exists
      if (abortController) {
        abortController.abort()
      }

      // Create a new AbortController instance
      abortController = new AbortController()

      console.log('itemId')
      console.log(this.itemId)

      this.erp.ext.request.axiosInstance.post(`/modules/sales/cart/item/change-qty/${this.itemId}`, { qty }, {
        signal: abortController.signal,
      })
        .then(res => this.$emit('reloadCart'))
        .catch(error => {
          if (axios.isCancel(error)) {
            // Request was canceled
            console.log('Request canceled:', error.message)
          } else {
            this.displayedValue = this.value
            this.$alert.formattedError(error)
          }
        }).finally(() => this.$store.state.system.isLoading = false)

    }, 500),
  },
  created() {
    this.displayedValue = this.value
  },
  watch: {
    value: function(value) {
      this.displayedValue = value
    },
  },
}
</script>