<template>
  <entity-form :entity-route="$route.params.entityType" />
</template>

<script>
import EntityForm from "@/modules/erp_entities/components/page/form/EntityForm.vue"

// Single entry point for all entities
// todo load pagebuilder entities based on the entity type

export default {
  name: "EntityComposer",
  components: {
    EntityForm,
  },
}
</script>