<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Title', 'page-builder') }}</label>
          <input-field
            v-model.trim="data.name"
            :field-props="{ type: 'text' }"
          />
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Description', 'page-builder') }}</label>
          <textarea-simple-field
            v-model.trim="data.description"
            :field-props="{}"
          />
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label class="label">{{ translate('Choose category', 'page-builder') }}</label>
          <treeselect-field
            v-model="data.categories"
            :field-props="{ data_type: 'treeselectCategory', data_module: 'cms-templates' }"
            :key="renderKey"
          />
          <a href="javascript:;" class="d-flex justify-content-end btn-create-new"
             @click="$modal.show('create_category')">
            {{ translate('Create new', 'entities') }}
          </a>
        </div>
      </div>

<!--      <div class="col-12">-->
<!--        <craft-toggle-->
<!--          label="Save global"-->
<!--          :id="'save-global'"-->
<!--          :value-on="1"-->
<!--          :value-off="0"-->
<!--          value=""-->
<!--          @input="$emit('input', $event)"-->
<!--        />-->
<!--      </div>-->

      <div class="col-12">
        <div class="text-right my-4">
          <button
            @click="save"
            class="btn btn-primary rounded-pill px-3"
          >
            {{ translate('Save item', 'page-builder') }}
          </button>
        </div>
      </div>
    </div>
    <modal name="create_category"
           :pivot-x="0.5"
           :pivot-y="0"
           overlayTransition="wait"
           height="400px"
           width="500px"
           transition="slide-top"
           classes="mt-5 remove-modal-height"
    >
      <create-category-modal
        :entity-name="'cms-templates'"
        :entity-type="'categories'"
        @refreshCategories="renderKey++"
      />
    </modal>
  </div>
</template>

<script>
import { InputField, TextareaSimpleField, TreeselectField } from "@/modules/erp_entities/components/page/form/fields"
import {CraftToggle} from "@/modules/pagebuilder/components/craft-inputs"
import CreateCategoryModal from "@/modules/pagebuilder/components/CreateCategoryModal.vue"
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import { mapActions } from "vuex"
import html2canvas from "html2canvas"
import uniqid from "uniqid"

export default {
  name: "ExportTemplateSettings",
  mixins: [BuilderMixin],
  components: { InputField, TextareaSimpleField, TreeselectField, CreateCategoryModal, CraftToggle },
  inject: ['editor'],
  data() {
    return {
      data: {
        name: '',
        description: '',
        categories: null,
      },
      html2canvasOptions: {
        logging: false,
      },
      renderKey: 0, // used to update template categories after create new one from modal
    }
  },
  computed: {
    selectedComponentName() {
      return this.editor.selectedNode && this.editor.selectedNode.componentName === 'Canvas'
        ? this.editor.selectedNode.props.component
        : this.editor.selectedNode.componentName
    },
  },
  methods: {
    ...mapActions('builder', [
      'showGlobalLoader',
      'hideGlobalLoader',
    ]),
    async save() {
      if (!this.data.name) {
        return this.$toast.error('Title is required!')
      }

      const duplicatedNode = this.editor.selectedNode.duplicate(true)

      const slug = uniqid()
      const cms_block_id = this.$store.state.builder.blocks.objectData.find(item => item.component === this.selectedComponentName).id
      const preview_image = await this.getPreviewImage(slug)

      const data = {
        options: {
          name: this.data.name,
          slug: slug,
          description: this.data.description,
          content_json: JSON.stringify([duplicatedNode.serialize()]),
          categories: this.data.categories,
          cms_block_id: cms_block_id,
          user_id: this.$store.state.system.authData.user_id,
          preview_image: preview_image,
        },
      }

      try {
        await this.erp.ext.request.axiosInstance.post(`/modules/cms-templates`, data)
        this.$toast.success('Template saved')
        this.editor.selectNode(null)
      } catch (error) {
        this.$toast.error(error)
      }
    },

    // todo refactor #982374982364
    async getPreviewImage(slug) {
      const uuid = this.editor.selectedNode.uuid
      this.editor.disable(true)
      await new Promise(resolve => setTimeout(resolve, 10)) // hot fix, wait for editor to be disabled

      return new Promise(async (resolve, reject) => {
        try {
          (await html2canvas(document.querySelector(`[data-uuid='${uuid}']`), this.html2canvasOptions)).toBlob(async blob => {
            if (blob) {
              const snapshotAsFile = new File([blob], `${slug}.png`, { type: 'image/png' })
              const formData = new FormData()
              formData.append('file', snapshotAsFile)

              const res = await this.erp.ext.request.axiosInstance.post(`/modules/documents`, formData)
              resolve(res.data.data.id)
            }
          })
        } catch (error) {
          reject(error)
        } finally {
          this.editor.enable()
        }
      })
    },
  },
}
</script>
