<template>
    <div>
        <div>
            <h5 @click="show.image = !show.image"
                class="node-styling--title">{{ translate('Image', 'page-builder') }}
                <i class="fa ml-auto fa-chevron-down chevron ml-2" :class="{'chevron-rotate': show.image === true}"></i>
            </h5>
        </div>
        <transition-expand>
            <div v-show="show.image">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group add-image">
                            <small>{{ translate('Enter external url', 'page-builder') }}:</small>
                            <input onClick="this.setSelectionRange(0, this.value.length)"
                                   @input="ev => settings.image = ev.target.value"
                                   :value="settings.image"
                                   class="form-control form-control-sm" type="text">
                            <div v-if="settings.image === '/uploads/defaults/image-placeholder.jpg'">
                                <small>{{ translate('Upload image', 'page-builder') }}:</small>
                                <span class="open-file_manager"
                                      @click="() => selectImage('image')">
                                    <i class="fas fa-plus"></i>
                                </span>
                            </div>
                            <div>
                                <small>{{ translate('Select image', 'page-builder') }}</small>
                                <img style="object-fit: contain"
                                     width="100%"
                                     height="300px"
                                     :src="settings.image">
                                <span class="remove-image" v-if="settings.image !== '/uploads/defaults/image-placeholder.jpg'"
                                      @click="settings.image = '/uploads/defaults/image-placeholder.jpg'">
                                    <i class="fas fa-times"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Width', 'page-builder') }} (%)</label>
                            <input
                                type="number"
                                class="form-control"
                                @input="ev => settings.image_width = ev.target.value"
                                :value="settings.image_width"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">{{ translate('Alignment', 'page-builder') }}:</label>
                            <v-select :options="['left', 'center', 'right']"
                                      style="min-width: 120px;" :clearable="false"
                                      :value="elementsAlignValue('image_align')"
                                      @input="ev => computedAlignProp(ev, 'image_align')">
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
        <div>
            <h5 @click="show.title = !show.title"
                class="node-styling--title">{{ translate('Title', 'page-builder') }}
                <i class="fa ml-auto fa-chevron-down chevron ml-2" :class="{'chevron-rotate': show.title === true}"></i>
            </h5>
        </div>
        <transition-expand>
            <div v-show="show.title">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="titleInput">{{ translate('Title', 'page-builder') }}</label>
                            <input
                                type="text"
                                id="titleInput"
                                class="form-control"
                                v-model="settings.title"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Title color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.title = !show.color.title"
                            >
                                <span
                                    :style="{'background-color': settings.title_color}"
                                ></span>
                                <input class="mx-2" :value="settings.title_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.title"
                                v-if="show.color.title"
                                :value="settings.title_color"
                                @input="style => settings.title_color = style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="fontSizeInput">{{ translate('Font size', 'page-builder') }}(px)</label>
                            <input
                                id="fontSizeInput"
                                type="number"
                                class="form-control"
                                @input="ev => settings.title_font_size = ev.target.value"
                                :value="settings.title_font_size"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Title alignment', 'page-builder') }}</label>
                            <v-select :options="textAlignOptions" label="key"
                                      @input="value => settings.title_alignment = value.value">
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
        <div>
            <h5 @click="show.description = !show.description"
                class="node-styling--title">{{ translate('Description', 'page-builder') }}
                <i class="fa ml-auto fa-chevron-down chevron ml-2" :class="{'chevron-rotate': show.description === true}"></i>
            </h5>
        </div>
        <transition-expand>
            <div v-show="show.description">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Description', 'page-builder') }}</label>
                            <div class="sidebar-text-editor">
                              <craft-text-editor
                                  :label="'Content'"
                                  :value="settings.description"
                                  @input="e => {settings.description = e}"
                              />
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Description color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.description = !show.color.description"
                            >
                                <span
                                    :style="{'background-color': settings.description_color}"
                                ></span>
                                <input class="mx-2" :value="settings.description_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.description"
                                v-if="show.color.description"
                                :value="settings.description_color"
                                @input="style => settings.description_color = style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="fontSizeInput">{{ translate('Font size', 'page-builder') }}(px)</label>
                            <input
                                id="descFontSizeInput"
                                type="number"
                                class="form-control"
                                @input="ev => settings.description_font_size = ev.target.value"
                                :value="settings.description_font_size"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ translate('Description alignment', 'page-builder') }}</label>
                            <v-select :options="textAlignOptions" label="key"
                                      @input="value => settings.description_alignment = value.value">
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
        <div>
            <h5 @click="show.button = !show.button"
                class="node-styling--title">{{ translate('Button', 'page-builder') }}
                <i class="fa ml-auto fa-chevron-down chevron ml-2" :class="{'chevron-rotate': show.button === true}"></i>
            </h5>
        </div>
        <transition-expand>
            <div v-show="show.button">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="titleInput">{{ translate('Title', 'page-builder') }}</label>
                            <input
                                type="text"
                                id="buttonTitleInput"
                                class="form-control"
                                v-model="settings.button_text"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Button text', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_color = !show.color.button_color"
                            >
                                <span
                                    :style="{'background-color': settings.button_color}"
                                ></span>
                                <input class="mx-2" :value="settings.button_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_color"
                                v-if="show.color.button_color"
                                :value="settings.button_color"
                                @input="style => settings.button_color = style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Background color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_background = !show.color.button_background"
                            >
                                <span
                                    :style="{'background-color': settings.button_background_color}"
                                ></span>
                                <input class="mx-2" :value="settings.button_background_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_background"
                                v-if="show.color.button_background"
                                :value="settings.button_background_color"
                                @input="style => settings.button_background_color = style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Border color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_border = !show.color.button_border"
                            >
                                <span
                                    :style="{'background-color': settings.button_border_color}"
                                ></span>
                                <input class="mx-2" :value="settings.button_border_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_border"
                                v-if="show.color.button_border"
                                :value="settings.button_border_color"
                                @input="style => settings.button_border_color = style.hex"
                            ></color>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Border width', 'page-builder') }}(px)</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="settings.button_border_width"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Border radius', 'page-builder') }}(px)</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="settings.button_border_radius"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Width', 'page-builder') }}(%)</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="settings.button_width"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-control-label">{{ translate('Alignment', 'page-builder') }}:</label>
                            <v-select :options="['left', 'center', 'right']"
                                      style="min-width: 120px;" :clearable="false"
                                      :value="elementsAlignValue('button_align')"
                                      @input="ev => computedAlignProp(ev, 'button_align')">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Hover color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_hover_color = !show.color.button_hover_color"
                            >
                                <span
                                    :style="{'background-color': settings.button_hover_color}"
                                ></span>
                                <input class="mx-2" :value="settings.button_hover_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_hover_color"
                                v-if="show.color.button_hover_color"
                                :value="settings.button_hover_color"
                                @input="style => settings.button_hover_color = style.hex"
                            ></color>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Hover background color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_hover_background = !show.color.button_hover_background"
                            >
                                <span
                                    :style="{'background-color': settings.button_hover_background}"
                                ></span>
                                <input class="mx-2" :value="settings.button_hover_background"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_hover_background"
                                v-if="show.color.button_hover_background"
                                :value="settings.button_hover_background"
                                @input="style => settings.button_hover_background = style.hex"
                            ></color>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label>{{ translate('Hover border color', 'page-builder') }}:</label>
                            <span
                                class="color-picker"
                                @click.stop="show.color.button_hover_border = !show.color.button_hover_border"
                            >
                                <span
                                    :style="{'background-color': settings.button_hover_border}"
                                ></span>
                                <input class="mx-2" :value="settings.button_border_color"/>
                            </span>
                            <color
                                v-click-outside="show.color.button_hover_border"
                                v-if="show.color.button_hover_border"
                                :value="settings.button_hover_border"
                                @input="style => settings.button_hover_border = style.hex"
                            ></color>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import {Chrome} from 'vue-color'
import TransitionExpand from '../../TransitionExpand'
import {CraftTextEditor} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    name: "CardSettings",
    mixins: [ComponentSettingsMixin],
    components: {
      color: Chrome,
      TransitionExpand,
      CraftTextEditor
    },
    data() {
        return {
            settings: {
                image: '/uploads/defaults/image-placeholder.jpg',
                image_width: '100',
                image_align: '0 auto 0 auto',
                title: 'Calving Howards',
                title_color: 'rgb(0, 0, 0)',
                title_font_size: '24',
                title_alignment: 'left',
                description: encodeURIComponent('Out of town'),
                description_color: 'rgb(0, 0, 0)',
                description_font_size: '20',
                description_alignment: 'left',
                button_text: 'Push the button',
                button_width: '100',
                button_color: JSON.parse(window.localStorage.getItem('colors')).secondary_color,
                button_background_color: JSON.parse(window.localStorage.getItem('colors')).primary_color,
                button_border_width: '1',
                button_border_color: JSON.parse(window.localStorage.getItem('colors')).primary_color,
                button_border_radius: '50',
                button_align: '0 auto 0 auto',
                button_hover_background: JSON.parse(window.localStorage.getItem('colors')).secondary_color,
                button_hover_border: JSON.parse(window.localStorage.getItem('colors')).secondary_color,
                button_hover_color: JSON.parse(window.localStorage.getItem('colors')).primary_color
            },

            show: {
                image: true,
                title: true,
                description: true,
                button: true,
                color: {
                    title: false,
                    description: false,
                    button_color: false,
                    button_border: false,
                    button_background: false,
                    button_hover_color: false,
                    button_hover_border: false,
                    button_hover_background: false,
                },
            }
        }
    },
    mounted() {
        this.$fileManager.$on(`selected_element_image`, imgs => {
            this.settings.image = imgs[0]
        })
    },
    methods : {
        inputVal(style) {
            let output

            style === '0 auto 0 0'
                ? output ='left'
                : style === '0 auto 0 auto' ? output = 'center' : output = 'right'

            return output
        },
        computedAlignProp(ev, prop) {
            ev === 'left'
                ? this.settings[prop] = '0 auto 0 0'
                : ev === 'center'
                    ? this.settings[prop] = '0 auto 0 auto'
                    : this.settings[prop] = '0 0 0 auto'
        },
        elementsAlignValue(prop) {
            return this.inputVal(this.settings[prop])
        }
    },
    computed: {
        textAlignOptions() {
            return [
                {key: 'Left', value: 'left'},
                {key: 'Center', value: 'center'},
                {key: 'Right', value: 'right'}
            ]
        }
    },
}
</script>

<style scoped>

</style>
