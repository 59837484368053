<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :name="translate('Color', 'page-builder')" :selected="true">
        <div class="row">
          <div class="col-md-12">
            <craft-color-picker
              label="Background color"
              :has-responsive-styles="true"
              :placeholder="getPlaceholder('background-color')"
              :value="getStyle('background-color')"
              @input="setStyle('background-color', $event);"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Image', 'page-builder')">
        <div class="row">
          <div class="col-md-12">
            <craft-tab-pills
              label="Background size"
              :options="backgroundSizeOptions"
              :has-responsive-styles="true"
              @input="setStyle('background-size', $event)"
              :value="getStyle('background-size')"
            />
          </div>

          <div class="col-md-12">
            <craft-tab-pills
              label="Repeat"
              :options="repeatOptions"
              :has-responsive-styles="true"
              @input="setStyle('background-repeat', $event)"
              :value="getStyle('background-repeat')"
            />
          </div>

          <div class="col-md-12">
            <craft-tab-pills
              label="Position"
              :options="positionOptions"
              :has-responsive-styles="true"
              @input="setStyle('background-position', $event)"
              :value="getStyle('background-position')"
            />
          </div>
          <div class="col-md-12">
            <craft-image-picker
              label="Background image"
              @input="setBackgroundImage"
              :has-responsive-styles="true"
              :value="getStyle('background-image', null, 'background-image')"
              :node="this.editor.selectedNode"
              :type="'background-image'"
            />
          </div>
          <div class="col-md-12">
            <craft-color-picker
              label="Overlay color"
              :value="getSetting('background_image_overlay_color')"
              @input="setSetting('background_image_overlay_color', $event)"
            />
          </div>

          <div class="col-md-12">
            <craft-input
              label="Overlay opacity"
              input-type="range"
              min="0"
              max="100"
              suffix="%"
              :value="getSetting('background_image_overlay_opacity', '%') || '100'"
              @input="setSetting('background_image_overlay_opacity', $event, '%')"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab v-if="canHaveVideoBackground" :name="translate('Video', 'page-builder')">
        <div class="row">
          <div class="col-md-12">
            <craft-input
              label="Video url"
              :value="getSetting('background_video_link')"
              @input="setSetting('background_video_link', $event)"
            />

            <transition-expand>
              <p v-if="isYoutubeVideo"><i class="fa-solid fa-info-circle"></i> <small> {{ translate('By using a YouTube video as a background you may violate YouTube policies. By enabling this you are responsible for any liability.', 'page-builder') }}</small></p>
            </transition-expand>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="label">{{ translate('Start time', 'page-builder') }}</label>
              <date-picker-field
                :field-props="{ type: 'time' }"
                :placeholder="'HH:MM:SS'"
                :value="getSetting('background_video_start_time')"
                @input="setSetting('background_video_start_time', $event)"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="label">{{ translate('End time', 'page-builder') }}</label>
              <date-picker-field
                :field-props="{ type: 'time' }"
                :placeholder="'HH:MM:SS'"
                :value="getSetting('background_video_end_time')"
                @input="setSetting('background_video_end_time', $event)"
              />
            </div>
          </div>

          <div class="col-md-12">
            <craft-toggle
              :id="`background_video_loop_${node.uuid}`"
              label="Loop video"
              :value-off="0"
              :value-on="1"
              :value="getSetting('background_video_loop')"
              @input="setSetting('background_video_loop', $event)"
            />
          </div>

          <div class="col-md-12">
            <craft-input
              label="Video opacity"
              input-type="range"
              :min="0"
              :max="100"
              suffix="%"
              :value="getSetting('background_video_opacity', '%')"
              @input="setSetting('background_video_opacity', $event, '%')"
            />
          </div>

          <div class="col-md-12">
            <craft-color-picker
              label="Overlay color"
              :value="getSetting('background_video_overlay_color')"
              @input="setSetting('background_video_overlay_color', $event);"
            />
          </div>

          <div class="col-md-12">
            <craft-input
              label="Overlay opacity"
              input-type="range"
              min="0"
              max="100"
              suffix="%"
              :value="getSetting('background_video_overlay_opacity', '%')"
              @input="setSetting('background_video_overlay_opacity', $event, '%')"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import TransitionExpand from '../../../../TransitionExpand.vue'
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftStyleSettingsMixin from '@/modules/pagebuilder/mixins/CraftStyleSettingsMixin'
import { CraftColorPicker, CraftImagePicker, CraftTabPills } from "@/modules/pagebuilder/components/craft-inputs"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import CraftInput from "@/modules/pagebuilder/components/craft-inputs/CraftInput.vue"
import CraftToggle from "@/modules/pagebuilder/components/craft-inputs/CraftToggle.vue"
import DatePickerField from "@/modules/erp_entities/components/page/form/fields/DatePickerField.vue"
import { getVideoProviderAndCode } from "@/utilities/helper"

export default {
  name: "Background",
  components: {
    CraftToggle,
    CraftInput,
    CraftColorPicker,
    CraftImagePicker,
    CraftTabPills,
    DatePickerField,
    TransitionExpand,
  },
  mixins: [ComponentSettingsMixin, CraftStyleSettingsMixin, CraftSettingsMixin],
  inject: ['editor'],
  computed: {
    backgroundSizeOptions() {
      return [
        { key: 'auto', label: 'Auto' },
        { key: 'contain', label: 'Contain' },
        { key: 'cover', label: 'Cover' },
      ]
    },
    repeatOptions(){
      return [
        { key: 'repeat', label: 'repeat' },
        { key: 'no-repeat', label: 'no-repeat' },
        { key: 'repeat-x', label: 'repeat-x' },
        { key: 'repeat-y', label: 'repeat-y' },
      ]
    },
    positionOptions(){
      return [
        { key: 'center', label: 'Center' },
        { key: 'top', label: 'Top' },
        { key: 'bottom', label: 'Bottom' },
        { key: 'left', label: 'Left' },
        { key: 'right', label: 'Right' },
      ]
    },
    canHaveVideoBackground() {
      return ['SectionElement', 'RowElement', 'ColumnElement'].includes(this.node.props.component)
    },
    isYoutubeVideo() {
      if (this.getSetting('background_video_link')) {
        const provider = getVideoProviderAndCode(this.getSetting('background_video_link'))
        return provider ? provider.provider === 'youtube' : false
      }
      return false
    },
  },
  created() {
    this.normalizeComponentSettings()
  },
  methods: {
    setBackgroundImage(imageUrl){
      this.setStyle('background-image', this.escapeHtmlAttribute(imageUrl))

      // no idea why this is here
      // this.setStyle('background-size', this.getStyle('background-size'));
      // this.setStyle('background-repeat', this.getStyle('background-repeat'))
    },

    escapeHtmlAttribute(value) {
      return value.replace(/['"&<>]/g, function(character) {
        switch (character) {
        case "'": return '%27'
        case '"': return '%22'
        default: return character
        }
      })
    },
  },
}
</script>