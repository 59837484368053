<template>
  <page-title :page-title="translate('Data Management', 'management')"></page-title>
</template>

<script>
export default {
  name: "DataManagement"
}
</script>

<style scoped>

</style>