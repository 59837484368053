<template>
  <div v-if="value">
    <progress-bar
      :value="value"
      :total="100"
      colorful="true"
    />
    {{ value }}%
  </div>
</template>

<script>
export default {
  name: "PercentageBar",
  props: {
    value: {
      required: true,
    },
  },
}
</script>