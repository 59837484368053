<template>
  <div class="cms-block cms-block-whitespace"></div>
</template>

<script>

export default {
  name: 'WhitespaceElement',
  craft: {
    defaultProps:{
      elementStyle: {
        desktop: {
          "height": '50px',
        },
      },
    },
  },
};
</script>