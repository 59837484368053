<template>
    <div>
		<div class="w-100 d-flex justify-content-center my-3">
			<div class="d-flex justify-content-end align-items-center h-100">
				<div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
					<a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown"
					   aria-haspopup="true" aria-expanded="false">
            {{ translate('{count} per page', 'global', { count: tableParams.per_page }) }}
						<i class="fa fa-chevron-down"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
						<a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi"
						   @click="setItemsPerPage(per_page.key)">
							{{ per_page.value }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-column w-100 justify-content-center align-items-center">
			<div class="d-flex w-75">
				<div class="switch-field w-100">
					<div class="five-parts">
						<input id="all" name="radio" type="radio" @click="loadPlans({key: '',
                        value: '', condition: ''})">
						<label class="w-100" for="all">{{ translate('All', 'orders') }}</label>
					</div>
					<div class="five-parts" v-for="(status, p) in filterByPeriod" :key="p">
						<input :id="status.key" name="radio" type="radio" @click="loadPlans({key: 'period',
                        value: status.key, condition: '='})">
						<label class="w-100" :for="status.key">{{ translate(status.value, 'orders') }}</label>
					</div>
				</div>
			</div>
		</div>

        <div class="row" v-if="plans && !loading">
            <div class="col-md-6" v-for="(plan, p) in plans.data" :key="p">
                <div class="plan-container my-4 p-3 pb-3">
                    <div class="plan-header text-center pt-4">
                        <span class="plan-type font-weight-bold">{{ plan.slug }}</span>
                        <div class="plan-price d-flex justify-content-center align-items-center">
                            <span class="plan-value font-weight-bold pr-1">€</span>
                            <span class="plan-amount font-weight-bold">{{ plan.price.price }}</span>
                        </div>
                        <div>
                            <span v-if="plan.readable_options.period === 1">{{ translate('per month', 'orders') }}</span>
                            <span v-if="plan.readable_options.period === 12">{{ translate('per year', 'orders') }}</span>
                            <span v-if="plan.readable_options.period === 24">{{ translate('per two years', 'orders') }}</span>
                            <span v-if="plan.readable_options.period === 36">{{ translate('per three years', 'orders') }}</span>
                        </div>
                        <span class="plan-extra-user">$5,00 {{ translate('extra user per month', 'orders') }}</span>
                    </div>
                    <div class="plan-details">
                        <div v-for="(limit, l) in plan.limits" :key="l"
                             class="plan-single-detail mx-3 py-1 d-flex justify-content-between">
                            <span>{{ limit.pivot.inventory_name }}</span><span>{{ limit.pivot.qty }}</span>
                        </div>
                    </div>
                    <div class="m-3 py-1">
                        <button v-if="!plan.added" class="w-100 btn btn-primary rounded-pill" :disabled="value.plan"
								@click="choosePlan(plan)">{{ translate('Choose plan', 'orders') }}
						</button>
                        <button v-else class="w-100 btn btn-danger" @click="removePlan(plan)">{{ translate('Remove plan', 'orders') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center m-5">
            <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
        </div>
    </div>
</template>

<script>
import {db, routes} from "../../../../../utilities/database";
import {createEntityFormObjectNoTabs, urlEncoder} from "../../../../../utilities/helper";
import {mapGetters, mapActions, mapState} from 'vuex';
import debounce from "debounce";

export default {
    name: "ChoosePlan",
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {
			plans: null,
			loading: false,
			pagination: null,
			tableParams: {
				per_page: 5,
				page: 1
			}
        }
    },
	methods: {
        ...mapActions('blockzCheckout', ['addToCart', 'removeFromCart', 'getCart']),
		goToPage(page) {
			this.tableParams.page = page
			this.loadPlans()
		},
		setItemsPerPage(per_page) {
			this.tableParams.per_page = per_page
			this.loadPlans()
		},
		loadPlans: debounce(async function (filters) {
			this.loading = true
			this.$store.state.system.isLoading = true

			let params = null
			if (filters) {
				params = {
					'filter_criteria': {
						'filter_groups': {
							0: {
								'filters': {
									0: filters
								}
							}
						}
					}
				}
			}

			if (filters && filters.key === 'period') {
				this.selectedFilter = filters.value
			} else {
				this.selectedFilter = null
			}

			let columns = {column: 'created_at', direction: 'desc', type: 'text'}

			let response = await
				db.get(`${routes.plans.index}?page=${this.tableParams.page}&perpage=${this.tableParams.per_page}&${urlEncoder(columns)}&${urlEncoder(params)}`)

			response.data.data.forEach(plan => {
                this.$set(plan, 'added', false)
				plan.readable_options = createEntityFormObjectNoTabs(plan.options, 'key')
				plan.limits.forEach(pivot => {
					plan.limit_options = pivot
				})
				this.plans = response.data
			})

			if (this.value.plan) {
				this.plans.data.forEach(plan => {
					if(plan.id === this.value.plan.id) {
						plan.added = true
					} else {
						plan.added = false
					}
				})
			}

			this.loading = false
			this.$store.state.system.isLoading = false
		}, 700),
        choosePlan(plan) {
        	plan.added = true
            this.value.plan = plan
            this.addToCart(plan)
			this.$router.push({path: 'limits'})
        },
        removePlan(plan) {
        	plan.added = false
            delete this.value.plan
			this.$store.state.blockzCheckout.cart = []
			this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
		}
    },
    computed: {
        ...mapState('blockzCheckout', ['cart', 'data']),
		filterByPeriod() {
			return [
				{key: 1, value: 'month'},
				{key: 12, value: 'year'},
				{key: 24, value: 'two_years'},
				{key: 36, value: 'three_years'}
			]
		},
		perPage() {
			return [
        { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
        { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
        { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
        { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
			]
		}
    },
    mounted() {
        // Start session for cart
        let session = window.localStorage.getItem('session')
        if (!session) {
            db.get('modules/blockz/cart/start-session ').then(res => {
                window.localStorage.setItem('session', res.data['carttoken'])
                db.defaults.headers.common['carttoken'] = res.data['carttoken']
            })
        } else {
            db.defaults.headers.common['carttoken'] = session
        }

        this.loadPlans()
    },
	beforeRouteLeave: function(to, from, next) {
		if (to.name === 'limits') {
			next()
		} else {
			window.localStorage.removeItem('session')
			this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
			this.$store.state.blockzCheckout.cart = []
			next();
		}
	}
}
</script>

<style scoped lang="scss">
.switch-field {
    border: 1px solid $primary !important;
    border-radius: 5px !important;

    label {
        flex: 0 0 25%;
        color: $primary;
        margin: 0;
        border-radius: unset !important;
        border-right: 1px solid;

		&:hover {
			cursor: pointer;
		}

        &:last-of-type {
            border-right: none;
        }
    }

    input:checked + label {
        background: $primary;
        color: #fff;
    }

    .five-parts {
        flex: 0 0 20%;
    }
}

.plan-container {
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #fff;

    .plan-header {
        span {
            color: #b5c4d9;
            font-size: 12px;
        }

        .plan-type {
            text-transform: uppercase;
        }

        .plan-price {
            .plan-value {
                font-size: 16px;
                color: #000;
            }

            .plan-amount {
                font-size: 36px;
                color: #000;
            }
        }

        .plan-extra-user {
            color: #a0a0a0;
        }
    }

    .plan-details {
        .plan-single-detail {
            border-bottom: 1px solid #bfbfbf;

            .fa-check-circle {
                color: #79eab9;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .switch-field {
        flex-direction: column;

        label {
            margin: 0;
            border-radius: unset !important;
            border-bottom: 1px solid;
            border-right: none;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

</style>
