<template>
  <div class="file-picker p-0">
    <div class="container-fluid">
      <div class="row">

        <div class="col-md-7 p-1">

          <draggable v-model="files"
                     v-bind="dragOptions"
                     v-on="!isReadonly ? { start: drag = true, end: drag = false } : null"
                     draggable=".draggable-item"
                     class="file-picker_files">

            <div class="item draggable-item m-2" v-for="(file, idx) in files" :key="idx" @click="canWrite ? selectImage(idx) : null" :class="{ selected: imageSelected === idx}">
              <div class="initial_file-picker file-picker_content">
                <img :src="file.url" :alt="file.alt" :title="file.title">
                <div class="file_overlay" v-if="file.title">
                  <div class="text">{{ entityLabel(file.title) }}</div>
                </div>
                <div class="file-options_row" v-if="idx === 0">
                  <a href="javascript:;" >
                    <i class="fas fa-star bg-transparent"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="item m-2" @click="canWrite ? triggerFilemanager() : null">
              <div class="initial_file-picker file-picker_content">
                <i class="fa-3x fas fa-circle-plus"></i>
                <span>{{ translate('Add image(s)', 'entities') }}</span>
              </div>
            </div>
          </draggable>

          <!--
          <div class="item m-1" v-if="files.length <= 1">
            <div class="initial_file-picker">
              <div class="file-picker_content">
                <i class="far fa-images"></i>
              </div>
            </div>
          </div>
          <div class="item m-1" v-if="files.length <= 2">
            <div class="initial_file-picker">
              <div class="file-picker_content">
                <i class="far fa-images"></i>
              </div>
            </div>
          </div>
          -->

        </div>

        <div class="col-md-5 file_settings" v-if="imageSelected || imageSelected === 0">
          <div class="form-group">
            <div class="row justify-content-between">
              <div class="col-md-8">
                <label class="">{{ translate('Set image as main image:', 'entities') }}</label>
              </div>
              <div class="custom-control custom-switch col-md-4 text-right">
                <input type="checkbox"
                       name=""
                       :id="`option-featured-image-${imageSelected}`"
                       class="custom-control-input"
                       @change="canWrite ? makeFeatured(imageSelected) : null"
                       :disabled="imageSelected == 0"
                       :checked="imageSelected == 0"
                       :true-value="1"
                       :false-value="0">
                <label :for="`option-featured-image-${imageSelected}`" class="custom-control-label"></label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ translate('Image alt text', 'entities') }}</label>
            <input-field v-model="files[imageSelected].alt"
                         :field-props="fieldProps"/>
          </div>
          <div class="form-group">
            <label>{{ translate('Image title', 'entities') }}</label>
            <input-field v-model="files[imageSelected].title"
                         :field-props="fieldProps"/>
          </div>
          <div class="form-group py-2">
<!--            <button @click="remove(imageSelected)" class="btn btn-secondary rounded-circle">-->
<!--              <i class="far fa-trash-can"></i>-->
<!--            </button>-->
            <button @click="canWrite ? remove(imageSelected) : null" class="btn btn-white rounded-pill">
              <i class="far fa-trash-can"></i> {{ translate('Delete', 'entities') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash"
import { isVoidValue } from "@/utilities/helper"
import DraggableMixin from "@/mixins/DraggableMixin"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: 'FilePicker',
  mixins: [DraggableMixin, FormFieldMixin],
  components: {
    InputField,
  },
  data() {
    return {
      key: 0,
      imageSelected: null,
      files: [],
      alt: null,
      title: null,
    }
  },

  methods: {
    triggerFilemanager() {
      this.$fileManager.$emit('open', this.key)
    },
    remove(i) {
      if (isVoidValue(i)) return
      this.files.splice(i, 1)
      this.imageSelected = null
      this.$emit('input', JSON.stringify(this.files))
    },
    makeFeatured(i) {
      var removedElement = this.files.splice(i, 1)
      this.files.splice(0, 0, removedElement[0])
      this.imageSelected = 0
      this.$emit('input', JSON.stringify(this.files))
    },

    selectImage(idx) {
      if (this.imageSelected === idx) idx = null
      this.imageSelected = idx
    },
  },

  created() {
    this.key = uniqueId('fpi_')

    this.files = (typeof this.value == 'string' ? JSON.parse(this.value) : this.value) || []

    // fpi = file picker input
    this.$fileManager.$on('selected_'+ this.key, imgs => {
      imgs.forEach(img => this.files.push({ url: img, alt: this.alt, title: this.title }))

      this.$emit('input', JSON.stringify(this.files))
    })

  },

  watch: {
    'files': {
      handler: function (newFiles) {
        this.$emit('input', JSON.stringify(newFiles))
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.file-picker {
    border: 1px solid $border-color;
    padding: .5rem .5rem 0;

    .file-picker_files {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .item {
            width: 130px;
            height: 130px;
            border: 2px solid $border-color;
            cursor: pointer;

            .file-picker_content {
              position: relative;
              min-height: 70px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 13px;
          }

          img {
              width: 100%;
              height: 100%;
              object-fit: cover;
          }

          .file_overlay {
              position: absolute;
              right: 0;
              bottom: 0;
              left:  0;
              overflow: hidden;
              width: 100%;
              height: 0;
              transition: .2s ease;
              display: flex;
              justify-content: center;
              align-items: center;

              > .text {
                color: #fff;
                font-size: 12px;
                text-align: center;
              }
          }

          &:hover, &.selected {
              border-color: $primary;

              .file_overlay {
                  height: 25%;
                  background-color: $primary;

              }
          }
      }

      .file-options_row {
          position: absolute;
          top: 2px;
          right: 2px;
          cursor: pointer;

          a {
              i {
                font-size: 1.5rem;
              }
          }
      }

      .col-md-3 {
          margin-bottom: 1rem;
      }
  }

  .initial_file-picker {
      span {
        color: var(--vs-colors--light);
      }

      &.file-picker_content, .file-picker_content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          i:not(.fa-star) {
              font-size: 2rem;
              color: var(--vs-colors--light);
          }
      }
  }

  .file_settings {
      padding: 2rem 1rem;
      line-height: 0.8;
      border-left: 1px solid $border-color;

      .form-group {
        .custom-control {
            &.custom-switch {
            margin-top: -5px;
          }
        }
      }
  }

  @media all and (max-width: 767px)  {
    .file_settings {
      border-left: none;
    }
  }
}
</style>