<template>
	<div>
		<page-title :page-title="translate('Modules', 'modules')">
			<template v-slot:actions>
				<router-link to="/extensions-create" class="btn btn-primary">{{ translate('Add new', 'entities') }}</router-link>
			</template>
		</page-title>
		<div class="extension-search-field w-100 my-3">
			<span class="p-3"><i class="fas fa-search mr-3"></i></span>
			<input class="form-control w-100 pl-5"
				   @input="e => loadModules({key: 'name', value: `%${e.target.value}%`, condition: 'like'})"
				   :placeholder="translate('Search', 'modules')">
		</div>
		<div class="container">
			<div class="row" v-if="modules && !loading">
				<div class="col-md-4" v-for="(module, e) in modules" :key="e">
					<div class="modules-holder bg-white d-flex flex-column mt-4">
						<div class="img-holder">
							<router-link :to="`/app-show/${module.id}`">
								<img v-if="module.readable_options.logo" :src="module.readable_options.logo">
								<img v-else src="/uploads/defaults/image-placeholder.jpg">
							</router-link>
						</div>
						<div class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
							<div class="d-flex flex-column w-100">
								<div class="text-center">
									<span class="modules-title d-block">{{ module.name }}</span>
									<span class="modules-rating font-weight-bold">
										{{ module.readable_options.description }}
									</span>
								</div>
							</div>
						</div>
						<div class="w-100 modules-actions">
							<div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
								<div v-if="module.status === 1"
									 class="d-flex justify-content-center align-items-center">
									<span class="p-1"><i class="fas fa-circle d-flex align-items-center"></i></span>
									<span>{{ translate('Online', 'modules') }}</span>
								</div>
								<div v-else class="d-flex justify-content-center align-items-center offline">
									<span class="p-1"><i class="fas fa-circle d-flex align-items-center"></i></span>
									<span>{{ translate('Offline', 'modules') }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="loading" class="d-flex justify-content-center m-5">
			<span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
		</div>
	</div>
</template>

<script>
import {db} from "@/utilities/database";
import debounce from "debounce";
import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";

export default {
	name: "Modules",
	data() {
		return {
			modules: null,
			loading: false
		}
	},
	methods: {
		loadModules: debounce(async function (filters) {
			this.loading = true;

			let params = null
			if (filters) {
				params = {
					'filter_criteria': {
						'filter_groups': {
							0: {
								'filters': {
									0: filters
								}
							}
						}
					}
				}
			}

			if (filters && filters.key === 'status') {
				this.selectedFilter = filters.value
			}

			let columns = {column: 'created_at', direction: 'desc', type: 'text'}

			let response = await db.get(`modules/blockz/app-extensions/extensions-modules?page=1&perpage=200&${urlEncoder(columns)}&${urlEncoder(params)}`)
			response.data.data.forEach(module => {
				module.readable_options = createEntityFormObjectNoTabs(module.options, 'key')
				if (module.readable_options.description) {
					module.readable_options.description = module.readable_options.description.replace(/(<([^>]+)>)/gi, "")
				}
			})

			this.modules = response.data.data
			this.loading = false;
		}, 700),
	},
	created() {
		this.loadModules()
	},
}
</script>

<style scoped lang="scss">
.plan-counter {
	background-color: #DEDEDE;
	border-radius: 50px;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.extension-search-field {
	span {
		position: absolute;
		cursor: pointer;

		.fa-search {
			color: #DEDEDE;
			font-size: 20px;
			font-weight: 600;
		}
	}

	input {
		border: 1px solid #DEDEDE;
		border-radius: 10px;
		font-size: 18px;
		color: $body-color;
		background-color: #fff;

		&:focus {
			outline: none;
		}
	}
}

.modules-holder {
	border: 1px solid #DEDEDE;
	border-radius: 10px;
	margin-bottom: 1rem;

	.img-holder {
		img {
			width: 100%;
			height: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}

	.info-holder {
		.modules-title {
			font-size: 18px;
			font-weight: bold;
		}

		.modules-rating {
			color: #DEDEDE;
			font-size: 16px;
		}
	}

	.modules-actions {
		i.fa-circle {
			font-size: 8px;
			color: #00da7d;
		}

		.offline {
			i.fa-circle {
				color: red;
			}
		}

		.btn-edit {
			color: #000;
			background-color: #fff;
			border-color: #000;
			border-radius: 10px;
			&:hover {
				color: $primary;
				background-color: #fff;
				border-color: $primary;
			}
		}
	}
}
</style>