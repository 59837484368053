<template>
  <div>
    <div v-if="!$store.state.layout.preview">
      <!-- Builder is required because the method that triggers -->
      <builder />

      <div v-if="entity && entity.objectData">
        <div class="position-relative">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-6">
                  <label>{{ translate('Title', 'entities') }} <span style="color: red">*</span></label>
                  <input v-model="entity.objectData.name" type="text" name="title" class="form-control">
                </div>
                <div class="col-6">
                  <label>{{ translate('Items', 'entities') }}</label>
                  <input v-model="entity.objectData.items" type="number" name="title" class="form-control">
                </div>
                <div class="col-4">
                  <div class="custom-control custom-switch my-3">
                    <input
                      id="mosue_drag"
                      v-model="entity.objectData.mouse_drag"
                      type="checkbox"
                      name="mosue_drag"
                      :true-value="1"
                      :false-value="0"
                      class="custom-control-input"
                    >
                    <label for="mosue_drag" class="custom-control-label">
                      {{ translate('Mouse drag', 'entities') }}
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="custom-control custom-switch my-3">
                    <input
                      id="navigation"
                      v-model="entity.objectData.navigation"
                      type="checkbox"
                      name="navigation"
                      :true-value="1"
                      :false-value="0"
                      class="custom-control-input"
                    >
                    <label for="navigation" class="custom-control-label">
                      {{ translate('Navigation', 'entities') }}
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="custom-control custom-switch my-3">
                    <input
                      id="pagination"
                      v-model="entity.objectData.slider_pagination"
                      type="checkbox"
                      name="pagination"
                      :true-value="1"
                      :false-value="0"
                      class="custom-control-input"
                    >
                    <label for="pagination" class="custom-control-label">
                      {{ translate('Pagination', 'entities') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="entity.objectData.images" class="col-12">
                  <file-picker :key="entity.objectData.id" v-model="entity.objectData.images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slider-show v-else v-model="data" />
  </div>
</template>

<script>
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin"
import EntityCrudMixin from '@/modules/pagebuilder/mixins/EntityCrudMixin'
import UploadImage from "../../components/UploadImage"
import FilePicker from "@/components/FilePicker.vue"
import SliderShow from './Show'
import { mapActions } from "vuex"

export default {
  name: "SliderCreate",
  components: {
    UploadImage,
    FilePicker,
    SliderShow,
  },
  mixins: [BuilderMixin, EntityCrudMixin],
  data() {
    return {
      defaultData: {
        images: [],
        name: '',
        items: 1,
        mouse_drag: false,
        navigation: false,
        slider_pagination: false,
      },
    }
  },
  mounted() {
    this.$set(this.entity.objectData, 'website_id', this.$store.state.system.scope.value)
  },
  methods: {
    ...mapActions('builder', [
      'hideGlobalLoader',
    ]),
  },

  watch: {

    // copied and modified from Builder.vue because we don't need the whole Builder here
    // called when the entity is updated (after save) or when the entity is changed (when you switch to another or click create new)
    '$store.state.builder.originalState': {
      deep: false,
      handler(){
        this.hideGlobalLoader()
      },
    },
  },
}
</script>

<style scoped lang="scss">
.custom-control-label {
  padding-left: 0.5rem;
}
.add-image {
	height: 150px;
	width: 100%;
  border: 2px solid $border-color;
	border-radius: .375rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	margin: 1rem 0;

	&._images {
		position: relative;

		.gallery-image {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		.remove-image {
			position: absolute;
			top: 3px;
			right: 5px;
			font-size: 12px;
			opacity: 0;
			visibility: hidden;
			transition: all .3s ease;
			cursor: pointer;
		}

		&:hover {
			.remove-image {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
</style>