<template>
  <entity-index-content
    :entity-type="$route.params.entityType"
  />
</template>

<script>
import EntityIndexContent from './EntityIndexContent.vue'

export default {
  name: "Entity",
  components: {
    EntityIndexContent,
  },

  watch: {
    '$route.params.entityType': function(){
      this.entityType = this.$route.params.entityType
    },
  },
}
</script>
