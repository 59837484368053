<template>
    <div>
        <div class="row">
            <div class="col-md-12" v-for="(limit, li) in get_limits" :key="li">
                <label class="input-label">{{ translate('Add extra', 'orders') }} {{ limit.slug }}</label>
                <div class="d-flex flex-column">
                    <div class="switch-field filtered-limits w-100 d-flex justify-content-between">
                        <div>
                            <input @input="limit.properties.editable = true" :id="`yes-${limit.id}`" :name="`${limit.id}`" type="radio">
                            <label class="w-100" :for="`yes-${limit.id}`">{{ translate('Yes', 'orders') }}</label>
                        </div>

                        <div>
                            <input checked @input="limit.properties.editable = false" :id="`no-${limit.id}`"
									:name="`${limit.id}`" type="radio">
                            <label class="w-100" :for="`no-${limit.id}`">{{ translate('No', 'orders') }}</label>
                        </div>
                    </div>

                    <div v-if="limit.properties.editable === true">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="limit-box d-flex flex-column py-3">
                                <label class="input-label text-center">{{ translate('Enter quantity', 'orders') }}</label>
                                <input @input="calcPrice(limit)" v-model.number="limit.properties.qty" class="input-box text-center">
                            </div>

                            <div class="limit-box d-flex flex-column py-3">
                                <label class="input-label text-center">{{ translate('Price', 'orders') }}</label>
                                <input v-model.number="limit.properties.price" class="input-box text-center">
                            </div>

                            <div class="limit-box d-flex align-items-center align-self-end py-3">
                                <button @click="makeFree(limit)" class="btn btn-secondary rounded-pill">
                                    {{ translate('Make it free', 'orders') }}
                                </button>
								<button v-if="limit.added === false" @click="addLimitToCart(limit)" class="btn btn-primary rounded-pill">
									{{ translate('Add to cart', 'orders') }}
								</button>
								<button v-else @click="removeLimitFromCart(limit)" class="btn btn-primary rounded-pill">
									{{ translate('Remove from cart', 'orders') }}
								</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from "@/utilities/database";
import {mapActions, mapState} from 'vuex'
import {createEntityFormObjectNoTabs} from "@/utilities/helper";

export default {
    name: "Limits",
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {
            checked: false,
            isEdited: null,
            get_limits: [],
            extra_price: null,
            limits: {
                options: [1, 3, 5, 10]
            },
        }
    },
    methods: {
        ...mapActions('blockzCheckout', ['addToCart', 'removeFromCart']),
        loadLimits() {
            db.get(`/modules/blockz/limits`).then(res => {
                res.data.data.forEach(limit => {
					limit.readable_options = createEntityFormObjectNoTabs(limit.options, 'key')
					this.$set(limit, 'added', false)
                    limit.properties = {
                        editable: false,
                        qty: 0,
                        price: limit.price.price,
                        free: false,
                    }
					this.$set(limit, 'added', false)
                })

                this.get_limits = res.data.data
            })
        },
        calcPrice(limit) {
            if (limit.properties.price === 0) {
                limit.properties.price = limit.price.price
                this.$forceUpdate()
            }
            limit.properties.price = limit.properties.qty * limit.price.price
            this.$forceUpdate()
        },
        makeFree(limit) {
            limit.properties.free = !limit.properties.free
            if (limit.properties.free === true) {
                limit.properties.price = 0
            }
        },
		addLimitToCart(limit) {
			limit.added = true
			this.addToCart(limit)
		},
		removeLimitFromCart(limit) {
			limit.added = false
			this.removeFromCart(limit)
		}
    },
    computed: {
        ...mapState('blockzCheckout', ['cart'])
    },
    mounted() {
        this.loadLimits()
    },
	beforeRouteLeave: function(to, from, next) {
		if (to.name === 'choose-plan' || to.name === 'trial-and-extensions') {
			next()
		} else {
			window.localStorage.removeItem('session')
			this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
			this.$store.state.blockzCheckout.cart = []
			next();
		}
	},
}
</script>

<style scoped lang="scss">
.limit-box {


    .input-box {
        height: 45px;
        border: 1px solid;
        border-radius: 5px;

        &:focus {
            outline: none;
        }
    }
}

.switch-field.filtered-limits {
    border: 1px solid $primary !important;
    border-radius: 5px !important;

    div, input {
        flex-grow: 1;
        flex-basis: 0;
    }

	input:checked + label {
		background-color: $primary;
		color: #f6f6f6;
	}

    label {
        margin: 0;
        border-radius: unset !important;
        border-right: 1px solid;
		    color: $primary;

        &:last-of-type {
            border-right: none;
        }
    }
}
</style>
