
<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <devices-dropdown v-if="hasResponsiveStyles" />
    <div class="linear-pillbar">
      <button
        v-for="(option, index) of options"
        :key="index"
        class="option"
        :class="{selected: (option['key'] ? option['key'] : option) === value}"
        @click="$emit('input', options[index]['key'] ? options[index]['key'] : options[index])"
      >
        {{ translate((option['label'] ? option['label'] : option), 'page-builder') }}
      </button>
    </div>
  </div>
</template>

<script>
import DevicesDropdown from "@/modules/pagebuilder/components/craft-inputs/DevicesDropdown.vue"

export default {
  name: "CraftTabPills",
  components: { DevicesDropdown },
  inject: ['editor'],
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    hasResponsiveStyles: {
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .linear-pillbar {
    margin-top: 8px;
  }
}

.linear-pillbar {
  display: flex;
  min-height: 40px;
  position: relative;
  border-radius: 5rem;
  background-color: #f1f4f8;

  .option {
    outline: none;
    font-size: 0.8rem;
    background: transparent;
    border: none;
    flex: 1 1 auto;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;

    margin: 5px;
    border-radius: 22px;

    flex: 1; /* equal width */

    &.selected {
      background: #fff;
      color: black;

      &:hover,
      &:focus,
      &:active {
        background: #fff;
      }
    }

    &:last-child {
      border-right: none;
    }
  }
}
</style>
