<template>
  <div v-if="componentData" class="container mt-5">
    <carousel
      class="slider-carousel"
      :per-page="componentData.items"
      :navigation-enabled="navigation"
      :pagination-enabled="pagination"
      :mouse-drag="mouseDrag"
      @pageChange="loaded = false"
    >
      <slide v-for="(image, imageIdx) in JSON.parse(componentData.images)" :key="imageIdx" class="slider-container w-100 h-100">
        <img style="visibility: visible; width: 100%; height: 100%; object-fit: contain" :src="image">
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import EntityShowMixin from '../../mixins/EntityShowMixin'

export default {
  name: "SliderShow",
  components: { Slide, Carousel },
  mixins: [EntityShowMixin],
  data() {
    return {
      entity: 'sliders',
      loaded: false,
    }
  },
  computed: {
    mouseDrag() {
      return this.componentData.mouse_drag == 0 ? false : true
    },
    navigation() {
      return this.componentData.navigation == 0 ? false : true
    },
    pagination() {
      return this.componentData.slider_pagination == 0 ? false : true
    },
  },
  mounted() {
    setTimeout(function () {
      this.loaded = true
    }.bind(this), 500)
  },
}
</script>