<template>
  <span>

    <transition-group name="toggle-sidebar">
      <!-- "Components" need to use v-show so that dragend for Blueprint works -->
      <components
          :key="'components'"
          v-if="$store.state.builder.blocks"
          v-show="showComponents"
          ref="components"
          @closeLeftSidebar="toggleLeftSidebar('components')"
      />

      <pages
          v-if="showPages"
          :key="'pages'"
          @loadData="loadData"
          @closeLeftSidebar="toggleLeftSidebar( 'pages')"
      />

      <!-- check "entity.objectData" to make sure the entity is loader, todo refactor -->
      <settings
          v-if="Object.keys(entity.objectData).length"
          v-show="showSettings"
          :key="'settings'"
          v-model="entity.objectData"
          @closeLeftSidebar="toggleLeftSidebar( 'settings')"
      />

      <seo
          v-if="showSeo"
          :key="'seo'"
          v-model="entity.objectData"
          @closeLeftSidebar="toggleLeftSidebar( 'seo')"
      />

      <css
          v-else-if="showCss"
          :key="'css'"
          @closeLeftSidebar="toggleLeftSidebar( 'css')"
      />

      <div v-else class="left-sidebar-content css-sidebar">
        <div class="left-sidebar-content--container css-sidebar--container nav-scrollbar">
          <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
            todo wtf is wrong?
          </div>
        </div>
      </div>

    </transition-group>

    <div class="pages-sidebar--overlay"
         v-if="isSidebarOpen && $store.state.builder.entities"
         @click="disableAllLeftSidebars"
    ></div>
  </span>
</template>

<script>
import BuilderMixin from '../../mixins/BuilderMixin'
import busEvent from "@/utilities/eventBus";

import Components from "./entities/Components";
import Pages from "./entities/Pages";
import Settings from "./entities/Settings";
import Seo from "./entities/Seo";
import Css from "./entities/Css";
import {mapState} from "vuex";

export default {
  name: "LeftSidebarContent",
  mixins: [BuilderMixin],
  components: {
    Components, Pages, Settings, Seo, Css
  },
  data() {
    return {
      showPages: false,
      showComponents: false,
      showSeo: false,
      showCss: false,
      showSettings: false,
    }
  },
  mounted() {
    busEvent.$on("toggleLeftSidebar", (type) => {
      this.toggleLeftSidebar(type);
    });
  },
  computed: {
    ...mapState('builder', [
      'entity', 'entities'
    ]),
    isSidebarOpen() {
      let {showPages, showComponents, showSeo, showCss, showSettings, showCategories} = this;
      return showPages || showComponents || showSeo || showCss || showSettings || showCategories;
    },
  },
  methods: {
    toggleLeftSidebar(type) {
      switch (type) {
        case 'components':
          this.showComponents = !this.showComponents
          this.showPages = false;
          this.showSeo = false;
          this.showCss = false;
          this.showSettings = false;
          if (this.$refs['components'] && this.$refs['components'].focusInput) {
            this.$refs['components'].focusInput()
          }
          break;

        case 'pages':
          this.showPages = !this.showPages
          this.showComponents = false;
          this.showSeo = false;
          this.showCss = false;
          this.showSettings = false;
          break;

        case 'seo':
          this.showSeo = !this.showSeo
          this.showComponents = false;
          this.showPages = false;
          this.showCss = false;
          this.showSettings = false;
          break;

        case 'css':
          this.showCss = !this.showCss
          this.showComponents = false;
          this.showPages = false;
          this.showSeo = false;
          this.showSettings = false;
          break;

        case 'settings':
          this.showSettings = !this.showSettings
          this.showComponents = false;
          this.showPages = false;
          this.showSeo = false;
          this.showCss = false;
          break;
      }
    },
    disableAllLeftSidebars(){
      this.showComponents = false;
      this.showPages = false;
      this.showSeo = false;
      this.showCss = false;
      this.showSettings = false;
    },
    async loadData() {
      this.$emit('loadData')
    },
  },
}
</script>