<template>
    <div class="reseravtion">
        <div class="row mt-4">
                <!-- TODO: uncomment if we need it -->

<!--            <div class="col-md-3">-->
<!--                <div class="form-group">-->
<!--                    <label class="form-control-label">{{ translate('reset-list.pick_start_date') }}</label>-->
<!--                    <date-picker type="datetime" :minuteStep="15" :disabled-time="notBefore" :disabled-date="notBeforeToday" v-model="data.range.start" valueType="format" :input-class="['form-control']"></date-picker>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-md-3">-->
<!--                <div class="form-group">-->
<!--                    <label class="form-control-label">{{ translate('reset-list.pick_start_date') }}</label>-->
<!--                    <date-picker type="datetime" :disabled="!data.range.start" :minuteStep="15" :disabled-time="notBeforeSecond" :disabled-date="notBeforeTodaySecond" v-model="data.range.end" valueType="format" :input-class="['form-control']"></date-picker>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-md-3">-->
<!--                <button type="button" @click="addToCart(value)" class="btn btn-primary btn-sm">{{ translate('reset-list.add_to_cart') }}</button>-->
<!--            </div>-->

            <div class="col-md-12">
                <h5>{{ translate('inventory.items_in_cart') }}</h5>
                <ul class="reset-list">
                    <li class="py-1" v-for="(item, ii) in cart" :key="ii">
                        {{item.item_name}} <span class="cursor-pointer ml-3 text-danger" @click="removeFromCart({item: item, index: null})"><i class="fa fa-times"></i></span>
                    </li>
                </ul>
                <span>{{total}}</span>
            </div>
        </div>
        <div class="modal-footer remove-border">
            <button type="button" class="btn btn-white btn-sm" @click="$emit('close')">
              {{ translate('Go back', 'global') }}
            </button>
            <button type="submit" @click="reserve" class="btn btn-primary btn-sm">
                {{ translate('inventory.reserve') }}
            </button>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import {mapState, mapActions, mapGetters} from 'vuex'
    import moment from 'moment'
    import {db} from "@/utilities/database";
    import {clientDataToApiData} from "@/utilities/helpers/checkout";

    export default {
        name: "Reservation",
        components: {
            DatePicker
        },
        computed: {
            ...mapGetters('beachCheckout', ['total']),
            ...mapState('beachCheckout', ['data', 'cart']),
            ...mapState('system', ['authData']),
        },
        props: {
            value: {
                required: true
            }
        },
        methods: {
            ...mapActions('beachCheckout', ['addToCart', 'getCart', 'removeFromCart']),
            reserve() {
                db.post('/modules/sales/cart/complete', {
                    ...clientDataToApiData({...this.authData.user}, ''),
                }).then(res => {
                    this.$toast.success('The order has been placed successfully')
                    this.$store.state.beachCheckout.cart = []
                    this.$emit('successReservation')
                    window.localStorage.removeItem('session')
                })
            },
            // TODO: if they want to pick dates for custom reservation just uncomment html above
            notBefore(date) {
                let start =  date.getHours() < 9;
                let end =  date.getHours() > 15;
                return start + end
            },
            notBeforeSecond(date) {
                let start =  date.getHours() < moment(this.data.range.start).format('HH');
                let end =  date.getHours() > 15;
                return start + end
            },
            notBeforeToday(date) {
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notBeforeTodaySecond(date) {
                return date < new Date(this.data.range.start);
            },
        },
        mounted() {
            this.getCart()
        }
    }
</script>

<style scoped>
    .reseravtion .remove-border {
        border-bottom: 0 !important;
    }
</style>
