<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="input-label">{{ translate('Plan title', 'plans') }}</label>
                    <input v-model="value.name" class="create-plan-input form-control">
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <div class="d-flex flex-column">
                        <label class="input-label">{{ translate('Plan description', 'plans') }}</label>
                        <label class="text-secondary-color">{{ translate('Plan description text', 'plans') }}</label>
                    </div>
                    <div id="editor">
                        <text-editor-inline v-model="value.description" />
                    </div>
                </div>
            </div>

			<div class="col-md-12">
				<div class="form-group">
					<label class="input-label">{{ translate('Choose enviorment', 'plans') }}</label>
					<v-select class="text-capitalize" :reduce="value => value.key" :options="environment" label="value"
							  v-model="value.env">
					</v-select>
				</div>
			</div>

            <div class="col-md-12" v-if="value.env">
                <div class="form-group">
                    <label class="input-label">{{ translate('Choose module', 'plans') }}</label>
                    <v-select multiple :reduce="value => value.key" :options="apps" label="value"
							   v-model="value.apps"></v-select>
                </div>
            </div>

            <div class="col-md-12">
                <div class="basics-actions d-flex justify-content-between my-3 py-5">
                    <div class="hide-appstore d-flex flex-column w-50">
                        <label class="input-label">{{ translate('Hide in appstore', 'plans') }}</label>
                        <label class="text-secondary-color">{{ translate('Hide extension', 'plans') }}</label>
                        <div class="hide-app-store-holder custom-control custom-switch px-2">
                            <input type="checkbox" name="auto_save" id="autosave"
                                   class="custom-control-input" value="1">
                            <label for="autosave" class="custom-control-label"></label>
                        </div>
                    </div>
                    <div class="warning w-50">
                        <div class="content w-100 p-4">
                            <div>
                                <span><i class="fas fa-exclamation-triangle"></i></span>
                                <span class="font-weight-bold">{{ translate('Warning', 'plans') }}</span>
                            </div>
                            <span class="text-secondary-color">{{ translate('Warning text', 'plans') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="basics-footer d-flex justify-content-between my-2">
            <router-link to="overview">
                <button class="btn btn-white rounded-pill">{{ translate('Go back', 'global') }}</button>
            </router-link>
            <span class="step-count">{{ translate('steps 2 of 5', 'plans') }}</span>
            <router-link to="extensions">
                <button class="btn btn-primary rounded-pill">{{ translate('Continue', 'plans') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {db} from "../../../../../utilities/database";
    import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

    export default {
        name: "Basics",
        components: {
            TextEditorInline,
        },
        data() {
            return {
                apps: [],
				environment: [],
            }
        },
		mounted() {
            db.get(`modules/blockz/app-extensions/extensions`).then(res => {
                this.apps = res.data
            })

			db.get(`modules/get-system-environments`).then(res => {
				this.environment = res.data
			})
        },
        props: {
            value: {
                required: true
            },
        }
    }
</script>

<style lang="scss">
    .step-count {
        font-size: 12px;
        text-transform: uppercase;
        color: #b5c4d9;
    }

    .basics-actions {
        border-top: 1px solid #ecf1f9;
        border-bottom: 1px solid #ecf1f9;
    }

    .text-secondary-color {
        color: #666;
    }

    .input-label {
        font-size: 18px;
    }

    .create-plan-input {
        border: 1px solid #000;
        background-color: #fff;
    }

    #editor {
        .quillWrapper {
            border-radius: 5px;
            border: 1px solid #000;

            .ql-toolbar.ql-snow {
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid;
				background-color: #fff;
            }

            #quill-container {
				background-color: #fff;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
                height: 100px;
                border: none;
            }
        }
    }

    .hide-app-store-holder {
        margin-left: 3.5rem;
    }

    .warning .content {
        background-color: #edf2f9;
        border-radius: 5px;
        border: 1px solid #dae1eb;
    }

    .basics-footer {
        .btn-cancel {
            background: #fff;
            border-color: #b5c4d9;
            color: #000;
            &:hover {
                background: var(--secondary-btn-color);
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>