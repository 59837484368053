import Attributes from "@/modules/sitecart/views/attributes/Attributes";
import AttributesCrud from "@/modules/sitecart/views/attributes/AttributesCrud";
import AttributeSets from "@/modules/sitecart/views/attributes/AttributeSets";
import AttributeSetsCrud from "@/modules/sitecart/views/attributes/AttributeSetsCrud";
import ProductsCreate from "@/modules/sitecart/views/products/ProductsCreate";
import Products from "@/modules/sitecart/views/products/Products";
import VatTypes from "@/modules/sitecart/views/taxes/VatTypes";
import VatRates from "@/modules/sitecart/views/taxes/VatRates";

// Settings - Webshop
import WebshopSettings from "./views/settings/webshop/Settings"

import Shipping from "./views/shipping/Shipping";
import ShippingCreate from "./views/shipping/ShippingCreate";
import ShippingEdit from "./views/shipping/ShippingEdit";
import PaymentProviders from "@/modules/sitecart/views/payment/PaymentProviders";
import EditPaymentProvider from "@/modules/sitecart/views/payment/EditPaymentProvider";

import Orders from "@/modules/sitecart/views/orders/Orders";
import ShowOrder from "@/modules/sitecart/views/orders/ShowOrder";
import CreateOrder from "@/modules/sitecart/views/orders/CreateOrder"
import Export from "./views/Export";
import RequestQuotes from "./views/request_quote/RequestQuotes"
import ShowRequestQuote from "./views/request_quote/ShowRequestQuote"
import CreateCategory from "../cms/views/categories_and_tags/Create"
import Customers from "./views/customers/Customers";
import ShowCustomer from "./views/customers/ShowCustomer";
import CustomerCreate from "./views/customers/CustomerCreate";

import MediaLibrary from "../cms/views/cms/MediaLibrary";
import ProvisionSettings from "./views/settings/ProvisionSettings";
import CustomerGeneralInfo from "./views/customers/CustomerGeneralInfo";
import CustomerAddresses from "./views/customers/CustomerAddresses";
import CustomerOrders from "./views/customers/CustomerOrders";
import EditCustomerAddress from "./views/customers/EditCustomerAddress";
import Navigation from "../../layout/sidebar/navigation/WebsiteNavigation"
import CustomerGroups from "./views/customer_groups/CustomerGroups";
import CreateCustomerGroup from "./views/customer_groups/CreateCustomerGroup";
import Settings from "./views/translations/Settings";

export default [
    {
        path: '/media-library',
        name: 'sitecart-media-library',
        component: MediaLibrary,
        meta: {
            interface: 'sitecart'
        }
    },

    // Attributes routes
    {
        path: '/product-attributes',
        name: 'attributes',
        component: Attributes,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/product-attributes/create',
        name: 'create_attributes',
        component: AttributesCrud,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/product-attributes/:id',
        name: 'update_attributes',
        component: AttributesCrud,
        meta: {
            interface: 'sitecart'
        }
    },

    // Attribute sets routes
    {
        path: '/product-attributes-sets',
        name: 'attributes_sets',
        component: AttributeSets,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/product-attributes-sets/create',
        name: 'create_attributes_sets',
        component: AttributeSetsCrud,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/product-attributes-sets/:id',
        name: 'update_attributes_sets',
        component: AttributeSetsCrud,
        meta: {
            interface: 'sitecart'
        }
    },
    // Provisions
    {
        path: '/provision-settings',
        name: 'provision-settings',
        component: ProvisionSettings,
        meta: {
            interface: 'sitecart'
        }
    },

    // Translations
    {
        path: '/translations/settings',
        name: 'translations_settings',
        component: Settings,
        meta: {
            interface: 'sitecart'
        }
    },
    // Products routes
    {
        path: '/products',
        name: 'products-index',
        component: Products,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/products/create',
        name: 'products-create',
        component: ProductsCreate,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/products/:id',
        name: 'products-update',
        component: ProductsCreate,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/entity/:entity/:type/crud',
        name: 'sitecart-categories',
        component: CreateCategory,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/vat-types',
        name: 'vat-type',
        component: VatTypes,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/vat-rates',
        name: 'vat-rates',
        component: VatRates,
        meta: {
            interface: 'sitecart'
        }
    },

    // Webshop settings
    {
        path: '/webshop-settings/',
        name: 'webshop-settings',
        component: WebshopSettings,
        meta: {
            interface: 'sitecart'
        }
    },

    // Shipping
    {
        path: '/webshop-settings/shipping/list',
        name: 'webshop-shipping',
        component: Shipping,
        meta: {
            interface: 'sitecart'
        },
    },
    {
        path: '/webshop-settings/shipping/create',
        name: 'webshop-shipping',
        component: ShippingCreate,
        meta: {
            interface: 'sitecart'
        },
    },
    {
        path: '/webshop-settings/shipping/:type/:slug/edit',
        name: 'webshop-shipping',
        component: ShippingEdit,
        meta: {
            interface: 'sitecart'
        },
    },

    // Payments
    {
        path: '/payment-providers',
        name: 'payment-providers',
        component: PaymentProviders,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/payment-providers/:id',
        name: 'payment-providers-edit',
        component: EditPaymentProvider,
        meta: {
            interface: 'sitecart'
        }
    },

    // Orders
    {
        path: '/webshop-orders',
        name: 'webshop-orders',
        component: Orders,
        meta: {
            interface: 'sitecart'
        }
    },
    {
      path: '/webshop-orders/create',
      name: 'webshop-orders/create',
      component: CreateOrder,
      meta: {
        interface: 'sitecart'
      }
    },
    {
        path: '/webshop-orders/:id',
        name: 'webshop-orders-show',
        component: ShowOrder,
        meta: {
            interface: 'sitecart'
        }
    },

    // Request quotes
    {
        path: '/request-quotes/',
        name: 'request-quotes',
        component: RequestQuotes,
        meta: {
            interface: 'sitecart',
        }
    },
    {
        path: '/request-quotes/:id',
        name: 'request-quotes-show',
        component: ShowRequestQuote,
        meta: {
            interface: 'sitecart',
        }
    },

    // Webshop customers
    {
        path: '/webshop-customers',
        name: 'webshop-customers',
        component: Customers,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/webshop-customers/create',
        name: 'webshop-customers-create',
        component: CustomerCreate,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/webshop-customers/:id',
        name: 'webshop-customers-show',
        component: ShowCustomer,
        meta: {
            interface: 'sitecart'
        },
        children: [
            {
                path: 'account-information',
                name: 'account-information',
                component: CustomerGeneralInfo,
                meta: {
                    interface: 'sitecart',
                }
            },
            {
                path: 'addresses',
                name: 'addresses',
                component: CustomerAddresses,
                meta: {
                    interface: 'sitecart',
                }
            },
            {
                path: 'addresses/create',
                name: 'addresses-create',
                component: EditCustomerAddress,
                meta: {
                    interface: 'sitecart',
                }
            },
            {
                path: 'addresses/:addressId',
                name: 'addresses-update',
                component: EditCustomerAddress,
                meta: {
                    interface: 'sitecart',
                }
            },
            {
                path: 'orders',
                name: 'customer-orders',
                component: CustomerOrders,
                meta: {
                    interface: 'sitecart',
                }
            },
        ]
    },

    // Webshop customer groups
    {
        path: '/webshop-customer-groups',
        name: 'b2b_customer_groups',
        component: CustomerGroups,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/webshop-customer-groups/create',
        name: 'customer_groups_create',
        component: CreateCustomerGroup,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/webshop-customer-groups/update/:id',
        name: 'customer_groups_update',
        component: CreateCustomerGroup,
        meta: {
            interface: 'sitecart'
        }
    },

    // Export
    {
        path: '/export/:type?/:id?',
        name: 'export',
        component: Export,
        meta: {
            interface: 'sitecart'
        }
    },
    {
        path: '/sitecart-navigation',
        name: 'sitecart-navigation',
        component: Navigation,
        meta: {
            interface: 'sitecart'
        }
    }
]
