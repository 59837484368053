<script>
import { unauthorize } from '@/utilities/auth'

export default {
  name: "Logout",
  created() {
    unauthorize()
    this.$router.go({ path: '/login'} )
  },
}
</script>