<template>
  <div class="container">
    <page-title :page-title="translate('Attribute set', 'attributes')" buttons="/product-attributes-sets">
      <template #actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
          <span v-else>{{ translate('Create', 'global') }}</span>
        </button>
      </template>
    </page-title>

    <div class="container bg-white py-3" v-if="!dataLoading">
      <div class="col-md-12">
        <ee-form-group
          :label="translate('Name', 'attributes')"
        >
<!--          <input-field v-model="data.name" :field-props="{translatable: 1}" @input="v => generateSlug(v)"/>-->
          <input-field v-model="data.name" :field-props="{translatable: 1}"/>
        </ee-form-group>
      </div>
<!--      <div class="col-md-12">-->
<!--        <ee-form-group-->
<!--          :label="translate('Slug', 'attributes')"-->
<!--        >-->
<!--          <input-field v-model="data.slug" />-->
<!--        </ee-form-group>-->
<!--      </div>-->

      <navigation-links
        :available-attributes="availableAttributes"
        v-model="setsNormalized"/>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <router-link class="btn btn-white rounded-pill" to="/product-attributes-sets">
        {{ translate('Go back', 'global') }}
      </router-link>
      <button class="btn btn-primary rounded-pill" @click="save">
        <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
        <span v-else>{{ translate('Create', 'global') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { slug } from '@/utilities/helper'
import { EeFormGroup } from "@/modules/erp_entities/components/forms"
import NavigationLinks from "./NavigationLinks.vue"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"

export default {
  name: "AttributesCrud",
  display: "Two Lists",
  components: {
    InputField,
    NavigationLinks,
    EeFormGroup,
  },
  data() {
    return {
      data: {
        name: '',
        slug: '',
        sets: {},
      },
      dataLoading: true,
      defaultAttributes: [],
      userAttributes: [],

      availableAttributes: [],
      setsNormalized: [],
    }
  },
  computed: {
    defaultGroups() {
      return [...new Set(this.defaultAttributes.map(item => item.group_by === null ? '__default__' : item.group_by))]
    },
    usedAttributeIds() {
      return Object.values(this.data.sets).reduce((acc, v) => {
        let attributeIds = v.fields.reduce((acc, v) => {
          acc.push(v.id)
          return acc
        }, [])
        acc = [...acc, ...attributeIds]
        return acc
      }, [])
    },
  },
  async created() {
    this.$store.state.system.isLoading = true

    // todo if the field is requires_attribute, it cannot be deleted

    this.defaultAttributes = (await this.erp.ext.request.axiosInstance(`/modules/columns/Inventory`)).data // system attributes
    this.userAttributes = (await this.erp.ext.request.axiosInstance(`/modules/attributes`)).data.data // user attributes

    // On edit get data
    if (this.$route.params.id) {
      let data = await this.erp.ext.request.axiosInstance(`/modules/attribute-sets/${this.$route.params.id}`)
      this.data = {
        name: data.data.data.name,
        slug: data.data.data.slug,
        sets: data.data.data.sets,
      }

    // On create pre-fill from Product Entity
    } else {

      // Get required attributes from defaultAttributes
      let requiredAttributes = this.defaultAttributes.filter(attr => attr.requires_attribute == '1')

      // Add a required property

      // Object.entries(sets).forEach(([key, value]) => {
      //   const group = {
      //     label: value.label,
      //     name: key,
      //     nav: value.fields,
      //   }
      //
      //   result.push(group)
      // })

      this.data.sets = {}

      Object.values(this.defaultAttributes).forEach(attr => {
        let group = attr.group_by ?? '__default__'

        if (typeof this.data.sets[group] === 'undefined') {
          this.data.sets[group] = {
            name: group,
            label: { nl: group },
            fields: [],
          }
        }

        this.data.sets[group].fields.push({
          id: attr.id,
          name: attr.name,
          label: attr.label,
          requires_attribute: attr.requires_attribute == '1',
          //required: v.required, // todo for default attributes make it always required
          required: attr.rules && attr.rules.some(rule => rule.rule === 'required') ? 1 : 0, // todo for default attributes make it always required
        })
      })
    }

    this.setsNormalized = this.normalizeSets(this.data.sets)

    // All defaultAttributes + userAttributes not used in this.data.sets
    this.availableAttributes = [...this.userAttributes, ...this.defaultAttributes].filter(attribute => {
      return !this.usedAttributeIds.includes(attribute.id)
    }).map(attribute => {
      return this.normalizeAttribute(attribute)
    })

    this.dataLoading = false
    this.$store.state.system.isLoading = false
  },
  methods: {
    normalizeAttribute(attribute) {
      let label = attribute.label
      if(!label) {
        const foundAttribute = [...this.userAttributes, ...this.defaultAttributes].find(item => item.id === attribute.id)
        label = foundAttribute ? foundAttribute.label : ''
      }

      return {
        id: attribute.id,
        name: attribute.name,
        required: attribute.required,
        label: label,
      }
    },
    normalizeSets(sets) {
      const result = []

      Object.entries(sets).forEach(([key, value]) => {
        const group = {
          type: 'group',
          is_default: this.defaultGroups.includes(key),
          name: key,
          label: value.label,
          fields: [],
        }

        group.fields = value.fields.map(attribute => {
          return this.normalizeAttribute(attribute)
        })

        result.push(group)
      })

      return result
    },
    generateSlug(v) {
      this.data.slug = slug(v)
    },
    // async addGroup() {
    //   const confirmResult = await this.$alert.confirmWithInput('Please enter group name to continue')
    //   if(!confirmResult.isConfirmed) return
    //
    //   if(this.data.sets[slug(confirmResult.value)] !== undefined){
    //     return this.$toast.error('Group with the same name already exists.')
    //   }
    //
    //   if(confirmResult.value !== ''){
    //     this.$set(this.data.sets, slug(confirmResult.value), [])
    //   }
    // },
    async save() {
      let data = { ...this.data }
      const method = this.$route.params.id ? 'put' : 'post'

      /* data before the changes */
      /*
      company_id: 101
      created_at: "2023-12-20T09:11:37.000000Z"
      id: 45
      name: {__ob__: Observer}
      sets: {…}
      slug: "test1"
      updated_at: "2024-02-28T07:25:52.000000Z"
      */

      // format sets
      data.sets = {}

      this.setsNormalized.forEach(group => {
        const fields = group.fields.reduce((acc, v) => {
          // get rid of unneeded props (label)
          acc.push({
            id: v.id,
            name: v.name,
            required: v.required,
          })
          return acc
        }, [])

        data.sets[group.name] = {
          label: group.label,
          fields: fields,
        }
      })

      try {
        this.$store.state.system.isLoading = true
        await this.erp.ext.request.axiosInstance[method](`/modules/attribute-sets${this.$route.params.id ? '/' + this.$route.params.id : ''}`, data)
        this.$toast.requestSuccess(method, 'Attribute')
        await this.$router.push('/product-attributes-sets')
        this.$store.state.system.isLoading = false
      } catch (errors) {
        this.$toast.error(errors.response.data.message)
      }
    },
  },
}
</script>
