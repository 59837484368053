<template>
    <div class="editor">
        <div class="editor-header w-100 py-2 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <i class="fas fa-paint-brush pr-3"></i>
                <h4>{{ translate('Copy of Conform', 'global') }}</h4>
                <span class="px-2"> > </span>
                <h5 v-if="selected_page">{{ selected_page.name }}</h5>
            </div>
            <div>
                <button class="btn btn-primary rounded-pill" @click="savePage">{{ translate('Save changes', 'global') }}</button>
            </div>
        </div>
        <div class="editor-main-content row mt-2">
            <div class="col-md-2">
                <div class="editor-sidebar">
                    <h5>{{ translate('Pages', 'global') }}</h5>
                    <ul class="reset-list" v-if="pages">
                        <li v-for="(page, pi) in pages.objectData" :key="pi" :class="{'active': selected_page && selected_page.id == page.id}">
                            <button @click="selected_page = page" class="btn"><i class="fas fa-file-alt"></i>
                                {{ page.name }}
                            </button>
                        </li>
                    </ul>
                    <span class="dfc" v-else><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                </div>
            </div>
            <div class="col-md-10">
                <div class="editor-code" v-if="selected_page">
                    <codemirror v-model="selected_page.content_html" :options="cmOptions"></codemirror>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {db, modules} from "@/utilities/database";
    import {createEntityFormObjectNoTabs, createEntityTypesObjectNoTabs,} from "@/utilities/helper";

    import { codemirror } from 'vue-codemirror'
    import 'codemirror/mode/css/css.js'
    import 'codemirror/mode/xml/xml.js'
    import 'codemirror/theme/oceanic-next.css'
    import 'codemirror/addon/selection/active-line.js'
    import 'codemirror/addon/edit/closetag.js'
    import 'codemirror/addon/search/search.js'
    import 'codemirror/addon/dialog/dialog.css'
    import 'codemirror/addon/dialog/dialog.js'
    import 'codemirror/addon/search/searchcursor.js'
    import 'codemirror/addon/search/jump-to-line.js'

    export default {
        name: "CustomEditor",
        components: {
            codemirror
        },
        data() {
            return {
                pages: null,
                selected_page: null
            }
        },
        methods: {
            savePage() {
                let data = {
                    options: {...this.selected_page},
                }

                delete data.options.id

                db.put(`/modules/cms-pages/${this.selected_page.id}`, data)
                    .then(res => {
                      this.$toast.success('put', 'Page')
                    })
            }
        },
        async mounted() {
            this.pages = await this.erp.ext.request.get('/modules/cms-pages',
                this.erp.ext.query()
                    .where('website_id', '=', this.$store.state.system.scope.value)
                    .set('column', 'created_at')
                    .set('direction', 'desc')
                    .toString())
            this.selected_page = this.pages.objectData[0]
        },
        computed: {
            cmOptions() {
                return {
                    tabSize: 4,
                    styleActiveLine: true,
                    lineNumbers: true,
                    autoCloseTags: true,
                    line: true,
                    mode: 'text/html',
                    theme: 'oceanic-next',
                    search: true,
                    lineWrapping: true
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .editor {
        color: #AEB5B5;

        .editor-header {
            h4 {
                color: #AEB5B5;
            }
        }

        .editor-main-content {
            .editor-sidebar {
                height: calc(100vh - 150px);
                overflow-y: auto;

                li {
                    .btn {
                        color: $primary-panel-text-color;
                    }

                    &.active {
                        .btn {
                            color: $primary;
                        }
                    }
                }
            }

            .editor-code {
                ::v-deep .CodeMirror {
                    height: 75vh;
                }

                .editor-footer {
                    ::v-deep .v-select {
                        min-width: 20vw;

                        .vs__dropdown-toggle {
                            height: 3rem;
                        }
                    }

                    .date-info {
                        font-size: 12px;
                        color: #ccc;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
</style>
