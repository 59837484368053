<template>
  <div class="row">
    <div class="col-md-12">
      <craft-id-or-class-input
        :label="'Custom ID'"
        :value="getId()"
        @input="setId($event)"
      />
    </div>

    <div class="col-md-12">
      <craft-id-or-class-input
        :label="'Custom CSS classes'"
        :value="getClass('custom')"
        @input="setClass('custom', $event)"
      />
    </div>

    <div class="col-md-12">
      <craft-select
        label="Animation on scroll"
        :value="getAttribute('data-aos')"
        :options="animationOptions"
        :clearable="true"
        @input="setAttribute('data-aos', $event)"
      />
    </div>
  </div>
</template>

<script>
import CraftIdSettingsMixin from "@/modules/pagebuilder/mixins/CraftIdSettingsMixin"
import CraftClassSettingsMixin from "@/modules/pagebuilder/mixins/CraftClassSettingsMixin"
import CraftAttributesMixin from "@/modules/pagebuilder/mixins/CraftAttributesMixin"
import { CraftIdOrClassInput, CraftSelect } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: "Advanced",
  components: {
    CraftIdOrClassInput, CraftSelect,
  },
  mixins: [CraftIdSettingsMixin, CraftClassSettingsMixin, CraftAttributesMixin],
  inject: ['editor'],
  computed: {
    animationOptions() {
      return [
        { label: 'Fade up', key: 'fade-up' },
        { label: 'Fade down', key: 'fade-down' },
        { label: 'Fade right', key: 'fade-right' },
        { label: 'Fade left', key: 'fade-left' },
        { label: 'Fade up right', key: 'fade-up-right' },
        { label: 'Fade up left', key: 'fade-up-left' },
        { label: 'Fade down right', key: 'fade-down-right' },
        { label: 'Fade down left', key: 'fade-down-left' },
        { label: 'Flip left', key: 'flip-left' },
        { label: 'Flip right', key: 'flip-right' },
        { label: 'Flip up', key: 'flip-up' },
        { label: 'Flip down', key: 'flip-down' },
        { label: 'Zoom in', key: 'zoom-in' },
        { label: 'Zoom in up', key: 'zoom-in-up' },
        { label: 'Zoom in down', key: 'zoom-in-down' },
        { label: 'Zoom in left', key: 'zoom-in-left' },
        { label: 'Zoom in right', key: 'zoom-in-right' },
        { label: 'Zoom out', key: 'zoom-out' },
        { label: 'Zoom out up', key: 'zoom-out-up' },
        { label: 'Zoom out down', key: 'zoom-out-down' },
        { label: 'Zoom out right', key: 'zoom-out-right' },
        { label: 'Zoom out left', key: 'zoom-out-left' },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
