<template>
  <div>
    <span>
      <div class="text-right field-actions-container" v-if="hasActionShortcodes || hasActionLocales">
        <shortcodes-dropdown v-if="hasActionShortcodes" :entity="entity" @shortcodeAppend="shortcode => shortcodeAppend(shortcode)"/>
        <locales-dropdown v-if="hasActionLocales"/>
      </div>

      <template v-if="fieldProps && fieldProps.translatable == 1">
        <text-editor-inline
          :ref="fieldRef"
          v-model="translationModel"
          :class="fieldClasses"
          :placeholder="placeholderComputed"
          :disabled="isDisabled"
        />
      </template>
      <template v-else>
        <text-editor-inline
          :ref="fieldRef"
          v-model="model"
          :class="fieldClasses"
          :placeholder="placeholderComputed"
          :disabled="isDisabled"
        />
      </template>
    </span>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormInputMixin from "@/modules/erp_entities/mixins/FormInputMixin"
import LocalesDropdown from "./components/LocalesDropdown.vue"
import ShortcodesDropdown from "./components/ShortcodesDropdown.vue"
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
  name: 'TextareaField',
  mixins: [FormFieldMixin, FormInputMixin],
  components: {
    TextEditorInline,
    LocalesDropdown,
    ShortcodesDropdown,
  },
  props: {
    entity: {
      required: false,
    },
    fieldRef: {
      required: false,
      default: 'input',
    },
  },
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
}
</script>
