<template>
  <div class="cms-block cms-block-custom-code" v-html="content"></div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"

export default {
  name: 'CustomCodeElement',
  mixins: [CraftSettingsMixin],
  props: {
    content: String,
  },
  inject: ['node'],
  craft: {
    defaultProps: {
      content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
    },
  },
}
</script>