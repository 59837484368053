<template>
    <div>
        <div class="contact-modal">
            <div class="modal-default">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                        <input type="text" class="form-control" placeholder="Search for contacts....">
                        <i class="fas fa-times pr-2 close-modal" @click="$modal.hide('contact_modal')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <h5 class="my-3">user.name</h5>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <h5 class="my-3">user.name</h5>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                            <div class="col-md-3 pb-3">
                                <div class="d-flex flex-column align-items-center add-contact">
                                    <img>
                                    <label class="form-control-label my-3">user.name</label>
                                    <button class="btn btn-add-contact" @click="contactAdded">{{ translate('Add', 'contact_modal') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactModal",
        methods: {
            contactAdded() {
                alert('Added')
            }
        }
    }
</script>

<style lang="scss">
    .contact-modal {
        .modal-content .modal-header {
            padding: 0.5rem;
            border-bottom: 1px solid #ccc !important;

            input {
                border: none;
                box-shadow: none;
            }

            .close-modal {
                cursor: pointer;
                color: #A3ADBD;
            }
        }

        .modal-body {
            .add-contact {
                border: 1px solid #ccc;
                padding: 20px;
                border-radius: 5px;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50px;
                }

                .btn-add-contact {
                    background: #F0F2F7 !important;
                    border: 1px solid #F0F2F7 !important;
                    color: #000 !important;
                    width: 70px;

                    &:hover {
                        background-color: #fff !important;
                        color: #000 !important;
                        border: 1px solid #F0F2F7 !important;
                    }
                }
            }
        }
    }
</style>