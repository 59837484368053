<template>
    <div class="mb-5">
        <div class="table-responsive">
            <h5 v-if="regionName">
                {{ regionName }}
            </h5>

            <div class="d-flex">
                <a href="javascript:;" @click="removeSelected">{{ translate('Remove selected', 'shipping') }}</a>
            </div>

            <table class="table align-items-center m-0" v-if="value && value.rates">
                <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" @input="e => selectAll(e.target.checked)"
                                   class="custom-control-input"
                                   :id="trkey+'select_all'">
                            <label class="custom-control-label" :for="trkey+'select_all'"></label>
                        </div>
                    </th>
                    <!-- <th scope="col">{{ translate('Name', 'shipping') }}</th>
                    <th scope="col">{{ translate('County', 'shipping') }}</th>
                    <th scope="col">{{ translate('Region', 'shipping') }}</th>
                    <th scope="col">{{ translate('City', 'shipping') }}</th>
                    <th scope="col">{{ translate('ZIP', 'shipping') }}</th> -->
                    <th scope="col">{{ translate(`Min ${getConditionValue}`, 'shipping') }}</th>
                    <th scope="col">{{ translate(`Max ${getConditionValue}`, 'shipping') }}</th>
                    <th scope="col">{{ translate('Price', 'shipping') }}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="tr-1" v-for="(rate, rateI) in value.rates" :key="trkey+'-'+rateI">
                    <td width="10px">
                        <div class="custom-control custom-checkbox">
                            <input :id="'row-'+trkey+'-'+rateI" type="checkbox" class="custom-control-input"
                                   v-model="value.rates[rateI].selected">
                            <label class="custom-control-label" :for="'row-'+trkey+'-'+rateI"></label>
                        </div>
                    </td>
                    <td>
                        <input
							v-model.number="value.rates[rateI].condition_from_value"
							class="form-control form-control-sm"
							:class="{'is-invalid': value.rates[rateI].errors && value.rates[rateI].errors.condition_from_value }"
							type="number"/>
                    </td>
                    <td>
                        <input
							v-model.number="value.rates[rateI].condition_to_value"
							class="form-control form-control-sm"
							:class="{'is-invalid':  value.rates[rateI].errors && value.rates[rateI].errors.condition_to_value}"
							type="number"/>
                    </td>
                    <td>
                        <input
							v-model="value.rates[rateI].price"
							class="form-control form-control-sm"
							:class="{'is-invalid':  value.rates[rateI].errors && value.rates[rateI].errors.price}"
							type="number"
						/>
                    </td>
                    <td class="text-right">
                        <a href="javascript:;" @click="removeDataProp(rateI)">{{ translate('Remove', 'global') }}</a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end">
                <a href="javascript:;" class="mr-2" @click="addRow">{{ translate('Add new row', 'shipping') }}</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TableRates",
    props: {
      trkey: {
        required: true
      },
      condition_name: {
        required: true
      },
      value: {
        type: Array,
        required: true
      },
      regionName: {
        required: false
      }
    },
    methods: {
        addRow() {
          if (!this.value.rates) {
            this.$set(this.value, 'rates', [])
          }

          this.value.rates.push({
              condition_from_value: '',
              condition_to_value: '',
              price: '',
              id: ''
          })
          this.$forceUpdate()
        },
        removeDataProp(pdi) {
            this.value.rates.splice(pdi, 1)
            this.$forceUpdate()
        },
        selectAll(checked) {
            if (this.value.rates) {
                this.value.rates.forEach(rate => {
                   if (checked) {
                       this.$set(rate, 'selected', true)
                   } else {
                       this.$set(rate, 'selected', false)
                   }
                })
                this.$forceUpdate()
            }
        },
        removeSelected() {
            this.value.rates = this.value.rates.filter(item => !item.selected)
            this.$forceUpdate()
        }
    },
    computed: {
      getConditionValue() {
          let val
          if (this.condition_name === 'package_weight') { val = 'weight' }
          if (this.condition_name === 'package_value') { val = 'value' }
          if (this.condition_name === 'package_qty') { val = 'QTY' }
          return val;
      }
    },
}
</script>

<style scoped>

</style>
