import settingMixin from "../craft/components/settingMixin"

export default {
  mixins: [settingMixin],
  methods: {
    idExist() {
      return this.elementProps && this.elementProps.elementId && this.elementProps.elementId !== undefined
    },
    getId() {
      return this.elementProps && this?.elementProps?.elementId
        ? this.elementProps.elementId
        : ''
    },
    setId(value) {
      this.elementPropsSetter({
        ...this.elementProps,
        elementId: value,

      })
    },
  },
}
