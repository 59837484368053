<template>
    <div>
        <div class="row">
            <div class="col-md-12">
<!--                <div v-for="(option, oi) in value.limits.options" :key="oi">-->
<!--                    <input v-model="value.limit.selected_option" :id="`${oi}`" :name="`${oi}`" :value="option" type="radio">-->
<!--                    <label class="w-100" :for="`${oi}`">{{ option }}</label>-->
<!--                </div>-->
<!--                <input v-model="limit.selected_option" class="custom-input" placeholder="Custom" type="text">-->
            </div>

            <div class="col-md-12">
                <label class="input-label">{{ translate('Enable trial period', 'orders') }}</label>
                <div class="d-flex">
                    <div class="switch-field w-100 d-flex justify-content-between">
                        <div>
                            <input @click="isDisabled = false" id="yes" name="yes-no" type="radio">
                            <label class="w-100" for="yes">{{ translate('Yes', 'orders') }}</label>
                        </div>
                        <div>
                            <input checked @click="disableAndNull" id="no" name="yes-no" type="radio">
                            <label class="w-100" for="no">{{ translate('No', 'orders') }}</label>
                        </div>
                        <input v-model.number="data.additional_data.trial" :disabled="isDisabled" class="custom-input" placeholder="Days" type="text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: "TrialAndExtensions",
        data() {
            return {
                isDisabled: true
            }
        },
        props: {
            value: {
                required: true
            }
        },
        computed: {
            ...mapState('blockzCheckout', ['data'])
        },
        methods: {
            disableAndNull() {
                this.isDisabled = true
                this.data.trial = 0
            }
        },
		beforeRouteLeave: function(to, from, next) {
			if (to.name === 'limits' || to.name === 'order-extensions') {
				next()
			} else {
				window.localStorage.removeItem('session')
				this.erp.ext.request.axiosInstance.post('/modules/blockz/cart/flush')
				this.$store.state.blockzCheckout.cart = []
				next();
			}
		}
    }
</script>

<style scoped lang="scss">
.switch-field {
    border: 1px solid !important;
    border-radius: 5px !important;

    div, input {
        flex-grow: 1;
        flex-basis: 0;
    }

	input:checked + label {
		background-color: $primary;
		color: #f6f6f6;
	}

    .custom-input {
        position: relative !important;
        background-color: $primary-panel-offset-color;
        z-index: 1;
        opacity: 1;
        right: 0;
        height: 100%;
        width: 100%;
        border: none;
        border-left: 1px solid $primary;
        text-align: center;

        &:focus {
            outline: none;
        }
    }

	label {
		margin: 0;
		border-radius: unset !important;
		border-right: 1px solid;
		color: $primary;

		&:last-of-type {
			border-right: none;
		}
	}
}
</style>
