<template>
    <ul class="ml-lg-auto align-items-center d-flex m-0 p-0">


<!--        <li class="nav-item">-->
<!--             <work-tracker></work-tracker>-->
<!--        </li>-->

        <!--      Language picker-->
        <LanguagePicker
            :label="translate('Storeview', 'dashboard')"
            :customLabelClasses="['font-weight-700', 'mr-2']"
            :labelAlignment="true"
            class="mr-2 d-none d-lg-inline"
            @changeLocale="e => changeTranslationLocale(e)"
        />


        <!--Theme switcher-->
        <!-- <theme-switcher v-if="$store.state.system.theme == 'default'"></theme-switcher> -->
<!--                <theme-switcher></theme-switcher>-->

        <!--User dropdown-->
        <li class="nav-item d-none d-lg-inline" v-if="$store.state.system.interface !== 'blockz' && $store.state.system.interface !== 'rock' && $store.state.system.scope && $store.state.system.scope.obj && page">
            <router-link :to="page" class="btn btn-secondary rounded-pill mr-3" :class="{'disabled': !pageLinkEnabled}">
                <span>{{ translate((page.includes('create') ? 'Create your first page': 'Edit website'), 'global') }}</span>
            </router-link>
        </li>
        <li class="nav-item d-none d-lg-inline" v-if="$store.state.system.interface !== 'blockz' && $store.state.system.interface !== 'rock' && $store.state.system.scope && $store.state.system.scope.obj">
            <a target="_blank" :href="`//${$store.state.system.scope.obj.url}`" class="btn btn-primary rounded-pill">
                {{ translate('Go to website', 'global') }}
            </a>
        </li>
        <li class="nav-item d-none d-lg-inline" v-if="$store.state.system.interface === 'blockz'">
            <router-link to="/manager" class="btn btn-secondary rounded-pill">
                <span>{{ translate('Translation manager', 'global') }}</span>
            </router-link>
        </li>

        <!-- NOTIFICATIONS ARE DISABLED FOR NOW, THEY ARE NOT DONE YET IN THE API
		<notifications v-if="$store.state.system.interface === 'skitle'"></notifications>
		-->

        <menu-dropdown></menu-dropdown>
    </ul>

</template>

<script>
// import Notifications from "./Notifications"; NOTIFICATIONS ARE DISABLED FOR NOW, THEY ARE NOT DONE YET IN THE API
import MenuDropdown from "./MenuDropdown";
import WorkTracker from "@/modules/project_management/components/tracker/WorkTracker";
import ThemeSwitcher from "./ThemeSwitcher";
import LanguagePicker from "@/components/LanguagePicker";
import {changeTranslationLocale, hasManyTranslationLocales} from "@/utilities/helper";

export default {
  name: "UserMenu",
  components: {
    LanguagePicker,
    // Notifications, NOTIFICATIONS ARE DISABLED FOR NOW, THEY ARE NOT DONE YET IN THE API
    MenuDropdown, WorkTracker, ThemeSwitcher
  },
  data() {
    return {
      page: null,
      pageLinkEnabled: true
    }
  },
  created() {
    this.getEditLink()
  },
  methods: {
    changeTranslationLocale,
    async getEditLink() {
      this.pageLinkEnabled = false

      if (this.$store.state.system.theme === 'rock') {
        return
      }

      if (this.$store.state.system.scope) {

        let filter_by_locale = false
        if(hasManyTranslationLocales()){
          filter_by_locale = this.erp.ext.query()
            .where('locale', '=', this.$store.state.system.translation_locale)
        }
        let website_pages = await this.erp.ext.request.get('/modules/cms-pages', filter_by_locale)

        if (website_pages.objectData.length) {
          this.pageLinkEnabled = true

          // return the home page if any, otherwise the first page
          const homepage = website_pages.objectData.filter(item => {
            return item.url_key == '/'
          })

          const page_id = homepage.length ? homepage[0].id : website_pages.objectData[0].id
          return this.page = `/builder/cms-pages/${page_id}`
        }
      }
      this.pageLinkEnabled = true
      return this.page = `/builder/cms-pages/create`
    },
  },
  watch: {
    '$store.state.system.scope': {
      deep: true,
      handler() {
        this.getEditLink()
      },
    },
    '$store.state.system.translation_locale': {
      deep: true,
      handler() {
        this.getEditLink()
      },
    },
  },
}
</script>

<style scoped lang="scss">

    .go-to-website {
        color: #1f85d8;
        background-color: #fff;
        border: 1px solid #1f85d8;
        border-radius: 0.25rem;
        transition: all .3s ease;
        font-weight: bold;
        font-size: 16px;

        &:hover {
            color: #fff;
            background-color: #1f85d8;
        }
    }

    .btn-outline-danger {
        &:hover {
            background-color: #ff0553;
        }
    }

	.notifications {
		.fa-bell {
			font-size: 20px;
			color: #7e92ad;
		}
	}
</style>
