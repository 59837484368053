<template>
    <div>
      <element-settings-content-tabs>
        <element-settings-content-tab :selected="true" :name="translate('Video', 'page-builder')">
          <div class="row">
            <div class="col-12">
                <craft-input
                    label="Add Youtube or Vimeo Link"
                    :value="getSetting('link')"
                    @input="embedVideo($event)"
                />
            </div>

            <div class="col-12">
              <craft-tab-pills
                  :label="'Size'"
                  :value="getSetting('width')"
                  @input="setSetting('width', $event)"
                  :options="widthOptions"
              />
            </div>

            <div class="col-12">
              <craft-tab-pills
                  :label="'Alignment'"
                  :value="getSetting('alignment')"
                  @input="setSetting('alignment', $event)"
                  :options="generalAlignmentOptions"
              />
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="label">{{ translate('Start time', 'page-builder') }}</label>
                <date-picker-field
                  :field-props="{ type: 'time' }"
                  :placeholder="'HH:MM:SS'"
                  :value="getSetting('start_time')"
                  @input="setSetting('start_time', $event)"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="label">{{ translate('End time (YouTube only)', 'page-builder') }}</label>
                <date-picker-field
                  :field-props="{ type: 'time' }"
                  :placeholder="'HH:MM:SS'"
                  :value="getSetting('end_time')"
                  @input="setSetting('end_time', $event)"
                />
              </div>
            </div>

            <div class="col-12">
              <craft-toggle
                label="Autoplay"
                id="autoplay"
                :value="getSetting('autoplay')"
                :value-on="1"
                :value-off="0"
                @input="setSetting('autoplay', $event)"
              />
            </div>

<!--            not needed as we cut of playlist from urls -->
<!--            <div class="col-12">-->
<!--              <craft-toggle-->
<!--                label="Start following video (YouTube only)"-->
<!--                id="start_following_video"-->
<!--                :value="getSetting('start_following_video')"-->
<!--                :value-on="1"-->
<!--                :value-off="0"-->
<!--                @input="setSetting('start_following_video', $event)"-->
<!--              />-->
<!--            </div>-->

            <div class="col-12">
              <craft-toggle
                label="Hide controls"
                id="hide_controls"
                :value="getSetting('hide_controls')"
                :value-on="1"
                :value-off="0"
                @input="setSetting('hide_controls', $event)"
              />
            </div>

            <div class="col-12">
              <craft-toggle
                label="Loop"
                id="loop"
                :value="getSetting('loop')"
                :value-on="1"
                :value-off="0"
                @input="setSetting('loop', $event)"
              />
            </div>

            <div class="col-12">
              <craft-toggle
                label="Mute"
                id="mute"
                :value="getSetting('mute')"
                :value-on="1"
                :value-off="0"
                :disabled="getSetting('autoplay') === 1"
                @input="setSetting('mute', $event)"
              />
            </div>

            <!-- todo lazy load
            <div class="col-12">
              <craft-toggle
                label="Lazy load"
                id="lazy_load"
                :value="getSetting('lazy_load')"
                :value-on="1"
                :value-off="0"
                @input="setSetting('lazy_load', $event)"
              />
            </div>
            -->

          </div>
        </element-settings-content-tab>
      </element-settings-content-tabs>
    </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import { CraftInput, CraftTabPills, CraftToggle } from "@/modules/pagebuilder/components/craft-inputs";
import DatePickerField from "@/modules/erp_entities/components/page/form/fields/DatePickerField.vue"

export default {
  name: "EmbedVideo",
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  components:{
    CraftToggle, CraftInput, CraftTabPills, DatePickerField,
  },
  methods: {
    embedVideo(value) {
      // validate url
      if(!value.includes('youtube.com/watch?v=') &&
          !value.includes('youtube.com/embed') &&
          !value.includes('youtu.be/') &&
          !value.includes('vimeo.com/')){
        return
      }
      this.setSetting('link', value)
    },
  },
computed: {
    widthOptions() {
      return [
        {key: '100%', label: 'Full'},
        {key: '85%', label: 'Large'},
        {key: '60%', label: 'Medium'},
        {key: '40%', label: 'Small'},
        {key: '25%', label: 'Thumbnail'},
      ]
    },
  },
  watch: {
    'elementProps.settings.autoplay': {
      handler(newVal) {
        // Videos must begin in muted in order for autoplay to work
        this.setSetting('mute', newVal)
      },
    },
  },
}
</script>