<template>
    <a class="fc-time-grid-event fc-v-event fc-event fc-start fc-end">
        <div class="fc-content">
            <div class="fc-title">{{ 'tracker.started_progress_on_project'}}: {{value.data.name}}</div>
        </div>
        <div class="fc-bg"></div>
        <div class="fc-event-description">
            <p>{{value.data.description.slice(0, 100)}}</p>
        </div>
    </a>
</template>

<script>
    export default {
        name: "TrackerProjectTemplate",
        props: {
            value: {
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
