<template>
  <div>
    <div class="app-entities-settings">
      <div v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups" class="card rounded bg-white">

        <div class="card-header d-flex justify-content-between align-items-center"
             @click="visible[attrSetGroupI] = !visible[attrSetGroupI]">

          <h5>{{ translate(humanReadable(attrSetGroupI), 'settings') }}</h5>
          <button class="btn btn-white rounded-pill mr-3" v-if="showCancelButton" @click="$modal.hide('settings')">
            {{ translate('Go back', 'global') }}
          </button>

          <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible[attrSetGroupI] === true}"></i>
        </div>

        <transition-expand>
          <div v-show="visible[attrSetGroupI]" class="card-body py-0">
            <entity-settings-form
              @input="updateForm"
              :value="attrSetGroup"
              :defaultInputSize="12"
              :entity="entity"
            />
          </div>
        </transition-expand>

      </div>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button class="btn btn-primary rounded-pill" @click="save">
        {{ translate('Save changes', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import EntitySettingsForm from './EntitySettingsForm.vue'
import bus from './../../../utilities/eventBus'
import {
  createEntityFormObjectNoTabs,
  createEntityTypesObjectNoTabs,
  humanReadable,
} from '@/utilities/helper'
import { checkIfFormIsValidNoTabs, groupForm } from '../../../utilities/helper'
import { createSettingsForm } from "@/utilities/helpers/websiteSettings"
import axios from "axios"

export default {
  name: 'EntitySettings',
  components: {
    EntitySettingsForm,
  },
  async created() {
    await this.showForm()
  },
  data() {
    return {
      form: [],
      attributeSetGroups: [],
      visible: {},
      groupName: '',
      subGroupName: '',
      settingsSlug: '',
    }
  },
  props: {
    value: {
      required: true,
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    // used to pass website id, setting and group directly but not from the url
    routeProps: {
      default: '',
    },
    entity: { // required for fields with shortcodes
      required: false,
    },
  },
  methods: {
    humanReadable,
    updateForm(form) {
      this.form = form
    },
    async openCategory() {
      this.$store.state.system.isLoading = true
      let model = this.value.entity
      let routeValue = this.routeProps === '' ? this.$route : this.routeProps
      let route = `/modules/settings/${model}/${this.$store.state.system.scope.value}/${routeValue.params.setting}`
      if (routeValue.params.group){
        route += `/${routeValue.params.group}`
      }
      let res = await this.erp.ext.request.axiosInstance.get(route)
      this.form = createSettingsForm(res)
      this.attributeSetGroups = groupForm(this.form)

      this.setInitialVisibility();
      this.$store.state.system.isLoading = false
    },
    async showForm() {
      await this.openCategory()

      let routeValue = this.routeProps === '' ? this.$route : this.routeProps
      this.groupObjects = this.value.menu.filter(item => item.slug == routeValue.params.setting);
      if (this.groupObjects.length > 0) {
        this.groupName = this.groupObjects[0].name
      }
      this.subGroupName = routeValue.params.group
      this.settingsSlug = routeValue.params.setting
    },
    save() {
      let routeValue = this.routeProps === '' ? this.$route : this.routeProps
      let options = createEntityFormObjectNoTabs(this.form, 'name', 'value', false)
      let colors = {
        primary_color: options.primary_color,
        secondary_color: options.secondary_color,
        third_color: options.third_color,
      }

      let colors_group = this.value.menu.filter(group => group.name === 'Colors')[0]
      if (colors_group && parseInt(routeValue.params.setting) === colors_group.id) window.localStorage.setItem('colors', JSON.stringify(colors))

      bus.$emit('validate')
      let formIsValid = checkIfFormIsValidNoTabs(this.form)

      if (!formIsValid) {
        this.$toast.error('Fix errors in form')
        return
      }

      this.form.forEach(item => {
        if (item.type === 'group_checkbox') {
          item.value = JSON.stringify(item.value)
        }
      })

      this.$store.state.system.isLoading = true

      let data = {
        options: createEntityFormObjectNoTabs(this.form, 'name', 'value', false),
        model: this.value.entity,
        modelId: this.value.id,
        settingsSlug: this.settingsSlug,
        name: '',
      }

      this.erp.ext.request.axiosInstance.post(`/modules/settings/${this.value.entity}/${this.value.id}`, data).then(response => {
        this.$toast.requestSuccess(false, `${humanReadable(this.value.entity)} settings`)
        this.$modal.hide('settings')
        this.$store.state.system.isLoading = false
        this.erp.ext.requestFront.axiosInstance.get(`https://${this.$store.state.system.scope.obj.url}`) // trigger rebuilding app styles in the background
      }).catch(error => {
        this.$toast.error(error.message)
        this.$store.state.system.isLoading = false
      })
    },
    // Set initial visibility of attribute set groups and sub groups, todo refactor, it is the same in products
    setInitialVisibility(){
      for (let groupKey in this.attributeSetGroups) {
        this.$set(this.visible, groupKey, true)
        for (let subGroupKey in this.attributeSetGroups[groupKey]) {
          this.$set(this.visible, groupKey+'_'+subGroupKey, false)
        }
      }
    },
  },
  watch: {
    '$route.params.group': {
      deep: true,
      handler() {
        this.showForm()
      },
    },
    '$route.params.entity': {
      deep: true,
      handler() {
        this.showForm()
      },
    },
  },
}
</script>