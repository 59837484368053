<template>
  <div id="pb-frame-wrapper" :style="stylesComputed">
    <iframe src="" frameborder="0" ref="editorFrame" id="pb-frame" @load="onIframeLoad" />
    <teleport v-if="iframeLoaded" :to="editorFrame.contentDocument.body">
      <slot />
    </teleport>
  </div>
</template>

<script>
import Teleport from 'teleport-vue2'

export default {
  name: "IframeWrapper",
  components: {
    Teleport,
  },
  props: {
    iframeStyle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editorFrame: null,
      iframeLoaded: false,
      initialHeight: 'calc(100vh - 50px - 15px - 15px)', // Height fallback, 50px header height, 15px margin from header and same 15px at the bottom
      initialWidth: '100%', // Width fallback
    }
  },
  computed: {
    stylesComputed() {
      return {
        ...this.iframeStyle,
        height: (this.$store.state.layout.responsiveMode && this.iframeStyle?.height) || this.initialHeight,
        width: (this.$store.state.layout.responsiveMode && this.iframeStyle?.width) || this.initialWidth,
      }
    },
  },
  methods: {
    onIframeLoad() {
      this.editorFrame = this.$refs.editorFrame
      this.applyStylesFromParentDoc()
      this.registerSwiper()
      this.iframeLoaded = true
    },
    applyStylesFromParentDoc() {
      // todo apply only the needed styles but not all

      if (this.editorFrame) {
        // Clone and append all style elements from parent head to iframe head
        document.head.querySelectorAll('style').forEach(style => {
          const frameStyles = style.cloneNode(true)
          this.editorFrame.contentDocument.head.appendChild(frameStyles)
        })

        // Clone and append all meta elements from parent head to iframe head
        document.head.querySelectorAll('meta').forEach(meta => {
          const frameMeta = meta.cloneNode(true)
          this.editorFrame.contentDocument.head.appendChild(frameMeta)
        })

        // Clone and append all link elements with rel="stylesheet" from parent head to iframe head
        document.head
          .querySelectorAll('link[rel="stylesheet"]')
          .forEach(stylesheet => {
            const frameStylesheet = stylesheet.cloneNode(true)
            this.editorFrame.contentDocument.head.appendChild(frameStylesheet)
          })
      }
    },

    registerSwiper() {
      // Add Swiper from CDN, because it doesn't work as module
      const swiperScript = document.createElement('script')
      swiperScript.src = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js"
      swiperScript.async = true

      this.editorFrame.contentDocument.head.appendChild(swiperScript)
    },
  },
}
</script>
