<template>
  <div class="card-body" v-if="accordionKey === 'accordion_default'">
    <slot></slot>
  </div>
  <div v-else class="card rounded bg-white">
    <div class="card-header d-flex justify-content-between align-items-center accordion-toggle"
         :class="{'border-bottom-0': visible}"
         @click.stop="toggleVisibility">
      <div class="d-flex align-items-center accordion-edit">
        <div v-if="!currentlyEdited" class="d-flex align-items-center accordion-title">
          <i @click="focusEditInput" class="fas fa-pen mr-2"></i>
          <h5 @click="focusEditInput">{{ translate(value, 'entities') }}</h5>
        </div>
        <div v-else>
          <input type="text"
                 :value="value"
                 class="accordion-edit-input"
                 ref="accordionInput"
                 @focusout="exitEditMode"
                 @change="e => setValue(e.target.value)"/>
          <i @click="$emit('removeAccordion')" class="fas fa-trash-alt"></i>
        </div>
        <i class="fa fa-chevron-down chevron accordion-toggle" :class="{'chevron-rotate': visible}"></i>
      </div>
    </div>
    <transition-expand>
      <div class="card-body" v-show="visible">
        <slot></slot>
      </div>
    </transition-expand>
  </div>
</template>

<script>
export default {
  name: "EditableAccordion",
  props: {
    value: { // accordion label
      required: true,
    },
    tabKey: {
      required: true,
    },
    accordionKey: {
      required: true,
    },
    allAccordionLabels: { required: true },
  },
  data() {
    return {
      currentlyEdited: false,
      visible: false,
    }
  },
  methods: {
    setValue(value) {
      if (!value) {
        return this.$toast.error('Accordion name cannot be empty.')
      }
      else if (this.allAccordionLabels.includes(value)) {
        return this.$toast.error('Accordion with the same name already exists.')
      }

      this.currentlyEdited = false
      this.$emit('input', value)
    },

    toggleVisibility(e) {
      if (!e.srcElement.className.includes('accordion-toggle')) return
      if (this.currentlyEdited) {
        this.currentlyEdited = false
      } else {
        this.visible = !this.visible
      }
    },
    focusEditInput() {
      this.currentlyEdited = true
      this.$nextTick(() => this.$refs['accordionInput'].focus())
    },
    exitEditMode() {
      setTimeout(() => this.currentlyEdited = false, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion-edit {

  i {
    cursor: pointer;
  }

  .accordion-title {

    i.fas.fa-pen {
      opacity: 0;
    }

    &:hover {
      .fas.fa-pen {
        transition: 250ms;
        opacity: 100%;
      }
    }
  }

  .accordion-edit-input {
    outline: none;
    border: none;
    cursor: text;
  }
}
</style>