import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from "./middleware"

// Modules routes
import blockzRoutes from '@/modules/blockz/routes'
import cmsRoutes from '@/modules/cms/routes'
import entitiesRoutes from '@/modules/erp_entities/routes'
import inventoryRoutes from '@/modules/inventory/routes'
import onboardingRoutes from '@/modules/onboarding/routes'
import pagebuilderRoutes from '@/modules/pagebuilder/routes'
import projectManagementRoutes from '@/modules/project_management/routes'
import settingsRoutes from '@/modules/settings/routes'
import sitecartRoutes from '@/modules/sitecart/routes'
import systemRoutes from '@/modules/system/routes'
import rockRoutes from '@/modules/rock/routes'
import userManagementRoutes from '@/modules/users_management/routes'
import Dashboard from "@/modules/system/views/dashboards/Dashboard.vue"

Vue.use(VueRouter)


const dashboardRoute = {
  path: '/',
  name: 'Dashboard',
  component: Dashboard,
}

//Setup routers
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  //All routes and child routes are stored in routes folder
  routes: [
    dashboardRoute,
    ...blockzRoutes,
    ...cmsRoutes,
    ...entitiesRoutes,
    ...inventoryRoutes,
    ...onboardingRoutes,
    ...pagebuilderRoutes,
    ...projectManagementRoutes,
    ...settingsRoutes,
    ...sitecartRoutes,
    ...systemRoutes,
    ...rockRoutes,
    ...userManagementRoutes,
  ],
})

// Apply middlewares to router
middleware(router)

export default router
