<template>
  <li class="nav-item dropdown dropdown-animate" data-toggle="hover">
    <a class="nav-link dropdown-toggle system-text-primary" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
       aria-expanded="false">
      <i class="fas fa-file-export mr-2"></i> {{ translate('Export', 'global') }}
    </a>
    <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right p-lg-0">
      <div class="p-lg-4">
        <div class="pb-1 pt-1 delimiter-bottom" v-for="(type, i) in exportFileTypes" :key="i">
          <a href="javascript:;" @click="exportAsFile(type.key)" class="d-flex align-items-center dropdown-item">{{ type.value }}</a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { exportTypes } from "@/modules/erp_entities/utilities/helper"
import moment from "moment/moment"
import exportFromJSON from "export-from-json"

export default {
  name: "ExportTable",
  props: {
    actions: {
      required: true,
    },
    entityType: {
      required: true,
    },
  },
  computed: {
    exportFileTypes(){
      return exportTypes
    },
  },
  methods: {
    async exportAsFile(type){
      let records = await this.actions.exportData()

      this.modifyToHumanReadable(records.data)

      const columnsAvailable = records.columns
        .filter(col => parseInt(col.visible_in_table) === 1 && parseInt(col.visible_in_table_default) === 1)
        .map(col => ({ name: col.name, label: this.entityLabel(col.label) }))
        .sort((a, b) => {
          // hotfix for products because arrangement of the columns is hardcoded
          if (this.entityType === 'inventory') {
            const order = ["name", "sku", "price", "websites", "categories", "stock_status"]
            return order.indexOf(a.name) - order.indexOf(b.name)
          }
        })

      columnsAvailable.push({ name: 'created_at', label: 'Created at' })

      const data = records.data.map(item => {
        const result = {}
        for (const column of columnsAvailable) {
          if (column.name in item) {
            result[column.label] = this.entityLabel(item[column.name])
            if (['formatted_price_amounts', 'price'].includes(column.name)) {
              const key = item[column.name].total_amount ? 'total_amount' : 'priceFormatted'
              const parser = new DOMParser()
              // parsing the currency symbol on export
              result[column.label] = parser.parseFromString(item[column.name][key], 'text/html').documentElement.textContent.trim()
            }
          }
        }
        return result
      })

      // replaces all delimiters with hyphens
      const fileNameFormatter = inputString => inputString.replace(/[^\w\s]/g, "-")
      const fileName = `${this.entityType}-${moment().format('MMMM-Do-YYYY')}`
      const exportType = type
      exportFromJSON({ data, fileName, exportType, fileNameFormatter })
    },
    modifyToHumanReadable(records) {
      records.forEach(record => {
        Object.entries(record).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            record[key] = value.map(this.entityLabel).join(', ')
          } else if (key === 'created_at') {
            record[key] = moment(value).format('DD MMMM YYYY hh:mm')
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>