import './prototypes/array'
import './prototypes/object'
import './prototypes/string'

import moment from 'moment'

// services
import httpQueryBuilder from './services/http_query_builder'
import requestService from './services/request_service'
import requestFrontService from './services/request_front_service'

// Vue utilities
import directives from './utilities/vue/directives'
import { default as erp_model } from './services/ErpModel'
import Authentication from './services/auth/Authentication'
import currencies from "./misc/currencies"

const timeAgo = v => {
  return moment(v).fromNow()
}

const formatDate = (v, parseFormat = null, format = 'DD MMMM YYYY') => {
  return moment(v, parseFormat).format(format)
}

const currency = v => {
  return currencies[0][v].symbol_native
}

/*
 * Converts an html characterSet into its original character.
 */
const htmlentitiesDecode = input => {
  if(!input) return ''
  var txt = document.createElement("textarea")
  txt.innerHTML = input
  return txt.value
}

/*
 * Strip HTML tags
 */
const stripTags = (input, allowed) => {
  if(!input) return ''

  allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('') // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi

  return input.replace(tags, function ($0, $1) {
    return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
  })
}

// Export ERP framework functionalities
export const erp = {

  ext: {
    query: () => new httpQueryBuilder(),
    request: requestService,
    requestFront: requestFrontService,
  },

  auth: new Authentication(),

  // When every package is included run initialize method. should be at very bottom
  initialize({ vue }) {

    vue.prototype.erp = this

    // Vue utilities
    Object.keys(directives).forEach(index => vue.directive(index, directives[index]))

    // Filters Backward compatibility
    vue.filter('timeAgo', timeAgo)
    vue.filter('formatDate', formatDate)
    vue.filter('currency', currency)
    vue.filter('htmlentitiesDecode', htmlentitiesDecode)
    vue.filter('stripTags', stripTags)

    vue.prototype.timeAgo = timeAgo
    vue.prototype.formatDate = formatDate
    vue.prototype.currency = currency
    vue.prototype.htmlentitiesDecode = htmlentitiesDecode
    vue.prototype.stripTags = stripTags

  },
}


export const ErpModel = erp_model
