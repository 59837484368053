export default function (node) {
  const socialMediaIconsId = 'social-media-icons-' + node.uuid

  let styles = ''

  // todo remove me after the css is added in frontside
  //styles += `${socialMediaIconsId} {flex-grow: 1}`
  styles += `.cms-block-icon-box a:hover {text-decoration: none!important}`
  // END todo remove me after the css is added in frontside


  // type !important in place because of frontend .card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;}
  if (node.props.settings['type'] === 'rounded') {
    styles += `#${socialMediaIconsId} li a {border-radius: 0.25rem}`
  } else if (node.props.settings['type'] === 'square') {
    styles += `#${socialMediaIconsId} li a {border-radius: 0}`
  } else if (node.props.settings['type'] === 'round') {
    styles += `#${socialMediaIconsId} li a {border-radius: 50rem}`
  }

  if (node.props.settings['type'] !== 'icon_only') {
    if (node.props.settings['border_width', 'px']) {
      styles += `#${socialMediaIconsId} a {border-width: ${node.props.settings['border_width']}}`
    }
    if (node.props.settings['border_color']) {
      styles += `#${socialMediaIconsId} a {border-color: ${node.props.settings['border_color']}}`
    }
    if (node.props.settings['background_color']) {
      styles += `#${socialMediaIconsId} a {background-color: ${node.props.settings['background_color']}}`
    }
  }

  if (node.props.settings['icon_color']) {
    styles += `#${socialMediaIconsId} li i {color: ${node.props.settings['icon_color']}}`
  }
  if (node.props.settings['icon_width']) {
    styles += `#${socialMediaIconsId} li i {font-size: ${node.props.settings['icon_width']}}`
  }
  if (node.props.settings['padding']) {
    styles += `#${socialMediaIconsId} li a {padding: ${node.props.settings['padding']}}`
  }
  if (node.props.settings['gap']) {
    styles += `#${socialMediaIconsId} {gap: ${node.props.settings['gap']}}`
  }

  return styles
}