<template>
  <div v-if="editor.enabled" class="cms-block cms-block-text-block">
    <text-editor-inline
      v-model="model"
      :editor-enabled="isSelected && showActions"
      :bordered="false"
      external-actions
      @editorMounted="editorMounted"/>
  </div>
  <div v-else v-html="model" class="cms-block cms-block-text-block"></div>
</template>

<script>
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin"
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
  name: 'TextElement',
  mixins: [CraftSettingsMixin],
  components: {
    TextEditorInline,
  },
  props: {
    content: String,
    showActions: Boolean
  },
  inject: ['editor', 'node'],
  computed: {
    model: {
      get(){
        return this.content
      },
      set(content){
        this.node.setProps({ content })
      },
    },
    isSelected() { // copied from craft/components/Node
      return this.node === this.editor.selectedNode
    },
  },
  craft: {
    defaultProps: {
      content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
      showActions: false,
    },
  },
  methods: {
    editorMounted(editor) {
      this.node.textEditor = editor
    },
  },
}
</script>

<style lang="scss">
.cf-node-selected.cms-block-text-block {
  position: relative; /* show editor actions on top of the other elements */
}
</style>