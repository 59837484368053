<template>
    <div>
        <div class="card-header">
            <h4 class="font-weight-bold">
                <span v-if="value.id">{{ translate('Edit {entity}', 'entities', { entity: translate('VAT group', 'entities') }) }}</span>
                <span v-else>{{ translate('Create {entity}', 'entities', { entity: translate('VAT group', 'entities') }) }}</span>
            </h4>
        </div>
        <ul class="list-group">
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Title', 'entities') }}</label>
                <input class="" type="text" v-model="value.name" :placeholder="translate('VAT name', 'entities')">
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between">
                <label>{{ translate('Identifier', 'entities') }}</label>
                <input class="" type="text" v-model="value.group" :placeholder="translate('VAT identifier', 'entities')">
            </li>
        </ul>
        <div class="d-flex p-3">
            <button class="btn btn-primary rounded-pill w-100" @click="$emit('save')">
                <span v-if="value.id">{{ translate('Save changes', 'global') }}</span>
                <span v-else>{{ translate('Create', 'global') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "VatTypesCreate",
    props: {
        value: {
            required: true
        }
    },
}
</script>