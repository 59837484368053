export function sidebarNavigation() {

  let win = window,
    menu = document.querySelector('.sidebar-new'),
    content = document.querySelector('.main-content'),
    winHeight,
    menuScrollMax = 0,
    contentHeight,
    winTop = 0,
    winTopLast = 0,
    scrollStep = 0,
    nextTop = 0

  if (menu) {
    window.menuHeight = menu.getBoundingClientRect().height
  } else {
    return
  }

  /**
     * Check if menu is fixed
     * @returns {Boolean}
     */
  function isMenuFixed() {
    return window.menuHeight < contentHeight && contentHeight > winHeight
  }

  menu && window.menuHeight && window.menuHeight < win.innerHeight ? menu.style.bottom = 0 : menu.style.bottom = 'unset'

  /**
     * Calculate and apply menu position
     */
  function positionMenu() {

    //  Spotting positions and heights
    winHeight = win.innerHeight
    contentHeight = content.offsetHeight
    winTop = win.scrollY
    scrollStep = winTop - winTopLast


    if (isMenuFixed()) { // fixed menu cases

      if (window.menuHeight > winHeight) { // smart scroll cases

        if (winTop > winTopLast) { //eslint-disable-line max-depth

          menuScrollMax = window.menuHeight - winHeight

          nextTop < menuScrollMax - scrollStep ?
            nextTop += scrollStep : nextTop = menuScrollMax

          menu.style.top = -nextTop + 'px'

        } else if (winTop <= winTopLast) { // scroll up

          nextTop > -scrollStep ?
            nextTop += scrollStep : nextTop = 0

          menu.style.top = -nextTop + 'px'

        }
      }

    } else { // static menu cases
      menu.style.top = 0
    }

    //  Save previous window scrollTop
    winTopLast = winTop
  }

  positionMenu() // page start calculation

  //  Change position on scroll
  win.onscroll = function () {
    positionMenu()
  }

  document.addEventListener('click', function () {
    setTimeout(function () {
      window.menuHeight = menu.getBoundingClientRect().height
    }, 500)
  })

  win.onresize = function () {
    winHeight = win.innerHeight
    window.menuHeight < win.innerHeight ? menu.style.bottom = 0 : menu.style.bottom = 'unset'

    //  Reset position if fixed and out of smart scroll
    if (window.menuHeight < contentHeight && window.menuHeight <= winHeight) {
      menu.removeAttribute('style')
      menu.style.bottom = 0
    }

  }
}
