<template>
  <component :is="component">
    <builder-styles v-if="editor.nodes" />
    <Node
      v-for="node in editor.nodes" :key="node.uuid"
      :data-uuid="node.uuid"
      :node="node"
    />
    <Indicator :indicator="editor.indicator" />
  </component>
</template>

<script>
import Node from './Node.vue';
import Indicator from './Indicator.vue';
import createNodeFromVNode from '../utils/createNodeFromVNode';
import BuilderStyles from "@/modules/pagebuilder/craft/components/BuilderStyles.vue"

export default {
  components: {
    Node, Indicator, BuilderStyles,
  },
  props: {
    component: [Object, String],
  },
  inject: [
    'editor',
  ],
  created() {
    if (!this.editor) {
      throw new Error('<Frame/> must be wrapped with <Editor/>.');
    }

    if (this.editor.nodes.length === 0) {
      const nodes = this.createNodesFromSlots();
      this.editor.setTopLevelNodes(nodes);
    }
  },
  methods: {
    createNodesFromSlots() {
      const defaultSlots = this.$slots.default || [];
      return defaultSlots
        .map((vnode) => createNodeFromVNode(this.editor, vnode))
        .filter((node) => !!node);
    },
  },
};
</script>
