<template>
  <div>
    <div class="card-header d-flex" v-if="model">
      <h5 class="font-weight-bold">
        <span>{{ translate('Bulk edit variations selected items', 'products') }}</span>
      </h5>
      <button type="button" style="margin-left: auto;" class="close" @click="$modal.hide('product_variations_bulk_actions')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <ul class="list-group" v-if="model">
      <li class="list-group-item d-flex align-items-center justify-content-between" v-for="field in model">
        <label>{{ translate(field.label, 'products') }}</label>
        <div style="width: 60%;">
          <ee-form-input v-model="field.value" :field-props="field.fieldProps"
                         :type="field.type"
                         :placeholder="field.label"
          />
        </div>
      </li>
    </ul>
    <div class="d-flex justify-content-end p-3">
      <button class="btn btn-white rounded-pill" @click="$modal.hide('product_variations_bulk_actions')">
        <span>{{ translate('Cancel', 'global') }}</span>
      </button>
      <button class="btn btn-primary rounded-pill" @click="saveBulkActions()">
        <span>{{ translate('Apply', 'global') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {slug} from '@/utilities/helper'
import {environment} from '../../../../erp_entities/utilities/helper'
import EeFormInput from "@/modules/erp_entities/components/forms/FormInput";
import busEvent from "@/utilities/eventBus";

export default {
  components: {EeFormInput},
  data() {
    return {
      loading: false,
      model: null
    }
  },
  async created() {
    this.loading = true
    this.model = {
      quantity: {
        label: 'Qty',
        value: null,
        type: 'number',
        fieldProps: {
          translatable: 0
        }
      },
      price: {
        label: 'Price',
        value: null,
        type: 'number',
        fieldProps: {
          translatable: 0
        }
      },
      sale_price: {
        label: 'Sale price',
        value: null,
        type: 'number',
        fieldProps: {
          translatable: 0
        }
      },
      weight: {
        label: 'Weight',
        value: null,
        type: 'text',
        fieldProps: {
          translatable: 0
        }
      },
    }
    this.loading = false
  },
  methods: {
    saveBulkActions(){
      busEvent.$emit('apply_product_variations_bulk_actions', this.model)
      this.$modal.hide('product_variations_bulk_actions')
    }
  }
}
</script>