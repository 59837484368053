<template>
  <div class="cms-block" :class="'cms-block-'+blockName" :data-component="blockName">
    <template v-if="editor.enabled">
      <div v-if="resData" v-html="resData.output"/>
      <vs-skeleton v-else primary-color="#ebebeb" secondary-color="#f2f2f2" class="cms-placeholder" data-placeholder="Categories">
        <vs-line height="250" class="mt-1" b-radius='0'></vs-line>
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from '../../../../mixins/DynamicComponentMixin';

export default {
  name: 'CategoriesElement',
  mixins: [DynamicComponentMixin],
  inject: ['editor'],
  craft: {
    defaultProps: {
      settings: {
        filter: [],
        layout: 'list',
        columns: {
          desktop: '4', // 3 columns
          tablet_landscape: '4', // 3 columns
          tablet_portrait: '6', // 2 columns
          mobile: '12', // 1 column
        },
        number_of_categories: 3,
      },
    },
  },
  data() {
    return {
      blockName: 'categories',
      renderUrl: 'categories',
    }
  },
}
</script>