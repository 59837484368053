<template>
  <component :is="dashboard" />
</template>

<script>
import BlockzDashboard from "@/modules/system/views/dashboards/BlockzDashboard.vue"
import RockDashboard from "@/modules/system/views/dashboards/RockDashboard.vue"
import SitecartDashboard from "@/modules/system/views/dashboards/SitecartDashboard.vue"
import SkitleDashboard from "@/modules/system/views/dashboards/SkitleDashboard.vue"
import DefaultDashboard from "@/modules/system/views/dashboards/DefaultDashboard.vue"

export default {
  name: "Dashboard",
  components: {
    BlockzDashboard, RockDashboard, SitecartDashboard, SkitleDashboard, DefaultDashboard,
  },
  computed: {
    dashboard(){
      if (['blockz', 'rock', 'skitle', 'sitecart'].includes(this.$store.state.system.interface)) {
        return this.$store.state.system.interface + 'Dashboard'
      }
      return 'DefaultDashboard'
    },
  },
}
</script>
