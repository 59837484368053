<template>
  <div class="row border-top pt-2">
    <div
      v-for="(record, recordI) in records.objectData"
      :key="recordI"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-6 d-flex flex-grow-1"
    >
      <div class="card bg-white border flex-grow-1 card-holder">
        <div class="card-image">
          <router-link :to="`/records/${record.id}`">
            <img :src="getFirstImage(record.images)" :alt="entityLabel(record.name)">
          </router-link>
        </div>
        <div class="card-body">
          <h5 class="card-title">
            {{ entityLabel(record.name) }}
          </h5>
          <div class="list-group list-group-flush">
            <div class="list-group-item px-0">
              <div class="row">
                <div class="col">
                  {{ entityLabel(record.name) }}
                </div>
                <div class="col-auto">
                  {{ $formatCurr.format( record.price, {code: record.currency}) }}
                </div>
              </div>
            </div>
            <div v-if="record.sale_price" class="list-group-item px-0">
              <div class="row">
                <div class="col">
                  {{ translate('Product sale price', 'global') }}
                </div>
                <div class="col-auto">
                  {{ $formatCurr.format( record.sale_price, {code: record.currency}) }}
                </div>
              </div>
            </div>
            <div class="list-group-item px-0">
              <div class="row">
                <div class="col">
                  {{ 'qty' | translate('records') }}
                </div>
                <div class="col-auto">
                  {{ record.qty }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridLayout",
  props: {
    records: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFirstImage(images) {
      if (images && images.length > 0) {
        return images[0].thumbnail
      }

      return '/img/fallback.png'
    },
  },
}
</script>

<style scoped lang="scss">
.card-holder {

  .card-body {
    display: flex;
    flex-direction: column;

    .list-group.list-group-flush {
      height: 100%;

      .list-group-item:last-child {
        margin-top: auto;
        padding-bottom: 0 !important;
      }
    }
  }

  .card-image {
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
      transition: all .3s ease-in-out;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}
</style>