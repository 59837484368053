<template>
    <div class="container">
        <page-title :page-title="translate('Extensions', 'entities')">
            <template v-slot:actions>
                <router-link to="/extensions-create" v-if="$store.state.system.interface === 'blockz'" class="btn btn-primary rounded-pill">{{ translate('Add new extension', 'extensions') }}</router-link>
            </template>
        </page-title>
		<div class="search-field w-100 my-3 d-flex align-items-center">
			<span><i class="fa fa-search m-3"></i></span>
			<input class="form-control w-100 pl-5"
				   @input="e => loadExtensions({key: 'name', value: `%${e.target.value}%`, condition: 'like'})"
				   :placeholder="translate('Search', 'extensions')">
		</div>

			<div class="row" v-if="extensions && !loading">
				<div class="col-md-4" v-for="(extension, e) in extensions" :key="e">
					<div class="extension-holder d-flex flex-column mt-4 bg-white zoom-in-hover">
						<div class="img-holder">
							<router-link :to="`/app-show/${extension.id}`">
								<img v-if="extension.readable_options.logo" :src="extension.readable_options.logo">
								<img v-else src="/uploads/defaults/image-placeholder.jpg">
							</router-link>
						</div>
						<div class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
							<div class="d-flex flex-column w-100">
								<div class="text-center">
									<span class="extension-title d-block">{{ extension.name }}</span>
									<span class="extension-rating font-weight-bold">
										{{ extension.readable_options.description }}
									</span>
								</div>
							</div>
						</div>
						<div class="w-100 extension-actions">
							<div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
<!--								<div v-if="extension.status === 1"-->
<!--									 class="d-flex justify-content-center align-items-center">-->
<!--									<span class="p-1"><i class="fas fa-circle d-flex align-items-center"></i></span>-->
<!--									<span>Online</span>-->
<!--								</div>-->
<!--								<div v-else class="d-flex justify-content-center align-items-center offline">-->
<!--									<span class="p-1"><i class="fas fa-circle d-flex align-items-center"></i></span>-->
<!--									<span>Offline</span>-->
<!--								</div>-->
								<span>€ {{ extension.readable_options.price }}</span>
								<button class="btn btn-edit py-0 px-4" v-if="$store.state.system.interface === 'skitle'"
										@click="$router.push(`/app-show/${extension.id}`)">
									{{ translate('View', 'extensions') }}
								</button>
                                <button class="btn btn-edit py-0 px-4" v-if="$store.state.system.interface === 'blockz'"
										@click="$router.push(`/app-show/${extension.id}`)">
									{{ translate('Edit', 'extensions') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

		<div v-if="loading" class="d-flex justify-content-center m-5">
			<span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
		</div>
    </div>
</template>

<script>
    import {db} from "@/utilities/database";
	import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";
	import debounce from "debounce";

    export default {
        name: "Extension",
		data() {
        	return {
        		extensions: null,
				loading: false
			}
		},
		methods: {
			loadExtensions: debounce(async function (filters) {
				this.loading = true;

				let params = null
				if (filters) {
					params = {
						'filter_criteria': {
							'filter_groups': {
								0: {
									'filters': {
										0: filters
									}
								}
							}
						}
					}
				}

				if (filters && filters.key === 'status') {
					this.selectedFilter = filters.value
				}

				let columns = {column: 'created_at', direction: 'desc', type: 'text'}

				let response = await db.get(`modules/blockz/app-extensions/extensions-modules?page=1&perpage=200`)
				let uniqueExt = []
				response.data.data.forEach(modules => {
					db.get(`modules/blockz/app-extensions/extensions-props/${modules.id}?page=1&perpage=200&${urlEncoder(columns)}&${urlEncoder(params)}`)
						.then(extensions => {
							extensions.data.data.forEach(ext => {
								ext.readable_options = createEntityFormObjectNoTabs(ext.options, 'key')
								if (ext.inventory_type === 'extension' && !uniqueExt.some(item => item.id === ext.id)) {
									uniqueExt.push(ext)
									if (ext.readable_options.description) {
										ext.readable_options.description = ext.readable_options.description.replace(/(<([^>]+)>)/gi, "")
									}
								}
							})
						})
				})

				this.extensions = uniqueExt
				this.loading = false;
        // this.$toast.info('Please use only 1 browser tab, when working with this system!')
			}, 700),
		},
		created() {
			this.loadExtensions()
		},
    }
</script>

<style scoped lang="scss">
    .plan-counter {
        background-color: #DEDEDE;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .extension-holder {
        border: 1px solid #DEDEDE;
        border-radius: 10px;
        margin-bottom: 1rem;

        .img-holder {
            img {
                width: 100%;
                height: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        .info-holder {
            .extension-title {
                font-size: 18px;
                font-weight: bold;
            }

            .extension-rating {
                color: #DEDEDE;
                font-size: 16px;
            }
        }

        .extension-actions {
            i.fa-circle {
                font-size: 8px;
                color: #00da7d;
            }

			.offline {
				i.fa-circle {
					color: red;
				}
			}

            .btn-edit {
                color: #000;
                background-color: #fff;
                border-color: #000;
                border-radius: 10px;
                &:hover {
                    color: $primary;
                    background-color: #fff;
                    border-color: $primary;
                }
            }
        }
    }
</style>
