<template>
  <div>
    <div class="card-header" style="display:flex">
      <h4 class="font-weight-bold">
        <span v-if="valueVat.id">{{ translate('Edit {entity}', 'entities', { entity: translate('VAT rate', 'entities') }) }}</span>
        <span v-else>{{ translate('Create {entity}', 'entities', { entity: translate('VAT rate', 'entities') }) }}</span>
      </h4>
      <button type="button" style="margin-left: auto;" class="close" @click="$modal.hide('create_vat_rate')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <ul class="list-group">
      <li class="list-group-item d-flex align-items-center justify-content-between" v-if="vatTypes">
        <label>{{ translate('VAT group', 'entities') }}</label>
        <div style="width: 60%;">
          <v-select :options="vatTypes" v-model="valueVat.vat_type_id" class="select2-form" :class="{'is-invalid': !errors.vat_type_id}"
                    label="value" :reduce="value => value.key"></v-select>
          <small class="help text-danger" v-if="!errors.vat_type_id" >{{ translate('This field is required', 'settings')}}</small>
        </div>
      </li>
      <li class="list-group-item d-flex align-items-center justify-content-between" v-if="countries">
        <label>{{ translate('Country', 'entities') }}</label>
        <div style="width: 60%;">
          <v-select :options="countries"  v-model="valueVat.country" class="select2-form" :class="{'is-invalid': !errors.country}"
                    label="value" :reduce="value => value.key"></v-select>
          <small class="help text-danger" v-if="!errors.country" >{{ translate('This field is required', 'settings')}}</small>
        </div>
      </li>
      <li class="list-group-item d-flex align-items-center justify-content-between">
        <label>{{ translate('Percentage rate', 'entities') }}</label>
        <div  style="width: 60%;">
          <input class="form-control" :class="{'is-invalid': !errors.percentage_rate}" type="number" min="0" max="100"  step="0.01" v-model="valueVat.percentage_rate" :placeholder="translate('VAT percentage', 'entities')">
          <small class="help text-danger" v-if="!errors.percentage_rate" >{{ translate("This field is required. The percentage rate have to be between 0 and 100.", 'entities') }}</small>
        </div>
      </li>
      <li class="list-group-item d-flex align-items-center justify-content-between">
        <label>{{ translate('ZIP', 'entities') }}</label>
        <input class="input" type="text" v-model="valueVat.zip" :placeholder="translate('VAT ZIP', 'entities')">
      </li>
      <li class="list-group-item d-flex align-items-center justify-content-between">
        <label>{{ translate('State', 'entities') }}</label>
        <input class="input" type="text" v-model="valueVat.state" :placeholder="translate('VAT state', 'entities')">
      </li>
    </ul>
    <div class="d-flex p-3">
      <button class="btn btn-primary rounded-pill w-100" @click="save()">
        <span>{{ translate('Save changes', 'global') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VatRatesCreate",
  props: {
    vat: {
      required: true,
    },
  },
  data() {
    return {
      valueVat:{},
      vatTypes: null,
      countries: null,
      errors:{
        vat_type_id:true,
        country:true,
        percentage_rate:true,
      },
    }
  },
  mounted(){

  },
  async created() {
    this.valueVat = Object.assign({}, this.vat)
    this.vat = {}
    let types = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/VatType?perpage=9999')
    this.vatTypes = types.data.data
    let countries = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country?perpage=9999')
    this.countries = countries.data.data
  },
  methods:{
    save(){
      this.valueVat.vat_type_id?this.errors.vat_type_id=true:this.errors.vat_type_id=false;
      this.valueVat.country?this.errors.country=true:this.errors.country=false;
      this.valueVat.percentage_rate&&this.valueVat.percentage_rate>=0&&this.valueVat.percentage_rate<=100?this.errors.percentage_rate=true:this.errors.percentage_rate=false;
      this.errors.vat_type_id&&this.errors.country&&this.errors.percentage_rate?this.$emit('save',this.valueVat):null
    },
  },

  watch: {
    'valueVat.vat_type_id': function(){
      this.valueVat.vat_type_id?this.errors.vat_type_id=true:null;
    },
    'valueVat.country': function(){
      this.valueVat.country?this.errors.country=true:this.errors.country=false;
    },
    'valueVat.percentage_rate' :function(){
      this.valueVat.percentage_rate&&this.valueVat.percentage_rate>=0&&this.valueVat.percentage_rate<=100?this.errors.percentage_rate=true:this.errors.percentage_rate=false;
    },
  },
}
</script>