<template>
    <div v-if="data">
        <div class="card border bg-white">
            <div class="card-header d-flex align-items-center justify-content-between border-bottom">
                <h6 class="mb-0">
                    {{ translate('Members', 'account') }}
                </h6>
                <button @click="$modal.show('create_user')" class="btn btn-primary rounded-pill">
                    {{ translate('Invite member', 'account') }}
                </button>
            </div>
            <div class="card-body">
                <div class="border-bottom" v-for="(user, ui) in data.data.users">
                    <div class="d-flex justify-content-between py-3">
                        <div class="d-flex align-items-center">
                            <img v-if="user.avatar" class="avatar rounded-circle" :src="user.avatar" alt="">
							<img v-else class="avatar rounded-circle" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`">
                            <div class="d-flex flex-column ml-3">
                                <span>{{user.first_name}} {{user.last_name}}</span>
                                <small class="text-muted">{{user.email}}</small>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
<!--                            <cta></cta>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="create_user"
               :pivot-x="1"
               :pivot-y="0"
               transition="slide-fade"
               overlayTransition="wait"
               height="100%"
               width="85%"
               @before-close="formData = initialFormData"
               :adaptive="true">
            <user-form :roles="data.additionalData.roles" v-if="data && data.additionalData.roles" v-model="formData" :company="$route.params.id"></user-form>
        </modal>
    </div>
</template>

<script>
    import UserForm from "@/modules/users_management/views/users/UserForm";
    export default {
        name: 'Members',
        components: {
            UserForm
        },
        data() {
            return {
                data: null,
                formData: null
            }
        },
        async created() {
            this.$store.state.system.isLoading = true
            this.data = await this.erp.ext.request.find('companies', this.$route.params.id)
            this.$store.state.system.isLoading = false
            this.formData = {...this.initialFormData}
        },
        computed: {
            initialFormData() {
                return {
                    avatar: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    phone: '',
                    birthdate: '',
                    roles: '',
                    timezone: '',
                    locale: '',
                    company_id: ''
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
