<template>
  <div>
    <div v-if="$router.currentRoute.name !== 'finish'"
         class="text-center py-5"
    >
      <h3 class="font-weight-500 text-color">
        {{ translate($router.currentRoute.meta.title, 'onboarding') }}
      </h3>
      <span class="description">{{ translate($router.currentRoute.meta.description, 'onboarding') }}</span>
    </div>

    <router-view :value="new_website" />

    <div v-if="!$router.currentRoute.meta.validation_required"
         class="container d-flex justify-content-between w-100 mt-5"
         :class="$router.currentRoute.meta.prev ? 'justify-content-between': 'justify-content-end'">

      <router-link v-if="$router.currentRoute.meta.prev"
                   :to="`${$router.currentRoute.meta.prev}`"
                   class="btn btn-primary"
      >
        {{ translate('Go back', 'global') }}
      </router-link>

      <router-link v-if="$router.currentRoute.meta.next"
                   :to="`${$router.currentRoute.meta.next}`"
                   class="btn btn-primary"
      >
        <span v-if="['website-finish', 'finish'].includes($router.currentRoute.meta.next)">
          {{ translate('Finish', 'global') }}
        </span>
        <span v-else>
          {{ translate('Next', 'global') }}
        </span>
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      websites: null,
      new_website: {
        name: '',
        url: '',
        slug: '',
        template: '',
        color_schema: '',
        font: '',
        layout: '',
        logo: '',
        locales: [],
        company_id: this.$store.state.system.company.key,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.text-color {
    color: #000;
}

.description {
    font-size: 18px;
    color: #afaeae;
}
</style>
