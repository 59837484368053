<template>
  <intro-layout :authLoading="authLoading">
    <form method="POST" @submit.prevent="reset">
      <h6 class="h3 font-weight-bold mb-5">{{ translate('Password recovery', 'system') }}</h6>
      <div class="form-group">
        <label class="form-control-label">{{ translate('Email', 'system') }}</label>
        <input type="email" tabindex="1" v-model="email" name="email" class="form-control"
               @input="errors = null"
               :class="{'is-invalid': errors && errors.hasOwnProperty('email')}"
               id="input-email" placeholder="name@example.com">
        <span class="text-danger"
              v-if="errors && errors.hasOwnProperty('email')">{{ errors['email'][0] }}</span>
      </div>
      <div class="mt-4 text-right">
        <button :disabled="authLoading" type="submit" tabindex="2" class="btn btn-primary rounded-pill login-btn">
            <span class="btn-inner--text font-weight-bold">{{ translate('Send password', 'system') }}
                <span class="btn-inner--icon"></span>
            </span>
        </button>
      </div>
    </form>
  </intro-layout>
</template>

<script>
import IntroLayout from "@/modules/system/views/IntroLayout.vue"

export default {
    name: "PasswordRecovery",
  components: {IntroLayout},
    data() {
        return {
            email: '',
            authLoading: false,
            errors: null
        }
    },
    methods: {
        async reset() {
            this.authLoading = true
            try {
                await this.erp.ext.request.axiosInstance.post('/password/reset', {email: this.email})
                this.$alert.success('Your password reset link was sent to your email.')
                await this.$router.push('/login')
            } catch (error) {
                if (typeof error.response.data.errors !== 'object') {
                    this.$alert.error(error.response.data.message)
                } else {
                    this.errors = error.response.data.errors
                }
            }
            this.authLoading = false
        },
    },
    mounted() {
        Array.from(document.querySelectorAll('.form-control')).forEach(elm => {
            elm.addEventListener('focus', function (e) {
                e.target.parentNode.parentNode.classList.add('focused')
            })
            elm.addEventListener('blur', function (e) {
                e.target.parentNode.parentNode.classList.remove('focused')
            })
        })
    }
}
</script>