<template>
  <entity-index-content
    entity-type="users"
  />
</template>

<script>
import EntityIndexContent from "@/modules/erp_entities/components/page/EntityIndexContent.vue";

export default {
  name: "Users",
  components: {
    EntityIndexContent,
  },
}
</script>