<template>
  <div>
    <loader v-if="loading" />
    <div v-else class="dfc alert alert-success">
      {{ translate('Your authorization was successful.', 'page-builder') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "OauthInstagram",
  data() {
    return {
      loading: true,
    }
  },
  async created() {
    if (!this.$route.query.code) {
      return window.close()
    }

    try {
      await this.erp.ext.request.axiosInstance.post('/modules/integrations/instagram/token', { code: this.$route.query.code })
      this.loading = false
    } catch (err) {
      this.loading = false
    }

    setTimeout(function() {
      window.close()
    }, 5000)
  },
}
</script>