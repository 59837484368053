<template>
  <div>
    <page-title :page-title="translate('Dashboard', 'dashboard')" />
  </div>
</template>

<script>
export default {
  name: "DefaultDashboard",
}
</script>
