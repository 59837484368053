<template>
  <div class="left-sidebar-content css-sidebar">
    <div class="left-sidebar-content--container css-sidebar--container nav-scrollbar">

      <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
        <div>
          <h6>{{ translate('Custom CSS for the website', 'page-builder') }}</h6>
          <p><small class="info mt-1">{{ translate('With our CSS Module you have the ability to apply custom CSS to your templates. Pay attention to first add a custom class to the respective section, row, column or component before you apply it.', 'page-builder') }}</small></p>
        </div>
        <div class="close-sidebar">
          <a @click="closeLeftSidebar">
            <i class="icon-erp-plus icon-erp-rotate-45 text-bold"></i>
          </a>
        </div>
      </div>

        <div class="css-codemirror-container">
            <codemirror
                v-if="$store.state.builder.website"
                v-model="$store.state.builder.website.objectData.css"
                class="css-codemirror"
                :options="cmOptionsCss"></codemirror>
            <button class="btn btn-primary mt-3 ml-auto float-right" @click="saveCss">{{translate('Save changes', 'global')}}</button>
        </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

// Codemirror
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/theme/oceanic-next.css'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/jump-to-line.js'
import PagesMixin from "../../../mixins/PagesMixin";

export default {
  name: "css",
  components: {codemirror,},
  data() {
    return {

    }
  },
  mixins: [PagesMixin],
  methods: {
    ...mapActions('builder', ['saveWebsite']),
    closeLeftSidebar() {
      this.$emit('closeLeftSidebar')
    },
    async saveCss(){
      this.saveWebsite().then(() => {
        this.$toast.requestSuccess(false, 'CSS')
      })
    }
  },
  computed: {
    cmOptionsCss() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/css',
        theme: 'oceanic-next',
        search: true,
        lineWrapping: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
