<template>
  <div class="col-md-12 mt-3">
    <div class="main-table border-0">
      <div class="table-responsive rounded">
        <table class="table m-0">
          <thead class="bg-white">
            <tr>
              <th class="font-weight-bold" scope="col">
                {{ translate('Item name', 'orders') }}
              </th>
              <th class="font-weight-bold" scope="col">
                {{ translate('Single price', 'orders') }}
              </th>
              <th class="font-weight-bold" scope="col">
                {{ translate('Item qty', 'orders') }}
              </th>
              <th class="font-weight-bold" scope="col">
                {{ translate('VAT rate', 'orders') }}
              </th>
              <th class="font-weight-bold" scope="col">
                {{ translate('VAT amount', 'orders') }}
              </th>
              <th class="font-weight-bold" scope="col">
                {{ translate('Total', 'orders') }}
              </th>
              <th class="font-weight-bold text-right" scope="col">
                {{ translate('Actions', 'orders') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in cartItems" :key="i">
              <td>
                <span class="font-weight-bold">{{ item.item_name }}</span>
              </td>
              <td>
                <span class="font-weight-bold" v-html="item?.formatted_price_amounts?.single_price"></span>
              </td>
              <td>
                <order-item-qty-input
                  :value="item.qty"
                  :item-id="item.item_id"
                  @reloadCart="$emit('reloadCart')"
                />
              </td>
              <td>
                <span class="font-weight-bold">
                  {{ item.tax_percentage }}
                </span>
              </td>
              <td>
                <span class="font-weight-bold" v-html="item?.formatted_price_amounts?.tax_amount"></span>
              </td>
              <td>
                <span class="font-weight-bold" v-html="item?.formatted_price_amounts?.total_amount"></span>
              </td>
              <td class="text-right">
                <cta>
                  <action-button
                    v-tooltip="translate('Delete product', 'orders')"
                    class-icon="fa-trash-alt"
                    @click.native="removeFromCart(item.item_id)"
                  />
                </cta>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItemQtyInput from "./OrderItemQtyInput.vue"

export default {
  name: "OrderItems",
  components: {
    OrderItemQtyInput,
  },
  props: {
    cartItems: {
      required: true,
    },
  },
  methods: {
    removeFromCart(itemId) {
      this.$store.state.system.isLoading = true
      this.erp.ext.request.axiosInstance.delete(`/modules/sales/cart/item/delete/${itemId}`)
        .then(res => this.$emit('reloadCart'))
        .catch(err => this.$toast.error(err))
        .finally(() => this.$store.state.system.isLoading = false)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>