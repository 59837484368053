import { HTMLElement } from 'node-html-parser'

Object.defineProperty(HTMLElement.prototype, 'children', {
  get: function () {
    return this.childNodes.filter(({ outerHTML }) => outerHTML)
  },
})

HTMLElement.prototype.toggleClass = function (targetClass) {
  let className = this.attributes.class ? this.attributes.class : 'default'
  let elHasTargetClass = className.includes(targetClass)
  elHasTargetClass ? this.removeClass(targetClass) : this.setAttribute('class', this.getAttribute('class') + ' ' + targetClass)
  return this
}

HTMLElement.prototype.removeClass = function (classNames) {
  if (typeof classNames === 'string') classNames = [classNames]

  const resultClassNames = this
    .getAttribute('class')
    .split(' ')
    .filter(Boolean)
    .filter(className => !classNames.includes(className))
    .join(' ')

  this.setAttribute('class', resultClassNames)
  return this
}

HTMLElement.prototype.addClass = function (className) {
  if (this.containsClass(className)) {
    this.removeClass(className)
  }
  this.setAttribute('class', `${this.getAttribute('class')} ${className}`)
  return this
}

HTMLElement.prototype.containsClass = function (className) {
  return !!this.getAttribute('class').split(' ').filter(Boolean).find(cl => cl === className)
}

Element.prototype.addClass = function (classNames) {
  if (typeof classNames === 'string') classNames = [classNames]
  classNames.forEach(className => this.classList.add(className))
  return this
}