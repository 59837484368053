<template>
  <!-- select || multiselect,  data_type = custom -->
  <div>
    <select-dynamic-field v-if="fieldProps.data_type === 'moduleNames'"
                          :field-props="fieldProps"
                          :placeholder="placeholder"
                          :apply-default-val="applyDefaultVal"
                          :ref="`field_${fieldProps.name}_${fieldProps.id}`"
                          :data-filters="dataFilters"
                          :value="value"
                          @input="$emit('input', $event)"
    />
    <select-static-field v-else
                         :field-props="fieldProps"
                         :placeholder="placeholder"
                         :apply-default-val="applyDefaultVal"
                         :value="value"
                         @input="$emit('input', $event)"/>

    <a
      v-if="fieldProps.default != '1' && fieldProps.create_new_data_option == '1'"
      href="javascript:;"
      class="d-flex justify-content-end"
      @click="createNewOptions(fieldProps)"
    >
      {{ translate('Create new', 'entities') }}
    </a>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import SelectDynamicField from "@/modules/erp_entities/components/page/form/fields/components/SelectDynamicField.vue"
import SelectStaticField from "@/modules/erp_entities/components/page/form/fields/components/SelectStaticField.vue"
import bus from "@/modules/erp_entities/utilities/eventBus"

export default {
  name: 'SelectField',
  mixins: [FormFieldMixin],
  components: {
    SelectDynamicField,
    SelectStaticField,
  },
  props: {
    applyDefaultVal: {
      required: false,
      default: true,
    },
    // only for SelectDynamicField
    dataFilters: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    bus.$on(`record_created_${this.fieldProps.name}_${this.fieldProps.id}`, () => {
      this.$refs[`field_${this.fieldProps.name}_${this.fieldProps.id}`]?.loadData()
    })
  },
  methods: {
    createNewOptions(fieldProps) {
      if (fieldProps.data_type === 'moduleNames') {
        this.$modal.show('create_new_entity')
      } else if (fieldProps.data_type === 'custom') {
        this.$modal.show('create_new_options')
      }

      this.$emit('createOptions', fieldProps)
    },
  }
}
</script>
