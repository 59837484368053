<template>
    <div class="progress progress-loader">
        <div class="indeterminate"></div>
    </div>
</template>
<script>
export default {
  name: 'vInfiniteProgressBar'
}
</script>
<style>
.page-content .progress-loader {
    position: absolute;
    top: 66px;
    left: 0;
    right: 0;
}
</style>
