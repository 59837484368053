<template>
  <div class="inline-editor-actions" ref="draggableContainer" :class="{'inline-editor-actions-draggable': showDrag}">
    <template v-if="showDrag">
      <div class="item grabbable" @mousedown="dragMouseDown">
        <button class="d-flex align-items-center grabbable">
          <i class="icon-erp-drag-and-drop grabbable"></i>
        </button>
      </div>

      <div class="item inline-editor-actions-separator m-0 p-0"></div>
    </template>

    <div class="item">
      <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ headingName }} <i class="fas fa-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
          <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 1 }) }">
            Heading 1
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 2 }) }">
            Heading 2
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 3 }) }">
            Heading 3
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 4 }) }">
            Heading 4
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 5 }) }">
            Heading 5
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" class="dropdown-item text-bold" :class="{ 'active': editor.isActive('heading', { level: 6 }) }">
            Heading 6
          </button>
          <button @click="editor.chain().focus().setParagraph().run()" class="dropdown-item" :class="{ 'active': editor.isActive('paragraph') }">
            Paragraph
          </button>
        </div>
      </div>
    </div>

    <div class="item inline-editor-actions-separator"></div>

    <ee-form-color-picker
      :simple="true"
      :value="editor.getAttributes('textStyle').color"
      @input="editor.chain().focus().setColor($event).run()"
      class="item inline-editor-picker-component"
    />

    <button @click="editor.chain().focus().unsetColor().run()" class="item item-cancel" v-if="editor.getAttributes('textStyle').color">
      <i class="icon-erp-plus icon-erp-rotate-45 d-flex font-weight-bold"></i>
    </button>

    <div class="item inline-editor-actions-separator"></div>

    <div class="item">
      <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <template v-if="editor.getAttributes('textStyle').fontSize">
            {{ editor.getAttributes('textStyle').fontSize }}
          </template>
          <template v-else>
            {{ translate('Unset', 'entities') }}
          </template>
          <i class="fas fa-chevron-down"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">

          <button
            @click="editor.chain().focus().unsetFontSize().run()"
            class="dropdown-item"
            :class="{'active': !editor.getAttributes('textStyle').fontSize}"
          >
            {{ translate('Unset', 'entities') }}
          </button>

          <button v-for="fontSize in fontSizes"
                  @click="editor.chain().focus().setFontSize(fontSize).run()"
                  class="dropdown-item"
                  :class="{'active': editor.isActive('textStyle', {fontSize: fontSize})}"
          >
            {{ fontSize }}
          </button>

        </div>
      </div>
    </div>

    <button @click="editor.chain().focus().toggleBold().run()" class="item" :class="{ 'is-active': editor.isActive('bold') }">
      <i class="icon-erp-ri-bold"></i>
    </button>
    <button @click="editor.chain().focus().toggleItalic().run()" class="item" :class="{ 'is-active': editor.isActive('italic') }">
      <i class="icon-erp-ri-italic"></i>
    </button>
    <button @click="editor.chain().focus().toggleUnderline().run()" class="item" :class="{ 'is-active': editor.isActive('underline') }">
      <i class="icon-erp-ri-underline"></i>
    </button>
    <button @click="editor.chain().focus().toggleStrike().run()" class="item" :class="{ 'is-active': editor.isActive('strike') }">
      <i class="icon-erp-ri-strikethrough"></i>
    </button>
    <button @click="editor.chain().focus().toggleOrderedList().run()" class="item" :class="{ 'is-active': editor.isActive('bulletList') }">
      <i class="icon-erp-ri-list-ordered"></i>
    </button>
    <button @click="editor.chain().focus().toggleBulletList().run()" class="item" :class="{ 'is-active': editor.isActive('bulletList') }">
      <i class="icon-erp-ri-list-unordered"></i>
    </button>

    <div class="item">
      <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i :class="alignClass"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">

          <button @click="editor.chain().focus().setTextAlign('left').run()" class="dropdown-item" :class="{ 'active': editor.isActive({ textAlign: 'left' }) }">
            <i class="icon-erp-ri-align-left"></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('center').run()" class="dropdown-item" :class="{ 'active': editor.isActive({ textAlign: 'center' }) }">
            <i class="icon-erp-ri-align-center"></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('right').run()" class="dropdown-item" :class="{ 'active': editor.isActive({ textAlign: 'right' }) }">
            <i class="icon-erp-ri-align-right"></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('justify').run()" class="dropdown-item" :class="{ 'active': editor.isActive({ textAlign: 'justify' }) }">
            <i class="icon-erp-ri-align-justify"></i>
          </button>
          <button @click="editor.chain().focus().unsetTextAlign().run()" class="dropdown-item">
            {{ translate('Unset', 'entities') }}
          </button>

        </div>
      </div>
    </div>

    <ee-form-color-picker
      simple
      icon="bg"
      :value="editor.getAttributes('textStyle').backgroundColor"
      @input="editor.chain().focus().setBackgroundColor($event).run()"
      class="item inline-editor-picker-component"
    />

    <button @click="editor.chain().focus().unsetBackgroundColor().run()" class="item item-cancel" v-if="editor.getAttributes('textStyle').backgroundColor">
      <i class="icon-erp-plus icon-erp-rotate-45 d-flex font-weight-bold"></i>
    </button>

    <link-picker
      :value="this.editor.getAttributes('link')"
      @input="setLink($event)"
      class="item"
      :class="{ 'is-active': editor.isActive('link') }"
    />

    <button @click="editor.chain().focus().unsetLink().run()" class="item" v-if="editor.isActive('link')">
      <i class="icon-erp-ri-link-unlink-m"></i>
    </button>


    <!--
    <button @click="editor.chain().focus().toggleCode().run()" class="item" :class="{ 'is-active': editor.isActive('code') }">
      code
    </button>
    <button @click="editor.chain().focus().clearNodes().run()" class="item">
      clear nodes
    </button>

    <button @click="editor.chain().focus().toggleOrderedList().run()" class="item" :class="{ 'is-active': editor.isActive('orderedList') }">
      ordered list
    </button>
    <button @click="editor.chain().focus().toggleCodeBlock().run()" class="item" :class="{ 'is-active': editor.isActive('codeBlock') }">
      code block
    </button>
    <button @click="editor.chain().focus().toggleBlockquote().run()" class="item" :class="{ 'is-active': editor.isActive('blockquote') }">
      blockquote
    </button>
    <button @click="editor.chain().focus().setHorizontalRule().run()" class="item">
      horizontal rule
    </button>
    <button @click="editor.chain().focus().setHardBreak().run()" class="item">
      hard break
    </button>
    <button @click="editor.chain().focus().undo().run()" class="item">
      undo
    </button>
    <button @click="editor.chain().focus().redo().run()" class="item">
      redo
    </button>
    -->

    <div class="item inline-editor-actions-separator"></div>

    <button @click="editor.chain().focus().unsetAllMarks().run()" class="item">
      <i class="icon-erp-ri-format-clear"></i>
    </button>

  </div>
</template>

<script>
import LinkPicker from "@/modules/erp_entities/components/page/form/fields/components/LinkPicker.vue"
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker.vue"

export default {
  name: "TextEditorActions",
  components: {
    EeFormColorPicker,
    LinkPicker,
  },
  props: {
    editor: {
      required: true,
    },
    showDrag: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    }
  },
  computed: {
    headingName() {
      if(this.editor.isActive('heading', { level: 1 })){
        return 'Heading 1'
      } else if(this.editor.isActive('heading', { level: 2 })){
        return 'Heading 2'
      } else if(this.editor.isActive('heading', { level: 3 })){
        return 'Heading 3'
      } else if(this.editor.isActive('heading', { level: 4 })){
        return 'Heading 4'
      } else if(this.editor.isActive('heading', { level: 5 })){
        return 'Heading 5'
      } else if(this.editor.isActive('heading', { level: 6 })){
        return 'Heading 6'
      } else if(this.editor.isActive('heading', { level: 7 })){
        return 'Heading 7'
      } else if(this.editor.isActive('paragraph')){
        return 'Paragraph'
      }
      return 'Paragraph'
    },
    fontSizes(){
      return [
        '12px', '14px', '16px', '18px', '24px', '28px', '32px', '36px', '42px', '48px', '64px'
      ]
    },
    alignClass(){
      if(this.editor.isActive({ textAlign: 'left' })){
        return 'icon-erp-ri-align-left'
      } else if(this.editor.isActive({ textAlign: 'right' })){
        return 'icon-erp-ri-align-right'
      } else if(this.editor.isActive({ textAlign: 'center' })){
        return 'icon-erp-ri-align-center'
      } else if(this.editor.isActive({ textAlign: 'justify' })){
        return 'icon-erp-ri-align-justify'
      }
      return 'icon-erp-ri-align-left'
    },
  },
  methods: {
    // link = {href: string, target: string}
    setLink(link) {

      /* enable this once tiptap link add support for rel
      link.rel = ''
      if(link.target === '_blank'){
        link.rel = 'noopener noreferrer nofollow'
      }*/

      // empty
      if (link.href === '') {
        this.editor
          .chain()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .extendMarkRange('link')
        .setLink(link)
        .run()

    },
    dragMouseDown(event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag(event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
  },
}
</script>

<style lang="scss">

.inline-editor-actions {
  background: #fff;
  padding: 0 5px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  white-space: nowrap;
  z-index: 2; /* required for the pagebuilder, z-index must be greater than  .add_new_section, .add_new_row, .add_snippets_to_col */

  .item {
    display: flex;
    padding: 5px 7px;

    &.inline-editor-actions-separator {
      padding-right: 0;
      margin-left: 7px; // 👆
    }
    .nav-item {
      height: 22px; /* hot fix */
      align-items: center;
      i.fa-chevron-down {
        font-size: 10px;
        padding-left: 5px;
      }
    }

    button {
      font-size: 0.9rem;
    }

    [class^=icon-erp] {
      font-size: 20px;
    }

    &.item-cancel {
      margin: 0;
      [class^=icon-erp] {
        font-size: 16px;
      }
    }
    &.is-active {
      background: #f1f4f8;
    }
  }

  button:not(.btn-primary) {
    background: none;
    border: none;
  }

  .inline-editor-actions-separator {
    border-left: 1px solid rgba(226,220,220,.75);
    width: 1px;
    height: 24px;
  }

  input[type="color"] {
    width: 26px;
    height: 26px;
    border: none;
    padding: 0;
    background: none;
  }
  input[type="color"]:hover {
    cursor: pointer;
  }
  input[type="color" i]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color" i]::-webkit-color-swatch {
    border-radius: 50%;
  }
}

.inline-editor-actions {
  &.inline-editor-actions-draggable {
    position: absolute;
  }

  .dropdown-menu {
    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      color: #212529;
      white-space: nowrap;
      background-color: transparent;
      border: 0;

      /* new styles */
      text-align: center;
      /* END new styles */
    }

    .dropdown-item.active, .dropdown-item:hover {
      background-color: #e9ecef;
    }
  }
}
</style>