<template>
  <div class="col-style">
    <div class="col-style-child d-flex justify-content-between align-items-center p-3">
      <span class="system-text-primary font-weight-bold">
        {{ translate('Order date', 'orders') }}
      </span>
<!--      <span>{{ formatDate(value, null, 'DD MMM YY - HH:mm') }}</span>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDate",
  props: {
    value: {
      required: true,
    },
  },
}
</script>
