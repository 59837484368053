<template>
  <textarea
    v-model="value.notes"
    class="form-control order-note note"
    type="text"
    :placeholder="translate('Put a note to this order', 'orders')"
  />
</template>

<script>
export default {
  name: "OrderNotes",
  props: {
    value: {
      required: true,
    },
  },
}
</script>