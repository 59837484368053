<template>
  <div class="col-style">
    <div class="col-style-child d-flex justify-content-between align-items-center p-3">
      <span class="system-text-primary font-weight-bold">
        {{ translate('Order status', 'orders') }}
      </span>
      <div class="d-flex align-items-center">
        <div
          class="nav-item dropdown dropdown-styled dropdown-animate mr-0"
          data-toggle="hover"
        >
          <a
            class="nav-link pr-lg-0 py-0"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span
              class="status-badge"
              :class="`status_${value.status}`"
            >{{ translate(value.status, 'orders') }} <i class="fa fa-chevron-down align-middle" /></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
            <a
              v-for="(status, si) in statuses"
              :key="si"
              href="#"
              class="dropdown-item"
              @click="changeStatus(si)"
            >
              {{ status }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderStatuses",
  props: { 
    value: {
      required: true,
    },
  },
  data(){
    return {
      statuses: null,
    }
  },
  created() {
    this.erp.ext.request.axiosInstance.get(`/modules/sales/orders/statuses`)
      .then(res => this.statuses = res.data)
  },
  methods: {
    changeStatus(status) {
      this.value.status = status
    },
  },
}
</script>
