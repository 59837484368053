import { render, staticRenderFns } from "./PaymentProviders.vue?vue&type=template&id=2016e440&scoped=true&"
import script from "./PaymentProviders.vue?vue&type=script&lang=js&"
export * from "./PaymentProviders.vue?vue&type=script&lang=js&"
import style0 from "./PaymentProviders.vue?vue&type=style&index=0&id=2016e440&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2016e440",
  null
  
)

export default component.exports