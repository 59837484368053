<template>
    <div class="container">
        <page-title :page-title="translate('Attributes', 'entities')" buttons="/product-attributes">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="save">
                    <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
                    <span v-else>{{ translate('Create', 'global') }}</span>
                </button>
            </template>
        </page-title>

        <div class="row">
            <div class="col-md-12">
                <span class="font-weight-bold mb-3 d-block">
                    <span v-if="$route.params.id">
                      {{ translate('Edit {entity}', 'entities', { entity: translate('Attribute', 'entities') }) }}
                    </span>
                    <span v-else>{{ translate('Add New', 'entities') }}</span>
                </span>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ translate('Name', 'attributes') }}</label>
                    <ee-form-input v-model="data.label" :field-props="{translatable: 1}"/>
                </div>
                <div class="form-group" v-if="!$route.params.id">
                    <label>{{ translate('Slug', 'attributes') }}</label>
                    <input type="text" class="form-control" :value="data.name" @input="data.name=$event.target.value.toLowerCase()">
                </div>
                <div class="form-group">
                    <label>{{ translate('Visual Type', 'attributes') }}</label>
                    <div class="d-flex flex-wrap">
                        <div
                            v-for="(type, typeI) in types" :key="typeI"
                            class="switch-field custom-switch-class"
                        >
                            <input :id="`${type}_${typeI}`" :name="`${type}_${typeI}`" type="radio" :value="typeI"
                                   v-model="data.type">
                            <label :for="`${type}_${typeI}`">{{ translate(`${type}`, 'attributes') }}</label>
                        </div>
                    </div>
                </div>

              <div v-if="withPrefixSuffixTypes.includes(data.type)" class="row form-group">
                <div class="col-6">
                  <label>{{ translate('Prefix', 'attributes') }}</label>
                  <input type="text" class="form-control" v-model="data.input_prepend">
                </div>

                  <div class="col-6">
                  <label>{{ translate('Suffix', 'attributes') }}</label>
                  <input type="text" class="form-control" v-model="data.input_append">
                </div>
              </div>

              <div class="form-group">
                    <label>{{ translate('Location on Product Page', 'attributes') }}</label>
                    <treeselect-field
                        v-model="data.attribute_location"
                        :field-props="{ data: positions }"
                        required
                    />
                </div>

                <div class="form-group">
                    <label>{{ translate('Position in Attiribute list', 'attributes') }}</label>
                    <input class="form-control" type="number" v-model="data.attribute_location_order">
                    <small>{{ translate('Ascending order (top to bottom)', 'attributes') }}</small>
                </div>

                <div v-if="webshopFiltarablerTypes.includes(data.type)" class="form-group">
                    <label>{{ translate('Filterable', 'attributes') }}</label>
                    <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                name="webshop_filterable"
                                id="webshop_filterable"
                                class="custom-control-input"
                                :true-value="1"
                                :false-value="0"
                                v-model="data.webshop_filterable"
                            />
                            <label for="webshop_filterable" class="custom-control-label"></label>
                        </div>
                    </div>
                </div>

              <div class="form-group"  v-if="data.type && translatableTypes.includes(data.type)">
                <label>{{ translate('Translatable', 'attributes') }}</label>
                <div class="d-flex justify-content-between py-2">
                  <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        name="translatable"
                        id="translatable"
                        class="custom-control-input"
                        :true-value="1"
                        :false-value="0"
                        v-model="data.translatable"
                    />
                    <label for="translatable" class="custom-control-label"></label>
                  </div>
                </div>
              </div>

              <div v-if="webshopFiltarablerTypes.includes(data.type) && data.webshop_filterable" class="form-group">
                  <label>{{ translate('Webshop filter type', 'attributes') }}</label>
                  <v-select class="select2-form" :options="webshopFilterTypeOptions(data.type)" v-model="data.webshop_filter_type"
                    label="value" :reduce="value => value.key"
                    :clearable="false" :searchable="false"/>
              </div>

              <div v-if="webshopFiltarablerTypes.includes(data.type) && data.webshop_filterable" class="form-group">
                  <label>{{ translate('Position in Filters', 'attributes') }}</label>
                  <input class="form-control" v-model="data.webshop_filters_order" type="number">
                  <small>{{ translate('Ascending order (top to bottom)', 'attributes') }}</small>
              </div>

              <div v-if="webshopFiltarablerTypes.includes(data.type) && data.webshop_filterable" class="form-group">
                <label>{{ translate('Tooltip', 'attributes') }}</label>
                <textarea-simple-field
                  v-model="data.webshop_filter_tooltip"
                  :field-props="data"
                />
              </div>
            </div>

            <div class="col-md-8" v-if="data.type && ['select', 'multiselect', 'visual_swatch'].includes(data.type)">
                <label class="font-weight-700">{{ translate('Options', 'attributes') }}</label>
                <button @click="addOption"
                        class="icon btn-primary rounded-circle icon-sm icon-shape ml-2">
                    <i class="fas fa-plus"></i>
                </button>

                <div class="bg-white categories-table">
                    <table class="table align-items-center mb-0">
                        <thead>
                        <tr>
                            <th><i class="icon-erp-drag-racing"></i></th>
                            <th>{{ translate('Label', 'attributes') }}</th>
                            <th>{{ translate('Key', 'attributes') }}</th>
                            <th>{{ translate('Default', 'attributes') }}</th>
                            <th v-if="['visual_swatch'].includes(data.type)">{{ translate('Visual', 'attributes') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <draggable
                            v-model="data.data"
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="drag = false"
                            handle=".handle"
                            tag="tbody"
                            :draggable="'.draggable-item'"
                        >
                            <tr v-for="(option, optionI) in data.data" :key="optionI" class="draggable-item">
                                <td class="cursor-pointer handle">
                                    <i class="icon-erp-menu"></i>
                                </td>
                                <td>
                                   <ee-form-input @input="e => generateKey(e, optionI)" v-model="data.data[optionI].value" :field-props="data"/>
                                </td>
                                <td>
                                  <input-field
                                    :value="data.data[optionI].key"
                                    @input="e => data.data[optionI].key=e.toLowerCase()"
                                    :field-props="{ permission: isVoidValue(data.data[optionI].id) ? 'w' : 'r' }"/>
                                </td>
                                <td v-if="['visual_swatch'].includes(data.type)">
                                    <div
                                        :class="{'no-chosen': !option.visual || option.visual === ''}"
                                        class="position-relative visual-swatch"
                                    >
                                        <span
                                            class="swatch-picker"
                                            :style="{background: option.visual && option.visual.toString().includes('data:') ? `url(${option.visual})` : `${option.visual}`}"
                                            @click="openSettings('picker', optionI)"
                                        >
                                        </span>
                                        <div
                                            v-show="show.picker === optionI"
                                            class="swatch-picker-container"
                                        >
                                            <span
                                                class="js-pick"
                                                @click="openFilePicker(optionI, 'fileInput' + optionI)"
                                            >
                                                {{ translate('Choose image', 'attributes') }}
                                            </span>
                                            <input
                                                type="file" :ref="'fileInput' + optionI"
                                                @change="onFileChange(option, $event)"
                                            >
                                            <span
                                                class="js-pick"
                                                @click="openSettings('color', optionI)"
                                            >
                                                {{ translate('Choose color', 'entities') }}
                                            </span>
                                            <div class="color-picker" v-if="show.color === optionI">
                                                <color
                                                    :value="option.visual && option.visual.toString().includes('#') ? option.visual : '#fff'"
                                                    @input="v => changeColor(v, optionI)"
                                                >
                                                </color>
                                            </div>
                                            <span
                                                class="js-pick"
                                                @click="clearSwatch(optionI)"
                                            >
                                                {{ translate('Clear', 'attributes') }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-switch">
                                      <input
                                        :id="'option-default-'+optionI"
                                        v-model="data.data[optionI].is_default"
                                        type="checkbox"
                                        class="custom-control-input"
                                        name="default_value"
                                        :true-value="1"
                                        :false-value="0"
                                        @click="e => setDefaultProp(e.target, optionI)"
                                      >
                                      <label
                                        class="custom-control-label"
                                        :for="'option-default-'+optionI"
                                      />
                                    </div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="removeDataProp(optionI)">
                                        <i class="icon-erp-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </div>

                <div class="form-group">
                    <div class="d-inline-block my-3 mr-1">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                name="create_new_data_option"
                                id="create_new_data_option"
                                class="custom-control-input"
                                :true-value="1"
                                :false-value="0"
                                v-model="data.create_new_data_option"
                            />
                            <label for="create_new_data_option" class="custom-control-label"></label>
                        </div>
                    </div>
                    <label>{{ translate('Allow to create new option on fly', 'attributes') }}</label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="d-flex justify-content-end">
                    <router-link class="btn btn-white rounded-pill" to="/product-attributes">
                        {{ translate('Go back', 'global') }}
                    </router-link>
                    <button class="btn btn-primary rounded-pill" @click="save">
                        <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
                        <span v-else>{{ translate('Create', 'global') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {locales, translationLocales, isVoidValue} from '@/utilities/helper'
import {Chrome} from 'vue-color'
import {slug} from "../../../../utilities/helper";
import { EeFormInput } from "@/modules/erp_entities/components/forms";
import { InputField, TreeselectField, TextareaSimpleField } from "@/modules/erp_entities/components/page/form/fields"

export default {
    name: "AttributesCrud",
    display: 'Table',
    components: {
      InputField,
      TextareaSimpleField,
      TreeselectField,
      EeFormInput,
      draggable, 'color': Chrome
    },
    data() {
        return {
            data: {
              name: '',
              translatable: 1, // translatable by default
              attribute_location: [],
            },
            drag: false,
            show: {
                picker: '',
                color: false
            },
            withPrefixSuffixTypes: ['text', 'number'],
            translatableTypes: ['text', 'textarea', 'select', 'multiselect', 'visual_swatch'],
            webshopFiltarablerTypes: ['number', 'select', 'multiselect', 'visual_swatch', 'date'],
        }
    },
    async created() {
        if (this.$route.params.id) {
            let data = await this.erp.ext.request.axiosInstance.get(`/modules/attributes/${this.$route.params.id}`)
            this.data = data.data

            let soRQ = ['select', 'multiselect', 'visual_swatch'].includes(this.data.type)

            if (soRQ && this.data.data_module !== null) {
                let dropdown = await this.erp.ext.request.axiosInstance.get(`/select-options/${this.data.data_module}`)
                this.data.data = dropdown.data.data.select_option_options;
            }
        }
        window.addEventListener('click', function (e) {
            if (!e.target.classList.contains('swatch-picker') &&
                !e.target.classList.contains('swatch-picker-container') &&
                !e.target.classList.contains('js-pick') &&
                (!e.target.getAttribute('class') || e.target.getAttribute('class').indexOf('vc-') === -1)
            ) {
                this.show.picker = false
                this.show.color = false
            }
        }.bind(this))
    },
    methods: {
        isVoidValue,
        webshopFilterTypeOptions(data_type) {
          if(['number', 'date'].includes(data_type)) {
            return [
              { key: 'range', value: this.translate('Range', 'attributes') },
            ]
          }

          return [
            { key: 'range', value: this.translate('Range', 'attributes') },
            { key: 'select', value: this.translate('Select', 'attributes') },
            { key: 'visual_swatch', value: this.translate('Visual swatch', 'attributes') },
          ]
        },
        generateKey(v, i) {
          // generate key only on new attributes
          if (!this.data.data[i].id) {
            this.data.data[i].key = slug(v)
          }
        },
        async save() {

            let soRQ = ['select', 'multiselect', 'visual_swatch'].includes(this.data.type)

            // those types cannot be translatable
            if(['image', 'number', 'date'].includes(this.data.type)){
              this.data.translatable = 0
            }

            // Set default value 0 for position in attribute list if some void value is being passed
            if (isVoidValue(this.data.attribute_location_order)) {
              this.data.attribute_location_order = 0
            }

            // Set default value 0 for position in filters if some void value is being passed
            if (isVoidValue(this.data.webshop_filters_order)) {
              this.data.webshop_filters_order = 0
            }

            if (soRQ) {
                // build data for dropdown
                var dropdownData = {...this.data}
                dropdownData.name = this.data.label
                dropdownData.slug = this.data.name
                dropdownData.select_option_options = this.data.data
                if(!dropdownData.select_option_options || dropdownData.select_option_options.length === 0){
                  return this.$toast.error('Please enter at least one option!')
                }

                // todo remove me after backend has correct validation
                let keys_filled = true
                dropdownData.select_option_options.forEach(el => {
                  if(el.key === ''){
                    keys_filled = false
                  }
                });
                if(!keys_filled){
                  return this.$toast.error('Please fill all keys!')
                }
                // END todo remove me after backend has correct validation

                dropdownData.model = 'Inventory'
                delete dropdownData.label
                delete dropdownData.type
                delete dropdownData.data
            }

            // build data for attribute
            let attributeData = {...this.data}
            delete attributeData.data
            attributeData.data_type = 'custom'

            let locales = Object.keys(this.translationLocales)
            for(let i=0; i < locales.length; i++){
              if(isVoidValue(this.data.label[locales[i]])){
                return this.$toast.error('Name field is required!')
              }
            }

            try {
                if (this.$route.params.id) {
                    // Update
                    if (soRQ) {
                        if(typeof attributeData.data_module === 'undefined' || attributeData.data_module === null){
                          let dropdownRQ = await this.erp.ext.request.axiosInstance.post('/select-options', {options: dropdownData})
                          //attributeData.data_module = dropdownRQ.data.data[0].id //old way
                          attributeData.data_module = dropdownRQ.data.data.id

                        }else{
                          await this.erp.ext.request.axiosInstance.put(`/select-options/${attributeData.data_module}`, {options: dropdownData})
                        }
                    }
                    await this.erp.ext.request.axiosInstance.put(`/modules/attributes/${this.$route.params.id}`, {options: attributeData})
                    this.$toast.requestSuccess('put', 'Attribute')
                } else {
                    // Create
                    if (soRQ) {
                        let dropdownRQ = await this.erp.ext.request.axiosInstance.post('/select-options', {options: dropdownData})
                        //attributeData.data_module = dropdownRQ.data.data[0].id //old way
                        attributeData.data_module = dropdownRQ.data.data.id
                    }
                    await this.erp.ext.request.axiosInstance.post('/modules/attributes', {options: attributeData})
                    .then(res => {
                      this.$toast.requestSuccess('post', 'Attribute')
                      this.$router.push('/product-attributes')
                    }).catch(error => {
                      this.$alert.formattedError(error)
                    })
                    // await this.$router.push('/product-attributes')
                }
            } catch (error) {
                this.$alert.formattedError(error)
            }
        },
        addOption() {
            if (!this.data.data) {
                this.$set(this.data, 'data', [])
            }
            this.data.data.push({
                key: '',
                value: '',
                is_default: 0,
            })
        },
        removeDataProp(i) {
            this.data.data.splice(i, 1)
        },
        openSettings(type, index) {
            if (this.show[type] === index) {
                this.show[type] = null
            } else {
                this.show[type] = index
            }
        },
        onFileChange(item, e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(item, files[0]);
            this.$forceUpdate()
        },
        createImage(item, file) {
            var image = new Image();
            var reader = new FileReader();

            reader.onload = (e) => {
                item.visual = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        openFilePicker(i, ref) {
            this.$refs[ref][0].click()
        },
        changeColor(v, i) {
            this.data.data[i].visual = v.hex
            this.$forceUpdate()
        },
        clearSwatch(i) {
            this.data.data[i].visual = ''
            this.$forceUpdate()
        },
        setDefaultProp(e, i) {
          if(this.data.data[i].is_default == '0') {
            this.data.data.forEach(option => {
              option.is_default = 0
            })
          }
        },
    },
    computed: {
        locales,
        translationLocales,
        types() {
            return {
                image: 'Image',
                text: 'Text',
                number: 'Number',
                textarea: 'Textarea',
                select: 'Select',
                multiselect: 'Multi-select',
                visual_swatch: 'Visual swatch',
                date: 'Date',
            }
        },
        positions() {
            return [
                { key: 'above_title', value: this.translate('Above product title on product page', 'attributes') },
                { key: 'under_title', value: this.translate('Under the product title on product page', 'attributes') },
                { key: 'under_desc', value: this.translate('Under the short description on product page', 'attributes') },
                { key: 'under_img', value: this.translate('Under the product image on product page', 'attributes') },
                { key: 'under_title_cat_page', value: this.translate('Under product title in catalog page', 'attributes') },
                { key: 'under_pricing_cat_page', value: this.translate('Under the pricing in catalog page', 'attributes') },
                { key: 'tabs', value: this.translate('Additional information tab', 'attributes') },
                { key: 'brochure_main_page', value: this.translate('Brochure main page', 'attributes') },
            ]
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
  watch: {
    'data.type': function (newValue, oldValue){
      if(typeof oldValue !== 'undefined'){
        // set default values
        this.$set(this.data, 'input_prepend', '')
        this.$set(this.data, 'input_append', '')
        this.$set(this.data, 'webshop_filterable', 0)
        this.$set(this.data, 'webshop_filter_type', null)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.custom-switch-class {
    flex: 1 1 50%;
    max-width: 50%;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color !important;

    &:first-of-type, &:nth-of-type(2) {
        border-top: 1px solid $border-color;
    }

    &:nth-of-type(odd) {
        border-left: 1px solid $border-color !important;
    }

    label {
        margin-bottom: 0;
        width: 100%;
        color: $primary;
        border: 0 !important;
        border-radius: 0 !important;
    }

    input:checked + label {
        background-color: $primary;
    }
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    cursor: grabbing;
}

.option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: .5rem 1rem;
    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: 0;
    }
}

.swatch-picker {
    border: 1px solid #c6c5c3;
    cursor: pointer;
    overflow: visible;
    position: relative;
    width: 25px;
    height: 24px;
    display: block;
    background-size: cover !important;
    background-position: center !important;

    &:before {
        position: absolute;
        content: '';
        top: -4px;
        font-size: 19px;
        color: #808080;
        left: -4px;
        width: 33px;
        height: 30px;
        background: transparent;
        border-radius: 3px 0 0 3px;
        border: 3px solid white;
        z-index: 1;
        outline: 1px solid #adadad;
    }

    &:after {
        position: absolute;
        content: '\2193';
        top: -5px;
        font-size: 19px;
        color: #808080;
        right: -22px;
        width: 16px;
        height: 32px;
        background: #fff;
        border-radius: 0 3px 3px 0;
        border: 1px solid rgb(173, 173, 173);
        text-align: center;
        z-index: 8;
    }
}

.swatch-picker-container {
    position: absolute;
    top: 27px;
    left: -103px;
    background-color: #fff;
    border: 1px solid $border-color;
    z-index: 10;
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22);

    span {
        padding: .2rem .5rem;
        border-bottom: 1px solid $border-color;
        cursor: pointer;

        &:last-child {
            border-bottom: 0;
        }
    }

    input[type=file] {
        display: none;
    }

    .color-picker {
        position: absolute;
        top: 0;
        right: 150px;
    }
}

.no-chosen {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 32px;
        height: 2px;
        background: #f00;
        transform: rotate(-45deg);
        z-index: 5;
        left: -3px;
        top: 11px;
    }
}

</style>
