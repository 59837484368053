<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ translate('Embed video', 'entities') }}</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="$modal.hide('embed-video')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{ translate('Youtube video URL', 'entities') }}</label>
      <input-field
        v-model="src"
        placeholder="https://"
        :field-props="{ ...fieldProps, translatable: false }"
      />
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary rounded-pill"
        data-dismiss="modal"
        @click="$modal.hide('embed-video')"
      >{{ translate('Close', 'entities') }}
      </button>
      <button @click="save" type="button" class="btn btn-primary rounded-pill">{{ translate('Save changes', 'entities') }}</button>
    </div>
  </div>
</template>

<script>
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { generateEmbeddedSrc, generateThumbnailFromSrc } from "@/utilities/helper"

export default {
  name: "EmbedVideoModal",
  components: {
    InputField,
  },
  props: {
    fieldProps: {
      required: true,
    },
  },
  data() {
    return {
      src: '',
    }
  },
  methods: {
    save() {
      if (this.src && this.src.length) {
        const embeddedSrc = generateEmbeddedSrc(this.src)
        if (!embeddedSrc) {
          return this.$toast.error('Please enter valid URL')
        }

        const data = {
          url: embeddedSrc,
          thumbnail: generateThumbnailFromSrc(this.src),
          title: null,
          alt: null,
          featured: false,
          type: 'embed-video',
        }

        this.$emit('input', data)
      }
      this.$modal.hide('embed-video')
    },
  },
}
</script>
