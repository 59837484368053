<template>
    <div class="not-found-page">
        <div class="dfc h-100">
            <h2> <br> {{ translate('This page is in development or does not exist!', 'error_page') }} </h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style scoped>
    .not-found-page {
        height: calc(100vh - 200px);
    }

    .team-avatar {
        width: 200px;
    }

    .single-member {
        display: flex;
        flex-direction: column;
    }
</style>
