<template>
     <div class="subscription-index">
        <div class="row">
            <div class="col-md-12 mb-4">
                <page-title :page-title="translate('Subscriptions', 'subscriptions')">
                    <template v-slot:actions>
                        <router-link to="/blockz-order/create/choose-plan" class="btn btn-primary rounded-pill">
                                <!-- <span class="p-3">{{ translate('marketplace.go_to_my_apps') }}</span> -->
                                <span class="p-3">{{ translate('Add new order', 'subscriptions') }}</span>
                        </router-link>
                    </template>
                </page-title>
            </div>
            <div class="col-md-12 mb-2">
                <div class="col-style">
                    <div class="d-flex">
                        <div class="d-flex justify-content-between align-items-center p-2">
                            <div class="search-field">
                                <span><i class="fa fa-search mr-3"></i></span>
                                <input type="text" id="search" :placeholder="translate('Search', 'subscriptions')" name="search">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <span>{{ translate('Active', 'subscriptions') }}</span>
                    </div>
                </div>
                <hr>
            </div>

            <div class="col-md-12">
                <div class="col-style">
                    <div class="main-table">
                    <div class="d-flex flex-column">
                        <div class="table-responsive">
                            <div class="card m-0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ translate('Subscriptions', 'subscriptions') }}</th>
                                            <th scope="col">{{ translate('Last Payment', 'subscriptions') }}</th>
                                            <th scope="col">{{ translate('Next Payment', 'subscriptions') }}</th>
                                            <th scope="col">{{ translate('Disk Usage', 'subscriptions') }}</th>
                                            <th scope="col">{{ translate('Seats used', 'subscriptions') }}</th>
                                            <th scope="col">{{ translate('Action', 'subscriptions') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="p-2">
                                        <tr>
                                            <td>Content Management
                                                <p>Business plan</p>
                                            </td>
                                            <td>$79.95
                                                <p>Jan 17, 2020</p>
                                            </td>
                                            <td>$89.95
                                                <p>Jan 17, 2020</p>
                                            </td>
                                            <td>200mb / 5GB</td>
                                            <td>3/4
                                                <p>Seats used</p>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <a class="btn dropdown-toggle" data-toggle="dropdown" href="#">
                                                        {{ translate('Action', 'subscriptions') }}
                                                        <span class="caret"></span>
                                                    </a>
                                                    <ul class="dropdown-menu">
                                                        <li><a href="#">{{ translate('Cancel plan', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Update payment method', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Upgrade plan', 'subscriptions') }}</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Project Management
                                                <p>Business plan</p>
                                            </td>
                                            <td>$79.95
                                                <p>Too Late</p>
                                            </td>
                                            <td>$89.95
                                                <p>Jan 17, 2020</p>
                                            </td>
                                            <td>4GB / 5GB</td>
                                            <td>1/4
                                                <p>Seats used</p>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <a class="btn dropdown-toggle" data-toggle="dropdown" href="#">
                                                        {{ translate('Action', 'subscriptions') }}
                                                        <span class="caret"></span>
                                                    </a>
                                                    <ul class="dropdown-menu">
                                                        <li><a href="#">{{ translate('Cancel plan', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Update payment method', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Upgrade plan', 'subscriptions') }}</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="last-row">
                                            <td>Invoicing
                                                <p>Trial</p>
                                            </td>
                                            <td>$79.95
                                                <p>Jan 17, 2020</p>
                                            </td>
                                            <td>$89.95
                                                <p>Jan 17, 2020</p>
                                            </td>
                                            <td>200nb / 5GB</td>
                                            <td>3/4
                                                <p>Seats used</p>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <a class="btn dropdown-toggle" data-toggle="dropdown" href="#">
                                                        {{ translate('Action', 'subscriptions') }}
                                                        <span class="caret"></span>
                                                    </a>
                                                    <ul class="dropdown-menu">
                                                        <li><a href="#">{{ translate('Cancel plan', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Update payment method', 'subscriptions') }}</a></li>
                                                        <li><a href="#">{{ translate('Upgrade plan', 'subscriptions') }}</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <span>{{ translate('Expired', 'subscriptions') }}</span>
                    </div>
                </div>
                <hr>
            </div>
        </div>
     </div>

</template>
<script>
    export default {

    }
</script>
<style scoped lang="scss">
    .subscription-index {
        .main-table {
            margin: 1rem 0;
            border-radius: 5px;
            background-color: #fff;
        }

        thead {
            tr {
                background-color: #f8fafc;

                th {
                    border-bottom: 1px solid #e5edf5;
                }
            }

        }

        tbody {
            tr {
                td {
                    background-color: #fff;
                    border-bottom: 1px solid #e5edf5;
                }

                .invoicing {
                border-bottom: none;;
            }
            }

            .last-row {
                border-bottom-left-radius: 5px;

                td {
                    border-bottom: none;
                }
            }
        }

        .search-field {
            width: 100%;

            #search {
                border: none;
            }
        }

        .col-style {
            background-color: white;
        }

        // .style-row {
        //     border-bottom: 1px solid black;
        // }
    }
</style>
