<template>
  <div :class="['field-'+fieldProps.name.split('.')[1]]">
    <div class="d-flex">
      <div class="switch-field" v-for="(option, optionIndex) in data" :key="optionIndex" >
        <input
          :id="`option-${fieldProps.name}-${option.key}`"
          :name="`option-${fieldProps.name}`"
          :type="fieldProps.type"
          :value="option.key"
          v-model="model"
          :disabled="isDisabled"
          @click="value !== option.key && confirmEdit($event)"
        >
        <label :for="`option-${fieldProps.name}-${option.key}`" v-html="entityLabel(option.value, fieldProps.system_attribute==1)"></label>
      </div>
    </div>
    <a href="javascript:;" v-if="fieldProps.default != '1' && fieldProps.create_new_data_option == '1' && fieldProps.data_type === 'custom'"
       class="d-flex justify-content-end" @click="canWrite ? createNewOptions(fieldProps) : null">
      {{ translate('Create new', 'entities') }}
    </a>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormSelectMixin from "@/modules/erp_entities/mixins/FormSelectMixin"

export default {
  name: 'RadioField',
  mixins: [FormFieldMixin, FormSelectMixin],
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
}
</script>
