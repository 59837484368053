<template>
  <div class="container px-0">
    <div class="container px-0">
      <page-title :page-title="translate('Website navigations', 'menus')" :borderBottom="false">
        <template v-slot:actions>
          <button type="submit"
                  @click="save"
                  class="btn btn-primary rounded-pill">
            {{ translate('Save changes', 'global') }}
          </button>
        </template>
      </page-title>

      <LanguagePicker :label="translate('Choose language', 'dashboard')"
                      :customLabelClasses="['font-weight-400', 'mr-2']"
                      :labelAlignment="true"
                      :lang="menu.locale"
                      class="mt-2 md-2"
                      @changeLocale="e => changeLocale(e)"/>

      <div class="row">
        <div class="tabbable website-settings col-12">
          <ul class="nav nav-tabs mb-4">
            <li class="nav-item">
              <span class="nav-link d-flex"
                    :class="{'active': isNavbar}"
                    @click="$set(menu, 'location', 'navbar')">
                {{ translate('Header navigation', 'menus')}}
              </span>
            </li>
            <li class="nav-item">
              <span class="nav-link d-flex"
                    :class="{'active': isSidebar}"
                    @click="$set(menu, 'location', 'sidebar')">
                {{ translate('Sidebar navigation', 'menus')}}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span class="mb-3 d-block">
            {{ translate('Drag the items into the order you prefer.', 'menus')}}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <NavigationLinks v-if="isNavbar" :entities-data="selectedLanguageData" v-model="navbarNavigation" />
        <NavigationLinks v-if="isSidebar" :entities-data="selectedLanguageData" v-model="sidebarNavigation" />
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button type="submit"
              @click="save"
              class="btn btn-primary rounded-pill">
        {{ translate('Save changes', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'vuex'
import NavigationLinks from "./NavigationLinks.vue"
import { hasManyTranslationLocales, urlEncoder } from "@/utilities/helper"
import { db } from '@/utilities/database'
import LanguagePicker from "@/components/LanguagePicker.vue"

export default {
  name: 'WebsiteNavigation',
  components: {
    NavigationLinks,
    LanguagePicker,
  },
  data(){
    return {
      data: {
        pages: [],
        categories: [],
      },
      menu: {
        location: 'navbar',
        navigation: [],
        locale: null,
      },
      website: '',
      navbarNavigation: '',
      sidebarNavigation: '',
      unsavedChanges: false,
    }
  },
  computed: {
    hasManyTranslationLocales,
    ...mapState('system', ['scope', 'translation_locale']),
    isNavbar(){
      return this.menu.location === 'navbar'
    },
    isSidebar(){
      return this.menu.location === 'sidebar'
    },
    selectedLanguageData() {
      let pages = []
      pages = this.data.pages.filter(el => el.original_locale == this.menu.locale)

      let categories = []
      this.data.categories.forEach(el => {
        categories.push(Object.assign({}, el))
      })

      categories.forEach(el => {
        if(typeof el.original_name === 'object'){
          el.original_name = this.entityLabel(el.original_name)
        }

        // if(typeof el.url_key === 'object'){
        //   el.url_key = this.entityLabel(el.url_key)
        // }
      })

      return { pages, categories }
    },
  },
  async created() {
    this.$store.state.system.isLoading = true
    this.menu.locale = this.translation_locale

    const pages = (await this.erp.ext.request.get('/modules/cms-pages',
      this.erp.ext.query().set('perpage', 9999).toString()))
      .objectData

    this.data.pages = pages.map(item => {
      return {
        entity: "cms_pages",
        entity_id: item.id,
        visible_on_mobile: 1,
        nav: [],
        original_name: item.name,
        original_locale: item.locale,
      }
    })

    const categories = (await this.erp.ext.request.get('/modules/categories',
      this.erp.ext.query()
        .where('type', '=', 'categories')
        .set('perpage', 9999).toString()
    ))
      .objectData

    this.data.categories = categories.map(item => {
      return {
        entity: "categories",
        entity_id: item.id,
        visible_on_mobile: 1,
        nav: [],
        original_name: item.name,
      }
    })

    await this.fetchWebsite()
    this.$store.state.system.isLoading = false
    this.unsavedChanges = false
  },

  methods: {
    async fetchWebsite() {
      this.$store.state.system.isLoading = true

      const params = {
        filter_criteria : {
          filter_groups: {
            '1': { filters: [{ key: 'locale', condition: '=', value: this.menu.locale }] },
          },
        },
      }

      this.website = await db.get(`modules/menus?${urlEncoder(params)}`)
      this.setupNavigation().then(() => this.unsavedChanges = false)
      this.$store.state.system.isLoading = false
    },

    async setupNavigation() {
      let navbarData = this.website.data.data.filter(el => el.location === 'navbar').reverse()
      let sidebarData = this.website.data.data.filter(el => el.location === 'sidebar').reverse()
      if (navbarData.length > 0) {
        this.navbarNavigation = navbarData[0].navigation
        this.website.navbar_id = navbarData[0].id
      } else {
        this.navbarNavigation = []
      }
      if (sidebarData.length > 0) {
        this.sidebarNavigation = sidebarData[0].navigation
        this.website.sidebar_id = sidebarData[0].id
      } else {
        this.sidebarNavigation = []
      }
      this.unsavedChanges = false
    },

    // get rid of cached original_name and add
    formatMenu(menu){
      menu.forEach(m => {
        delete m.original_name
        m.use_static_name = m.name ? 1 : 0 // required for the backend only
        if(m.nav) {
          m.nav = this.formatMenu(m.nav)
        }
      })
      return menu
    },

    save() {
      this.$store.state.system.isLoading = true

      const location = this.menu.location
      const name = this.isNavbar ? 'Header navigation' : 'Sidebar navigation'
      const navigation = this.formatMenu(cloneDeep( this.isNavbar ? this.navbarNavigation : this.sidebarNavigation ))

      const menu = { ...this.menu, name, navigation }

      if(this.website[`${location}_id`]) {
        this.erp.ext.request.axiosInstance.put(`modules/menus/${this.website[location + '_id']}`, menu)
          .then(() => {
            this.$toast.requestSuccess('put', 'Website navigation')
            this.$store.state.system.isLoading = false;
          })
          .catch(error => {
            this.$alert.formattedError(error)
            this.$store.state.system.isLoading = false
          })
      } else {
        this.erp.ext.request.axiosInstance.post('modules/menus', menu)
          .then(res=> {
            this.website[`${location}_id`] = res.data.data.id
            this.$toast.requestSuccess('post', 'Website navigation')
            this.$store.state.system.isLoading = false
          })
          .catch(error => {
            this.$alert.formattedError(error)
            this.$store.state.system.isLoading = false
          })
      }

      this.unsavedChanges = false
      this.$forceUpdate()
    },

    async changeLocale(e) {
      if(e !== this.menu.locale) {
        if(this.unsavedChanges) {
          const confirmResult = await this.$alert.confirmSaveChanges()
          if(confirmResult.isDismissed){
            return
          } else if(confirmResult.isConfirmed){
            await this.save()
          }
        }

        this.$set(this.menu, 'locale', e)
        this.fetchWebsite()
        this.unsavedChanges = false
      }
    },
  },
  watch: {
    'navbarNavigation': {
      deep: true,
      handler() {
        this.unsavedChanges = true
      },
    },
    'sidebarNavigation': {
      deep: true,
      handler() {
        this.unsavedChanges = true
      },
    },
  },
}
</script>