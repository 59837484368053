<template>
    <div>
        <div class="modal-content" v-if="entity && entityOptions">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ translate('Update users', 'tasks')}}
                </h5>
                <button type="button" class="close" @click="$modal.hide('task_users')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body b-b-pr">
                <div class="form-group">
                    <label class="form-control-label">{{ translate('Assignee', 'tasks')}}</label>
                    <v-select class="select2-form" :options="entityOptions.assignee" v-model="entity.assignee" label="value"></v-select>
                </div>
                <div class="form-group">
                    <label class="form-control-label">{{ translate('Contributors', 'tasks')}}</label>
                    <v-select multiple class="select2-form" :options="entityOptions.contributors" v-model="entity.contributors" label="value"></v-select>
                </div>
                <div class="form-group">
                    <label class="form-control-label">{{ translate('Watchers', 'tasks')}}</label>
                    <v-select multiple class="select2-form" :options="entityOptions.watchers" v-model="entity.watchers" label="value"></v-select>
                </div>
            </div>
            <div class="modal-footer remove-border">
                <button type="button" :disabled="loading" class="btn btn-primary btn-sm"
                        @click="$modal.hide('task_users')">{{ translate('Go back', 'global') }}
                </button>
                <button type="submit" :disabled="loading" @click="$emit('saveUsers', entity) && $modal.hide('task_users')"
                        class="btn btn-primary btn-sm">
                    {{ translate('Save changes', 'global') }}
                </button>
            </div>
            <v-infinite-progressbar v-if="loading"></v-infinite-progressbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TaskUsers",
        data() {
            return {
                loading: false,
            }
        },
        props: {
            entity: {
                required: true
            },
            entityOptions: {
                required: true
            }
        },
    }
</script>

<style scoped>

</style>
