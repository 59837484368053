<template>
    <div class="row">
      <div class="col-md-12">
        <h5 v-if="regionName">
          {{ regionName }}
        </h5>
      </div>
      <div class="col-md-3" v-if="!samePricing">
        <div class="form-group">
          <label>{{ translate('Title', 'shipping') }}</label>
          <input class="form-control" type="text" v-model="value.title">
        </div>
      </div>
      <div :class="{'col-md-3': !samePricing, 'col-md-6': samePricing}">
        <div class="form-group">
          <label>{{ translate('Price', 'shipping') }}</label>
          <input class="form-control" type="number" v-model.number="value.price">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group mb-0">
          <label>{{ translate('Allow free shipping', 'shipping') }}</label>
          <div class="d-flex justify-content-between py-2">
            <div class="custom-control custom-switch">
              <input
                  type="checkbox"
                  name="enable_free_shipping"
                  :id="`enable_free_shipping_${value.country}`"
                  class="custom-control-input"
                  v-model="value.enable_free_shipping"
                  true-value="1"
                  false-value="0"
              />
              <label
                  :for="`enable_free_shipping_${value.country}`"
                  class="custom-control-label">
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="value.enable_free_shipping != 0">
        <div class="form-group">
          <label>{{ translate('Free shipping above', 'shipping') }}</label>
          <input class="form-control" type="number" v-model.number="value.free_shipping_above">
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: "FlatRate",
    props: {
      value: {
        type: Object,
        required: true
      },
      regionName: {
        required: false
      },
    },
    computed: {
      samePricing() {
        return !this.regionName
      }
    }
}
</script>

<style scoped>

</style>
