// General settings
import DesignSettings from "./general/DesignSettings.vue"
import ExportTemplateSettings from "./general/ExportTemplateSettings.vue"
import ImportTemplateSettings from "./general/ImportTemplateSettings.vue"
import VisibilitySettings from "./general/VisibilitySettings.vue"

// Element specific settings
import AccordionSettings from "./AccordionSettings.vue"
import BannerSettings from "./BannerSettings.vue"
import BlogSettings from "./BlogSettings.vue"
import ButtonSettings from "./ButtonSettings.vue"
import CardSettings from "./CardSettingsNEWTODO.vue"
import CategoriesSettings from "./CategoriesSettings.vue"
import ColumnSettings from "./ColumnSettings.vue"
import CustomCodeSettings from "./CustomCodeSettings.vue"
import CustomHtmlSettings from "./CustomHtmlSettings.vue"
import EmbedVideoSettings from "./EmbedVideoSettings.vue"
import FormSettings from "./FormSettings.vue"
import GallerySettings from "./GallerySettings.vue"
import GoogleReviewsSettings from "./GoogleReviewsSettings.vue"
import IconBoxSettings from "./IconBoxSettings.vue"
import InstagramFeedSettings from "./InstagramFeedSettings.vue"
import ImageSettings from "./ImageSettings.vue"
import PortfolioSettings from "./PortfolioSettings.vue"
import ProductsSettings from "./ProductsSettings.vue"
import RowSettings from "./RowSettings.vue"
import SearchBarSettings from "./SearchBarSettings.vue"
import SocialMediaIconsSettings from "./SocialMediaIconsSettings.vue"
import StoreLocatorSettings from "./StoreLocatorSettings.vue"
import SectionSettings from "./SectionSettings.vue"
import ContentSliderSettings from "./ContentSliderSettings.vue"
import SeparatorSettings from "./SeparatorSettings.vue"
import SliderSettings from "./SliderSettings.vue"
import TabsSettings from "./TabsSettings.vue"
import TestimonialsSettings from "./TestimonialsSettingsNEWTODO.vue"
import WhitespaceSettings from "./WhitespaceSettings.vue"

export default {
  // General settings
  DesignSettings,
  ExportTemplateSettings,
  ImportTemplateSettings,
  VisibilitySettings,

  // Element specific settings
  AccordionSettings,
  BannerSettings,
  BlogSettings,
  ButtonSettings,
  CardSettings,
  CategoriesSettings,
  ColumnSettings,
  CustomCodeSettings,
  CustomHtmlSettings,
  EmbedVideoSettings,
  FormSettings,
  GallerySettings,
  GoogleReviewsSettings,
  IconBoxSettings,
  ImageSettings,
  InstagramFeedSettings,
  PortfolioSettings,
  ProductsSettings,
  RowSettings,
  SearchBarSettings,
  SocialMediaIconsSettings,
  StoreLocatorSettings,
  SectionSettings,
  ContentSliderSettings,
  SeparatorSettings,
  SliderSettings,
  TabsSettings,
  TestimonialsSettings,
  WhitespaceSettings,
}
