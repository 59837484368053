<template>
    <nav class="navbar system-nav sticky-top p-0" id="navbar-main" v-if="authData && authData.company">
        <div class="container-fluid h-100">

            <!-- Mobile menu toggler -->
            <div class="d-flex d-lg-none justify-content-between align-items-center h-100">
                <button @click="show.menu = !show.menu" class="navbar-toggler h-100 menu-button" :class="show.menu ? 'active' : null" type="button" data-toggle="collapse" data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fa fa-bars"></span>
                </button>
            </div>

            <!-- User's navbar -->
            <!--  NOT USED ANYMORE <nav-menu></nav-menu>-->
            <user-menu></user-menu>

            <transition name="slide-in">
                <div class="w-100 mobile-menu-overlay d-lg-none" v-show="show.menu">
                    <sidebar-links @close="show.menu = false" :value="navigation"></sidebar-links>
                </div>
            </transition>
        </div>
    </nav>
</template>

<script>
    import UserMenu from "./user/UserMenu";
    import {mapState} from "vuex";
    import SidebarLinks from "../sidebar/SidebarLinks";
    import {getNavigation} from "@/utilities/navigation";
    import {sidebarNavigation} from "@/utilities/sidebarNavigation";

    export default {
        name: "Navbar",
        components: {
            UserMenu, SidebarLinks
        },
        data() {
            return {
                show: {
                    menu: false,
                },
                navigation: []
            }
        },
        computed: {
            ...mapState('system', ['authData', 'modules', 'company']),
        },
        async mounted() {
          this.navigation = await getNavigation()

          setTimeout(function () {
            sidebarNavigation()
          }.bind(this), 2500)
        },
        watch: {
          'scope': {
            deep: true,
            async handler(){
              this.navigation = await getNavigation()
            }
          }
        }
    }
</script>
