<template>
    <div>
        <div class="main-table no-search mt-4">
            <div class="table-responsive">
                <table class="table align-items-center m-0">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ translate('Order number', 'entities') }}</th>
                        <th scope="col">{{ translate('Purchased', 'entities') }}</th>
                        <th scope="col">{{ translate('Bill to', 'entities') }}</th>
                        <th scope="col">{{ translate('Ship to', 'entities') }}</th>
                        <th scope="col">{{ translate('Total', 'entities') }}</th>
                        <th scope="col">{{ translate('Status', 'entities') }}</th>
                        <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                    </tr>
                    </thead>
                    <tbody v-if="value.orders">
                    <tr class="tr-1" v-for="(order, orderI) in value.orders">
                        <td>
                            <router-link :to="`/webshop-orders/${order.id}?redirectLocation=/webshop-customers/${value.id}/orders`" class="table cursor-pointer">
                                {{ order.order_number }}
                            </router-link>
                        </td>
                        <td>{{ formatDate(order.created_at) }}</td>
                        <td>{{ order.billing_client_first_name }} {{ order.billing_client_last_name }}</td>
                        <td>{{ order.shipping_client_first_name }} {{ order.shipping_client_last_name }}</td>
                        <td><span v-html="order.formatted_price_amounts.total_amount" /></td>
                        <td>
                            <span class="status-badge" :class="`status_${order.status}`">
                                {{ translate(`${order.status}`, 'orders') }}
                            </span>
                        </td>
                        <td class="text-right">
                            <cta :collapsed="true" :buttonsNum="3">
                                <action-button class-icon="fa-eye" @click.native="$router.push(`/webshop-orders/${order.id}?redirectLocation=/webshop-customers/${value.id}/orders`)"></action-button>
                            </cta>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="bg-white text-center p-5" v-if="!value.orders">
              {{ translate('No data', 'global') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomerOrders",
    props: {
        value: {
            required: true
        },
    }
}
</script>

<style scoped>

</style>
