import menu from './menu'
import entities from "./entities";
import global from "./global"
import sidebar from "./sidebar"
import table from './table'
import users from "./users";
import roles from "./roles"
import companies from "./companies";
import calendar from "./calendar";
import entity from "./entity";
import tracker from "./tracker";
import tasks from "./tasks";
import builder from "./builder";
import dropdowns from "./dropdowns";
import entity_settings from "./entity_settings.json";
import projects from "./projects";
import status from "./status.json"

export default {
    name: 'Dutch',
    groups:{
        menu,
        entities,
        global,
        sidebar,
        table,
        users,
        roles,
        companies,
        calendar,
        entity,
        entity_settings,
        tracker,
        tasks,
        builder,
        dropdowns,
        projects,
        status
    }
}
