<template>
  <a
    :onmouseenter="getButtonMouseEnterValues()"
    :onmouseleave="getButtonMouseLeaveValues()"
    :href="getSetting('link')"
    :target="linkTarget"
    class="cms-block cms-block-button btn btn-primary"
    :class="getSetting('icon_position') === 'left' ? 'flex-row' : 'flex-row-reverse'"
  >
    <template v-if="getSetting('icon') || getSetting('icon_custom')">
      <img
        v-if="getSetting('icon_custom')"
        :src="getSetting('icon_custom')"
        style="height: 45px; width: 45px;"
        class="mx-2"
      >
      <i
        v-else
        class="mx-2 fa"
        :class="`${getSetting('icon')}`"
        :style="iconStyle"
      ></i>
    </template>
    {{ getSetting('button_text') }}
  </a>
</template>

<script>

import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";
import CraftStyleSettingsMixin from "../../../mixins/CraftStyleSettingsMixin";

export default {
  name: 'ButtonElement',
  mixins: [
    CraftSettingsMixin,
    CraftStyleSettingsMixin
  ],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        button_text: 'Button',
        link: '',
        link_target: '_self',
        target: '',
        background_hover_color: '',
        text_hover_color: '',
        border_hover_color: '',
        icon: '',
        icon_custom: '',
        icon_position: 'left',
        icon_color: '',
        icon_hover_color: '',
      },
      elementStyle: {
        desktop: {
          'border-radius': '.25rem',
          'width': '25%',
          'margin': '0 auto 0 0',
        },
      },
    },
  },
  computed: {
    linkTarget() {
      return (this.getSetting('link_target') && this.getSetting('link_target') !== '_self') ? this.getSetting('link_target') : null;
    },
    iconStyle(){
      let color = ""
      if(this.getSetting('icon_color')){
        color = this.getSetting('icon_color')
      }

      return {
        color: color,
      }
    },
  },
  methods: {
    getButtonMouseEnterValues() {
      let values = ''
      if(this.getSetting('background_hover_color')){
        values += "this.style.setProperty('background-color', '" + `${this.getSetting('background_hover_color')}` + "', 'important');"
      }
      if(this.getSetting('text_hover_color')){
        values += "this.style.setProperty('color', '" + `${this.getSetting('text_hover_color')}` + "', 'important');"
      }
      if(this.getSetting('border_hover_color')){
        values += "this.style.setProperty('border-color', '" + `${this.getSetting('border_hover_color')}` + "', 'important');"
      }
      if (this.getSetting('icon_hover_color')) {
        values += `this.children[0].style.setProperty('color', "${this.getSetting('icon_hover_color')}", 'important');`
      }
      return values
    },

    getButtonMouseLeaveValues() {
      // here we also check for hover styles, if exist we need to return the original color
      let values = ''
      if(this.getStyle('background-color') || this.getSetting('background_hover_color')){
        values += "this.style.setProperty('background-color', '" + `${this.getStyle('background-color')}` + "', 'important');"
      }
      if(this.getStyle('color') || this.getSetting('text_hover_color')){
        values += "this.style.setProperty('color', '" + `${this.getStyle('color')}` + "', 'important');"

        if (this.getSetting('icon_color')) {
          values += `this.children[0].style.setProperty('color', "${this.getSetting('icon_color')}", 'important');`
        } else {
          values += `this.children[0].style.setProperty('color', "${this.getSetting('color')}", 'important');`
        }

      }
      if(this.getStyle('border-color') || this.getSetting('border_hover_color')){
        values += "this.style.setProperty('border-color', '" + `${this.getStyle('border-color')}` + "', 'important');"
      }
      if (this.getSetting('icon_color')) {
        values += `this.children[0].style.setProperty('color', "${this.getSetting('icon_color')}", 'important');`
      }
      return values

    },
  },
};
</script>