<template>
  <entity-index-content
    entity-type="orders"
  />
</template>

<script>
import EntityIndexContent from "@/modules/erp_entities/components/page/EntityIndexContent.vue"

export default {
  name: "Orders",
  components: {
    EntityIndexContent,
  },
}
</script>