import CustomEditor from "@/modules/cms/views/CustomEditor.vue"
import WebsiteShow from "@/modules/cms/views/cms/WebsiteShow.vue"
import CreateTagAndCategory from '@/modules/cms/views/categories_and_tags/Create.vue'

// CHILD ROUTES
import Navigation from "../../layout/sidebar/navigation/WebsiteNavigation.vue"
import ClientsBilling from "@/modules/cms/views/account/ClientsBilling.vue"
import ClientsMembers from "@/modules/cms/views/account/ClientsMembers.vue"
import ClientsSubscriptions from "@/modules/cms/views/account/ClientsSubscriptions.vue"
import MyPayments from "@/modules/cms/views/account/MyPayments.vue"
import InvoiceShowView from "@/modules/cms/views/account/InvoiceShowView.vue"
import Knowledgebase from "@/modules/cms/views/knowledgebase/Knowledgebase.vue"
import SubscriptionsGeneral from "../sitecart/views/subscriptions/SubscriptionsGeneral.vue"

import Integrations from "./views/marketing/Integrations.vue"
import SeoSitemaps from "./views/marketing/SeoSitemaps.vue"

export default [
  // not used, won't work with the new builder
  {
    path: '/custom-editor',
    name: 'custom-editor',
    component: CustomEditor,
    meta: {
      interface: 'skitle',
    },
  },
  // END not used
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
    component: Knowledgebase,
  },
  {
    path: '/navigation',
    name: 'navigation',
    component: Navigation,
    meta: {
      layout: 'master',
    },
  },

  // Categories and tags
  {
    path: '/entity/:entity/:type/crud',
    name: 'categories',
    component: CreateTagAndCategory,
    meta: {
      interface: 'skitle',
    },
  },

  {
    path: '/my-account/:id',
    name: 'my-account',
    component: () => import('../cms/views/account/ClientsShow.vue'),
    meta: {
      interface: 'skitle',
    },
    children: [
      {
        path: 'general',
        name: 'general',
        component: SubscriptionsGeneral,
        meta: {
          interface: 'skitle',
        },
      },
      {
        path: 'billing',
        name: 'billing',
        component: ClientsBilling,
        meta: {
          interface: 'skitle',
        },
      },
      {
        path: 'members',
        name: 'members',
        component: ClientsMembers,
        meta: {
          interface: 'skitle',
        },
      },
      {
        path: 'subscriptions',
        name: 'my-subscriptions',
        component: ClientsSubscriptions,
        meta: {
          interface: 'skitle',
        },
      },
      {
        path: 'payments',
        name: 'my-payments',
        component: MyPayments,
        meta: {
          interface: 'skitle',
        },
      },
    ],
  },
  {
    path: '/my-account/:id/billing/:invoiceId',
    name: 'showview-invoice',
    component: InvoiceShowView,
    meta: {
      interface: 'skitle',
    },
  },

  //Marketing routes
  {
    path: '/integrations',
    name: 'integrations',
    component: Integrations,
    meta: {
      interface: 'sitecart',
    },
  },
  {
    path: '/seo-sitemaps',
    name: 'seo-sitemaps',
    component: SeoSitemaps,
    meta: {
      interface: 'sitecart',
    },
  },
]
