<template>
  <intro-layout :authLoading="authLoading">
    <form method="POST" @submit.prevent="login">
      <h6 class="h3 font-weight-bold mb-5">{{ translate('Login', 'system') }}</h6>
      <div class="form-group">
        <label class="form-control-label">{{ translate('Email', 'system') }}</label>
        <input type="email" tabindex="1" v-model="username" name="email" class="form-control"
               @input="clear('username')" :class="{'is-invalid': fill.username === true}" @focus="fill.username = false"
               id="input-email" placeholder="name@example.com">
        <small class="text-danger" v-if="fill.username">{{ translate('Please fill the email field!', 'system') }}</small>
      </div>
      <div class="form-group mb-4">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <label class="form-control-label">{{ translate('Password', 'system') }}</label>
          </div>
          <div class="mb-2">
            <router-link to="/password-recovery" class="small text-muted text-underline--dashed border-primary">{{ translate('Forget your password?', 'system') }}</router-link>
          </div>
        </div>
        <div class="position-relative">
          <input :type="!showPassword ? 'password' : 'text'" tabindex="2" v-model="password"
                 @input="clear('password')" :class="{'is-invalid': fill.password === true}" @focus="fill.password = false"
                 name="password" class="form-control" id="input-password" placeholder="Password">
          <span class="password" @click="showPassword = !showPassword"><i class="fas fa-eye"></i></span>
        </div>
        <small class="text-danger" v-if="errorMsg">{{ translate('Incorrect email or password!', 'system') }}</small>
        <small class="text-danger" v-if="fill.password">{{ translate('Please fill the password field!', 'system') }}</small>
      </div>
      <div class="mt-4 text-right">
        <button :disabled="authLoading" type="submit" tabindex="3" class="btn btn-primary rounded-pill login-btn">
          <span class="btn-inner--text font-weight-bold">{{ translate('Login', 'system') }}
            <span class="btn-inner--icon"></span>
          </span>
        </button>
      </div>
    </form>
  </intro-layout>
</template>

<script>
import { mapActions } from 'vuex'
import IntroLayout from "@/modules/system/views/IntroLayout.vue"

export default {
  name: "Login",
  components: {
    IntroLayout,
  },
  data() {
    return {
      username: '',
      password: '',
      authLoading: false,
      showPassword: false,
      errorMsg: false,
      fill: {
        username: false,
        password: false,
      },
    }
  },
  methods: {
    login() {
      window.localStorage.removeItem('company')
      if (this.username === '' && this.password === '') {
        this.fill.username = true
        this.fill.password = true
        return
      }
      if (this.username === '') {
        this.fill.username = true
        return
      }
      if (this.password === '') {
        this.fill.password = true
        return
      }

      document.getElementById('initial-loader').style.display = 'flex' // show initial-loader

      this.authenticate({ username: this.username, password: this.password })
        .then(res => {
          if (this.$store.state.system.interface === 'rock') {
            this.saveAuthenticationLog({ type: 'login_success', email: this.username })
          }
          document.getElementById('initial-loader').style.display = 'none' // hide initial-loader
          if (!!this.$store.state.system.authData) {
            this.$router.push({ path: '/' })
          }
        })
        .catch((error) => {
          if (this.$store.state.system.interface === 'rock') {
            this.saveAuthenticationLog({ type: 'login_fail', email: this.username })
          }
          document.getElementById('initial-loader').style.display = 'none' // hide initial-loader

          console.log(error.response)
          // TODO: uncomment when we have code = 10 for Incorrect credentials
          // console.log(error.response.data)
          // if (error.response.data.code === 10) {
          if (typeof error.response !== 'undefined') {
            this.$alert.error('Incorrect credentials!')
          }
          this.errorMsg = true
          // } else {
          //     this.$toast.errorGeneral()
          // }
        })
    },
    clear(prop){
      this.fill[prop] = false
      this.errorMsg = false
    },
    ...mapActions('system', ['authenticate', 'saveAuthenticationLog']),
  },
  mounted() {
    Array.from(document.querySelectorAll('.form-control')).forEach(elm => {
      elm.addEventListener('focus', function (e) {
        e.target.parentNode.parentNode.classList.add('focused')
      })
      elm.addEventListener('blur', function (e) {
        e.target.parentNode.parentNode.classList.remove('focused')
      })
    })
  },
}
</script>