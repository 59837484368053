<template>
  <select-dynamic-field :field-props="{ data_type: 'module' }"
                        :placeholder="translate('Search customer', 'orders')"
                        :value="value"
                        @input="$emit('input', $event)"
                        customRoute="customers"
                        has-option-data-slot
                        class="dropdown-menu-advanced"
  >
    <template v-slot:option-data="option">
      <div class="row my-2">
        <div class="col-md-12">
          <span class="font-weight-bold">{{ option.rawObject.full_name }}</span>
        </div>
        <div class="col-md-12">
          <small>{{ customerDetails(option.rawObject) }}</small>
        </div>
      </div>
    </template>
  </select-dynamic-field>
</template>

<script>
import SelectDynamicField from "@/modules/erp_entities/components/page/form/fields/components/SelectDynamicField.vue"

export default {
  name: "CustomerSelect",
  components: {
    SelectDynamicField,
  },
  props: {
    value: {
      required: false,
    },
  },
  methods: {
    customerDetails(customer) {
      let result = ''
      if(customer.company_name){
        result += this.translate('Company', 'customers') + ': ' + customer.company_name + ', '
      }
      return result + this.translate('Email', 'customers') + ': ' + customer.email
    },
  },
}
</script>