<template>
  <div class="cms-block cms-block-search-bar">
    <div :id="searchBarId" :style="wrapperStyle">
      <div class="input-group">
        <input
          type="text"
          class="form-control js_auto-suggest"
          :placeholder="getSetting('placeholder')"
          @mousedown.prevent
          @keydown.prevent
        >
        <div class="input-group-append">
          <button class="input-group-text"><i class="icon-erp-search js-filter-icon"></i></button>
        </div>
      </div>

      <div class="js-auto-suggest-area">
        <div class="auto-suggest-container" style="display: none;"></div>
        <div class="as-loader" style="display: none">
          <div class="loader-lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="text-center auto-suggest-no-results" style="display:none;">
          <span>{{ getSetting('no_results_text') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"

export default {
  name: "SearchBarElement",
  mixins: [CraftSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        // General
        placeholder: 'Search...',
        no_results_text: 'No results found',

        // Layout
        type: 'rounded',
        width: '50%',
        alignment: 'left',

        // Styling
        background_color: '',
        button_background_color: '',

        text_color: '',
        icon_color: '',
        placeholder_color: '',

        border_style: '',
        border_color: '',
        border_width: '',
      },
    },
  },
  computed: {
    searchBarId() {
      return `search-${this.node.uuid}`
    },
    wrapperStyle() {
      let margin

      if (this.getSetting('alignment') === 'left') {
        margin = "0 auto 0 0"
      } else if (this.getSetting('alignment') === 'center') {
        margin = "0 auto"
      } else if (this.getSetting('alignment') === 'right') {
        margin = "0 0 0 auto"
      }

      return {
        margin: margin,
      }
    },
  },
}
</script>

<style lang="scss">
// Page-builder only, disable input hover behavior
.cms-block-search-bar {
  .input-group {
    cursor: default;
    pointer-events: none;
  }
}
</style>