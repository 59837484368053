import settingMixin from "../craft/components/settingMixin"

// Passing the params in a key: value pairs, because the devices toggle buttons should have identifiers which class to be set to (desktop, tablet, mobile)
// All other custom classes are passed in an object with "custom" key. Ex. setClass('custom', 'row-usp bg-success') -> {'custom': 'row-usp bg-success'} in the json
export default {
  mixins: [settingMixin],
  methods: {
    classExist(name) {
      return this.elementProps && this.elementProps.elementClasses && this.elementProps.elementClasses[name] !== undefined
    },
    // custom, mobile, desktop, table
    getClass(name, defaultValue = '') {
      return this.elementProps && this.elementProps.elementClasses && this.elementProps.elementClasses[name]
        ? this.elementProps.elementClasses[name]
        : defaultValue


    },
    setClass(name, value) {

      this.elementPropsSetter({
        elementClasses: {
          ...this.elementProps.elementClasses,
          [name]: value
        },

      })
    },
  },
}
