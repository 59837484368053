<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                    <div class="card col-style" >
                        <div id="tab-1" class="d-flex justify-content-between p-2">
                            <div class="d-flex justify-content-start" style="width: 100%">
                                <img class="avatar rounded-circle p-1" :src="src">
                                <input type="text" id="comment" style="width: 90%" placeholder="Leave a comment" v-model="note"  name="comment">
                            </div>
                            <div class="d-flex justify-content-end">
                                <i class="fa fa-camera" aria-hidden="true" style="padding-right: 0.5rem"></i>
                                <i class="fa fa-paperclip" aria-hidden="true" style="padding-right: 0.5rem" ></i>
                                <i class="fa fa-microphone" aria-hidden="true" style="padding-right: 0.5rem" ></i>
                            </div>
                        </div>
                    </div>

            </div>
            <div class="col-md-12 d-flex justify-content-end" >
                 <button class="button-invoice" @click="newNote()"> {{ translate('Post note', 'invoices') }} </button>
            </div>
            <div class="col-md-12" v-for="note in sortNotes">

                    <div class="card col-style "  >
                        <div id="tab-1" class="d-flex justify-content-between px-4 pt-3 pb-2">
                            <div class="d-flex justify-content-start">
                                <img class="avatar rounded-circle p-1" :src= "note.avatar" >
                                <div class="d-flex flex-column">
                                    <span>{{note.name}}</span>
                                    <span class="created"><i class="fa fa-clock"></i>{{time_ago(note.created_at)}}</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end">
                            </div>
                        </div>
                        <div class="d-flex px-4 pb-3">
                            <p>{{note.comment}}</p>
                        </div>
                    </div>

            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import { db, modules, routes } from "@/utilities/database";
    export default {
        name:"Notes",
        props: {
            notes: {
                required: false
            },
            invoice_id: {
                required: true
            },
            src:{
                required: false
            }
        },
        data(){
            return {
               note:'',
            }
        },
        created(){

        },
        computed:{
            sortNotes(){
            return this.notes.sort((a,b)=>b.number-a.number)
            }
        },
        methods:{
            time_ago(created_at){
              return  moment(created_at).fromNow();
            },
            newNote(){
                db.post(routes.invoice.notes,
                { options:{
                        object_id: this.invoice_id,
                        object_type: "Invoice",
                        comment: this.note
                    },
                })
                .then(res=>{
                    this.$emit('newNote')
                })
                .catch(error => this.$toast.error(error.message))
                this.note=""
            }
        }
    }
</script>
<style scoped>
    #comment {
        border: none;
    }

    .created {
        color: #C0C0C0;
    }
    .col-style {
        background-color: white;
        margin-bottom: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.88);
        border-radius: 5px;
    }
    .button-invoice {
    border-radius: 0.5rem;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    height: 2.6rem
    }
</style>