<template>
  <div class="cms-block cms-block-tabs tabs">
    <div class="row">
      <div :class="`col-md-${tabsWidth}`">
        <ul class="nav nav-tabs" :id="tabsId" :aria-orientation="getSetting('orientation')" :class="navClasses">
          <li v-for="(item, itemIndex) in getSetting('items')" class="nav-item">
            <a class="nav-link p-2" :id="itemId(itemIndex)+'-tab'" data-toggle="tab" :href="'#'+itemId(itemIndex)" role="tab" :aria-controls="itemId(itemIndex)" :aria-selected="itemIndex===0" :class="{active: itemIndex===0}">
              <i v-if="getSetting('icon_position') === 'left' || getSetting('icon_position') === 'top'" :class="`${item.icon} mr-2`"></i>
              <component :is="getSetting('title_font')">{{item.title}}</component>
              <i v-if="getSetting('icon_position') === 'right'" :class="`${item.icon} ml-2`"></i>
            </a>
          </li>
        </ul>
      </div>

      <div :class="`col-md-${contentWidth}`">
        <div class="tab-content" :id="'tab-content-'+node.uuid">
          <div v-for="(item, itemIndex) in getSetting('items')" class="tab-pane fade" :id="itemId(itemIndex)" role="tabpanel" :aria-labelledby="itemId(itemIndex)+'-tab'" v-html="item.content" :class="{show: itemIndex===0, active: itemIndex===0}"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";

export default {
  name: 'TabsElement',
  mixins:[
    CraftSettingsMixin,
  ],
  inject: ['editor', 'node'],
  craft: {
    defaultProps:{
      settings:{
        items: [
          {
            title: 'Tab 1',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            icon: 'fas fa-square-check',
            upload_icon: false,
            position: 1
          },
          {
            title: 'Tab 2',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            icon: 'fas fa-square-check',
            upload_icon: false,
            position: 2
          }
        ],

        // Layout
        orientation: 'horizontal',
        icon_position: 'left',
        tabs_width: 'medium',
        space_between_tabs: '',
        icon_size: '',
        corners: '',

        // Colors
        tab_background_color: '',
        tab_font_color: '',
        tab_icon_color: '',

        tab_active_background_color: '',
        tab_active_font_color: '',
        tab_active_icon_color: '',

        content_background_color: '',
        content_font_color: '',

        // Fonts
        title_font: 'h5',
        title_font_size: ''
      },
      elementStyle: {
        desktop: {
          "width": '100%',
          "margin": '0 auto 0 0',
          "padding-top": '15px',
          "padding-bottom": '15px',
        },
      },
    }
  },
  computed: {
    tabsId(){
      return 'tabs-'+this.node.uuid
    },
    navClasses(){
      let classes = []
      if(this.getSetting('orientation') === 'vertical'){
        classes.push('flex-column')
      }
      if(this.getSetting('tabs_width') === 'full'){
        classes.push('nav-fill')
      }
      return classes
    },
    tabsWidth(){
      if(this.getSetting('orientation') === "vertical") {
        if(this.getSetting('tabs_width') === 'full'){
          return 12
        } else if(this.getSetting('tabs_width') === 'large'){
          return 6
        } else if(this.getSetting('tabs_width') === 'medium'){
          return 3
        } else { // small
          return 2
        }
      }
      return 12
    },
    tabWidth(){
      if(this.getSetting('orientation') === "horizontal") {
        return this.getSetting('tabs_width')
      } else {
        return 12
      }
    },
    contentWidth(){
      if(this.getSetting('orientation') === "vertical"){
        let columnWidth = 12 - this.tabsWidth
        return columnWidth > 0 ? columnWidth : 12
      } else {
        return 12
      }
    },
  },
  methods: {
    itemId(itemIndex){
        return 'item-'+this.node.uuid+'-'+itemIndex
    },
  }
};
</script>