<template>
  <div class="tabbable">
    <ul class="nav nav-tabs">
      <template v-for="tab in tabs">
        <li class="nav-item" v-show="tab.v_hide === undefined || !tab.v_hide">
          <a href="javascript:;" :class="{'active' : tab.isActiveTab}" class="nav-link" @click="selectTab(tab)">
            {{ tab.name }}
          </a>
        </li>
      </template>
    </ul>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  data() {
    return {
      tabs: [],
      show: {
        tabs: false,
      },
    }
  },
  created() {
    this.tabs = this.$children
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActiveTab = (tab.name == selectedTab.name)
      })
      this.$children.forEach(tab => {
        tab.isActiveTab = (tab.name == selectedTab.name)
      })
      this.show.tabs = false
    },
  },
}
</script>

<style>
    @media (max-width: 991px) {
        .tabbable .hide-on-mobile {
            display: none;
        }
    }
    @media (min-width: 991px){
        .hide-on-desktop {
            display: none;
        }
    }
</style>
