<template>
  <!-- TODO Create, Edit, Back button need to be fixed -->

  <div class="container">
    <div class="row">
      <div class="col-12">
        <shipping
          v-if="!shipping_id"
          is-onboarding
          :website-id="value.website_id"
          @edit-shipping="editShipping"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Shipping from "../../../sitecart/views/shipping/Shipping.vue"

export default {
  name: "ShippingSettings",
  components: {
    Shipping,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      shipping_id: null,
    }
  },
  methods: {
    editShipping(id) {
      this.shipping_id = id
    },
    save(){
      this.$refs.editShipping.save()
    },
  },
}
</script>

<style scoped>

</style>
