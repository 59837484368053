<template>
    <div class="container mb-3 px-0">
        <page-title
        	:page-title="translate('Edit courier', 'settings')"
          buttons="/webshop-settings/shipping/list"
		>
            <template v-slot:actions>
            	<button
            		class="btn btn-primary rounded-pill"
                    @click="$emit('save-courier')"
            	>
            		{{ translate('Update', 'settings') }}
            	</button>
        	</template>
        </page-title>

        <ShippingForm
            v-if="shipping"
            ref="saveCourier"
            :initialShipping="shipping"
            :shippingType="shipping.shipping_type"
            :shippingShipToSingleCountry="shippingShipToSingleCountry"

            :initialDefaultFlatRateShippingPrices="defaultFlatRateShippingPrices"
            :initialDefaultTableRateShippingPrices="defaultTableRateShippingPrices"
            :initialShippingPrices="shippingPrices"
            :initialCountries="countries"
        />
    </div>
</template>

<script>
import ShippingForm from "./ShippingForm";

export default {
  name: "EditShipping",
  components: {
      ShippingForm
  },
  data() {
      return {
          shipping: '',
          countries: '',
          defaultFlatRateShippingPrices: {price: ''},
          defaultTableRateShippingPrices: {rates: []},
          shippingPrices: {}
      }
  },
  async mounted() {
      this.$store.state.system.isLoading = true;
      const res = await this.erp.ext.request.axiosInstance.get(`/modules/shipping/rates/${this.$route.params.type}/${this.$route.params.slug}`).catch(() => false)
      if(res) {
          const prices = res.data.data;

          // general fields
          this.shipping = {
              slug: prices[0].slug,
              shipping_type: prices[0].shipping_type,
              cash_on_delivery: prices[0].cash_on_delivery,
              courier: prices[0].courier,
              vat_type_id: prices[0].vat_type_id,
              status: prices[0].status,
              ship_to_all_countries: parseInt(prices[0].ship_to_all_countries),
              same_pricing: parseInt(prices[0].same_pricing),

              // only for table rate & local pickup
              enable_free_shipping: prices[0].enable_free_shipping,
              free_shipping_above: prices[0].free_shipping_above,
              condition_name: prices[0].condition_name,
              // END only for table rate & local pickup

              // only for local pickup
              title: prices[0].title,
              region: prices[0].region,
              city: prices[0].city,
              zip: prices[0].zip,
              address: prices[0].address,
              street_number: prices[0].street_number,
              address_additional_information: prices[0].address_additional_information,
              // END only for local pickup
          }

          // Price fields (all countries/regions or per country/region)
          if( this.shipping.shipping_type === 'flat_rate') {
              await this.setUpFlatRate(prices);
          } else {
              await this.setUpTableRate(prices);
          }
      }
      this.$store.state.system.isLoading = false;
  },
  computed: {
    shippingShipToSingleCountry(){
      return this.shipping.shipping_type === 'local_pickup'
    }
  },
  methods: {
    setUpFlatRate(prices){
      this.countries = []

      prices.forEach((p) => {
        if(p.country && !this.countries.includes(p.country)){
          this.countries.push(p.country)
        }

        if(!this.shipping.ship_to_all_countries && p.country){
          this.$set(this.shippingPrices, p.country, this.flatRatePrice(p))
          if(this.shipping.same_pricing){
            this.defaultFlatRateShippingPrices = this.flatRatePrice(p)
          }
        } else {
          this.defaultFlatRateShippingPrices = this.flatRatePrice(p)
        }
      });
    },
    setUpTableRate(prices) {
      this.countries = []

      prices.forEach((p) => {
        if(p.country && !this.countries.includes(p.country)){
          if(this.shippingShipToSingleCountry){
            this.countries = p.country
          } else {
            this.countries.push(p.country)
          }
        }

        if(p.country){
          if(typeof this.shippingPrices[p.country] === 'undefined'){
            this.shippingPrices[p.country] = {rates: []}
          }
          this.shippingPrices[p.country].rates.push(this.tableRatePrice(p));
        } else {
          this.defaultTableRateShippingPrices.rates.push(this.tableRatePrice(p));
        }
      });

      // if ship to specific countries and same pricing - default prices from the first country
      if(!this.shipping.ship_to_all_countries && this.shipping.same_pricing){
        this.defaultTableRateShippingPrices = this.shippingPrices[Object.keys(this.shippingPrices)[0]]
      }

    },

    flatRatePrice(p){
      return {
        id: p.id,
        price: p.price,
        enable_free_shipping: p.enable_free_shipping,
        free_shipping_above: p.free_shipping_above,
        country: p.country,
        title: p.title
      }
    },
    tableRatePrice(p){
      return {
        id: p.id,
        condition_from_value: p.condition_from_value,
        condition_to_value: p.condition_to_value,
        price: p.price,
        country: p.country,
        title: p.title
      }
    }
  }
}

</script>

<style scoped>

</style>
