<template>
  <div class="entity-container container entity-editable-container">

<!--    <pre><code>{{ JSON.stringify(data, null, 4) }}</code></pre>-->

    <!-- REFACTOR #120147901 INFO: only the visible key is different -->

    <!-- FIELDS WITHOUT TAB ON TOP -->

    <!-- NO TAB AND ACCORDION -->
    <div v-if="data['tab_default']['accordions']['accordion_default']['fields']" class="mb-3">

<!--        <draggable @input="e => $emit('input', e)"-->
      <!--                   :list="attrSetGroup"-->

      <draggable :list="data['tab_default']['accordions']['accordion_default']['fields']"
                 v-bind="dragOptions"
                 @start="drag = true"
                 @end="drag = false;"
                 group="tabs"

                 :draggable="'.draggable-item'">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" tag="div" class="row">
          <div v-for="(input, inputI) in data['tab_default']['accordions']['accordion_default']['fields']" :key="'fields1'+inputI"
               @click="$emit('edit', input)"
               :class="['col-md-' + input.width, 'draggable-item', {'active': input === currentlyEdited}]">
            <div class="d-flex justify-content-end">
              <span class="font-weight-bold pr-1">Envs:</span>
              <span v-for="(env, envI) in input.environments" class="env">{{ env }}</span>
            </div>
            <editable-input-fields
              v-model="input.value" :field-props="input"/>
          </div>
        </transition-group>
      </draggable>
    </div>
    <!-- END NO TAB AND ACCORDION -->

    <!-- NO TAB -->

    <!-- all accordions without tab, except tab_default -->
    <editable-accordion v-for="(accordion, accordionI) in objectWithoutProp(data['tab_default']['accordions'], 'accordion_default')"
               :key="accordionI"
               :tabKey="'tab_default'"
               :accordionKey="accordionI.charAt(0) === '_' ? accordionI.substring(1) : accordionI"

               v-model="accordion.label"

               :all-accordion-labels="allAccordionLabels('tab_default')"
               @removeAccordion="removeAccordion('tab_default', accordionI)">

      <draggable @input="e => $emit('input', e)"
                 v-bind="dragOptions"
                 @start="drag = true"
                 @end="drag = false"
                 group="tabs"
                 :list="accordion['fields']"
                 :draggable="'.draggable-item'">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" tag="div" class="row">
          <div v-for="(input, inputI) in accordion['fields']" :key="'fields2'+inputI"
               @click="$emit('edit', input)"
               :class="['col-md-' + input.width, 'draggable-item', {'active': input === currentlyEdited}]">
            <div class="d-flex justify-content-end">
              <span class="font-weight-bold pr-1">Envs:</span>
              <span v-for="(env, envI) in input.environments" class="env">{{ env }}</span>
            </div>
            <editable-input-fields
              v-model="input.value" :field-props="input"/>
          </div>
        </transition-group>
      </draggable>

    </editable-accordion>

    <!-- END fields without tabs on top -->


    <!-- END TODO REFACTOR #120147901, IT IS ALMOST THE SAME AS BELOW -->
    <button class="btn border w-100 py-3 my-3 bg-white" @click="addAccordion('tab_default')">
      <i class="fas fa-plus mx-auto"></i>
      {{ translate('Add accordion', 'entities') }}
    </button>

    <!-- The rest of the tabs -->
    <editable-tabs v-if="Object.keys(objectWithoutProp(data, 'tab_default')).length"
                   :all-tab-names="allTabLabels()"
                   @addTab="addTab"
                   @editTab="editTab"
                   @removeTab="removeTab">
      <template v-for="(tab, tabI, tabIndex) in objectWithoutProp(data, 'tab_default')">

        <editable-tab :name="tab.label"
                      :tab-key="tabI"
                      :selected="tabIndex === 0">

          <editable-accordion v-for="(accordion, accordionI) in data[tabI]['accordions']"
                     :key="accordionI"

                     v-model="accordion.label"

                     :accordion-key="accordionI"
                     :tabKey="tabI"

                     :all-accordion-labels="allAccordionLabels(tabI)"
                     @removeAccordion="removeAccordion(tabI, accordionI)">

            <draggable @input="e => $emit('input', e)"
                       v-bind="dragOptions"
                       @start="drag = true"
                       @end="drag = false"
                       group="tabs"
                       :list="accordion['fields']"
                       :draggable="'.draggable-item'">
              <transition-group type="transition" :name="!drag ? 'flip-list' : null" tag="div" class="row">
                <div v-for="(input, inputI) in accordion['fields']" :key="'fields3'+inputI"
                     @click="$emit('edit', input)"
                     :class="['col-md-' + input.width, 'draggable-item', {'active': input === currentlyEdited}]">
                  <div class="d-flex justify-content-end">
                    <span class="font-weight-bold pr-1">Envs:</span>
                    <span v-for="(env, envI) in input.environments" class="env">{{ env }}</span>
                  </div>
                  <editable-input-fields
                    v-model="input.value" :field-props="input"/>
                </div>
              </transition-group>
            </draggable>

          </editable-accordion>

          <!-- END TODO REFACTOR #120147901, IT IS THE ALMOST SAME AS ABOVE -->
          <button class="btn border w-100 py-3 bg-white" @click="addAccordion(tabI)">
            <i class="fas fa-plus mx-auto"></i>
            {{ translate('Add accordion', 'entities') }}
          </button>
        </editable-tab>

      </template>



    </editable-tabs>

    <template v-else>
      <button class="btn border w-100 py-3 ml-0 bg-white" @click="addTab">
        <i class="fas fa-plus"></i>
        {{ translate('Add tab', 'entities') }}
      </button>
    </template>

  </div>
</template>

<script>
import EditableAccordion from "@/modules/erp_entities/views/entities/components/EditableAccordion.vue"
import EditableTabs from "@/modules/erp_entities/views/entities/components/EditableTabs.vue"
import EditableTab from "@/modules/erp_entities/views/entities/components/EditableTab.vue"
import EditableInputFields from '../../../components/page/form/EditableInputFields.vue'
import {isVoidValue, objectWithoutProp} from '@/utilities/helper'
import { groupEntityForm } from "../../../utilities/helper"
import {routes} from "@/modules/erp_entities/utilities/database";
import Background from "@/modules/pagebuilder/components/components/settings/sections/design/Background.vue";
import DraggableMixin from "@/mixins/DraggableMixin"

export default {
  name: 'FromFields',
  mixins: [DraggableMixin],
  components: {
    EditableInputFields, EditableAccordion, EditableTabs, EditableTab,
  },
  props: {
    value: {
      required: true,
    },
    currentlyEdited: {
      required: false,
    },
    entity: { // required for fields with shortcodes
      required: false,
    },
  },
  data() {
    return {
      data: {},
    }
  },
  methods:{
    isVoidValue,
    objectWithoutProp,
    allTabLabels(){
      return Object.values(this.data).reduce((acc, obj) => {
        acc.push(obj.label)
        return acc
      }, [])
    },
    allAccordionLabels(tabI){
      return Object.values(this.data[tabI]['accordions']).reduce((acc, obj) => {
        acc.push(obj.label)
        return acc
      }, [])
    },
    addProperty(property){
      this.data['tab_default']['accordions']['accordion_default']['fields'].push(property)
    },
    deleteProperty(property) {
      for (const tabI in this.data) {
        const tab = this.data[tabI]
        for (const accordionI in tab['accordions']) {
          const accordion = tab['accordions'][accordionI]
          for (let fieldI = 0; fieldI < accordion['fields'].length; fieldI++) {
            const field = accordion['fields'][fieldI]
            if (field === property) {
              accordion['fields'].splice(fieldI, 1)
              return
            }
          }
        }
      }
    },

    addTab() {
      const lastKey = Object.keys(this.data).pop().split('_')[1]
      const next = lastKey === 'default' ? 1 : parseInt(lastKey) + 1

      this.$set(this.data, `tab_${next}`, {
        label: 'Tab',
        accordions: {
          accordion_default: {
            label: '',
            fields: [],
          },
        },
      })
    },

    editTab(tabI, value) {
      this.data[tabI]['label'] = value
    },

    async removeTab(tabI) {
      // confirmation is done inside EditableTabs

      // move fields to the default accordion if any
      Object.keys(this.data[tabI]['accordions']).forEach(accordionI => {
        if(this.data[tabI]['accordions'][accordionI]['fields'].length){
          this.data['tab_default']['accordions']['accordion_default']['fields'].push(...this.data[tabI]['accordions'][accordionI]['fields'])
        }
      })

      // delete this.data[tabI] this is not reactive
      this.$delete(this.data, tabI)
    },


    addAccordion(tabI) {
      const lastKey = Object.keys(this.data[tabI]['accordions']).pop().split('_')[1]
      const next = lastKey === 'default' ? 1 : parseInt(lastKey) + 1

      this.$set(this.data[tabI]['accordions'], `accordion_${next}`, {
        label: 'Accordion',
        fields: [],
      })
    },

    async removeAccordion(tabI, accordionI) {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      // move fields to the default accordion if any
      if(this.data[tabI]['accordions'][accordionI]['fields'].length){
        this.data[tabI]['accordions']['accordion_default']['fields'].push(...this.data[tabI]['accordions'][accordionI]['fields'])
      }

      // delete this.data[tabI]['accordions'][accordionI] this is not reactive
      this.$delete(this.data[tabI]['accordions'], accordionI)
    },

    // updateFields(){
    //   let data = []
    //
    //   Object.entries(this.attributeSetGroups).forEach(([tabName, accordions]) => {
    //     Object.entries(accordions).forEach(([accordionName, fields]) => {
    //       fields.forEach(f => {
    //         if (f.group_by === '__default__' || accordionName === '__default__') {
    //           f.group_by = null
    //         } else {
    //           if (accordionName.charAt(0) === '_' ) {
    //             if (!isNaN(Number(accordionName.substring(1)))) {
    //               f.group_by = accordionName
    //             } else {
    //               f.group_by = accordionName.substring(1)
    //             }
    //           }
    //         }
    //         if (f.tab === '__default__' || tabName === '__default__') {
    //           f.tab = null
    //         } else {
    //           if (tabName.charAt(0) === '_' ) {
    //             if (!isNaN(Number(tabName.substring(1)))) {
    //               f.tab = tabName
    //             } else {
    //               f.tab = tabName.substring(1)
    //             }
    //           }
    //           f.tab = tabName.charAt(0) === '_' ? tabName.substring(1) : tabName
    //         }
    //         data.push(f)
    //       })
    //     })
    //   })
    //
    //   this.$emit('input', data)
    // },

    updateFields() {
      let data = []

      // foreach tabs
      Object.keys(this.data).forEach(tabI => {
        // foreach accordions
        Object.keys(this.data[tabI]['accordions']).forEach(accordionI => {
          // foreach fields
          this.data[tabI]['accordions'][accordionI]['fields'].forEach(field => {
            // Set tab and accordion labels
            field.tab = this.data[tabI]['label']
            field.group_by = this.data[tabI]['accordions'][accordionI]['label']

            data.push(field)
          })
        })
      })

      this.$emit('input', data)
    },

    groupFields(value){
      let groupedFields = groupEntityForm(this.value)

      let new_format = {}
      Object.keys(groupedFields).forEach((tab, tabI) => {
        const tabKey = tab === "__default__" ? "tab_default" : 'tab_'+tabI

        new_format[tabKey] = {
          label: tab !== "__default__" ? tab : "",
          accordions: {},
        }

        Object.keys(groupedFields[tab]).forEach((accordion, accordionI) => {
          const accordionKey = accordion === "__default__" ? "accordion_default" : 'accordion_'+accordionI

          new_format[tabKey]['accordions'][accordionKey] = {
            label: accordion !== "__default__" ? accordion : "",
            fields: groupedFields[tab][accordion],
          }

        })
      })

      this.data = new_format
    },
  },
  created(){
    this.groupFields(this.value)
  },
  watch: {
    'data': {
      deep: true,
      handler(){
        this.updateFields()
      },
    },
  },
}
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.env:not(:last-child)::after {
  content: ", ";
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.error-tab .nav-link:not(.active) {
  color: #dc3545 !important;
  opacity: 1 !important;
}

.entity-editable-container {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .draggable-item {
    cursor: pointer;
    background: #fff;

    &.active {
      background: #c8ebfb;
    }
  }
}
</style>
