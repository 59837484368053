<template>
  <div>
    <treeselect
        v-if="continents"
        v-model="model"
        :multiple="multiple"
        :disableBranchNodes="!multiple"
        :placeholder="translate('Select country', 'entities')"
        :normalizer="normalizer"
        :value-consists-of="'LEAF_PRIORITY'"
        :close-on-select="false"
        :defaultExpandLevel="10"
        :clearable="true"
        :options="treeselectOptions"
    />
        <!--
        :value-consists-of="'BRANCH_PRIORITY'"
        :disable-branch-nodes="true"
        -->
</div>
</template>

<script>

// Treeselect
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "Countries",
  components: {Treeselect},
  props: {
    value: {
      required: true
    },
    multiple: {
      required: false,
      default: true,
    }
  },
  data() {
    return {
      continents: null,
      normalizer(node) {
        return {
          id: node.key,
          label: node.value,
        }
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    treeselectOptions() {
      let data_formatted = []
      for (let continent in this.continents){
        let item = {
          key: continent,
          value: continent, // todo human readable name
          //isDisabled: true,
          children: []
        }
        for(let country in this.continents[continent]['countries']){
          // hot fix because somehow final element is a function istead of array
          if(typeof this.continents[continent]['countries'][country] === 'object'){
            item.children.push({
              key: this.continents[continent]['countries'][country]['code'],
              value: this.continents[continent]['countries'][country]['name']
            })
          }
        }
        data_formatted.push(item)
      }

      return data_formatted
    },
  },
  async created() {
      let continents = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/country/continents?perpage=9999')
      this.continents = continents.data.data
      for (const continent in this.continents) {
          this.$set(this.continents[continent], 'expand', false)
      }
  },
  // methods: {
  //   selectCountry(country) {
  //     this.$emit('select-country', country);
  //     if (this.value.map(c => c.code).includes(country.code)) {
  //       this.value.splice(this.value.map(c => c.code).indexOf(country.code), 1);
  //     } else {
  //       this.value.push(country)
  //     }
  //   }
  // }
}
</script>