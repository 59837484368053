<template>
    <div class="container" v-if="records">
        <page-title :page-title="translate('Payment providers', 'entities')" v-if="!isOnboarding"></page-title>
        <div class="row">
            <div class="col-md-12">
                <div class="main-table mt-4">
                    <div class="d-flex justify-content-between align-items-center table-header px-4">
                        <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
                            <div class="search-icon">
                                <span><i class="fa fa-search"></i></span>
                            </div>
                            <input type="text" class="form-control h-100 search-field"
                                   :placeholder="translate('Search by name', 'global')"
                                   :value="userPreferences.search"
                                   @input="e => setSearch(e.target.value)">
                        </div>
                        <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
<!--                            <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">-->
<!--                                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="records">-->
<!--                                    {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}-->
<!--                                    <i class="fa fa-chevron-down"></i>-->
<!--                                </a>-->
<!--                                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">-->
<!--                                    <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">-->
<!--                                        {{per_page.value}}-->
<!--                                    </a>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center m-0">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" @input="e => selectAll(e.target.checked)"
                                               class="custom-control-input"
                                               :id="`select_all`">
                                        <label class="custom-control-label" :for="`select_all`"></label>
                                    </div>
                                </th>
                                <th scope="col">{{ translate('Name', 'payments') }}</th>
                                <th scope="col">{{ translate('Provider', 'payments') }}</th>
                                <th scope="col">{{ translate('Pay in all countries', 'payments') }}</th>
                                <th scope="col">{{ translate('Status', 'payments') }}</th>
                                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(payment, paymentI) in records.data" :key="paymentI"
                                class="tr-1"
                                @mouseleave="activate = null"
                            >
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input :id="'row-' + paymentI" type="checkbox" class="custom-control-input"
                                               v-model="records.data[paymentI].selected">
                                        <label class="custom-control-label" :for="'row-' + paymentI"></label>
                                    </div>
                                </td>
                                <td>
                                    <router-link :to="`/payment-providers/${payment.id}`" class="table">{{ payment.name }}</router-link>
                                </td>
                                <td>{{ payment.provider }}</td>
                                <td>
                                    <span v-if="payment.pay_in_all_countries == '1'">{{ translate('Yes', 'payments') }}</span>
                                    <span v-else>{{ translate('No', 'payments') }}</span>
                                </td>
                                <td v-if="isOnboarding">
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            name="status"
                                            :id="`status_${paymentI}`"
                                            class="custom-control-input"
                                            true-value="1"
                                            false-value="0"
                                            v-model="payment.status"
                                            @click="changePaymentStatus(payment)"
                                        />
                                        <label :for="`status_${paymentI}`" class="custom-control-label"></label>
                                    </div>
                                </td>
                                <td v-else>
                                    <div class="position-relative">
                                        <span class="status-badge" :class="`status_${payment.status}`" @click="activate = payment.id">
                                            {{ translate(`Status ${payment.status}`, 'payments') }}
                                        </span>
                                        <div v-if="activate === payment.id" class="activate-container">
                                            <button class="btn btn-primary" @click="changePaymentStatus(payment)">
                                                <span v-if="payment.status == '1'">{{ translate('Deactivate', 'payments') }}</span>
                                                <span v-else>{{ translate('Activate', 'payments') }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <cta>
                                        <action-button class-icon="fa-pen" @click.native="editPayment(payment.id)"></action-button>
                                    </cta>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
<!--                    <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"-->
<!--                                v-model="records.meta" v-if="records && records.meta"-->
<!--                                @goToPage="v => setProp('page', v)"></pagination>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EntityIndexMixin from "@/mixins/EntityIndexMixin";
export default {
    name: "PaymentProviders",
    mixins: [EntityIndexMixin],
    data() {
        return {
            activate: null
        }
    },
    async created() {
        this.entityType = 'payment-providers'
        await this.loadUserPreferences()

        this.userPreferences.filters = {}
        this.userPreferences.filters['cms_website_id'] = {
          prop: 'cms_website_id',
          compare: '=',
          value: this.isOnboarding ? this.websiteId : this.$store.state.system.scope.value
        }

        await this.loadData()
    },
    methods: {
        editPayment(id) {
            if (this.isOnboarding) {
                this.$emit('edit-payment', id)
            } else {
                this.$router.push(`/payment-providers/${id}`)
            }
        },
        async changePaymentStatus(payment) {
            payment.status = Number(!Number(payment.status))
            payment.pay_in_all_countries = 1
            this.activate = null
            await this.erp.ext.request.axiosInstance.put(`/modules/payment-providers/${payment.id}`, payment)
        }
    },
    props: {
        isOnboarding: {
            required: false
        },
        websiteId: {
            required: false,
        }
    }
}
</script>

<style scoped lang="scss">
.activate-container {
    position: absolute;
    top: 26px;
    left: 0;
    background: #fff;
    box-shadow: 0 1px 8px 3px #e6e6e6;
    z-index: 999;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
