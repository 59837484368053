import axios from 'axios'
import { environment } from "./helper"
import { SweetToast } from "@/utilities/sweetalert"

/*
* Create single axios instance for the whole project
*/

let axiosInstance = axios.create({
  // baseURL: environment.api_domain + 'api',
})


/*
* Chain request middlewares here
*/
axiosInstance.interceptors.request.use(config => {

  // If we dont have internet connection cancel the request
  if(!window.navigator.onLine){
    throw new axios.Cancel('Application is offline.')
  }

  return config


/*
* If axios has errors before request launch, alert them
*/
}, error => {
  // check for errorHandle config
  if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
    return Promise.reject(error)
  }

  // if has response show the error
  if (error.response) {
    SweetToast.error(error.response.data.message)
  }
})

/*
*  Extend axios and create caching functionality mentioned in task #1
*/
export const db = axiosInstance

/*
* Project routes
* Store all routes here for easier navigation
*/
export const routes = {
  oauth_token: '/oauth/token',
  tasks:{
    update: '/modules/tasks/:id',
  },
  //Users
  get_user_data: '/user-data',
  user: {
    filter: '/users',
    create: '/users',
    update: '/users/%id',
    delete: '/users/%id',
    show: 'users/%id',
    isDeletableAndEditable: '/users/actions/%id?action=all_actions',
    switchStatus: '/switch-status/entity/%id',
  },
  orders: {
    index: '/modules/sales/orders',
  },

  //Entities
  entities: {
    filter: '/entities',
    create: '/entities',
    update: '/entities/:id',
    delete: '/entities/:id',
    updatePermissions: '/entities/:id/permissions',
    updateRestrictions: '/entities/:id/properties/:propId/restrictions',
    updateProperty: '/property',
    getValidationRules: '/entities/validation-rules',
  },

  //Dropdowns
  dropdown: {
    filter: '/select-options',
    filterByModel: '/select-options/models/:model',
    create: '/select-options',
    update: '/select-options/:id',
    delete: '/select-options/:id',
  },

  //Tracker
  tracker: {
    stop: `modules/tracker/stop`,
  },
}
