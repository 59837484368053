<template>
  <div>
    <draggable :value="value"
               @input="e => $emit('input', e)"
               v-bind="dragOptions"
               @start="dragStart"
               @end="dragEnd"
    >
      <div v-show="value.length" v-for="(m, menuL) in value" :key="menuL + key" class="draggable-item pt-2">
        <div class="d-flex dragNdrop nav-link-title draggable-item-child align-items-center">
            <!-- DISABLED FOR NOW BECAUSE WE DON'T HAVE BULK ACTIONS
            <div class="col-md-1">
              <div class="custom-control custom-checkbox">
                <input type="checkbox"
                  class="my-auto custom-control-input"
                  v-model="value[menuL].selected"
                  :id="`option-${menuL}`"
                >
                <label class="custom-control-label" :for="`option-${menuL}`"></label>
              </div>
             </div>
            -->

          <div class="col-md-6">{{ m.name ? m.name : m.original_name }}</div>
          <div class="col-md-3 text-center font-weight-600">
            <div class="custom-control custom-switch mx-2">
              <input
                type="checkbox"
                v-model="m.visible_on_mobile"
                :true-value="1" :false-value="0"
                name="show"
                :id="`visibleOnMobile-${menuL}-${generateNum}`"
                class="custom-control-input"
                :value="m.visible_on_mobile"
              />
              <label
                :for="`visibleOnMobile-${menuL}-${generateNum}`"
                class="custom-control-label"
              ></label>
            </div>
          </div>
          <div class="col-md-3 text-right">
            <cta>
              <action-button class-icon="fa-edit" @click.native="editLink(menuL)"></action-button>
              <action-button class-icon="fa-trash" @click.native="removeLink(menuL)"></action-button>
            </cta>
          </div>
        </div>

        <LeftNavigation v-if="m.nav"
                        class="ml-4"
                        v-model="m.nav"
                        :parent="m"
                        @dragStart="$emit('dragStart')"
                        @dragEnd="$emit('dragEnd')"
        />
      </div>
    </draggable>

    <transition name="fade">
      <div v-if="!$store.state.system.dragInProgress && parent && parent.nav && parent.nav.length > 0" class="nav-link-title_add add-page-body mt-2" @click="addNewLink">
        <span class="mr-3"><i class="fa fa-plus-circle"></i></span>
        <span>{{ translate('Add custom link for', 'global') }} <b>{{ parent.name || parent.original_name }}</b></span>
      </div>
    </transition>

    <modal v-if="value"
           :name="`edit-link-${key}`"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="auto"
           :scrollable="true"
           :adaptive="true"
           classes="edit-link-modal remove-modal-height"
    >
      <edit-link :key="pageIndex" v-model="value[pageIndex]" @close="$modal.hide(`edit-link-${key}`)" :edit_key="key"></edit-link>
    </modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import EditLink from "./EditLink.vue"

export default {
  name: "LeftNavigation",
  components: {
    draggable,
    EditLink,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    parent: {
      required: false,
    },
  },
  data() {
    return {
      drag: false,
      left: [],
      pageIndex: null,
      key: Math.floor((Math.random() * 100) + 1)
    }
  },
  computed: {
    dragOptions() {
      return {
        draggable: ".draggable-item",
        group: "nav",
        //group: { name: 'nav', pull: 'clone', put: false },
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        dragClass: "draggable-ghost",
        handle: ".dragNdrop",
      }
    },
    generateNum() {
      return Math.floor((Math.random() * 100) + 1)
    },
  },
  methods: {
    // used to show/hide add new item rows
    dragStart(){
      this.$emit('dragStart')
    },
    dragEnd(){
      this.$emit('dragEnd')
    },
    // END used to show/hide add new item rows
    editLink(i) {
      this.pageIndex = i
      this.$modal.show(`edit-link-${this.key}`)
    },
    updateLink(v){
      this.$emit('input', this.value)
    },
    addNewLink() {
      this.$emit('input', [...this.value, { name: 'Custom link', entity: "", visible_on_mobile: 1, nav: [], extras: [] }])
    },
    removeLink(i) {
      this.value.splice(i, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  cursor: grabbing;
}

.draggable-item {

  .draggable-item-child {
    border: 1px solid #dedede;
    border-radius: 5px;
  }

  .dragNdrop {
    cursor: grab;
  }

  &.sortable-chosen {
    .nav-link-title {
      cursor: grabbing;
    }
  }

  .nav-link-title {
    background: #fff;
    padding: 1rem;
  }

  .nav-link-title_add {
    background: #cccccc69;
    color: #69a3f6;
    padding: 1rem;
    border: 1px solid $border-color;
    align-items: center;
    cursor: pointer;
    border-bottom: 0;

    .fa {
      font-size: 20px;
    }
  }

  .add-page-body {
    border: 1px solid #dedede;
    border-radius: 5px;
  }

  .btn-group{
    min-width: 50px;
  }
}
</style>
