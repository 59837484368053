import Reports from "@/modules/inventory/views/orders/Reports";
import OrdersIndex from '@/modules/inventory/views/orders/Index';
import ShowOrder from "@/modules/inventory/views/orders/ShowOrder";
import ShowInventory from "@/modules/inventory/views/inventory/ShowInventory";
import Payment from '@/modules/inventory/views/orders/Payment'

// Invoices
import InvoiceShowview from "@/modules/inventory/views/invoices/InvoiceShowview";
import InvoiceIndex from "@/modules/inventory/views/invoices/InvoiceIndex";
import InvoicePaymentTab from "@/modules/inventory/views/invoices/InvoicePaymentTab";
import InvoiceActionLogger from "@/modules/inventory/views/invoices/InvoiceActionLogger";
import InvoiceComments from "@/modules/inventory/views/invoices/InvoiceComments";
import InvoiceShowviewToLate from "@/modules/inventory/views/invoices/InvoiceShowviewToLate";
import InvoiceCreateEdit from "@/modules/inventory/views/invoices/InvoiceCreateEdit";

export default [

    {
        path: '/reports',
        name: 'sales-reports',
        component: Reports
    },
    // Orders routes
    {
        path: '/orders',
        name: 'orders',
        component: OrdersIndex,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/orders/:id',
        name: 'show-order',
        component: ShowOrder,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/entity/inventory-categories/:id',
        name: 'entity.inventory-categories.show',
        component: ShowInventory,
        meta: {
            interface: 'blockz'
        }
    },


    // Invoice routes

    {
        path: '/showview-send/:id',
        name: 'showview-send',
        component: InvoiceShowview,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/webshop-invoices',
        name: 'invoice-index',
        component: InvoiceIndex,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/invoice-payment',
        name: 'invoice-payment',
        component: InvoicePaymentTab,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/invoice-action',
        name: 'invoice-action',
        component: InvoiceActionLogger,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/invoice-comments',
        name: 'invoice-comments',
        component: InvoiceComments,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/invoice-showview-late',
        name: 'invoice-showview-late',
        component: InvoiceShowviewToLate,
        meta: {
            interface: 'blockz'
        }
    },

    {
        path: '/invoice-create',
        name: 'invoice-create',
        component: InvoiceCreateEdit,
        meta: {
            interface: 'blockz'
        }
    },
    {
        path: '/payment/:id',
        name: 'payment-preview',
        component: Payment,
        meta: {
            interface: 'skitle'
        }
    },
]
