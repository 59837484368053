<template>
  <!-- TODO only back button needs to be fixed -->
  <div>
    <payment-providers
      v-if="!payment_id"
      is-onboarding="true"
      :website-id="value.website_id"
      @edit-payment="editPayment"
    />

    <edit-payment-provider
      v-else
      :value="payment_id"
    >
      <!-- TODO
      <template>
        <button
          class="btn btn-white rounded-pill"
          @click="payment_id = null"
        >
          {{ translate('Go back', 'global') }}
        </button>
      </template>
       -->
    </edit-payment-provider>

  </div>
</template>

<script>
import PaymentProviders from "../../../sitecart/views/payment/PaymentProviders.vue"
import EditPaymentProvider from "../../../sitecart/views/payment/EditPaymentProvider.vue"

export default {
  name: "PaymentSettings",
  components: {
    PaymentProviders, EditPaymentProvider,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      payment_id: null,
    }
  },
  methods: {
    editPayment(id) {
      console.log('id')
      console.log(id)

      this.payment_id = id
    },
  },
}
</script>