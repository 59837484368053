<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <textarea
        :placeholder="translate(placeholder, 'page-builder')"
        @input="e => $emit('input', e.target.value)"
        cols="45"
        rows="5"
        class="form-control"
        :class="{'is-invalid': errors }"
    >{{value}}</textarea>
    <small class="help text-danger" v-if="errors" v-text="errors"></small>
  </div>
</template>

<script>
export default {
  name: "CraftTextarea",
  inject: ['editor'],
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: ''
    },
    fieldProps: {
      type: Object,
      required: false,
    }
  },
  computed: {
    errors() {
      return this.fieldProps && this.fieldProps.errors ? Object.values(this.fieldProps.errors).join(', ') : false
    },
  }
}
</script>

