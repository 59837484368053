<template>
  <div class="container app-entities-settings">
    <page-title :page-title="translate('Webshop settings', 'settings')">
      <template v-slot:actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div class="row" v-if="display_forms">

      <!-- Stock status -->
      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.stock_status = !visible.stock_status">
            <h5>{{ translate('Stock status', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.stock_status === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.stock_status" class="card-body">
              <entity-settings-form
                :value="stock_status_form"
                :defaultInputSize="12"
              />
            </div>
          </transition-expand>
        </div>
      </div>

      <!-- Related Products -->
      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.related_products = !visible.related_products">
            <h5>{{ translate('Related products', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.related_products === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.related_products" class="card-body">
              <entity-settings-form
                :value="related_products_form"
                :defaultInputSize="12"
              />
            </div>
          </transition-expand>
        </div>
      </div>

      <!-- Cart Management -->
      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div class="card-header d-flex justify-content-between align-items-center"
               @click="visible.cart_management = !visible.cart_management">
            <h5>{{ translate('Cart management', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.cart_management === true}"></i>
          </div>
          <transition-expand>
            <div v-show="visible.cart_management" class="card-body">
              <entity-settings-form
                :value="cart_management_form"
                :defaultInputSize="12"
              />
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import ErpModel from "@/modules/erp_framework/services/ErpModel"
import { createEntityFormObjectNoTabs } from "@/utilities/helper"
import { createSettingsForm } from "@/utilities/helpers/websiteSettings"
import EntitySettingsForm from "@/modules/erp_entities/components/page/form/EntitySettingsForm.vue"
import { environment } from "@/modules/erp_entities/utilities/helper"

export default {
  name: 'WebshopSettings',
  components: {
    EntitySettingsForm,
  },
  data() {
    return {
      website: null,
      display_forms: false,
      visible: {
        stock_status: true,
        related_products: true,
        cart_management: true,
      },
      stock_status_form: [],
      related_products_form: [],
      cart_management_form: [],
    }
  },
  methods: {
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },
    async getStockStatusSettings(){
      this.$store.state.system.isLoading = true;
      await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}/webshop-settings-cmswebsite/stock_display`).then((res) => {
        this.stock_status_form = createSettingsForm(res)
        this.$store.state.system.isLoading = false
      })
    },
    async getRelatedProductsSettings(){
      this.$store.state.system.isLoading = true;
      await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}/webshop-settings-cmswebsite/related_products`).then((res) => {
        this.related_products_form = createSettingsForm(res)
        this.$store.state.system.isLoading = false
      })
    },
    async getCartManagementSettings(){
      this.$store.state.system.isLoading = true;
      await this.erp.ext.request.axiosInstance.get(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}/webshop-settings-cmswebsite/cart_management`).then((res) => {
        this.cart_management_form = createSettingsForm(res)
        this.$store.state.system.isLoading = false
      })
    },
    async save() {
      this.$store.state.system.isLoading = true
      let stock_status_data = createEntityFormObjectNoTabs(this.stock_status_form, 'name', 'value', false)
      let related_products_data = createEntityFormObjectNoTabs(this.related_products_form, 'name', 'value', false)
      let cart_management_data = createEntityFormObjectNoTabs(this.cart_management_form, 'name', 'value', false)

      let merged_data = {}
      Object.assign(merged_data, stock_status_data, related_products_data, cart_management_data)

      let data = {
        options: merged_data,
        model: 'cms-websites',
        modelId: this.$store.state.system.scope.value,
        settingsSlug: 'webshop-settings-cmswebsite',
      }

      environment.request.post(`/modules/settings/cms-websites/${this.$store.state.system.scope.value}`, data).then(response => {
        this.$store.state.system.isLoading = false
        this.$toast.requestSuccess('put', 'Website settings') // explicit put
      }).catch(error => {
        this.$store.state.system.isLoading = false
        console.log(error)
      })
    },
  },
  async created() {
    this.$store.state.system.isLoading = true
    await this.getStockStatusSettings()
    await this.getRelatedProductsSettings()
    await this.getCartManagementSettings()

    this.display_forms = true
    this.$store.state.system.isLoading = false
  },
}
</script>

<style lang="scss">
.disable-v-select {
  .vs__dropdown-toggle {
    height: auto !important;
    border: 0;
    background-color: #fff !important;

    input {
      background-color: #fff !important;
    }

    .vs__actions, .vs__deselect {
      display: none;
    }
  }
}

#note {
  font-weight: 600;
}

#drop_file {
  border: 2px dotted grey;
  border-radius: 2px;
}

.cont_personEmail {
  color: grey;
  font-weight: 500;
  word-wrap: break-word;
}

.settings-input {
  background-color: #fff;
  border: none;

  &:focus {
    outline: none;
  }
}

.border-transparent {
  border: 1px solid transparent;
}

input.rounded {
  border-radius: 50rem !important;
}

.client-info-row {
  border-top: 1px solid #e5edf5;
}

.client-info-row.last{
  border: none;
  border-top: 1px solid #e5edf5;
}

.input-container {
  padding: 1rem;
}

.container-title {
  margin-top: 0.5rem;
}

div.border-right {
  border-right: 1px solid #e5edf5 !important;
}
.top-save-btn{
  position: absolute;
  right: 0;
  top: -5rem;
}
.save-btn{
  text-align: center;
  justify-content: center;
}

.card-header {
  h5 {
    color: #000;
    font-size: 1.3rem;
    font-weight: 700;
  }
}
</style>
