<template>
	<!-- <div class=""> -->
	<div class="row" v-if="subscriptions">
<!--		<div class="col-md-12">-->
<!--			<div class="input-group w-100 input-group-lg page-builder-modal-input">-->
<!--				<input type="text" class="form-control"-->
<!--					   :placeholder="translate('global.search_placeholer')">-->
<!--				<div class="input-group-append">-->
<!--					<span class="input-group-text"><i class="fa fa-search"></i></span>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		<div class="col-md-12 mt-3">
			<h6 class="font-weight-700 mb-0">{{ translate('Active', 'account') }}</h6>
			<hr>
		</div>
		<div class="col-md-12" v-if="subscriptions && subscriptions.data && subscriptions.data.length">
			<div class="d-flex flex-column bg-white border rounded mt-3">
                <div class="table-responsive">
                </div>
                <div class="card m-0">
                    <table class="table">
                        <thead class="thead-light">
                        <tr>
                            <th class="font-weight-700">{{ translate('Subscriptions', 'account') }}</th>
                            <th class="font-weight-700">{{ translate('Price', 'account') }}</th>
                            <th class="font-weight-700">{{ translate('Yearly price', 'account') }}</th>
                            <th class="font-weight-700">{{ translate('Trial end', 'account') }}</th>
                            <th class="font-weight-700">{{ translate('Status', 'account') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="border-bottom" v-for="(sub, si) in subscriptions.data" >
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.plan_options.name }}</span>
                                    <small class="text-muted">{{ sub.plan.inventory_type }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.price }} {{ sub.plan_options.currency }}</span>
                                    <small class="text-muted">{{ sub.cycle_start }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.yearly_price }} {{ sub.plan_options.currency }}</span>
                                    <small class="text-muted">{{ sub.plan_options.payment_terms }} {{ translate('years', 'account') }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.trial_end }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span v-if="sub.status == 1" class="font-weight-700">
                                        {{ translate('Active', 'account') }}
                                    </span>
                                    <span v-if="sub.status == 0" class="font-weight-700">
                                        {{ translate('Inactive', 'account') }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
<!--        <div class="col-md-12 mt-3">-->
<!--            <h6 class="font-weight-700 mb-0">Expired</h6>-->
<!--            <hr>-->
<!--        </div>-->
    </div>
</template>
<script>
import moment from 'moment'

export default {
	data() {
		return {
			subscriptions: null,
			loading: false
		}
	},
	async created() {
		this.$store.state.system.isLoading = true
		let subscriptions = await this.erp.ext.request.axiosInstance.get('/modules/financial/subscriptions?perpage=5&column=created_at&direction=desc',
			{
				headers: {
					company: this.$route.params.id
				}
			})
		subscriptions.data.data.forEach(rec => {
			// rec.plan ? rec.plan_options = rec.plan.options.optionsToObject() : rec.plan
			rec.trial_end_formated = moment(rec.trial_end).format('YYYY-MM-DD')
			rec.cycle_start_formated = moment(rec.cycle_start).format('YYYY-MM-DD')
		})
		this.subscriptions = subscriptions.data
		this.$store.state.system.isLoading = false
	},
}
</script>
<style scoped>

</style>
