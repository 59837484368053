import english from './en/language'
import bulgarian from './bg/language'
import netherlands from './nl/language'

export default {
  meta: {
    default: 'nl',
    fallback: 'en',
  },
  en: english,
  nl: netherlands,
}
