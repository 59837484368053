<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Images', 'page-builder')">
        <div class="row">
          <span class="add-new-image" @click="$fileManager.$emit('open', `element_gal`)">
            <i class="fa icon-erp-add mr-2"></i>{{ translate('Add image', 'page-builder') }}
          </span>
        </div>
        <draggable
          v-model="items"
          @start="drag = true; selected = null;"
          @end="dragEnd"
          v-bind="dragOptions"
          draggable=".draggable-item"
          class="row"
        >
          <template v-for="(item, itemIndex) in items">
            <div :key="itemIndex" class="col-6 float-left draggable-item">
              <span class="add-image _images" :class="selected === itemIndex ? 'active' : ''">
                <img class="gallery-image" :src="item.src" :alt="item.alt" :title="item.title"
                     @click="selected === itemIndex ? selected = null : selected = itemIndex">
                <span class="position-span">{{ item.position + 1 }}</span>
                <span class="remove-image" @click="removeItem(itemIndex)"><i class="icon-erp-trash"></i></span>
              </span>
            </div>
            <div v-if="showItemSettings(itemIndex)" class="col-12">
              <div class="form-group">
                <craft-input
                  label="Alt"
                  v-model="items[selected].alt"
                />
              </div>
              <div class="form-group">
                <craft-input
                  label="Title"
                  v-model="items[selected].title"
                />
              </div>
            </div>
            <template v-else></template> <!-- do not remove this line, without it draggable makes problems -->
          </template>
        </draggable>
      </element-settings-content-tab>
      <element-settings-content-tab :name="translate('Layout', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
              label="Items per row"
              input-type="range"
              min="1"
              max="16"
              :value="getSetting('items_per_row')"
              @input="setSetting('items_per_row', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
              label="Spacing"
              input-type="range"
              min="0"
              max="64"
              :value="getSetting('gap', 'px')"
              @input="setSetting('gap', $event, 'px')"
              suffix="PX"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ComponentWithItemsMixin from "@/modules/pagebuilder/mixins/ComponentWithItemsMixin"

import CraftInput from "@/modules/pagebuilder/components/craft-inputs/CraftInput.vue"
import { isVoidValue } from "@/utilities/helper"

export default {
  name: "GallerySettings",
  components: {
    CraftInput,
  },
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, ComponentWithItemsMixin],
  data() {
    return {
      columnIsSet: {key: '0 0 25%', value: '4'},
      lightbox: false,
      items: [],
      selected: null,
    }
  },
  mounted() {
    this.$fileManager.$on(`selected_element_gal`, imgs => {

      imgs.forEach(src => {
        let image = {
          src: src,
          position: this.items.length,
          alt: '',
          title: '',
        }
        this.items.push(image)
      })
    })

    if(this.items.length < 1) {
      for (let i = 1; i < 9; i++) {
        let image = {
          src: "/uploads/defaults/cms-block-gallery/" + i + ".png",
          position: this.items.length,
          alt: '',
          title: '',
        }
        this.items.push(image)
      }
    }
  },
  // on vue 3, replace with beforeUnmount
  beforeDestroy() {
    this.$fileManager.$off('selected_element_gal')
  },

  methods: {
    showItemSettings(index) {
      if (isVoidValue(this.selected)) {
        return false
      }

      return this.selected - (this.selected % 2) === index - 1 // 0/1 = 0, 2/3 = 2, 4/5 = 4, etc
        || (this.selected === this.items.length - 1 && this.selected === index) // last item
    },
  },
}
</script>

<style scoped lang="scss">
.add-new-image {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin-left: 1.5rem; /* same as .card */
  margin-bottom: 1.25rem; /* same as .card */
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
}

.add-image {
  height: 95px;
  width: 100%;
  border: 2px solid $border-color;
  border-radius: .375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 1rem 0;
  overflow: hidden;

  &.active {
    border: 2px solid $primary;
  }

  &._images {
    position: relative;

    .gallery-image {
        width: 100%;
    }

    .remove-image {
      width: 25px;
      height: 25px;
      text-align: center;
      position: absolute;
      top: 3px;
      right: 5px;
      font-size: 12px;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;
      cursor: pointer;
      background: white;
      padding: 4px;
      border-radius: 50%;
    }

    &:hover {
      .remove-image {
        opacity: 1;
        visibility: visible;
      }
    }
    i {
      color: black
    }

    .position-span {
      width: 25px;
      height: 25px;
      text-align: center;
      position: absolute;
      top: 3px;
      left: 5px;
      font-size: 12px;
      cursor: pointer;
      background: white;
      padding: 4px;
      border-radius: 50%;
      color: black;
    }
  }
}
</style>
