<template>
    <div class="footer p-3" id="footer-main">
        <div class="row text-center text-sm-left align-items-sm-center">
            <div class="col-sm-12 col-md-12 d-flex align-items-center justify-content-center">
                <p class="text-sm mb-0">
                  <img
                    :alt="translate('Logo', 'global')"
                    :src="`/img/themes/${$store.state.system.theme}/logo-footer.png`"
                    height="18"
                    class="align-middle mr-2"
                    :title="websiteAndCompanyId"
                  >
                  <span>
                    &copy;
<!--                    {{ translate('Copyright', 'global') }}-->
                    {{ new Date().getFullYear() }} - {{ translate('All rights reserved', 'global') }}
                    -
                    <template v-if="$store.state.system.theme === 'rock'">
                      Rock 2.0 Client Volg Systeem
                    </template>
                    <template v-if="$store.state.system.theme === 'trucks'">
                      Trucks.nl B.V.
                    </template>
                    <template v-else>
                      {{ $store.state.system.interface ? $store.state.system.interface.charAt(0).toUpperCase() + $store.state.system.interface.slice(1) : '' }}
                    </template>
                    <span
                      v-if="$store.state.system.theme !== 'trucks'"
                      class="report-a-bug d-print-none"
                      @click="$modal.show('report-a-bug')"
                    >{{ translate('Report a bug', 'global')}}</span>
                  </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "MainFooter",
  computed: {
    websiteAndCompanyId() {
      let result = ''

      if(this.$store.getters["system/isAdmin"] && this.$store.state.system.company) {
        result += `company: ${this.$store.state.system.company.key}`
        if(this.$store.state.system.scope && this.$store.state.system.scope.value){
          result += `, website: ${this.$store.state.system.scope.value}`
        }
      }
      return result
    },
  },
}
</script>