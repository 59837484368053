<template>
  <div>
    <div class="py-2">
      <div class="custom-control custom-switch">
        <input
          :id="`option-${fieldProps.name}-${entityLabel(fieldProps.label)}`"
          v-model="value"
          @change="$emit('input', value)"
          type="checkbox"
          name=""
          class="custom-control-input"
          :true-value="1"
          :false-value="0"
          :disabled="isDisabled"
          @click="confirmEdit"
        >
        <label
          :for="`option-${fieldProps.name}-${entityLabel(fieldProps.label)}`"
          class="custom-control-label"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "CheckboxField",
  mixins: [FormFieldMixin],
  computed: {
    isDisabled() {
      return !this.canWrite
    },
  },
}
</script>
