<template>
    <div>
        <tabs>
            <tab name="navigation" :selected="true">
<!--                 <navigation></navigation>-->
            </tab>
            <tab name="design_options">
<!--                 <design></design>-->
            </tab>
        </tabs>
    </div>
</template>

<script>
    // import Navigation from "@/modules/pagebuilder/components/inputs/navigation/Navigation";
    // import Design from "@/modules/pagebuilder/components/inputs/design/Design";

    export default {
        name: "WebsiteShow",
        components: {
            // Navigation, Design
        }
    }
</script>

<style scoped>

</style>
