export default {
    state: {
        refreshToken: null,
        authData: null,
        locales: null,
        timeZones: null,
        roles: null,
        organizations: null,
        services: null
    },
    actions: {
        async authenticate({state}, {username, password}){
            let authUser = await db.post(routes.oauth_token, {
                client_secret: environment.client_secret,
                client_id: environment.client_id,
                grant_type: environment.grant_type,
                username: username,
                password: password
            })

            // Set bearer token and authenticate the user
            db.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
            this.$store.state.auth.refreshToken = response.data.refresh_token
        }
    },
    namespaced: true
}
