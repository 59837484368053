<template>
  <div class="add-locale-wrapper">

    <div class="card">
      <div class="card-header" style="display:flex">
        <h6 class="font-weight-bold">
          <span>{{ translate('Add languages', 'settings') }}</span>
        </h6>
      </div>

      <div class="card">
        <div class="col-md-12">
          <div class="col-style">

              <ul class="list-group mt-2 mb-2">
                <li class="list-group-item row d-flex justify-content-between align-items-center" v-for="(locale, index) in Object.keys(allLocalesObject)"
                    :key="index">
                  <div class="col-md-1">
                    <img width="40" height="40" class="rounded-circle" :src="'/img/flags/locales/1x1/'+locale+'.svg'"
                         :alt="allLocalesObject[locale].name">
                  </div>
                  <div class="col-md-9">
                    <span
                        :class="{'text-muted' : website_locales[locale]}"
                        class="font-weight-400"
                    >
                      {{ allLocalesObject[locale].name }}
                    </span>
                  </div>
                  <div class="col-md-1">
                    <a href="#"
                       class="text-black float-right"
                       v-if="!website_locales[locale]"
                       @click="addLocale(locale)"
                    >
                        <i class="icon-erp-plus icon-xl p-2 mt-1 font-weight-700 border-bold-circle"></i>
                    </a>
                  </div>
                </li>
              </ul>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AddLocale",
  props: {
    allLocalesObject: {
      required: true
    },
    website_locales: {
      required: true
    },
  },
  methods:{
    addLocale(locale) {
      this.$emit('localeChosen', locale)
      this.$emit('hideModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.add-locale-wrapper {
  height: 600px;
  overflow-y: scroll;
  font-weight: bold;
  background-color: white;
  border-radius: 8px;

  .list-group {
    .list-group-item {
      color: #000;
      border-bottom: 1px solid $border-color;
    }
  }
}

.border-bold-circle {
  border: 2px solid #000;
  border-radius: 50rem;
}

.text-black {
  color: #000;
}
</style>
