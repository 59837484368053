/*
 * Custom groups and fields used to be added to all products
 *
 * TODO: Leave only the required fields. Currently all fields are just copied from existing field
 *
**/

// variations[0] - configurable_attributes

export function presetProductConfigurableAttributes() {
  return {
    actions: {
      create: true,
      update: true,
      delete: true,
      locked: false
    },
    attribute_location: null,
    attribute_location_order: "0",
    create_new_data_option: "0",
    created_at: "2022-05-17T00:00:00.000000Z",
    data: null,
    data_module: null,
    data_type: null,
    default: "1",
    description: null,
    entity_id: "47",
    errors: null,
    filterable: "0",
    group_by: "variations",
    // id: 0,
    label: {nl: 'Variations', en: 'Variations'},
    multiply: null,
    multiply_group: null,
    name: "configurable_attributes",
    order: "30",
    pivot: {
      // inventory_attribute_set_id: '11',
      // entity_property_id: '762',
      group_by: 'variations',
      group_name: 'variations',
      required: '0'
    },
    requires_attribute: "0",
    rules: [],
    search_weight: "0",
    searchable: "0",
    shortcodeable: "0",
    system_attribute: "1",
    table_name: "inventory_text",
    translatable: "0",
    type: "product_configurable_attributes",
    updated_at: "2022-04-16T00:46:29.000000Z",
    value: [],
    visible_in_table: "0",
    webshop_filter_type: null,
    webshop_filterable: "0",
    webshop_filters_order: "0",
    width: "12"
  }
}

// variations[1] - variations
export function presetProductVariations() {
  return {
    actions: {
      create: true,
      update: true,
      delete: true,
      locked: false
    },
    attribute_location: null,
    attribute_location_order: "0",
    create_new_data_option: "0",
    created_at: "2022-05-17T00:00:00.000000Z",
    data: null,
    data_module: null,
    data_type: null,
    default: "1",
    description: null,
    entity_id: "47",
    errors: null,
    filterable: "0",
    group_by: "variations",
    // id: 0,
    label: {nl: 'Variations', en: 'Variations'},
    multiply: null,
    multiply_group: null,
    name: "variations",
    order: "29",
    pivot: {
      // inventory_attribute_set_id: '11',
      // entity_property_id: '762',
      group_by: 'variations',
      group_name: 'variations',
      required: '0'
    },
    requires_attribute: "0",
    rules: [],
    search_weight: "0",
    searchable: "0",
    shortcodeable: "0",
    system_attribute: "1",
    table_name: "inventory_text",
    translatable: "0",
    type: "product_variations",
    updated_at: "2022-04-16T00:46:29.000000Z",
    value: [],
    visible_in_table: "0",
    webshop_filter_type: null,
    webshop_filterable: "0",
    webshop_filters_order: "0",
    width: "12"
  }
}