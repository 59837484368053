import moment from 'moment'
import request_service from "../request_service";

export default class ApiAuthorization {

    constructor()
    {
        this._refreshToken = null
        this._authorizationToken = null
        this._expires = null
        this.request = request_service.axiosInstance
    }

    async getTokenFromApi(username = null, password = null)
    {
        if(!username && !password){
            username = process.env.VUE_APP_USERNAME
            password = process.env.VUE_APP_PASSWORD
        }

        const tokenRes = await this.request.post('oauth/token', {
            client_secret: (process.env.VUE_APP_CLIENT_SECRET || process.env.MIX_APP_CLIENT_SECRET),
            client_id: (process.env.VUE_APP_CLIENT_ID || process.env.MIX_APP_CLIENT_ID),
            grant_type: 'password',
            username: username,
            password: password
        })

        this.request.defaults.headers.common['company'] = tokenRes.data.company_id

        this.refreshToken = tokenRes.data.refresh_token
        this.authorizationToken = tokenRes.data.access_token
        this.expires = tokenRes.data.expires_in
        
        return this.tokens 
    }

    async refreshApplicationToken()
    {
        const tokenRes = await this.request.post('oauth/token', {
            client_secret: (process.env.VUE_APP_CLIENT_SECRET || process.env.MIX_APP_CLIENT_SECRET),
            client_id: (process.env.VUE_APP_CLIENT_ID || process.env.MIX_APP_CLIENT_ID),
            grant_type: 'refresh_token',
            refresh_token: this.refreshToken
        })
        
        this.refreshToken = tokenRes.data.refresh_token
        this.authorizationToken = tokenRes.data.access_token
        this.expires = tokenRes.data.expires_in
        
        return this.tokens 
    }

    // GETTERS

    get tokens()
    {
        return {
            authorizationToken: this._authorizationToken,
            refreshToken: this._refreshToken
        };
    }

    get authorizationToken()
    {
        return {
            raw: this.authorizationToken,
            bearer: `Bearer ${this.authorizationToken}`
        };
    }

    get refreshToken()
    {
        return this._refreshToken;
    }

    get expires()
    {
        return {
            time: this._expires,
            readable: moment(this._expires).fromNow(),
            expired: false 
        }
    }

    // SETTERS

    set authorizationToken(token){
        this._authorizationToken = token
        localStorage.setItem('authorizationToken', token)
        this.request.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        if(!token) localStorage.removeItem('authorizationToken')
        
        let company = window.localStorage.getItem('company')
        if (company) {
            company =  JSON.parse(company)
            this.request.defaults.headers.common['company'] = window.__cmpny || company.key
        }
    }

    set refreshToken(token)
    {
        this._refreshToken = token
        localStorage.setItem('refreshToken', token)
        if(!token) localStorage.removeItem('refreshToken')
    }

    set expires(secondsFromNowToExpire)
    {
        let tokenRetrievedTime = moment()
        tokenRetrievedTime.add(secondsFromNowToExpire, 'seconds')
        this._expires = tokenRetrievedTime.format()
    }
}
