import { loadSessionData } from "../../utilities/auth"
import { db } from '../../utilities/database'
import { createEntityFormObjectNoTabs } from "../../utilities/helper"
import moment from 'moment'
import { erp } from '@/modules/erp_framework'

export default {
  state: {
    website: null,// todo remove or rename
    authData: null,
    isLoading: false,
    dragInProgress: false, // using state because passing props is bad for performance in navigation
    locales: null,
    locale: 'en',
    translation_locale: 'en',
    companies: null,
    roles: null,
    timeZones: null,
    modules: null,
    todayEvents: null,
    layout: 'master',
    company_logo: null,
    theme: null,
    scope: null,
    interface: 'skitle',
    company: null,
    currentEntity: null,

    impersonate: 'false',

    entitiesPermissions: null,
    moduleNames: [],
    fileManagerKey: 1, // used just to rerender the popup file manager
  },

  actions: {
    async setupStore({ commit }) {
      commit('setEntitiesAndModuleNames')
      commit('setRoles')
    },

    /**
     * System authentication passing either username/pass or bearer token
     *
     * @param {*} {username = null, password = null, token = null}
     */

    async authenticate({ commit }, { username = null, password = null, token = null }) {
      await erp.auth.authenticate(username, password, token)

      // Return auth user data and system information
      let sessionData = await loadSessionData()
      Promise.resolve(sessionData)
    },

    /* NOT USED CURRENTLY
    async watchForUpcomingEvents({state, dispatch, getters}) {

        if (getters.isAuthenticated) {

            async function loadEventsAndNotify() {

                let todayEvents = await db.get(`modules/events?page=1`)

                state.todayEvents = todayEvents.data.data.map(ev => ev = createEntityFormObjectNoTabs(ev.options, 'key'))


                // Watch for upcoming events and notify
                state.todayEvents.forEach(event => {

                    let timeUntilEvent = moment.duration(moment(event.start).diff(moment.now()))

                    if (timeUntilEvent.asMinutes() > 5 && timeUntilEvent.asMinutes() < 10) {
                        new Notification(`You have event in ${Math.round(timeUntilEvent.asMinutes())}  minutes`, {
                            body: 'Click on the notification to preview the event',
                            icon: 'https://api.erp.webcode.bg/images/users/avatar/1/1BNGvwlG_1583849023.png'
                        })
                    }

                    if (timeUntilEvent.asMinutes() > -5 && timeUntilEvent.asMinutes() < 2) {
                        window.sw.showNotification(event.title + ' has started', {
                            body: 'Select your action',
                            actions: [
                                {
                                    action: 'track',
                                    title: 'Start tracking'
                                },
                                {
                                    action: 'close',
                                    title: 'ok'
                                }
                            ],
                            icon: 'https://api.erp.webcode.bg/images/users/avatar/1/1BNGvwlG_1583849023.png'
                        })
                        trackingNotification.onclick = function () {
                            dispatch('tracker/track', {
                                type: 'event',
                                data: event
                            }, {root: true})
                            dispatch('tracker/startTracking', {}, {root: true})
                        }.bind(this)
                    }
                })
            }

            setTimeout(async function () {
                loadEventsAndNotify()
            }, 1000);

            setInterval(async function () {
                loadEventsAndNotify()
            }.bind(this), 5 * 60 * 1000);
        }
    },
    */

    fetchWebsite: async ({ commit }) => {
      // const website = await erp.ext.request.find('cms-websites', id)
      const website = await db.get('modules/menus')
      commit('setWebsite', website)
    },

    async saveWebsite({ state }) {
      let websiteData = { ...state.website.objectData }
      delete websiteData.id
      await erp.ext.request.axiosInstance.put('modules/cms-websites/' + state.website.objectData.id, {
        options: websiteData,
      })
    },

    /**
     *
     * @param { 'login_success'| 'login_fail'| 'logout_success' } type
     * @param {string} email
     * @returns {Promise<void>}
     */
    async saveAuthenticationLog({ state }, { type, email }) {
      await erp.ext.request.axiosInstance.post(`/modules/authentication-logs/${type}/${email}`)
    },
  },

  mutations: {
    setEntitiesAndModuleNames(state) {
      state.moduleNames = []
      let entities = {}

      erp.ext.request.axiosInstance.get('entities', { params: { 'perpage': 9999 } }).then(res => {
        res.data.data.forEach(entity => {
          let { model, name } = entity
          entities[entity.slug] = { model, name }

          if (!state.moduleNames.some(module => module.key === model)) {
            state.moduleNames.push({ key: model, label: name })
          }
        })

        localStorage.setItem('entities', JSON.stringify(entities))
      })
    },

    setRoles(state) {
      state.roles = []
      erp.ext.request.axiosInstance.get('modules/dropdowns/role', { params: { 'perpage': 9999 } }).then(res => {
        state.roles = res.data.data
      })
    },

    setWebsite: (state, website) => {
      state.website = null
      // Correct data coming from API
      // if(!website.objectData.navigation) website.objectData.navigation = []
      // if(website.objectData.navigation && typeof website.objectData.navigation == 'string') website.objectData.navigation = JSON.parse(website.objectData.navigation)
      // if(!website.objectData.sidebar_navigation) website.objectData.sidebar_navigation = []
      // if(website.objectData.sidebar_navigation && typeof website.objectData.sidebar_navigation == 'string') website.objectData.sidebar_navigation = JSON.parse(website.objectData.sidebar_navigation)
      // state.website = website
      // let headData = website.data.data.filter(el => el.name == "Header navigation").reverse();
      // let sideData = website.data.data.filter(el => el.name == "Sidebar navigation").reverse();
      // if (sideData.length > 0) {
      //     website.sidebar_navigation = sideData[0].navigation
      //     website.sidebar_id = sideData[0].id
      // } else {
      //     website.sidebar_navigation = []
      // }
      // if (headData.length > 0) {
      //     website.header_navigation = headData[0].navigation
      //     website.navbar_id = headData[0].id
      // } else {
      //     website.header_navigation = []
      // }
      state.website = website
    },

    setCompany: (state, company) => {
      state.company = company
    },

    setImpersonate(state) {
      let impersonate = window.localStorage.getItem('impersonate')
      if (!impersonate) {
        state.impersonate = 'false'
      }
      state.impersonate = impersonate
    },

    rerenderFileManager(state){
      state.fileManagerKey++
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.authData,
    isAdmin: (state) => state.authData ? state.authData.roles.some(role => role.value === "admin") : false,
    auth: (state) => state.authData,
    canSwitchInterface: (state) => state.authData ? state.authData.roles.some(role => role.slug == 'company_manager' || role.slug == 'sales' || role.slug == 'admin') && state.company ? state.company.key === 1 : false : false,
    hasWebsite: state => state.scope?.data?.length > 0,
  },

  namespaced: true,
}
