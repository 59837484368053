<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">
        <div class="row">
          <div class="col-12">
            <craft-select
                label="Choose grid layout"
                :options="rowOptions"
                :value="getSetting('grid_layout')"
                @input="setSetting('grid_layout', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
                label="Column gap"
                :input-type="'range'"
                :suffix="'PX'"
                :min="0"
                :max="100"
                :has-responsive-styles="true"
                :placeholder="getPlaceholder('column-gap', 'px')"
                :value="getStyle('column-gap', 'px')"
                @input="setStyle('column-gap', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-input
                label="Height (leave empty for auto)"
                :input-type="'number'"
                :suffix="'PX'"
                :has-responsive-styles="true"
                :placeholder="getPlaceholder('height', 'px')"
                :value="getStyle('height', 'px')"
                @input="setStyle('height', $event, 'px')"
            />
          </div>

          <div class="col-12">
            <craft-select
              label="Vertical align"
              :options="generalVerticalAlignmentOptions"
              :value="getStyle('align-items')"
              :has-responsive-styles="true"
              :placeholder="getPlaceholder('align-items' )"
              @input="setStyle('align-items', $event)"
            />
          </div>

          <div class="col-12">

            <h6 class="card-header d-flex align-items-center px-0" @click="visible['responsive'] = !visible['responsive']">
              <span>{{ translate('Responsive', 'page-builder') }}</span>
              <i
                class="fa ml-auto fa-chevron-down chevron"
                :class="{'chevron-rotate': visible['responsive']}"
              />
            </h6>

            <transition-expand>
              <div v-show="visible['responsive']">
                <div class="card-body px-0" :class="{'snippets-holder' : visible['responsive']}">
                  <craft-toggle
                    :label="'Reverse columns - Tablet'"
                    :id="'reverse_on_tablet'"
                    :value="getSetting('reverse_columns_tablet')"
                    @input="setSetting('reverse_columns_tablet', $event)"
                  />
                  <craft-toggle
                    :label="'Reverse columns - Mobile'"
                    :id="'reverse_on_mobile'"
                    :value="getSetting('reverse_columns_mobile')"
                    @input="setSetting('reverse_columns_mobile', $event)"
                  />
                </div>
              </div>
            </transition-expand>

          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import { CraftInput, CraftSelect, CraftToggle } from "@/modules/pagebuilder/components/craft-inputs"
import {createNodeFromComponentName} from "@/modules/pagebuilder/craft/utils/createNodeFromVNode";

export default {
  name: "RowSettings",
  mixins: [ComponentSettingsMixin, CraftSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor', 'node'],
  data(){
    return {
      visible: {
        responsive: false,
      },
    }
  },
  components: {
    CraftInput, CraftSelect, CraftToggle,
  },
  computed: {
    rowOptions() {
      return [
        {key: '12', label: '1'},
        {key: '6-6', label: '1/2 + 1/2'},
        {key: '4-4-4', label: '1/3 + 1/3 + 1/3'},
        {key: '3-3-3-3', label: '1/4 + 1/4 + 1/4 + 1/4'},
        {key: '2-2-2-2-2-2', label: '1/6 + 1/6 + 1/6 + 1/6 + 1/6 + 1/6'},
        {key: '4-8', label: '1/3 + 2/3'},
        {key: '8-4', label: '2/3 + 1/3'},
        {key: '3-9', label: '1/4 + 3/4'},
        {key: '9-3', label: '3/4 + 1/4'},
        {key: '2-10', label: '1/6 + 5/6'},
        {key: '10-2', label: '5/6 + 1/6'},
        {key: '3-6-3', label: '1/4 + 1/2 + 1/4'},
        {key: '2-8-2', label: '1/6 + 2/3 + 1/6'},
        {key: '3-3-6', label: '1/4 + 1/4 + 1/2'},
        {key: '6-3-3', label: '1/2 + 1/4 + 1/4'},
        {key: '2-2-8', label: '1/6 + 1/6 + 2/3'},
        {key: '8-2-2', label: '2/3 + 1/3 + 1/3'},
        {key: '1-1-1-9', label: '1/12 + 1/12 + 1/12 + 3/4'},
        {key: '9-1-1-1', label: '3/4 + 1/12 + 1/12 + 1/12'},
      ]
    },
    selectedRow() {
      return this.node
    },
    hasColumns() {
      return this.selectedRow.children.some((child) => this.isColumn(child));
    },
  },

  methods: {
    isColumn(node) {
      return node.props.component === 'ColumnElement'
    },
    rearrangeRowLayout(gridCols) {
      const columns = this.selectedRow.children.filter((child) => {
        return this.isColumn(child);
      });

      gridCols.forEach((width, idx) => {
        // keep existing column if any
        if(columns[idx]){
          columns[idx].props.settings.width = width
        // otherwise create new
        } else {
          const column = createNodeFromComponentName(this.editor,'ColumnElement')
          column.props.settings.width = width
          this.selectedRow.append(column);
        }
      });

      /*
       * on reducing the number of columns remove the last ones
       * and move their content in new last column
       */
      const lengthDifference = columns.length - gridCols.length;
      if (lengthDifference > 0) {

        // the last column after rearrange
        const newLastColumn = columns[gridCols.length - 1];

        // content outside
        columns.slice(gridCols.length).forEach((col) => {
          col.children.forEach((child) => {
            newLastColumn.append(child);
          });
        });

        // remove the rest of the columns
        for(let i=0; i<lengthDifference; i++){
          this.editor.removeNode(columns[gridCols.length + i])
        }
      }
    },

    modifyRowLayout(gridCols) {
      this.rearrangeRowLayout(gridCols);
    },

  },

  watch: {
    'elementProps.settings.grid_layout': {
      deep: true,
      handler(value) {
        let propVal = value.split('-') // convert the key to array 10-2 = [10,2]
        this.modifyRowLayout(propVal);
      }
    }
  },

};
</script>