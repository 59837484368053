<template>
    <div class="container-fluid container-application app-erp" v-if="$store.state.system.authData">

        <sidebar></sidebar>
        <div class="main-content position-relative" :class="{'full-width-main': $store.state.system.sidebar == false}">
            <div class="topbar-head-warning" v-if="$store.state.system.impersonate == 'true'">
                <div class="topbar-wrap">
                    {{ translate('Impersonate mode', 'global') }}
                    <button class="btn btn-impersonate" @click="unimpersonate">{{ translate('Leave impersonate', 'global') }}</button>
                </div>
            </div>
            <navbar></navbar>

            <v-infinite-progressbar v-if="isLoading"></v-infinite-progressbar>
            <div class="page-content">
                <router-view/>
            </div>

            <main-footer></main-footer>
        </div>

        <contact-sales-modal />

        <modal name="report-a-bug"
          :pivot-x="0.5"
          :pivot-y="0.5"
          width="600px"
          height="730px"
          transition="slide-top"
          overlayTransition="wait"
          classes="remove-modal-height report-a-bug">
          <iframe class="clickup-embed clickup-dynamic-height" style="border: 0"
                  src="https://forms.clickup.com/f/2gmvg-1457/55CSLQUSB9ERC8IACF" onwheel="" width="100%"
                  height="100%"></iframe>
        </modal>

    </div>
</template>

<script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import Sidebar from "@/layout/sidebar/Sidebar";
    import Navbar from "@/layout/navbar/Navbar";
    import MainFooter from "@/layout/MainFooter";
    import vInfiniteProgressBar from '@/modules/system/components/utilities/vInfiniteProgressBar'
    import ContactSalesModal from "@/components/ContactSalesModal.vue"

    export default {
        name: 'DefaultMaster',
        components: {
            'v-infinite-progressbar': vInfiniteProgressBar, Sidebar, Navbar, MainFooter, ContactSalesModal,
        },
        methods: {
            ...mapActions('system', ['authenticate']),
            ...mapMutations('system', ['setImpersonate']),
            unimpersonate() {
                let impersonate_id = window.localStorage.getItem('impersonate_id')
                let original_token = window.localStorage.getItem('original_token')
                this.authenticate({token: original_token})
                window.localStorage.removeItem('original_token')
                window.localStorage.removeItem('impersonate')
                this.setImpersonate()
            }
        },
        computed: {
            ...mapState('system', ['isLoading'])
        },
        async created() {
            let trans = await this.erp.ext.request.axiosInstance.get('/modules/translations')
            window.localStorage.setItem('translations', JSON.stringify(trans.data.data))
        }
	}
</script>

<style lang="scss">
    .topbar-head-warning {
        padding: .5rem 0;
        background: repeating-linear-gradient(45deg, #de6e6c, #de6e6c 22px, #dc5854 0, #dc5854 45px);

        .topbar-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-shadow: 0 2px 2px #ff4f5f91;

            @media (max-width: 1050px) {
                flex-direction: column;
            }

            .btn-impersonate {
                padding: 3px 15px;
                background: #fff;
                color: #ff4f5f;
                margin-left: 1rem;
                transition: all .3s ease-in-out;

                &:hover {
                    background: #ffffffe0;
                }
            }
        }
    }

    #footer-main {
        bottom: 0;
        position: fixed;
        width: inherit;
        z-index: 1;
    }

    #footer-main span.report-a-bug {
        &:before {
          content: '|';
          display: inline-block;
          color: black;
          margin: 0 5px;
        }

      color: $primary;
      cursor: pointer;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
</style>

