<template>
  <div>
    <div v-if="value" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ translate('Update menu item', 'menus') }} </h5>
        <button class="close" type="button" @click="$emit('close')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body b-b-pr">

        <template v-if="!data.entity">
          <div class="form-group">
            <label class="form-control-label">{{ translate('Title', 'menus') }} *</label>
            <input v-model.trim="data.name" class="form-control" type="text" required>
          </div>

          <div class="form-group" v-if="!data.entity">
            <label class="form-control-label">{{ translate('URL', 'menus') }} *</label>
            <input v-model="getUrlKey" class="form-control" type="text">
          </div>
        </template>
        <template v-else>
          <div class="form-group">
            <label class="form-control-label">{{ translate('Title', 'menus') }}</label>
            <input v-model.trim="data.name" class="form-control" type="text" :placeholder="data.original_name">
          </div>
        </template>

        <div class="form-group">
          <input id="advanced" type="checkbox" class="mr-1" @input="advanced=!advanced">
          <label class="form-control-label" for="advanced">{{ translate('Advanced settings', 'menus') }}</label>
        </div>

        <div v-if="advanced">
          <div class="form-group">
            <label class="form-control-label"> {{ translate('Icon', 'menus') }}</label>
            <input v-model="extras.icon" class="form-control" type="text">
          </div>
          <div class="form-group position-relative">
            <label class="form-control-label"> {{ translate('Icon Color', 'menus') }} </label>
            <ee-form-color-picker
              v-model="extras.icon_color"
            />
          </div>
          <div class="form-group position-relative">
            <label class="form-control-label"> {{ translate('CSS Classes', 'menus') }} </label>
            <input v-model="extras.classes" class="form-control" type="text">
          </div>
          <div class="form-group position-relative">
            <label class="form-control-label">{{ translate('Link behavior', 'menus') }}</label>
            <div class="d-flex">
              <div
                v-for="(option, optionIndex) in generalLinkTargetOptions"
                :key="optionIndex"
                class="switch-field"
              >
                <input
                  :id="`link-target-${option.key}`"
                  v-model="data.link_target"
                  :value="option.key"
                  type="radio"
                >
                <label :for="`link-target-${option.key}`">{{ translate(option.label, 'entities') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer remove-border">
        <button class="btn btn-white rounded-pill" @click.prevent="$emit('close')">
          {{ translate('Go back', 'global') }}
        </button>
        <button class="btn btn-primary rounded-pill" @click.prevent="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker.vue"

export default {
  name: "EditPage",
  components: {
    EeFormColorPicker,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      data: null,
      advanced: false,
      extras: {
        icon: '',
        icon_color: '#ffffff',
        classes: '',
      },
    }
  },
  computed: {
    // TODO refactor #232357568657
    generalLinkTargetOptions() {
      return [
        { key: '_self', label: 'Open in same tab' },
        { key: '_blank', label: 'Open in new tab' },
      ]
    },
    // END TODO
    getId() {
      return this.$route.params.id || this.$store.state.system.company.key
    },
    getUrlKey: {
      get() {
        return this.data.url || this.data.url_key
      },
      set(v) {
        this.data.url = v
      },
    },
  },
  methods: {
    save() {
      // sanitize input
      if (this.extras.classes && this.extras.classes.split(/\s+/).length > 1) {
        this.extras.classes = this.extras.classes
          .split(/\s+/)
          .join(" ")
      }

      this.data.extras = Object.entries(this.extras)
        .map(([key, value]) => ({ [key]: value }))

      this.$emit('input', this.data)
      this.$emit('close')
    },
  },
  created() {
    this.data = this.value

    if (!this.data?.link_target) {
      this.$set(this.data, 'link_target', '_self')
    }

    // convert extras to JSON
    if(this.value.extras){ // hot fix check if have extras from backend

      // todo key by key
      this.extras = this.value.extras.reduce((obj, item) => {
        let key = Object.keys(item)[0]
        obj[key] = item[key]
        return obj
      }, {})
    }
  },
}
</script>
