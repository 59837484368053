<template>
    <div class="">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{getTitle}}</h5>
                <button type="button" class="close" @click="$modal.hide('create_task')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label">
                        {{ translate('Task name', 'entities') }}
                    </label>
                    <input v-model="form.title" type="text" class="form-control">
                  </div>
                  <!-- Task description -->
                  <div class="form-group">
                    <label class="form-control-label mb-0">
                        {{ translate('Task description', 'entities') }}
                    </label>
                    <small class="form-text text-muted mb-2 mt-0">
                        {{ translate('Textarea with editing toolbar', 'entities') }}
                    </small>
                    <div class="d-flex">
                      <button class="btn btn-flat" @click="showParsedDescription = false">{{ translate('original', 'entities') }}</button>
                      <button class="btn btn-flat" @click="showParsedDescription = true">{{ translate('preview', 'entities') }}</button>
                    </div>
                    <textarea class="form-control" v-if="!showParsedDescription" v-model="form.description"></textarea>
                    <div v-if="showParsedDescription" v-html="parsedDescription"></div>
                </div>
                  <!-- Task members -->
                  <div class="form-group">
                    <label class="form-control-label">
                        {{ translate('Assign member', 'entities') }}
                    </label>
                    <v-select :options="asignees" label="name" class="select2-form" v-model="form.asignee"></v-select>
                </div>
                <!-- Task date -->
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- Start date -->
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('Start date', 'entities') }}
                                </label>
                                <input v-model="form.start" type="date" class="form-control">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Start date -->
                            <div class="form-group">
                                <label class="form-control-label">
                                    {{ translate('End date', 'entities') }}
                                </label>
                                <input v-model="form.end" type="date" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <attachments v-model="form.attachments"></attachments>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-danger rounded-pill" @click="$modal.hide('create_task')">
                {{ translate('Go back', 'global') }}
              </button>
              <button type="submit" class="btn btn-sm btn-primary rounded-pill" @click="$emit('created', form)">
                {{ translate('Save changes', 'global') }}
              </button>
            </div>
        </div>
    </div>
</template>
<script>
import marked from 'marked'
import vSelect from 'vue-select'

export default {
    props: {
        title: {
            required: true
        },
        asignees: {
            required: true
        }
    },
    components: {
        vSelect
    },
    data(){
        return {
            form: {
                title: '',
                description: '',
                attachments: [],
                asignee: null,
                end: null,
                start: null
            },
            showParsedDescription: false
        }
    },
    computed: {
        parsedDescription(){
            return marked(this.form.description)
        },
        getTitle() {
            if(this.title) {
                return this.title
            }
            return 'Create Task'
        },
    }
}
</script>