<template>
  <div v-if="model">
    <div class="p-3 d-flex justify-content-between align-items-center">
      <h6>{{ translate('Current options', 'entities') }}</h6>
      <button class="btn btn-primary rounded-pill" @click="addNewOption">
        {{ translate('Add new', 'entities') }}
      </button>
    </div>

    <div class="bg-white overflow-auto height-300">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th>{{ translate('Label', 'attributes') }}</th>
            <th>{{ translate('Key', 'attributes') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(option, oi) in model.select_option_options" :key="oi">
            <td>
              <input-field
                v-model="model.select_option_options[oi].value"
                :field-props="{translatable: value.translatable}"
                @input="e => generateKey(e, oi)"
              />
            </td>
            <td>
              <input v-model="model.select_option_options[oi].key" class="form-control" type="text">
            </td>
            <td>
              <span class="btn btn-secondary rounded-circle" @click="removeOption(oi)"><i class="fas fa-times" /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-primary rounded-pill d-flex ml-auto mt-3" @click="save">
      {{ translate('Save changes', 'global') }}
    </button>
  </div>
</template>

<script>
import { slug } from "@/utilities/helper"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { environment } from "@/modules/erp_entities/utilities/helper";

export default {
  name: 'CreateCustomOption',
  components: { InputField },
  props: {
    value: {
      required: true,
    }, 
  },
  data() {
    return {
      model: null,
    }
  },
  async created() {
    this.$emit('loading', true)

    const selectOptions = await this.erp.ext.request.axiosInstance.get(`/select-options/${this.value.data_module}`)
    this.model = selectOptions.data.data
    this.$emit('loading', false)
  },
  methods: {
    generateKey(v, i) {
      this.model.select_option_options[i].key = slug(v)
    },
    addNewOption() {
      this.model.select_option_options.push({ key: '', value: '' })
    },
    async removeOption(i) {
      const option = this.model.select_option_options[i]
      this.model.select_option_options.splice(i, 1)
      this.value.data.splice(i, 1)
      try {
        if (option.key) {
          await environment.request.put(`/select-options/${this.model.id}`, { options: this.model })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      this.$emit('loading', true)
      if (this.model && this.value.data_type === 'custom') {
        let data = {
          options: this.model,
        }
        try {
          let res = await environment.request.put(`/select-options/${this.model.id}`, data)
          if (res) {
            this.value.data = res.data.data.select_option_options
          }
          this.$modal.hide('create_new_options')
        } catch (e) {
          console.log(e)
        }
      }
      this.$emit('loading', false)
    },
  },
}
</script>

<style scoped lang="scss">
.height-300 {
  height: 300px;
}
</style>