<template>
  <div class="row">
    <order-items
      :cart-items="cartItems"
      @reloadCart="$emit('reloadCart')"
    />
    <order-overview
      :cart-overview="cartOverview"
      @completeOrder="$emit('completeOrder')"
    />
  </div>
</template>

<script>
import OrderItems from "@/modules/sitecart/views/orders/CreateOrder/OrderItems.vue"
import OrderOverview from "@/modules/sitecart/views/orders/CreateOrder/OrderOverview.vue"

export default {
  name: "OrderCart",
  components: { OrderOverview, OrderItems },
  props: {
    cartOverview: {
      required: true,
    },
    cartItems: {
      required: true,
    },
  },
}
</script>
