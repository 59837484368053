<template>
  <div class="row">
    <div class="form-group required col-12">
      <label class="control-label">
        {{ translate('Conditions', 'entities') }}
      </label>
      <v-select
        :value="data['conditions']"
        @input="v => selectConditions(v)"
        :options="conditionOptions"
        :reduce="value => value.key"
        multiple
        label="label"
        class="select2-form"
      />
    </div>

    <template v-if="showConditions">
      <label class="control-label col-12 mb-2">{{ translate('Condition values', 'entities') }}</label>
      <template v-for="(condition, idx) in data['conditions']">
        <div v-if="!conditionsWithoutInput.includes(condition.key)" :key="idx" class="form-group required col-12">
          <div class="control-label">
            {{ optionLabel(conditionOptions, condition.key) }}
          </div>
          <input v-model="condition.value" type="text" class="form-control">
        </div>
      </template>
    </template>

    <div class="form-group col-12">
      <hr/>
    </div>

    <div class="form-group required col-12">
      <label class="control-label">
        {{ translate('Destination', 'entities') }}
      </label>
      <treeselect-field :field-props="{ data: destinationOptions }" :value="null" :multiple="false" @input="updateDestination"/>
    </div>

    <div class="form-group required col-12">
      <label class="control-label">
        {{ translate('Destination behaviour', 'entities') }}
      </label>
      <v-select
        :value="data['destination_behaviour']"
        @input="setDestinationBehaviour"
        :options="behaviorOptions"
        :reduce="value => value.key"
        multiple
        label="label"
        class="select2-form"
      />
    </div>

    <div class="col">
      <button class="btn btn-primary" @click="createCondition">
        {{ translate('Add condition', 'entities') }}
      </button>
    </div>
  </div>
</template>

<script>
import { isVoidValue, optionLabel } from "@/utilities/helper"
import { conditionOptions, conditionsWithoutInput } from "@/utilities/lists"
import TreeselectField from "@/modules/erp_entities/components/page/form/fields/TreeselectField.vue"
import {groupEntityForm} from "@/modules/erp_entities/utilities/helper"

export default {
  name: 'CreateCondition',
  components: { TreeselectField },
  props: {
    properties: {
      required: true,
    },
  },
  data() {
    return {
      delimiter: '-->', // special delimiter used in condition destinations
      conditionOptions,
      conditionsWithoutInput,
      data: {
        conditions: [],
        destination_tab: '',
        destination_group: '',
        destination: '',
        destination_behaviour: '',
      },
    }
  },
  computed: {
    destinationOptions() {
      let options = []
      const attributeSetGroups = groupEntityForm(this.properties)

      for (let tabKey in attributeSetGroups) {
        let tab = {
          key: tabKey,
          value: tabKey,
        }

        if (attributeSetGroups[tabKey]) {
          tab.children = []

          for (let groupKey in attributeSetGroups[tabKey]) {
            let group = {
              key: tabKey + this.delimiter + groupKey,
              value: groupKey,
            }

            if (attributeSetGroups[tabKey][groupKey].length) {
              group.children = attributeSetGroups[tabKey][groupKey].reduce((acc, currentItem) => {
                const itemKey = tabKey + this.delimiter + groupKey + this.delimiter + currentItem.name
                const isDuplicate = acc.some(item => item.key === itemKey)

                if (!isDuplicate) {
                  acc.push({
                    key: itemKey,
                    value: currentItem.label,
                  })
                }

                return acc
              }, [])
            }
            tab.children.push(group)
          }
        }
        options.push(tab)
      }
      return options
    },

    showConditions() {
      const conditions = this.data.conditions || []
      if (!conditions.length) return false

      return conditions.some(condition => !this.conditionsWithoutInput.includes(condition.key))
    },

    destinationOptionsOLD() {
      return this.properties.reduce((acc, currentItem) => {
        const isDuplicate = acc.some(item => item.name === currentItem.name)

        if (!isDuplicate) {
          acc.push({
            name: currentItem.name,
            label: currentItem.label,
          })
        }

        return acc
      }, [])
    },

    destinationTabOptions() {
      return this.properties.reduce((acc, currentItem) => {
        const currentItemName = currentItem.tab || '__default__'
        const isDuplicate = acc.some(item => item.name === currentItemName)

        if (!isDuplicate) {
          acc.push({
            name: currentItemName,
            label: currentItemName,
          })
        }

        return acc
      }, [])
    },
    destinationGroupOptions() {
      return this.properties.reduce((acc, currentItem) => {
        const currentItemName = currentItem.group_by || '__default__'
        const isDuplicate = acc.some(item => item.name === currentItemName)

        if (!isDuplicate) {
          acc.push({
            name: currentItemName,
            label: currentItemName,
          })
        }

        return acc
      }, [])
    },
    behaviorOptions() {
      return [
        { key: 'show', label: 'Show' },
        { key: 'hide', label: 'Hide' },
      ]
    },
  },
  methods: {
    optionLabel,
    updateDestination(selectedItem) {
      const [tab, group, field] = selectedItem ? selectedItem.split(this.delimiter) : ['', '', '']
      this.data.destination_tab = tab
      this.data.destination_group = group ?? ''
      this.data.destination = field ?? ''
    },
    selectConditions(conditions) {
      // If all conditions are deselected, clear data.conditions
      if (conditions.length === 0) {
        this.data.conditions = []
        return
      }

      // Remove deselected conditions
      this.data.conditions = this.data.conditions.filter(condition => conditions.includes(condition.key))

      // Add new conditions
      conditions.forEach(conditionKey => {
        // Check if the condition already exists in data.conditions
        const existingCondition = this.data.conditions.find(c => c.key === conditionKey)

        const conditionLabel = this.conditionOptions.find(condition => condition.key === conditionKey).label
        // If it doesn't exist, create a new condition
        if (!existingCondition) {
          this.data.conditions.push({ key: conditionKey, value: '', label: conditionLabel })
        }
      })
    },
    setDestinationBehaviour(behaviours) {
      const hideOption = behaviours.indexOf('hide')
      const showOption = behaviours.indexOf('show')

      // Permits from having both 'hide' and 'show' options as destination behaviour
      if (hideOption !== -1 && showOption !== -1) {
        behaviours.splice(hideOption > showOption ? showOption : hideOption, 1)
      }

      this.$set(this.data, 'destination_behaviour', behaviours)
    },
    isValidCondition() {
      const { conditions, destination_tab, destination_group, destination, destination_behaviour } = this.data

      const hasValidConditionValues = conditions && Array.isArray(conditions) && conditions.length > 0 && conditions.every(condition => (this.conditionsWithoutInput.includes(condition.key) || !isVoidValue(condition.value)))
      const hasValidDestination = Boolean(destination_tab) || Boolean(destination_group) || Boolean(destination)
      const hasValidDestinationBehaviour = Array.isArray(destination_behaviour) && destination_behaviour.length > 0

      return hasValidConditionValues && hasValidDestination && hasValidDestinationBehaviour
    },
    resetData() {
      this.data = {
        conditions: [],
        destination_tab: '',
        destination_group: '',
        destination: '',
        destination_behaviour: '',
      }
    },
    createCondition() {
      if (this.isValidCondition()) {
        this.data.conditions.forEach(condition => delete condition.label)
        this.$emit('createCondition', this.data)
        this.resetData()
      } else {
        this.$toast.error('Please fill in the empty fields!')
      }
    },
  },
}
</script>
