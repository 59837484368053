<template>
  <div class="picker-component" :id="uuid">
    <span @click="togglePicker">
      <i class="icon-erp-ri-link"></i>
    </span>

    <div v-if="show" class="picker-content">
      <div class="row">
        <div class="col-12">
          <craft-input
            :label="'Link'"
            :value="data.href"
            @input="data.href = $event"
            ref="linkEl"
            field-ref="linkInput"
          />
        </div>
        <div class="col-md-12">
          <craft-tab-pills
            :label="'Link behavior'"
            :value="data.target"
            @input="data.target = $event"
            :options="generalLinkTargetOptions"
          />
        </div>
      </div>

      <div class="text-right my-4">
        <!-- <a @click="$emit('closeSettingsPopup')" class="btn btn-cancel rounded-pill mr-2">{{ translate('Cancel', 'page-builder') }}</a> -->
        <button
          @click.prevent="save"
          class="btn btn-primary rounded-pill"
        >{{ translate('Save changes', 'global') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";

// !important this import DOES NOT WORK, the whole editor is not rendering in Element settings screens
// import {CraftInput, CraftTabPills} from "@/modules/pagebuilder/components/craft-inputs";
import CraftInput from "@/modules/pagebuilder/components/craft-inputs/CraftInput.vue";
import CraftTabPills from "@/modules/pagebuilder/components/craft-inputs/CraftTabPills.vue";

export default {
  name: 'EeFormLinkPicker',
  mixins: [ComponentSettingsMixin],
  components: {
    CraftInput, CraftTabPills
  },
  data() {
    return {
      data: {
        href: '',
        target: '_self',
      },
      show: false,
      uuid: uuidv4().split('-')[0],
    }
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: {},
    },
  },
  computed: {
    href(){
      return this.value.href ?? null
    }
  },
  methods: {
    togglePicker(e) {
      this.show = !this.show;
      if (this.show) {
        this.$nextTick(() => {
          this.$refs['linkEl'].$refs['linkInput'].focus()
        })
      }
    },
    // does not work in the inside the TextEditor
    closePicker(e) {
      let clickedElement = e.target
      let colorPickerEl = document.getElementById(this.uuid)

      let hasClass = clickedElement.getAttribute('class') && clickedElement.getAttribute('class').indexOf('vc-') !== -1;
      if(hasClass){
        return false
      }

      if(!clickedElement || !colorPickerEl) {
        this.show = false
      }

      if (!this.isDescendant(colorPickerEl, clickedElement)) {
        this.show = false
      }
    },

    isDescendant(parent, child) {
      var node = child.parentNode

      while (node != null) {
        if (node == parent) {
          return true
        }
        node = node.parentNode
      }
      return false
    },
    save() {
      this.$emit('input', this.data)
      this.show = false
    },
  },
  async mounted() {
    await document.addEventListener('click', e => {
      this.closePicker(e)
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', this.togglePicker);
  },
  watch: {
    value: function(newVal) {
      this.data.href = typeof newVal.href !== 'undefined' ? newVal.href : ''
      this.data.target = typeof newVal.target !== 'undefined' ? newVal.target : '_self'
    },
  },
}
</script>

<style lang="scss">
.picker-component {

  > span {
    line-height: 0;
  }

  .picker-content {
    padding: 15px;
    width: 400px;
  }

  label {
    /* todo refactor #10378911 */
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
    /* END todo refactor */
  }

}
</style>