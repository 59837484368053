<template xmlns="http://www.w3.org/1999/html">
  <div class="builder-header">
    <div class="builder-header-container">
      <router-link class="btn d-flex align-items-center" to="/">
        <i class="icon-erp-home" />
      </router-link>
      <span class="block-b-r" />

      <div class="_page">
        <div class="logo">
          <img :src="`/img/themes/${$store.state.system.theme}/page-builder/logo.png`" alt="preloader-editor">
        </div>
      </div>

      <span class="block-b-r mr-2" />

      <!-- Export as HTML -->
      <!-- download DISABLED FOR NOW
      <div class="_export ripple d-none d-xl-flex" v-if="builder.meta.entity == 'cms-pages' && !isPreviewMode">
          <a href="#" @click="download">
              <i class="icon-erp-download"></i>
          </a>
      </div>
      <span class="block-b-r d-none d-xl-flex" v-if="!isPreviewMode"></span>
      -->

      <!-- Advanced editor button -->
      <div class="_page">
        <button
          v-if="showAdvancedEditorToggle"
          @click="toggleAdvancedEditor"
          class="btn btn-secondary">
          <span v-if="entity.objectData.advanced_builder">{{ translate('Simple editor', 'page-builder') }}</span>
          <span v-else>{{ translate('Advanced editor', 'page-builder') }}</span>
        </button>

        <button
          v-if=isAdvancedBuilder
          @click="toggleResponsiveMode"
          v-tooltip="translate('Responsive mode', 'page-builder')"
          class="btn btn-secondary mr-2">
          <i class="icon-erp-device-responsive" style="font-size: 14px;"/>
        </button>
      </div>

      <div class="mx-auto"></div>

      <!-- Right side options -->
      <div class="_builder-options">

        <!-- Language picker -->
        <template v-if="hasManyTranslationLocales && !isPreviewMode && (['cms-pages', 'cms-sections', 'blogs', 'portfolios'].includes(builder.meta.entity))">
          <span v-if="!isPreviewMode" class="block-b-r"></span>
          <LanguagePicker
            :label="translate('Storeview', 'dashboard')"
            :customLabelClasses="['font-weight-700', 'mr-2']"
            :labelAlignment="true"
            class="pl-3 pr-3 d-none d-lg-block storeview"
            @changeLocale="e => builderChangeTranslationLocale(e)"
          />
        </template>

        <!-- Auto save-->
        <span class="block-b-r d-none d-lg-flex pr-3"></span>
        <div v-if="!isPreviewMode" class="custom-control custom-switch d-none d-xl-flex pr-3">
          <input
            type="checkbox"
            v-model="$store.state.builder.autoSave"
            name="auto_save"
            id="autosave"
            class="custom-control-input"
            value="1"
          />
          <label
            for="autosave"
            class="custom-control-label"
          >{{ translate('Autosave', 'page-builder') }}</label>
        </div>

        <!-- Preview buttons -->
        <!--
        <button
          v-if="builder.meta.entity === 'cms-pages' || builder.meta.entity === 'cms-sections'"
          class="btn btn-toggle-status btn-secondary m-0"
          @click="handlePreviewPage">
          <span v-if="isPreviewMode">{{ translate('Edit', 'page-builder') }} {{ entityNameTranslated }}</span>
          <span v-else>{{ translate('Preview', 'page-builder') }} {{ entityNameTranslated }}</span>
        </button>

        <button
          v-if="entity && !isCreateMode && builder.meta.entity !== 'cms-pages' && builder.meta.entity !== 'cms-sections'"
          class="btn btn-toggle-status btn-secondary m-0"
          @click="handlePreview"
        >
          <span v-if="isPreviewMode">{{ translate('Edit', 'page-builder') }} {{ entityNameTranslated }}</span>
          <span v-else>{{ translate('Preview', 'page-builder') }} {{ entityNameTranslated }}</span>
        </button>
        -->

        <!-- Preview buttons end -->

        <!-- Save as template -->
        <template v-if="entity && !['cms-templates'].includes(builder.meta.entity) && can('create', 'cms-template')">
          <span class="block-b-r d-none d-xl-flex"></span>
          <button class="btn btn-toggle-status pl-3 pr-3" @click="saveAsTemplate">
            {{ translate('Save as template', 'page-builder') }}
          </button>
        </template>

        <button v-if="entity && !isCreateMode && !['cms-templates'].includes(builder.meta.entity)"
                :key="entity.objectData.status"
                class="btn btn-toggle-status ml-0"
                :class="{'btn-publish': entity.objectData.status != '1', 'btn-dark': entity.objectData.status == '1'}"
                @click="togglePageStatus"
        >
          <span v-if="entity.objectData.status != '1'">{{ translate('Publish {entity}', 'page-builder', { entity: entityNameTranslated }) }}</span>
          <span v-else>{{ translate('Unpublish', 'page-builder', { entity: entityNameTranslated }) }}</span>
        </button>
        <button
          v-if="!$store.state.builder.autoSave"
          @click="save"
          class="btn btn-primary btn-save-entity ml-0"
        >
          {{ translate('Save {entity}', 'page-builder', { entity: entityNameTranslated }) }}
        </button>
      </div>

      <modal name="translation-modal"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="auto"
             width="500px"
             transition="fade-in"
             overlay-transition="wait"
             classes="remove-modal-height"
      >
        <translations-modal
          :locales="localesToSwitch"
          :entityNameTranslated="entityNameTranslated"
          :entity-prop="builder.meta.entity"
          @performTranslationAction="performTranslationAction($event)"
          @save="saveTranslation"
        />
      </modal>

    </div>

    <div
      v-if="isAdvancedBuilder && $store.state.layout.responsiveMode"
      class="builder-header-container">

      <!-- Emulator options -->
      <emulator-options />

    </div>

  </div>
</template>

<script>
// Components
import LanguagePicker from "@/components/LanguagePicker.vue"

// Utilities
import BuilderMixin from '../mixins/BuilderMixin'
// import Swal from 'sweetalert2/dist/sweetalert2.min'
import { mapActions, mapGetters, mapState } from 'vuex'
import { erp } from '@/modules/erp_framework'
import debounce from 'debounce'
import { can, changeTranslationLocale, hasManyTranslationLocales, setPageTitle } from "@/utilities/helper"

import PagesMixin from "@/modules/pagebuilder/mixins/PagesMixin" // required for findCmsContentBySlug
import TranslationsModal from "@/modules/pagebuilder/components/modals/TranslationsModal.vue"
import EmulatorOptions from "@/modules/pagebuilder/components/EmulatorOptions.vue"
import html2canvas from "html2canvas"
import uniqid from "uniqid"

export default {
  name: 'BuilderHeader',
  components: {
    LanguagePicker, TranslationsModal, EmulatorOptions
  },
  mixins: [BuilderMixin, PagesMixin],
  inject: ['editor'],
  data() {
    return {
      nested_categories: null,
      localeToChange: null,
      localesToSwitch: [],
      normalizer(node) {
        return {
          id: node.key,
          label: node.value,
        }
      },
      html2canvasOptions: {
        logging: false,
        width: '1200',
        height: '1200',
      },
    }
  },
  computed: {
    ...mapState('builder', [
      'entity', 'autoSave',
    ]),
    ...mapGetters('builder', ['unsavedChanges', 'mainContainerElementId']),
    ...mapGetters('layout', ['deviceType', 'responsiveMode']),
    hasManyTranslationLocales,
    isPreviewMode() {
      return this.$store.state.layout.preview
    },

    showAdvancedEditorToggle(){
      return ['portfolios', 'blogs', 'stores'].includes(this.builder.meta.entity) && !this.isPreviewMode
    },
  },
  methods: {
    ...mapActions('builder', [
      'fetchPreview',
      'getEntities',
      'saveEntity',
      'update',
      'showGlobalLoader',
      'hideGlobalLoader',
    ]),
    ...mapActions('layout', [
      'changeDeviceViewport',
    ]),
    can,
    toggleResponsiveMode(){
      if(this.$store.state.layout.responsiveMode){
        this.changeDeviceViewport('desktop')
        this.$store.state.layout.responsiveMode = false
      } else {
        this.$store.state.layout.responsiveMode = true
      }
    },
    async save(){
      this.showGlobalLoader()

      if(this.isAdvancedBuilder){
        this.$store.state.builder.entity.objectData.content_json = await this.editor.export()
        this.$store.state.builder.entity.objectData.content_html = await this.editor.exportAsHtml()
      }

      if(this.builder.meta.entity === 'cms-templates'){
        this.$store.state.builder.entity.objectData.cms_block_id = this.mainContainerElementId

        try {
          this.$store.state.builder.entity.objectData.preview_image = await this.getPreviewImage(this.entity.objectData.slug)
        } catch (err) {
          console.error(err)
        }
      }

      await this.saveEntity()
      this.editor.enable()
      this.hideGlobalLoader()
    },

    async builderChangeTranslationLocale(locale){
      if(locale === this.entity.objectData.locale){
        return
      }

      this.localeToChange = locale
      // todo refactor #100014
      if(this.unsavedChanges){
        if (this.autoSave) {
          await this.save()
        } else {
          const confirmResult = await this.$alert.confirmSaveChanges()
          if(confirmResult.isDismissed){
            return
          } else if(confirmResult.isConfirmed){
            await this.save()
          }
        }
      }
      // END todo refactor #100014

      // redirect to the translated entity if any or create new one

      // check if page with that slug already exists
      let translatedCmsContent = await this.findCmsContentBySlug(this.builder.meta.entity, this.entity.objectData.slug, this.localeToChange)

      if(translatedCmsContent){
        this.showGlobalLoader()
        this.$store.state.builder.originalState = this.entity.objectData // disable the secondary "unsaved changes" modal
        await changeTranslationLocale(locale)
        return this.$router.push(`/builder/${this.builder.meta.entity}/${translatedCmsContent.id}`)
      }

      this.localesToSwitch = (await this.$store.dispatch('builder/getEntities', { entity: this.builder.meta.entity, locale: this.localeToChange })).objectData
        .filter(entity => entity.slug)
        .map(entity => ({ key: entity.slug, value: entity.name }))

      return this.$modal.show('translation-modal')
    },
    async performTranslationAction(duplicateContent) {
      this.showGlobalLoader()
      this.$store.state.builder.originalState = this.entity.objectData // disable the secondary "unsaved changes" modal
      this.$modal.hide('translation-modal')
      changeTranslationLocale(this.localeToChange)

      this.$router.push({
        name: 'builder-create',
        params: {
          contentJson: duplicateContent ? this.entity.objectData.content_json : '',
        },
        query: {
          slug: this.entity.objectData.slug,
          timestamp: Date.now(), // required so we don't get error NavigationDuplicated: Avoided redundant navigation when navigating from create to create
        },
      })
    },
    async saveTranslation(slug){
      let translatedCmsContent = await this.findCmsContentBySlug(this.builder.meta.entity, slug, this.entity.objectData.locale)
      if (translatedCmsContent && translatedCmsContent.id !== this.entity.objectData.id) {
        return this.alertCmsContentRelationExists()
      }

      this.entity.objectData.slug = slug
      this.$store.state.builder.originalState = this.entity.objectData // disable the secondary "unsaved changes" modal
      this.$modal.hide('translation-modal')
      translatedCmsContent = await this.findCmsContentBySlug(this.builder.meta.entity, slug, this.localeToChange)
      changeTranslationLocale(this.localeToChange)
      await this.$router.push(`/builder/${this.builder.meta.entity}/${translatedCmsContent.id}`)
      await this.save()
    },
    toggleAdvancedEditor(){
      // using this.$set because exising blog post/
      this.$set(this.$store.state.builder.entity.objectData, 'advanced_builder', this.$store.state.builder.entity.objectData.advanced_builder ? 0 : 1)
    },
    handlePreview(){
      this.$store.state.layout.preview = !this.$store.state.layout.preview
      this.changeDeviceViewport('desktop')
    },
    async handlePreviewPage(){
      if(this.$store.state.layout.preview){
        this.$store.state.builder.previewHTML = ''
        this.$store.state.layout.preview = false
      } else {
        this.showGlobalLoader()
        this.$store.state.builder.previewHTML = await this.fetchPreview({
          html: this.entity.objectData.content_html,
          include_header_and_footer: this.builder.meta.entity === 'cms-pages',
        })
        this.changeDeviceViewport('desktop')
        this.hideGlobalLoader()
        this.$store.state.layout.preview = true
      }
    },

    async loadData() {
      this.$emit('loadData')
    },
    async togglePageStatus() {
      const status = Number(!Number(this.$store.state.builder.entity.objectData.status))
      this.$store.state.builder.entity.objectData.status = status
      let savedData = await this.update({
        status,
      })
      if((savedData.status === 200 || savedData.status === 201) && !savedData.errors){
        this.$toast.requestSuccess('put')
      } else {
        this.$alert.formattedError(savedData)
      }
    },
    /* download DISABLED FOR NOW
    download() {
      var element = document.createElement('a')
      element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(this.dom)
      )
      element.setAttribute('download', 'builder.html')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },*/

    // todo refactor #982374982364
    async getPreviewImage(slug) {
      this.editor.disable()
      await new Promise(resolve => setTimeout(resolve, 10)) // hot fix, wait for editor to be disabled

      const iframeDoc = document.getElementById('pb-frame').contentDocument
      const html = iframeDoc.getElementById('pb-wrapper').outerHTML
      const iframe = document.createElement("iframe")
      iframe.style.width = "1200px"
      iframe.style.height = "1200px"
      document.body.appendChild(iframe)
      iframe.srcdoc = html

      return new Promise(async (resolve, reject) => {
        iframe.addEventListener("load", async () => {
          try {
            (await html2canvas(iframe.contentWindow.document.body, this.html2canvasOptions)).toBlob(async blob => {
              if (blob) {
                const snapshotAsFile = new File([blob], `${slug}.png`, { type: 'image/png' })
                const formData = new FormData()
                formData.append('file', snapshotAsFile)

                const res = await this.erp.ext.request.axiosInstance.post(`/modules/documents`, formData)
                resolve(res.data.data.id)
              }
            })
          } catch (error) {
            reject(error)
          } finally {
            document.body.removeChild(iframe)
            this.editor.enable()
          }
        })
      })
    },

    async saveAsTemplate() {
      // const result = await this.$alert.confirm({
      //   title: 'Do you want to save the current {entity} as template?',
      //   translateArgs: { entity: this.entityNameTranslated.toLowerCase() },
      // })
      const name = await this.$alert.confirmWithInput('Please enter template title', this.$store.state.builder.entity.objectData.name)
      if(!name.value){
        return
      }

      // todo another loader, we don't want the whole page hidden
      // this.showGlobalLoader()

      const slug = uniqid()
      const preview_image = await this.getPreviewImage(slug)

      let data = {
        options: {
          name: name.value,
          slug: slug,
          content_json: this.$store.state.builder.entity.objectData.content_json,
          cms_block_id: this.mainContainerElementId,
          user_id: this.$store.state.system.authData.user_id,
          preview_image: preview_image,
        },
      }
      delete data.options.id

      this.erp.ext.request.axiosInstance.post(`/modules/cms-templates`, data)
        .then(res => this.$toast.requestSuccess('post', 'Cms Template'))
        .catch(err => this.$toast.requestFailed('post', 'Cms Template'))
        // .finally(() => this.hideGlobalLoader())
    },
  },
}
</script>

<style lang="scss">
.btn-toggle-status {
    height: 100%;
    border-radius: 0px !important;
}

.categories-treeselect .vue-treeselect {
    width: 300px;
    max-width: 300px;
}

/* hot fix footer builder disabled builder options */
.multi-builder-view ._builder-options {
  display: none!important;
}
</style>
