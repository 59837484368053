<template>
	<div>
		<page-title :page-title="translate('Orders', 'orders')" :counter="orders.meta.total" :borderBottom="false" v-if="orders && orders.meta">
			<template v-slot:actions>
				<router-link class="btn btn-primary rounded-pill" to="/blockz-order/create/choose-plan">
                    {{ translate('Add new order', 'orders') }}
                </router-link>
			</template>
		</page-title>

		<div class="card filter-nav mt-3">
			<ul class="nav nav-tabs scope-tabs">
				<li class="nav-item pt-3" v-if="orders.meta"
					:class="{'active': selectedFilter == null}">
					<a href="javascript:;"
					   class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
					   @click="loadOrders(null)">
						{{ translate(`All`, 'orders') }}
						<span class="counter ml-4">{{ orders.meta.total }}</span>
					</a>
				</li>
				<li class="nav-item d-flex justify-content-between align-items-center pt-3 px-2"
					v-for="(status, si) in filterByStatus" :class="{'active':
                        selectedFilter == status}">
					<a href="javascript:;" class="d-flex system-text-primary d-flex align-items-center pb-3 pl-2"
					   @click="loadOrders({key: 'status', value: status, condition: '='})">
						{{ translate(`${status}`, 'orders') }}
						<span class="counter ml-4">?</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="main-table">
			<div v-if="orders" class="data-table order-table">
				<loading-circles v-if="loading"></loading-circles>
				<div class="d-flex justify-content-between align-items-center table-header px-4">
					<div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
						<div class="search-icon">
							<span><i class="fa fa-search"></i></span>
						</div>
						<input type="text" class="form-control h-100 search-field"
							   :placeholder="translate('Search', orders)"
							   @input="e => loadOrders({key: 'order_number', value: `%${e.target.value}%`,
							   condition: 'like'})">
					</div>
					<div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
						<div class="d-flex justify-content-end align-items-center">
							<div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
								<a class="nav-link pr-lg-0 py-0" href="#" role="button"
								   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ translate('{count} per page', 'global', { count: tableParams.per_page }) }}
									<i class="fa fa-chevron-down"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
									<a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi"
									   @click="setItemsPerPage(per_page.key)">
										{{ per_page.value }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table align-items-center">
						<thead>
						<tr>
							<th scope="col">{{ translate('order_number', 'orders') }}<i class="ml-1 fa fa-sort"></i></th>
							<th scope="col">{{ translate('user', 'orders') }}<i class="ml-1 fa fa-sort"></i></th>
							<th scope="col">{{ translate('email', 'orders') }}<i class="ml-1 fa fa-sort"></i></th>
							<th scope="col">{{ translate('status', 'orders') }}<i class="ml-1 fa fa-sort"></i></th>
							<th scope="col" class="text-right">{{ translate('Actions', 'global') }}<i class="ml-1 fa fa-sort"></i></th>
						</tr>
						</thead>
						<tbody class="list">
						<tr v-for="(order, orderIndex) in orders.data" :key="orderIndex">
							<td>
								<router-link :to="`/orders/${order.id}`">
									{{ order.order_number }}
								</router-link>
							</td>
							<td>
								<router-link :to="`/companies/${order.company_id}`">
									{{ order.billing_client_first_name }} {{ order.billing_client_last_name }}
								</router-link>
							</td>
							<td>{{ order.billing_client_email }}</td>
							<td>
								<span :class="`status-badge ${order.status} `" class="px-4 py-2">
									{{ translate(order.status, 'orders') }}
								</span>
							</td>
							<td class="text-right">
								<cta>
									<action-button class-icon="fa-eye" @click.native="showOrder(order)"></action-button>
								</cta>
							</td>
						</tr>
						</tbody>
					</table>

					<pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
								@goToPage="loadPage" v-if="orders.meta"
								:value="orders.meta"></pagination>

					<slot :reload="loadOrders"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Pagination from "@/components/page/table/Pagination";
import debounce from 'debounce'
import {db, routes} from '@/utilities/database';
import {buildHttpFilterObject, urlEncoder} from "@/utilities/helper";

export default {
	name: 'OrdersIndex',
	data() {
		return {
			tableParams: {
				page: 1,
				per_page: 5
			},
			loading: false,
			orders: null,
			selectedFilter: null
		}
	},
	methods: {
		showOrder(order) {
			this.$router.push('orders/' + order.id)
		},
		loadPage(page) {
			this.tableParams.page = page;
			this.loadOrders()
		},
		setItemsPerPage(per_page) {
			this.tableParams.per_page = per_page
			this.loadOrders()
		},
		loadOrders: debounce(async function (filters) {
			this.loading = true;
			this.$store.state.system.isLoading = true;

			let params = null
			if (filters) {
				params = {
					'filter_criteria': {
						'filter_groups': {
							0: {
								'filters': {
									0: filters
								}
							}
						}
					}
				}
			}

			if (filters && filters.key === 'status') {
				this.selectedFilter = filters.value
				this.orders.data = null
			} else {
				this.selectedFilter = null
			}

			let columns = {column: 'created_at', direction: 'desc', type: 'text'}

			let response = await
				db.get(`${routes.orders.index}?page=${this.tableParams.page}&perpage=${this.tableParams.per_page}&${urlEncoder(columns)}&${urlEncoder(params)}`)

			this.orders = response.data

			this.loading = false;
			this.$store.state.system.isLoading = false
		}, 700),
		countOrders() {
			// db.get('/modules/info/subscription/status').then(res => {
			// 	console.log(res)
			// })
		},
	},
	mounted() {
		this.loadOrders()
		this.countOrders()
	},
	computed: {
		filterByStatus() {
			return ['canceled', 'pending', 'completed']
		},
		perPage() {
			return [
        { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
        { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
        { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
        { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
			]
		}
	},
	watch: {
		'$route'(to, from) {
			if (from.name == 'show-order') {
				this.loadOrders()
			}
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/scss/components/all.scss";

.filter-nav {
	.nav-tabs .active .link {
		border-bottom: 2px solid red;
		padding-bottom: 10px;
	}

	.nav-tabs li a {
		// color: #000;
		font-size: 16px;
	}
}

.main-table {
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);

	.table-header {
		height: 60px;

		.search-field {
			border: none;
			font-size: 16px;

			&:focus {
				background: transparent;
				box-shadow: none;
			}
		}
	}

	table {
		border-radius: 5px;

		thead {
			background-color: #fcfcfc;
		}

		tr {
			border-bottom: 1px solid #e5edf5;

			th {
				border-bottom: 0;
				background-color: #f9fbfd;
				color: #000;
				line-height: 1.8rem;
				text-transform: uppercase !important;
				border-color: #e5edf5;

				i {
					color: #95aac9;
				}
			}

			td {
				background-color: #fff;
                vertical-align: middle;

				.avatar {
					vertical-align: middle;
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}

				.status-badge {
					&.awaiting.payment {
						background-color: #fdf3da;
						color: #d69710;
						border: 1px solid #fdf3da;
						border-radius: 50px;
					}
				}
			}
		}
	}
}
</style>
