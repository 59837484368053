<template>
  <div>
    <order-card label="Add customer">
      <customer-select
        v-model="customerId"
      />
    </order-card>

    <order-card label="Billing address">
      <order-billing-address-form
        v-model="customerDetails"
        :countries="countries"
        :customer="customer"
        :address-options="addressOptions"
        :selected-address="selectedBillingAddress"
        @changeAddress="v => changeAddress(v, 'billing')"
      />
    </order-card>

    <order-card label="Shipping address">
      <order-shipping-address-form
        v-model="customerDetails"
        :countries="countries"
        :customer="customer"
        :address-options="addressOptions"
        :selected-address="selectedShippingAddress"
        :same-as-billing-address="sameAsBillingAddress"
        @changeAddress="v => changeAddress(v, 'billing')"
      />

      <template v-slot:actions>
        <div class="d-flex">
          <span class="text-normal">{{ translate('Same as billing address', 'orders') }}</span>
          <div class="custom-control custom-switch">
            <input
              id="same_as_billing"
              v-model="sameAsBillingAddress"
              type="checkbox"
              name=""
              class="custom-control-input"
              :true-value="true"
              :false-value="false"
              @change="updateSameAsBillingAddress(sameAsBillingAddress)"
            >
            <label
              for="same_as_billing"
              class="custom-control-label"
            />
          </div>
        </div>
      </template>
    </order-card>

  </div>
</template>

<script>
import CustomerSelect from "@/modules/sitecart/components/CustomerSelect.vue"
import OrderBillingAddressForm from "@/modules/sitecart/views/orders/CreateOrder/OrderBillingAddressForm.vue"
import OrderShippingAddressForm from "@/modules/sitecart/views/orders/CreateOrder/OrderShippingAddressForm.vue"
import { isVoidValue } from "@/utilities/helper"
import OrderCard from "@/modules/sitecart/views/orders/CreateOrder/OrderCard.vue"

export default {
  name: "OrderCustomerDetails",
  components: { OrderCard, OrderShippingAddressForm, OrderBillingAddressForm, CustomerSelect },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      customerId: null,
      customer: null,
      customerAddressBook: [],
      customerDetails: {},
      selectedBillingAddress: null,
      selectedShippingAddress: null,
      sameAsBillingAddress: false,
      countries: [],
    }
  },
  computed: {
    customerDetailsComputed: {
      get() {
        return this.customerDetails
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    addressOptions() {
      if(this.customerAddressBook){
        return this.customerAddressBook.reduce((acc, obj) => {
          let address_name = obj.name
          if(!address_name){ // hot fix because some addresses does not have alias
            let address_name_temp = []
            if(obj.address_address) address_name_temp.push(obj.address_address)
            if(obj.address_city) address_name_temp.push(obj.address_city)
            if(obj.address_country) address_name_temp.push(obj.address_country)
            address_name = address_name_temp.join(', ')
          }
          acc.push({ key: obj.id, label: address_name, rawObject: obj })
          return acc
        }, [])
      }
      return []
    },
  },
  methods: {
    isVoidValue,
    changeCustomer(customerId) {
      this.$store.state.system.isLoading = true
      this.erp.ext.request.axiosInstance.get(`/customers/${customerId}`).then(res => {
        this.customer = res.data.data
        this.sameAsBillingAddress = false
        this.customerAddressBook = res.data.data.address_book

        // Preselecting first address from address book
        if (this.customerAddressBook.length > 0) {
          this.selectedBillingAddress = this.customerAddressBook[0].id
          this.selectedShippingAddress = this.customerAddressBook[0].id
          this.chooseAddress(this.customerAddressBook[0], ['billing', 'shipping'])
        // otherwise clear addresses
        } else {
          this.selectedBillingAddress = null
          this.selectedShippingAddress = null
          // clear inputs if customer don't have address book
          this.clearCustomerInfo()
        }

        this.customerDetailsComputed.client_id = res.data.data.id
        this.$store.state.system.isLoading = false
      })
    },
    clearCustomerInfo(){
      this.customerDetailsComputed.billing_client_first_name = null
      this.customerDetailsComputed.billing_client_last_name = null
      this.customerDetailsComputed.billing_client_phone = null
      this.customerDetailsComputed.billing_client_email = null
      this.customerDetailsComputed.billing_address_country = null
      this.customerDetailsComputed.billing_address_state = null
      this.customerDetailsComputed.billing_address_city = null
      this.customerDetailsComputed.billing_address_postcode = null
      this.customerDetailsComputed.billing_address_id = null
      this.customerDetailsComputed.billing_address_address = null
      this.customerDetailsComputed.billing_address_street_number = null
      this.customerDetailsComputed.billing_address_additional_information = null
      this.customerDetailsComputed.billing_company_name = null
      this.customerDetailsComputed.billing_vat_number = null
      this.customerDetailsComputed.chamber_of_commerce = null

      this.customerDetailsComputed.shipping_client_first_name = null
      this.customerDetailsComputed.shipping_client_last_name = null
      this.customerDetailsComputed.shipping_client_phone = null
      this.customerDetailsComputed.shipping_address_country = null
      this.customerDetailsComputed.shipping_address_state = null
      this.customerDetailsComputed.shipping_address_city = null
      this.customerDetailsComputed.shipping_address_postcode = null
      this.customerDetailsComputed.shipping_address_id = null
      this.customerDetailsComputed.shipping_address_address = null
      this.customerDetailsComputed.shipping_address_street_number = null
      this.customerDetailsComputed.shipping_address_additional_information = null
    },
    removeCustomer() {
      this.customer = null
      this.customerAddressBook = []
      this.customerDetailsComputed.client_id = 0
      this.clearCustomerInfo()
    },
    changeAddress(addressId, type){
      const address = this.customerAddressBook.find(el => el.id === addressId)
      this.chooseAddress(address, type)
    },
    chooseAddress(address, type) {
      if (type.includes('billing')) {
        this.customerDetailsComputed.billing_client_first_name = address.client_first_name
        this.customerDetailsComputed.billing_client_last_name = address.client_last_name
        this.customerDetailsComputed.billing_client_phone = address.client_phone
        this.customerDetailsComputed.billing_client_email = this.customer.email
        this.customerDetailsComputed.billing_address_country = address.address_country
        this.customerDetailsComputed.billing_address_state = address.address_state
        this.customerDetailsComputed.billing_address_city = address.address_city
        this.customerDetailsComputed.billing_address_postcode = address.address_postcode
        this.customerDetailsComputed.billing_address_id = address.id
        this.customerDetailsComputed.billing_address_address = address.address_address
        this.customerDetailsComputed.billing_address_street_number = address.address_street_number
        this.customerDetailsComputed.billing_company_name = address.billing_company_name
        this.customerDetailsComputed.billing_vat_number = address.billing_vat_number
        this.customerDetailsComputed.chamber_of_commerce = address.chamber_of_commerce
        this.customerDetailsComputed.billing_address_additional_information = address.address_additional_information
      }

      if (type.includes('shipping')) {
        this.customerDetailsComputed.shipping_client_first_name = address.client_first_name
        this.customerDetailsComputed.shipping_client_last_name = address.client_last_name
        this.customerDetailsComputed.shipping_client_phone = address.client_phone
        this.customerDetailsComputed.shipping_address_country = address.address_country
        this.customerDetailsComputed.shipping_address_state = address.address_state
        this.customerDetailsComputed.shipping_address_city = address.address_city
        this.customerDetailsComputed.shipping_address_postcode = address.address_postcode
        this.customerDetailsComputed.shipping_address_id = address.id
        this.customerDetailsComputed.shipping_address_address = address.address_address
        this.customerDetailsComputed.shipping_address_street_number = address.address_street_number
        this.customerDetailsComputed.shipping_address_additional_information = address.address_additional_information
      }
    },
    updateSameAsBillingAddress(value){
      this.sameAsBillingAddress = value
      // In case we select billing country first and then toggle sameAsBillingAddress checkbox we get shipping/payment methods
      if (this.sameAsBillingAddress && this.customerDetailsComputed.billing_address_country) {
        this.$emit('updateShippingAndPaymentMethods')
      }
    },
  },
  async created() {
    this.countries = (await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country')).data.data
    this.customerDetails = this.value
  },
  // in case shipping country changes update order to get payment methods
  watch: {
    customerId: function (val) {
      if(val){
        this.changeCustomer(val)
      } else {
        this.removeCustomer()
      }
    },
    'customerDetails.shipping_address_country': {
      deep: true,
      handler(val) {
        if (val) {
          this.$emit('updateShippingAndPaymentMethods')
        }
      },
    },
    'customerDetails.billing_address_country': {
      deep: true,
      handler(){
        if (this.sameAsBillingAddress) {
          this.$emit('updateShippingAndPaymentMethods')
        }
      },
    },
  },
}
</script>
