<template>
    <div>
      <element-settings-content-tabs>

        <element-settings-content-tab :selected="true" :name="translate('Background', 'page-builder')" :has-padding="false">
          <background :node="this.editor.selectedNode"/>
        </element-settings-content-tab>

        <element-settings-content-tab :name="translate('Spacing', 'page-builder')">
          <spacing :node="this.editor.selectedNode"/>
        </element-settings-content-tab>

        <element-settings-content-tab :name="translate('Borders', 'page-builder')">
          <borders :node="this.editor.selectedNode"/>
        </element-settings-content-tab>

        <element-settings-content-tab :name="translate('Advanced', 'page-builder')">
          <advanced :node="this.editor.selectedNode"/>
        </element-settings-content-tab>

      </element-settings-content-tabs>
    </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";

import Background from '../sections/design/Background.vue';
import Spacing from '../sections/design/Spacing.vue';
import Borders from '../sections/design/Borders.vue';
import Advanced from '../sections/design/Advanced.vue';

export default {
  name: "Design",
  inject: ['editor', 'node'],
  mixins: [ComponentSettingsMixin, CraftSettingsMixin],
  components: {
    Background,
    Spacing,
    Borders,
    Advanced,
  },
}
</script>
