<template>
    <!-- wait for attributes before displaying anything -->
    <div class="row" v-if="attributes">
      <template v-if="showPlaceholder">
        <div class="col-lg-8">
          <p class="mb-lg-4">{{ translate('Does your product have options such as different sizes and colors? Add variations for your customers.', 'products') }}</p>
          <template v-if="attributes.length === 0">
            <div class="warning">
              <div class="content p-4">
                <span><i class="fas fa-exclamation-triangle"></i></span>
                <span class="text-secondary-color"> {{ translate('To use this feature you must have selectable attributes.', 'products') }}</span>
              </div>
            </div>
          </template>
          <button v-else type="button" @click="startAddVariations" class="btn btn-primary rounded-pill mt-lg-4 mb-2">
              <span class="btn-inner--text">
                  {{ translate('Add variations', 'products') }}
              </span>
          </button>
        </div>
        <div class="col-lg-4">
          <img src="/img/backgrounds/product-variations.png">
        </div>
      </template>

      <template v-else>

        <template v-for="(field, fieldI) in attributes">
          <template v-if="configurableAttributes && configurableAttributes.includes(field.name)">
            <div class="col-md-6">
              <field
                @createOptions="v => $emit('createOptions', v)"
                :key="'variation-attribute-'+field.name"
                @input="v => handleAttributeValue(field.name, v)"
                :value="attributesSelectValues[field.name]"
                :field-props="field"
                :apply-default-val="false"
              />
            </div>
          </template>
        </template>

        <div class="col-12 d-flex">
          <button type="button" @click="openChooseConfigurableAttributesModal" class="btn btn-primary rounded-pill my-2 ml-auto">
              <span class="btn-inner--text">
                  {{
                  translate(configurableAttributes.length ? 'Choose more attributes' : 'Choose attributes', 'products')
                }}
              </span>
          </button>
        </div>

        <div class="table-responsive">
          <div class="col-12">
            <button type="button" @click="openBulkActionsModal"  class="btn btn-primary rounded-pill mb-2">
              <span class="btn-inner--text">
                  {{ translate('Bulk actions', 'products') }}
              </span>
            </button>
          </div>

          <!-- NOT NEEDED FOR NOW
          <div class="d-flex justify-content-end">
            <a href="javascript:;" @click="removeSelected">{{ translate('Remove selected', 'products') }}</a>
          </div>-->

          <table class="table align-items-center m-0" id="variations-table">
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" @input="e => selectAll(e.target.checked)"
                           class="custom-control-input"
                           :id="`select_all`">
                    <label class="custom-control-label" :for="`select_all`"></label>
                  </div>
                </th>
                <th scope="col">{{ translate('Variations', 'products') }}</th>
                <th scope="col" class="text-right">{{ translate('Status', 'products') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(variation, variationI) in variations">

                <tr :class="variation.status==0 ? 'disabled': ''">
                  <td width="10px">
                    <div v-if="variation.status==1" class="custom-control custom-checkbox">
                      <input :id="'row-' + variationI" type="checkbox" class="custom-control-input"
                             v-model="variations[variationI].selected">
                      <label class="custom-control-label" :for="'row-' + variationI"></label>
                    </div>
                  </td>
                  <td @click="toggleVariation(variationI)">
                    <h6>{{ attributeNames(variation) }}</h6>
                  </td>

                  <td class="text-right status-toggler">
                    <div class="custom-control custom-switch">
                      <input
                          type="checkbox"
                          :true-value="1" :false-value="0"
                          name="variation_status"
                          :id="`variation_status-${variationI}`"
                          class="custom-control-input"
                          v-model="variations[variationI].status"
                      />
                      <label :for="`variation_status-${variationI}`" class="custom-control-label"></label>
                    </div>
                  </td>
                  <td @click="toggleVariation(variationI)" width="50">
                    <i class="fa ml-auto fa-chevron-down chevron-relative" :class="{'chevron-rotate': variationShow(variationI) }"></i>
                  </td>
                </tr>
                <tr class="additional-attributes" :class="variation.status==0 ? 'disabled': ''" v-if="variationShow(variationI)">

                  <td colspan="4">

                    <div class="row">
                      <template v-for="(field, fieldI) in variationFields">
                        <field v-if="field && field.permission !== 'd'" @createOptions="v => $emit('createOptions', v)"
                               :class="`col-md-${field.width || '6'} ${(field && field.type === 'hidden') ? 'd-none' : ''}`"
                               :key="variationI + '_' + field.name"
                               v-model="variations[variationI][field.name]"
                               :field-props="field"
                               :entity="'inventory'"
                               :placeholder="fieldPlaceholder(field)"/>
                      </template>
                    </div>


                      <!-- todo refactor #00141-attributeSetGroups-1 -->
                      <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups">
                        <div class="card rounded bg-white" v-if="attrSetGroupI !== 'variations' && isGroupEnabled(attrSetGroupI)" :key="variationI +'_'+attrSetGroupI">
                          <div class="card-header d-flex justify-content-between align-items-center" @click="toggleVisibility(visibilityKey(variationI, attrSetGroupI))">
                            <h5>{{ translate(`${humanReadable(attrSetGroupI)}`, 'products') }}</h5>
                            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible[visibilityKey(variationI, attrSetGroupI)] === true}"></i>
                          </div>
                          <transition-expand>
                            <div v-show="visible[visibilityKey(variationI, attrSetGroupI)]">
                              <!-- Items with multiple sub groups -->
                              <!-- First used for Role Based Pricing -->
                              <template v-if="(typeof multipliedGroups[attrSetGroupI]) !== 'undefined'">
                                <div class="card-body inner-cards-as-accordion">
                                  <div class="row">
                                    <template v-for="(attrSetSubGroup, attrSetSubGroupI) in attributeSetGroups[attrSetGroupI]">
                                      <div
                                          :key="visibilityKey(variationI, attrSetGroupI, attrSetSubGroupI)"
                                          class="col-12 card"
                                          :class="{ active: visible[visibilityKey(variationI, attrSetGroupI)] }">
                                        <div class="card-header d-flex justify-content-between" @click="toggleVisibility(visibilityKey(variationI, attrSetGroupI, attrSetSubGroupI))">
                                          <h6>{{ attrSetSubGroupI }}</h6>
                                          <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible[visibilityKey(variationI, attrSetGroupI, attrSetSubGroupI)] === true}"></i>
                                        </div>
                                        <transition-expand>
                                          <div v-show="visible[visibilityKey(variationI, attrSetGroupI, attrSetSubGroupI)]">
                                            <div class="card-body">
                                              <div class="row">

                                                <!-- todo refactor #00141-attributeSetGroups-2 -->
                                                <template v-for="(field, fieldI) in attrSetSubGroup.fields">
                                                  <field v-if="field && field.permission !== 'd'" @createOptions="v => $emit('createOptions', v)"
                                                         :class="`col-md-${field.width || '6'} ${(field && field.type === 'hidden') ? 'd-none' : ''}`"
                                                         :key="variationI + '_' + field.name"
                                                         v-model="variations[variationI][field.name]"
                                                         :field-props="field"
                                                         :entity="'inventory'"
                                                         :placeholder="fieldPlaceholder(field)"/>
                                                </template>

                                              </div>
                                            </div>
                                          </div>
                                        </transition-expand>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>

                              <!-- Items with 1 sub group -->
                              <template v-else>
                                <div class="card-body">
                                  <div class="row">
                                    <template v-for="(attrSetSubGroup, attrSetSubGroupI) in attributeSetGroups[attrSetGroupI]">
                                      <!-- todo refactor #00141-attributeSetGroups-2 -->
                                      <template v-for="(field, fieldI) in attrSetSubGroup.fields">
                                        <field v-if="field && field.permission !== 'd'" @createOptions="v => $emit('createOptions', v)"
                                               :class="`col-md-${field.width || '6'} ${(field && field.type === 'hidden') ? 'd-none' : ''}`"
                                               :key="variationI + '_' + field.name"
                                               v-model="variations[variationI][field.name]"
                                               :field-props="field"
                                               :entity="'inventory'"
                                               :placeholder="fieldPlaceholder(field)"/>
                                      </template>
                                    </template>
                                  </div>
                                </div>
                              </template>

                            </div>
                          </transition-expand>
                        </div>
                      </template>

                  </td>
                </tr>

              </template>
            </tbody>
          </table>
        </div>
      </template>

      <modal name="product_variations_bulk_actions"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="auto"
             width="500px"
             transition="slide-top"
             overlayTransition="wait"
             classes="remove-modal-height">
        <variations-bulk-actions/>
      </modal>

      <modal name="product_variations_attributes"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="auto"
             width="500px"
             transition="slide-top"
             overlayTransition="wait"
             classes="remove-modal-height">

        <variations-attributes
            :configurable-attributes="configurableAttributes"
            @inputConfigurableAttributes="$emit('inputConfigurableAttributes', $event)"

            :attributes-select-values="attributesSelectValues"
            @handleAttributeValue="(field_name, v) => handleAttributeValue(field_name, v)"
            @createOptions="v => $emit('createOptions', v)"

            :attributeSetId="attributeSetId"
        />
      </modal>

    </div>
</template>

<script>
import field from '@/modules/erp_entities/components/page/form/InputEntityProps'
import busEvent from "@/utilities/eventBus";
import VariationsAttributes from "@/modules/sitecart/views/products/modals/VariationsAttributes";
import VariationsBulkActions from "@/modules/sitecart/views/products/modals/VariationsBulkActions";

import ProductMixin from "@/modules/sitecart/mixins/ProductsMixin";
import {humanReadable} from "@/utilities/helper";

export default {
    name: 'ProductVariations',
    mixins: [ProductMixin],
    components: {
      field, VariationsAttributes, VariationsBulkActions,
    },
    props: {
        configurableAttributes: {
            required: true,
            default: () => [],
        },
        variations: {
            required: true
        },
        attributeSetId: {
            required: true
        },
        // todo check if is not used and remove it
        attributeSetGroupNames: {
          required: true
        },
        parentProduct: {
          required: true
        }
    },
    data() {
        return {
          options: {},
          attributes: null,
          showPlaceholder: true,
          attributesSelectValues: {},
          variationFields: [],
          variationsVisible: [],

          // on edit only
          variationOptions: {}
        }
    },
    async created() {
      // On edit directly show the variations if there are any, otherwise the placeholder
      if(this.configurableAttributes.length){
        this.showPlaceholder = false
        this.buildAttributesSelectValues()
      }

      this.attributes = await this.getSelectableAttributes()
      await this.getAttributeSetProperties(this.attributeSetId)
      this.variationFields = this.getVariationFields()

      // check also "this.configurableAttributes" because somehow it becomes [__ob__: Observer] and .length throw error
      if(this.configurableAttributes && this.configurableAttributes.length){
        await this.rebuildVariationsFromAttributes()
      }
      // on first load toggle fields based on the parent product
      this.toggleField('manage_stock')
    },
    mounted() {
      busEvent.$on('apply_product_variations_bulk_actions', (bulk_edit_values) => {
        this.variations.forEach((variation, variationI) => {
          if(variation.selected){
            if(bulk_edit_values.quantity.value){
              this.$set(this.variations[variationI], 'qty', bulk_edit_values.quantity.value)
            }
            if(bulk_edit_values.price.value){
              this.$set(this.variations[variationI], 'price', bulk_edit_values.price.value)
            }
            if(bulk_edit_values.sale_price.value){
              this.$set(this.variations[variationI], 'sale_price', bulk_edit_values.sale_price.value)
            }
            if(bulk_edit_values.weight.value){
              this.$set(this.variations[variationI], 'weight', bulk_edit_values.weight.value)
            }
          }
        })

        this.$emit('inputVariations', this.variations);
      })

      // not used anymore
      // busEvent.$on('apply_product_configurable_attributes', (configurable_attributes) => {
      //   this.$emit('inputConfigurableAttributes', configurable_attributes);
      // })

    },
    computed: {
      configurableAttributesSorted(){
        // must be sorted alphabetically, otherwise this.variationOptions keys will not match
        const result = this.configurableAttributes.slice()
        return result.sort()
      },
    },
    methods: {
      humanReadable,
      isGroupEnabled(group){
        if(group === 'Role Based Pricing'){
          return true
        }
        return false
      },
      startAddVariations(){
        this.showPlaceholder = false
        this.openChooseConfigurableAttributesModal()
      },

      // returns only the fields needed for variations
      getVariationFields(){

        const attributes = [
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'images'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'qty'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'stock_status'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'price'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sku'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'weight'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price_from'),
          this.getGroupAndIndexByFieldName(this.attributeSetGroups, 'sale_price_until'),
        ]

        let fields = []
        attributes.forEach(attr => {
          if(attr){
            fields.push(this.attributeSetGroups[attr.group_by][attr.group_name].fields[attr.index])
          }
        })

        fields.forEach(field => {
          // custom properties (width, type)
          if(['sale_price_from', 'sale_price_until'].includes(field.name)){
            field.width = '4'
          } else if(['images'].includes(field.name)){
            //field.type = 'image'
            field.width = '12'
          } else {
            field.width = '3'
          }

          // apply default value from the parent product
          if(['weight', 'price', 'qty', 'stock_status'].includes(field.name)) {
            field.default_value = this.parentProduct[field.name]
          }
        })

        return fields
      },

      async deleteProduct(id) {
        this.erp.ext.request.axiosInstance.delete(`/modules/inventory/${id}`).then(res => {})
      },

      /* not used anymore
      setVariationValue(variationI, fieldProps, v){
        this.$set(this.variations[variationI], fieldProps.name, v)
        this.$emit('inputVariations', this.variations);
      },*/

      async getSelectableAttributes(){
        let properties = await this.erp.ext.request.axiosInstance.get(`/modules/attribute-sets/${this.attributeSetId}/properties`)

        let fields = []
        Object.entries(properties.data.data).forEach(([groupKey, group]) => {
          group.fields.forEach(v => {
            // Skip system attributes
            if(typeof v.system_attribute === 'undefined' || parseInt(v.system_attribute) === 1){
              return
            }
            // Skip non select types (multiselect excluded as well as it is not working in the frontside)
            if(!['select', 'visual_swatch'].includes(v.type)){
              return
            }
            v.type = 'multiselect' // all fields must be rendered as multiselects

            // set attribute values
            /* not used anymore
            if(typeof this.attributesSelectValues[v.name] !== 'undefined'){
              v.value = this.attributesSelectValues[v.name]
            }*/

            fields.push(v)
          })
        })
        return fields
      },

      // used to handle change on any select
      async handleAttributeValue(field_name, v) {
        if(typeof this.attributesSelectValues[field_name] !== 'undefined' && this.attributesSelectValues[field_name].length > v.length){
          this.$store.state.system.isLoading = true

          const removedAttributeValue = this.attributesSelectValues[field_name].filter(x => !v.includes(x))[0];
          await this.removeAttributeSelectValue(field_name, v, removedAttributeValue)
          this.$store.state.system.isLoading = false

        } else {
          this.addAttributeSelectValue(field_name, v)
        }
      },

      /*
       * Add new attribute select value
       */
      addAttributeSelectValue(field_name, v){
        this.$set(this.attributesSelectValues, field_name, v)
        this.rebuildVariationsFromAttributes()
      },
      /*
       * Remove attribute select value
       */
      async removeAttributeSelectValue(field_name, v, removedValue){
        let new_variations = []
        this.variations.forEach((variation, variationI) => {
          if(variation[field_name] !== removedValue){
            new_variations.push(variation)
          } else {
            if(typeof variation.id !== 'undefined'){

              // todo refactor #0001916 use filters for both
              let configurable_attributes_values = {}
              Object.keys(variation).forEach(attr_name => {
                if(this.configurableAttributes.includes(attr_name)){
                  configurable_attributes_values[attr_name] = variation[attr_name]
                }
              });
              delete this.variationOptions[this.variationAttributesToString(configurable_attributes_values)]
              this.deleteProduct(variation.id)
            }
          }
        });

        this.$set(this.attributesSelectValues, field_name, v)
        this.$emit('inputVariations', new_variations);
      },

      combineRecursive(...arrays) {
        if (arrays.length === 1) {
          return arrays[0];
        }

        if (arrays.length > 2) {
          return this.combineRecursive(arrays[0], this.combineRecursive(...arrays.slice(1)));
        }

        // arrays.length is exactly 2
        const combinations = [];
        for (const word0 of arrays[0]) {
          for (const word1 of arrays[1]) {
            combinations.push(word0 +'|'+ word1);
          }
        }
        return combinations;
      },

      rebuildVariationsFromAttributes(){
        let attributesValues = [];

        this.configurableAttributesSorted.forEach(attribute_name => {
          if(typeof this.attributesSelectValues[attribute_name] !== 'undefined' && this.attributesSelectValues[attribute_name].length){
            let variation = [];
            this.attributesSelectValues[attribute_name].forEach(value => {
              variation.push(attribute_name+':'+value)
            });
            attributesValues.push(variation)
          }
        });

        let result = []
        if(attributesValues.length){
          result = this.combineRecursive(...attributesValues)
        }

        let variations = [];
        result.forEach(item => {
          variations.push({
            // must have options for new variations
            status: 0,
            // END must have options for new variations
            ...this.variationOptions[item],
            ...this.variationAttributesToKeyValue(item)
          })
        })

        this.$emit('inputVariations', variations);
      },

      buildAttributesSelectValues(){
        if(this.variations){
          this.variations.forEach(variation => {
            // destructuring params from original object:

            // todo refactor #0001916 use filters for both
            let configurable_attributes_values = {}
            let options = {}

            // must be sorted alphabetically, otherwise this.variationOptions keys will not match
            Object.keys(variation).sort().forEach(attr_name => {
              if(this.configurableAttributes.includes(attr_name)){
                configurable_attributes_values[attr_name] = variation[attr_name]
              } else {
                options[attr_name] = variation[attr_name]
              }
            });

            // keep selected attribute values
            this.configurableAttributesSorted.forEach(attr_name => {
              if(typeof this.attributesSelectValues[attr_name] === 'undefined'){
                this.attributesSelectValues[attr_name] = []
              }
              if(!this.attributesSelectValues[attr_name].includes(variation[attr_name])){
                this.attributesSelectValues[attr_name].push(variation[attr_name])
              }
            })

            // keep also existing variations values
            this.variationOptions[this.variationAttributesToString(configurable_attributes_values)] = options
          });
        }
      },

      /**
       * Format variations object to string
       * attribute_1:value_1|attribute_2:value_2
       *
       * @param {object} variations
       * @returns String
       */
      variationAttributesToString(variations){
        return Object.keys(variations).map(function(k) { return k + ":" + variations[k] }).join("|")
      },

      /**
       * Format variations string to object
       *
       * @param {attributesString} string
       * @returns Object
       */
      variationAttributesToKeyValue(attributesString){
        let res = {}
        let attributes = attributesString.split("|")
        attributes.forEach(value => {
          const tmp = value.split(':');
          res[tmp[0]] = tmp[1];
        })
        return res
      },

      selectAll(checked) {
        if (this.variations) {
          this.variations.forEach(variation => {
            if(parseInt(variation.status) === 1){
              if (checked) {
                this.$set(variation, 'selected', true)
              } else {
                this.$set(variation, 'selected', false)
              }
            }
          })
        }
      },

      openBulkActionsModal() {
        this.$modal.show('product_variations_bulk_actions')
      },
      openChooseConfigurableAttributesModal() {
        this.$modal.show('product_variations_attributes')
      },

      /* NOT NEEDED FOR NOW
      removeSelected() {
        // something like this will work only for items without ID
        // this.variations = this.variations.filter(item => !item.selected)
      }*/

      attributeNames(variation){
        let variationValues = []
        let attrIndex, attrValue = null
        this.configurableAttributes.forEach(attr_name => {
          if(variation[attr_name]){
            attrIndex = this.getIndexByFieldName(this.attributes, attr_name)

            // hot fix check for data, on first load the data is still missing (null)
            if(this.attributes[attrIndex].data){
              attrValue = this.attributes[attrIndex].data.filter(function (item) {
                return item.key == variation[attr_name]
              })
              if(attrValue && attrValue.length){
                variationValues.push(this.entityLabel(attrValue[0].value))
              }
            }
          }
        })
        return variationValues.join(', ')
      },

      toggleField(fieldName){
        if(fieldName === 'manage_stock'){
          const qtyAttrIndex = this.getIndexByFieldName(this.variationFields, 'qty')
          const stockStatusAttrIndex = this.getIndexByFieldName(this.variationFields, 'stock_status')

          if(this.parentProduct.manage_stock == 1){
            this.$set(this.variationFields[qtyAttrIndex], 'v_hide', false);
            this.$set(this.variationFields[stockStatusAttrIndex], 'v_hide', true);

          }else{
            this.$set(this.variationFields[qtyAttrIndex], 'v_hide', true);
            this.$set(this.variationFields[stockStatusAttrIndex], 'v_hide', false);
          }
        }
      },
      toggleVariation(variationI) {
        if (parseInt(this.variations[variationI].status) === 0) {
          return
        }
        if (this.variationsVisible.includes(variationI)) {
          let idx = this.variationsVisible.indexOf(variationI)
          this.variationsVisible.splice(idx, 1)
        } else {
          this.variationsVisible = []
          this.variationsVisible.push(variationI)
        }
      },
      variationShow(variationI) {
        return this.variationsVisible.includes(variationI)
      }
    }
}
</script>

<style scoped lang="scss">
  #variations-table {
    tbody {
      > tr {
        &:not(.additional-attributes) {
          //background: #e5edf5;
          background: #eff2f7;
        }
        &.additional-attributes {
          td > .card .card-header {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &.disabled {
          &.additional-attributes {
            display: none;
          }
          td:not(.status-toggler) {
            opacity: 0.4;
            input {
              pointer-events: none;
            }
          }
        }

        .card-header {
          h5 {
            font-size: 13px;
          }
        }
      }
    }
  }

</style>
