<template>
  <div class="container app-entities-settings">
    <page-title :page-title="translate('Company settings', 'settings')">
      <template #actions>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <div v-if="website" class="row">
      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            @click="visible.general_info = !visible.general_info"
          >
            <h5>{{ translate('General info', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.general_info === true}" />
          </div>
          <transition-expand>
            <div v-show="visible.general_info" class="card-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Logo', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <image-field
                        v-model="website.data.logo"
                        class="picker-secondary w-100"
                        :field-props="{}"
                        @input="v => website.set('logo', v)"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('Name', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input 
                        v-model="website.data.name" 
                        class="bg-white form-control" 
                        name="name"
                        @input="v => website.set('name', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('Street number', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.street_number"
                        class="form-control bg-white"
                        name="street_number"
                        @input="v => website.set('street_number', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('ZIP Code', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.zip_code"
                        class="form-control bg-white"
                        name="zip_code"
                        @input="v => website.set('zip_code', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('City', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.city"
                        class="form-control bg-white"
                        name="city"
                        @input="v => website.set('city', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('Chamber of commerce', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.chamber_of_commerce"
                        class="form-control bg-white"
                        name="chamber_of_commerce"
                        @input="v => website.set('chamber_of_commerce', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('Email', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.email"
                        class="form-control bg-white"
                        name="email"
                        @input="v => website.set('email', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="control-label">{{ translate('Phone', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.phone"
                        class="form-control bg-white"
                        name="phone"
                        @input="v => website.set('phone', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Website', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.website"
                        class="form-control bg-white"
                        name="website"
                        @input="v => website.set('website', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('FAX', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.fax"
                        class="form-control bg-white"
                        name="fax"
                        @input="v => website.set('fax', v.target.value)"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            @click="visible.financial = !visible.financial"
          >
            <h5>{{ translate('Finances', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.financial === true}" />
          </div>
          <transition-expand>
            <div v-show="visible.financial" class="card-body">
              <div class="row">
              <!--
              TODO DISCUSS NEW FIELDS

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Bank name', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.bank_name"
                        class="form-control bg-white"
                        name="bank_name"
                        @input="v => website.set('bank_name', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('IBAN', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.iban"
                        class="form-control bg-white"
                        name="IBAN"
                        @input="v => website.set('iban', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('BIC', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.bic"
                        class="form-control bg-white"
                        name="bic"
                        @input="v => website.set('bic', v.target.value)"
                      >
                    </div>
                  </div>
                </div>

                -->

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('VAT Number', 'settings') }}</label>
                    <div class="d-flex justify-content-between py-2">
                      <input
                        v-model="website.data.vat"
                        class="form-control bg-white"
                        name="vat"
                        @input="v => website.set('vat', v)"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card rounded bg-white">
          <div
            class="card-header d-flex justify-content-between align-items-center"
            @click="visible.localization = !visible.localization"
          >
            <h5>{{ translate('Localization', 'settings') }}</h5>
            <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible.localization === true}" />
          </div>
          <transition-expand>
            <div v-show="visible.localization" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Country', 'settings') }}</label>
                    <div v-if="countries" class="d-flex justify-content-between py-2">
                      <v-select
                        v-model="website.data.country"
                        :options="countries"
                        label="value"
                        :reduce="value => value.key"
                        @input="v => website.set('country', v)"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Language', 'settings') }}</label>
                    <div v-if="allLocales" class="d-flex justify-content-between py-2">
                      <v-select
                        v-model="website.data.website_primary_locale"
                        :options="allLocales"
                        label="name"
                        :reduce="value => value.key"
                        :clearable="false"
                        @input="v => website.set('website_primary_locale', v)"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">{{ translate('Primary currency', 'settings') }}</label>
                    <div v-if="currencies" class="d-flex justify-content-between py-2">
                      <v-select
                        v-model="website.data.currency"
                        :options="currencies"
                        label="value"
                        :reduce="value => value.key"
                        @input="v => website.set('currency', v)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-expand>
        </div>
      </div>

      <div class="col-md-12">
        <button class="save-btn btn btn-primary rounded-pill d-flex ml-auto mt-2" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErpModel from "@/modules/erp_framework/services/ErpModel"
import ImageField from "@/modules/erp_entities/components/page/form/fields/ImageField.vue"

import { allLocales } from "@/utilities/helper"

export default {
  name: 'CompanySettings',
  components: {
    ImageField,
  },
  data() {
    return {
      website: null,
      countries: null,
      allLocales: null,
      currencies: null,
      visible: {
        general_info: true,
        financial: true,
        localization: true,
      },
    }
  },
  async created() {
    this.$store.state.system.isLoading = true
    await this.findWebsite()

    this.allLocales = await allLocales()
    let countries = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Country?perpage=9999')
    this.countries = countries.data.data
    let currencies = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/Currency?perpage=9999')
    this.currencies = currencies.data.data
    this.$store.state.system.isLoading = false
  },
  methods: {
    async findWebsite() {
      this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    },
    async save() {
      this.$store.state.system.isLoading = true
      try {
        //Alert success if both requests go through
        await this.website.save()
        this.$toast.requestSuccess('put', this.interfaceName)
      } catch (errors) {
        Object.entries(errors.errors).forEach(([name, errors]) => {
          document.querySelector(`[name="${name}"]`).addClass('is-invalid')
          Object.values(errors).forEach(err => {
            document.querySelector(`[name="${name}"]`).parentElement
              .insertAdjacentHTML('beforeend', `<p class="mb-0" style="color: #dc3545; font-size: 12px">${err}</p>`)
          })
        })
      }
      this.$store.state.system.isLoading = false
    },
  },
}
</script>