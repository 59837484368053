<template>
  <section class="cms-block cms-block-section">
    <video-background v-if="getSetting('background_video_link')" />
    <div :class="getSetting('container_class')">
      <slot />
      <button v-if="editor.enabled && !this.editor.draggedNode" class="add_new_section" @click="addNewSection"><i class="fa fa-plus mr-2"></i>{{translate('Add section', 'page-builder')}}</button>
    </div>
  </section>
</template>

<script>
import busEvent from "@/utilities/eventBus";
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";
import Node from '../../../craft/core/Node';
import {createNodeFromComponentName} from "@/modules/pagebuilder/craft/utils/createNodeFromVNode";
import VideoBackground from "@/modules/pagebuilder/components/components/VideoBackground.vue"

export default {
  name: 'SectionElement',
  components: { VideoBackground },
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        container_class: 'container', // container, container-fluid

        // todo refactor #18764781, this code is duplicated
        background_video_link: null,
        background_video_start_time: null,
        background_video_end_time: null,
        background_video_loop: 0,
        background_video_opacity: '100%',
        background_video_overlay_color: '',
        background_video_overlay_opacity: '50%',
        // END todo refactor #18764781
      },
      elementStyle: {
        desktop: {
          "padding-top": '50px',
          "padding-bottom": '50px',
        },
      },
    },
    rules: {
      // Only allow RowElement to be placed in Section
      canMoveIn(incommingNode, thisNode) {
        if(incommingNode.componentName === 'RowElement' || (incommingNode.componentName === 'Canvas' && incommingNode.props.component === 'RowElement')){
          return true
        }
        return false
      }
    }
  },
  methods: {
    addNewSection() {
      // if you need default column just uncomment the commented code below and remove this.editor.selectNode(row)

      const section = createNodeFromComponentName(this.editor, 'SectionElement')
      const row = createNodeFromComponentName(this.editor,'RowElement')
      //row.props.settings.grid_layout = '12'
      //const column = createNodeFromComponentName(this.editor,'ColumnElement')

      //row.append(column)
      section.append(row)
      section.insertAfter(this.node)

      setTimeout(() => { // does not work without timeout
        this.editor.selectNode(row)

        setTimeout(() => { // does not work without timeout
          busEvent.$emit('rowToolsToggleSettings')
        }, 10)

      }, 10)
    },
  },
};
</script>