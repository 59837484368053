<template>
  <!-- SAME AS SelectField FOR NOW -->
  <div>
    <v-select
      v-model="model"
      label="value"
      :placeholder="placeholderComputed"
      :options="data || []"
      :disabled="isDisabled"
      :multiple="fieldProps.type == 'multiselect'"

      :reduce="value => value.key"
      @input="$emit('filter', value)"
      class="select2-form"
    >
      <template #selected-option="{ key, value }">
        {{ entityLabel(value, fieldProps && fieldProps.system_attribute == 1) }}
      </template>
      <template v-slot:option="option">
        {{ entityLabel(option.value, fieldProps && fieldProps.system_attribute == 1) }}
      </template>
      <template v-slot:no-options>{{ translate(noOptionsLabel, 'entities') }}</template>
    </v-select>

    <a href="javascript:;" v-if="fieldProps.default != '1' && fieldProps.create_new_data_option == '1'"
       class="d-flex justify-content-end" @click="canWrite ? createNewOptions(fieldProps) : null">
      {{ translate('Create new', 'entities') }}
    </a>
  </div>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormSelectMixin from "@/modules/erp_entities/mixins/FormSelectMixin"

export default {
  name: 'VisualSwatchField',
  mixins: [FormFieldMixin, FormSelectMixin],
  computed: {
    isDisabled(){
      // we also use "disabled" for read only because v-select does not support readonly
      return !this.canWrite
    },
    noOptionsLabel(){
      return 'Sorry, no matching options.'
    },
  },
}
</script>
