<template>
    <div>
        <page-title
        	:page-title="translate('Shipping settings', 'settings')"
		>
        	<template v-slot:actions>
            	<router-link
            		class="btn btn-primary rounded-pill"
            		to="/webshop-settings/shipping/create"
            	>
                {{ translate('Add new {entity}', 'entities', { entity: translate('Courier', 'entities').toLowerCase() }) }}
            	</router-link>
        	</template>
        </page-title>
        <div class="row" v-if="records">
            <div class="col-md-12">
                <div class="main-table mt-4">
                    <div class="d-flex justify-content-between align-items-center table-header px-4">
                        <div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
                            <div class="search-icon">
                                <span><i class="fa fa-search"></i></span>
                            </div>
                            <input
                                type="text" class="form-control h-100 search-field"
                                :placeholder="translate('Search by name', 'global')"
                                :value="userPreferences.search"
                                @input="e => setSearch(e.target.value)">
                        </div>
                        <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
                            <!-- <div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                                <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="records">
                                    {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                                    <i class="fa fa-chevron-down"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                    <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">
                                        {{per_page.value}}
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table align-items-center m-0">
                            <thead class="thead-light">
                            <tr>
                                <!--
                                <th scope="col">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            type="checkbox" @input="e => selectAll(e.target.checked)"
                                            class="custom-control-input"
                                            :id="`select_all`"
                                        >
                                        <label class="custom-control-label" :for="`select_all`"></label>
                                    </div>
                                </th>
                                -->
                                <th scope="col">{{ translate('Type', 'entities') }}</th>
                                <th scope="col">{{ translate('Courier', 'entities') }}</th>
                                <th scope="col">{{ translate('Ship to', 'entities') }}</th>
                                <th scope="col">{{ translate('Status', 'entities') }}</th>
                                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(shipping, shippingI) in records.data"
                                :key="shippingI"
                                @mouseleave="activate = null"
                                class="tr-1"
                            >
                              <!--
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input :id="'row-' + shippingI" type="checkbox" class="custom-control-input"
                                               v-model="records.data[shippingI].selected">
                                        <label class="custom-control-label" :for="'row-' + shippingI"></label>
                                    </div>
                                </td>
                                 -->
                                <td @click="editShipping(shipping)">
                                    <span class="capitalize" role="button">{{ shipping.shipping_type.replaceAll("_", " ") }}</span>
                                </td>
                                <td>{{ shipping.courier }}</td>
                                <td>
                                    <span v-if="shipping.country">{{ formatCountryLabels(shipping.country) }}</span>
                                    <span v-else>{{ translate('All countries', 'shipping') }}</span>
                                </td>
                                <td>
                                    <div
                                        v-if="shipping.status"
                                        class="position-relative"
                                    >
                                        <span class="status-badge" :class="`status_${shipping.status}`" @click="activate = shipping.id">
                                            {{ translate(shipping.status == 1 ? 'Active' : 'Inactive', 'shipping') }}
                                        </span>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <cta>
                                        <action-button class-icon="fa-pen" @click.native="editShipping(shipping)"></action-button>
                                        <action-button class-icon="fa-trash-alt" @click.native="deleteCourier(shipping)"></action-button>
                                    </cta>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <pagination
                        v-if="records && records.meta"
                        v-model="records.meta"
                        :disabled="loading"
                        :container-class="'d-flex justify-content-between'"
                        @goToPage="v => setProp('page', v)"
                    >
                    </pagination> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EntityIndexMixin from "../../../../mixins/EntityIndexMixin";
    export default {
        name: "Shipping",
        props: {
            isOnboarding: {
                required: false
            },
            websiteId: {
                required: false,
            }
        },
        mixins: [EntityIndexMixin],
        data() {
            return {
                activate: null
            }
        },
        computed: {
        },
        async created() {
            this.entityType = 'shipping/rates'
            await this.loadUserPreferences()

            this.userPreferences.filters = {}
            this.userPreferences.filters['cms_website_id'] = {
              prop: 'cms_website_id',
              compare: '=',
              value: this.isOnboarding ? this.websiteId : this.$store.state.system.scope.value
            }

            await this.loadData()
        },
		methods: {
            editShipping(shipping) {
                if(!shipping.slug.length){
                  console.warn('The shipping does not have slug')
                  return
                }

                this.$router.push(`/webshop-settings/shipping/${shipping.shipping_type}/${shipping.slug}/edit`);
			},
            async deleteCourier(shipping) {
              const confirmDelete = await this.$alert.confirmDelete()
              if(!confirmDelete.isConfirmed) return

              this.erp.ext.request.axiosInstance.delete(`modules/shipping/rates/${shipping.shipping_type}/${shipping.slug}`).then(res => {
                this.loadData()
                this.$toast.requestSuccess('delete')
              }).catch(error => {
                this.loadData()
                this.$toast.error(error.message)
              })
            },
            /* not used for now
            async changeShippingStatus(shipping) {
                shipping.status = Number(!Number(shipping.status))
                shipping.ship_to_all_countries = 1
                this.activate = null
        	    await this.erp.ext.request.axiosInstance.put(`/modules/shipping/${shipping.id}`, shipping)
            },*/
            formatCountryLabels(countries) {
                if(countries) {
                    const codes = countries.split(',');
                    let label = codes.slice(0,5).join();
                    label += codes.slice(5).length ? ` (+ ${codes.slice(5).length} more)` : ''; // todo translate
                    return label;
                } else {
                    return ''
                }
            }
		},

    }
</script>

<style scoped lang="scss">
    .activate-container {
        position: absolute;
        top: 26px;
        left: 0;
        background: #fff;
        box-shadow: 0 1px 8px 3px #e6e6e6;
        z-index: 999;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .capitalize {
        text-transform: capitalize;
    }
</style>
