/**
 * Erp arr prototype file for defining helpers.
 * Globally used helpers for arrays and such should be placed in this package structure.
 * This is ment to be use across multiple projects independent from any functionality
 */




/**
 * Transforming options array to object containing key(name)/value of the inputs
 * @param {String} keyIdentifier Property that contains the name of the input. when used with options its "key" else could be "name"
 * @returns {Object}
 */
// DEPRECATED, use toKeyValue() instead
Array.prototype.optionsToObject = function(inputIdentifier = 'key'){
  let valueKey = inputIdentifier == 'key' ? 'value' : 'type'
  return this.toKeyValue(inputIdentifier, valueKey)
}

Array.prototype.toKeyValue = function(key = 'name', valueKey = 'value'){
  return this.reduce((a, option) => {
    a[option[key]] = option[valueKey];
    return a
  }, {})
}