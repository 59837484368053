import { mapActions } from "vuex"
import { createEntityFormObjectNoTabs } from './../utilities/helper'

export default {
  methods: {
    ...mapActions('tracker', ['track']),
    trackEntity(type, properties){
      this.track({
        type: type,
        data: { ...createEntityFormObjectNoTabs(properties, 'key'), id: this.entity.data.id },
      })
    },
  },
}
