<template>
  <div class="card mb-0">
    <div
      class="card-header d-flex justify-content-between align-items-center px-0 border-bottom-0"
      @click="visible = !visible"
    >
      <h6 class="text-bold">{{ translate(componentsType, 'page-builder') }}</h6>
      <i class="fa fa-chevron-down chevron-relative" :class="{'chevron-rotate': visible}" />
    </div>
    <transition-expand>
      <div v-show="visible" class="card-body p-0">
        <div class="app-page-snippets">
          <blueprint
            v-for="(block, idx) in filteredComponents"
            :id="'single-snippet-' + block.slug"
            :key="idx"
            component="div"
            :disabled="!isBought(block)"
            class="single-snippet position-relative"
            @dragstart="$emit('dragStart')"
            @click.native="selectBlock(block.slug)"
          >
            <transition name="slide">
              <premium-component-popup
                v-if="isPremium(block) && !isBought(block) && showPopupBlock === block.slug"
                :style="popupStyle"
                :block-name="block.name"
                @close="showPopupBlock = 'portfolio'"
              />
            </transition>
            <span v-if="isPremium(block) && !isBought(block)" class="premium-badge">{{ translate('Premium', 'page-builder') }}</span>
            <i :class="`${block.icon} icon-erp-2x`" />
            <span class="snippet-name mt-2">{{ translate(block.name, 'page-builder') }}</span>
            <template v-slot:blueprint>
              <component :is="block.component" />
            </template>
          </blueprint>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import Blueprint from "@/modules/pagebuilder/craft/components/Blueprint.vue"
import BuilderElements from "@/modules/pagebuilder/components/components/elements"
import PremiumComponentPopup from "@/modules/pagebuilder/components/left-sidebar/entities/components/PremiumComponentPopup.vue"
import { isVoidValue } from "@/utilities/helper"

export default {
  name: 'ComponentsAccordion',
  components: {
    PremiumComponentPopup,
    ...BuilderElements, Blueprint,
  },
  props: {
    componentsType: {
      type: String,
      required: true,
    },
    blockData: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      visible: true,
      showPopupBlock: false,
    }
  },
  computed: {
    filteredComponents() {
      return this.blockData.filter(block => {
        return (
          block.name &&
          (!block.environments || block.environments.includes(this.$store.state.system.interface)) &&
          block.status === '1' &&
          typeof block.component !== 'undefined' &&
          block.component !== null &&
          block.name.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
    popupStyle() {
      if (this.showPopupBlock !== false) {
        const selectedBlueprint = this.$el.querySelector(`#single-snippet-${this.showPopupBlock}`)
        if (selectedBlueprint) {
          const rect = selectedBlueprint.getBoundingClientRect()
          return {
            position: 'fixed',
            top: `${rect.top - 150}px`,
            left: `${rect.right}px`,
            zIndex: 1,
          }
        }
      }

      return null
    },
  },
  methods: {
    isPremium(block){
      return !isVoidValue(block.extension)
    },
    isBought(block) {
      return !this.isPremium(block) || this.$store.state.system.authData.apps.includes(block.extension)
    },
    selectBlock(slug) {
      if (this.showPopupBlock === slug) {
        this.showPopupBlock = false
      } else {
        this.showPopupBlock = slug
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.premium-badge {
  background-color: $primary;
  color: #fff;
  position: absolute;
  top: 2px;
  right: 5px;
  padding: 2px 6px;
  border-radius: 50px;
  margin-top: 5px;
  font-size: 0.675rem;
}
</style>
