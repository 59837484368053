<template>
  <draggable
    :list="value"
    v-bind="dragOptions"
  >
    <!-- <div class="col-md-12 nested-category mt-2 p-3" v-if="value.length"> -->
    <div v-for="n in value" :key="n.id" class="draggable-item w-100">
      <div class="dragNdrop draggable-item-child d-flex p-3 nav-link-title w-100 mt-2">
        <div class="col-md-1">
          <div class="custom-control custom-checkbox">
            <input
              :id="`option-${n.id}`"
              v-model="n.selected"
              type="checkbox"
              class="my-auto custom-control-input"
            >
            <label
              class="custom-control-label"
              :for="`option-${n.id}`"
            />
          </div>
        </div>
        <div class="font-weight-600" :class="isCmsCategory ? 'col-md-3' : 'col-md-6'">
          {{ parent && '-' }} <title-with-shortcodes :value="entityLabel(n.options.name, false, true)" />
        </div>
        <div v-if="isCmsCategory" class="col-md-3">
          <span class="color-box" :style="`background-color: ${n.color || n.options.color}`"></span>
        </div>
        <div class="col-md-2 text-center">
          {{ n.categoryable_count }}
        </div>
        <div class="col-md-3 text-right">
          <cta>
            <action-button class-icon="fa-edit" @click.native="edit(n)" />
            <action-button class-icon="fa-trash" @click.native="remove(n)" />
          </cta>
        </div>
      </div>
      <nested-categories
        v-if="n.children"
        class="ml-4"
        :value="n.children"
        :parent="n"
        :entity="entity"
        :entity-type="entityType"
      />
    </div>
  </draggable>
</template>

<script>
import { EeTitle } from "@/modules/erp_entities/components/forms"
// Nested categories in recursion doesnt allow to emit "edit" and "delete"
//  to root component (Create.vue) so we have to use bus
// TODO: remove bus and transfer functions from main component
import eventBus from '@/utilities/eventBus'
import TitleWithShortcodes from "@/modules/erp_entities/components/utilities/TitleWithShortcodes.vue"
import DraggableMixin from "@/mixins/DraggableMixin"

export default {
  name: "NestedCategories",
  components: { TitleWithShortcodes },
  mixins: [DraggableMixin],
  props: {
    value: {
      required: true,
      type: Array,
    },
    parent: {
      required: false,
      type: Object,
    },
    entity: {
      required: true,
    },
    entityType: {
      required: true,
    },
  },
  computed: {
    isCmsCategory() {
      return this.entity !== 'products' && this.entityType === 'categories'
    },
    dragOptions() {
      return {
        draggable: ".draggable-item",
        group: "nav",
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        dragClass: "draggable-ghost",
        handle: ".dragNdrop",
      }
    },
  },
  methods: {
    edit(n){
      eventBus.$emit('edit', n, true)
    },
    remove(n){
      eventBus.$emit('del', n)
    },
  },
}
</script>

<style scoped lang="scss">
span.color-box {
  display: block;
  height: 20px;
  width: 20px;
  border: 1px solid #cbcbcb;
  border-radius: 50px;
}
</style>
