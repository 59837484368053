<template>
  <modal
    name="contact_sales"
    :pivot-x="0.5"
    :pivot-y="0.5"
    height="auto"
    width="500px"
    transition="slide-top"
    overlay-transition="wait"
    classes="remove-modal-height"
  >
    <div class="bg-white py-5 px-3 text-center" style="border-radius: 20px;">
      <img src="/img/menu-icons/seller.svg" height="200px" alt="">
      <hr>
      <h3 class="text-center mt-3">
        {{ 'Questions' | translate('dashboard') }}
      </h3>
      <p>{{ 'Please contact our sales person' | translate('dashboard') }}</p>
      <h6 class="font-weight-700 mb-3">
        +31  (0) 85 902 1583
      </h6>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ContactSalesModal",
}
</script>

