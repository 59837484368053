<template>
  <div v-show="isActiveTab">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    name: { required: true },
    selected: { default: false },
    v_hide: { default: false },
  },
  data() {
    return {
      isActiveTab: false,
    }
  },
  mounted() {
    this.isActiveTab = this.selected
  },
}
</script>
