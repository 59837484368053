<template>
  <!-- select || multiselect,  data_type = custom -->
  <v-select
    v-model="model"
    label="value"
    :placeholder="placeholderComputed"
    :options="data || []"
    :disabled="isDisabled"
    :multiple="fieldProps.type == 'multiselect'"

    :get-option-label="(option) => entityLabel(option.value, fieldProps && fieldProps.system_attribute == 1)"
    :reduce="value => value.key"
    class="select2-form"
    :class="fieldClasses"
  >
    <template v-slot:no-options>{{ translate(noOptionsLabel ,'entities') }}</template>
  </v-select>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormSelectMixin from "@/modules/erp_entities/mixins/FormSelectMixin"

export default {
  name: 'SelectStaticField',
  mixins: [FormFieldMixin, FormSelectMixin],
  computed: {
    isDisabled(){
      // we also use "disabled" for read only because v-select does not support readonly
      return !this.canWrite
    },
    noOptionsLabel(){
      return 'Sorry, no matching options.'
    },
  },
}
</script>
