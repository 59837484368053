<template>
    <div class="clients-index">
        <page-title :page-title="translate('Customers', 'entities')" v-if="companies && companies.meta" :counter="companies.meta.total">
            <template v-slot:actions>
                <button class="btn btn-primary rounded-pill" @click="$modal.show('add_new_client')">{{ translate('Add new {entity}', 'entities', { entity: translate('Customer', 'entities').toLowerCase() }) }}</button>
            </template>
        </page-title>
        <div class="main-table">
			<div class="d-flex justify-content-between align-items-center table-header px-4">
				<div class="input-group w-100 h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
					<div class="search-icon">
						<span><i class="fa fa-search"></i></span>
					</div>
					<input type="text" class="form-control h-100 search-field"
						   :placeholder="translate('Search placeholder', 'global')"
						   @input="v => filterByName(v.target.value)">
				</div>
				<div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
					<div class="nav-item dropdown dropdown-styled dropdown-animate" data-toggle="hover">
						<a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="companies">
              {{ translate('{count} per page', 'global', { count: companies.meta.per_page }) }}
							<i class="fa fa-chevron-down"></i>
						</a>
						<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
							<a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="filters.set('perpage', per_page.key) && loadCompanies()">
								{{per_page.value}}
							</a>
						</div>
					</div>
				</div>
			</div>
            <div v-if="companies && !loading" class="table-responsive">
                <table class="table m-0">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <div class="custom-control custom-checkbox">
                                <input :id="'all'" type="checkbox" class="custom-control-input">
                                <label class="custom-control-label" :for="'all'"></label>
                            </div>
                        </th>
                        <th scope="col">{{ translate('Company name', 'clients') }}<i class="fa fa-sort"
                                                                               aria-hidden="true"></i></th>
                        <th scope="col">{{ translate('Email', 'clients') }} <i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th scope="col">{{ translate('Owner', 'clients') }} <i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th scope="col">{{ translate('Phone index', 'clients') }}
							<i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th scope="col">{{ translate('Subscription', 'clients') }} <i class="fa fa-sort" aria-hidden="true"></i></th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(company, cI) in companies.objectData" :key="cI" class="tr-1">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input id="all" type="checkbox" class="custom-control-input">
                                <label for="all" class="custom-control-label"></label>
                            </div>
                        </td>
                        <td class="d-flex align-items-center">
                            <img :src="company.logo" alt="Avatar" class="avatar">
							<router-link class="px-2" :to="`/companies/${company.id}`">
								{{ company.name }}
							</router-link>
                        </td>
                        <td>{{ company.email }}</td>
                        <td>
							<div v-if="companies.data[cI].managers.length">
								{{ companies.data[cI].managers[0].first_name }}
								{{ companies.data[cI].managers[0].last_name }}
							</div>
						</td>
						<td>{{ company.phone }}</td>
                        <td><span class="active_status px-2">{{ translate('Active', 'clients') }}</span></td>
                        <td class="actions-cta">
							<cta>
                                <action-button class-icon="fa-eye" @click.native="companyShow(company.id)"></action-button>
                                <action-button class-icon="fa-trash-alt" url="#"
											   @click.native="deleteRow(company)">
<!-- TODO NEW permissions v-if="companies.data[cI].actions.delete" -->
								</action-button>
							</cta>
						</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="loading" class="d-flex justify-content-center m-5">
                <span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
            </div>
            <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                        :value="companies.meta"
                        v-if="companies && companies.meta" @goToPage="v => filters.set('page', v) && loadCompanies()"></pagination>
            <modal
                name="add_new_client"
                :pivot-x="0.5"
                :pivot-y="0.5"
                height="auto"
                width="500px"
                transition="slide-top"
                overlayTransition="wait"
                classes="remove-modal-height"
            >
                <add-new-client></add-new-client>
            </modal>
        </div>
    </div>
</template>
<script>
import AddNewClient from "@/modules/blockz/views/clients/AddNewClient";
import {routes} from "@/utilities/database";
import {environment} from "@/modules/erp_entities/utilities/helper";

export default {
    name: 'CustomerIndex',
    components: {
       AddNewClient
    },
    data() {
        return {
            companies: null,
            filters: this.erp.ext.query(),
            loading: false,
            deleteId: false
        }
    },
    methods: {
        async loadCompanies() {
            this.loading = true
            this.$store.state.system.isLoading = true
            this.companies = await this.erp.ext.request.get('/modules/companies', this.filters.toString())
			this.companies.objectData.forEach(co => {return co.logo == null || co.logo == undefined ? co.logo = '/img/avatar_default.jpg' : co.logo})
            this.loading = false
            this.$store.state.system.isLoading = false
        },
        async filterByName(v) {
            this.filters.where('name', 'like', `%${v}%`)
            await this.loadCompanies
        },
        async deleteRow(row) {
          const confirmDelete = await this.$alert.confirmDelete()
          if(!confirmDelete.isConfirmed) return

          this.performAction('delete', row.id)
        },

      performAction(action, id) {
        // Delete
        if(action === 'delete'){
          this.erp.ext.request.axiosInstance.delete(`/modules/companies/${id}`).then(res => {
            this.loadCompanies()
          }).catch(error => {
            this.loadCompanies()
            this.$alert.error(error.message)
          })
        }
      },

        deleteUser(id) {

        },
		companyShow(id) {
			console.log(id)
        	this.$router.push(`/companies/${id}`)
		}
    },
    computed: {
        perPage() {
            return [
              { key: 5, value: this.translate('{count} per page', 'global', { count: 5 }) },
              { key: 10, value: this.translate('{count} per page', 'global', { count: 10 }) },
              { key: 20, value: this.translate('{count} per page', 'global', { count: 20 }) },
              { key: 50, value: this.translate('{count} per page', 'global', { count: 50 }) },
            ]
        }
    },
    mounted() {
        this.filters.set('perpage', 10)
        this.loadCompanies()
    },
}
</script>
<style scoped lang="scss">
.clients-index {

    .main-table {
        margin: 1rem 0;
        border-radius: 5px;
        background-color: #fff;

        table {
            border-radius: 5px;

            thead {
                background-color: #fcfcfc;
            }

            tr {
                border-bottom: 1px solid #e5edf5;

                th {
                    border-bottom: 0;
                    border-color: #e5edf5;
                }

                td {
                    background-color: #fff;

                    .avatar {
                        vertical-align: middle;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }
            }

        }
    }

    //.txt_1 {
    //    font-weight: 300;
    //}
    //
    //.txt_3 {
    //    font-weight: 300;
    //}
    //
    //#search-field, .table, .tr-1, .tr-2, .tr-3, .tr-4 {
    //    border-bottom: 1px solid #d9d9d9;
    //}

    .to_22, .active_status {
        border: 1px solid #98FB98;
        border-radius: 10px;
        background-color: #98FB98;
        color: #228B22;
    }

    .to_66, .expire_soon {
        border: 1px solid #FFDEAD;
        border-radius: 10px;
        background-color: #FFDEAD;
        color: #F4A460;
    }

    .expired {
        border: 1px solid #FA8072;
        border-radius: 10px;
        background-color: #FA8072;
        color: #FF0000;
    }

    .fa-ellipsis-v {
        cursor: pointer;
        color: #122b3f;
    }
}
</style>
