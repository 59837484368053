<template>
  <div class="form-group" style="position:relative;">
    <label v-if="label" class="label">{{ translate(label, 'entities') }}</label>

    <!-- avatar style -->
    <div v-if="pickerStyle === 'avatar'" class="avatar-picker-container">
      <div class="logo">
        <img v-if="value" :src="value">
        <img v-else :src="defaultValue" @click="canWrite ? triggerFilemanager() : null">

        <span v-if="canWrite && value" class="clear-input-img" @click="removeImage"><i class="fas fa-trash-alt"></i></span>
      </div>
      <i class='icon-erp-edit' @click="triggerFilemanager" id="pick-logo"></i>
    </div>

    <!-- default and secondary styles -->
    <div v-else>
      <div class="d-flex justify-content-between align-items-center" v-if="isVoidValue(value)">
        <img
          :src="defaultValue"
          class="default-placeholder"
        />
        <div v-if="canWrite" class="justify-content-end open-file_manager mr-1 ml-1" @click="triggerFilemanager">
          <button class="btn-browse">{{ translate('Browse', 'entities') }}</button>
        </div>
      </div>
      <div v-else>
        <img
          v-if="value"
          :src="value"
          width="100%"
          height="300px"
          style="object-fit: contain"
        />
        <button v-if="canWrite" class="btn remove-image" @click="removeImage">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {uniqueId} from "lodash";
import {isVoidValue} from "@/utilities/helper";
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"

export default {
  name: "ImageField",
  mixins: [FormFieldMixin],
  props:{
    // todo move labels as other component and remove this label prop
    label: {
      required: false,
    },
    pickerStyle: {
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      key: 0,
      files: [],
    }
  },
  computed: {
    defaultValue() {
      return this.fieldProps.default_value ? this.fieldProps.default_value : '/uploads/defaults/image-placeholder.jpg'
    },
  },
  created() {
    this.key = uniqueId('cfpi_')

    this.$fileManager.$on('selected_'+ this.key, imgs => {
      this.$emit('input', imgs[0])
      this.value = imgs[0]
    })
  },
  methods: {
    isVoidValue,
    triggerFilemanager() {
      this.$fileManager.$emit('open', this.key)
    },
    removeImage(){
      this.$emit('input', null)
      this.value = null
    },
  },
}
</script>

<style scoped lang="scss">
// Picker styles - default + secondary
.default-placeholder {
  display: none;
}

.picker-secondary, .picker-style-3 {
  .default-placeholder {
    display: block;
  }
}

.picker-secondary {
  img {
    max-height: 120px;
  }

  .btn-browse {
    color: #fff;
    margin-left: 0.5rem;
    background-color: $primary;
    border: 1px solid $primary;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 50rem;
  }

  .open-file_manager {
    border: none;
    padding: unset;
  }
}

.remove-image {
  position: absolute;
  right: 10px;
  top: 36px;
  background: #fff;
  padding: 5px 8px;
  line-height: 0;

  .fa-times {
    font-size: 16px;
  }
}

// Picker style - avatar

.avatar-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .logo {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 170px;
    height: 170px;

    img {
      cursor: pointer;
      object-fit: cover;
      width: 100%;
      max-height: 170px;
    }

    .clear-input-img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      background-color: #00000015;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease-in-out;
    }

    &:hover {
      .clear-input-img {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  #pick-logo {
    position: absolute;
    bottom: 0;
    left: calc(50% + 40px);
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
  }
}
</style>
