<template>
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title d-flex align-items-center">
        {{ translate('There is no translation yet. Do you want to connect or create new?', 'page-builder') }}
      <button @click="$modal.hide('translation-modal')" class="close d-flex justify-content-center align-items-center">
        <span>&times;</span>
      </button>
      </h6>
    </div>
    <div class="modal-body">
      <select-field
        v-model="relation"
        :placeholder="translate('Translation', 'page-builder')"
        :field-props="{ data: locales}"
      />
      <button
        :disabled="!relation"
        class="btn btn-primary rounded-pill mt-4"
        @click="$emit('save', relation)"
      >
        {{ translate('Connect and continue', 'page-builder') }}
      </button>

      <h6 class="divider my-4">{{ translate('OR', 'page-builder') }}</h6>

      <div class="form-group mt-4">
        <div class="custom-control custom-switch">
          <input
            id="duplicate"
            v-model="duplicate"
            :disabled="relation"
            type="checkbox"
            name="duplicate"
            class="custom-control-input"
          />
          <label for="duplicate" class="custom-control-label pl-2">{{ translate('Duplicate content', 'page-builder') }}</label>
        </div>
      </div>
      <button
        :disabled="relation"
        class="btn btn-primary rounded-pill"
        @click="$emit('performTranslationAction', duplicate)"
      >
        {{ translate('Create new {entity}', 'page-builder', { entity: entityNameTranslated.toLowerCase() }) }}
      </button>

    </div>

    <div class="modal-footer remove-border">
      <button type="button" class="btn btn-white rounded-pill" @click.prevent="$modal.hide('translation-modal')">
        {{ translate('Go back', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import SelectField from "@/modules/erp_entities/components/page/form/fields/SelectField.vue"

export default {
  name: "TranslationsModal",
  components: {
    SelectField,
  },
  props: {
    entityProp: {
      type: String,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
    entityNameTranslated: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      relation: null,
      duplicate: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #c2c2c2;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #c2c2c2;
  }
  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
}
</style>
