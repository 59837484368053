<template>
  <div class="table-responsive mt-4">
    <table class="table system-text-primary">
      <thead>
        <tr>
          <th>{{ translate('Conditions', 'entities') }}</th>
          <th>{{ translate('Destination', 'entities') }}</th>
          <th>{{ translate('Behaviours', 'entities') }}</th>
        </tr>
      </thead>
      <tbody v-if="value && value.length">
        <condition-row
          v-for="(condition, idx) in value"
          :key="idx"
          :condition="condition"
          :properties="properties"
          @removeCondition="$emit('removeCondition', idx)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import ConditionRow from "@/modules/erp_entities/components/entities/Conditions/ConditionRow.vue"

export default {
  name: 'ConditionsTable',
  components: { ConditionRow },
  props: {
    value: {
      required: true,
      type: Array,
      default: () => [],
    },
    properties: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
}
</script>
