<template>
	<div class="container">
		<page-title :page-title="translate('app-store', 'marketplace')">
			<template v-slot:actions>
				<router-link to="/my-apps" class="btn btn-primary rounded-pill">
					{{ translate('Go to my apps', 'marketplace') }}
				</router-link>
			</template>
		</page-title>
		<div class="app-store-main-content mt-4">
			<div class="store-search-field w-100 my-3">
				<span class="p-3"><i class="fas fa-search mr-3"></i></span>
				<input class="form-control w-100 pl-5"
					   @input="e => loadExtensions({key: 'name', value: `%${e.target.value}%`, condition: 'like'})"
					   :placeholder="translate('Search', 'marketplace')">
			</div>

            <div class="row" v-if="extensions && !loading">
					<div class="col-md-4" v-for="(extension, e) in extensions" :key="e">
						<div class="app-holder d-flex flex-column zoom-in-hover bg-white">
							<div class="img-holder">
								<router-link :to="`/app-show/${extension.id}`">
									<img v-if="extension.readable_options.logo" :src="extension.readable_options.logo">
									<img v-else src="/uploads/defaults/image-placeholder.jpg">
								</router-link>
							</div>
							<div
								class="info-holder d-flex flex-row w-100 justify-content-between align-items-center p-4">
								<div class="d-flex flex-column w-100">
									<div class="d-flex justify-content-between">
										<span class="app-title">{{ extension.name }}</span>
										<span><i class="fas fa-ellipsis-v"></i></span>
									</div>
									<div class="d-flex justify-content-between">
                                    <span class="app-rating font-weight-bold">
										{{ extension.readable_options.description }}
									</span>
										<div>
											<span><i class="far fa-star"></i></span>
											<span><i class="far fa-star"></i></span>
											<span><i class="far fa-star"></i></span>
											<span><i class="far fa-star"></i></span>
											<span><i class="far fa-star"></i></span>
										</div>
									</div>
								</div>
							</div>
							<div class="w-100 app-actions">
								<div class="d-flex justify-content-between align-items-center b-t-pr-gr mx-3 py-4">
									<button class="btn btn-primary rounded-pill w-100 px-4 py-2">€{{ extension.price.price }}
										{{ translate('per year', 'marketplace') }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
		<div v-if="loading" class="d-flex justify-content-center m-5">
			<span><i class="fa fa-spin fa-spinner fa-3x"></i></span>
		</div>
	</div>
</template>

<script>
import {db, routes} from "@/utilities/database";
import debounce from 'debounce'
import {createEntityFormObjectNoTabs, urlEncoder} from "@/utilities/helper";

export default {
	name: "AppStore",
	data() {
		return {
			active: false,
			extensions: null,
			loading: false
		}
	},
	methods: {
		loadExtensions: debounce(async function (filters) {
			this.loading = true;

			let params = null
			if (filters) {
				params = {
					'filter_criteria': {
						'filter_groups': {
							0: {
								'filters': {
									0: filters
								}
							}
						}
					}
				}
			}

			if (filters && filters.key === 'status') {
				this.selectedFilter = filters.value
			}

			let columns = {column: 'created_at', direction: 'desc', type: 'text'}

			let response = await db.get(`modules/blockz/app-extensions/extensions-modules?page=1&perpage=200`)
			let uniqueExt = []
			response.data.data.forEach(modules => {
				db.get(`modules/blockz/app-extensions/extensions-props/${modules.id}?page=1&perpage=200&${urlEncoder(columns)}&${urlEncoder(params)}`)
					.then(extensions => {
						extensions.data.data.forEach(ext => {
							ext.readable_options = createEntityFormObjectNoTabs(ext.options, 'key')
							if (ext.inventory_type === 'extension' && !uniqueExt.some(item => item.id === ext.id)) {
								uniqueExt.push(ext)

								if (ext.readable_options.description) {
									ext.readable_options.description = ext.readable_options.description.replace(/(<([^>]+)>)/gi, "")
								}
							}
						})
				})
			})

			this.extensions = uniqueExt
			this.loading = false;
		}, 700),
	},
	created() {
		this.loadExtensions()
	}
}
</script>

<style scoped lang="scss">
.app-store-main-content {
	/*span {*/
	/*    cursor: pointer;*/
	/*}*/

	.store-search-field {
		span {
			position: absolute;
			cursor: pointer;

			.fa-search {
				color: #DEDEDE;
				font-size: 20px;
				font-weight: 600;
			}
		}

		input {
			border: 1px solid #DEDEDE;
			border-radius: 10px;
			font-size: 18px;
			color: $body-color;
			background-color: #fff;

			&:focus {
				outline: none;
			}
		}
	}

	.app-holder {
		border: 1px solid #DEDEDE;
		border-radius: 10px;
		margin-bottom: 1rem;

		.img-holder {
			img {
				width: 100%;
				height: 100%;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}

		.info-holder {
			.app-title {
				font-size: 18px;
				font-weight: bold;
			}

			.app-rating {
				color: #DEDEDE;
				font-size: 16px;
			}

			i {
				color: #DEDEDE;
			}

			.fa-star {
				color: #ffc02e;
			}
		}

		.app-actions {
			.action-buttons {
				span.trash-icon {
					background-color: $primary;
					border: 1px solid $primary;
					cursor: pointer;
				}

				span {
					border: 1px solid #DEDEDE;
					border-radius: 10px;
					padding: 14px 12px 8px 12px;
					cursor: pointer;

					i {
						font-size: 22px;
						color: #DEDEDE;
					}
				}
			}

		}
	}

	@media (min-width: 990px) and (max-width: 1070px) {
		.app-rating {
			word-break: break-word;
		}
	}
}
</style>
