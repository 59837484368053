<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <devices-dropdown v-if="hasResponsiveStyles" />
    <ee-form-color-picker
      :value="value"
      :placeholder="placeholder"
      @input="color => setColor(color)"
    />
  </div>
</template>

<script>
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker";
import DevicesDropdown from "@/modules/pagebuilder/components/craft-inputs/DevicesDropdown.vue"

export default {
  name: "CraftColorPicker",
  inject: ["editor"],
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "Color Picker",
    },
    selector: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    hasResponsiveStyles: {
      required: false,
      default: false,
    },
  },
  components: {
    DevicesDropdown, EeFormColorPicker,
  },
  methods: {
    setColor(style){
      this.value = style
      this.$emit('input', style)
    }
  },
};
</script>