export default class Collection {
  constructor(data = []){
    this._data = data
  }


  /**
     * Delete all rows in filtered collection collection
     *
     * @returns
     * @memberof Collection
     */
  delete(){
    this._data.forEach(item => item.delete())
    return
  }

  /**
     * Update all records inside collection class
     *
     * @param {Object} [properties=[]]
     * @returns
     * @memberof Collection
     */


  update(properties = {}){
    this._data.forEach(model => {

      // Update item properties with new data
      Object.entries(properties).forEach(([key, value]) => model.set(key, value))

      try {
        this._data.forEach(model => model.save())
      } catch({ errors }){
        // if validation fails somewhere return errors once
        return errors
      }
    })
    return
  }


  /**
     *  Array representation of collection
     *
     * @memberof Collection
     */
  toArray(){
    return this._data
  }
}