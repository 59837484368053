<template>
  <div class="container">
    <div class="row">
      <div
        v-for="(color, ci) in colors"
        :key="ci"
        class="col-md-4"
      >
        <div
          class="color-theme-holder my-3"
          @click="nextStep(color)"
        >
          <div class="p-4">
            <div class="colors-holder d-flex">
              <span :style="`background-color: ${color.primary}`" />
              <span :style="`background-color: ${color.secondary}`" />
              <span :style="`background-color: ${color.third}`" />
            </div>
            <div class="color-name text-center p-1">
              <span>{{ ci }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorSchema",
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    colors() {
      return {
        green: {
          primary: '#164910',
          secondary: '#429e38',
          third: '#c1edbc',
        },
        blue: {
          primary: '#0f1c3e',
          secondary: '#3a64d4',
          third: '#e5edff',
        },
        purple: {
          primary: '#170924',
          secondary: '#753bd4',
          third: '#e3d8f5',
        },
        yellow: {
          primary: '#a88f14',
          secondary: '#eecc1b',
          third: '#fff7c7',
        },
        orange: {
          primary: '#fff7c7',
          secondary: '#d4713a',
          third: '#ffe0c0',
        },
        red: {
          primary: '#560f04',
          secondary: '#d43a3a',
          third: '#fad3cc',
        },

      }
    },
  },
  methods: {
    nextStep(val) {
      this.value.color_schema = val
      this.$router.push(this.$router.currentRoute.meta.next)
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped lang="scss">
.color-theme-holder {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: .375rem;

    .colors-holder {
        border-radius: 10px;

        span {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            height: 5rem;

            &:first-of-type {
                border-top-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
            }
        }
    }

    .color-name {
        background-color: #f2f2f2;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}
</style>
