import AccordionElement from "./AccordionElement.vue"
import BannerElement from "./BannerElement.vue"
import ButtonElement from "./ButtonElement.vue"
import ColumnElement from "./ColumnElement.vue"
import ContentSliderElement from "./ContentSliderElement.vue"
import ContentSliderSlideElement from "./ContentSliderSlideElement.vue"
import CustomCodeElement from "./CustomCodeElement.vue"
import CustomHtmlElement from "./CustomHtmlElement.vue"
import EmbedVideoElement from "./EmbedVideoElement.vue"
import GalleryElement from "./GalleryElement.vue"
import IconBoxElement from "./IconBoxElement.vue"
import ImageElement from "./ImageElement.vue"
import MainContainerElement from "./MainContainerElement.vue"
import RowElement from "./RowElement.vue"
import SearchBarElement from "./SearchBarElement.vue"
import SectionElement from "./SectionElement.vue"
import SeparatorElement from "./SeparatorElement.vue"
import SocialMediaIconsElement from "./SocialMediaIconsElement.vue"
import TabsElement from "./TabsElement.vue"
import TextElement from "./TextElement.vue"
import WhitespaceElement from "./WhitespaceElement.vue"

// Dynamic
import BlogElement from "./dynamic/BlogElement.vue"
import CategoriesElement from "./dynamic/CategoriesElement.vue"
import FormElement from "./dynamic/FormElement.vue"
import InstagramFeedElement from "./dynamic/InstagramFeedElement.vue"
import PortfolioElement from "./dynamic/PortfolioElement.vue"
import ProductsElement from "./dynamic/ProductsElement.vue"
import SliderElement from "./dynamic/SliderElement.vue"
import StoreLocatorElement from './dynamic/StoreLocatorElement.vue'
import TestimonialsElement from "./dynamic/TestimonialsElementNEWTODO.vue"
import GoogleReviewsElement from "./dynamic/GoogleReviewsElement.vue"

export default {
  AccordionElement,
  BannerElement,
  ButtonElement,
  ColumnElement,
  ContentSliderElement,
  ContentSliderSlideElement,
  CustomCodeElement,
  CustomHtmlElement,
  EmbedVideoElement,
  GalleryElement,
  IconBoxElement,
  ImageElement,
  MainContainerElement,
  RowElement,
  SearchBarElement,
  SectionElement,
  SeparatorElement,
  SocialMediaIconsElement,
  TabsElement,
  TextElement,
  WhitespaceElement,

  // Dynamic
  BlogElement,
  CategoriesElement,
  FormElement,
  InstagramFeedElement,
  PortfolioElement,
  ProductsElement,
  SliderElement,
  StoreLocatorElement,
  TestimonialsElement,
  GoogleReviewsElement,
}