<template>
  <div class="cms-block" :class="'cms-block-'+blockName" :data-component="blockName">
    <template v-if="editor.enabled">
      <div v-if="resData" v-html="resData.output"/>
      <vs-skeleton v-else primary-color="#ebebeb" secondary-color="#f2f2f2" class="cms-placeholder" data-placeholder="Store Locator">
        <vs-line height="250" class="mt-1" b-radius='0'></vs-line>
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from '../../../../mixins/DynamicComponentMixin'
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import { responsiveColumns } from "@/modules/pagebuilder/utilities/helpers"

export default {
  name: 'StoreLocatorElement',
  mixins: [DynamicComponentMixin, CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        categories: null,
        layout: 'list',
        store_locator_type: 'list',
        number_of_items: 4,
        columns: {
          desktop: '4', // 3 columns
          tablet_landscape: '4', // 3 columns
          tablet_portrait: '6', // 2 columns
          mobile: '12', // 1 column
        },
      },
    },
  },
  data() {
    return {
      blockName: 'store-locator',
      renderUrl: null,
    }
  },
}
</script>